import {
  VIEW_MODE_KEYS,
  VIEW_MODES,
  DEFAULT_VIEW_MODE,
} from '@/utils/constants/viewModes';

import LOCAL_STORAGE from '@/utils/constants/localStorage';

export default {
  namespaced: true,
  state: {
    shouldSuppressCheckoutModal: false,
    filterQuery: '',
    viewModeKey: VIEW_MODE_KEYS.LIST,
  },
  getters: {
    filterQuery(state) {
      return state.filterQuery;
    },

    viewModeKey(state) {
      return state.viewModeKey;
    },

    viewMode(state) {
      return VIEW_MODES[state.viewModeKey];
    },

    oppositeViewMode(state) {
      if (state.viewModeKey === VIEW_MODE_KEYS.LIST) {
        return VIEW_MODES[VIEW_MODE_KEYS.GRID];
      }

      return VIEW_MODES[VIEW_MODE_KEYS.LIST];
    },
  },
  mutations: {
    unsuppressCheckoutModal(state) {
      state.shouldSuppressCheckoutModal = false;
    },

    suppressCheckoutModal(state) {
      state.shouldSuppressCheckoutModal = true;
    },

    setFilterQuery(state, filterQuery) {
      state.filterQuery = filterQuery;
    },

    setViewModeKey(state, viewModeKey) {
      if (!Object.values(VIEW_MODE_KEYS).includes(viewModeKey)) {
        // eslint-disable-next-line no-param-reassign
        viewModeKey = DEFAULT_VIEW_MODE;
      }

      state.viewModeKey = viewModeKey;

      window.localStorage.setItem(
        LOCAL_STORAGE.SPACES_PEOPLE_VIEW_TYPE,
        viewModeKey,
      );
    },
  },
  actions: {
    unsuppressCheckoutModal({ commit }) {
      commit('unsuppressCheckoutModal');
    },

    suppressCheckoutModal({ commit }) {
      commit('suppressCheckoutModal');
    },

    setFilterQuery({ commit }, filterQuery) {
      commit('setFilterQuery', filterQuery);
    },

    setViewModeKey({ commit }, viewModeKey) {
      commit('setViewModeKey', viewModeKey);
    },

    toggleViewMode({ state, dispatch }) {
      dispatch('setViewModeKey', state.viewModeKey === VIEW_MODE_KEYS.LIST
        ? VIEW_MODE_KEYS.GRID
        : VIEW_MODE_KEYS.LIST);
    },
  },
};
