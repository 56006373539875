import { mapGetters, mapState } from 'vuex';

import BasePopover from '@widgets/Popover/BasePopover';
import SpacesEditorToolbarButton from '@sections/SpacesEditor/SpacesEditorToolbar/SpacesEditorToolbarButton';
import KeyboardShortcutItem from '@sections/KeyboardShortcutsList/KeyboardShortcutItem';
import { isDesktop } from '@/utils/helpers/device';
import ListItem from './SpacesEditorToolbarListItem';

// @vue/component
export default {
  props: {
    types: {
      type: Array,
      default: () => [],
    },
    tooltip: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    includeChevronDown: {
      type: Boolean,
      default: true,
    },
    showParentActive: {
      type: Boolean,
      default: true,
    },
    horizontalChildren: {
      type: Boolean,
      default: false,
    },
    popperPlacement: {
      type: String,
      default: 'bottom',
    },
    variant: {
      type: String,
      default: '',
      required: false,
    },
  },
  components: {
    BasePopover,
    ListItem,
    KeyboardShortcutItem,
    SpacesEditorToolbarButton,
  },
  data() {
    return {
      states: [],
    };
  },
  computed: {
    ...mapState('ui/activeEditor', ['activeToolbarButtons']),

    ...mapGetters('session', ['isInternalInstallation']),

    ...mapState('apps', {
      sdkEditorComponents: 'editorComponents',
    }),

    activeTypes() {
      return this.types.map(
        (type) => (type.type ? this.activeToolbarButtons.includes(type.type) : false),
      );
    },

    anyChildActive() {
      return this.activeTypes.includes(true);
    },

    showParentButtonAsActive() {
      if (this.showParentActive) {
        return this.anyChildActive;
      }
      return false;
    },

  },
  methods: {
    shouldShowShortcut(type) {
      return !!type.key;
    },

    onListItemClick(item, close) {
      this.$emit('click', item);

      close();
    },

    shouldShowShortcutKey() {
      return isDesktop();
    },
  },
};
