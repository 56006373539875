/* eslint-disable func-names */
/* eslint-disable class-methods-use-this */
import store from '@/store';
import { STATES } from '@/store/modules/loading';
import { DATA_PROMISE_HANDLERS, DATA_PROMISE_KEYS } from '@/utils/constants/dataPromise';

const KEEP_ALIVE_DURATION = 3000;

// This is a big facade that is a simple api over the
// get requests within the application
// It will simply kick off api requests
// and store them in a promise map for an arbitrary
// amount of time to minimize duplicate calls being made
class SpacesService {
  constructor() {
    // The idea here is that we hold onto promises for an arbitrary
    // amount of time in case of duplicate calls happen,
    // The benefit here beng is that you get the promise back and
    // the http request only fires once
    // we will clear this between route changes
    // but will also keep a promise alive for about 3 seconds
    this.promiseMap = {};
  }

  clean() {
    this.promiseMap = {};
  }

  getDataPromise(key, action, args = []) {
    if (this.promiseMap[key]) {
      return this.promiseMap[key];
    }

    if (!(action instanceof Function)) {
      return undefined;
    }

    const promise = action(...args);
    if (!(promise instanceof Promise)) {
      return undefined;
    }

    this.promiseMap[key] = promise;

    store.commit('loading/recordState', { key, data: { state: STATES.PROCESSING } });

    promise
      .then(() => {
        store.commit('loading/recordState', {
          key,
          data: {
            state: STATES.SUCCESS,
          },
        });
      })
      .catch((error) => {
        store.commit('loading/recordState', {
          key,
          data: {
            state: STATES.ERROR,
            errorObject: {
              status: error?.response?.status || '',
              statusText: error?.message || 'Something went wrong',
            },
          },
        });
      })
      .finally(() => {
        setTimeout(() => {
          // Pop the promise off the map, but after a timeout (so that downstream components do
          // not duplicate these calls)
          delete this.promiseMap[key];
        }, KEEP_ALIVE_DURATION);
      });

    return promise;
  }

  getSession() {
    return this.getDataPromise(
      DATA_PROMISE_KEYS.SESSION,
      DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.SESSION],
    );
  }

  getSessionSpacePermissions() {
    return this.getDataPromise(
      DATA_PROMISE_KEYS.SESSION_SPACE_PERMISSIONS,
      DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.SESSION_SPACE_PERMISSIONS],
    );
  }

  getApiKeys() {
    return this.getDataPromise(
      DATA_PROMISE_KEYS.API_KEYS,
      DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.API_KEYS],
    );
  }

  getPageLinks() {
    return this.getDataPromise(
      DATA_PROMISE_KEYS.PAGE_LINKS,
      DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.PAGE_LINKS],
    );
  }

  getTags(/* limit, offset */) {
    return this.getDataPromise(
      DATA_PROMISE_KEYS.TAGS,
      DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.TAGS],
    );
  }

  getUsers(/* limit, offset */) {
    return this.getDataPromise(
      DATA_PROMISE_KEYS.USERS,
      DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.USERS],
    );
  }

  getCompanies(/* limit, offset */) {
    return this.getDataPromise(
      DATA_PROMISE_KEYS.COMPANIES,
      DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.COMPANIES],
    );
  }

  getLaunchpadInfo() {
    return this.getDataPromise(
      DATA_PROMISE_KEYS.LAUNCHPAD_INFO,
      DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.LAUNCHPAD_INFO],
    );
  }

  getSpaces(/* limit, offset */) {
    return this.getDataPromise(
      DATA_PROMISE_KEYS.SPACES,
      DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.SPACES],
    );
  }

  getCategorisedSpaces() {
    return this.getDataPromise(
      DATA_PROMISE_KEYS.CATEGORISED_SPACES,
      DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.CATEGORISED_SPACES],
    );
  }

  getStarredSpaces() {
    return this.getDataPromise(
      DATA_PROMISE_KEYS.STARRED_SPACES,
      DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.STARRED_SPACES],
    );
  }

  getStarredPages() {
    return this.getDataPromise(
      DATA_PROMISE_KEYS.STARRED_PAGES,
      DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.STARRED_PAGES],
    );
  }

  getRequiredReading() {
    const handler = (...args) => (async () => {
      await Promise.all([
        this.getSession(),
      ]);

      if (!store.getters['features/canUseRequiredReading']) {
        return Promise.resolve();
      }

      return DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.REQUIRED_READING](...args);
    })();

    return this.getDataPromise(
      DATA_PROMISE_KEYS.REQUIRED_READING,
      handler,
    );
  }

  getEmojiData() {
    return this.getDataPromise(
      DATA_PROMISE_KEYS.EMOJIS,
      DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.EMOJIS],
    );
  }

  getNotifications(/* limit, offset */) {
    return this.getDataPromise(
      DATA_PROMISE_KEYS.NOTIFICATIONS,
      DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.NOTIFICATIONS],
    );
  }

  getPageTemplates() {
    return this.getDataPromise(
      DATA_PROMISE_KEYS.PAGE_TEMPLATES,
      DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.PAGE_TEMPLATES],
    );
  }

  getPageTemplatesGallery() {
    return this.getDataPromise(
      DATA_PROMISE_KEYS.PAGE_TEMPLATES_GALLERY,
      DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.PAGE_TEMPLATES_GALLERY],
    );
  }

  getInstallationStats() {
    // Force re-fetch
    delete this.promiseMap[DATA_PROMISE_KEYS.INSTALLATION_STATS];

    return this.getDataPromise(
      DATA_PROMISE_KEYS.INSTALLATION_STATS,
      DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.INSTALLATION_STATS],
    );
  }

  getExternalApps() {
    return this.getDataPromise(
      DATA_PROMISE_KEYS.APPS,
      DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.APPS],
    );
  }

  // Start Space Level Gets
  getSpace(spaceCode) {
    return this.getDataPromise(
      DATA_PROMISE_KEYS.SPACE,
      DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.SPACE],
      [spaceCode],
    );
  }

  getSpacePages(spaceCode) {
    return this.getDataPromise(
      DATA_PROMISE_KEYS.SPACE_PAGES,
      DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.SPACE_PAGES],
      [spaceCode],
    );
  }

  getTrashedSpacePages(spaceCode) {
    return this.getDataPromise(
      DATA_PROMISE_KEYS.TRASHED_SPACE_PAGES,
      DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.TRASHED_SPACE_PAGES],
      [spaceCode],
    );
  }

  getSpaceFollowingState(spaceCode) {
    return this.getDataPromise(
      DATA_PROMISE_KEYS.SPACE_FOLLOWING_STATE,
      DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.SPACE_FOLLOWING_STATE],
      [spaceCode],
    );
  }

  getSpaceAttachments(spaceCode, pageSize = 100, pageOffset = 0) {
    return this.getDataPromise(
      DATA_PROMISE_KEYS.SPACE_ATTACHMENTS,
      DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.SPACE_ATTACHMENTS],
      [spaceCode, pageSize, pageOffset],
    );
  }

  getSpaceAccessRequests(spaceCode) {
    return this.getDataPromise(
      DATA_PROMISE_KEYS.SPACE_ACCESS_REQUESTS,
      DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.SPACE_ACCESS_REQUESTS],
      [spaceCode],
    );
  }

  // End Space Level Gets

  // Start Page Level Gets
  getActiveSpacePermission(spaceId) {
    return this.getDataPromise(
      DATA_PROMISE_KEYS.ACTIVE_SPACE_PERMISSION,
      DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.ACTIVE_SPACE_PERMISSION],
      [spaceId],
    );
  }

  getSpacePermissions(spaceId) {
    return this.getDataPromise(
      DATA_PROMISE_KEYS.SPACE_PERMISSIONS,
      DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.SPACE_PERMISSIONS],
      [spaceId],
    );
  }

  getPage(spaceCode, pageCode) {
    return this.getDataPromise(
      DATA_PROMISE_KEYS.PAGE,
      DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.PAGE],
      [spaceCode, pageCode],
    );
  }

  getCollaborators(spaceCode, pageCode) {
    return this.getDataPromise(
      DATA_PROMISE_KEYS.COLLABORATORS,
      DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.COLLABORATORS],
      [spaceCode, pageCode],
    );
  }

  getComments(spaceCode, pageCode) {
    return this.getDataPromise(
      DATA_PROMISE_KEYS.COMMENTS,
      DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.COMMENTS],
      [spaceCode, pageCode],
    );
  }

  getInlineComments(spaceCode, pageCode) {
    return this.getDataPromise(
      DATA_PROMISE_KEYS.INLINE_COMMENTS,
      DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.INLINE_COMMENTS],
      [spaceCode, pageCode],
    );
  }

  async getPageSharingStatus(spaceCode, pageCode) {
    const handler = (...args) => (async () => {
      await Promise.all([
        this.getCategorisedSpaces(),
        this.getSessionSpacePermissions(),
      ]);

      const space = store.getters['spaces/byCode'](spaceCode);

      if (!space) {
        return Promise.resolve();
      }

      if (!store.getters['session/canEditSpace'](space.id)) {
        return Promise.resolve();
      }

      return DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.PAGE_SHARE_STATE](...args);
    })();

    return this.getDataPromise(
      DATA_PROMISE_KEYS.PAGE_SHARE_STATE,
      handler,
      [spaceCode, pageCode],
    );
  }

  // @TODO - Only perform request if page is Required Reading
  getRequiredReadingStatus(spaceCode, pageCode) {
    const handler = (...args) => (async () => {
      await Promise.all([
        this.getSession(),
      ]);

      if (!store.getters['features/canUseRequiredReading']) {
        return Promise.resolve();
      }

      return DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.REQUIRED_READING_STATUS](...args);
    })();

    return this.getDataPromise(
      DATA_PROMISE_KEYS.REQUIRED_READING_STATUS,
      handler,
      [spaceCode, pageCode],
    );
  }

  getRequiredReadingStats(spaceId, pageId) {
    const handler = (...args) => (async () => {
      await Promise.all([
        this.getSession(),
      ]);

      if (!store.getters['features/canUseRequiredReading']) {
        return Promise.resolve();
      }

      if (!store.getters['features/canUseRequiredReadingReporting']) {
        return Promise.resolve();
      }

      return DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.REQUIRED_READING_STATS](...args);
    })();

    return this.getDataPromise(
      DATA_PROMISE_KEYS.REQUIRED_READING_STATS,
      handler,
      [spaceId, pageId],
    );
  }

  getPageFollowingState(spaceCode, pageCode) {
    return this.getDataPromise(
      DATA_PROMISE_KEYS.PAGE_FOLLOWING_STATE,
      DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.PAGE_FOLLOWING_STATE],
      [spaceCode, pageCode],
    );
  }

  getPagePermissions(spaceCode, pageId) {
    const handler = (...args) => (async () => {
      await Promise.all([
        this.getSession(),
        this.getSessionSpacePermissions(),
        this.getCategorisedSpaces(),
      ]);

      const space = store.getters['spaces/byCode'](spaceCode);

      if (!space) {
        return Promise.resolve();
      }

      if (!store.getters['session/canEditSpace'](space.id)) {
        return Promise.resolve();
      }

      if (!store.getters['features/canUsePagePrivacy']) {
        return Promise.resolve();
      }

      return DATA_PROMISE_HANDLERS[DATA_PROMISE_KEYS.PAGE_PERMISSIONS](...args);
    })();

    return this.getDataPromise(
      DATA_PROMISE_KEYS.PAGE_PERMISSIONS,
      handler,
      [spaceCode, pageId],
    );
  }
  // End Page Level Gets
}

export default new SpacesService();
