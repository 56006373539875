import store from '@/store';

export const DATA_PROMISE_KEYS = Object.freeze({
  ACTIVE_SPACE_PERMISSION: 'active-space-permission',
  API_KEYS: 'api-keys',
  APPS: 'apps',
  CATEGORISED_SPACES: 'categorised-spaces',
  COLLABORATORS: 'collaborators',
  COMMENTS: 'comments',
  COMPANIES: 'companies',
  EMOJIS: 'emojis',
  INLINE_COMMENTS: 'inline-comments',
  INSTALLATION_STATS: 'installation-stats',
  INSTALLATION: 'installation',
  LAUNCHPAD_INFO: 'launchpad-info',
  NOTIFICATIONS: 'notifications',
  PAGE_FOLLOWING_STATE: 'page-following-state',
  PAGE_LINKS: 'page-links',
  PAGE_PERMISSIONS: 'page-permissions',
  PAGE_SHARE_STATE: 'page-share-state',
  PAGE_TEMPLATES_GALLERY: 'page-templates-global',
  PAGE_TEMPLATES: 'page-templates',
  PAGE: 'page',
  REQUIRED_READING_STATS: 'required-reading-stats',
  REQUIRED_READING_STATUS: 'required-reading-status',
  REQUIRED_READING: 'required-reading',
  SESSION_SPACE_PERMISSIONS: 'session-space-permissions',
  SESSION: 'session',
  SPACE_ACCESS_REQUESTS: 'space-access-requests',
  SPACE_ATTACHMENTS: 'space-attachments',
  SPACE_FOLLOWING_STATE: 'space-following-state',
  SPACE_OPTIONS: 'space-options',
  SPACE_PAGES: 'space-pages',
  SPACE_PERMISSIONS: 'space-permissions',
  SPACE: 'space',
  SPACES: 'spaces',
  STARRED_PAGES: 'starred-pages',
  STARRED_SPACES: 'starred-spaces',
  TAGS: 'tags',
  TRASHED_SPACE_PAGES: 'trashed-space-pages',
  USERS: 'users',
});

/* eslint-disable arrow-body-style */
export const DATA_PROMISE_HANDLERS = Object.freeze({
  [DATA_PROMISE_KEYS.SESSION]: () => {
    return store.dispatch('session/fetch');
  },
  [DATA_PROMISE_KEYS.SESSION_SPACE_PERMISSIONS]: () => {
    return store.dispatch('session/fetchSpacePermissions');
  },
  [DATA_PROMISE_KEYS.API_KEYS]: () => {
    return store.dispatch('apiKeys/fetchApiKeys');
  },
  [DATA_PROMISE_KEYS.INSTALLATION]: () => {
    return store.dispatch('installation/fetch');
  },
  [DATA_PROMISE_KEYS.USERS]: () => {
    return store.dispatch('users/fetchList');
  },
  [DATA_PROMISE_KEYS.COMPANIES]: () => {
    return store.dispatch('companies/fetchList');
  },
  [DATA_PROMISE_KEYS.SPACES]: () => {
    return store.dispatch('spaces/getPageOfSpaces');
  },
  [DATA_PROMISE_KEYS.STARRED_SPACES]: () => {
    return store.dispatch('starred/fetchStarredSpaces');
  },
  [DATA_PROMISE_KEYS.STARRED_PAGES]: () => {
    return store.dispatch('starred/fetchStarredPages');
  },
  [DATA_PROMISE_KEYS.LAUNCHPAD_INFO]: () => {
    return store.dispatch('launchpad/fetchInfo');
  },
  [DATA_PROMISE_KEYS.PAGE_LINKS]: () => {
    return store.dispatch('ui/links/fetch');
  },
  [DATA_PROMISE_KEYS.TAGS]: () => {
    return store.dispatch('tags/fetchList');
  },
  [DATA_PROMISE_KEYS.EMOJIS]: () => {
    return store.dispatch('emojis/parseEmojiData');
  },
  [DATA_PROMISE_KEYS.REQUIRED_READING]: () => {
    return store.dispatch('requiredReading/fetch');
  },
  [DATA_PROMISE_KEYS.REQUIRED_READING_STATS]: (spaceId, pageId) => {
    return store.dispatch('requiredReadingInfo/fetch', {
      customUrlFnArgs: [spaceId, pageId],
    });
  },
  [DATA_PROMISE_KEYS.NOTIFICATIONS]: () => {
    return store.dispatch('notifications/fetchPageOfNotifications');
  },
  [DATA_PROMISE_KEYS.PAGE_TEMPLATES]: () => {
    return store.dispatch('pageTemplates/fetchPageTemplates');
  },
  [DATA_PROMISE_KEYS.PAGE_TEMPLATES_GALLERY]: () => {
    return store.dispatch('templateGallery/fetchList');
  },
  [DATA_PROMISE_KEYS.INSTALLATION_STATS]: () => {
    return store.dispatch('installationStats/fetch');
  },
  [DATA_PROMISE_KEYS.APPS]: () => {
    return store.dispatch('apps/loadApps');
  },
  [DATA_PROMISE_KEYS.CATEGORISED_SPACES]: () => {
    return store.dispatch('spaceCategories/fetchCategoriesWithSpaces');
  },
  [DATA_PROMISE_KEYS.SPACE]: (spaceCode) => {
    return store.dispatch('spaces/fetchSingle', { id: spaceCode }, { root: true });
  },
  [DATA_PROMISE_KEYS.SPACE_PAGES]: (spaceCode) => {
    return store.dispatch('activeSpacePages/fetch', {
      customUrlFnArgs: [spaceCode],
    }, { root: true });
  },
  [DATA_PROMISE_KEYS.TRASHED_SPACE_PAGES]: (spaceCode) => {
    return store.dispatch('trashedSpacePages/fetch', {
      customUrlFnArgs: [spaceCode],
    }, { root: true });
  },
  [DATA_PROMISE_KEYS.SPACE_ACCESS_REQUESTS]: (spaceCode) => {
    return store.dispatch('accessRequests/fetchList', {
      customUrlFnArgs: [spaceCode],
    }, { root: true });
  },
  [DATA_PROMISE_KEYS.SPACE_ATTACHMENTS]: (spaceCode, pageSize, pageOffset) => {
    return store.dispatch('spaceAttachments/fetchPageOfSpaceAttachments', {
      customUrlFnArgs: [spaceCode],
      pageSize,
      pageOffset,
      filter: '[objectType]=attachment',
    }, { root: true });
  },
  [DATA_PROMISE_KEYS.SPACE_FOLLOWING_STATE]: (spaceCode) => {
    return store.dispatch('following/setSpaceFollowingState', {
      spaceId: spaceCode, fetch: true,
    }, { root: true });
  },
  [DATA_PROMISE_KEYS.ACTIVE_SPACE_PERMISSION]: (spaceId) => {
    return store.dispatch('navigation/setActiveSpacePermission', { id: spaceId }, { root: true });
  },
  [DATA_PROMISE_KEYS.SPACE_PERMISSIONS]: (spaceId) => {
    return store.dispatch('spacePermissions/fetchList', {
      customUrlFnArgs: [spaceId],
    }, { root: true });
  },
  [DATA_PROMISE_KEYS.PAGE]: (spaceCode, pageCode) => {
    return store.dispatch('pages/fetchSingle', {
      customUrlFnArgs: [spaceCode],
      id: pageCode,
      config: {
        params: {
          include: '',
        },
      },
    }, { root: true });
  },
  [DATA_PROMISE_KEYS.COLLABORATORS]: (spaceCode, pageCode) => {
    return store.dispatch('collaborators/fetch', {
      customUrlFnArgs: [pageCode, spaceCode],
    }, { root: true });
  },
  [DATA_PROMISE_KEYS.COMMENTS]: (spaceCode, pageCode) => {
    return store.dispatch('comments/fetchList', {
      customUrlFnArgs: [spaceCode, pageCode],
    }, { root: true });
  },
  [DATA_PROMISE_KEYS.INLINE_COMMENTS]: (spaceCode, pageCode) => {
    return store.dispatch('inlineComments/fetchList', {
      customUrlFnArgs: [spaceCode, pageCode],
    }, { root: true });
  },
  [DATA_PROMISE_KEYS.PAGE_SHARE_STATE]: (spaceCode, pageCode) => {
    return store.dispatch('pageShare/fetch', {
      customUrlFnArgs: [spaceCode, pageCode],
    }, { root: true });
  },
  [DATA_PROMISE_KEYS.PAGE_PERMISSIONS]: (spaceCode, pageCode) => {
    return store.dispatch('activePagePermissions/fetchList', {
      customUrlFnArgs: [spaceCode, pageCode],
    }, { root: true });
  },
  [DATA_PROMISE_KEYS.PAGE_FOLLOWING_STATE]: (spaceCode, pageCode) => {
    return store.dispatch('following/setPageFollowingState', {
      spaceId: spaceCode,
      pageId: pageCode,
      fetch: true,
    }, { root: true });
  },
  [DATA_PROMISE_KEYS.REQUIRED_READING_STATUS]: (spaceCode, pageCode) => {
    return store.dispatch('requiredReadingStatus/fetch', {
      customUrlFnArgs: [spaceCode, pageCode],
    }, { root: true });
  },
});

