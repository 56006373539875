import { mapState } from 'vuex';
import { debounce } from 'lodash-es';

import AnimatedEllipsis from '@widgets/AnimatedEllipsis';

// @vue/component
export default {
  components: {
    AnimatedEllipsis,
  },
  data() {
    return {
      showDraftSavedMsg: false,
      showIsSavingMsg: false,
      otConnectionErrorMsg: 'We are having trouble establishing your collaborative editing session. Changes made to this document will not be seen by collaborators. Also your changes will not be saved if you close the editor without publishing.',
    };
  },
  computed: {
    ...mapState('ui/spacesEditor', ['hasPendingSync', 'hasOtConnectionError']),
  },
  methods: {
    // set showDraftSavedMsg true for a number of milliseconds
    flashDraftSavedMsg(duration) {
      this.showDraftSavedMsg = true;
      setTimeout(() => {
        this.showDraftSavedMsg = false;
      }, duration);
    },
    // Setter for showIsSavingMsg.
    // This decouples the UI from the actual OT sync state.
    // Debouncing by 500ms with leading=true ensures the indicator
    // will not flicker on screen.
    /* eslint-disable-next-line */
    setShowIsSavingMsg: debounce(function (value) {
      this.showIsSavingMsg = value;

      // if no longer saving draft, flash "draft saved" msg for a second
      if (!value) {
        this.flashDraftSavedMsg(1000);
        return;
      }
      // if saving, we make sure to hide draft indicator
      // in case it still displaying from previous flash
      this.showDraftSavedMsg = false;
    }, 500, { leading: true }),
  },
  watch: {
    hasPendingSync: {
      handler(newValue) {
        this.setShowIsSavingMsg(newValue);
      },
    },
  },
};
