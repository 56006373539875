import Vue from 'vue';

import { createStaticCrudModule } from '@/utils/vuex';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import cnameHelper from '@/utils/helpers/CNameHelper';
import { canUserEdit } from '@/utils/access-control';
import REQUIRED_READING_STATUS from '@/utils/constants/requiredReadingStatuses';

import spacesService from '@/api/spacesService';

import store from '..';

export { REQUIRED_READING_STATUS };

/**
 * This module uses /status.json and fetches/updates the required reading
 * "read" status for the current user only
 */
export default createStaticCrudModule({
  only: ['FETCH', 'REPLACE'],
  property: 'requiredReading',
  customUrlFn(spaceId = store.getters['navigation/activeSpaceId'], pageId = store.getters['navigation/activePageId']) {
    if (!spaceId || !pageId) {
      throw new Error('Unable to construct URL. Missing required parameter(s)');
    }

    return buildApiUrl({
      resource: `requiredreading/spaces/${spaceId}/pages/${pageId}/status.json`,
      apiVersion: 'v1',
    });
  },
  onFetchStart(state) {
    state.entity = {};
  },
  getters: {
    // eslint-disable-next-line no-unused-vars
    isPageRequiredReadingForCurrentUser(state, getters, rootState, rootGetters) {
      return (
        rootGetters['navigation/activePage']
        && rootGetters['navigation/activePage'].isRequiredReading
        && getters.get.state
        && getters.get.state.toLowerCase() !== 'not required'
      );
    },

    isLapsed(state, getters) {
      return getters.isPageRequiredReadingForCurrentUser
        && getters.get
        && getters.get.state === REQUIRED_READING_STATUS.LAPSED;
    },

    isRead(state, getters) {
      return getters.isPageRequiredReadingForCurrentUser
        && getters.get
        && getters.get.state === REQUIRED_READING_STATUS.READ;
    },

    isUnread(state, getters) {
      return getters.isPageRequiredReadingForCurrentUser
        && getters.get
        && getters.get.state === REQUIRED_READING_STATUS.UNREAD;
    },

    isReadVersionSameAsLatestVersion(state, getters) {
      return getters.get.readVersion === getters.get.latestVersion;
    },
  },
  onReplaceSuccess(state) {
    const readStatus = state && state.entity && state.entity.state;

    store.dispatch('requiredReading/fetch');

    if (canUserEdit()) {
      spacesService.getRequiredReadingStats(
        store.getters['navigation/activeSpace'].id,
        store.getters['navigation/activePage'].id,
      );
    }

    if (Vue.$ga) {
      Vue.$ga.event({
        eventCategory: 'required-reading',
        eventAction: `page-marked-as-${readStatus}`,
        eventLabel: (() => [
          cnameHelper(),
          store.getters['navigation/activeSpace'].title,
          store.getters['navigation/activePage'].title,
        ]
          .filter((a) => a)
          .join(' | '))(),
        eventValue: 1,
      });
    }
  },
});
