import { faAnchor } from '@fortawesome/pro-regular-svg-icons/faAnchor';
import { faAlignCenter } from '@fortawesome/pro-regular-svg-icons/faAlignCenter';
import { faAlignJustify } from '@fortawesome/pro-regular-svg-icons/faAlignJustify';
import { faAlignLeft } from '@fortawesome/pro-regular-svg-icons/faAlignLeft';
import { faAlignRight } from '@fortawesome/pro-regular-svg-icons/faAlignRight';
import { faBold } from '@fortawesome/pro-regular-svg-icons/faBold';
import { faClipboardList } from '@fortawesome/pro-regular-svg-icons/faClipboardList';
import { faCode } from '@fortawesome/pro-regular-svg-icons/faCode';
import { faFileCode } from '@fortawesome/pro-regular-svg-icons/faFileCode';
import { faExpandAlt } from '@fortawesome/pro-regular-svg-icons/faExpandAlt';
import { faH1 } from '@fortawesome/pro-regular-svg-icons/faH1';
import { faH2 } from '@fortawesome/pro-regular-svg-icons/faH2';
import { faH3 } from '@fortawesome/pro-regular-svg-icons/faH3';
import { faH4 } from '@fortawesome/pro-regular-svg-icons/faH4';
import { faImage } from '@fortawesome/pro-regular-svg-icons/faImage';
import { faIndent } from '@fortawesome/pro-regular-svg-icons/faIndent';
import { faItalic } from '@fortawesome/pro-regular-svg-icons/faItalic';
import { faLink } from '@fortawesome/pro-regular-svg-icons/faLink';
import { faListAlt } from '@fortawesome/pro-regular-svg-icons/faListAlt';
import { faUnlink } from '@fortawesome/pro-regular-svg-icons/faUnlink';
import { faListOl } from '@fortawesome/pro-regular-svg-icons/faListOl';
import { faListUl } from '@fortawesome/pro-regular-svg-icons/faListUl';
import { faParagraph } from '@fortawesome/pro-regular-svg-icons/faParagraph';
import { faOutdent } from '@fortawesome/pro-regular-svg-icons/faOutdent';
import { faRedo } from '@fortawesome/pro-regular-svg-icons/faRedo';
import { faStrikethrough } from '@fortawesome/pro-regular-svg-icons/faStrikethrough';
import { faUnderline } from '@fortawesome/pro-regular-svg-icons/faUnderline';
import { faUndo } from '@fortawesome/pro-regular-svg-icons/faUndo';
import { faVideo } from '@fortawesome/pro-regular-svg-icons/faVideo';
import { faFont } from '@fortawesome/pro-regular-svg-icons/faFont';
import { faHighlighter } from '@fortawesome/pro-regular-svg-icons/faHighlighter';
import { faQuoteLeft } from '@fortawesome/pro-regular-svg-icons/faQuoteLeft';

export default [
  faAnchor,
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faBold,
  faClipboardList,
  faCode,
  faFileCode,
  faExpandAlt,
  faH1,
  faH2,
  faH3,
  faH4,
  faImage,
  faIndent,
  faItalic,
  faLink,
  faUnlink,
  faListAlt,
  faListOl,
  faListUl,
  faOutdent,
  faParagraph,
  faRedo,
  faStrikethrough,
  faUnderline,
  faUndo,
  faVideo,
  faFont,
  faHighlighter,
  faQuoteLeft,
];
