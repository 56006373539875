import { isPlainObject, isString, includes, extend } from 'lodash-es';
import isValidId from '@/utils/helpers/isValidId';

import supportedIncludedFields from './supportedIncludedFields';

/* eslint-disable no-use-before-define, no-param-reassign */

/**
 * Used to populate detected 'included' fields within a response from the Teamwork Unified API.
 * Any 'linked resources' detected in the response data with an associated 'include' will be
 * decorated. A 'linked resource' is an object with ONLY 'type' and 'id' properties
 *
 * @param {Object} response
 * @param {Object|Array} response.data
 * @param {String} response.config.params.include
 * @param {Object} included
 */
export default (response, included) => {
  if (!isPlainObject(included) || !Object.keys(included).length) {
    return;
  }

  if (!isPlainObject(response) || (!Array.isArray(response.data) && !isPlainObject(response.data))) {
    return;
  }

  let definedIncludes = response.config
    && response.config.params
    && isString(response.config.params.include)
    && response.config.params.include.split(',');

  if (!Array.isArray(definedIncludes) || !(definedIncludes.length)) {
    return;
  }

  decorateIncludes(included, definedIncludes);

  // After includes are decorated, we need to filter any 'nested' includes i.e. ones that are
  // decorated already so we're only worred about root-level includes when decorating the data
  // itself. Convert to a map for faster lookups
  definedIncludes = definedIncludes.reduce((result, include) => {
    if (include.split('.').length === 1) {
      result[include] = true;
    }
    return result;
  }, {});

  response.data = handleIncludedFields(response.data, included, definedIncludes);
};

const handleIncludedFields = (data, included, definedIncludes = {}) => {
  if (!isPlainObject(included) || !Object.keys(included).length) {
    return data;
  }

  if (!isPlainObject(definedIncludes) || !Object.keys(definedIncludes).length) {
    return data;
  }

  if (Array.isArray(data)) {
    return handleIncludedFieldsForArray(data, included, definedIncludes);
  }

  if (isPlainObject(data)) {
    return handleIncludedFieldsForObject(data, included, definedIncludes);
  }

  return data;
};

const handleIncludedFieldsForArray = (a, included, definedIncludes = {}) => {
  if (!Array.isArray(a)) {
    return a;
  }

  if (!isPlainObject(definedIncludes) || !Object.keys(definedIncludes).length) {
    return a;
  }

  return a.map((v) => handleIncludedFields(v, included, definedIncludes));
};

const handleIncludedFieldsForObject = (o, included, definedIncludes = {}) => {
  if (!isPlainObject(o)) {
    return o;
  }

  if (!isPlainObject(definedIncludes) || !Object.keys(definedIncludes).length) {
    return o;
  }

  // Check if the object itself is an included field
  if (isLinkedResource(o)) {
    if (hasAssociatedInclude(o, included)) {
      if (definedIncludes[o.type]) {
        o = extend({}, included[o.type][o.id]);
      }
    }

    return o;
  }

  // Otherwise iterate the object entries
  return Object.entries(o)
    .reduce((result, [k, v]) => {
      result[k] = handleIncludedFields(v, included, definedIncludes);
      return result;
    }, o);
};

const hasAssociatedInclude = ({ id, type }, included) => {
  if (!isPlainObject(included) || !Object.keys(included).length) {
    return false;
  }

  return isPlainObject(included)
    && included[type]
    && included[type][id];
};

const isLinkedResource = (o) => isPlainObject(o)
  && Object.keys(o).length === 2
  && Object.prototype.hasOwnProperty.call(o, 'id')
  && Object.prototype.hasOwnProperty.call(o, 'type')
  && isString(o.type)
  && includes(supportedIncludedFields, o.type)
  && isValidId(o.id);

const decorateIncludes = (included, definedIncludes) => {
  if (!Array.isArray(definedIncludes) || !(definedIncludes.length)) {
    return;
  }

  definedIncludes.forEach((definedInclude) => {
    const includeLevels = definedInclude.split('.');

    if (includeLevels.length <= 1) {
      return;
    }

    const reversed = includeLevels.reverse();

    for (let i = 0, j = reversed.length; i < j; i += 1) {
      const currentLevel = reversed[i];
      const nextLevel = reversed[i + 1];

      if (!nextLevel) {
        return;
      }

      if (!isPlainObject(included[nextLevel])) {
        return;
      }

      Object.entries(included[nextLevel]).forEach(([k, v]) => {
        included[nextLevel][k] = handleIncludedFields(v, included, { [currentLevel]: true });
      });
    }
  });
};
