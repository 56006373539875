import SPACE_PAGE_ACTIONS from '@/utils/constants/spacePageActions';
import { PERMISSION_LEVELS } from '@/utils/constants/spacePermissions';
import USER_ACCESS_ERRORS from '@/utils/constants/userAccessErrors';
import { ROUTE_NAMES } from '@/router/routes';

import store from '../../store';

export const isEditRoute = (routeName) => routeName.params
  && routeName.params.action
  && routeName.params.action === SPACE_PAGE_ACTIONS.EDIT;

export const isEditorOnlyRoute = (routeName) => routeName.name === ROUTE_NAMES.SPACE_SETTINGS_ORGANIZE;

export const isSpaceManagerOnlyRoute = (routeName) => {
  if (
    routeName.name === 'spaceOptions'
    || routeName.name === 'spacePermissions'
  ) {
    return true;
  }

  return false;
};

export const pageIsInTrash = () => store.getters['navigation/isActivePageInTrash'];

export const hasActiveSpacePermission = (requiredPermissionLevel) => {
  const userPermissionLevel = store.getters['navigation/activeSpacePermission'];

  if (!userPermissionLevel) {
    return false;
  }

  let permitted = false;

  if (!Object.values(PERMISSION_LEVELS).includes(requiredPermissionLevel)) {
    throw new Error(`'${requiredPermissionLevel}' is not a valid permission level`);
  }

  if (!Object.values(PERMISSION_LEVELS).includes(userPermissionLevel)) {
    throw new Error(`'${userPermissionLevel}' is not a valid permission level`);
  }

  if (requiredPermissionLevel === userPermissionLevel) {
    permitted = true;
  }

  return permitted;
};

export const hasActivePagePermission = (requiredRole) => hasActiveSpacePermission(requiredRole);

export const canUserEdit = () =>
  hasActiveSpacePermission(PERMISSION_LEVELS.EDIT)
  || hasActiveSpacePermission(PERMISSION_LEVELS.MANAGE);

export const canUserAccessRoute = (targetRoute) => {
  const permit = { granted: false, error: null };
  const isPageEditRoute = isEditRoute(targetRoute);

  if (isSpaceManagerOnlyRoute(targetRoute)
  && !hasActiveSpacePermission(PERMISSION_LEVELS.MANAGE)) {
    permit.error = USER_ACCESS_ERRORS.PERMISSION;
    return permit;
  }

  if (isEditorOnlyRoute(targetRoute) || isPageEditRoute) {
    if (!canUserEdit()) {
      permit.error = USER_ACCESS_ERRORS.PERMISSION;
      return permit;
    }

    if (isPageEditRoute && pageIsInTrash()) {
      permit.error = USER_ACCESS_ERRORS.PAGE_IN_TRASH;
      return permit;
    }
  }

  permit.granted = true;
  return permit;
};

export default hasActiveSpacePermission;
