import Vue from 'vue';
import { isNull, isObject, isUndefined } from 'lodash-es';

import { createCrudModule } from '@/utils/vuex';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import spaceConstants from '@/utils/constants/spaceConstants';
import isValidId from '@/utils/helpers/isValidId';
import { RESOURCE_TYPES } from '@/utils/constants/reactions';

import { logInfo } from '@/utils/helpers/logger.utility';
import store from '..';

const prepareData = (data) => {
  if (!isObject(data)) {
    return;
  }

  if (
    !isUndefined(data.parentId)
    && (
      (
        (isValidId(data.parentId) && data.parentId < 1)
        || (!isValidId(data.parentId) && !isNull(data.parentId))
      )
    )
  ) {
    /* eslint-disable-next-line no-param-reassign */
    data.parentId = null;
  }
};

export default createCrudModule({
  only: ['FETCH_SINGLE', 'CREATE', 'UPDATE'],
  socketEvents: ['UPDATE'],
  resource: 'page',
  customUrlFn(id, spaceId) {
    // Default Space Home Page will call homepage.json
    // as we will no longer have homePageId on active Space
    // I think Mark is proposing changing the endpoint to home.json
    // but currently it is homepage.json
    if (id === spaceConstants.defaultPageCode) {
      return buildApiUrl({
        resource: `spaces/${spaceId}/${id}page`,
      });
    }

    return buildApiUrl({
      resource: id ? `spaces/${spaceId}/pages/${id}` : `spaces/${spaceId}/pages`,
    });
  },
  state: {
    updatedPageAvailableFromSocket: false,
  },
  getters: {
    getActiveHomePage: (state) => (spaceId) => {
      const key = Object.keys(state.entities)
        .find((page) => state.entities[page].isHomePage
         && state.entities[page].space.id === spaceId);

      return state.entities[key];
    },
  },
  mutations: {
    remove(_state, id) {
      if (!_state.entities[id]) {
        return;
      }

      Vue.delete(_state.entities, id);
    },
    setUpdatedPageAvailableFromSocket(state, hasUpdate) {
      state.updatedPageAvailableFromSocket = hasUpdate;
    },
  },
  actions: {
    // Note: the event is related to reverting a page to a previous version (PVH)
    handlePageRestoredEvent(_, socketData) {
      const { data: { page: { id: pageId = null } } } = socketData;

      if (
        pageId
        && store.getters['ui/spacePage/canPublishPage']
        && store.getters['navigation/isEditingSpacePage']
        && store.getters['navigation/activePageId'] === pageId
      ) {
        store.commit('ui/spacePage/setCanPublishPage', false);
        store.dispatch('alerts/showAlert', {
          type: 'warning',
          message: 'This page has been restored, you can no longer publish any changes',
          isPersistent: true,
        });

        store.subscribe((mutation) => {
          if (mutation.type === 'route/ROUTE_CHANGED') {
            // This hard reload is used as we don't support sockets for page.updated
            // If the user was to close the editor from the invalid state they'd see the
            // previous content which could lead to confusion. Here is the task to add
            // this socket support in, then this could be changed.
            // https://digitalcrew.teamwork.com/#/tasks/15600023
            window.location.href = `/spaces${mutation.payload.to.fullPath}`;
          }
        });
      }
    },

    handlePageTrashedEvent({ dispatch, rootGetters }, socketData) {
      const { data: { page: { id: pageId = null } } } = socketData;

      if (!pageId) {
        return;
      }

      const isCurrentlyResyncing = store.state.spaceTrash.isResyncing;
      const hasAlreadyResynced = store.getters['trashedSpacePages/getPageById'](pageId);
      if (isCurrentlyResyncing || hasAlreadyResynced) {
        return;
      }

      dispatch('spaceTrash/resyncData', {
        spaceId: rootGetters['navigation/activeSpaceId'],
        pageId,
      }, { root: true });

      store.commit('ui/spacePage/setCanPublishPage', false);

      if (rootGetters['modals/hasOpenModal']) {
        dispatch('modals/closeAllModals', {}, { root: true });
      }

      const alertMessage = rootGetters['navigation/isEditingSpacePage']
        ? 'This page has been moved to trash, you can no longer publish any changes'
        : 'This page has been moved to trash, you can no longer make any changes';

      dispatch('alerts/showAlert', {
        type: 'warning',
        message: alertMessage,
        isPersistent: true,
      }, { root: true });
    },

  },
  onFetchSingleSuccess(state, response) {
    // allow enough time for the editor to be torn down,
    // vue seems to optimise this is this happens too quickly
    setTimeout(() => {
      store.commit('navigation/setIsFlushingEditorOnPageCodeChange', false);
    }, 100);
    store.commit('reactions/setMetaForResource', {
      resourceType: RESOURCE_TYPES.PAGE,
      resourceId: response.data.id,
      meta: response.data.reactions,
    });

    store.commit('pages/setUpdatedPageAvailableFromSocket', false);
  },
  onFetchSingleError() {
    store.commit('navigation/setIsFlushingEditorOnPageCodeChange', false);
  },
  onCreateSuccess(state, response) {
    // allow enough time for the editor to be torn down,
    // vue seems to optimise this is this happens too quickly
    setTimeout(() => {
      store.commit('navigation/setIsFlushingEditorOnPageCodeChange', false);
    }, 100);
    // refetch page links
    store.dispatch('ui/links/addNewPageLink', {
      pageId: response.data.id,
      pageTitle: response.data.title,
      pageCode: response.data.slug,
      spaceCode: store.getters['spaces/byId'](response.data.space.id).code,
    });

    // If page is being created from within template settings, just return
    if (!store.getters['navigation/activeSpace']) {
      return;
    }

    // Note: we need to re-fetch our Space pages
    store.dispatch('activeSpacePages/fetch', {}, { root: true });

    if (Vue.$ga) {
      Vue.$ga.event({
        eventCategory: 'pages',
        eventAction: 'created',
        eventLabel: store.getters['navigation/activeSpace'].title,
        eventValue: 1,
      });
    }
  },
  onCreateError() {
    // allow enough time for the editor to be torn down,
    // vue seems to optimise this is this happens too quickly
    setTimeout(() => {
      store.commit('navigation/setIsFlushingEditorOnPageCodeChange', false);
    }, 100);
  },

  onUpdateSuccess(state, response) {
    // Note: we need to re-fetch our Space pages
    store.dispatch('activeSpacePages/fetch', {}, { root: true });

    // mutate title in page links after update
    store.dispatch('ui/links/updatePageTitle',
      {
        pageId: response.data.id,
        title: response.data.title,
      });

    // mutate space code in page links after page move
    store.dispatch('ui/links/updateSpaceCode',
      {
        pageId: response.data.id,
        spaceCode: store.getters['spaces/byId'](response.data.space.id).code,
      });

    // clear updatedPageAvailableFromSocket (ie hide banner)
    store.commit('pages/setUpdatedPageAvailableFromSocket', false);

    if (Vue.$ga) {
      Vue.$ga.event({
        eventCategory: 'pages',
        eventAction: 'updated',
        eventLabel: store.getters['navigation/activeSpace'].title,
        eventValue: 1,
      });
    }
  },

  onCreateStart(state, payload) {
    // We need to tear down the editor any time we get a page
    // This is to ensure OT reinstantiates at the correct mongo document
    store.commit('navigation/setIsFlushingEditorOnPageCodeChange', true);
    store.commit('collaborators/resetCollaborators', true);
    if (!isObject(payload.params)) {
      return;
    }

    prepareData(payload.params.data);
  },

  onUpdateStart(state, payload) {
    if (!isObject(payload.params)) {
      return;
    }

    prepareData(payload.params.data);
  },

  onReplaceStart(state, payload) {
    if (!isObject(payload.params)) {
      return;
    }

    prepareData(payload.params.data);
  },

  onSocketDataReceived(socketData) {
    const { data } = socketData;
    switch (socketData.operation) {
      case 'page.updated.trashed':
        store.dispatch('pages/handlePageTrashedEvent', socketData);
        store.dispatch('socket/clearPageRoomSocketData');
        break;
      case 'page.updated.reverted':
        // need to check if page content has changes because tag and banner img changes trigger updated event too
        if (store.getters['navigation/activePage'].contentRevision !== data.page.contentRevision) {
          store.commit('pages/setUpdatedPageAvailableFromSocket', true);
        }
        store.dispatch('pages/handlePageRestoredEvent', socketData);
        break;
      case 'page.updated':
        // need to check if page content has changes because tag and banner img changes also trigger updated event
        if (store.getters['navigation/activePage'].contentRevision !== data.page.contentRevision) {
          store.commit('pages/setUpdatedPageAvailableFromSocket', true);
          store.commit('ui/spacePage/setNewVersionAlertDismissed', false);
        }
        break;
      default:
        logInfo(`unrecognised socket operation: ${socketData.operation}`);
        break;
    }
  },
  onUpdateViaSocketSuccess() {
    store.commit('pages/setUpdatedPageAvailableFromSocket', false);
  },
});
