export const pageEditorId = 'page-editor';
export const templateEditorId = 'template-editor';

const EDITOR_TYPES = {
  PAGE: 'page',
  TEMPLATE: 'template',
};

if (Object.freeze) {
  Object.freeze(EDITOR_TYPES);
}

export { EDITOR_TYPES };
