/* eslint-disable no-underscore-dangle */
import createPopper from '@/utils/popper/createPopper';

// @vue/component
export default {
  props: {
    reference: {
      type: Object,
      default: () => { },
      required: false,
    },
    config: {
      type: Object,
      default: () => ({
        placement: 'top',
        modifiers: [
          {
            name: 'flip',
            options: {
              altBoundary: true,
              padding: 40,
            },
          },
          {
            name: 'preventOverflow',
            options: {
              altBoundary: true,
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, 200],
            },
          },
          {
            name: 'arrow',
            options: {
              element: '[data-popper-arrow]',
            },
          },
        ],
      }),
    },
  },
  data() {
    return {
      popper: null,
      referenceElement: this.reference,
      popperConfig: this.config,
    };
  },
  methods: {
    destroy() {
      if (this.popper && (typeof this.popper.destroy) === 'function') {
        this.popper.destroy();
      }
    },
  },
  mounted() {
    if (this.reference) {
      this.popper = createPopper(this.reference, this.$el, this.popperConfig);
      this.$store.dispatch('ui/popover/setPopoverState', { id: this._uid, isOpen: true }, { root: true });
    }
  },
  beforeDestroy() {
    this.destroy();

    this.$store.dispatch('ui/popover/setPopoverState', { id: this._uid, isOpen: undefined }, { root: true });
  },
  watch: {
    referenceElement() {
      if (this.popper !== null) {
        this.popper.destroy();
      }

      this.popper = createPopper(this.referenceElement, this.$el, this.popperConfig);
    },
    reference() {
      this.referenceElement = this.reference;
    },
  },
};
