// List of allowed embed nodes that inline comments can be added to.
// This option allows us to add our widgets as allowed elements for
// inline comments; example ['TW-SPACES-INFO-PANEL']
// OT-Client link: https://github.com/Teamwork/ot-client/blob/master/docs/index.md#editor-settings
// Embed Nodes; https://github.com/Teamwork/ot-client/blob/master/src/util/inlineComments.js#L30
export const OT_INLINE_COMMENT_ELEMENTS = Object.freeze([]);

export default {
  OT_INLINE_COMMENT_ELEMENTS,
};
