export default {
  namespaced: true,
  state: {
    tooltipId: null,
    title: '',
    popperConfig: {},
    colour: null,
  },
  mutations: {
    setTooltipId(state, refEl) {
      state.tooltipId = refEl;
    },
    setTitle(state, title) {
      state.title = title;
    },
    setPopperConfig(state, popperConfig) {
      state.popperConfig = popperConfig;
    },
    setColour(state, colour) {
      state.colour = colour;
    },
  },
  actions: {
    activateTooltip({ commit }, {
      tooltipId, tooltipColour, title, popperConfig,
    }) {
      commit('setTooltipId', tooltipId);
      commit('setTitle', title);
      commit('setPopperConfig', popperConfig);
      commit('setColour', tooltipColour);
    },
    deactivateTooltip({ commit }) {
      commit('setTooltipId', null);
      commit('setTitle', '');
      commit('setPopperConfig', {});
      commit('setColour', null);
    },
  },
};
