/* eslint-disable */
import { isDevEnvironment } from '@/utils/helpers/environment.utility';
import { isIE } from '@/utils/helpers/device';
import INTERNAL_INSTALLATION_IDS from '@/utils/constants/internalInstallationIds';

const doNotRecord = (userId, { installationId, siteOwner, createdAt, isImpersonating }) => {
  if (INTERNAL_INSTALLATION_IDS.includes(installationId) || isDevEnvironment) {
    return true;
  }

  var d = new Date(createdAt);
  d.setDate(d.getDate() + 30);

  if (isIE() || userId != siteOwner || new Date() > d || isImpersonating === true) {
    return true;
  }

  return false;
};

const initFullstory = (userId, { installationId, siteOwner, createdAt, isImpersonating }) => {
  // don't use fullstory on sunbeam
  if (doNotRecord(userId, { installationId, siteOwner, createdAt, isImpersonating })) {
    return;
  }

  try {
    window._fs_debug = false;
    window._fs_host = 'fullstory.com';
    window._fs_org = 'FAVB3';
    window._fs_namespace = 'FS';
    (function (m, n, e, t, l, o, g, y) {
      if (e in m) { if (m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].'); } return; }
      g = m[e] = function (a, b) { g.q ? g.q.push([a, b]) : g._api(a, b); }; g.q = [];
      o = n.createElement(t); o.async = 1; o.src = `https://${_fs_host}/s/fs.js`;
      y = n.getElementsByTagName(t)[0]; y.parentNode.insertBefore(o, y);
      g.identify = function (i, v) { g(l, { uid: i }); if (v) g(l, v); }; g.setUserVars = function (v) { g(l, v); }; g.event = function (i, v) { g('event', { n: i, p: v }); };
      g.shutdown = function () { g('rec', !1); }; g.restart = function () { g('rec', !0); };
      g.consent = function (a) { g('consent', !arguments.length || a); };
      g.identifyAccount = function (i, v) { o = 'account'; v = v || {}; v.acctId = i; g(o, v); };
      g.clearUserCookie = function () { };
    }(window, document, window._fs_namespace, 'script', 'user'));
    FS.identify(userId, { installationId, siteOwner, createdAt, isImpersonating });
  } catch (e) {
    console.error(e);
  }
};

export default initFullstory;



/*
Example of using this
    if (this.isAuthenticated) {
      initFullstory(
        this.$store.getters['session/userId'],
        {
          displayName: this.$options.filters.fullName(
            this.$store.getters['session/user'],
          ),
          email: this.$store.getters['session/user'].email,
          userId: this.$store.getters['session/userId'],
          region: this.$store.getters['installation/region'],
          installationId: this.$store.getters['installation/id'],
          installationName: this.$store.getters['installation/name'],
          siteOwner: this.$store.getters['installation/appOwner/id'],
          companyName: this.$store.getters['session/companyName'],
          createdAt:  this.$store.getters['installation/createdAt']
          product: 'Spaces',
        },
      );
    }
*/
