import 'babel-polyfill';
import 'document-register-element';
import 'intersection-observer';

import '@/utils/polyfills';
import '@/utils/font-awesome';
import '@/registerServiceWorker';

import initGlobals from '@/utils/appStart/initGlobals';
import initRouterSync from '@/utils/appStart/initRouterSync';
import initSmoothScroll from '@/utils/appStart/initSmoothScroll';
import initVueUse from '@/utils/appStart/initVueUse';
import startSpaces from '@/utils/appStart/startSpaces';
import initEditorComponents from '@/utils/appStart/initEditorComponents';

// kick off the scroll polyfill!
initSmoothScroll();
// Vue.use(...)
initVueUse();
// Link Router and Vuex store
initRouterSync();
// Init Global Directive, Filters, Components
initGlobals();
// up front work required to use both internal
// and external editor components in the Spaces Editor
initEditorComponents();
// Mount Vue
startSpaces();
