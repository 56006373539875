import { mapState } from 'vuex';

// @vue/component
export default {
  computed: {
    ...mapState('ui/activeEditor', {
      activeSelectionFormats: 'activeSelectionFormats',
    }),

    activeIconProps() {
      return [
        'far',
        `align-${this.currentAlignment}`,
      ];
    },

    currentAlignment() {
      const current = this.activeSelectionFormats.find((format) => format.includes('align'));
      if (current) {
        return current.slice(5);
      }
      return 'left';
    },
  },
};
