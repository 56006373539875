import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';

import BasePopover from '@widgets/Popover/BasePopover';
import PageHeaderNavButton from '@widgets/PageHeaderNav/PageHeaderNavButton';
import FormRadio from '@widgets/Form/FormRadio';
import { PERMISSION_LEVELS } from '@/utils/constants/pagePermissions';
import { REQUESTER_TYPES } from '@/utils/constants/spacePermissions';
import { PAGE_PRIVACY_OPTIONS } from '@/utils/constants/spacePage';
import { logError } from '@/utils/helpers/logger.utility';
import { FEATURE_KEYS } from '@/store/modules/features';

// @vue/component
export default {
  components: {
    BasePopover,
    PageHeaderNavButton,
    FormRadio,
  },
  data() {
    return {
      isProcessingPermissions: false,
    };
  },
  computed: {
    ...mapState('ui/inlineComments', ['shouldShowCommentsGutter']),

    ...mapGetters('session', {
      currentUserId: 'userId',
    }),

    ...mapGetters('navigation', [
      'activeSpaceId',
      'activePageId',
      'activePage',
      'isActivePageARootLevelPage',
      'isActivePageInTrash',
    ]),

    ...mapGetters('activePagePermissions', [
      'isPrivateForOnlyMe',
      'isPrivateForSpecificMembers',
      'isPrivateForEditors',
      'privacySetting',
    ]),

    isFetchingPagePermissions() {
      return !!this.$store.state.activePagePermissions.isFetchingList;
    },

    selectedOption() {
      return this.optionsMap[this.privacySetting];
    },

    disabled() {
      return this.loading
      || this.isActivePageInTrash
      || (
        this.isPrivate
        && !this.isActivePageARootLevelPage
      );
    },

    loading() {
      return this.isFetchingPagePermissions
        || this.isProcessingPermissions;
    },

    isPrivate() {
      return this.selectedOption
        && this.selectedOption.value
        && this.selectedOption.value !== PAGE_PRIVACY_OPTIONS.ALL_MEMBERS;
    },

    rootParentPage() {
      // Note: there's a possible issue where whereby not all getters have recomputed and
      // 'isActivePageInTrash' is true but the page doesn't actually exist in the
      // 'trashedSpacePages' module (as it's in the process of being restored)
      if (
        this.$store.getters['trashedSpacePages/isLoading']
        || this.$store.getters['activeSpacePages/isLoading']
      ) {
        return null;
      }

      const storeModule = (
        this.$store.getters['trashedSpacePages/getPageById'](this.activePageId)
      ) ? 'trashedSpacePages' : 'activeSpacePages';

      if (this.$store.getters[`${storeModule}/isPageARootLevelPage`](this.activePageId)) {
        return null;
      }

      if (this.$store.getters[`${storeModule}/isLoading`]) {
        return null;
      }

      return this.$store.getters[`${storeModule}/getRootParentPageForPage`](this.activePageId);
    },

    rootParentPageTitle() {
      return this.rootParentPage && this.rootParentPage.title;
    },

    tooltip() {
      if (!this.isPrivate || !this.selectedOption.value) {
        return 'Privacy settings';
      }

      switch (this.selectedOption.value) {
        case PAGE_PRIVACY_OPTIONS.EDITORS: {
          return this.rootParentPageTitle
            ? `Only editors and managers can see this page, based on '${this.rootParentPageTitle}' privacy settings`
            : 'Only space editors and managers can see this page';
        }
        case PAGE_PRIVACY_OPTIONS.ME: {
          return this.rootParentPageTitle
            ? `Only you can see this page, based on '${this.rootParentPageTitle}' privacy settings`
            : 'Only you can see this page';
        }
        case PAGE_PRIVACY_OPTIONS.SPECIFIC_MEMBERS: {
          return this.rootParentPageTitle
            ? `Only specific space members can see this page, based on '${this.rootParentPageTitle}' privacy settings`
            : 'Only specific space members can see this page';
        }
        default: {
          return 'Privacy settings';
        }
      }
    },

    permissions() {
      return this.$store.getters['activePagePermissions/list'];
    },

    optionsMap() {
      function getLabel(value) {
        switch (value) {
          case PAGE_PRIVACY_OPTIONS.EDITORS: {
            return 'Editors and managers';
          }
          case PAGE_PRIVACY_OPTIONS.ME: {
            return 'Only me';
          }
          case PAGE_PRIVACY_OPTIONS.SPECIFIC_MEMBERS: {
            return 'Specific members';
          }
          case PAGE_PRIVACY_OPTIONS.ALL_MEMBERS:
          default: {
            return 'Space members';
          }
        }
      }

      function getDescription(value) {
        switch (value) {
          case PAGE_PRIVACY_OPTIONS.EDITORS: {
            return 'Space members with edit or manage permission';
          }
          case PAGE_PRIVACY_OPTIONS.ME: {
            return 'Only visible to you';
          }
          case PAGE_PRIVACY_OPTIONS.SPECIFIC_MEMBERS: {
            return 'Only show to some space members';
          }
          case PAGE_PRIVACY_OPTIONS.ALL_MEMBERS:
          default: {
            return 'All members of this space';
          }
        }
      }

      function getIcon(value) {
        switch (value) {
          case PAGE_PRIVACY_OPTIONS.EDITORS: {
            return ['far', 'user-edit'];
          }
          case PAGE_PRIVACY_OPTIONS.ME: {
            return ['far', 'lock-alt'];
          }
          case PAGE_PRIVACY_OPTIONS.SPECIFIC_MEMBERS: {
            return ['far', 'user-friends'];
          }
          case PAGE_PRIVACY_OPTIONS.ALL_MEMBERS:
          default: {
            return ['fas', 'globe-europe'];
          }
        }
      }

      const options = Object.values(PAGE_PRIVACY_OPTIONS);

      return options.reduce((accumulator, currentValue) => {
        // eslint-disable-next-line no-param-reassign
        accumulator[currentValue] = {
          label: getLabel(currentValue),
          description: getDescription(currentValue),
          icon: getIcon(currentValue),
          value: currentValue,
        };

        return accumulator;
      }, {});
    },

    options() {
      return Object.values(this.optionsMap);
    },

    isPublic() {
      return !this.isPrivate;
    },

    selectedCompanyIds() {
      return [...this.permissions.reduce((result, permission) => {
        if (permission.requester && permission.requester.type === REQUESTER_TYPES.COMPANY) {
          result.add(Number(permission.requester.id));
        }

        return result;
      }, new Set([]))];
    },

    selectedTeamIds() {
      return [...this.permissions.reduce((result, permission) => {
        if (permission.requester && permission.requester.type === REQUESTER_TYPES.TEAM) {
          result.add(Number(permission.requester.id));
        }

        return result;
      }, new Set([]))];
    },

    selectedUserIds() {
      return [...this.permissions.reduce((result, permission) => {
        if (permission.requester && permission.requester.type === REQUESTER_TYPES.USER) {
          result.add(Number(permission.requester.id));
        }

        return result;
      }, new Set([]))];
    },
  },
  methods: {
    ...mapActions('alerts', ['showAlert']),

    ...mapActions('modals', ['openModal']),

    ...mapActions('activePagePermissions', {
      createPagePermission: 'create',
      destroyAllPagePermissions: 'destroyAll',
    }),

    ...mapActions('activeSpacePages', {
      fetchActiveSpacePages: 'fetch',
    }),

    ...mapActions('trashedSpacePages', {
      fetchTrashedSpacePages: 'fetch',
    }),

    getOptionForValue(value) {
      return this.optionsMap[value];
    },

    refreshNecessaryData() {
      // Note: we need to fetch both the active page and the active Space's pages again
      return Promise.all([
        this.$store.dispatch('pages/fetchSingle', {
          customUrlFnArgs: [this.activeSpaceId],
          id: this.activePageId,
        }, { root: true }),
        this.fetchActiveSpacePages({ customUrlFnArgs: [this.activeSpaceId] }),
        this.fetchTrashedSpacePages({ customUrlFnArgs: [this.activeSpaceId] }),
      ]);
    },

    async makePagePublic() {
      let canContinue = false;

      try {
        await this.destroyAllPagePermissions({
          customUrlFnArgs: [this.activeSpaceId, this.activePageId],
          isOptimistic: true,
        });

        canContinue = true;
      } catch (error) {
        if (error && error.response && error.response.status && error.response.status === 422) {
          // Treat a 422 Unprocessable Entity error as a 200 OK. Changing the privacy setting is
          // a two-step process - we need to delete all existing permission entries before
          // applying the new permission setting
          canContinue = true;
          this.$store.commit('activePagePermissions/destroyAllSuccess');
        }
      }

      if (!canContinue) {
        throw new Error('Could not delete existing permissions in preparation for creation of new page permission record(s)');
      }

      await this.refreshNecessaryData();

      this.showAlert({ type: 'success', message: 'Page privacy setting updated' });
    },

    async makePagePrivateToEditorsAndManagers() {
      let canContinue = false;

      try {
        await this.destroyAllPagePermissions({
          customUrlFnArgs: [this.activeSpaceId, this.activePageId],
          isOptimistic: true,
        });

        canContinue = true;
      } catch (error) {
        if (error && error.response && error.response.status && error.response.status === 422) {
          // Treat a 422 Unprocessable Entity error as a 200 OK. Changing the privacy setting is
          // a two-step process - we need to delete all existing permission entries before
          // applying the new permission setting
          canContinue = true;
          this.$store.commit('activePagePermissions/destroyAllSuccess');
        }
      }

      if (!canContinue) {
        throw new Error('Could not delete existing permissions in preparation for creation of new page permission record(s)');
      }

      await this.createPagePermission({
        customUrlFnArgs: [this.activeSpaceId, this.activePageId],
        isOptimistic: true,
        data: [{
          requester: null,
          role: PERMISSION_LEVELS.EDIT,
        }],
      });

      await this.refreshNecessaryData();

      this.showAlert({ type: 'success', message: 'Page privacy setting updated' });
    },

    async makePagePrivateForSpecificMembers(selectedCompanyIds, selectedTeamIds, selectedUserIds) {
      let canContinue = false;

      try {
        await this.destroyAllPagePermissions({
          customUrlFnArgs: [this.activeSpaceId, this.activePageId],
          isOptimistic: true,
        });

        canContinue = true;
      } catch (error) {
        if (error && error.response && error.response.status && error.response.status === 422) {
          // Treat a 422 Unprocessable Entity error as a 200 OK. Changing the privacy setting is
          // a two-step process - we need to delete all existing permission entries before
          // applying the new permission setting
          canContinue = true;
          this.$store.commit('activePagePermissions/destroyAllSuccess');
        }
      }

      if (!canContinue) {
        throw new Error('Could not delete existing permissions in preparation for creation of new page permission record(s)');
      }

      if (!selectedUserIds.includes(this.currentUserId)) {
        selectedUserIds.push(Number(this.currentUserId));
      }

      await this.createPagePermission({
        customUrlFnArgs: [this.activeSpaceId, this.activePageId],
        isOptimistic: true,
        data: [
          ...selectedCompanyIds.reduce((result, id) => {
            result.push({
              requester: {
                id,
                type: REQUESTER_TYPES.COMPANY,
                meta: {
                  level: PERMISSION_LEVELS.EDIT,
                },
              },
              role: null,
            });
            return result;
          }, []),
          ...selectedTeamIds.reduce((result, id) => {
            result.push({
              requester: {
                id,
                type: REQUESTER_TYPES.TEAM,
                meta: {
                  level: PERMISSION_LEVELS.EDIT,
                },
              },
              role: null,
            });
            return result;
          }, []),
          ...selectedUserIds.reduce((result, id) => {
            result.push({
              requester: {
                id,
                type: REQUESTER_TYPES.USER,
                meta: {
                  level: PERMISSION_LEVELS.EDIT,
                },
              },
              role: null,
            });
            return result;
          }, []),
        ],
      });

      await this.refreshNecessaryData();

      this.showAlert({ type: 'success', message: 'Page privacy setting updated' });
    },

    async makePagePrivateToOnlyMe() {
      let canContinue = false;

      try {
        await this.destroyAllPagePermissions({
          customUrlFnArgs: [this.activeSpaceId, this.activePageId],
          isOptimistic: true,
        });

        canContinue = true;
      } catch (error) {
        if (error && error.response && error.response.status && error.response.status === 422) {
          // Treat a 422 Unprocessable Entity error as a 200 OK. Changing the privacy setting is
          // a two-step process - we need to delete all existing permission entries before
          // applying the new permission setting
          canContinue = true;
          this.$store.commit('activePagePermissions/destroyAllSuccess');
        }
      }

      if (!canContinue) {
        throw new Error('Could not delete existing permissions in preparation for creation of new page permission record(s)');
      }

      await this.createPagePermission({
        customUrlFnArgs: [this.activeSpaceId, this.activePageId],
        isOptimistic: true,
        data: [{
          requester: {
            id: this.currentUserId,
            type: REQUESTER_TYPES.USER,
            meta: {
              level: PERMISSION_LEVELS.EDIT,
            },
          },
          role: null,
        }],
      });

      await this.refreshNecessaryData();

      this.showAlert({ type: 'success', message: 'Page privacy setting updated' });
    },

    onButtonClicked(event) {
      if (!this.$store.getters['features/canUsePagePrivacy']) {
        event.stopPropagation();
        this.$store.dispatch('features/triggerUpgradeModal', { feature: FEATURE_KEYS.PAGE_PRIVACY });
      }
    },

    async onOptionClicked(option, closeFn) {
      if (this.isProcessingPermissions) {
        return;
      }

      closeFn();

      if (
        option.value === this.selectedOption.value
        && this.selectedOption.value !== PAGE_PRIVACY_OPTIONS.SPECIFIC_MEMBERS
      ) {
        return;
      }

      try {
        switch (option.value) {
          case PAGE_PRIVACY_OPTIONS.ALL_MEMBERS: {
            this.isProcessingPermissions = true;

            await this.makePagePublic();

            if (Vue.$ga) {
              Vue.$ga.event({
                eventCategory: 'pages',
                eventAction: 'make-non-private',
                eventValue: 1,
              });
            }

            this.$nextTick(() => {
              this.isProcessingPermissions = false;
            });
            break;
          }
          case PAGE_PRIVACY_OPTIONS.EDITORS: {
            this.isProcessingPermissions = true;

            await this.makePagePrivateToEditorsAndManagers();

            if (Vue.$ga) {
              Vue.$ga.event({
                eventCategory: 'pages',
                eventAction: `make-private-${PAGE_PRIVACY_OPTIONS.EDITORS}`,
                eventValue: 1,
              });
            }

            this.$nextTick(() => {
              this.isProcessingPermissions = false;
            });
            break;
          }
          case PAGE_PRIVACY_OPTIONS.ME: {
            this.isProcessingPermissions = true;

            await this.makePagePrivateToOnlyMe();

            if (Vue.$ga) {
              Vue.$ga.event({
                eventCategory: 'pages',
                eventAction: `make-private-${PAGE_PRIVACY_OPTIONS.ME}`,
                eventValue: 1,
              });
            }

            this.$nextTick(() => {
              this.isProcessingPermissions = false;
            });
            break;
          }
          case PAGE_PRIVACY_OPTIONS.SPECIFIC_MEMBERS: {
            this.openModal({
              activeModal: 'space-members-selector',
              modalProps: {
                headerTitle: 'Specific members',
                submitButtonText: 'Save changes',
                initialSelectedCompanyIds: this.selectedCompanyIds,
                initialSelectedTeamIds: this.selectedTeamIds,
                initialSelectedUserIds: this.selectedUserIds,
                onSubmit: async (selectedCompanyIds, selectedTeamIds, selectedUserIds) => {
                  this.isProcessingPermissions = true;

                  try {
                    await this.makePagePrivateForSpecificMembers(
                      selectedCompanyIds,
                      selectedTeamIds,
                      selectedUserIds,
                    );

                    if (Vue.$ga) {
                      Vue.$ga.event({
                        eventCategory: 'pages',
                        eventAction: `make-private-${PAGE_PRIVACY_OPTIONS.SPECIFIC_MEMBERS}`,
                        eventValue: 1,
                      });
                    }

                    this.$nextTick(() => {
                      this.isProcessingPermissions = false;
                    });
                  } catch (error) {
                    this.showAlert({ type: 'error', message: 'Couldn\'t update the privacy setting for this page' });
                  }

                  this.$nextTick(() => {
                    this.isProcessingPermissions = false;
                  });
                },
              },
            });
            break;
          }
          default: {
            break;
          }
        }
      } catch (error) {
        logError(error);
        this.showAlert({ type: 'error', message: 'Couldn\'t update the privacy setting for this page' });
        this.isProcessingPermissions = false;
      }
    },
  },
};
