import { createCrudModule } from '@/utils/vuex';
import RESOURCES from '@/utils/constants/resources';

export default createCrudModule({
  only: ['FETCH_LIST', 'FETCH_SINGLE', 'CREATE', 'UPDATE', 'DESTROY'],
  resource: RESOURCES.WEBHOOK_EVENT,
  getters: {
    eventsByCategory(state) {
      return Object.values(state.entities)
        .reduce((result, webhook) => {
          const key = webhook.key.split('.')[0];

          if (!result[key]) {
          // eslint-disable-next-line no-param-reassign
            result[key] = [];
          }

          result[key].push(webhook);

          return result;
        }, {});
    },
  },
});
