import store from '@/store';
import isAppCompatibleBrowser from '@/utils/helpers/isAppCompatibleBrowser';
import SPACE_PAGE_ACTIONS from '@/utils/constants/spacePageActions';
import { ROUTES, ROUTE_NAMES } from '@/router/routes';

export default [
  {
    name: ROUTE_NAMES.SPACE,
    path: '/:spaceCode',
    component: () => import(/* webpackChunkName: "app-layout" */ '@pages/AppLayout'),
    redirect: '/:spaceCode/page/home',
    props: true,
    children: [
      {
        path: 'page/:pageCode/:action?',
        name: ROUTE_NAMES.SPACE_PAGE,
        meta: {

        },
        props: {
          sidebar: () => ({
            dragSource: 'sidebar',
            scrollParentSelector: '.scroll-container .scroll-container__inner',
          }),
          content: true,
        },
        components: {
          sidebar: () => import(/* webpackChunkName: "space-page" */ '@sections/SpaceSidebar'),
          content: () => import(/* webpackChunkName: "space-page" */ '@sections/SpacePage'),
        },
        beforeEnter(to, from, next) {
          if (!store.getters['features/canUseRequiredReading'] && to.params.action === SPACE_PAGE_ACTIONS.DIFF) {
            next({ path: to.path.replace(`/${SPACE_PAGE_ACTIONS.DIFF}`, '') });
            return;
          }

          if (!isAppCompatibleBrowser() && to.params.action === SPACE_PAGE_ACTIONS.EDIT) {
            next({ path: to.path.replace(`/${SPACE_PAGE_ACTIONS.EDIT}`, '') });
            return;
          }

          next();
        },
      },
      {
        path: 'settings',
        name: ROUTE_NAMES.SPACE_SETTINGS,
        meta: {
          title: 'Space Settings',
        },
        props: {
          sidebar: () => ({
            dragSource: 'sidebar',
            scrollParentSelector: '.scroll-container .scroll-container__inner',
          }),
          content: true,
        },
        redirect: '/:spaceCode/settings/options',
        components: {
          sidebar: () => import(/* webpackChunkName: "space-page" */ '@sections/SpaceSettingsSidebar'),
          content: () => import(/* webpackChunkName: "space-settings" */ '@sections/SpaceSettings'),
        },
        children: [
          {
            path: 'options',
            name: ROUTE_NAMES.SPACE_SETTINGS_OPTIONS,
            component: () => import(/* webpackChunkName: "space-options" */'@sections/SpaceOptions'),
            meta: {
              title: ROUTES[ROUTE_NAMES.SPACE_SETTINGS_OPTIONS].title,
            },
          },
          {
            path: 'reorder',
            redirect: ROUTES[ROUTE_NAMES.SPACE_SETTINGS_ORGANIZE].path,
          },
          {
            path: 'organize',
            name: ROUTE_NAMES.SPACE_SETTINGS_ORGANIZE,
            meta: {
              title: ROUTES[ROUTE_NAMES.SPACE_SETTINGS_ORGANIZE].title,
            },
            props: {
              dragSource: 'organize',
              scrollParentSelector: '.p-space-settings__scroll-container',
            },
            component: () => import(/* webpackChunkName: "space-organize" */ '@sections/SpaceOrganize'),
          },
          {
            path: 'permissions',
            name: ROUTE_NAMES.SPACE_SETTINGS_PERMISSIONS,
            meta: {
              title: ROUTES[ROUTE_NAMES.SPACE_SETTINGS_PERMISSIONS].title,
            },
            component: () => import(/* webpackChunkName: "space-permissions" */'@sections/SpacePermissions'),
          },
          {
            path: 'trash',
            name: ROUTE_NAMES.SPACE_SETTINGS_TRASH,
            component: () => import(/* webpackChunkName: "space-trash" */ '@sections/SpaceTrash'),
            meta: {
              title: ROUTES[ROUTE_NAMES.SPACE_SETTINGS_TRASH].title,
            },
            props: true,
          },
          {
            path: 'activity',
            redirect: ROUTES[ROUTE_NAMES.SPACE_SETTINGS_INSIGHTS].path,
          },
          {
            path: 'insights',
            name: ROUTE_NAMES.SPACE_SETTINGS_INSIGHTS,
            component: () => import(/* webpackChunkName: "space-insights" */ '@sections/SpaceInsights'),
            meta: {
              title: ROUTES[ROUTE_NAMES.SPACE_SETTINGS_INSIGHTS].title,
            },
            props: true,
          },
        ],
      },
      {
        path: 'attachments',
        name: ROUTE_NAMES.SPACE_ATTACHMENTS,
        meta: {
          title: ROUTES[ROUTE_NAMES.SPACE_ATTACHMENTS].title,
        },
        props: { sidebar: true, content: true },
        components: {
          sidebar: () => import(/* webpackChunkName: "space-page" */ '@sections/SpaceSettingsSidebar'),
          content: () => import(/* webpackChunkName: "space-attachments" */'@sections/SpaceAttachments'),
        },
      },
    ],
  },
];
