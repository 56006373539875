import { uniqBy } from 'lodash-es';

export default (autoCompleteItems, query) => {
  const lowerCaseQuery = query.toLowerCase();

  function shortcodeBeginsWithQuery(item) {
    return item.shortcode && item.shortcode.startsWith(lowerCaseQuery);
  }

  function displayNameEqualsQuery(item) {
    return item.displayName && item.displayName.toLowerCase() === lowerCaseQuery;
  }

  function displayNameBeginsWithQuery(item) {
    return item.displayName && item.displayName.toLowerCase().startsWith(lowerCaseQuery);
  }

  function anyWordInDisplayNameBeginsWithQuery(item) {
    const displayNameWords = item.displayName.toLowerCase().split(' ');
    const wordThatBeginsWithQuery = (word) => word.startsWith(lowerCaseQuery);
    return !!displayNameWords.find(wordThatBeginsWithQuery);
  }

  function displayNameContainsQuery(item) {
    return item.displayName.includes(lowerCaseQuery);
  }

  return [
    ...uniqBy([
      ...autoCompleteItems.filter(shortcodeBeginsWithQuery),
      ...autoCompleteItems.filter(displayNameEqualsQuery),
      ...autoCompleteItems.filter(displayNameBeginsWithQuery),
      ...autoCompleteItems.filter(anyWordInDisplayNameBeginsWithQuery),
      ...autoCompleteItems.filter(displayNameContainsQuery),
    ], 'name'),
  ];
};
