export default Object.freeze({
  ACCESS_REQUEST_PLURAL: 'accessRequests',
  ACCESS_REQUEST: 'accessRequest',
  CATEGORY_PLURAL: 'categories',
  CATEGORY: 'category',
  COMPANY_PLURAL: 'companies',
  COMPANY: 'company',
  INSTALLATION_PLURAL: 'installations',
  INSTALLATION: 'installation',
  PAGE_PLURAL: 'pages',
  PAGE: 'page',
  PERMISSION_PLURAL: 'permissions',
  PERMISSION: 'permission',
  SPACE_PLURAL: 'spaces',
  SPACE: 'space',
  USER_PLURAL: 'users',
  USER: 'user',
  WEBHOOK_EVENT_PLURAL: 'webhook-events',
  WEBHOOK_EVENT: 'webhook-event',
  WEBHOOK_REQUEST_PLURAL: 'webhook-requests',
  WEBHOOK_REQUEST: 'webhook-request',
});
