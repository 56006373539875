import { isPlainObject, isUndefined, pick } from 'lodash-es';

import { createCrudModule } from '@/utils/vuex';
import RESOURCES from '@/utils/constants/resources';

export const REQUIRED_CREATE_FIELDS = Object.freeze(['url', 'token', 'events']);
export const REQUIRED_UPDATE_FIELDS = Object.freeze(['id', 'url', 'token', 'events', 'isActive']);

export default createCrudModule({
  only: ['FETCH_LIST', 'FETCH_SINGLE', 'CREATE', 'REPLACE', 'DESTROY'],
  resource: 'webhook',
  prepareConfig(config) {
    if (!isPlainObject(config)) {
      /* eslint-disable no-param-reassign */
      config = {};
    }

    if (!isPlainObject(config.params)) {
      config.params = {};
    }

    config.params.include = [
      RESOURCES.WEBHOOK_EVENT_PLURAL,
      RESOURCES.WEBHOOK_REQUEST_PLURAL,
    ].join(',');

    return config;
  },
  actions: {
    duplicateWebhook({ dispatch, getters }, id) {
      const webhook = getters.byId(id);

      if (!webhook) {
        throw new Error(`Webhook (ID: ${id}) does not exist in state`);
      }

      return dispatch('create', {
        data: pick(webhook, REQUIRED_CREATE_FIELDS),
      });
    },

    activateWebhook({ dispatch }, id) {
      return dispatch('updateWebhookState', { id, isActive: true });
    },

    deactivateWebhook({ dispatch }, id) {
      return dispatch('updateWebhookState', { id, isActive: false });
    },

    toggleWebhookState({ dispatch, getters }, id) {
      const webhook = getters.byId(id);

      if (!webhook) {
        throw new Error(`Webhook (ID: ${id}) does not exist in state`);
      }

      return dispatch('updateWebhookState', { id, isActive: !webhook.isActive });
    },

    updateWebhookState({ dispatch, getters }, { id, isActive }) {
      const webhook = getters.byId(id);

      if (!webhook) {
        throw new Error(`Webhook (ID: ${id}) does not exist in state`);
      }

      const data = Object.assign(pick(webhook, REQUIRED_UPDATE_FIELDS), {
        isActive,
      });

      return dispatch('replace', {
        id,
        data,
      });
    },
  },

  /**
   * The purpose of this override is to ensure that the 'events' field is mapped to the expected
   * format i.e. a list of 'linked resources'. This is the format that is initially returned from
   * the API, but we're decorating the 'events' before adding them to state, which causes their
   * format to be invalid when performing an update request. We also need to validate and ensure
   * all required fields are present in the update payload
   */
  onReplaceStart(state, payload) {
    if (!isPlainObject(payload.params)) {
      throw new Error('Cannot replace webhook - \'payload.params\' is missing or invalid');
    }

    if (!isPlainObject(payload.params.data)) {
      throw new Error('Cannot replace webhook - \'payload.params.data\' is missing or invalid');
    }

    const missingField = REQUIRED_UPDATE_FIELDS.find((field) => isUndefined(payload.params.data[field]));

    if (missingField) {
      throw new Error(`Cannot replace webhook - 'payload.params.data['${missingField}']' is missing or invalid`);
    }

    if (!Array.isArray(payload.params.data.events) || !payload.params.data.events.length) {
      throw new Error('Cannot replace webhook - \'payload.params.data.events\' is not a valid Array');
    }

    payload.params.data.events = payload.params.data.events.map((event) => ({
      id: Number(event.id),
      type: RESOURCES.WEBHOOK_EVENT_PLURAL,
    }));
  },
});
