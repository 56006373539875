import { isObject } from 'lodash-es';

import isValidId from '@/utils/helpers/isValidId';

/**
 * Create default getters and merge them with getters defined by a user.
 */
const createGetters = (config = {}) => ({
  get(state) {
    return state.entities;
  },

  /**
   * Return array of resources.
   */
  list(state) {
    return Object.values(state.entities);
  },

  /**
   * Get resource by id.
   */
  byId(state) {
    return (id) => (
      (isObject(state.entities) && isValidId(id) && state.entities[id.toString()]) || null
    );
  },

  /**
   * Return true if there is a ongoing request.
   */
  isLoading(state) {
    return !!(
      state.isFetchingList
      || state.isFetchingSingle
      || state.isCreating
      || state.isUpdating
      || state.isReplacing
      || state.isDestroying
      || state.isDestroyingAll
    );
  },

  /**
   * Get the current page of entities as dictated by the 'currentList' value
   */
  currentPageOfEntities(state) {
    return state.currentList.map((entityId) => state.entities[entityId.toString()] || null);
  },

  ...config.getters,
});

export default createGetters;
