import store from '@/store';
import axios from '@/utils/vuex/client';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';

const recordGlobalTemplateUsage = async (templateId) => {
  const installation = store.getters['installation/get'];

  await axios.post(buildApiUrl({
    resource: 'templates/metrics',
  }), {
    globalTemplate: {
      installationID: installation.id,
      templateID: templateId,
    },
  });
};

export default recordGlobalTemplateUsage;
