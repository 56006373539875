// Taken from https://github.com/theomessin/vue-chat-scroll
/**
 * @name VueJS vChatScroll (vue-chat-scroll)
 * @description Monitors an element and scrolls to the bottom if a new child is added
 * @author Theodore Messinezis <theo@theomessin.com>
 * @file v-chat-scroll  directive definition
 */

const scrollToBottom = (el) => {
  /* eslint-disable-next-line no-param-reassign */
  el.scrollTop = el.scrollHeight;
};

const stickToBottom = {
  bind: (el, binding) => {
    let timeout;
    let scrolled = false;

    el.addEventListener('scroll', () => {
      if (timeout) window.clearTimeout(timeout);
      timeout = window.setTimeout(() => {
        scrolled = el.scrollTop + el.clientHeight + 1 < el.scrollHeight;
      }, 200);
    });

    (new MutationObserver((e) => {
      const config = binding.value || {};
      const pause = config.always === false && scrolled;
      if (pause || e[e.length - 1].addedNodes.length !== 1) return;
      scrollToBottom(el);
    })).observe(el, { childList: true, subtree: true });
  },
  inserted: scrollToBottom,
};

export default stickToBottom;
