import { isArray } from 'lodash-es';

const searchPageTree = (page, searchFn) => {
  let result = searchFn(page);

  if (result) {
    return result;
  }

  if (!isArray(page.childPages)) {
    return null;
  }

  page.childPages.forEach((el) => {
    if (!result) {
      result = searchPageTree(el, searchFn);
    }
  });

  return result;
};

export default searchPageTree;
