import { createAutocomplete } from '@teamwork/autocomplete-core';
import { createEditorAdapter } from '@teamwork/autocomplete-editor-contenteditable';
import { TwAutocomplete } from '@teamwork/autocomplete-ui-vue';
import searchAutoCompleteItems from '@/utils/editor/searchAutoCompleteItems';
import autocompleteItemsInitialOrder from '@/utils/editor/config/autocompleteItemsInitialOrder';
import { logError } from '@/utils/helpers/logger.utility';

// @vue/component
export default {
  inject: ['editor', 'editorType'],
  props: {
    scrollTop: {
      type: Number,
      default: 0,
    },
  },
  components: {
    TwAutocomplete,
  },
  computed: {
    autoCompleteItems() {
      const nameToComponent = (name) => {
        const comp = this.$store.getters['ui/editorComponents/insertableComponentByName'](name);
        if (!comp) {
          return undefined;
        }
        return comp;
      };

      const isInstalled = (component) => (component !== undefined);

      const isEnabledForCurrentEditorType = (editorComponent) => editorComponent.enabledEditors
        .includes(this.editorType);

      return autocompleteItemsInitialOrder
        .map(nameToComponent)
        .filter(isInstalled)
        .filter(isEnabledForCurrentEditorType)
        .concat(this.$store.getters['ui/editorComponents/devAppComponents']);
    },
  },

  methods: {
    // determines what triggers the autocomplete
    // in this case its any word-characters after a '/'
    match(textBeforeCaret, textAfterCaret) {
      // Match whitespace or the end of input.
      if (/^(?:$|\s)/.test(textAfterCaret)) {
        // Match / followed by word-characters after whitespace or the start of input.
        //
        // [\u00C0-\u1FFF\u2C00-\uD7FF\w] approximates a word character in any language.
        // Source: http://stackoverflow.com/questions/150033/regular-expression-to-match-non-english-characters#comment22322603_150078
        const match = /(?:^|\s)(\/[\u00C0-\u1FFF\u2C00-\uD7FF\w]*)$/.exec(textBeforeCaret);
        if (match) {
          return [match[1].length, 0];
        }
      }
      return [-1, -1];
    },

    load(query) {
      switch (query[0]) {
        case '/':
          return this.loadAutoCompleteItems(query.substring(1).toLowerCase());
        default:
          return [];
      }
    },

    loadAutoCompleteItems(query) {
      return searchAutoCompleteItems(this.autoCompleteItems, query);
    },

    accept(item) {
      this.autocomplete.editorAdapter.focus();
      this.autocomplete.replace('');
      this.autocomplete.clear();
      item.insertFn(this.editor.ref, this);
    },

    onError() {
      logError('SpacesEditorAutocomplete: Error', this.autocomplete.error);
    },

    onActive() {
      this.$emit('active', this.autocomplete.active);
    },
    initAutocomplete() {
      if (!this.editor.ref) {
        return;
      }

      const editorAdapter = createEditorAdapter(this.editor.ref.getBody());
      const autocomplete = createAutocomplete({
        editorAdapter,
        match: this.match,
        load: this.load,
        accept: this.accept,
      });
      autocomplete.on('error', this.onError);
      autocomplete.on('active', this.onActive);
      this.$refs.autocomplete.init(autocomplete);
      this.$once('hook:beforeDestroy', () => {
        autocomplete.destroy();
        editorAdapter.destroy();
      });
      Object.defineProperty(this, 'autocomplete', {
        get() {
          return autocomplete;
        },
      });
    },
  },

  mounted() {
    this.initAutocomplete();
  },

  watch: {
    scrollTop() {
      if (this.autocomplete.active) {
        this.autocomplete.match();
      }
    },
  },
};
