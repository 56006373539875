import { popperGenerator, defaultModifiers } from '@popperjs/core/lib/popper-lite';
import arrow from '@popperjs/core/lib/modifiers/arrow';
import flip from '@popperjs/core/lib/modifiers/flip';
import hide from '@popperjs/core/lib/modifiers/hide';
import offset from '@popperjs/core/lib/modifiers/offset';
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow';

export default popperGenerator({
  defaultModifiers: [...defaultModifiers, arrow, flip, hide, offset, preventOverflow],
});
