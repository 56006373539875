import Vue from 'vue';

import { DATA_PROMISE_KEYS } from '@/utils/constants/dataPromise';
import { ROUTE_NAMES } from '@/router/routes';
import router from '@/router';

export const STATES = Object.freeze({
  UNPROCESSED: 1,
  PROCESSING: 2,
  SUCCESS: 3,
  ERROR: 4,
});

export default {
  namespaced: true,
  state: {
    states: {

    },
  },
  mutations: {
    recordState(_state, { key, data }) {
      if (!Object.values(STATES).includes(data.state)) {
        throw new Error(`Could not record state for '${key}' - invalid 'state' ${data.state} provided`);
      }

      // eslint-disable-next-line no-param-reassign
      Vue.set(_state.states, key, data);
    },
  },
  getters: {
    getState: (_state) => (key) =>
      (_state.states && _state.states[key] && _state.states[key].state) || STATES.UNPROCESSED,

    hasDataLoaded: (_state, getters) => (key) => (getters.getState(key) === STATES.SUCCESS),

    hasAllSelectedDataLoaded: (_state, getters) => (keys) =>
      keys.every((k) => (getters.getState(k) === STATES.SUCCESS)),

    isDataLoading: (_state, getters) => (key) => (getters.getState(key) === STATES.PROCESSING),

    isAnyDataLoading: (_state, getters) => (keys) => keys.some((k) =>
      (getters.getState(k) === STATES.PROCESSING)),

    hasDataErrored: (_state, getters) => (key) => (getters.getState(key) === STATES.ERROR),

    hasAnySelectedDataErrored: (_state, getters) => (keys) => keys.some((k) =>
      (getters.getState(k) === STATES.ERROR)),

    getErrorState: (_state, getters) => (key) => (getters.hasDataErrored(key)
      && _state.states && _state.states[key] && _state.states[key].errorObject) || null,

    getAnyErrorState: (_state, getters) => (keys) => keys.find((k) =>
      (getters.getState(k) === STATES.ERROR)),

    shouldShowLoader: (_state, getters, rootState, rootGetters) => {
      // Note: we need to check the current route via the 'router' itself as it has the
      // 'matched' property detailing all ancestor routes
      const currentRoute = router.currentRoute;
      // ensure this gets re-evalutated on every route change
      const currentRouteState = rootState.route;

      if (rootGetters['ui/isPreloading'] && currentRouteState) {
        return true;
      }

      if (currentRoute.matched.find((route) => route.name === ROUTE_NAMES.HOME)) {
        return !getters.hasAllSelectedDataLoaded([
          DATA_PROMISE_KEYS.CATEGORISED_SPACES,
          DATA_PROMISE_KEYS.STARRED_SPACES,
          DATA_PROMISE_KEYS.USERS,
        ]);
      }

      if (currentRoute.name === ROUTE_NAMES.STARRED_PAGES) {
        return !getters.hasDataLoaded(DATA_PROMISE_KEYS.STARRED_PAGES);
      }

      if (currentRoute.matched.find((route) => route.name === ROUTE_NAMES.PEOPLE)) {
        return !getters.hasAllSelectedDataLoaded([
          DATA_PROMISE_KEYS.USERS,
          DATA_PROMISE_KEYS.COMPANIES,
        ]);
      }

      if (currentRoute.name === ROUTE_NAMES.REQUIRED_READING) {
        return !getters.hasAllSelectedDataLoaded([
          DATA_PROMISE_KEYS.REQUIRED_READING,
          DATA_PROMISE_KEYS.SESSION_SPACE_PERMISSIONS,
        ]);
      }

      if (currentRoute.name === ROUTE_NAMES.SPACE_SETTINGS_PERMISSIONS) {
        // Nothing special
      }

      if (currentRoute.name === ROUTE_NAMES.SPACE_ATTACHMENTS) {
        // Nothing special
      }

      return false;
    },

    shouldShowSectionLoader: (_state, getters, rootState) => {
      if (rootState.route.name === ROUTE_NAMES.SPACE_SETTINGS_PERMISSIONS) {
        return !getters.hasAllSelectedDataLoaded([
          DATA_PROMISE_KEYS.SPACE_PERMISSIONS,
          DATA_PROMISE_KEYS.ACTIVE_SPACE_PERMISSION,
          DATA_PROMISE_KEYS.USERS,
          DATA_PROMISE_KEYS.COMPANIES,
        ]);
      }

      if (rootState.route.name === ROUTE_NAMES.SPACE_SETTINGS_OPTIONS) {
        return !getters.hasDataLoaded(DATA_PROMISE_KEYS.CATEGORISED_SPACES);
      }

      if (rootState.route.name === ROUTE_NAMES.SPACE_ATTACHMENTS) {
        return !getters.hasDataLoaded(DATA_PROMISE_KEYS.SPACE_ATTACHMENTS);
      }

      return false;
    },

    spaceError: (state, getters) => {
      // Note: these are dictated by `fetchSpaceChangeDataForRoute`
      const errorKey = getters.getAnyErrorState([
        DATA_PROMISE_KEYS.ACTIVE_SPACE_PERMISSION,
        DATA_PROMISE_KEYS.SPACE,
        DATA_PROMISE_KEYS.SPACE_PAGES,
        DATA_PROMISE_KEYS.SPACE_FOLLOWING_STATE,
        DATA_PROMISE_KEYS.TRASHED_SPACE_PAGES,
      ]);

      if (errorKey) {
        return getters.getErrorState(errorKey);
      }

      return null;
    },

    spacePageError: (state, getters, rootState, rootGetters) => {
      // Note: we are currently only reacting to an error on the main 'page' endpoint unless
      // we're editing the page
      if (!rootGetters['navigation/isEditingSpacePage']) {
        const errorKey = getters.getAnyErrorState([
          DATA_PROMISE_KEYS.PAGE,
        ]);

        if (errorKey) {
          return getters.getErrorState(errorKey);
        }
      } else {
        // Note: we need to know if a user has 'edit' permission on the editor route.
        const spaceId = rootGetters['navigation/activeSpaceId'];
        if (!rootGetters['session/canEditSpace'](spaceId)) {
          return { status: 403 };
        }

        const errorKey = getters.getAnyErrorState([
          DATA_PROMISE_KEYS.PAGE,
          DATA_PROMISE_KEYS.INLINE_COMMENTS,
          DATA_PROMISE_KEYS.APPS,
        ]);

        if (errorKey) {
          return getters.getErrorState(errorKey);
        }
      }

      return null;
    },

    httpError: (_state, getters, rootState, rootGetters) => {
      let error = null;
      // Note: we need to check the current route via the 'router' itself as it has the
      // 'matched' property detailing all ancestor routes
      const currentRoute = router.currentRoute;
      const routeName = currentRoute.name;
      const isSpaceHome = rootState.route.params.pageCode === 'shay';

      // Global data is required for Spaces to function, regardless of route
      function checkForGlobalDataError() {
        const errorKey = getters.getAnyErrorState([
          DATA_PROMISE_KEYS.APPS,
          DATA_PROMISE_KEYS.CATEGORISED_SPACES,
          DATA_PROMISE_KEYS.COMPANIES,
          DATA_PROMISE_KEYS.EMOJIS,
          DATA_PROMISE_KEYS.INSTALLATION_STATS,
          DATA_PROMISE_KEYS.INSTALLATION,
          DATA_PROMISE_KEYS.LAUNCHPAD_INFO,
          DATA_PROMISE_KEYS.NOTIFICATIONS,
          DATA_PROMISE_KEYS.PAGE_LINKS,
          DATA_PROMISE_KEYS.PAGE_TEMPLATES_GALLERY,
          DATA_PROMISE_KEYS.PAGE_TEMPLATES,
          DATA_PROMISE_KEYS.REQUIRED_READING,
          DATA_PROMISE_KEYS.SESSION_SPACE_PERMISSIONS,
          DATA_PROMISE_KEYS.STARRED_SPACES,
          DATA_PROMISE_KEYS.TAGS,
          DATA_PROMISE_KEYS.USERS,
        ]);

        return errorKey ? getters.getErrorState(errorKey) : null;
      }

      const globalDataError = checkForGlobalDataError();
      if (globalDataError) {
        return globalDataError;
      }

      if (currentRoute.matched.find((route) => route.name === ROUTE_NAMES.HOME)) {
        const errorKey = getters.getAnyErrorState([
          DATA_PROMISE_KEYS.CATEGORISED_SPACES,
          DATA_PROMISE_KEYS.STARRED_SPACES,
          DATA_PROMISE_KEYS.USERS,
        ]);

        if (errorKey) {
          return getters.getErrorState(errorKey);
        }
      }

      if (routeName === ROUTE_NAMES.STARRED_PAGES) {
        error = getters.getErrorState(DATA_PROMISE_KEYS.STARRED_PAGES);
      }

      if (isSpaceHome || currentRoute.matched.find((route) => route.name === ROUTE_NAMES.PEOPLE)) {
        const errorKey = getters.getAnyErrorState([
          DATA_PROMISE_KEYS.USERS,
          DATA_PROMISE_KEYS.COMPANIES,
        ]);

        if (errorKey) {
          return getters.getErrorState(errorKey);
        }
      }

      if (routeName === ROUTE_NAMES.REQUIRED_READING) {
        error = getters.getErrorState(DATA_PROMISE_KEYS.REQUIRED_READING);
      }

      if (routeName === ROUTE_NAMES.TAGS) {
        error = getters.getErrorState(DATA_PROMISE_KEYS.TAGS);
      }

      if (routeName === ROUTE_NAMES.SETTINGS_SUBSCRIPTION) {
        error = getters.getErrorState(DATA_PROMISE_KEYS.SETTINGS_SUBSCRIPTION);
      }

      if (routeName === ROUTE_NAMES.SETTINGS_API_KEYS) {
        error = getters.getErrorState(DATA_PROMISE_KEYS.SETTINGS_API_KEYS);
      }

      const isSpaceRoute = !!router.currentRoute.matched.find((route) => route.name === ROUTE_NAMES.SPACE);
      if (isSpaceRoute) {
        if (getters.spaceError) {
          error = getters.spaceError;
        }
      }

      // Note: we need to know if a user has 'edit' permission on the editor route.
      if (rootGetters['navigation/isEditingSpacePage']) {
        const spaceId = rootGetters['navigation/activeSpaceId'];
        if (!rootGetters['session/canEditSpace'](spaceId)) {
          return { status: 403 };
        }

        const errorKey = getters.getAnyErrorState([
          DATA_PROMISE_KEYS.PAGE,
          DATA_PROMISE_KEYS.INLINE_COMMENTS,
        ]);

        if (errorKey) {
          return getters.getErrorState(errorKey);
        }
      }

      return error;
    },

    sectionHttpError: (state, getters, rootState, rootGetters) => {
      let error = null;
      const routeName = rootState.route.name;

      if (routeName === ROUTE_NAMES.SPACE_PAGE) {
        if (getters.spacePageError) {
          error = getters.spacePageError;
        }
      }

      const spaceId = rootGetters['navigation/activeSpaceId'];

      if (rootState.route.name === ROUTE_NAMES.SPACE_SETTINGS_OPTIONS) {
        if (!rootGetters['session/canManageSpace'](spaceId)) {
          return { status: 403 };
        }
      }

      if (rootState.route.name === ROUTE_NAMES.SPACE_SETTINGS_ORGANIZE) {
        if (!rootGetters['session/canEditSpace'](spaceId)) {
          return { status: 403 };
        }
      }

      if (rootState.route.name === ROUTE_NAMES.SPACE_SETTINGS_PERMISSIONS) {
        if (!rootGetters['session/canManageSpace'](spaceId)) {
          return { status: 403 };
        }

        const errorKey = getters.getAnyErrorState([
          DATA_PROMISE_KEYS.SPACE_PERMISSIONS,
          DATA_PROMISE_KEYS.SPACE_ACCESS_REQUESTS,
        ]);

        if (errorKey) {
          error = getters.getErrorState(errorKey);
        }
      }

      return error;
    },
  },
};
