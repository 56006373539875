import fullName from '@/utils/filters/fullName';

export default {
  namespaced: true,
  state: {
    sharingToken: '',
    guestEmail: '',
    guestFirstName: '',
    guestLastName: '',
  },
  getters: {
    hasGuestDetails: (state) => state.guestEmail && state.guestFirstName && state.guestLastName,
    guestFullName: (state) => fullName({
      firstname: state.guestFirstName,
      lastname: state.guestLastName,
    }),
    hasToken: (state) => !!state.sharingToken,
    isSharedSpacePage: (state, getters) => getters.hasToken,
  },
  mutations: {
    setSharingToken(state, value) {
      state.sharingToken = value;
    },

    setGuestEmail(state, value) {
      state.guestEmail = value;
    },

    setGuestFirstName(state, value) {
      state.guestFirstName = value;
    },

    setGuestLastName(state, value) {
      state.guestLastName = value;
    },
  },
  actions: {
    setGuestUserDetails({ commit }, { firstName, lastName, emailAddress }) {
      commit('setGuestEmail', emailAddress);
      commit('setGuestFirstName', firstName);
      commit('setGuestLastName', lastName);
    },
  },
};
