import MockAdapter from 'axios-mock-adapter';

// import example from './example';

export default function (axios) {
  const mock = new MockAdapter(axios);

  /* eslint-disable import/no-named-as-default-member */
  const entities = [
    // example,
  ];

  entities.forEach((setUpMocks) => {
    setUpMocks(mock);
  });

  // let any other requests pass through to the network
  mock.onAny().passThrough();
}
