import accessRequests from '@/store/modules/accessRequests';
import activePagePermissions from '@/store/modules/activePagePermissions';
import activeSpacePages from '@/store/modules/activeSpacePages';
import alerts from '@/store/modules/alerts';
import apiKeys from '@/store/modules/apiKeys';
import apps from '@/store/modules/apps';
import collaborators from '@/store/modules/collaborators';
import comments from '@/store/modules/comments';
import companies from '@/store/modules/companies';
import emojis from '@/store/modules/emojis';
import features from '@/store/modules/features';
import feedback from '@/store/modules/feedback';
import following from '@/store/modules/following';
import inlineComments from '@/store/modules/inlineComments';
import installation from '@/store/modules/installation';
import installationStats from '@/store/modules/installationStats';
import integrations from '@/store/modules/integrations';
import launchpad from '@/store/modules/launchpad';
import lightbox from '@/store/modules/lightbox';
import loading from '@/store/modules/loading';
import modals from '@/store/modules/modals';
import mentions from '@/store/modules/mentions';
import navigation from '@/store/modules/navigation';
import nextRequiredReadingItem from '@/store/modules/nextRequiredReadingItem';
import notifications from '@/store/modules/notifications';
import onboarding from '@/store/modules/onboarding';
import pageAttachments from '@/store/modules/pageAttachments';
import pageDiff from '@/store/modules/pageDiff';
import pages from '@/store/modules/pages';
import pageShare from '@/store/modules/pageShare';
import pageShareScopes from '@/store/modules/pageShareScopes';
import pageTemplates from '@/store/modules/pageTemplates';
import pageVersions from '@/store/modules/pageVersions';
import panel from '@/store/modules/panel';
import reactions from '@/store/modules/reactions';
import reactionTypes from '@/store/modules/reactionTypes';
import requiredReading from '@/store/modules/requiredReading';
import requiredReadingAssignment from '@/store/modules/requiredReadingAssignment';
import requiredReadingInfo from '@/store/modules/requiredReadingInfo';
import requiredReadingStatus from '@/store/modules/requiredReadingStatus';
import searchPreview from '@/store/modules/searchPreview';
import session from '@/store/modules/session';
import slidingDrawers from '@/store/modules/slidingDrawers';
import socket from '@/store/modules/socket';
import spaceAttachments from '@/store/modules/spaceAttachments';
import spaceCategories from '@/store/modules/spaceCategories';
import spaceOptions from '@/store/modules/spaceOptions';
import spacePages from '@/store/modules/spacePages';
import spacePermissions from '@/store/modules/spacePermissions';
import spaces from '@/store/modules/spaces';
import spaceTrash from '@/store/modules/spaceTrash';
import starred from '@/store/modules/starred';
import subscription from '@/store/modules/subscription';
import tags from '@/store/modules/tags';
import templateGallery from '@/store/modules/templateGallery';
import trashedSpacePages from '@/store/modules/trashedSpacePages';
import ui from '@/store/modules/ui/index';
import users from '@/store/modules/users';
import webhookEvents from '@/store/modules/webhookEvents';
import webhookRequests from '@/store/modules/webhookRequests';
import webhooks from '@/store/modules/webhooks';

export default {
  accessRequests,
  activePagePermissions,
  activeSpacePages,
  alerts,
  apiKeys,
  apps,
  collaborators,
  comments,
  companies,
  emojis,
  features,
  feedback,
  following,
  inlineComments,
  installation,
  installationStats,
  pageAttachments,
  integrations: {
    modules: integrations,
    namespaced: true,
  },
  launchpad,
  lightbox,
  loading,
  modals,
  mentions,
  navigation,
  nextRequiredReadingItem,
  notifications,
  onboarding,
  pageDiff,
  pages,
  pageShare,
  pageShareScopes,
  pageTemplates,
  pageVersions,
  panel,
  reactions,
  reactionTypes,
  requiredReading,
  requiredReadingAssignment,
  requiredReadingInfo,
  requiredReadingStatus,
  searchPreview,
  session,
  slidingDrawers,
  socket,
  spaceAttachments,
  spaceCategories,
  spaceOptions,
  spacePages,
  spacePermissions,
  spaces,
  spaceTrash,
  starred,
  subscription,
  tags,
  templateGallery,
  trashedSpacePages,
  ui,
  users,
  webhookEvents,
  webhookRequests,
  webhooks,
};
