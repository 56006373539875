import { isObject } from 'lodash-es';
import { createStaticCrudModule } from '@/utils/vuex';

import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import { REQUIRED_READING_STATUS } from '@/store/modules/requiredReadingStatus';

import store from '..';

/**
 * This module uses /users/{id}/reading.json to fetch the required reading list
 * for the specified user
 */
export default createStaticCrudModule({
  only: ['FETCH', 'CREATE', 'UPDATE'],
  property: 'reading',
  prepareConfig(config) {
    if (!isObject(config)) {
      /* eslint-disable no-param-reassign */
      config = {};
    }

    if (!isObject(config.params)) {
      config.params = {};
    }

    config.params.include = ['users', 'pages', 'spaces'].join(',');

    return config;
  },
  customUrlFn(userId) {
    if (!userId) {
      // If no user is passed through, assume it's the current user session
      userId = store.getters['session/userId'];
    }

    if (!userId) {
      throw new Error('Unable to construct URL. Missing required User ID parameter');
    }

    return buildApiUrl({
      resource: `users/${userId}/reading`,
      apiVersion: 'v0',
    });
  },
  getters: {
    getSpacesFilteredByTitle(state) {
      return ((title) => {
        const spaceIds = [];
        const spaces = [];

        Object.values(state.entity)
          .forEach((pageObj) => {
            // Push only non-duplicate spaces
            if (pageObj.space && !spaceIds.includes(pageObj.space.id)) {
              spaceIds.push(pageObj.space.id);
              spaces.push(pageObj.space);
            }
          });

        if (!title) {
          return spaces;
        }

        return Object.values(spaces)
          .filter((x) => x.title.toLowerCase().includes(title.toLowerCase()));
      });
    },

    hasCompletedRequiredReading: (state, getters) => getters['requiredReading/requiredReadingCount'] < 1,

    requiredReadingCount: (state, getters) => getters.getPages.filter(
      (page) => (
        page.state === REQUIRED_READING_STATUS.UNREAD
        || page.state === REQUIRED_READING_STATUS.LAPSED
      ),
    ).length,

    requiredReadingReadCount: (state, getters) => getters.getPages.filter((page) => page.state === 'read').length,

    getReadStateForPage: (state, getters) => (pageId) => {
      const page = getters.getPage(pageId);
      return (page && page.state) || REQUIRED_READING_STATUS.UNREAD;
    },

    getPage: (state, getters) => (pageId) => getters.getPageById(pageId) || null,

    // eslint-disable-next-line max-len
    getPageById: (state, getters) => (pageId) => getters.getPages.find((page) => page.pageId === pageId),

    getPages: (state) => Object.values(state.entity),

    /* eslint-disable arrow-body-style */
    getPagesFilteredByTitle: (state, getters) => (filterInput) => {
      return getters.getPages.filter((page) => {
        return page.pageTitle.toLowerCase().includes(filterInput.toLowerCase());
      });
    },

    /* eslint-disable arrow-body-style */
    getPagesBySpaceAndPageTitle: (state, getters) => (query) => {
      const matchingSpaces = getters.getSpacesFilteredByTitle(query);

      // if the query matches any space titles then return all pages for that space
      const allPagesForMatchingSpaces = matchingSpaces.reduce((result, space) => {
        return result.concat(getters.getPagesForSpace(space.id));
      }, []);

      // next get any individual pages which match the query
      const allPagesWithMatchingTitles = getters.getPagesFilteredByTitle(query);
      // return only the unique pages
      return [...new Set(allPagesForMatchingSpaces.concat(allPagesWithMatchingTitles))];
    },

    getPagesForSpace: (state, getters) => (spaceId) => getters.getPagesBySpace[spaceId] || [],

    getPagesBySpace: (state, getters) => getters.getPages.reduce((pagesBySpace, page) => {
      const spaceId = page.space.id;

      if (!Array.isArray(pagesBySpace[spaceId])) {
        pagesBySpace[spaceId] = [];
      }

      pagesBySpace[spaceId].push(page);

      return pagesBySpace;
    }, {}),
  },
});
