import { createStaticCrudModule } from '@/utils/vuex';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';

import { config } from './spacePages';

import store from '..';

/*
 * This module exists to handle the custom response format of the GET /trash.json endpoint. This
 * is nearly a duplicate of the GET /pages.json endpoint and as such makes use of the config for
 * that CRUD module to define it's own. Any differences/overrides are defined below.
 */
export default createStaticCrudModule(
  Object.assign(config, {
    customUrlFn(spaceCode) {
      const resource = spaceCode ? `spaces/${spaceCode}/pages` : `spaces/${store.getters['navigation/activeSpaceCode']}/pages`;
      return buildApiUrl({
        apiVersion: 'v2',
        resource,
      });
    },
  }),
);
