import br2nl from './br2nl';
import capitalize from './capitalize';
import dateFormatFilters from './dateFormats';
import fileSizeToString from './fileSizeToString';
import fullName from './fullName';
import kebabcase from './kebabcase';
import lowercase from './lowercase';
import nl2br from './nl2br';
import percent from './percent';
import pluralize from './pluralize';
import slugify from './slugify';
import thousandSeparators from './thousandSeparators';
import truncatedPageBreadcrumb from './truncatedPageBreadcrumb';
import truncatedSpaceTitle from './truncatedSpaceTitle';

export default {
  ...dateFormatFilters,
  br2nl,
  capitalize,
  fileSizeToString,
  fullName,
  kebabcase,
  lowercase,
  nl2br,
  percent,
  pluralize,
  slugify,
  thousandSeparators,
  truncatedPageBreadcrumb,
  truncatedSpaceTitle,
};
