import Vue from 'vue';
import { compact, uniq } from 'lodash-es';

import hashIdGenerator from '@/utils/helpers/hashIdGenerator';
import getSelectionHtml from '@/utils/helpers/getSelectionHtml';
import { largeDesktop } from '@/utils/constants/mediaBreakpoints';

const activeGutterScrollContainerClass = '.inline-comment-gutter__tab--active .inline-comment-list__scroll-container';

export default {
  namespaced: true,
  state: {
    activeCommentId: null,
    canAddInlineComment: false,
    highlightedCommentId: null,
    newCommentIdentifier: '',
    newCommentTextSelection: '',
    selectedCommentIds: [],
    shouldShowCommentsGutter: false,
    activeTabScrollPosition: 0,
    resolvedTabScrollPosition: 0,
    activeTabName: 'active',
  },
  getters: {
    shouldShowCommentsGutter(state) {
      return state.shouldShowCommentsGutter;
    },
  },
  mutations: {
    setShouldShowCommentsGutter(state, shouldShow) {
      state.shouldShowCommentsGutter = shouldShow;
    },

    setCanAddInlineComment(state, canAdd) {
      state.canAddInlineComment = canAdd;
    },

    setHighlightedCommentId(state, inlineCommentIdentifier) {
      state.highlightedCommentId = inlineCommentIdentifier;
    },

    clearHighlightedCommentId(state) {
      state.highlightedCommentId = null;
    },

    setNewCommentIdentifier(state, inlineCommentIdentifier) {
      state.newCommentIdentifier = inlineCommentIdentifier;
    },

    setNewCommentTextSelection(state, newCommentTextSelection) {
      state.newCommentTextSelection = newCommentTextSelection;
    },

    setSelectedCommentIds(state, selectedCommentIds) {
      state.selectedCommentIds = compact(uniq(selectedCommentIds));
    },

    clearSelectedCommentIds(state) {
      state.selectedCommentIds = [];
    },

    setActiveCommentId(state, activeCommentId) {
      state.activeCommentId = activeCommentId;
    },

    clearActiveCommentId(state) {
      state.activeCommentId = null;
    },

    setActiveTabName(state, tabName) {
      state.activeTabName = tabName;
    },

    setTabScrollPosition(state, { position, tabName }) {
      if (tabName !== 'active' && tabName !== 'resolved') {
        return;
      }

      state[`${tabName}TabScrollPosition`] = position;
    },

    resetStoredScrollPositions(state) {
      state.activeTabScrollPosition = 0;
      state.resolvedTabScrollPosition = 0;
    },
  },
  actions: {
    clearCommentIdSelectionUtil({ commit }) {
      commit('clearSelectedCommentIds');
      commit('clearHighlightedCommentId');
      commit('clearActiveCommentId');
    },

    showCommentsGutter({ commit }) {
      commit('setShouldShowCommentsGutter', true);
    },

    hideCommentsGutter({ state, commit, dispatch }) {
      commit('setShouldShowCommentsGutter', false);

      if (!state.newCommentIdentifier) {
        dispatch('clearCommentIdSelectionUtil');
      }
    },

    toggleShouldShowCommentsGutter({ state, dispatch }) {
      if (state.shouldShowCommentsGutter) {
        dispatch('hideCommentsGutter');
      } else {
        dispatch('showCommentsGutter');
      }
    },

    setActiveTabName({ state, commit, dispatch }, tabName) {
      if (tabName !== 'active' && tabName !== 'resolved') {
        return;
      }

      if (tabName === state.activeTabName) {
        return;
      }

      commit('setActiveTabName', tabName);

      // When tab changes we automatically check if we should scroll to a previously stored position within it
      Vue.nextTick(() => {
        const activeTabHasSavedScrollPosition = state[`${tabName}TabScrollPosition`] > 0;

        if (activeTabHasSavedScrollPosition) {
          dispatch('scrollToLastGutterPosition');
        }
      });
    },

    setTabScrollPosition({ state, commit }, tabName, position) {
      const $element = document.querySelector(activeGutterScrollContainerClass);

      if (!$element) {
        return;
      }

      commit('setTabScrollPosition', {
        position: position || $element.scrollTop,
        tabName: tabName || state.activeTabName,
      });
    },

    scrollGutterToPosition({ state, commit }, position, tabName = state.activeTabName) {
      const $element = document.querySelector(activeGutterScrollContainerClass);
      const newPosition = position || state[`${tabName}TabScrollPosition`];

      if ($element) {
        $element.scrollTo({ top: newPosition, behaviour: 'smooth' });

        commit('setTabScrollPosition', {
          position: newPosition,
          tabName,
        });
      }
    },

    resetStoredScrollPositions({ commit }) {
      commit('resetStoredScrollPositions');
    },

    resetTabScrollPosition({ state, dispatch }) {
      dispatch('scrollGutterToPosition', 0, state.activeTabName);
    },

    scrollToLastGutterPosition({ state, dispatch }) {
      const scrollPos = state[`${state.activeTabName}TabScrollPosition`];

      if (scrollPos > 0) {
        dispatch('scrollGutterToPosition', scrollPos, state.activeTabName);
      }
    },

    scrollToHighlightedComment({ state, rootGetters }) {
      const $comment = Array.from(
        document.querySelectorAll(`tw-span[data-tw-inline-comments*="${state.highlightedCommentId}"]`),
      )[0];

      if (!$comment) {
        return;
      }

      const navBarsRoughHeight = 100;
      let offset = rootGetters['navigation/isEditingSpacePage'] ? $comment.offsetTop : $comment.offsetTop - navBarsRoughHeight;

      const isInsideTable = $comment.offsetParent
        && ($comment.offsetParent.nodeName === 'TD' || $comment.offsetParent.nodeName === 'TH');

      if (isInsideTable) {
        // offsetTop returns a value relative to offsetParent, for elements in table
        // we need add offset from TD and TR elements, check out offsetParent MDN site for more details
        offset += $comment.offsetParent.offsetTop + $comment.offsetParent.offsetParent.offsetTop;
      }

      const selector = rootGetters['navigation/isEditingSpacePage'] ? '.s-spaces-editor-body' : '.s-space-page__body';
      document.querySelector(selector).scrollTo({ top: offset, behavior: 'smooth' });
    },

    createInlineComment({ state, commit, dispatch, rootGetters }, otInlineCommentInstance) {
      if (!otInlineCommentInstance.canAdd()) {
        return;
      }

      if (state.newCommentIdentifier) {
        otInlineCommentInstance.remove(this.newCommentIdentifier);
      }

      commit('setNewCommentIdentifier', '');
      commit('setNewCommentTextSelection', '');

      dispatch('resetTabScrollPosition');
      dispatch('clearCommentIdSelectionUtil');
      dispatch('showCommentsGutter');

      if (window.innerWidth < largeDesktop
        && !rootGetters['navigation/isEditingSpacePage']
        && state.shouldShowCommentsGutter
        && !rootGetters['ui/sidebar/isCollapsed']
      ) {
        dispatch('ui/sidebar/setCollapsedState', true, { root: true });
      }

      commit('setNewCommentIdentifier', hashIdGenerator(rootGetters['session/userId']));

      commit('setNewCommentTextSelection', getSelectionHtml());
    },
  },
};
