/**
 * Some tinymce commands have different names to the formats they apply :(
 * map keeps every thing in one place
 */
export default {
  p: {
    command: 'FormatBlock',
    value: 'p',
    format: 'p',
  },
  h1: {
    command: 'FormatBlock',
    value: 'h1',
    format: 'h1',
  },
  h2: {
    command: 'FormatBlock',
    value: 'h2',
    format: 'h2',
  },
  h3: {
    command: 'FormatBlock',
    value: 'h3',
    format: 'h3',
  },
  h4: {
    command: 'FormatBlock',
    value: 'h4',
    format: 'h4',
  },
  bold: {
    command: 'bold',
    format: 'bold',
  },
  italic: {
    command: 'italic',
    format: 'italic',
  },
  strikethrough: {
    command: 'strikethrough',
    format: 'strikethrough',
  },
  subscript: {
    command: 'subscript',
    format: 'subscript',
  },
  superscript: {
    command: 'superscript',
    format: 'superscript',
  },
  underline: {
    command: 'underline',
    format: 'underline',
  },
  code: {
    command: 'FormatBlock',
    value: 'code',
    format: 'code',
  },
  ul: {
    command: 'InsertUnorderedList',
    format: 'ul',
  },
  ol: {
    command: 'InsertOrderedList',
    format: 'ol',
  },
  JustifyLeft: {
    command: 'JustifyLeft',
    format: 'alignleft',
  },
  JustifyRight: {
    command: 'JustifyRight',
    format: 'alignright',
  },
  JustifyCenter: {
    command: 'JustifyCenter',
    format: 'aligncenter',
  },
  JustifyFull: {
    command: 'JustifyFull',
    format: 'alignfull',
  },
  RemoveFormat: {
    command: 'RemoveFormat',
  },
  indent: {
    command: 'indent',
    format: 'indent',
  },
  outdent: {
    command: 'outdent',
    format: 'outdent',
  },
  image: {
    command: 'mceImage',
  },
  link: {
    command: 'mceLink',
    alt_active_command: 'unlink',
    format: 'link',
  },
  unlink: {
    command: 'unlink',
  },
  media: {
    command: 'mceMedia',
  },
  format: {
    command: 'FormatBlock',
  },
  textColor: {
    command: 'ForeColor',
  },
  highlightColor: {
    command: 'HiliteColor',
  },
  hr: {
    command: 'InsertHorizontalRule',
  },
  undo: {
    command: 'undo',
  },
  redo: {
    command: 'redo',
  },
  mergeCells: {
    command: 'mceTableMergeCells',
  },
  deleteTable: {
    command: 'mceTableDelete',
  },
  insertRowAbove: {
    command: 'mceTableInsertRowBefore',
  },
  insertRowBelow: {
    command: 'mceTableInsertRowAfter',
  },
  insertColLeft: {
    command: 'mceTableInsertColBefore',
  },
  insertColRight: {
    command: 'mceTableInsertColAfter',
  },
  deleteCol: {
    command: 'mceTableDeleteCol',
  },
  deleteRow: {
    command: 'mceTableDeleteRow',
  },
  blockquote: {
    command: 'mceBlockQuote',
    format: 'blockquote',
  },
};
