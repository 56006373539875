import isAppCompatibleBrowser from '@/utils/helpers/isAppCompatibleBrowser';
import { debounce, memoize } from 'lodash-es';
import store from '@/store';

function define(w = window, customElementName = 'tw-placeholder') {
  function getSelectedNode() {
    const editor = window.tinyMCE.activeEditor;
    const selection = editor.selection;
    const selectedNode = selection.getNode();
    return selectedNode;
  }

  function toggleEditorToolbar(state) {
    store.commit('ui/spacesEditor/setToolbarAsDisabled', state);
  }

  if (isAppCompatibleBrowser()) {
    const elementLookupFn = memoize((context, id) => context.getElementById(id));
    const template = document.createElement('template');
    template.innerHTML = `
    <style>
      :host {
        display: inline;
      }

      @media print {
        .tw-placeholder {
            display: none !important; // sass-lint:disable-line no-important
        }
      }

      .tw-placeholder {
        position:relative;
        color: #9C9FA5;
      }

      .tw-placeholder--focused {
        background-color: #F6F7F9;
      }

      .tw-placeholder-text {
         border-bottom: 1px solid  #CECFD2;
      }

      .tw-placeholder-slot--hidden {
        visibility: hidden;
      }

      .tw-placeholder-slot {
        outline: none;
        position: absolute;
        left:-2px;
      }

      </style>
      <span class="tw-placeholder">
         <span class="tw-placeholder-slot" contenteditable="true">
             <slot>&nbsp;</slot>
          </span>
          <span id="tw-placeholder-text" class="tw-placeholder-text">placeholder text</span>
      </span>
`;
    class TwPlaceholder extends HTMLElement {
      constructor() {
        super();

        // Attach a shadow root to the element.
        this.attachShadow({
          mode: 'open',
        });

        // add all document listeners here for clean up
        // in disconnectectCallback
        this.documentListeners = {};
        this.isFocused = false;

        this.shadowRoot.appendChild(template.content.cloneNode(true));
      }

      static get observedAttributes() {
        return ['data-placeholder-text'];
      }

      attributeChangedCallback(name, oldValue, newValue) {
        if (name === 'data-placeholder-text') {
          this.placeholderTextSpan().innerText = newValue;
        }
      }

      connectedCallback() {
        if (this.isEditMode()) {
          this.initialiseDocumentEventListeners();
          this.shadowRoot.addEventListener('click', this.selectSelf.bind(this));
        }
      }

      disconnectedCallback() {
        const self = this;
        Object.keys(this.documentListeners).forEach((listenerKey) => {
          document.removeEventListener(listenerKey,
            self.documentListeners[listenerKey].eventFn,
            self.documentListeners[listenerKey].eventPhase.capture === true,
          );
        });
      }

      initialiseDocumentEventListeners() {
        const placeholder = this.placeholderTextSpan();
        this.metaKeyDown = false;
        const self = this;

        // if there is no paragraph below insert one
        const onClickParentParagraph = () => {
          if (self.parentNode.tagName === 'P') {
            const nextSibling = self.parentNode.nextSibling;
            const editor = window.tinymce.activeEditor;
            const paragraphEl = document.createElement('p');

            paragraphEl.appendChild(document.createElement('br'));

            // always ensure there is a single empty paragraph below the placeholder
            if (!nextSibling) {
              editor.dom.insertAfter(paragraphEl, self.parentNode);
            }
          }
        };

        const onKeyDown = (e) => {
          const currentSelection = document.getSelection();
          this.metaKeyDown = e.metaKey;

          if (e.key === 'Backspace') {
            toggleEditorToolbar(false);
          }

          // Only want to remove the placeholder if an alphanumeric or symbol is pressed
          // when metakey is no down
          // eslint-disable-next-line no-useless-escape
          if (!/^[A-Za-z0-9!§±@£$%#^&€*():_+/\-\\\[\]\{\}`~.,"'?<>\s]$/.test(e.key) || this.metaKeyDown) {
            return;
          }

          //  if placeholder is focused replace it with the keyed value
          if ((currentSelection.anchorNode === self.parentNode)
          || (currentSelection.anchorNode.parentNode === self)) {
            self.replaceSelf(e.key);
            e.preventDefault();
          }
        };

        const onKeyUp = (e) => {
          this.metaKeyDown = e.metaKey;
        };

        const onPaste = () => {
          if (self.isFocused) {
            self.replaceSelf('');
          }
        };

        const isAnyTwPlaceholderSelected = () => {
          const selectedNode = getSelectedNode();
          return (selectedNode.tagName === 'TW-PLACEHOLDER');
        };

        const onSelectionChange = debounce(() => {
          const selectedNode = getSelectedNode();

          // ensure there is always an &nbsp; after the placeholder
          if (!self.nextSibling) {
            this.appendTrailingSpace();
          }

          if (!isAnyTwPlaceholderSelected()) {
            toggleEditorToolbar(false);
          }
          //  if placeholder is focused
          if (isAnyTwPlaceholderSelected() && selectedNode === self) {
            placeholder.classList.add('tw-placeholder--focused');
            this.isFocused = true;
            toggleEditorToolbar(true);
          } else {
            placeholder.classList.remove('tw-placeholder--focused');
            // dirty hack to offload this onto the eventloop
            // so that a paste over node will work :()
            setTimeout(() => {
              this.isFocused = false;
            }, 10);
          }
        }, 100);

        this.documentListeners.click = { eventFn: onClickParentParagraph, eventPhase: {} };
        this.documentListeners.keydown = { eventFn: onKeyDown, eventPhase: { capture: true } };
        this.documentListeners.keyup = { eventFn: onKeyUp, eventPhase: {} };
        this.documentListeners.paste = { eventFn: onPaste, eventPhase: {} };
        this.documentListeners.selectionchange = { eventFn: onSelectionChange, eventPhase: {} };

        Object.keys(this.documentListeners).forEach((listenerKey) => {
          document.addEventListener(listenerKey,
            this.documentListeners[listenerKey].eventFn,
            this.documentListeners[listenerKey].eventPhase,
          );
        });
      }

      appendTrailingSpace() {
        if (this.parentNode && this.parentNode.innerHTML) {
          this.parentNode.innerHTML += '&nbsp;';
        }
      }

      placeholderTextSpan() {
        return elementLookupFn(this.shadowRoot, 'tw-placeholder-text');
      }

      isEditMode() {
        return this.closest('[contenteditable=true]') !== null;
      }

      replaceSelf(key) {
        const textNode = document.createTextNode(key);
        this.parentElement.replaceChild(textNode, this);
        toggleEditorToolbar(false);
        setTimeout(() => {
          // bit of a quirk here between tinyMCE templates editor
          // and our collaborative editor in how the cursor postion works
          // see: https://digitalcrew.teamwork.com/#/tasks/16623416
          let cursorIndex = textNode.length;
          if (window.tinyMCE.activeEditor.plugins.ot) {
            cursorIndex = textNode.length - 1;
          }
          window.tinyMCE.activeEditor.selection.setCursorLocation(textNode, cursorIndex);
        }, 10);
      }

      selectSelf() {
        const editor = window.tinyMCE.activeEditor;
        const [slot] = this.shadowRoot.querySelectorAll('slot');
        const assignedNodes = slot.assignedNodes();
        if (assignedNodes && assignedNodes.length > 0) {
          editor.selection.setCursorLocation(assignedNodes[0], 0);
        }
      }
    }

    w.customElements.define(customElementName, TwPlaceholder);
  }
}

// eslint-disable-next-line import/prefer-default-export
export { define };
