import { isDeviceMacOs } from '@/utils/helpers/device';
import { formattingShortcuts, markdownShortcuts } from '@/utils/constants/shortcuts';
import capitalize from '@/utils/filters/capitalize';

const getUnicodeCharacterForKey = (key) => {
  if (key === 'shift') {
    return '⇧';
  }

  if (key === 'option') {
    return '⌥';
  }

  if (key === 'enter') {
    return '↵';
  }

  if (key === 'command') {
    return '⌘';
  }

  if (key === 'tab') {
    return 'tab';
  }

  return key;
};

const shortcutMetaKey = (convertToUnicode = true) => {
  if (isDeviceMacOs()) {
    return convertToUnicode ? getUnicodeCharacterForKey('command') : 'command';
  }

  return 'ctrl';
};

const shortcutModifierKey = (convertToUnicode = true) => {
  if (isDeviceMacOs()) {
    return convertToUnicode ? getUnicodeCharacterForKey('option') : 'alt';
  }

  return 'alt';
};

const getShortcut = (shortcutId) => [...formattingShortcuts, ...markdownShortcuts].find(
  (s) => s.id === shortcutId,
);

const getShortcutString = (shortcutId, convertToUnicode = true, shouldCapitalize = true) => {
  const shortcut = getShortcut(shortcutId);

  if (!shortcut) {
    return null;
  }

  // eslint-disable-next-line no-underscore-dangle
  const _capitalize = shouldCapitalize ? capitalize : (s) => s;

  // eslint-disable-next-line no-underscore-dangle
  const _convert = convertToUnicode ? getUnicodeCharacterForKey : (s) => s;

  let shortcutString = '';

  if (shortcut.hasMeta) {
    shortcutString += _capitalize(`${shortcutMetaKey(convertToUnicode)} + `);
  }

  if (shortcut.hasShift) {
    shortcutString += _capitalize(`${_convert('shift')} + `);
  }

  if (shortcut.hasModifier) {
    shortcutString += _capitalize(`${shortcutModifierKey(convertToUnicode)} + `);
  }

  shortcutString += _capitalize(_convert(shortcut.key));

  return shortcutString;
};

export {
  getShortcut,
  getUnicodeCharacterForKey,
  getShortcutString,
  shortcutMetaKey,
  shortcutModifierKey,
};
