import Vue from 'vue';
import { isObject } from 'lodash-es';

import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { createStaticCrudModule } from '@/utils/vuex';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import { PLANS as PRICE_PLAN, GRANDFATHER_PRICE_PLAN_IDS } from '@/utils/constants/pricingPlans';

export default createStaticCrudModule({
  only: ['FETCH', 'UPDATE'],
  property: 'installation',
  urlRoot: buildApiUrl({
    resource: 'installation',
  }),
  state: {
    isLocked: false,
    checkoutErrorCode: null,
  },
  mutations: {
    lockInstallation(state) {
      state.isLocked = true;
    },

    unlockInstallation(state) {
      state.isLocked = false;
    },

    setCheckoutErrorCode(state, data) {
      state.checkoutErrorCode = data;
    },

    setInstallation(state, data) {
      Vue.set(state, 'entity', data);
    },
  },
  actions: {
    lockInstallation({ commit }) {
      commit('lockInstallation');
    },

    unlockInstallation({ commit }) {
      commit('unlockInstallation');
    },

    setCheckoutErrorCode({ commit }, data) {
      commit('setCheckoutErrorCode', data);
    },

    setInstallation({ commit }, data) {
      commit('setInstallation', data);
    },
  },
  getters: {
    isNewTeamworkCustomer: (state, getters) => getters.get && getters.get.signupProduct && getters.get.signupProduct.startsWith('spaces'),

    isLocked: (state) => state.isLocked,

    id: (state, getters) => (isObject(getters.get) && getters.get.id) || null,

    name: (state, getters) => (isObject(getters.get) && getters.get.name) || '',

    appOwnerId: (state, getters) => (
      (isObject(getters.get)
        && isObject(getters.get.appOwner)
        && getters.get.appOwner.id) || null
    ),

    appOwner: (state, getters, rootState, rootGetters) => {
      if (!getters.appOwnerId) {
        return null;
      }

      return rootGetters['users/byId'](getters.appOwnerId);
    },

    baseHref: (state, getters) => ((isObject(getters.get) && getters.get.baseHref) || '').replace(/\/$/, ''),

    region: (state, getters) => (isObject(getters.get) && getters.get.region) || '',

    hasTeamworkBrand: (state, getters) =>
      (isObject(getters.get) && getters.get.allowTeamworkBrand) || false,

    hasActiveTrial: (state, getters) => (
      getters.get
      && getters.get.subscription
      && getters.get.subscription.trialUntil
      && !isNaN(new Date(getters.get.subscription.trialUntil).getTime())
      && !!(new Date(getters.get.subscription.trialUntil) > new Date())
    ) || false,

    isTrialExpiringSoon: (state, getters) => (
      isObject(getters)
      && getters.trialExpiryDate
      && !!(differenceInCalendarDays(getters.trialExpiryDate, new Date()) <= 4)
    ),

    trialExpiryDate: (state, getters) => (
      getters.get
        && getters.get.subscription
        && getters.get.subscription.trialUntil
        && !isNaN(new Date(getters.get.subscription.trialUntil).getTime())
        ? new Date(getters.get.subscription.trialUntil)
        : null
    ),

    planUsersCount: (state, getters) =>
      getters.get && getters.get.subscription && getters.get.subscription.planUsers,

    currentUsersCount: (state, getters) =>
      getters.get && getters.get.subscription && getters.get.subscription.currentUsers,

    checkoutErrorCode: (state) => state.checkoutErrorCode,

    inGracePeriod: (state, getters) => !!(
      getters.get
      && getters.get.subscription
      && getters.get.subscription.isInGracePeriod
    ),

    paymentMethod: (state, getters) => (getters.get && getters.get.subscription && getters.get.subscription.paymentMethod) || 'stripe',

    paymentCollectionMethod: (state, getters) => getters.get?.subscription?.paymentCollectionMethod || null,

    signupProduct: (state, getters) => getters.get && getters.get.signupProduct,

    createdAt: (state, getters) => (isObject(getters.get) && getters.get.createdAt) || null,

    pricePlanId: (state, getters) =>
      (getters.get && getters.get.subscription && getters.get.subscription.pricePlanId) || null,

    isPaid: (state, getters) =>
      (getters.get && getters.get.subscription && getters.get.subscription.isPaid) || false,

    isOnStarterPlan: (state, getters) => getters.pricePlanId === PRICE_PLAN.STARTER.ID && !getters.hasActiveTrial,

    isOnProPlan: (state, getters) => getters.pricePlanId === PRICE_PLAN.PRO.ID,

    isOnEnterprisePlan: (state, getters) => getters.pricePlanId === PRICE_PLAN.ENTERPRISE.ID,

    isOnAddOnPlan: (state, getters) => getters.pricePlanId === PRICE_PLAN.SPACES_BUNDLED.ID,

    isOnGrandFatheredPlan: (state, getters) => GRANDFATHER_PRICE_PLAN_IDS.includes(getters.pricePlanId),

    isOnSpacesPlan: (state, getters) => !getters.isOnAddOnPlan,

    isOnPaidAddOnPlan: (state, getters) => getters.isOnAddOnPlan && getters.isPaid,

    isOnFreeAddOnPlan: (state, getters) => getters.isOnAddOnPlan && !getters.isPaid,
  },
});
