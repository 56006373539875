import { mapActions } from 'vuex';

// @vue/component
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    }, // index in the v-for loop, used when dismissing the alert
    isPersistent: {
      type: Boolean,
      required: false,
      default: false,
    },
    ctaText: {
      type: String,
      required: false,
      default: null,
    },
    ctaHandler: {
      type: Function,
      required: false,
      default: () => { },
    },
    wrapText: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      timeout: undefined,
      canSelfDismiss: true,
      autoDismissTimeMilliseconds: 5000,
    };
  },
  computed: {
    classes() {
      return [
        'w-alert',
        `w-alert--${this.type}`,
        { 'w-alert--no-wrap': !this.wrapText },
      ];
    },
  },
  methods: {
    ...mapActions('alerts', ['closeAlert']),

    close() {
      const alertIndex = this.index;
      this.closeAlert({ index: alertIndex }); // Will destroy component, timeout will get cleared
    },

    ctaButtonClicked() {
      this.ctaHandler();
      this.close();
    },
  },
  created() {
    if (!this.isPersistent) {
      this.timeout = setTimeout(() => {
        this.close();
      }, this.autoDismissTimeMilliseconds);
    }
  },
  beforeDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  },
};
