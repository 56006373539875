import { createCrudModule } from '@/utils/vuex';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import alphabeticalSort from '@/utils/helpers/alphabeticalSort';

const resource = 'templates/library';

export default createCrudModule({
  only: ['FETCH_LIST', 'FETCH_SINGLE', 'CREATE', 'UPDATE', 'DESTROY'],
  resource,
  listProperty: 'globalTemplates',
  singleProperty: 'globalTemplate',
  customUrlFn(id) {
    return buildApiUrl({
      resource: id ? `${resource}/${id}` : resource,
    });
  },
  getters: {
    getSortedList(state, getters) {
      return (term = '', descendingOrder = true) => {
        if (term === '') {
          return getters.list;
        }

        return getters.list && getters.list
          .filter((template) => template.name.toLowerCase().includes(term.toLowerCase()))
          .sort((templateA, templateB) =>
            alphabeticalSort(templateA.name.toLowerCase(), templateB.name.toLowerCase(), descendingOrder));
      };
    },
  },
});
