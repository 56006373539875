import ListPopover from '@widgets/Popover/ListPopover';

// @vue/component
export default {
  props: {
    item: {
      type: Object,
      required: false,
      default: null,
    },
    pageList: {
      type: Array,
      required: false,
      default: null,
    },
  },
  components: {
    ListPopover,
  },
  computed: {
    spaceCode() {
      return this.$route.params.spaceCode;
    },
    routeParams() {
      if (this.item && this.item.uri && this.item.uri.params) {
        return this.item.uri.params;
      }

      return {
        spaceCode: this.spaceCode,
      };
    },
  },
};
