import { createStaticCrudModule } from '@/utils/vuex';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';

import { config } from './spacePages';

/*
 * This module exists to handle the custom response format of the GET /trash.json endpoint. This
 * is nearly a duplicate of the GET /pages.json endpoint and as such makes use of the config for
 * that CRUD module to define it's own. Any differences/overrides are defined below.
 */
export default createStaticCrudModule(
  Object.assign(config, {
    only: ['FETCH'],
    property: 'trashContent',
    customUrlFn(id) {
      if (!id) {
        throw new Error('Unable to construct URL. Missing required Space ID parameter');
      }

      return buildApiUrl({
        apiVersion: 'v1',
        resource: `spaces/${id}/trash`,
      });
    },
    getters: Object.assign(config.getters, {
      homePage() {
        return null;
      },
      unrestrictedPageList: (state, getters) => {
        if (
          !Object.hasOwnProperty.call(getters, 'get')
          || !Array.isArray(getters.get.pages)
        ) {
          return [];
        }

        return getters.get.pages;
      },
    }),
  }),
);
