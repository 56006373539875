import formMixin from '@/utils/mixins/form.mixin';

// @vue/component
export default {
  mixins: [formMixin],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return [
        'w-form-switch',
        { 'w-form-switch--disabled': this.disabled },
      ];
    },
  },
  methods: {
    onClick() {
      if (!this.disabled) {
        this.$emit('input', !this.value);
      }
    },

    onChange() {
      this.onClick();
    },
  },
};
