export const DAYS_IN_TRASH = 30;

export const STATES = Object.freeze({
  ACTIVE: 'active',
  DELETED: 'deleted',
  ARCHIVED: 'archived',
  REMOVED: 'removed',
});

export const PAGE_PRIVACY_OPTIONS = Object.freeze({
  ALL_MEMBERS: 'all-members',
  EDITORS: 'editors',
  SPECIFIC_MEMBERS: 'specific-members',
  ME: 'me',
});

// Default page title to be given to a page created from a blank page.
// API expects a page title on creation so this is the default.
export const DEFAULT_SPACE_PAGE_TITLE = 'Untitled';

// Default page slug to be given to a page created from a blank page.
export const DEFAULT_SPACE_PAGE_SLUG = 'untitled';

export default {
  DAYS_IN_TRASH,
  STATES,
  PAGE_PRIVACY_OPTIONS,
};
