/* eslint-disable no-underscore-dangle */
import { required } from 'vuelidate/lib/validators';
import { reduce } from 'lodash-es';

import FormLabel from '@widgets/Form/FormLabel';
import FormInput from '@widgets/Form/FormInput';
import FormSelect from '@widgets/Form/FormSelect';
import FormContainer from '@widgets/Form/FormContainer';
import FormGroup from '@widgets/Form/FormGroup';
import SubmitAndCancel from '@widgets/SubmitAndCancel';
import cnameHelper from '@/utils/helpers/CNameHelper';

// @vue/component
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      required: true,
    },
    target: {
      type: String,
      required: true,
    },
  },
  components: {
    FormGroup,
    FormLabel,
    FormInput,
    FormSelect,
    FormContainer,
    SubmitAndCancel,
  },
  data() {
    return {
      isEditing: false,
      fields: {
        text: {
          label: 'Text to display',
          name: 'text',
          value: this.text,
          validations: {

          },
        },
        link: {
          label: 'Web address',
          name: 'link',
          value: this.href,
          validations: {
            required,
          },
        },
        target: {
          label: 'Target',
          name: 'target',
          selected: null,
          options: [
            { label: 'New window', value: '_blank' },
            { label: 'None', value: '' },
          ],
        },
      },
    };
  },
  methods: {
    onCancelChangeClicked() {
      this.isEditing = false;

      this.fields.text.value = this.text;
      this.fields.link.value = this.href;
      this.fields.target.selected = this.fields.target.options.find((x) => x.value === this.target);
    },

    onSubmitChangeClicked() {
      if (!this.fields.text.value) {
        this.fields.text.value = this.fields.link.value;
      }

      if (!this.$v) {
        this.$emit('change', this.fields.text.value, this.fields.link.value, this.fields.target.selected.value);
        return;
      }

      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.$emit('change', this.fields.text.value, this.fields.link.value, this.fields.target.selected.value);
      }

      if (this.$ga) {
        const activeSpace = this.$store.getters['navigation/activeSpace'];

        this.$ga.event({
          eventCategory: 'editor-actions',
          eventAction: 'edit-link-popover',
          ...(activeSpace ? {
            eventLabel: `${cnameHelper()} | ${activeSpace.title} | ${this.$store.getters['navigation/activePage'].title}`,
          } : {}),
          eventValue: 1,
        });
      }
    },

    onChangeClicked() {
      this.isEditing = true;
    },

    onRemoveClicked() {
      this.$emit('remove');
    },
  },
  mounted() {
    this.$store.dispatch('ui/popover/setPopoverState', { id: this._uid, isOpen: true }, { root: true });
  },
  beforeDestroy() {
    this.$store.dispatch('ui/popover/setPopoverState', { id: this._uid, isOpen: undefined }, { root: true });
  },
  validations() {
    /* eslint-disable no-param-reassign */
    return {
      fields: reduce(this.fields, (result, value, key) => {
        if (value.validations) {
          result[key] = {
            value: value.validations,
          };
        }

        return result;
      }, {}),
    };
    /* eslint-enable no-param-reassign */
  },
  watch: {
    isEditing: {
      immediate: false,
      handler(isEditing) {
        this.$emit(isEditing ? 'start-editing' : 'stop-editing');
      },
    },
    text: {
      immediate: false,
      handler(text) {
        this.fields.text.value = text;
      },
    },
    href: {
      immediate: false,
      handler(href) {
        this.fields.link.value = href;
      },
    },
    target: {
      immediate: true,
      handler(target) {
        this.fields.target.selected = this.fields.target.options.find((x) => x.value === target);
      },
    },
  },
};
