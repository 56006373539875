import Hashids from 'hashids';

export default (id) => {
  if (!id) {
    return undefined;
  }

  // generate the hash id
  const hashIdGenerator = new Hashids(id.toString(), 5,
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890');

  return hashIdGenerator.encode(new Date().getMinutes(), new Date().getMilliseconds());
};
