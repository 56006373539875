import isPositiveInteger from '@/utils/helpers/isPositiveInteger';

export default (id) => {
  // eslint-disable-next-line no-param-reassign
  id = parseInt(id, 10);

  if (isNaN(id)) {
    return false;
  }

  return isPositiveInteger(id);
};
