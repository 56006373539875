import { EDITOR_TYPES } from '@/utils/constants/editorConfig';
import {
  defaultTinymceInsertFn, insertCodeSample,
  insertCustomElement,
  insertImage,
  insertInfoPanel, insertLink, insertMedia,
  insertPlaceholder,
  insertStatus, insertTable,
} from '@/utils/editor/insertFunctions';

export const customElementComponents = [
  {
    name: 'table-of-contents',
    customElementName: 'table-of-contents',
    dataCy: 'table-of-contents',
    shortcode: 'toc',
    displayName: 'Table of contents',
    description: 'Creates a Table of Contents based on headings in the current page',
    icon: ['far', 'list-alt'],
    component: 'TableOfContents',
    attributes: ['heading-depth'],
    showInEditorToolbar: true,
    requiresProjects: false,
    shouldIncludeInPrint: true,
    enabledEditors: [EDITOR_TYPES.PAGE, EDITOR_TYPES.TEMPLATE],
    insertFn: insertCustomElement,
  },
  {
    name: 'space-pages-list',
    customElementName: 'space-pages-list',
    dataCy: 'space-pages-list',
    shortcode: 'page',
    displayName: 'Page list',
    description: 'Creates a list of all the pages and sub-pages within the current Space',
    icon: ['far', 'list-alt'],
    component: 'SpacePagesList',
    attributes: ['root-page-option'],
    showInEditorToolbar: true,
    requiresProjects: false,
    shouldIncludeInPrint: true,
    enabledEditors: [EDITOR_TYPES.PAGE, EDITOR_TYPES.TEMPLATE],
    insertFn: insertCustomElement,
  },
  {
    name: 'projects-tasks',
    customElementName: 'projects-tasks',
    dataCy: 'projects-tasks',
    shortcode: 'tasks',
    displayName: 'Teamwork project tasks',
    description: 'Creates a list of tasks from Teamwork',
    icon: ['far', 'clipboard-list'],
    component: 'ProjectsTasks',
    attributes: ['task-list-id', 'project-id', 'filter-tags'],
    showInEditorToolbar: true,
    requiresProjects: true,
    shouldIncludeInPrint: true,
    enabledEditors: [EDITOR_TYPES.PAGE, EDITOR_TYPES.TEMPLATE],
    insertFn: insertCustomElement,
  },
  {
    name: 'tw-spaces-attachments',
    customElementName: 'tw-spaces-attachments',
    dataCy: 'tw-spaces-attachments',
    displayName: 'Attachments',
    shortcode: 'attach',
    description: 'Creates a list of files attached to this page',
    icon: ['far', 'paperclip'],
    component: 'Attachments',
    attributes: ['data-selected-space-attachment-ids'],
    showInEditorToolbar: true,
    requiresProjects: false,
    shouldIncludeInPrint: false,
    enabledEditors: [EDITOR_TYPES.PAGE],
    insertFn: insertCustomElement,
  },
  {
    name: 'iframely-embed',
    customElementName: 'iframely-embed',
    dataCy: 'iframely-embed',
    displayName: 'Embed media',
    description: 'iFramely embed',
    icon: ['far', 'play-circle'],
    component: 'IframelyEmbed',
    attributes: ['url'],
    showInEditorToolbar: false,
    requiresProjects: false,
    shouldIncludeInPrint: true,
    enabledEditors: [EDITOR_TYPES.PAGE, EDITOR_TYPES.TEMPLATE],
  },
  {
    name: 'emoji-icon',
    customElementName: 'emoji-icon',
    dataCy: 'emoji-icon',
    displayName: 'Emoji',
    component: 'EmojiIcon',
    attributes: ['short-name'],
    showInEditorToolbar: false,
    isInlineElement: true,
    requiresProjects: false,
    shouldIncludeInPrint: true,
    enabledEditors: [EDITOR_TYPES.PAGE, EDITOR_TYPES.TEMPLATE],
  },
  {
    name: 'tw-spaces-image',
    customElementName: 'tw-spaces-image',
    dataCy: 'tw-spaces-image',
    shortcode: 'img',
    icon: ['far', 'image'],
    displayName: 'Image',
    component: 'TwSpacesImage',
    attributes: [
      'src',
      'width',
      'data-float',
      'data-align',
      'size',
      'caption',
    ],
    showInEditorToolbar: true,
    isInlineElement: false,
    requiresProjects: false,
    shouldIncludeInPrint: true,
    enabledEditors: [EDITOR_TYPES.PAGE, EDITOR_TYPES.TEMPLATE],
    insertFn: insertImage,
  },
  {
    name: 'tw-spaces-info-panel',
    customElementName: 'tw-spaces-info-panel',
    dataCy: 'tw-spaces-info-panel',
    icon: ['far', 'info-circle'],
    shortcode: 'info',
    displayName: 'Info Panel',
    component: 'TwSpacesInfoPanel',
    attributes: ['type', 'content'],
    showInEditorToolbar: true,
    requiresProjects: false,
    shouldIncludeInPrint: true,
    diffable: true,
    enabledEditors: [EDITOR_TYPES.PAGE, EDITOR_TYPES.TEMPLATE],
    insertFn: insertInfoPanel,
  },
  {
    name: 'tw-spaces-internal-link',
    customElementName: 'tw-spaces-internal-link',
    dataCy: 'tw-spaces-internal-link',
    displayName: 'Internal Link',
    component: 'TwSpacesInternalLink',
    attributes: ['page-id', 'link-text', 'target'],
    showInEditorToolbar: false,
    isInlineElement: true,
    requiresProjects: false,
    shouldIncludeInPrint: true,
    diffable: true,
    enabledEditors: [EDITOR_TYPES.PAGE, EDITOR_TYPES.TEMPLATE],
  },
  {
    name: 'tw-spaces-status',
    dataCy: 'tw-spaces-status',
    displayName: 'Status Chip',
    shortcode: 'stat',
    wicon: 'status-chip',
    customElementName: 'tw-spaces-status',
    component: 'TwSpacesStatus',
    attributes: ['status', 'type'],
    showInEditorToolbar: true,
    isInlineElement: true,
    requiresProjects: false,
    shouldIncludeInPrint: true,
    diffable: true,
    enabledEditors: [EDITOR_TYPES.PAGE, EDITOR_TYPES.TEMPLATE],
    insertFn: insertStatus,
  },
  {
    name: 'tw-spaces-image-gallery',
    customElementName: 'tw-spaces-image-gallery',
    dataCy: 'tw-spaces-image-gallery',
    displayName: 'Gallery',
    icon: ['far', 'images'],
    shortcode: 'gal',
    description: 'Creates an image gallery based on images available in the current page',
    component: 'ImageGallery',
    attributes: ['selected-image-ids'],
    showInEditorToolbar: true,
    requiresProjects: false,
    shouldIncludeInPrint: true,
    enabledEditors: [EDITOR_TYPES.PAGE],
    insertFn: insertCustomElement,
  },
  {
    name: 'tw-spaces-user-mention',
    customElementName: 'tw-spaces-user-mention',
    displayName: 'Mention',
    component: 'TwSpacesUserMention',
    attributes: ['data-user-id', 'data-name', 'data-mentioned-by'],
    showInEditorToolbar: false,
    isInlineElement: true,
    shouldIncludeInPrint: true,
    diffable: true,
    requiresProjects: false,
    enabledEditors: [EDITOR_TYPES.PAGE],
  },
  {
    name: 'tw-spaces-9ef9c2f4-a3d6-437f-a70f-a268df61695b-render-factory-plugin',
    customElementName: 'tw-spaces-9ef9c2f4-a3d6-437f-a70f-a268df61695b-render-factory-plugin',
    dataCy: 'tw-spaces-9ef9c2f4-a3d6-437f-a70f-a268df61695b-render-factory-plugin',
    displayName: 'Incompatible Browser Fallback',
    component: 'TwSpacesRenderFactoryFallback',
    attributes: ['data-component-name', 'data-state-id'],
    showInEditorToolbar: false,
    isInlineElement: false,
    shouldIncludeInPrint: false,
    diffable: false,
    requiresProjects: false,
  },
  {
    name: 'tw-placeholder',
    customElementName: 'tw-placeholder',
    dataCy: 'tw-placeholder',
    displayName: 'Placeholder',
    wicon: 'placeholder-text',
    shortcode: 'pl',
    attributes: ['data-placeholder-text'],
    showInEditorToolbar: true,
    isInlineElement: true,
    shouldIncludeInPrint: false,
    diffable: false,
    requiresProjects: false,
    childNodeNames: ['br'],
    needsBr: true,
    insertFn: insertPlaceholder,
    enabledEditors: [EDITOR_TYPES.PAGE, EDITOR_TYPES.TEMPLATE],
  },
];
export const builtInTinymceComponents = [
  {
    name: 'p',
    dataCy: 'p',
    displayName: 'Paragraph',
    shortcode: 'par',
    icon: ['far', 'paragraph'],
    showInEditorToolbar: true,
    enabledEditors: [EDITOR_TYPES.PAGE, EDITOR_TYPES.TEMPLATE],
    insertFn: defaultTinymceInsertFn,
  },
  {
    name: 'h1',
    dataCy: 'h1',
    displayName: 'Heading 1',
    icon: ['far', 'h1'],
    shortcode: 'h1',
    showInEditorToolbar: true,
    enabledEditors: [EDITOR_TYPES.PAGE, EDITOR_TYPES.TEMPLATE],
    insertFn: defaultTinymceInsertFn,
  },
  {
    name: 'h2',
    dataCy: 'h2',
    displayName: 'Heading 2',
    icon: ['far', 'h2'],
    shortcode: 'h2',
    showInEditorToolbar: true,
    enabledEditors: [EDITOR_TYPES.PAGE, EDITOR_TYPES.TEMPLATE],
    insertFn: defaultTinymceInsertFn,
  },
  {
    name: 'h3',
    dataCy: 'h3',
    displayName: 'Heading 3',
    icon: ['far', 'h3'],
    shortcode: 'h3',
    showInEditorToolbar: true,
    enabledEditors: [EDITOR_TYPES.PAGE, EDITOR_TYPES.TEMPLATE],
    insertFn: defaultTinymceInsertFn,
  },
  {
    name: 'h4',
    dataCy: 'h4',
    displayName: 'Heading 4',
    icon: ['far', 'h4'],
    shortcode: 'h4',
    showInEditorToolbar: true,
    enabledEditors: [EDITOR_TYPES.PAGE, EDITOR_TYPES.TEMPLATE],
    insertFn: defaultTinymceInsertFn,
  },
  {
    name: 'ol',
    dataCy: 'ol',
    displayName: 'Number List',
    shortcode: 'num',
    icon: ['far', 'list-ol'],
    showInEditorToolbar: true,
    enabledEditors: [EDITOR_TYPES.PAGE, EDITOR_TYPES.TEMPLATE],
    insertFn: defaultTinymceInsertFn,
  },
  {
    name: 'ul',
    dataCy: 'ul',
    displayName: 'Bullet List',
    shortcode: 'bul',
    icon: ['far', 'list-alt'],
    showInEditorToolbar: true,
    enabledEditors: [EDITOR_TYPES.PAGE, EDITOR_TYPES.TEMPLATE],
    insertFn: defaultTinymceInsertFn,
  },
  {
    name: 'hr',
    dataCy: 'hr',
    displayName: 'Divider',
    shortcode: 'div',
    icon: ['far', 'ruler-horizontal'],
    showInEditorToolbar: true,
    enabledEditors: [EDITOR_TYPES.PAGE, EDITOR_TYPES.TEMPLATE],
    insertFn: defaultTinymceInsertFn,
  },
  {
    name: 'blockquote',
    dataCy: 'blockquote',
    displayName: 'Quote',
    shortcode: 'qu',
    wicon: 'quote-left',
    showInEditorToolbar: true,
    enabledEditors: [EDITOR_TYPES.PAGE, EDITOR_TYPES.TEMPLATE],
    insertFn: defaultTinymceInsertFn,
  },
  {
    name: 'table',
    dataCy: 'table',
    displayName: 'Table',
    shortcode: 'tbl',
    icon: ['far', 'table'],
    showInEditorToolbar: true,
    enabledEditors: [EDITOR_TYPES.PAGE, EDITOR_TYPES.TEMPLATE],
    insertFn: insertTable,
  },
  {
    name: 'code-sample',
    dataCy: 'code-sample',
    displayName: 'Code Sample',
    shortcode: 'cs',
    icon: ['far', 'code'],
    showInEditorToolbar: true,
    enabledEditors: [EDITOR_TYPES.PAGE, EDITOR_TYPES.TEMPLATE],
    insertFn: insertCodeSample,
  },
  {
    name: 'link',
    dataCy: 'link',
    displayName: 'Link',
    shortcode: 'link',
    icon: ['far', 'link'],
    showInEditorToolbar: true,
    enabledEditors: [EDITOR_TYPES.PAGE, EDITOR_TYPES.TEMPLATE],
    insertFn: insertLink,
  },
  {
    name: 'media',
    dataCy: 'media',
    displayName: 'Media',
    shortcode: 'med',
    icon: ['far', 'video'],
    showInEditorToolbar: true,
    enabledEditors: [EDITOR_TYPES.PAGE, EDITOR_TYPES.TEMPLATE],
    insertFn: insertMedia,
  },
  {
    name: 'JustifyLeft',
    dataCy: 'JustifyLeft',
    displayName: 'Left Align',
    shortcode: 'left',
    icon: ['far', 'align-left'],
    showInEditorToolbar: true,
    enabledEditors: [EDITOR_TYPES.PAGE, EDITOR_TYPES.TEMPLATE],
    insertFn: defaultTinymceInsertFn,
  },
  {
    name: 'JustifyRight',
    dataCy: 'JustifyRight',
    displayName: 'Right Align',
    shortcode: 'right',
    icon: ['far', 'align-right'],
    showInEditorToolbar: true,
    enabledEditors: [EDITOR_TYPES.PAGE, EDITOR_TYPES.TEMPLATE],
    insertFn: defaultTinymceInsertFn,
  },
  {
    name: 'JustifyCenter',
    dataCy: 'JustifyCenter',
    displayName: 'Center Align',
    shortcode: 'cent',
    icon: ['far', 'align-center'],
    showInEditorToolbar: true,
    enabledEditors: [EDITOR_TYPES.PAGE, EDITOR_TYPES.TEMPLATE],
    insertFn: defaultTinymceInsertFn,
  },
  {
    name: 'indent',
    dataCy: 'indent',
    displayName: 'Indent',
    shortcode: 'ind',
    icon: ['far', 'indent'],
    showInEditorToolbar: true,
    enabledEditors: [EDITOR_TYPES.PAGE, EDITOR_TYPES.TEMPLATE],
    insertFn: defaultTinymceInsertFn,
  },
  {
    name: 'outdent',
    dataCy: 'outdent',
    displayName: 'Outdent',
    shortcode: 'out',
    icon: ['far', 'outdent'],
    showInEditorToolbar: true,
    enabledEditors: [EDITOR_TYPES.PAGE, EDITOR_TYPES.TEMPLATE],
    insertFn: defaultTinymceInsertFn,
  },

];

export const internalEditorComponentsMeta = [
  ...customElementComponents,
  ...builtInTinymceComponents,
];
