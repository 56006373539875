import Vue from 'vue';

import { fixBody } from '@/utils/helpers/fixScroll';

export default {
  namespaced: true,
  state: {
    primaryModal: null,
    primaryModalProps: null,
    secondaryModal: null,
    secondaryModalProps: null,
  },
  getters: {
    getPrimaryModal(state) {
      return state.primaryModal;
    },

    getSecondaryModal(state) {
      return state.secondaryModal;
    },

    hasOpenModal(state) {
      return !!(state.primaryModal || state.secondaryModal);
    },
  },
  mutations: {
    changePrimaryModal(state, { activeModal, modalProps }) {
      state.primaryModal = activeModal;
      state.primaryModalProps = modalProps;
    },

    changeSecondaryModal(state, { activeModal, modalProps }) {
      state.secondaryModal = activeModal;
      state.secondaryModalProps = modalProps;
    },
  },
  actions: {
    openModal({ commit, state }, { activeModal, modalProps }) {
      let mutation = 'changePrimaryModal';

      if (state.primaryModal && activeModal !== state.primaryModal) {
        mutation = 'changeSecondaryModal';
      }

      if (mutation === 'changePrimaryModal') {
        fixBody(true);
      }

      commit(mutation, { activeModal, modalProps });
    },

    closeModal({
      commit, state, dispatch, rootState,
    }) {
      const mutation = state.secondaryModal ? 'changeSecondaryModal' : 'changePrimaryModal';

      if (mutation === 'changePrimaryModal') {
        if (rootState.ui.formState.isAmended) {
          dispatch('ui/formState/amend', false, { root: true });
        }

        fixBody(false);
      }

      commit(mutation, {
        activeModal: null,
        modalProps: null,
      });
    },

    closeAllModals({ commit }) {
      commit('changePrimaryModal', {
        activeModal: null,
        modalProps: null,
      });

      // Closing the second modal on nextTick to avoid poor animations on transition
      Vue.nextTick(() => {
        commit('changeSecondaryModal', {
          activeModal: null,
          modalProps: null,
        });
      });
    },
  },
};
