import { library } from '@fortawesome/fontawesome-svg-core';

import lightIcons from './light';
import regularIcons from './regular';
import solidIcons from './solid';
import spaceIcons from './spaceIcons';
import editorToolbarIcons from './editorToolbarIcons';
import brandIcons from './brands';

library.add(...lightIcons);
library.add(...regularIcons);
library.add(...solidIcons);
library.add(...spaceIcons);
library.add(...editorToolbarIcons);
library.add(...brandIcons);
