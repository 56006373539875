const arrowDownKey = {
  key: 'ArrowDown',
  keyCode: 40,
};

const arrowLeftKey = {
  key: 'ArrowLeft',
  keyCode: 37,
};

const arrowRightKey = {
  key: 'ArrowRight',
  keyCode: 39,
};

const arrowUpKey = {
  key: 'ArrowUp',
  keyCode: 38,
};

const backslashKey = {
  key: '\\',
  keyCode: 220,
};

const backspaceKey = {
  key: 'Backspace',
  keyCode: 8,
};

const cmdKey = {
  key: 'Meta',
  keyCode: 91,
};

const commaKey = {
  key: ',',
  keyCode: 188,
};

const ctrlKey = {
  key: 'Control',
  keyCode: 17,
};

const deleteKey = {
  key: 'Delete',
  keyCode: 46,
};

const enterKey = {
  key: 'Enter',
  keyCode: 13,
};

const escapeKey = {
  key: 'Escape',
  keyCode: 27,
};

const minusKey = {
  key: '-',
  keyCode: 189,
};

const tabKey = {
  key: 'Tab',
  keyCode: 9,
};

const xKey = {
  key: 'x',
  keyCode: 88,
};

export {
  arrowDownKey,
  arrowLeftKey,
  arrowRightKey,
  arrowUpKey,
  backslashKey,
  backspaceKey,
  cmdKey,
  commaKey,
  ctrlKey,
  deleteKey,
  enterKey,
  escapeKey,
  minusKey,
  tabKey,
  xKey,
};
