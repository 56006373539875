import { slugMaxLength } from '@/utils/constants/slugMaxLength';
import slugify from '@/utils/filters/slugify';
import spaceConstants from '@/utils/constants/spaceConstants';

/* eslint-disable no-param-reassign */
/**
 * @param {string} s - The string to slugify
 * @param {boolean} [isHomePage=false] - Used to determine if the generated slug is valid
 * @param {string} [conflictPrefix=""] - The purpose of this is to be able to specify a prefix for
 * the generate slug, in the event that the proposed generated slug based on 's' is invalid
 */
export default (s, isHomePage = false, conflictPrefix = '') => {
  if (typeof s !== 'string') {
    return '';
  }

  s = slugify(s).substring(0, slugMaxLength);

  if (isHomePage) {
    return spaceConstants.defaultPageCode;
  }

  if (s !== spaceConstants.defaultPageCode) {
    return s;
  }

  if (conflictPrefix) {
    return `${slugify(conflictPrefix)}-${s}`.substring(0, slugMaxLength);
  }

  return `${s}-${Date.now()}`;
};
