import {
  SORT_ORDER_KEYS,
  SORT_ORDERS,
  DEFAULT_SORT_ORDER,
} from '@/utils/constants/home';

import {
  VIEW_MODE_KEYS,
  VIEW_MODES,
  DEFAULT_VIEW_MODE,
} from '@/utils/constants/viewModes';

import LOCAL_STORAGE from '@/utils/constants/localStorage';

import { ROUTE_NAMES } from '@/router/routes';
import router from '@/router';

export default {
  namespaced: true,
  state: {
    filterQuery: '',
    viewModeKey: DEFAULT_VIEW_MODE,
    sortOrderKey: DEFAULT_SORT_ORDER,
    scrollPosition: 0,
    newCategoryId: null,
  },
  getters: {
    filterQuery(state) {
      return state.filterQuery;
    },

    viewModeKey(state) {
      return state.viewModeKey;
    },

    viewMode(state) {
      const isOnStarredPagesRoute = router.currentRoute.matched
        .find((route) => route.name === ROUTE_NAMES.STARRED_PAGES);

      if (isOnStarredPagesRoute) {
        return VIEW_MODES[VIEW_MODE_KEYS.LIST];
      }

      return VIEW_MODES[state.viewModeKey];
    },

    oppositeViewMode(state) {
      if (state.viewModeKey === VIEW_MODE_KEYS.LIST) {
        return VIEW_MODES[VIEW_MODE_KEYS.GRID];
      }

      return VIEW_MODES[VIEW_MODE_KEYS.LIST];
    },

    sortOrderKey(state) {
      return state.sortOrderKey;
    },

    sortOrder(state) {
      return SORT_ORDERS[state.sortOrderKey];
    },
  },
  mutations: {
    setFilterQuery(state, filterQuery) {
      state.filterQuery = filterQuery;
    },

    setViewModeKey(state, viewModeKey) {
      if (!Object.values(VIEW_MODE_KEYS).includes(viewModeKey)) {
        // eslint-disable-next-line no-param-reassign
        viewModeKey = DEFAULT_VIEW_MODE;
      }

      state.viewModeKey = viewModeKey;

      window.localStorage.setItem(
        LOCAL_STORAGE.SPACES_HOME_VIEW_TYPE,
        viewModeKey,
      );
    },

    setSortOrderKey(state, sortOrderKey) {
      if (!Object.values(SORT_ORDER_KEYS).includes(sortOrderKey)) {
        // eslint-disable-next-line no-param-reassign
        sortOrderKey = DEFAULT_SORT_ORDER;
      }

      state.sortOrderKey = sortOrderKey;

      window.localStorage.setItem(
        LOCAL_STORAGE.SPACES_HOME_SORT_ORDER,
        sortOrderKey,
      );
    },

    setScrollPosition(state, scrollY) {
      state.scrollPosition = scrollY;
    },

    setNewCategoryId(state, categoryId) {
      state.newCategoryId = categoryId;
    },
  },
  actions: {
    setFilterQuery({ commit }, filterQuery) {
      commit('setFilterQuery', filterQuery);
    },

    setViewModeKey({ commit }, viewModeKey) {
      commit('setViewModeKey', viewModeKey);
    },

    toggleViewMode({ state, dispatch }) {
      dispatch('setViewModeKey', state.viewModeKey === VIEW_MODE_KEYS.LIST
        ? VIEW_MODE_KEYS.GRID
        : VIEW_MODE_KEYS.LIST);
    },

    setSortOrderKey({ commit }, sortOrderKey) {
      commit('setSortOrderKey', sortOrderKey);
    },

    setScrollPosition({ commit }, scrollY) {
      commit('setScrollPosition', scrollY);
    },

    setNewCategoryId({ commit }, categoryId) {
      commit('setNewCategoryId', categoryId);
    },
  },
};
