import colorList from '@/utils/constants/editorFontColors.js';
import { mapState } from 'vuex';
import TinymceCommandMixin from '@sections/SpacesEditor/SpacesEditorToolbar/Mixins/TinymceCommandMixin';

// @vue/component
export default {
  mixins: [TinymceCommandMixin],

  computed: {
    ...mapState('ui/activeEditor', {
      activeForeColor: 'activeForeColor',
    }),

    colorList() {
      return colorList;
    },

    isDefaultColor() {
      return !colorList.includes(this.activeForeColor);
    },
  },

  methods: {
    getColorStyle(color) {
      return `background: ${color}; border: 1px solid ${color === '#fff' ? '#c3c6cc' : color};`;
    },

    onColorSelected(color) {
      this.executeCommand('textColor', color);
      this.$emit('color-selected');
    },

    resetSelectionColor() {
      window.tinyMCE.activeEditor.execCommand('mceRemoveTextcolor', 'forecolor');
      this.$emit('color-removed');
    },
  },
};
