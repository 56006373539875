import getMetaFromResponse from '@/utils/vuex/utils/getMetaFromResponse';
import getIncludesFromResponse from '@/utils/vuex/utils/getIncludesFromResponse';
import shouldPermitStoreMutationViaSocket from '@/utils/helpers/shouldPermitStoreMutationViaSocket';

const createActions = ({
  actions,
  rootUrl,
  client,
  only,
  parse,
  parseError,
  prepareConfig,
  preparePayload,
  socketEvents,
  onSocketDataReceived,
}) => {
  const crudActions = {};
  const isUsingCustomUrlGetter = typeof rootUrl === 'function';

  const urlGetter = ({ customUrl, customUrlFnArgs }) => {
    let url;
    if (typeof customUrl === 'string') {
      url = customUrl;
    } else if (isUsingCustomUrlGetter) {
      const args = Array.isArray(customUrlFnArgs)
        ? customUrlFnArgs
        : [customUrlFnArgs];
      url = rootUrl(...args);
    } else {
      url = rootUrl;
    }

    return `${url.replace(/\.json$/, '')}.json`;
  };

  if (only.includes('FETCH')) {
    Object.assign(crudActions, {
      /**
       * GET /api/<resourceName>
       *
       * Fetch resource
       */
      fetch({ commit }, {
        config,
        customUrl,
        customUrlFnArgs = [],
      } = {}) {
        commit('fetchStart');

        return client.get(
          urlGetter({ customUrl, customUrlFnArgs }),
          prepareConfig(config),
        )
          .then((res) => {
            const response = parse(res);

            const includes = getIncludesFromResponse(res);

            const meta = getMetaFromResponse(res);

            commit('fetchSuccess', { params: { response, includes, meta } });

            return res;
          })
          .catch((err) => {
            const parsedError = parseError(err);

            commit('fetchError', parsedError);

            return Promise.reject(parsedError);
          });
      },
    });
  }

  if (only.includes('CREATE')) {
    Object.assign(crudActions, {
      /**
       * POST /api/<resourceName>
       *
       * Create reource
       */
      create({ commit }, {
        data,
        config,
        customUrl,
        customUrlFnArgs = [],
      } = {}) {
        commit('createStart');

        return client.post(
          urlGetter({ customUrl, customUrlFnArgs }),
          preparePayload(data, 'create'),
          prepareConfig(config),
        )
          .then((res) => {
            const response = parse(res);

            const includes = getIncludesFromResponse(res);

            const meta = getMetaFromResponse(res);

            commit('createSuccess', { params: { response, includes, meta } });

            return response;
          })
          .catch((err) => {
            const parsedError = parseError(err);

            commit('createError', parsedError);

            return Promise.reject(parsedError);
          });
      },
    });
  }

  if (only.includes('UPDATE')) {
    Object.assign(crudActions, {
      /**
       * PATCH /api/<resouceName>
       *
       * Update resource
       */
      update({ commit }, {
        data,
        config,
        customUrl,
        customUrlFnArgs = [],
      } = {}) {
        commit('updateStart');

        return client.patch(
          urlGetter({ customUrl, customUrlFnArgs }),
          preparePayload(data, 'update'),
          prepareConfig(config),
        )
          .then((res) => {
            const response = parse(res);

            const includes = getIncludesFromResponse(res);

            const meta = getMetaFromResponse(res);

            commit('updateSuccess', { params: { response, includes, meta } });

            return response;
          })
          .catch((err) => {
            const parsedError = parseError(err);

            commit('updateError', parsedError);

            return Promise.reject(parsedError);
          });
      },
    });
  }

  if (only.includes('REPLACE')) {
    Object.assign(crudActions, {
      /**
       * PUT /api/<resouceName>
       *
       * Replace resource
       */
      replace({ commit }, {
        data,
        config,
        customUrl,
        customUrlFnArgs = [],
      } = {}) {
        commit('replaceStart');
        return client.put(
          urlGetter({ customUrl, customUrlFnArgs }),
          preparePayload(data, 'replace'),
          prepareConfig(config),
        )
          .then((res) => {
            const response = parse(res);

            const includes = getIncludesFromResponse(res);

            const meta = getMetaFromResponse(res);

            commit('replaceSuccess', { params: { response, includes, meta } });

            return response;
          })
          .catch((err) => {
            const parsedError = parseError(err);

            commit('replaceError', parsedError);

            return Promise.reject(parsedError);
          });
      },
    });
  }

  if (only.includes('DESTROY')) {
    Object.assign(crudActions, {
      /**
       * DELETE /api/<resouceName>
       *
       * Destroy resource
       */
      destroy({ commit }, {
        config,
        customUrl,
        customUrlFnArgs = [],
      } = {}) {
        commit('destroyStart');

        return client.delete(
          urlGetter({ customUrl, customUrlFnArgs }),
          prepareConfig(config),
        )
          .then((res) => {
            const parsedResponse = parse(res);

            commit('destroySuccess', { params: { response: parsedResponse } });

            return res;
          })
          .catch((err) => {
            const parsedError = parseError(err);

            commit('destroyError', parsedError);

            return Promise.reject(parsedError);
          });
      },
    });
  }

  if (socketEvents.length > 0) {
    Object.assign(crudActions, {
      storeIncomingSocketData({ commit, rootGetters }, response) {
        const parsedResponse = parse(response);
        const shouldPersistData = shouldPermitStoreMutationViaSocket(rootGetters, parsedResponse);

        if (shouldPersistData) {
          commit('socket/appendSocketData', response, { root: true });
          onSocketDataReceived(response);
        }
      },
    });
  }

  if (socketEvents.includes('CREATE')) {
    Object.assign(crudActions, {
      createViaSocket({ commit, rootGetters }, response) {
        const parsedResponse = parse(response);
        const shouldUpdateStore = shouldPermitStoreMutationViaSocket(rootGetters, parsedResponse);

        if (shouldUpdateStore) {
          commit('createViaSocket', { params: { response: parsedResponse } });
        }
      },
    });
  }

  if (socketEvents.includes('UPDATE')) {
    Object.assign(crudActions, {
      updateViaSocket({ commit, rootGetters }, response) {
        const parsedResponse = parse(response);
        const shouldUpdateStore = shouldPermitStoreMutationViaSocket(rootGetters, parsedResponse);

        if (shouldUpdateStore) {
          commit('updateViaSocket', { params: { response: parsedResponse } });
        }
      },
    });
  }

  if (socketEvents.includes('DESTROY')) {
    Object.assign(crudActions, {
      destroyViaSocket({ commit, rootGetters }, response) {
        const shouldUpdateStore = shouldPermitStoreMutationViaSocket(rootGetters, response);

        if (shouldUpdateStore) {
          commit('destroyViaSocket', { params: { response: response.data } });
        }
      },
    });
  }

  return Object.assign(crudActions, actions);
};

export default createActions;
