import store from '@/store';

function copyToClipboard({ target }) {
  const range = document.createRange();
  range.selectNode(target);
  window.getSelection().removeAllRanges();
  window.getSelection().addRange(range);
  document.execCommand('copy');
  if (target.alertProperty) {
    store.dispatch('alerts/showAlert',
      {
        type: 'success',
        message: `The ${target.alertProperty} was copied to your clipboard.`,
      });
  }
}

export default {
  bind(element, binding) {
    if (binding.value.alertProperty) {
      // eslint-disable-next-line no-param-reassign
      element.alertProperty = binding.value.alertProperty;
    }
    element.addEventListener('click', copyToClipboard, false);
  },
  unbind(element) {
    element.removeEventListener('click', copyToClipboard, false);
  },
};
