const API_VERSION = 'v1';

/**
 * @param {string} resourceName
 * @returns {string}
*/
export default ({
  resource, apiVersion = API_VERSION, json = true, app = 'spaces',
}) => {
  if (app === 'spaces') {
    return `/spaces/api/${apiVersion}/${resource.replace(/^\/+/, '').replace(/\.json$/, '')}${json ? '.json' : ''}`;
  }

  if (app === 'projects') {
    return `/${resource.replace(/^\/+/, '').replace(/\.json$/, '')}${json ? '.json' : ''}`;
  }

  if (app === 'launchpad') {
    return `/launchpad/${apiVersion}/${resource.replace(/^\/+/, '').replace(/\.json$/, '')}${json ? '.json' : ''}`;
  }

  return '';
};
