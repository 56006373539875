import Vue from 'vue';
import Router from 'vue-router';
import Meta from 'vue-meta';

import { ROUTE_NAMES } from '@/router/routes';

import onboardingRoutes from './onboarding';
import pageTemplateRoutes from './pageTemplates';
import middleware from './middleware';
import settingsRoutes from './settings';
import spaceRoutes from './space';
import topLevelRoutes from './topLevel';

export { DEFAULT_HOME_ROUTE_NAME, DEFAULT_HOME_ROUTE } from './topLevel';

const routerBasePathName = process.env.VUE_APP_PUBLIC_PATH;

const routes = [
  ...topLevelRoutes,
  ...onboardingRoutes,
  ...settingsRoutes,
  ...pageTemplateRoutes,
  ...spaceRoutes,
  {
    name: ROUTE_NAMES.NOT_FOUND,
    path: '*',
    component: () => import(/* webpackChunkName: "not-found" */'@pages/Error/404'),
  },
];

const metaConfig = {
  keyName: 'meta',
};

// Note: Chat put the below in as a workaround for the errors thrown by the router after upgrading
// https://github.com/Teamwork/chat-client-vue/pull/2288/files

// Below prototype pollution was recommended by the Vue Team core member
// to avoid throwing unnecessary errors that aren't real errors, more details:
// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
function wrapRouterFunction(originalFunction) {
  return function (location, onResolve, onReject) { // eslint-disable-line func-names
    if (onResolve || onReject) return originalFunction.call(this, location, onResolve, onReject);
    return originalFunction.call(this, location).catch(() => {});
  };
}

Router.prototype.push = wrapRouterFunction(Router.prototype.push);
Router.prototype.replace = wrapRouterFunction(Router.prototype.replace);

const router = new Router({
  base: routerBasePathName,
  mode: 'history',
  routes,
});

middleware(router);

Vue.use(Meta, metaConfig);
Vue.use(Router);

export default router;
