import createPopper from '@/utils/popper/createPopper';

// @vue/component
export default {
  props: {
    tooltipId: {
      type: String,
      default: null,
    },
    tooltipColour: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    popperConfig: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    shouldShow() {
      return this.tooltipId && this.tooltipId !== '';
    },
  },
  methods: {
    showTooltip() {
      if (!this.shouldShow) {
        return;
      }

      this.destroyTooltip();

      const referenceElement = document.querySelector(`[data-tooltip-id="${this.tooltipId}"]`);

      const defaultPopperConfig = {
        placement: 'bottom',
        strategy: 'fixed',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 12],
            },
          },
          {
            name: 'arrow',
            options: {
              element: '[data-popper-arrow]',
            },
          },
        ],
      };

      const options = {
        ...defaultPopperConfig,
        ...this.popperConfig,
      };

      this.tooltipInstance = createPopper(referenceElement, this.$el, options);
    },

    destroyTooltip() {
      if (!this.tooltipInstance) {
        return;
      }
      this.tooltipInstance.destroy();
    },

    setTooltipColour() {
      // set defualt or custom colour of the tooltip
      this.$el.style.background = this.tooltipColour;
      this.$refs.arrow.style.borderColor = `transparent transparent ${this.tooltipColour} ${this.tooltipColour}`;
    },
  },
  mounted() {
    this.setTooltipColour();
  },
  updated() {
    this.setTooltipColour();
  },
  beforeDestroy() {
    this.destroyTooltip();
  },
  watch: {
    // if tooltipId changes and is not ''
    // then show the tooltip
    tooltipId: {
      handler(tooltipId) {
        if (tooltipId !== '') {
          this.showTooltip();
        }
      },
    },
  },
};
