
import VueAnalytics, { set } from 'vue-analytics';
import cnameHelper from '@/utils/helpers/CNameHelper';
import { isSunbeam } from '../helpers/environment.utility';

const isTwTest = cnameHelper().toLowerCase() === 'twtest';

const googleAnalyticsOptions = {
  id: process.env.TRACKING_ID || 'test',
  disabled: isTwTest || isSunbeam,
  debug: {
    enabled: false, // disable the verbose console logs
    sendHitTask: process.env.NODE_ENV === 'production' && !isTwTest,
  },
  autoTracking: {
    exception: true,
    prependBase: false,
    pageviewTemplate(route) {
      return {
        page: route.path,
        title: cnameHelper(), // use installation name
        location: window.location.href,
      };
    },
  },
};

const enableGoogleAnalyticsPlugin = (Vue, router) => {
  const options = {
    ...googleAnalyticsOptions,
    router,
  };
  Vue.use(VueAnalytics, options);
};

const setUserIdInGoogleAnalytics = (userId) => {
  if (!userId) { return; }
  set('userId', `${userId}`);
};

const setInstallationIdInGoogleAnalytics = (installationId) => {
  if (!installationId) { return; }
  set('dimension1', `${installationId}`);
};

const setIsAdminInAnalytics = (isSpacesAdmin) => {
  if (isSpacesAdmin) {
    set('dimension2', 'spacesAdmin');
    return;
  }
  set('dimension2', 'spacesCollaborator');
};

const setIsFreeTrialInAnalytics = (isFreeTrial) => {
  set('dimension3', `${isFreeTrial}`);
};

const setPlanUsers = (noOfUsersInPlan) => {
  if (!noOfUsersInPlan) { return; }
  set('dimension4', `${noOfUsersInPlan}`);
};

const setSignupProduct = (signUpProduct) => {
  if (!signUpProduct) { return; }
  set('dimension5', `${signUpProduct}`);
};

const setCollaboratorSession = () => {
  set('dimension6', 'collaborative-editing');
};

export {
  enableGoogleAnalyticsPlugin,
  setUserIdInGoogleAnalytics,
  setInstallationIdInGoogleAnalytics,
  setIsAdminInAnalytics,
  setPlanUsers,
  setSignupProduct,
  setIsFreeTrialInAnalytics,
  setCollaboratorSession,
};
