// This is used when a range is selected to get the reference for popper
export default (e) => {
  if (!e) {
    return {};
  }

  const boundingRectangle = e.getBoundingClientRect();
  const { left } = boundingRectangle;
  const { top } = boundingRectangle;
  const { right } = boundingRectangle;
  const { bottom } = boundingRectangle;
  const clientWidth = boundingRectangle.width;
  const clientHeight = boundingRectangle.height;

  return {
    getBoundingClientRect: () => ({
      width: clientWidth,
      height: clientHeight,
      top,
      right,
      bottom,
      left,
    }),
  };
};
