import Vue from 'vue';
import memoize from 'lodash-es/memoize';
import store from '@/store';
import router from '@/router';
import EditorWidgetsMixin from '@/utils/mixins/widgets.mixin';
import EditorInlineWidgetsMixin from '@/utils/mixins/inlineWidgets.mixin';
import { customElementComponents } from '@/utils/editor/config/internalEditorComponentsMeta';
import { define as definePlaceholder } from '@/components/widgets/EditorWidgets/TwPlaceholder';

/* eslint-disable no-param-reassign */
const addEditorBehaviorsToVueComponent = memoize((editorComponent, Component) => {
  Component.default.store = store;
  Component.default.router = router;
  Component.default.props = {
    diffable: {
      required: false,
      default: editorComponent.diffable === undefined ? false : editorComponent.diffable,
    },
    ...Component.default.props,
  };

  // copy mixins from the component
  if (Component.default.mixins && Component.default.mixins.length) {
    Component.default.mixins = [...Component.default.mixins];
  } else {
    Component.default.mixins = [];
  }

  // add default mixin for all widgets
  Component.default.mixins.push(EditorWidgetsMixin);

  // add inline widget mixin for inline widgets
  if (editorComponent.isInlineElement) {
    Component.default.mixins.push(EditorInlineWidgetsMixin);
  }

  return Component.default;
});

const registerCustomElement = (editorComponent) => {
  // ensure we have no duplicates
  const attributeSet = new Set([...editorComponent.attributes,
    'uuid',
    'data-mce-selected',
    'data-diffable',
    'data-inserted',
    'data-pasted']);

  editorComponent.attributes = Array.from(attributeSet).sort();

  if (editorComponent.component) {
    Vue.customElement(
      editorComponent.customElementName,
      () => import(`@/components/widgets/EditorWidgets/${editorComponent.component}/${editorComponent.component}.vue`)
        .then((Component) => addEditorBehaviorsToVueComponent(editorComponent, Component)),
      {
        shadow: false,
        props: editorComponent.attributes,
        destroyTimeout: 500,
        beforeCreateVueInstance(RootComponentDefinition) {
          // The purpose of this block is to ensure the component is not instantiated when it
          // shouldn't be i.e. when OT Client creates the node
          if (
            !(RootComponentDefinition.el instanceof HTMLElement)
            || !(RootComponentDefinition.el.parentNode instanceof HTMLElement)
            || !(RootComponentDefinition.el.parentNode.parentNode instanceof HTMLElement)
            // stops custom element be re-instantiated on tinymce selection
            || RootComponentDefinition.el.parentNode.parentNode.hasAttribute('data-mce-bogus')
          ) {
            return {
              render(createElement) {
                return createElement('div', '');
              },
            };
          }

          return RootComponentDefinition;
        },
        vueInstanceCreatedCallback() {
          this.setAttribute('contenteditable', 'false');
          this.classList.add('custom-element');

          if (!editorComponent.shouldIncludeInPrint) {
            this.classList.add('no-print');
          }
        },
      },
    );
  }

  return editorComponent;
};

export default () => {
  customElementComponents
    .forEach(registerCustomElement);

  // placeholder is a pure web component so is registered separately
  // from web components compiled from vue components
  definePlaceholder();
};

