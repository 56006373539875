import {
  shortcutMetaKey,
  shortcutModifierKey,
  getUnicodeCharacterForKey,
  getShortcutString,
} from '@/utils/helpers/keyboardShortcuts';

// @vue/component
export default {
  props: {
    shortcut: {
      type: Object,
      required: true,
    },
  },
  computed: {

    shortcutClasses() {
      return [
        'keyboard-shortcut-item__label',
        `keyboard-shortcut-item__label--${this.shortcut.id}`,
      ];
    },
  },
  methods: {
    getShortcutString(shortcutId, convertToUnicode) {
      return getShortcutString(shortcutId, convertToUnicode);
    },

    getShortcutMetaKey() {
      return shortcutMetaKey();
    },

    getShortcutModifierKey() {
      return shortcutModifierKey();
    },

    getUnicodeCharacterForKey(key) {
      return getUnicodeCharacterForKey(key);
    },
  },
};
