export default {
  namespaced: true,
  getters: {
    colors: (state) => state.colors.map((color) => `#${color.replace(/^#/, '')}`),
    icons: (state) => state.icons,
  },
  state: {
    colors: [
      '#494e6a',
      '#3d8aed',
      '#1fcbdc',
      '#ff61c7',
      '#f03063',
      '#f45721',
      '#feb51f',
      '#59c894',
      '#4461d7',
      '#6438e0',
    ],
    icons: [
      'address-book',
      'address-card',
      'alarm-clock',
      'archive',
      'box-full',
      'calendar-alt',
      'camera-retro',
      'bell',
      'book',
      'clock',
      'cut',
      'copy',
      'paste',
      'bullhorn',
      'calculator',
      'columns',
      'bookmark',
      'comment-alt-dots',
      'envelope-open',
      'desktop',
      'browser',
      'print',
      'bug',
      'lock-alt',
      'globe',
      'handshake',
      'home',
      'building',
      'map-marker-alt',
      'coffee',
      'phone',
      'clipboard-check',
      'clipboard-list',
      'file-alt',
      'file-check',
      'file-edit',
      'file-exclamation',
      'file-plus',
      'list-alt',
      'folder-open',
      'images',
      'pencil-alt',
      'info-square',
      'chart-pie',
      'chart-line',
      'chart-bar',
      'cloud',
      'cloud-upload',
      'cloud-download',
      'clone',
      'code',
      'code-branch',
      'code-commit',
      'cog',
      'credit-card',
      'smile',
      'frown',
      'meh',
    ],
  },
};
