/* eslint-disable arrow-body-style */
/**
 * Execute a tinymce command
 *
 * @param command - required - returns false if command not found
 * @param editor - defaults to window.activeEditor
 * @param ui - default false - probably wont need this
 * @param value - default value
 * @param args - default {} - some commands may take arguments
 * @returns {boolean|*|*}
 */
export default ({
  command = '',
  ui = false,
  value = false,
  args = {},
} = {},
editor = window.tinyMCE.activeEditor) => {
  return editor.execCommand(command, ui, value, args);
};
