import SpacesEditorToolbar from '@sections/SpacesEditor/SpacesEditorToolbar/SpacesEditorToolbar';
import SpacesEditorToolbarTableContextMenu from '@sections/SpacesEditor/SpacesEditorToolbarTableContextMenu';

// @vue/component
export default {
  props: {
    variant: {
      type: String,
      default: '',
      required: false,
    },
    toolbarVariant: {
      type: String,
      default: '',
      required: false,
    },
    toolbarButtonVariant: {
      type: String,
      default: '',
      required: false,
    },
  },

  components: {
    SpacesEditorToolbar,
    SpacesEditorToolbarTableContextMenu,
  },

  computed: {
    headerClasses() {
      return [
        's-spaces-editor-header',
        { [`s-spaces-editor-header--${this.variant}`]: this.variant },
      ];
    },
  },
};
