import { logError } from '@/utils/helpers/logger.utility';
import urlGetter from '@/utils/vuex/utils/urlGetter';
import getMetaFromResponse from '@/utils/vuex/utils/getMetaFromResponse';
import getIncludesFromResponse from '@/utils/vuex/utils/getIncludesFromResponse';
import shouldPermitStoreMutationViaSocket from '@/utils/helpers/shouldPermitStoreMutationViaSocket';

const createActions = ({
  actions,
  rootUrl,
  client,
  only,
  parseList,
  parseSingle,
  parseError,
  prepareConfig,
  preparePayload,
  socketEvents,
  onSocketDataReceived,
}) => {
  const crudActions = {};

  if (only.includes('FETCH_LIST')) {
    Object.assign(crudActions, {
      /**
       * GET /api/v<apiVersionNumber>/<resourceName>
       *
       * Fetch list of resources.
       */
      fetchList({ commit }, { config, customUrl, customUrlFnArgs = [] } = {}) {
        commit('fetchListStart');

        return client.get(
          urlGetter(rootUrl, { customUrl, customUrlFnArgs }),
          prepareConfig(config),
        )
          .then((res) => {
            const response = parseList(res);

            const includes = getIncludesFromResponse(res);

            const meta = getMetaFromResponse(res);

            commit('fetchListSuccess', { params: { response, meta, includes } });

            return response;
          })
          .catch((err) => {
            const parsedError = parseError(err);

            commit('fetchListError', parsedError);

            logError(err);

            return Promise.reject(parsedError);
          });
      },
    });
  }

  if (only.includes('FETCH_SINGLE')) {
    Object.assign(crudActions, {
      /**
       * GET /api/v<apiVersionNumber>/<resourceName>/:id
       *
       * Fetch single resource.
       */
      fetchSingle({ commit }, {
        id,
        config,
        customUrl,
        customUrlFnArgs = [],
      } = {}) {
        commit('fetchSingleStart');

        if (!id) {
          throw new Error('\'id\' parameter is missing or invalid');
        }

        return client.get(
          urlGetter(rootUrl, { id, customUrl, customUrlFnArgs }),
          prepareConfig(config),
        )
          .then((res) => {
            const response = parseSingle(res);

            const includes = getIncludesFromResponse(res);

            const meta = getMetaFromResponse(res);

            commit('fetchSingleSuccess', { params: { response, includes, meta } });

            return res;
          })
          .catch((err) => {
            const parsedError = parseError(err);

            commit('fetchSingleError', parsedError);

            logError(err);

            return Promise.reject(parsedError);
          });
      },
    });
  }

  if (only.includes('CREATE')) {
    Object.assign(crudActions, {
      /**
       * POST /api/v<apiVersionNumber>/<resourceName>
       *
       * Create a new resource.
       */
      create({ commit }, {
        data,
        config,
        customUrl,
        customUrlFnArgs = [],
      } = {}) {
        commit('createStart', { params: { data } });

        return client.post(
          urlGetter(rootUrl, { customUrl, customUrlFnArgs }),
          preparePayload(data, 'create'),
          prepareConfig(config),
        )
          .then((res) => {
            const response = Array.isArray(data) ? parseList(res) : parseSingle(res);

            const includes = getIncludesFromResponse(res);

            const meta = getMetaFromResponse(res);

            commit('createSuccess', { params: { response, includes, meta } });

            return response;
          })
          .catch((err) => {
            const parsedError = parseError(err);

            commit('createError', parsedError);

            logError(err);

            return Promise.reject(parsedError);
          });
      },
    });
  }

  if (only.includes('UPDATE')) {
    Object.assign(crudActions, {
      /**
       * PATCH /api/v<apiVersionNumber>/<resourceName>/:id
       *
       * Update a single resource.
       */
      update({ commit }, {
        id,
        data,
        config,
        customUrl,
        customUrlFnArgs = [],
        isOptimistic = false,
      } = {}) {
        commit('updateStart', { params: { id, data }, options: { isOptimistic } });

        return client.patch(
          urlGetter(rootUrl, { id, customUrl, customUrlFnArgs }),
          preparePayload(data, 'update'),
          prepareConfig(config),
        )
          .then((res) => {
            const response = parseSingle(res);

            const includes = getIncludesFromResponse(res);

            const meta = getMetaFromResponse(res);

            commit('updateSuccess', { params: { response, includes, meta }, options: { isOptimistic } });

            return response;
          })
          .catch((err) => {
            const parsedError = parseError(err);

            commit('updateError', parsedError);

            logError(err);

            return Promise.reject(parsedError);
          });
      },
    });
  }

  if (only.includes('REPLACE')) {
    Object.assign(crudActions, {
      /**
       * PUT /api/v<apiVersionNumber>/<resourceName>/:id
       *
       * Update a single resource.
       */
      replace({ commit }, {
        id,
        data,
        config,
        customUrl,
        customUrlFnArgs = [],
        isOptimistic = false,
      } = {}) {
        commit('replaceStart', { params: { id, data }, options: { isOptimistic } });

        return client.put(
          urlGetter(rootUrl, { id, customUrl, customUrlFnArgs }),
          preparePayload(data, 'replace'),
          prepareConfig(config),
        )
          .then((res) => {
            const response = parseSingle(res);

            const includes = getIncludesFromResponse(res);

            const meta = getMetaFromResponse(res);

            commit('replaceSuccess', { params: { response, includes, meta }, options: { isOptimistic } });

            return response;
          })
          .catch((err) => {
            const parsedError = parseError(err);

            commit('replaceError', parsedError);

            logError(err);

            return Promise.reject(parsedError);
          });
      },
    });
  }

  if (only.includes('DESTROY')) {
    Object.assign(crudActions, {
      /**
       * DELETE /api/v<apiVersionNumber>/<resourceName>/:id
       *
       * Destroy a single resource.
       */
      destroy({ commit }, {
        id,
        config,
        customUrl,
        customUrlFnArgs = [],
        isOptimistic = false,
      } = {}) {
        commit('destroyStart', { params: { id }, options: { isOptimistic } });

        return client.delete(
          urlGetter(rootUrl, { id, customUrl, customUrlFnArgs }),
          prepareConfig(config),
        )
          .then((res) => {
            commit('destroySuccess', { params: { id }, options: { isOptimistic } });

            return res;
          })
          .catch((err) => {
            const parsedError = parseError(err);

            commit('destroyError', parsedError);

            logError(err);

            return Promise.reject(parsedError);
          });
      },
    });
  }
  if (only.includes('DESTROY_ALL')) {
    Object.assign(crudActions, {
      /**
       * DELETE /api/v<apiVersionNumber>/<resourceName>
       *
       * Destroy all resources.
       */
      destroyAll({ commit }, {
        config,
        customUrl,
        customUrlFnArgs = [],
        isOptimistic = false,
      } = {}) {
        commit('destroyAllStart', { options: { isOptimistic } });

        return client.delete(
          urlGetter(rootUrl, { customUrl, customUrlFnArgs }),
          prepareConfig(config),
        )
          .then((res) => {
            commit('destroyAllSuccess', { options: { isOptimistic } });

            return res;
          })
          .catch((err) => {
            const parsedError = parseError(err);

            commit('destroyAllError', parsedError);

            logError(err);

            return Promise.reject(parsedError);
          });
      },
    });
  }

  if (socketEvents.length > 0) {
    Object.assign(crudActions, {
      storeIncomingSocketData({ commit, rootGetters }, response) {
        const parsedResponse = parseSingle(response);
        const shouldPersistData = shouldPermitStoreMutationViaSocket(rootGetters, parsedResponse);

        if (shouldPersistData) {
          commit('socket/appendSocketData', response, { root: true });
          onSocketDataReceived(response);
        }
      },
    });
  }

  if (socketEvents.includes('CREATE')) {
    Object.assign(crudActions, {
      createViaSocket({ commit, rootGetters }, response) {
        const parsedResponse = parseSingle(response);
        const shouldUpdateStore = shouldPermitStoreMutationViaSocket(rootGetters, parsedResponse);

        if (shouldUpdateStore) {
          commit('createViaSocket', { params: { response: parsedResponse } });
        }
      },
    });
  }

  if (socketEvents.includes('UPDATE')) {
    Object.assign(crudActions, {
      updateViaSocket({ commit, rootGetters }, response) {
        const parsedResponse = parseSingle(response);
        const shouldUpdateStore = shouldPermitStoreMutationViaSocket(rootGetters, parsedResponse);

        if (shouldUpdateStore) {
          commit('updateViaSocket', { params: { response: parsedResponse } });
        }
      },
    });
  }

  if (socketEvents.includes('DESTROY')) {
    Object.assign(crudActions, {
      destroyViaSocket({ commit, rootGetters }, response) {
        const shouldUpdateStore = shouldPermitStoreMutationViaSocket(rootGetters, response);

        if (shouldUpdateStore) {
          commit('destroyViaSocket', { params: { response: response.data } });
        }
      },
    });
  }

  return Object.assign(crudActions, actions);
};

export default createActions;
