import Vue from 'vue';
import { isPlainObject } from 'lodash-es';

const deepVueSet = (obj, props, value) => {
  if (!Array.isArray(props)) {
    throw new Error('\'props\' is not an Array');
  }

  const prop = props.shift();
  if (!obj[prop]) {
    Vue.set(obj, prop, {});
  }

  if (!props.length) {
    if (isPlainObject(value)) {
      Vue.set(obj, prop, { ...obj[prop], ...value });
    } else {
      Vue.set(obj, prop, value);
    }

    return;
  }

  deepVueSet(obj[prop], props, value);
};

export default deepVueSet;
