import { REQUESTER_TYPES } from '@/utils/constants/permissions';

export const PERMISSION_LEVELS = {
  EDIT: 'edit',
  READ: 'read',
};

if (Object.freeze) {
  Object.freeze(PERMISSION_LEVELS);
}

export { REQUESTER_TYPES } from '@/utils/constants/permissions';

export default {
  REQUESTER_TYPES,
  PERMISSION_LEVELS,
};
