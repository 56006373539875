import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import { extend } from 'lodash-es';

import DraftIndicator from '@sections/SpacesEditor/DraftIndicator';
import PageBreadcrumbs from '@widgets/PageBreadcrumbs';
import PageEditorFooter from '@sections/PageEditor/PageEditorFooter';
import PresentCollaboratorsList from '@widgets/PresentCollaboratorsList';
import SpacesEditor from '@sections/SpacesEditor';
import SpacesEditorToolbarButton from '@sections/SpacesEditor/SpacesEditorToolbar/SpacesEditorToolbarButton';
import SpacesEditorTitle from '@sections/SpacesEditor/SpacesEditorTitle';

import recordGlobalTemplateUsage from '@/utils/analytics/globalTemplates';
import { EDITOR_TYPES, pageEditorId } from '@/utils/constants/editorConfig';
import { logError } from '@/utils/helpers/logger.utility';
import { pageTitleMaxLength } from '@/utils/validations/pageTitleValidations';
import cnameHelper from '@/utils/helpers/CNameHelper';
import generatePageSlug from '@/utils/helpers/generatePageSlug';
import { DEFAULT_SPACE_PAGE_TITLE } from '@/utils/constants/spacePage';
import activeInlineCommentsMixin from '@/utils/mixins/activeInlineCommentsMixin';
import { ROUTE_NAMES } from '@/router/routes';

// @vue/component
export default {
  mixins: [activeInlineCommentsMixin],

  components: {
    DraftIndicator,
    HelpMenu: () => import('@widgets/HelpMenu'),
    PageBreadcrumbs,
    PageEditorFooter,
    PresentCollaboratorsList,
    SpacesEditor,
    SpacesEditorTitle,
    SpacesEditorToolbarButton,
  },

  data() {
    return {
      isGlobalTemplateUsed: false,
      usedTemplateName: '',
      editorType: EDITOR_TYPES.PAGE,
      pageEditorId,
      pageTitleMaxLength,
      shouldScrollToTitle: false,
    };
  },

  computed: {
    ...mapState('ui/spacesEditor', [
      'content',
      'isLoadingInitialContent',
      'title',
      'usersPresentList',
      'isEmpty',
      'templateUsedId',
      'isFullWidth',
    ]),

    ...mapState('ui/inlineComments', [
      'shouldShowCommentsGutter',
    ]),

    ...mapGetters('navigation', [
      'activePage',
      'activePageId',
      'activeSpace',
      'activeSpaceId',
      'isActivePageAHomepage',
      'isActivePageFullWidth',
      'isEditingSpacePage',
    ]),

    ...mapGetters('installation', {
      installationId: 'id',
    }),

    ...mapGetters('ui/spacesEditor', {
      isPageValid: 'isPageValid',
      changeMessage: 'changeMessage',
      shouldNotifyFollowers: 'shouldNotifyFollowers',
    }),

    ...mapGetters('ui/spacePage', ['canPublishPage', 'shouldShowOnboardingHelp']),

    ...mapGetters('activePagePermissions', ['isPrivateForOnlyMe']),

    ...mapGetters('mentions', {
      mentionableUsers: 'userList',
    }),

    areCollaboratorsPresent() {
      return this.usersPresentList.length > 0;
    },

    otDocumentId() {
      if (!this.installationId || !this.activePageId) {
        return null;
      }

      return `${this.installationId}-${this.activePageId}`;
    },

    otConfig() {
      return {
        documentId: this.otDocumentId,
        collectionName: 'spaces',
      };
    },

    editorTitle() {
      // If the title matches the default and the page is unpublished clear the editor title.
      // This is expected behaviour as the API expects a page title on creation. Creating from a
      // blank page we send a dummy default title to be replaced by the user here.
      if (!this.activePage.isPublished && this.activePage.title === DEFAULT_SPACE_PAGE_TITLE) {
        return '';
      }

      return this.isActivePageAHomepage
        ? this.activeSpace.title
        : this.activePage.title;
    },
  },

  methods: {
    ...mapActions('ui/inlineComments', [
      'toggleShouldShowCommentsGutter',
    ]),

    ...mapActions('ui/spacesEditor', [
      'clearEditingMetaData',
      'validateTitle',
    ]),

    ...mapActions('alerts', ['showAlert']),

    ...mapActions('modals', ['openModal', 'closeAllModals']),

    ...mapActions('pages', {
      updatePage: 'update',
    }),

    ...mapMutations('ui/spacesEditor', {
      setContent: 'setContent',
      setIsPublishing: 'setIsPublishing',
      setTemplateUsedId: 'setTemplateUsedId',
      setTitle: 'setTitle',
      resetValidation: 'resetValidation',
    }),

    onUseTemplateButtonClick() {
      this.openModal({
        activeModal: 'page-templates',
        modalProps: {
          shouldFocusFirstElement: false,
          onUseTemplateCallback: this.onUseTemplate,
        },
      });
    },

    onUseTemplate(template) {
      const tinymceInstance = window.tinyMCE && window.tinyMCE.get(this.pageEditorId);

      if (tinymceInstance) {
        if (!this.title && template.name) {
          this.resetValidation();
          this.setTitle(template.name);
        }

        tinymceInstance.setContent(template.content);
        this.$store.dispatch('ui/spacesEditor/setIsFullWidth', template.isFullWidth, { root: true });

        this.isGlobalTemplateUsed = (!template.createdBy && !template.createdAt);
        this.usedTemplateName = template.name;
        this.setTemplateUsedId(template.id);
        this.closeAllModals();
      }
    },

    onEditorReady() {
      if (!this.isEditingSpacePage) {
        return;
      }
      this.focusAppropiateElement();
    },

    focusAppropiateElement() {
      if (!this.editorTitle) {
        this.focusPageTitle();
      } else {
        this.focusEditor();
      }
    },

    focusPageTitle() {
      // This displeases me.
      if (this.$refs.editorTitle
        && this.$refs.editorTitle.$refs
        && this.$refs.editorTitle.$refs.title
        && this.$refs.editorTitle.$refs.title.focus
      ) { this.$refs.editorTitle.$refs.title.focus(); }
    },

    focusEditor() {
      const tinymceInstance = window.tinyMCE && window.tinyMCE.get(this.pageEditorId);

      if (tinymceInstance) {
        tinymceInstance.selection.select(tinymceInstance.getBody(), true);
        tinymceInstance.selection.collapse(true);
      }
    },

    async publishPage() {
      if (!this.canPublishPage) {
        return;
      }

      this.setIsPublishing(true);
      this.validateTitle({ maxLength: pageTitleMaxLength });

      if (!this.isPageValid) {
        this.setIsPublishing(false);
        this.shouldScrollToTitle = true;
        return;
      }

      const tinymceInstance = window.tinyMCE && window.tinyMCE.get(pageEditorId);

      if (!tinymceInstance) {
        this.setIsPublishing(false);
        this.showAlert({ type: 'error', message: 'Couldn\'t save changes to the page, tinymce instance not found' });
        return;
      }

      // Get OT client instance from tinyMCE instance.
      const otClientInstance = tinymceInstance.plugins?.ot?.client;

      if (!otClientInstance) {
        this.setIsPublishing(false);
        this.showAlert({ type: 'error', message: 'Couldn\'t save changes to the page, otClient instance not found' });
        return;
      }

      // create a new clone of the active page and
      // set its content to the inner html of the tinymce editor body
      // set its title to draft page title
      const newPage = Object.assign({}, this.activePage);
      newPage.content = this.content;
      newPage.title = this.title.trim();
      newPage.isFullWidth = this.isFullWidth;
      newPage.draftVersion = otClientInstance.version;

      const data = extend({}, newPage, {
        // If we should notify followers it isn't a minor change.
        isMinorChange: !this.shouldNotifyFollowers,
        changeMessage: this.changeMessage,
        isPublish: true,
        templateUsedId: this.isGlobalTemplateUsed ? null : this.templateUsedId,
      });

      // Removed state when publishing a page
      // so that it doesn't get accidently changed
      // when page is in trash
      delete data.state;

      if (this.isActivePageAHomepage) {
        delete data.slug;
        delete data.title;
        delete data.parentId;
      } else if (this.title !== this.activePage.title) {
        data.slug = generatePageSlug(this.title, false, this.parentPage && this.parentPage.title);
      }

      try {
        await this.updatePage({
          customUrlFnArgs: [this.activeSpaceId],
          id: this.activePageId,
          data,
        });

        if (this.isGlobalTemplateUsed) {
          recordGlobalTemplateUsage(this.templateUsedId);
        }

        if (this.$ga && this.templateUsedId) {
          const action = this.isGlobalTemplateUsed ? 'global-template' : 'local-template';
          this.$ga.event({
            eventCategory: 'templates',
            eventAction: `${action}-used-inside-editor`,
            eventLabel: this.usedTemplateName,
            eventValue: this.templateUsedId,
          });
        }

        const currentSlug = this.$route.params.pageCode.split(/-(.*)/)[1];
        if (!this.isActivePageAHomepage && currentSlug !== data.slug) {
          this.$router.push({
            name: ROUTE_NAMES.SPACE_PAGE,
            params: {
              spaceCode: this.activeSpace.code,
              pageCode: `${this.activePageId}-${data.slug}`,
            },
          });
        } else {
          this.$router.push({
            name: ROUTE_NAMES.SPACE_PAGE,
          });
        }

        if (this.templateUsedId) {
          this.setTemplateUsedId(null);
        }

        this.clearEditingMetaData();
      } catch (error) {
        logError(error);
        this.showAlert({ type: 'error', message: 'Couldn\'t save changes to the page' });
      }

      this.setIsPublishing(false);
    },

    closeEditor() {
      this.$router.push({ name: ROUTE_NAMES.SPACE_PAGE });
    },

    onToggleShouldShowCommentsGutter() {
      this.toggleShouldShowCommentsGutter();

      if (this.$ga) {
        this.$ga.event({
          eventCategory: 'editor-actions',
          eventAction: 'show-inline-comments',
          eventLabel: `${cnameHelper()} | ${this.$store.getters['navigation/activeSpace'].title} | ${this.$store.getters['navigation/activePage'].title}`,
          eventValue: 1,
        });
      }
    },
  },

  mounted() {
    this.$store.dispatch('ui/spacesEditor/setIsFullWidth', this.isActivePageFullWidth, { root: true });
  },

  beforeDestroy() {
    // Reset ui store so the full width state does not become out of sync with the db
    this.$store.dispatch('ui/spacesEditor/setIsFullWidth', this.isActivePageFullWidth, { root: true });
  },

  watch: {
    isEmpty: {
      immeditate: false,
      handler() {
        if (this.isEmpty) {
          this.setTemplateUsedId(null);
        }
      },
    },
    isEditingSpacePage: {
      immediate: false,
      handler(isEditingSpacePage) {
        if (isEditingSpacePage) {
          this.focusAppropiateElement();
        }
      },
    },
  },
};
