import formMixin from '@/utils/mixins/form.mixin';

// @vue/component
export default {
  mixins: [formMixin],
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    value: {
      type: [Boolean, String],
      required: false,
      default: '',
    },
    checked: {
      type: [Boolean, String],
      required: false,
      default: '',
    },
    radioLabel: {
      type: String,
      default: '',
    },
  },
  computed: {
    shouldBeChecked() {
      return this.checked === this.value;
    },

    shouldDisplayRadioButton() {
      return Object.keys(this.$slots).length === 0;
    },

    radioClasses() {
      if (this.shouldBeChecked) {
        return [
          { 'w-form-radio--is-checked': this.shouldBeChecked },
        ];
      }
      return null;
    },
  },
  methods: {
    onChange() {
      this.$emit('change', this.value);
    },
  },
};
