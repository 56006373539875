import { logError } from '@/utils/helpers/logger.utility';

const save = (key, value, expires) => {
  try {
    let cookie = `${key}=${value};path=/spaces;`;
    if (expires) {
      cookie += `expires=${expires};`;
    }
    document.cookie = cookie;
  } catch (e) {
    logError(`Cookie drivers: save ${key} error: ${e}`);
  }
};

const get = (key) => { // eslint-disable-line consistent-return
  try {
    const cookies = {};
    document.cookie.split('; ').forEach((item) => cookies[item.split('=')[0]] = item.split('=')[1]); // eslint-disable-line no-return-assign, prefer-destructuring

    return cookies[key];
  } catch (e) {
    logError(`Cookie drivers: get ${key} error: ${e}`);
  }
};

const remove = (key, path = '/') => {
  try {
    document.cookie = `${key}=; Path=${path}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  } catch (e) {
    logError(`Cookie drivers: remove ${key} error: ${e}`);
  }
};

const cookieDrivers = {
  save,
  get,
  remove,
};

export default cookieDrivers;
