import Vue from 'vue';
import { isPlainObject } from 'lodash-es';

import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import axios from '@/utils/vuex/client';
import { logError } from '@/utils/helpers/logger.utility';
import STARRED_OBJECT_TYPES from '@/utils/constants/starredObjectTypes';

function addStarredItem(state, type, item) {
  if (!state) {
    throw new Error('Missing required parameter "state"');
  }

  if (!type) {
    throw new Error('Missing required parameter "type"');
  }

  if (!Object.values(STARRED_OBJECT_TYPES).includes(type)) {
    throw new Error('Unsupported "type" parameter specified');
  }

  if (!item) {
    throw new Error('Missing required parameter "item"');
  }

  if (!isPlainObject(state.items[type])) {
    Vue.set(state.items, type, {});
  }

  Vue.set(state.items[type], Number(item.id), item);
}

function removeStarredItem(state, type, id) {
  if (!state) {
    throw new Error('Missing required parameter "state"');
  }

  if (!type) {
    throw new Error('Missing required parameter "type"');
  }

  if (!Object.values(STARRED_OBJECT_TYPES).includes(type)) {
    throw new Error('Unsupported "type" parameter specified');
  }

  if (!id) {
    throw new Error('Missing required parameter "id"');
  }

  if (!isPlainObject(state.items[type])) {
    return;
  }

  if (!state.items[type][Number(id)]) {
    return;
  }

  Vue.delete(state.items[type], Number(id));
}

async function fetch(commit, type) {
  if (!commit) {
    throw new Error('Missing required parameter "commit"');
  }

  if (!type) {
    throw new Error('Missing required parameter "type"');
  }

  if (!Object.values(STARRED_OBJECT_TYPES).includes(type)) {
    throw new Error('Unsupported "type" parameter specified');
  }

  // NOTE: This isn't actually working pages.spaces dont get included properly
  let includes = Object.values(STARRED_OBJECT_TYPES).join(',');
  includes += ',pages.spaces';

  try {
    const url = buildApiUrl({
      resource: '/starred.json',
    });

    const { data } = await axios.get(
      url,
      {
        params: {
          pageSize: 250,
          include: includes,
          'filter[objectType]': type,
        },
      },
    );

    commit('setStarredItems', { type, items: (data && data.included && data.included[type]) || {} });
  } catch (error) {
    logError(error);
  }
}

async function create(commit, type, item, url) {
  if (!commit) {
    throw new Error('Missing required parameter "commit"');
  }

  if (!type) {
    throw new Error('Missing required parameter "type"');
  }

  if (!Object.values(STARRED_OBJECT_TYPES).includes(type)) {
    throw new Error('Unsupported "type" parameter specified');
  }

  if (!item) {
    throw new Error('Missing required parameter "item"');
  }

  if (!url) {
    throw new Error('Missing required parameter "url"');
  }

  try {
    await axios.put(
      url,
    );

    commit('addStarredItem', { type, item });

    if (Vue.$ga) {
      Vue.$ga.event({
        eventCategory: `star-${type}`,
        eventAction: 'starred',
        eventLabel: '',
        eventValue: 1,
      });
    }
  } catch (error) {
    logError(error);

    commit('removeStarredItem', { type, id: Number(item.id) });
  }
}

async function remove(state, commit, type, id, url) {
  if (!state) {
    throw new Error('Missing required parameter "state"');
  }

  if (!commit) {
    throw new Error('Missing required parameter "commit"');
  }

  if (!type) {
    throw new Error('Missing required parameter "type"');
  }

  if (!Object.values(STARRED_OBJECT_TYPES).includes(type)) {
    throw new Error('Unsupported "type" parameter specified');
  }

  if (!id) {
    throw new Error('Missing required parameter "id"');
  }

  if (!url) {
    throw new Error('Missing required parameter "url"');
  }

  if (!isPlainObject(state.items) || !isPlainObject(state.items[type]) || !isPlainObject(state.items[type][id])) {
    throw new Error(`Attempted to 'unstar' ${type} (ID: ${id}) when it is not currently starred`);
  }

  const item = state.items[type][id];

  try {
    await axios.delete(url);

    commit('removeStarredItem', { type, id: Number(id) });

    if (Vue.$ga) {
      Vue.$ga.event({
        eventCategory: `star-${type}`,
        eventAction: 'starred',
        eventLabel: '',
        eventValue: 1,
      });
    }
  } catch (error) {
    logError(error);

    commit('addStarredItem', { type, item });
  }
}

export default {
  namespaced: true,
  state: {
    items: {},
  },
  getters: {
    // eslint-disable-next-line max-len
    spaces: (state) => ((isPlainObject(state.items) && isPlainObject(state.items[STARRED_OBJECT_TYPES.SPACES])) ? Object.values(state.items[STARRED_OBJECT_TYPES.SPACES]) : []),

    // eslint-disable-next-line max-len
    pages: (state) => ((isPlainObject(state.items) && isPlainObject(state.items[STARRED_OBJECT_TYPES.PAGES])) ? Object.values(state.items[STARRED_OBJECT_TYPES.PAGES]) : []),

    // eslint-disable-next-line max-len
    templates: (state) => ((isPlainObject(state.items) && isPlainObject(state.items[STARRED_OBJECT_TYPES.TEMPLATES])) ? Object.values(state.items[STARRED_OBJECT_TYPES.TEMPLATES]) : []),

    // eslint-disable-next-line max-len
    spaceIds: (state, getters) => getters.spaces.map((i) => Number(i.id)),

    // eslint-disable-next-line max-len
    pageIds: (state, getters) => getters.pages.map((i) => Number(i.id)),

    // eslint-disable-next-line max-len
    templateIds: (state, getters) => getters.templates.map((i) => Number(i.id)),

    isStarred(state, getters) {
      return (type, id) => {
        if (!type) {
          throw new Error('Missing required parameter "type"');
        }

        if (!Object.values(STARRED_OBJECT_TYPES).includes(type)) {
          throw new Error('Unsupported "type" parameter specified');
        }

        if (!id) {
          throw new Error('Missing required parameter "id"');
        }

        switch (type) {
          case STARRED_OBJECT_TYPES.SPACES: {
            return getters.spaceIds.includes(Number(id));
          }
          case STARRED_OBJECT_TYPES.PAGES: {
            return getters.pageIds.includes(Number(id));
          }
          case STARRED_OBJECT_TYPES.TEMPLATES: {
            return getters.templateIds.includes(Number(id));
          }
          default: {
            return false;
          }
        }
      };
    },

    isSpaceStarred(state, getters) {
      return (id) => getters.isStarred(STARRED_OBJECT_TYPES.SPACES, Number(id));
    },

    isPageStarred(state, getters) {
      return (id) => getters.isStarred(STARRED_OBJECT_TYPES.PAGES, Number(id));
    },

    isTemplateStarred(state, getters) {
      return (id) => getters.isStarred(STARRED_OBJECT_TYPES.TEMPLATES, Number(id));
    },
  },
  mutations: {
    setStarredItems(state, { type, items }) {
      Vue.set(state.items, type, items);
    },

    addStarredItem(state, { type, item }) {
      addStarredItem(state, type, item);
    },

    addStarredSpace(state, item) {
      addStarredItem(state, STARRED_OBJECT_TYPES.SPACES, item);
    },

    addStarredPage(state, item) {
      addStarredItem(state, STARRED_OBJECT_TYPES.PAGES, item);
    },

    addStarredTemplate(state, item) {
      addStarredItem(state, STARRED_OBJECT_TYPES.TEMPLATES, item);
    },

    removeStarredItem(state, { type, id }) {
      removeStarredItem(state, type, id);
    },

    removeStarredSpace(state, id) {
      removeStarredItem(state, STARRED_OBJECT_TYPES.SPACES, id);
    },

    removeStarredPage(state, id) {
      removeStarredItem(state, STARRED_OBJECT_TYPES.PAGES, id);
    },

    removeStarredTemplate(state, id) {
      removeStarredItem(state, STARRED_OBJECT_TYPES.TEMPLATES, id);
    },
  },
  actions: {
    async fetchStarredSpaces({ commit }) {
      fetch(commit, STARRED_OBJECT_TYPES.SPACES);
    },

    async fetchStarredPages({ commit }) {
      fetch(commit, STARRED_OBJECT_TYPES.PAGES);
    },

    async fetchStarredTemplates({ commit }) {
      fetch(commit, STARRED_OBJECT_TYPES.TEMPLATES);
    },

    async createStarredSpace({ commit }, space) {
      const url = buildApiUrl({
        resource: `/starred/spaces/${Number(space.id)}.json`,
      });
      create(commit, STARRED_OBJECT_TYPES.SPACES, space, url);
    },

    async createStarredPage({ commit }, page) {
      const url = buildApiUrl({
        resource: `/starred/spaces/${Number(page.space.id)}/pages/${Number(page.id)}.json`,
      });
      create(commit, STARRED_OBJECT_TYPES.PAGES, page, url);
    },

    async createStarredTemplate({ commit }, template) {
      const url = buildApiUrl({
        resource: `/starred/templates/${Number(template.id)}.json`,
      });
      create(commit, STARRED_OBJECT_TYPES.TEMPLATES, template, url);
    },

    async removeStarredSpace({ state, commit }, space) {
      const url = buildApiUrl({
        resource: `/starred/spaces/${Number(space.id)}.json`,
      });
      remove(state, commit, STARRED_OBJECT_TYPES.SPACES, Number(space.id), url);
    },

    async removeStarredPage({ state, commit }, page) {
      const url = buildApiUrl({
        resource: `/starred/spaces/${Number(page.space.id)}/pages/${Number(page.id)}.json`,
      });
      remove(state, commit, STARRED_OBJECT_TYPES.PAGES, Number(page.id), url);
    },

    async removeStarredTemplate({ state, commit }, template) {
      const url = buildApiUrl({
        resource: `/starred/templates/${Number(template.id)}.json`,
      });
      remove(state, commit, STARRED_OBJECT_TYPES.TEMPLATES, Number(template.id), url);
    },

    toggleSpaceStarredState({ getters, dispatch }, space) {
      if (!space) {
        throw new Error('Missing required parameter "space"');
      }

      if (!getters.isSpaceStarred(Number(space.id))) {
        dispatch('createStarredSpace', space);
      } else {
        dispatch('removeStarredSpace', space);
      }
    },

    togglePageStarredState({ getters, dispatch }, page) {
      if (!page) {
        throw new Error('Missing required parameter "page"');
      }

      if (!getters.isPageStarred(Number(page.id))) {
        dispatch('createStarredPage', page);
      } else {
        dispatch('removeStarredPage', page);
      }
    },

    toggleTemplateStarredState({ getters, dispatch }, template) {
      if (!template) {
        throw new Error('Missing required parameter "template"');
      }

      if (!getters.isTemplateStarred(Number(template.id))) {
        dispatch('createStarredTemplate', template);
      } else {
        dispatch('removeStarredTemplate', template);
      }
    },
  },
};
