
export default () => {
  let cname = window.location.hostname;

  // much better to jut get the cname for the installation id
  if (window.location.hostname.split('.').length > 0) {
    cname = window.location.hostname.split('.')[0];
  }

  return cname;
};
