import Vue from 'vue';
import { isBoolean } from 'lodash-es';

import axios from '@/utils/vuex/client';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import FollowingStates from '@/utils/constants/followingStates';
import cnameHelper from '@/utils/helpers/CNameHelper';

import store from '..';

const buildFollowUrl = (spaceId, pageId) => {
  if (!spaceId) {
    return null;
  }

  return buildApiUrl({
    resource: pageId
      ? `spaces/${spaceId}/pages/${pageId}/follow.json`
      : `spaces/${spaceId}/follow.json`,
  });
};

const makeFollowRequest = async (shouldFollow, spaceId, pageId) => {
  if (!spaceId) {
    return;
  }

  const method = shouldFollow ? 'patch' : 'delete';

  const requestObj = shouldFollow ? {
    following: {
      level: FollowingStates.allChanges,
    },
  } : undefined;

  if (pageId) {
    const { data } = await axios[method](buildFollowUrl(spaceId, pageId), requestObj);
    /* eslint-disable-next-line */
    return data;
  }

  const { data } = await axios[method](buildFollowUrl(spaceId), requestObj);
  /* eslint-disable-next-line */
  return data;
};

export default {
  namespaced: true,
  state: {
    activePageFollowLevel: 'none',
    activeSpaceFollowLevel: 'none',
  },
  getters: {
    stateForActivePage(state) {
      return state.activePageFollowLevel;
    },
    stateForActiveSpace(state) {
      return state.activeSpaceFollowLevel;
    },
  },
  mutations: {
    setPageFollowingState(state, data) {
      state.activePageFollowLevel = data.following && data.following.level ? data.following.level : 'none';
    },

    setSpaceFollowingState(state, data) {
      state.activeSpaceFollowLevel = data.following && data.following.level ? data.following.level : 'none';
    },
  },
  actions: {
    // This action performs initial fetch and update/deletion
    async setSpaceFollowingState({ commit, rootGetters }, params) {
      const spaceId = params.spaceId || rootGetters['navigation/activeSpaceId'];

      if (!params || !spaceId) {
        throw Error('Missing required parameter(s)');
      }

      if (params.fetch) {
        // Fetch the data and store it in state
        const { data } = await axios.get(buildFollowUrl(spaceId));

        commit('setSpaceFollowingState', data);
        return;
      }

      // Otherwise update state with provided value
      if (!isBoolean(params.shouldFollow)) {
        throw Error('Missing required parameter');
      }

      const response = await makeFollowRequest(params.shouldFollow, spaceId);

      commit('setSpaceFollowingState', response);
    },
    // This action performs initial fetch and update/deletion
    async setPageFollowingState({ commit, rootGetters }, params) {
      const spaceId = params.spaceId || rootGetters['navigation/activeSpaceId'];
      const pageId = params.pageId || rootGetters['navigation/activePageId'];

      if (!params || !spaceId || !pageId) {
        throw Error('Missing required parameter(s)');
      }

      if (params.fetch) {
        // Fetch the data and store it in state
        const { data } = await axios.get(buildFollowUrl(spaceId, pageId));

        commit('setPageFollowingState', data);
        return;
      }

      // Otherwise update state with provided value
      if (!isBoolean(params.shouldFollow)) {
        throw Error('Missing required parameter');
      }

      const response = await makeFollowRequest(params.shouldFollow, spaceId, pageId);

      commit('setPageFollowingState', response);
    },
  },

  onUpdateSuccess() {
    if (Vue.$ga) {
      Vue.$ga.event({
        eventCategory: 'following',
        eventAction: 'followed',
        eventLabel: `${cnameHelper()} | ${store.getters['navigation/activeSpace'].title} | ${store.getters['navigation/activePage'].title}`,
        eventValue: 1,
      });
    }
  },

  onDestroySuccess() {
    if (Vue.$ga) {
      Vue.$ga.event({
        eventCategory: 'following',
        eventAction: 'unfollowed',
        eventLabel: `${cnameHelper()} | ${store.getters['navigation/activeSpace'].title} | ${store.getters['navigation/activePage'].title}`,
        eventValue: 1,
      });
    }
  },
};
