import { mapGetters } from 'vuex';
import { isEmpty } from 'lodash-es';

import { STATES } from '@/utils/constants/spacePage';
import spaceConstants from '@/utils/constants/spaceConstants';
import { ROUTE_NAMES } from '@/router/routes';

import PageBreadcrumbItem from './PageBreadcrumbItem';

// @vue/component
export default {
  props: {
    isPageInTrash: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    PageBreadcrumbItem,
  },
  computed: {
    ...mapGetters('navigation', ['activePage', 'activeSpace', 'isActivePageAHomepage']),

    ...mapGetters('pages', ['byId']),

    spaceCode() {
      return this.$route.params.spaceCode;
    },

    treeLength() {
      return Array.isArray(this.tree) && !isEmpty(this.tree) && this.tree.length;
    },

    innerBreadcrumbs() {
      if (this.tree.length < 3) {
        return [];
      }

      return this.tree.slice(1, this.treeLength - 1);
    },

    tree() {
      let page = this.activePage
        && this.activePage.breadcrumb
        && Array.isArray(this.activePage.breadcrumb.childPages)
        && this.activePage.breadcrumb.childPages[0];

      if (
        this.activePage && this.activePage.state && this.activePage.state === STATES.REMOVED
        && this.activePage.breadcrumb.slug !== spaceConstants.defaultPageCode
      ) {
        // removed pages do not contain home page as first breadcrumb item
        page = this.activePage && this.activePage.breadcrumb;
      }

      const tree = [];

      if (page) {
        while (Array.isArray(page.childPages) && !isEmpty(page.childPages)) {
          tree.push({
            title: page.title,
            uri: {
              name: ROUTE_NAMES.SPACE_PAGE,
              params: {
                spaceCode: this.spaceCode,
                pageCode: `${page.id}-${page.slug}`,
              },
            },
          });

          page = page.childPages[0];
        }
      }

      return tree;
    },
  },
};
