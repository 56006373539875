import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';

import AnimatedEllipsis from '@widgets/AnimatedEllipsis';
import LoadingSpinner from '@widgets/LoadingPlaceholder/LoadingSpinner';
import SpacesEditorBody from '@sections/SpacesEditor/SpacesEditorBody';
import SpacesEditorCollaborativeBody from '@sections/SpacesEditor/SpacesEditorCollaborativeBody';
import SpacesEditorFooter from '@sections/SpacesEditor/SpacesEditorFooter';
import SpacesEditorHeader from '@sections/SpacesEditor/SpacesEditorHeader';

import { escapeKey } from '@/utils/constants/keyboardKeys';
import { isEventKeyEqualTo } from '@/utils/helpers/keyboardEvents';
import dataURLtoBlob from '@/utils/fileProcessing/dataURLtoBlob';
import { EDITOR_TYPES } from '@/utils/constants/editorConfig';

// @vue/component
export default {
  provide() {
    return {
      isCollaborative: this.isCollaborative,
      editorType: this.editorType,
    };
  },

  props: {
    editorType: {
      type: String,
      required: true,
      validator: (value) => Object.values(EDITOR_TYPES).includes(value),
    },
    editorId: {
      type: String,
      default: '',
      required: false,
    },
    isCollaborative: {
      type: Boolean,
      default: false,
      required: false,
    },
    isEditorActive: {
      type: Boolean,
      default: false,
      required: false,
    },
    initialContent: {
      type: String,
      required: false,
      default: '',
    },
    headerVariant: {
      type: String,
      default: '',
      required: false,
    },
    mentionsEnabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    mentionUsers: {
      type: Array,
      default: () => [],
      required: false,
    },
    toolbarVariant: {
      type: String,
      default: '',
      required: false,
    },
    toolbarButtonVariant: {
      type: String,
      default: '',
      required: false,
    },
    hasHeader: {
      type: Boolean,
      default: true,
      required: false,
    },
    hasFooter: {
      type: Boolean,
      default: true,
      required: false,
    },
    otConfig: {
      type: Object,
      default: () => {},
      required: false,
      validator: (value) => value.documentId && value.collectionName,
    },
  },

  components: {
    AnimatedEllipsis,
    LoadingSpinner,
    SpacesEditorBody,
    SpacesEditorCollaborativeBody,
    SpacesEditorFooter,
    SpacesEditorHeader,
  },

  computed: {
    ...mapState('apps', ['areAppsReady']),

    ...mapState('ui/inlineComments', [
      'shouldShowCommentsGutter',
    ]),

    ...mapState('ui/spacesEditor', [
      'isLoadingInitialContent',
      'shouldShowEstablishingConnectionScreen',
    ]),

    ...mapGetters('navigation', [
      'isPluginFullScreen',
    ]),

    editorBodyComponent() {
      return this.isCollaborative ? 'SpacesEditorCollaborativeBody' : 'SpacesEditorBody';
    },
  },

  methods: {
    ...mapActions('modals', ['openModal']),

    ...mapActions('alerts', ['showAlert']),

    ...mapMutations('ui/spacesEditor', {
      setIsLoadingInitialContent: 'setIsLoadingInitialContent',
    }),

    ...mapActions('ui/spacesEditor', [
      'setContent',
      'setIsEditorActive',
    ]),

    ...mapActions('ui/inlineComments', [
      'hideCommentsGutter',
    ]),

    closeEditor() {
      this.$emit('close');
    },

    onKeyUp(event) {
      if (!this.isEditorActive) {
        return;
      }

      if (isEventKeyEqualTo(event, escapeKey)) {
        if (this.$store.getters['ui/popover/hasOpenPopovers']) {
          return;
        }

        if (this.$store.getters['modals/hasOpenModal']) {
          return;
        }

        if (this.shouldShowCommentsGutter) {
          this.hideCommentsGutter();
          return;
        }

        this.closeEditor();
      }
    },

    onDragDrop(event) {
      if (event.dataTransfer.files.length !== 1) {
        return;
      }

      const displayResult = ({ value }) => {
        // match all data images and replace them with blobs
        const el = document.createElement('div');
        el.innerHTML = value;

        Array.from(el.querySelectorAll('img'))
          .forEach((img) => {
            // eslint-disable-next-line no-param-reassign
            img.src = dataURLtoBlob(img.src);

            // BUG FIX: if there are multiple images within a single
            // paragraph this causes an issue on the conversion to
            // a tw-spaces-image, therefore we need to wrap them within
            // their own p tag
            if (
              img.parentNode instanceof HTMLElement
              && img.parentNode.parentNode instanceof HTMLElement
              && img.parentNode.tagName === 'P'
              && img.nextSibling
              && img.nextSibling.tagName === 'IMG'
            ) {
              // lift it to new p tag
              const wrapperParagraph = document.createElement('p');
              img.parentNode.parentNode.insertBefore(wrapperParagraph, img.parentNode);
              wrapperParagraph.appendChild(img);
            }
          });

        const htmlWithSpacesImages = el.innerHTML.replace(/<img /g, '<tw-spaces-image ');

        window.tinyMCE.activeEditor.setContent(htmlWithSpacesImages);
        window.tinyMCE
          .activeEditor
          .selection
          .select(window.tinyMCE.activeEditor.bodyElement.childNodes[1]);
        window.tinyMCE.activeEditor.selection.collapse(false);
        document.querySelector('.page-editor-content').scrollTo(0, 0);
      };

      (async () => {
        const wordToHtml = await import(/* webpackChunkName: "word-to-html-mammoth" */ '@/utils/fileProcessing/wordToHtml');

        wordToHtml.default(event.dataTransfer.files[0], displayResult, () => {
          this.openModal({
            activeModal: 'alert',
            modalProps: {
              title: 'Word document is greater than 2MB',
              text: 'This word document is too large to convert directly to html, please add this as a file attachment.',
              variant: 'tertiary',
            },
          });
        });
      })();
    },

    onEditorReady() {
      this.$emit('ready');
      this.setIsLoadingInitialContent(false);
    },

    onSave() {
      this.$emit('save');
    },
  },

  mounted() {
    this.setIsLoadingInitialContent(true);

    document.addEventListener('keyup', this.onKeyUp);
  },

  beforeDestroy() {
    this.setContent('');

    document.removeEventListener('keyup', this.onKeyUp);
  },

  watch: {
    isEditorActive: {
      immediate: true,
      handler(isActive) {
        this.setIsEditorActive(isActive);
      },
    },
  },
};
