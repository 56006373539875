/*
* Before we make update to the store via sockets we need to check if its actually worth doing
* because user could be in completly different context (space or page) for incoming update.
* One of the examples is inline comments, they are linked to space/page context,
* if user is currently on home then inline comments information will be fetched
* when user navigates to the page. So there is no point of doing unecessary work for the store
*/
export default (rootGetters, { data }) => {
  const updatedBy = data.updatedBy && data.updatedBy.id ? data.updatedBy.id : null;
  const createdBy = data.createdBy && data.createdBy.id ? data.createdBy.id : null;
  const operationPerformedBy = updatedBy || createdBy;

  // Do not mutate store if operation was performed by current logged in user
  if (rootGetters['session/userId'] === operationPerformedBy) {
    return false;
  }

  let context = 'GLOBAL';
  const responsePageId = data.page && data.page.id ? data.page.id : null;
  const responseSpaceId = data.space && data.space.id ? data.space.id : null;
  const activeSpaceId = rootGetters['navigation/activeSpaceId'];
  const activePageId = rootGetters['navigation/activePageId'];

  // Modify the context if user is in space or in particular page
  if (responsePageId && responseSpaceId) {
    context = 'PAGE';
  } else if (responseSpaceId) {
    context = 'SPACE';
  }

  // Don't update the store when the user is in an invalid document draft.
  // i.e. user in a editor session during a page restore.
  if (
    context === 'PAGE'
    && data.identifier
    && rootGetters['navigation/isEditingSpacePage']
    && !rootGetters['ui/spacePage/canConsumeInlineCommentSocketEvents']
  ) {
    return false;
  }

  // User is in different space or not in space at all, don't update the store
  if ((context === 'SPACE' || context === 'PAGE') && responseSpaceId !== activeSpaceId) {
    return false;
  }

  // User is on different page within a space, don't update the store
  if (context === 'PAGE' && activePageId !== responsePageId) {
    return false;
  }

  return true;
};
