import Multiselect from 'vue-multiselect';
import ResizeObserver from 'resize-observer-polyfill';

import formMixin from '@/utils/mixins/form.mixin';
import createPopper from '@/utils/popper/createPopper';
import FormError from '../FormError';

// @vue/component
export default {
  mixins: [formMixin],
  props: {
    isMultiple: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: false,
      default: null,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    optionLabel: {
      type: String,
      default: null,
    },
    entityType: {
      type: String,
      required: false,
      default: '',
    },
    maxHeight: {
      type: Number,
      default: 160,
    },
    placeholder: {
      type: String,
      default: 'Select option',
    },
    shouldShowLabels: {
      type: Boolean,
      required: false,
      default: false,
    },
    shouldAllowEmpty: {
      type: Boolean,
      required: false,
      default: false,
    },
    shouldAutoSelectFirstOption: {
      type: Boolean,
      required: false,
      default: false,
    },
    shouldClearOnSelect: {
      type: Boolean,
      required: false,
      default: true,
    },
    shouldCloseOnSelect: {
      type: Boolean,
      required: false,
      default: true,
    },
    shouldResetAfterSelection: {
      type: Boolean,
      required: false,
      default: false,
    },
    shouldHideSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
    openDirection: {
      type: String,
      required: false,
      default: 'bottom',
    },
    optionHeight: {
      type: Number,
      default: 40,
    },
    trackBy: {
      type: String,
      default: null,
    },
    optionsGetter: {
      type: Function,
      required: false,
      default: null,
    },
    optionsGetterArgs: {
      type: Object,
      required: false,
      default: null,
    },
    optionsMapper: {
      type: Function,
      required: false,
      default: null,
    },
    shouldAllowSearch: {
      type: Boolean,
      required: false,
      default: true,
    },
    shouldCommitChange: {
      type: Boolean,
      required: false,
      default: true,
    },
    caretIcon: {
      type: Array,
      required: false,
      default: () => ['fal', 'chevron-down'],
    },
    rotateCaretIconWhenActive: {
      type: Boolean,
      required: false,
      default: true,
    },
    noResultsString: {
      type: String,
      default: 'No matches found. Consider changing the search query.',
    },
    positionFixed: {
      type: Boolean,
      required: false,
      default: true,
    },
    placement: {
      type: String,
      required: false,
      default: 'bottom',
    },
    altBoundary: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  components: {
    Multiselect,
    FormError,
  },

  data() {
    return {
      resizeObserver: null,
      isFocused: false,
      fetchedOptions: undefined,
      shouldPreventSearchChange: undefined,
      selectedOption: null,
      initSelection: null,
      popper: null,
    };
  },

  computed: {
    formSelectClasses() {
      return [
        { 'w-form-select--is-focused': this.isFocused },
        { 'w-form-select--error': this.validationError },
        { 'w-form-select--disabled': this.disabled },
      ];
    },

    filterKey() {
      if (this.entityType === 'users' || this.entityType === 'contacts') {
        return 'fullName';
      }
      return 'name';
    },

    /**
     * If the parent feeds an array of options, use those.
     * Otherwise, typing in the field will trigger a call to fetch all
     * the options from the API that contain what has been typed (see onSearchChange method).
     * It will populate the `fetchedOptions` property that will be returned by this
     * computed property. If the `fetchedOptions` property is undefined as well,
     * this computed property will trigger a call with no filter in order to
     * retrieve all the available options from the store.
     * If it does not have an option selected already and it should autoselect its first option,
     * it will find the first one in the fetchedOptions array and select it.
     */
    optionsList() {
      if (this.options && !this.fetchedOptions) {
        return this.options;
      }

      if (!this.fetchedOptions && this.optionsGetter) {
        this.updateOptions();
      }

      return this.fetchedOptions;
    },

    rotateClass() {
      return this.rotateCaretIconWhenActive ? 'spaces-icon-rotate' : '';
    },
  },

  methods: {
    repositionPopper() {
      if (this.popper) {
        this.popper.update();
      }
    },

    close() {
      this.$refs.multiselect.deactivate();
    },

    disableFocus() {
      this.isFocused = false;
    },

    enableFocus() {
      this.isFocused = true;
    },

    getOptionLabel(option) {
      if (this.optionLabel) {
        return option[this.optionLabel];
      }

      return option;
    },

    makeFieldDirty() {
      if (this.vuelidateOptions) {
        this.vuelidateOptions.$touch();
      }
    },

    onClose(value, id) {
      this.disableFocus();
      this.shouldPreventSearchChange = true;
      this.makeFieldDirty();
      this.$emit('close', value, id);
      this.$refs.multiselect.pointerReset();
    },

    onOpen() {
      this.isFocused = true;
      if (this.optionsGetter) {
        const filter = this.optionsGetterArgs ? this.optionsGetterArgs.filter : undefined;
        this.updateOptions({ filterFunction: filter });
      }
      // passing update options as a callback to refresh the options list
      // when data has been fetched by parent
      this.$emit('open', this.updateOptions);
    },

    onInput(value, id) {
      this.makeFieldDirty();
      this.$emit('input', value, id);
    },

    open() {
      this.$refs.multiselect.activate();
    },

    onSearchChange(input) {
      this.$emit('on-search-change', input);
    },

    onSelect(selectedOption, id) {
      this.selectedOption = selectedOption;
      this.$emit('select', selectedOption, id);
      this.$refs.focusElement.focus();

      if (this.shouldCommitChange) {
        this.$nextTick(() => {
          const currentSelection = this.currentSelection();
          if (this.initSelection === currentSelection) {
            this.$root.$emit('formRevertChange', 'form-select');
          } else {
            this.$root.$emit('formCommitChange', 'form-select');
          }
        });
      }
    },

    currentSelection() {
      const selectedOption = this.$el.querySelector('.multiselect__option--selected');

      if (selectedOption && selectedOption.children && selectedOption.children[0]
        && selectedOption.children[0].innerText) {
        return selectedOption.children[0].innerText.trim();
      }

      return '';
    },

    selectFirstOption() {
      const option = this.optionsList[0];
      if (option) {
        this.selectedOption = option;
        this.$emit('select', option, this.id);
      }
    },

    /**
     * Fetches the options from the store, filtering them using the filter function (if any).
     * The shouldResetSelectedOption allows to reset the selectedOption property, which will
     * allow the optionsList computed property to reselect the first option in the list, if
     * shouldAutoSelectedFirstOption is set to true. This is useful when the parent needs to
     * trigger an update of the options (is the case of updating stages options when the pipeline
     * changes for example).
     *
     * @param {Function} filterFunction - a function to filter the results
     * @param {Boolean} shouldResetSelectedOption - boolean to reset the selectedOption
     */
    updateOptions({ filterFunction, shouldResetSelectedOption } = {}) {
      if (shouldResetSelectedOption) {
        this.selectedOption = undefined;
      }

      // getting and filtering options from the store
      let options = this.optionsGetter({
        filter: filterFunction,
      });

      // extra filtering step if the parent specified it
      // (example: only display pipelines that are qualified)
      if (this.optionsGetterArgs && this.optionsGetterArgs.filter) {
        options = options.filter(this.optionsGetterArgs.filter);
      }

      this.fetchedOptions = this.optionsMapper(options);
    },

    resizeDropdown() {
      const $content = this.$el.querySelector('.multiselect__content-wrapper');

      if (!$content) {
        return;
      }

      $content.style.width = `${this.$el.offsetWidth}px`;
    },
  },
  mounted() {
    if (this.shouldAutoSelectFirstOption && !this.selectedOption) {
      this.selectFirstOption();
    }

    const multiselectInput = this.$el.querySelector('.multiselect__input');

    if (multiselectInput) {
      multiselectInput.setAttribute('data-lpignore', 'true');
    }

    this.$nextTick(() => {
      const currentSelection = this.currentSelection();
      if (currentSelection) {
        this.initSelection = currentSelection;
      }
    });

    const $content = this.$el.querySelector('.multiselect__content-wrapper');

    this.resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const { width } = entry.contentRect;

        if (!$content) {
          return;
        }

        $content.style.width = `${width}px`;
      });
    });

    this.resizeObserver.observe(this.$el);

    this.popper = createPopper(
      this.$el,
      $content,
      {
        placement: this.placement,
        ...(this.positionFixed ? { strategy: 'fixed' } : {}),
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              altBoundary: this.altBoundary,
            },
          },
          {
            name: 'hide',
          },
          {
            name: 'offset',
            options: {
              offset: [0, 4],
            },
          },
        ],
      },
    );
  },
  updated() {
    this.repositionPopper();
  },
  beforeDestroy() {
    if (this.resizeObserver instanceof ResizeObserver) {
      this.resizeObserver.disconnect();
    }

    if (this.popper) {
      this.popper.destroy();
    }
  },
};
