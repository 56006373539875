import Raven from 'raven-js';
import { isDevEnvironment, isSentryEnabled } from '@/utils/helpers/environment.utility';

const raiseSentryError = (error, options = undefined) => {
  if (!isSentryEnabled) {
    return;
  }

  if (isDevEnvironment) {
    return;
  }

  // blacklist specific status codes that we can ignore
  if (error
        && error.response
        && [401, 402, 404, 422].includes(error.response.status)) {
    return;
  }

  Raven.captureException(error, options);
};

const raiseSentryMessage = (message) => {
  if (!isSentryEnabled) {
    return;
  }

  if (isDevEnvironment) {
    return;
  }
  Raven.captureMessage(message);
};

export {
  // eslint-disable-next-line import/prefer-default-export
  raiseSentryError,
  raiseSentryMessage,
};
