// @vue/component
export default {
  props: {
    hasCancelButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    cancelButtonText: {
      type: String,
      required: false,
      default: 'Cancel',
    },
    submitButtonText: {
      type: String,
      required: false,
      default: 'Submit',
    },
    isSubmitting: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSubmitButtonDestructive: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: 'large',
    },
    variant: {
      type: String,
      required: false,
      default: 'positive',
    },
  },
  computed: {
    submitButtonVariant() {
      return this.isSubmitButtonDestructive ? 'negative' : this.variant;
    },
  },
};
