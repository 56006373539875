const icons = require.context('!babel-loader!vue-svg-loader!@/assets/icons', true, /.*\.svg$/); // require all .svgs in the @/assets/icons folder
const iconComponents = {};

icons.keys().forEach((path) => {
  const iconName = path.substring(path.lastIndexOf('/') + 1, path.lastIndexOf('.')); // find the bare name of the icon
  iconComponents[iconName] = icons(path).default;
});

// @vue/component
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  components: {
    ...iconComponents,
  },
  methods: {
    checkIfIconExists(name) {
      return name in iconComponents;
    },
  },
};
