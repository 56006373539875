import { createStaticCrudModule } from '@/utils/vuex';

import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';

export default createStaticCrudModule({
  only: ['CREATE'],
  property: 'feedback',
  urlRoot: buildApiUrl({
    resource: 'feedback',
  }),
});
