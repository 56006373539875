import Vue from 'vue';

import client from '@/utils/vuex/client';
import { createCrudModule } from '@/utils/vuex';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import cnameHelper from '@/utils/helpers/CNameHelper';
import { logError } from '@/utils/helpers/logger.utility';

import { moduleConfig } from './pageAttachments';
import store from '..';

const customUrlFn = (id, spaceId) => buildApiUrl({
  apiVersion: 'v1',
  resource: `spaces/${spaceId}/attachments${id ? `/${id}` : ''}`,
});

/*
 * This module exists to handle "space level" attachments which is a variation on the
 * existing attachments endpoint and as such makes use of the config for
 * that CRUD module to define it's own. Any differences/overrides are defined below.
 */
export default createCrudModule(
  Object.assign(moduleConfig, {
    customUrlFn,
    state: {
      defaultPageSize: 100,
    },
    getters: {
      // Include Space and page level attachments
      getAllAttachmentsInSpace: (state, getters) => (spaceId) => getters.list
        .filter((attachment) =>
          (attachment.space.id === spaceId)
          && (attachment.objectType === 'attachment'),
        )
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),

      // Space attachments must have objectType attachment and no page ID.
      getSpaceLevelAttachments: (state, getters) => (spaceId) =>
        getters.getAllAttachmentsInSpace && getters.getAllAttachmentsInSpace(spaceId)
          .filter((attachment) => (attachment.page.id === 0))
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),

      getSpaceAttachmentById: (state, getters) => (spaceId, attachmentId) => (
        getters.getAllAttachmentsInSpace && getters.getAllAttachmentsInSpace(spaceId)
          .find((attachment) => attachment.id === attachmentId)
      ) || {},

      canFetchMore: (state, getters) => { // eslint-disable-line
        if (
          state && state.currentMeta && state.currentMeta.page
          && state.currentMeta.page.count && state.entities
          && (Object.keys(state.entities).length < state.currentMeta.page.count)
        ) {
          return true;
        }

        return false;
      },
    },
    mutations: {
      updateMetaCount(state, newCount) {
        Vue.set(state.currentMeta.page, 'count', newCount);
      },

      removeAttachmentById(state, id) {
        Vue.delete(state.entities, id);
      },
    },
    actions: {
      // Note: we have to override the create action to allow for custom file payload
      create({ commit }, {
        data,
        config,
        customUrlFnArgs = [],
      } = {}) {
        commit('createStart', { params: { data } });

        return client.post(
          customUrlFn(...[null].concat(customUrlFnArgs)),
          data,
          config,
        )
          .then((response) => {
            const parsedResponse = Object.assign({}, response, {
              data: response.data.attachment,
            });

            commit('createSuccess', { params: { response: parsedResponse } });

            return parsedResponse;
          })
          .catch((error) => {
            commit('createError', error);

            logError(error);

            return Promise.reject(error);
          });
      },
      // Needed to sync a delete from page attachments module
      removeAttachmentById({ commit, state, dispatch }, id) {
        if (!id) {
          return;
        }

        commit('removeAttachmentById', id);
        dispatch('updateMetaCount', state.currentMeta.page.count - 1);
      },
      // Needed to sync the pagination meta after a delete from page attachments module
      updateMetaCount({ commit }, newCount) {
        if (!newCount && newCount !== 0) {
          return;
        }

        commit('updateMetaCount', newCount);
      },

      fetchPageOfSpaceAttachments(
        { dispatch, state },
        {
          customUrlFnArgs,
          pageOffset = state.entities.length,
          pageSize = state.defaultPageSize,
        } = {}) {
        return dispatch('fetchList', {
          customUrlFnArgs,
          config: {
            params: {
              pageOffset,
              pageSize,
              'filter[objectType]': 'attachment',
            },
          },
        });
      },
    },
    onFetchListStart() {
      // This store module should aggregate all entities to support pagination.
      // As it piggy backs off the pageAttachments store, we need to override it
      // so that it does not clear before fetching the next lot of attachments
    },
    onCreateSuccess() {
      if (Vue.$ga) {
        Vue.$ga.event({
          eventCategory: 'space-attachment',
          eventAction: 'created',
          eventLabel: (() => [
            cnameHelper(),
            store.getters['navigation/activeSpace'].title,
          ]
            .filter((a) => a)
            .join(' | '))(),
          eventValue: 1,
        });
      }
    },
    onDestroySuccess(state) {
      if (Vue.$ga) {
        Vue.$ga.event({
          eventCategory: 'space-attachment',
          eventAction: 'deleted',
          eventLabel: (() => [
            cnameHelper(),
            store.getters['navigation/activeSpace'].title,
          ]
            .filter((a) => a)
            .join(' | '))(),
          eventValue: 1,
        });
      }

      store.dispatch('spaceAttachments/updateMetaCount', state.currentMeta.page.count - 1);
    },
  }),
);
