import Vue from 'vue';

import { createStaticCrudModule } from '@/utils/vuex';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import spaceConstants from '@/utils/constants/spaceConstants';

export default createStaticCrudModule({
  property: 'pages',
  customUrlFn() {
    return buildApiUrl({
      resource: 'links/pages.json',
    });
  },
  getters: {
    getPageLinkById: (state) => (id) => state.entity[id],

    pageLinks: (state) => Object.keys(state.entity)
      .map((key) => ({
        pageId: key,
        pageTitle: state.entity[key][0],
        pageSlug: state.entity[key][1],
        spaceCode: state.entity[key][2],
        isHomePage: state.entity[key][1] === spaceConstants.defaultPageCode,
      })),

    getHomepageLinkBySpaceCode: (state, getters) => (spaceCode) => getters.homepageLinks[spaceCode],

    /* eslint-disable no-param-reassign */
    homepageLinks: (state, getters) => getters.pageLinks.reduce((acc, pageLink) => {
      if (pageLink.isHomePage) {
        acc[pageLink.spaceCode] = pageLink;
      }

      return acc;
    }, {}),
    /* eslint-enable no-param-reassign */
  },
  mutations: {
    addNewPageLink(state, {
      pageId, pageTitle, pageCode, spaceCode,
    }) {
      Vue.set(state.entity, pageId, [pageTitle, pageCode, spaceCode]);
    },

    deletePageLink(state, { pageId }) {
      Vue.delete(state.entity, pageId);
    },

    updatePageTitle(state, { pageId, title }) {
      if (state.entity[pageId]) {
        // link map =  { pageId: [pageTitle, pageSlug, spaceCode ] }
        state.entity[pageId][0] = title;
      }
    },

    updateSpaceCode(state, { pageId, spaceCode }) {
      if (state.entity[pageId] && state.entity[pageId][2] !== spaceCode) {
        state.entity[pageId][2] = spaceCode;
      }
    },
  },
  actions: {
    addNewPageLink({ commit }, {
      pageId, pageTitle, pageCode, spaceCode,
    }) {
      commit('addNewPageLink', {
        pageId, pageTitle, pageCode, spaceCode,
      });
    },
    deletePageLink({ commit }, pageId) {
      commit('deletePageLink', pageId);
    },
    updatePageTitle({ commit }, { pageId, title }) {
      commit('updatePageTitle', { pageId, title });
    },
    updateSpaceCode({ commit }, { pageId, spaceCode }) {
      commit('updateSpaceCode', { pageId, spaceCode });
    },
  },
});
