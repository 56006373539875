import { nextTick } from 'vue-demi';
import { mapActions, mapGetters, mapState } from 'vuex';

import CHECKOUT_CODES from '@/utils/constants/checkoutCodes';
import { endpointUrls } from '@/utils/constants/twEndpoints';
import LOCAL_STORAGE from '@/utils/constants/localStorage';
import localStorage from '@/utils/drivers/localstorage';
import cookie from '@/utils/drivers/cookie';
import { ROUTE_NAMES } from '@/router/routes';

// @vue/component
export default {
  computed: {
    ...mapState('ui/people', ['shouldSuppressCheckoutModal']),

    ...mapGetters('installation', {
      checkoutErrorCode: 'checkoutErrorCode',
      inGracePeriod: 'inGracePeriod',
      paymentMethod: 'paymentMethod',
      paymentCollectionMethod: 'paymentCollectionMethod',
    }),

    isOnPeopleRoute() {
      return !!this.$route.matched.find((route) => route.name === ROUTE_NAMES.PEOPLE);
    },

    isOnSettingsRoute() {
      return !!this.$route.matched.find((route) => route.name === ROUTE_NAMES.SETTINGS_SUBSCRIPTION);
    },

    shouldShowCheckoutModal() {
      return !(this.isOnSettingsRoute && this.isSalesAssisted) && !(
        this.isOnPeopleRoute
          && this.$store.getters['session/canManageSubscription']
          && [
            CHECKOUT_CODES.SUBSCRIPTION_EXPIRED,
            CHECKOUT_CODES.TRIAL_EXPIRED,
            CHECKOUT_CODES.OVERSUBSCRIBED,
          ].indexOf(this.checkoutErrorCode) > -1
      );
    },

    // Note: this is a definitive list of all checkout modals. It's important to keep this accurate
    checkoutModals() {
      const modals = [];

      modals.push('subscription-expired');
      modals.push('installation-locked');
      modals.push('grace-period');
      modals.push('trial-expired');
      modals.push('oversubscribed');
      modals.push('trial-expired');
      modals.push('user-disabled');
      modals.push('page-limit-reached');
      modals.push('user-limit-reached');
      modals.push('subscription-invalid');

      return modals;
    },

    isSalesAssisted() {
      return this.paymentMethod === 'stripe' && this.paymentCollectionMethod === 'send_invoice';
    },
  },
  methods: {
    ...mapActions('modals', ['closeModal', 'openModal']),

    ...mapActions('ui/people', ['suppressCheckoutModal', 'unsuppressCheckoutModal']),

    closeCheckoutModals() {
      if (this.checkoutModals.includes(this.$store.getters['modals/getPrimaryModal'])) {
        this.closeModal();
      }

      if (this.checkoutModals.includes(this.$store.getters['modals/getSecondaryModal'])) {
        this.closeModal();
      }
    },

    showAppropriateModal() {
      if (!this.shouldShowCheckoutModal) {
        this.closeCheckoutModals();
        return;
      }

      switch (this.checkoutErrorCode) {
        case CHECKOUT_CODES.SUBSCRIPTION_INVALID: {
          this.openModal({ activeModal: 'subscription-invalid' });

          break;
        }
        case CHECKOUT_CODES.SUBSCRIPTION_EXPIRED: {
          if (this.$store.getters['session/canManageSubscription']) {
            this.openModal({ activeModal: 'subscription-expired' });
          } else {
            this.openModal({ activeModal: 'installation-locked' });
          }

          break;
        }
        case CHECKOUT_CODES.GRACE_PERIOD: {
          if (this.$store.getters['session/canManageSubscription']) {
            const hasDismissed = localStorage.get(LOCAL_STORAGE.GRACE_PERIOD_WARNING_DISMISSED) === cookie.get('tw-auth');

            if (!hasDismissed) {
              this.openModal({ activeModal: 'grace-period', modalProps: { isSalesAssisted: this.isSalesAssisted } });
            }
          }

          break;
        }
        case CHECKOUT_CODES.TRIAL_EXPIRED: {
          if (this.$store.getters['session/canManageSubscription']) {
            this.openModal({ activeModal: 'trial-expired' });
          } else {
            this.openModal({ activeModal: 'installation-locked' });
          }

          break;
        }
        case CHECKOUT_CODES.OVERSUBSCRIBED: {
          if (this.$store.getters['session/canManageSubscription']) {
            this.openModal({ activeModal: 'oversubscribed' });
          } else {
            this.openModal({ activeModal: 'installation-locked' });
          }

          break;
        }
        case CHECKOUT_CODES.USER_NOT_ENABLED: {
          this.openModal({ activeModal: 'user-disabled' });
          break;
        }
        case CHECKOUT_CODES.PAGE_LIMIT_REACHED: {
          this.openModal({ activeModal: 'page-limit-reached' });
          break;
        }
        case CHECKOUT_CODES.USER_LIMIT_REACHED: {
          this.openModal({ activeModal: 'user-limit-reached' });
          break;
        }
        default: {
          this.closeCheckoutModals();
          break;
        }
      }
    },

    lockOrUnlockInstallation() {
      if (
        [
          CHECKOUT_CODES.SUBSCRIPTION_EXPIRED,
          CHECKOUT_CODES.TRIAL_EXPIRED,
          CHECKOUT_CODES.OVERSUBSCRIBED,
          CHECKOUT_CODES.SPACES_NOT_SETUP,
          CHECKOUT_CODES.USER_NOT_ENABLED,
        ].indexOf(this.checkoutErrorCode) > -1
      ) {
        this.$store.dispatch('installation/lockInstallation');
      } else {
        this.$store.dispatch('installation/unlockInstallation');
      }
    },

    handleErrorCode() {
      if (this.checkoutErrorCode === CHECKOUT_CODES.TRIAL_EXPIRED) {
        // installation fetch as we need to update store so we get the correct expiry dates
        this.$store.dispatch('installation/fetch');
      }

      if (this.checkoutErrorCode === CHECKOUT_CODES.SPACES_NOT_SETUP) {
        window.location.href = endpointUrls.notSetup;
        return;
      }

      this.lockOrUnlockInstallation();
      this.showAppropriateModal();
    },
  },
  render() {
    return null;
  },
  watch: {
    inGracePeriod: {
      immediate: true,
      handler() {
        // Note: 'grace period' is a special case whereby the API does not return a checkout error
        // code. Therefore we are manually setting the error code as if it did to trigger the
        // 'grace period' logic
        if (this.inGracePeriod) {
          this.$store.dispatch('installation/setCheckoutErrorCode', CHECKOUT_CODES.GRACE_PERIOD);
        }
      },
    },
    checkoutErrorCode: {
      immediate: true,
      handler() {
        this.handleErrorCode();
      },
    },
    $route: {
      immediate: false,
      handler() {
        if (this.checkoutErrorCode === CHECKOUT_CODES.SPACES_NOT_SETUP) {
          // redirect to launchpad
          window.location.href = endpointUrls.notSetup;
          return;
        }

        this.lockOrUnlockInstallation();

        // Note: we need to suppress the modal if the user arrived here from another
        // CheckoutHandler modal on a different route
        if (this.isOnPeopleRoute || (this.isOnSettingsRoute && this.isSalesAssisted)) {
          if (this.shouldSuppressCheckoutModal) {
            this.unsuppressCheckoutModal();
            return;
          }
        }

        nextTick(() => {
          this.showAppropriateModal();
        });
      },
    },
  },
};
