var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'skeleton-text': true,
    'skeleton-text--paragraph': _vm.isParagraph,
  },style:({ width: _vm.width })},_vm._l((_vm.lines),function(line,index){return _c('p',{key:index,class:{
      'skeleton-text__text': true,
      'skeleton-text__text--heading': _vm.isHeading,
      'skeleton-text__text--dark': _vm.isDark
    },style:({ width: line.width })})}),0)}
var staticRenderFns = []

export { render, staticRenderFns }