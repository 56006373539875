import Vue from 'vue';

import cnameHelper from '@/utils/helpers/CNameHelper';
import { createCrudModule } from '@/utils/vuex';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import { RESOURCE_TYPES } from '@/utils/constants/reactions';

import store from '..';

function storeReactionsMetaForComments(comments) {
  comments.forEach((comment) => {
    store.commit('reactions/setMetaForResource', {
      resourceType: RESOURCE_TYPES.INLINE_COMMENT,
      resourceId: comment.id,
      meta: comment.reactions,
    });

    if (Array.isArray(comment.replies)) {
      storeReactionsMetaForComments(comment.replies);
    }
  });
}

export default createCrudModule({
  only: ['FETCH_LIST', 'CREATE', 'UPDATE', 'DESTROY'],
  socketEvents: ['CREATE', 'UPDATE', 'DESTROY'],
  resource: 'comment',
  customUrlFn(id, spaceId, pageId) {
    return buildApiUrl({
      resource: id
        ? `spaces/${spaceId}/pages/${pageId}/comments/inline/${id}`
        : `spaces/${spaceId}/pages/${pageId}/comments/inline`,
    });
  },
  state: {
    commentsWithNewReplies: [],
    newInlineCommentAvailableInReadMode: false,
    shouldRemoveOrphanedInlineComments: false,
  },
  getters: {
    getActiveCommentIdentifiersForPage(state, getters) {
      return (pageId, userCanEditSpace) => getters.list
        .filter((entity) => (
          entity.page.id === pageId
          && entity.parentId === undefined
          && entity.state === 'active'
        ))
        .filter((entity) => (userCanEditSpace ? true : !entity.isPrivate))
        .map((comment) => comment.identifier);
    },

    /* eslint-disable */
    activeCommentsForPage(state, getters, rootState) {
      return (pageId, userCanEditSpace) => {
        return getters.list
          .filter((entity) => (
            rootState.ui.inlineComments.selectedCommentIds.includes(entity.identifier) ||
            rootState.ui.inlineComments.selectedCommentIds.length === 0
          ))
          .filter((entity) => (
            entity.page.id === pageId &&
            entity.parentId === undefined &&
            entity.state === 'active'
          ))
          .filter((entity) => (userCanEditSpace ? true : !entity.isPrivate))
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .sort((a, b) => {
            const aHasNewReply = state.commentsWithNewReplies.includes(a.id);
            const bHasNewReply = state.commentsWithNewReplies.includes(b.id);

            // sort comments with active new reply to the top
            return aHasNewReply === bHasNewReply ? 0 : aHasNewReply ? -1 : 1;
          });
      }
    },

    closedCommentsForPage(state, getters) {
      return (pageId) => getters.list
        .filter((entity) => (
          entity.page.id === pageId &&
          entity.parentId === undefined &&
          entity.state === 'closed'
        ))
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    },

    shouldRemoveOrphanedInlineComments: (state) => state.shouldRemoveOrphanedInlineComments,
  },
  mutations: {
    removeReplyFromComment(state, inlineComment) {
      state.entities[inlineComment.parentId].replies =
        state.entities[inlineComment.parentId].replies
          .filter((reply) => reply.id !== inlineComment.id);
    },

    resolveComment(state, id) {
      state.entities[id.toString()].state = 'closed';
    },

    updateReplyInState(state, { parentId, id, content }) {
      const inlineComment = state.entities[parentId.toString()];
      const reply = inlineComment.replies.find((ic) => ic.id === id);

      reply.content = content;
    },

    includeInlineCommentAsReply(state, inlineComment) {
      const parentId = inlineComment.parentId;

      if (!state.entities[parentId]) {
        return;
      }

      if (!state.entities[parentId].replies) {
        Vue.set(state.entities[parentId], 'replies', [inlineComment]);
      } else if (!state.entities[parentId].replies.some((e) => e.id === inlineComment.id)) {
        // only push reply if it hasn't been added already by either socket or http request
        state.entities[parentId].replies.push(inlineComment);
      }
    },

    setCommentsWithNewReplies(state, commentsWithNewReplies) {
      state.commentsWithNewReplies = commentsWithNewReplies;
    },

    clearCommentsWithNewReplies(state) {
      state.commentsWithNewReplies = [];
    },

    setNewInlineCommentAvailableInReadMode(state, newCommentAvailable) {
      state.newInlineCommentAvailableInReadMode = newCommentAvailable;
    },

    setShouldRemoveOrphanedInlineComments(state, shouldRemoveOrphanedInlineComments) {
      state.shouldRemoveOrphanedInlineComments = shouldRemoveOrphanedInlineComments;
    }
  },
  actions: {
    resolveComment({ commit }, id) {
      commit('resolveComment', id);
    },

    updateReplyInState({ commit }, { parentId, id, content }) {
      commit('updateReplyInState', { parentId, id, content });
    },
  },
  onFetchListStart(state) {
    // This store module should only contain the entities for the current page, so we need to
    // clear it before fetching
    state.entities = {};
  },
  onFetchSingleSuccess(state, response) {
    storeReactionsMetaForComments([response.data]);
  },
  onFetchListSuccess(state, response) {
    storeReactionsMetaForComments(response.data);
  },
  onCreateSuccess(state, response) {
    if (Vue.$ga) {
      Vue.$ga.event({
        eventCategory: 'inline-comments',
        eventAction: 'created',
        eventLabel: `${cnameHelper()} | ${store.getters['navigation/activeSpace'].title} | ${store.getters['navigation/activePage'].title}`,
        eventValue: 1,
      });
    }

    // push comment to parent comment.replies array
    if (response.data.parentId) {
      store.commit('inlineComments/includeInlineCommentAsReply', response.data);
    }
  },
  onDestroySuccess() {
    if (Vue.$ga) {
      Vue.$ga.event({
        eventCategory: 'inline-comments',
        eventAction: 'resolved',
        eventLabel: `${cnameHelper()} | ${store.getters['navigation/activeSpace'].title} | ${store.getters['navigation/activePage'].title}`,
        eventValue: 1,
      });
    }
  },
  onCreateViaSocketSuccess(state, inlineComment) {
    if (inlineComment.parentId) {
      store.commit('inlineComments/includeInlineCommentAsReply', inlineComment);

      if (
        store.state.ui.inlineComments.activeCommentId !== inlineComment.parentId &&
        !state.commentsWithNewReplies.includes(inlineComment.parentId)
      ) {
        store.commit('inlineComments/setCommentsWithNewReplies', [inlineComment.parentId, ...state.commentsWithNewReplies]);
      }
    } else if (!inlineComment.isPrivate) {
      // in order to load new comments in read mode reload is needed, display info to the user
      // so that they can load new comments when they arrive through socket
      store.commit('inlineComments/setNewInlineCommentAvailableInReadMode', true);
      store.commit('ui/spacePage/setCanRefetchPage', true);
    } else if (inlineComment.isPrivate
      && !store.getters['navigation/isEditingSpacePage']
      && store.getters['ui/spacePage/allowInlineCommentsInReadMode']
    ) {
      // When private inline comment arrives, and current user is not in editor we need to sync up
      // editor content by connecting ot and disconnecting it to retrieve latest comment markers
      store.commit('ui/spacePage/setShouldResyncOtContent', true);
    }
  },
  onUpdateViaSocketSuccess(state, inlineComment) {
    if (inlineComment.parentId) {
      store.commit('inlineComments/updateReplyInState', inlineComment);
    }
  },
  onDestroyViaSocketSuccess(state, inlineComment) {
    if (inlineComment.parentId) {
      store.commit('inlineComments/removeReplyFromComment', inlineComment);
    } else {
      // we need to clean up any comment markers for the comment that was removed through the socket
      store.commit('inlineComments/setShouldRemoveOrphanedInlineComments', true);
    }
  }
});
