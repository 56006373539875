import { register } from 'register-service-worker';
import { raiseSentryError } from '@/utils/helpers/sentryHelper';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.VUE_APP_PUBLIC_PATH}service-worker.js`, {
    error(error) {
      raiseSentryError(error);
    },
  });
}
