import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    popoverStates: {},
  },
  mutations: {
    deletePopoverState(state, { id }) {
      Vue.delete(state.popoverStates, id);
    },

    setPopoverState(state, { id, isOpen }) {
      if (typeof isOpen === 'undefined') {
        Vue.delete(state.popoverStates, id);
        return;
      }

      Vue.set(state.popoverStates, id, isOpen);
    },
  },
  actions: {
    deletePopoverState({ commit }, { id }) {
      commit('deletePopoverState', { id });
    },

    setPopoverState({ commit }, { id, isOpen }) {
      commit('setPopoverState', { id, isOpen });
    },
  },
  getters: {
    getPopoverState(state) {
      return (id) => state.popoverStates[id];
    },

    hasOpenPopovers(state) {
      return !!(Object.values(state.popoverStates).find((s) => s));
    },
  },
};
