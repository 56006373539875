import { faAnchor } from '@fortawesome/pro-solid-svg-icons/faAnchor';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown';
import { faAngry } from '@fortawesome/pro-solid-svg-icons/faAngry';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import { faBars } from '@fortawesome/pro-solid-svg-icons/faBars';
import { faBookReader } from '@fortawesome/pro-solid-svg-icons/faBookReader';
import { faCamera } from '@fortawesome/pro-solid-svg-icons/faCamera';
import { faChartLine } from '@fortawesome/pro-solid-svg-icons/faChartLine';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faChevronDoubleLeft } from '@fortawesome/pro-solid-svg-icons/faChevronDoubleLeft';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp';
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { faClipboardList } from '@fortawesome/pro-solid-svg-icons/faClipboardList';
import { faComment } from '@fortawesome/pro-solid-svg-icons/faComment';
import { faCommentAltLines } from '@fortawesome/pro-solid-svg-icons/faCommentAltLines';
import { faEllipsisH } from '@fortawesome/pro-solid-svg-icons/faEllipsisH';
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons/faEllipsisV';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons/faExternalLink';
import { faEye } from '@fortawesome/pro-solid-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-solid-svg-icons/faEyeSlash';
import { faExpandAlt } from '@fortawesome/pro-solid-svg-icons/faExpandAlt';
import { faFileAlt } from '@fortawesome/pro-solid-svg-icons/faFileAlt';
import { faFilter } from '@fortawesome/pro-solid-svg-icons/faFilter';
import { faFrown } from '@fortawesome/pro-solid-svg-icons/faFrown';
import { faGlobeEurope } from '@fortawesome/pro-solid-svg-icons/faGlobeEurope';
import { faGrinHearts } from '@fortawesome/pro-solid-svg-icons/faGrinHearts';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { faKey } from '@fortawesome/pro-solid-svg-icons/faKey';
import { faListAlt } from '@fortawesome/pro-solid-svg-icons/faListAlt';
import { faListUl } from '@fortawesome/pro-solid-svg-icons/faListUl';
import { faLock } from '@fortawesome/pro-solid-svg-icons/faLock';
import { faLockAlt } from '@fortawesome/pro-solid-svg-icons/faLockAlt';
import { faMegaphone } from '@fortawesome/pro-solid-svg-icons/faMegaphone';
import { faMeh } from '@fortawesome/pro-solid-svg-icons/faMeh';
import { faMinus } from '@fortawesome/pro-solid-svg-icons/faMinus';
import { faPen } from '@fortawesome/pro-solid-svg-icons/faPen';
import { faPlay } from '@fortawesome/pro-solid-svg-icons/faPlay';
import { faPlug } from '@fortawesome/pro-solid-svg-icons/faPlug';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faQuestion } from '@fortawesome/pro-solid-svg-icons/faQuestion';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';
import { faRectangleWide } from '@fortawesome/pro-solid-svg-icons/faRectangleWide';
import { faRulerHorizontal } from '@fortawesome/pro-solid-svg-icons/faRulerHorizontal';
import { faSmile } from '@fortawesome/pro-solid-svg-icons/faSmile';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';
import { faStar } from '@fortawesome/pro-solid-svg-icons/faStar';
import { faSync } from '@fortawesome/pro-solid-svg-icons/faSync';
import { faTable } from '@fortawesome/pro-solid-svg-icons/faTable';
import { faTh } from '@fortawesome/pro-solid-svg-icons/faTh';
import { faThLarge } from '@fortawesome/pro-solid-svg-icons/faThLarge';
import { faThList } from '@fortawesome/pro-solid-svg-icons/faThList';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle';
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import { faUnlink } from '@fortawesome/pro-solid-svg-icons/faUnlink';

export default [
  faAnchor,
  faAngleDown,
  faAngry,
  faArrowLeft,
  faArrowRight,
  faBars,
  faBookReader,
  faCamera,
  faChartLine,
  faCheck,
  faCheckCircle,
  faChevronDoubleLeft,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faClipboardList,
  faComment,
  faCommentAltLines,
  faEllipsisH,
  faEllipsisV,
  faExclamationCircle,
  faExternalLink,
  faEye,
  faEyeSlash,
  faExpandAlt,
  faFileAlt,
  faFilter,
  faFrown,
  faGlobeEurope,
  faGrinHearts,
  faInfoCircle,
  faKey,
  faListAlt,
  faListUl,
  faLock,
  faLockAlt,
  faMegaphone,
  faMeh,
  faMinus,
  faPen,
  faPlay,
  faPlug,
  faPlug,
  faPlus,
  faQuestion,
  faQuestionCircle,
  faRectangleWide,
  faRulerHorizontal,
  faSmile,
  faSpinner,
  faStar,
  faStar,
  faSync,
  faTable,
  faTh,
  faThLarge,
  faThList,
  faTimes,
  faTimesCircle,
  faTrash,
  faUnlink,
];
