import { isFunction } from 'lodash-es';

import BasePopover from '@widgets/Popover/BasePopover';
import { logWarning } from '@/utils/helpers/logger.utility';

/*
 * This component expects an 'items' parameter which is an array of objects in the below format
 */

/**
 * List Item
 * @typedef {Object} Item
 * @property {String} title The label for this button/link
 * @property {String} uri This value indicates this item is a link (either it or 'onClick' will be
 * used)
 * @property {Function} onClick This handler indicates the item is a button (either it or 'uri'
 * will be used)
 * @property {Object} [icon] An optional icon component
 * @property {Object} [props] An optional set of props to be bound to the specified icon component
 */

// @vue/component
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    isArrowEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    alignArrowWithReference: {
      type: Boolean,
      required: false,
      default: false,
    },
    placement: {
      type: String,
      required: false,
      default: 'bottom',
    },
    positionFixed: {
      type: Boolean,
      required: false,
      default: false,
    },
    showMobileOverlay: {
      type: Boolean,
      required: false,
      default: false,
    },
    contentClass: {
      type: String,
      required: false,
      default: '',
    },
    addPadding: {
      type: Boolean,
      required: false,
      default: false,
    },
    fullWidthOnMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
    offset: {
      type: Array,
      required: false,
      default: () => [0, 8],
    },
    showOnHover: {
      type: Boolean,
      required: false,
      default: false,
    },
    showSeparator: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideWhenOutOfBoundary: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    BasePopover,
  },
  computed: {
    listItems() {
      return this.items.filter((item) => item.uri || item.onClick);
    },

    isMobileBreakpoint() {
      return (this.$mq === 'x-small' || this.$mq === 'small');
    },

    listClasses() {
      return [
        this.isMobileBreakpoint ? 'is-mobile' : '',
        (this.fullWidthOnMobile && this.isMobileBreakpoint) ? 'is-full-width' : '',
      ];
    },
  },
  methods: {
    onItemClicked(index) {
      const item = this.items[index];

      if (item.onClick && typeof item.onClick === 'function') {
        this.$emit('item-clicked', item);
        item.onClick();
      }
    },

    onPopoverOpen() {
      this.$emit('open');
    },

    onPopoverClose() {
      this.$emit('close');
    },
  },
  mounted() {
    /* eslint-disable-next-line func-names, prefer-arrow-callback */
    this.items.forEach(function (item, index) {
      if (!item.title) {
        logWarning(`Item ${index} is missing a 'title' attribute`);
      }

      if (!item.uri && !isFunction(item.onClick)) {
        logWarning(`Item ${index} is missing a 'uri' or 'onClick' attribute`);
      }
    }, this);
  },
};
