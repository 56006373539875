import { ROUTES, ROUTE_NAMES } from './routes';

export default [
  {
    path: ROUTES[ROUTE_NAMES.SETTINGS].path,
    name: ROUTE_NAMES.SETTINGS,
    redirect: ROUTES[ROUTE_NAMES.SETTINGS_PERSONAL].path,
    component: () => import(/* webpackChunkName: "app-layout" */ '@pages/AppLayout'),
    children: [
      {
        path: 'personal',
        name: ROUTE_NAMES.SETTINGS_PERSONAL,
        meta: {
          title: 'Personal Settings',
        },
        props: { sidebar: false, content: true },
        components: {
          sidebar: () => import(/* webpackChunkName: "settings-sidebar" */ '@sections/SettingsSidebar'),
          content: () => import(/* webpackChunkName: "settings-personal" */ '@sections/Personal'),
        },
      },
      {
        path: 'subscription',
        name: ROUTE_NAMES.SETTINGS_SUBSCRIPTION,
        meta: {
          title: 'Subscription',
        },
        props: { sidebar: false, content: true },
        components: {
          sidebar: () => import(/* webpackChunkName: "settings-sidebar" */ '@sections/SettingsSidebar'),
          content: () => import(/* webpackChunkName: "settings-subscription" */ '@sections/Subscription'),
        },
      },
      {
        path: '/settings/tags',
        name: ROUTE_NAMES.TAGS,
        redirect: ROUTES[ROUTE_NAMES.TAGS].path,
        meta: { shortName: 'Tags' },
        props: { sidebar: false, content: true },
        components: {
          sidebar: () => import(/* webpackChunkName: "settings-sidebar" */ '@sections/SettingsSidebar'),
          content: () => import(/* webpackChunkName: "settings-tags" */ '@sections/TagsManagement'),
        },
      },

      {
        path: 'apikeys',
        name: ROUTE_NAMES.SETTINGS_API_KEYS,
        meta: {
          title: 'API Keys',
        },
        props: { sidebar: false, content: true },
        components: {
          sidebar: () => import(/* webpackChunkName: "settings-sidebar" */ '@sections/SettingsSidebar'),
          content: () => import(/* webpackChunkName: "settings-apikeys" */ '@sections/SettingsApiKeys'),
        },
      },
      {
        path: 'webhooks',
        name: ROUTE_NAMES.WEBHOOKS,
        meta: {
          title: 'Webhooks',
        },
        props: { sidebar: false, content: true },
        components: {
          sidebar: () => import(/* webpackChunkName: "settings-sidebar" */ '@sections/SettingsSidebar'),
          content: () => import(/* webpackChunkName: "settings-webhooks" */ '@sections/SettingsWebhooks'),
        },
      },
      {
        path: 'webhooks/:id',
        name: ROUTE_NAMES.WEBHOOK,
        meta: {
          title: 'Webhook',
        },
        props: { sidebar: false, content: true },
        components: {
          sidebar: () => import(/* webpackChunkName: "settings-sidebar" */ '@sections/SettingsSidebar'),
          content: () => import(/* webpackChunkName: "settings-webhooks" */ '@sections/SettingsWebhook'),
        },
      },
      {
        path: 'apps-sdk',
        name: ROUTE_NAMES.APPS_SDK,
        meta: {
          title: 'Apps SDK',
        },
        props: { sidebar: false, content: true },
        components: {
          sidebar: () => import('@sections/SettingsSidebar'),
          content: () => import('@sections/SettingsAppsSDK'),
        },
      },
    ],
  },
];
