import { fixBody } from '@/utils/helpers/fixScroll';

export default {
  namespaced: true,
  state: {
    isLightboxOpen: null,
    lightboxProps: null,
  },
  mutations: {
    changeActiveLightbox(
      state,
      { isLightboxOpen = false, lightboxProps = null } = {},
    ) {
      state.isLightboxOpen = isLightboxOpen;
      state.lightboxProps = lightboxProps;
    },
  },
  actions: {
    openLightbox({ commit }, { lightboxProps }) {
      fixBody(true);
      commit('changeActiveLightbox', { isLightboxOpen: true, lightboxProps });
    },

    closeLightbox({ commit, dispatch, rootState }) {
      if (rootState.ui.formState.isAmended) {
        dispatch('ui/formState/amend', false, { root: true });
      }

      fixBody(false);
      commit('changeActiveLightbox', { isLightboxOpen: false });
    },
  },
};
