import Vue from 'vue';

const deepVueDelete = (obj, props) => {
  if (!Array.isArray(props)) {
    throw new Error('\'props\' is not an Array');
  }

  const prop = props.shift();
  if (!obj[prop]) {
    return;
  }

  if (!props.length) {
    Vue.delete(obj, prop);
    return;
  }

  deepVueDelete(obj[prop], props);
};

export default deepVueDelete;
