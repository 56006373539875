import uuidv1 from 'uuid/v1';
import { isEqual } from 'lodash-es';
import { logError } from '@/utils/helpers/logger.utility';

const TOOLTIP_DEFAULT_COLOUR = '#2B3A4E';

/**
 * Helper to determine if a value is an Object
 * @param value
 * @returns {boolean}
 */
const valueIsObject = (value) => typeof value === 'object' && value !== null;

/**
 * Helper to return the tooltip title from the directive value.
 * Value must be either a string or an must either be a String
 * or an object with a "title" property of type String.
 * @param value
 * @returns {*}
 */
const getTitleFromValue = (value) => {
  if (valueIsObject(value)) {
    if (!value.title) {
      throw new Error('v-tooltip value must either be a String or an object with a "title" property of type String');
    }

    return value.title;
  }

  return value;
};

/**
 * Helper to return the popperConfig from the directive value.
 * popperConfig is optional so if its not found in the directive we
 * just return an empty object.
 * @param value
 * @returns {{}|{}|*|{}|popperConfig|{placement, modifiers}|default.props.popperConfig}
 */
const getPopperConfigFromValue = (value) => {
  if (valueIsObject(value)) {
    if (!value.popperConfig) {
      return {};
    }

    return value.popperConfig;
  }

  return {};
};

const getDisabledFromValue = (value) => {
  if (valueIsObject(value)) {
    return Boolean(value.disabled);
  }

  return false;
};

/**
 * Helper to return the tooltip colour from the directive value.
 * colour is optional so if its not found in the directive we
 * just return default tooltip colour.
 * @param value
 * @returns {string}
 */
const getColourFromValue = (value) => {
  if (valueIsObject(value)) {
    if (!value.colour) {
      return TOOLTIP_DEFAULT_COLOUR;
    }

    return value.colour;
  }

  return TOOLTIP_DEFAULT_COLOUR;
};

const addEventListeners = (el) => {
  el.addEventListener('mouseenter', el.showTooltip);
  el.addEventListener('mouseleave', el.hideTooltip);
};

const removeEventListeners = (el) => {
  el.removeEventListener('mouseenter', el.showTooltip);
  el.removeEventListener('mouseleave', el.hideTooltip);
};

const tooltipDirective = {
  bind(el, bindings, vnode) {
    const { $store } = vnode.context;
    const { value } = bindings;

    if (!(el instanceof HTMLElement)) {
      return;
    }

    // eslint-disable-next-line no-param-reassign
    el.showTooltip = () => {
      if (!el.tooltipId || !el.tooltipTitle) {
        return;
      }

      if (el.disabled) {
        return;
      }

      $store.dispatch('ui/tooltip/activateTooltip', {
        tooltipId: el.tooltipId,
        tooltipColour: el.tooltipColour,
        title: el.tooltipTitle,
        popperConfig: el.popperConfig,
      });
    };

    // eslint-disable-next-line no-param-reassign
    el.hideTooltip = () => {
      if (!el.tooltipId) {
        return;
      }

      if (el.disabled) {
        return;
      }

      if ($store.state.ui.tooltip.tooltipId === el.tooltipId) {
        $store.dispatch('ui/tooltip/deactivateTooltip');
      }
    };

    try {
      // Get the tooltip title and popper config from the directive value and then store it on
      // the directive el Object. This means that the values can be accessed in the other
      // directive hooks i.e. update and unbind

      // eslint-disable-next-line no-param-reassign
      el.tooltipTitle = getTitleFromValue(value);

      // eslint-disable-next-line no-param-reassign
      el.popperConfig = getPopperConfigFromValue(value);

      // eslint-disable-next-line no-param-reassign
      el.tooltipColour = getColourFromValue(value);

      // eslint-disable-next-line no-param-reassign
      el.disabled = getDisabledFromValue(value);

      // Since we can't store the target element in Vuex, we'll instead set an ID data attribute
      // on the element and persist this ID in Vuex
      // eslint-disable-next-line no-param-reassign
      el.tooltipId = uuidv1();
      el.setAttribute('data-tooltip-id', el.tooltipId);

      if (bindings.modifiers.reactive) {
        const { shouldShow } = value;
        if (shouldShow === undefined) {
          throw new Error('shouldShow property required on value object if reactive modifier is present');
        }

        if (shouldShow) {
          el.showTooltip();
        }

        // Don't attach mouseover event listeners if in reactive mode
        return;
      }

      addEventListeners(el);
    } catch (e) {
      logError(e);
    }
  },
  update(el, bindings) {
    const { value, oldValue } = bindings;

    // If the directive value changes we update tooltipTitle, popperConfig and tooltipColour.
    // Because they are stored on the el Object,
    // the next time el.showTooltip event handler is executed
    // the new values will be used to update our tooltip vuex state.
    if (isEqual(value, oldValue)) {
      return;
    }

    try {
      // eslint-disable-next-line no-param-reassign
      el.tooltipTitle = getTitleFromValue(value);
      if (!el.tooltipTitle) {
        return;
      }

      // eslint-disable-next-line no-param-reassign
      el.popperConfig = getPopperConfigFromValue(value);

      // eslint-disable-next-line no-param-reassign
      el.tooltipColour = getColourFromValue(value);

      // If the tooltip is reactive then we might need to show or hide it now
      if (bindings.modifiers.reactive) {
        const { shouldShow } = value;
        if (shouldShow) {
          el.showTooltip();
        } else {
          el.hideTooltip();
        }
      }
    } catch (e) {
      logError(e);
    }
  },
  unbind(el) {
    if (el.hideTooltip) {
      el.hideTooltip();
    }

    removeEventListeners(el);

    el.setAttribute('data-tooltip-id', null);
  },
};

export default tooltipDirective;
