// see - /build/media-breakpoints.js
const headerBreakpointSmall = process.env.HEADER_BREAKPOINT_SMALL;
const headerBreakpointMedium = process.env.HEADER_BREAKPOINT_MEDIUM;
const headerBreakpointLarge = process.env.HEADER_BREAKPOINT_LARGE;

const mobile = process.env.MOBILE;
const largeMobile = process.env.LARGE_MOBILE;
const aboveLargeMobile = process.env.ABOVE_LARGE_MOBILE;
const tablet = process.env.TABLET;
const smallDesktop = process.env.SMALL_DESKTOP;
const mediumDesktop = process.env.MEDIUM_DESKTOP;
const pageEditorToolbarDesktop = process.env.PAGE_EDITORTOOLBAR_DESKTOP;
const pageEditorToolbar980 = process.env.PAGEEDITOR_TOOLBAR_980;
const pageEditorToolbar880 = process.env.PAGEEDITOR_TOOLBAR_880;
const pageEditorToolbar840 = process.env.PAGEEDITOR_TOOLBAR_840;
const pageEditorToolbar780 = process.env.PAGEEDITOR_TOOLBAR_780;
const pageEditorToolbar720 = process.env.PAGEEDITOR_TOOLBAR_720;
const pageEditorToolbar680 = process.env.PAGEEDITOR_TOOLBAR_680;
const pageEditorToolbar580 = process.env.PAGEEDITOR_TOOLBAR_580;
const pageEditorToolbar510 = process.env.PAGEEDITOR_TOOLBAR_510;
const pageEditorToolbar400 = process.env.PAGEEDITOR_TOOLBAR_400;
const inlineCommentsCollapseWidth = pageEditorToolbarDesktop;
const largeDesktop = process.env.LARGE_DESKTOP;
const xLargeDesktop = process.env.XLARGE_DESKTOP;

const mqSmall = process.env.VUE_MQ_SM;
const mqMedium = process.env.VUE_MQ_MD;

const formElementBreakpointLargeScreen = process.env.FORM_ELEMENT_BREAKPOINT_LARGE_SCREEN;

export {
  headerBreakpointSmall,
  headerBreakpointMedium,
  headerBreakpointLarge,
  mobile,
  largeMobile,
  aboveLargeMobile,
  tablet,
  smallDesktop,
  mediumDesktop,
  pageEditorToolbarDesktop,
  pageEditorToolbar980,
  pageEditorToolbar880,
  pageEditorToolbar840,
  pageEditorToolbar780,
  pageEditorToolbar720,
  pageEditorToolbar680,
  pageEditorToolbar580,
  pageEditorToolbar510,
  pageEditorToolbar400,
  inlineCommentsCollapseWidth,
  largeDesktop,
  xLargeDesktop,
  formElementBreakpointLargeScreen,
  mqSmall,
  mqMedium,
};
