import { mapGetters, mapActions, mapState } from 'vuex';
import { compact, debounce } from 'lodash-es';
import uuidv1 from 'uuid/v1';

import { EDITOR_TYPES } from '@/utils/constants/editorConfig';
import { largeDesktop } from '@/utils/constants/mediaBreakpoints';
import { getShortcutString } from '@/utils/helpers/keyboardShortcuts';
import AlignmentButton from '@sections/SpacesEditor/SpacesEditorToolbar/AlignmentButton';
import cnameHelper from '@/utils/helpers/CNameHelper';
import { ROUTE_NAMES } from '@/router/routes';
import InsertTable from '@widgets/InsertTable';
import SelectColor from '@widgets/SelectColor';
import SpacesEditorToolbarButton from '@sections/SpacesEditor/SpacesEditorToolbar/SpacesEditorToolbarButton';
import SpacesEditorToolbarListButton from '@sections/SpacesEditor/SpacesEditorToolbar/SpacesEditorToolbarListButton';
import overflowConfig from '@/utils/editor/config/toolbarOverflow';
import processTinymceCommand from '@/utils/helpers/processTinymceCommand';
import tinymceCommands from '@/utils/constants/tinymceCommands';
import SpacesEditorToolbarOverflowList from './SpacesEditorToolbarOverflowList';

// @vue/component
export default {
  inject: ['editorType'],

  props: {
    variant: {
      type: String,
      default: '',
      required: false,
    },
    buttonVariant: {
      type: String,
      default: '',
      required: false,
    },
  },

  components: {
    AlignmentButton,
    InsertTable,
    SelectColor,
    SpacesEditorToolbarButton,
    SpacesEditorToolbarListButton,
    SpacesEditorToolbarOverflowList,
  },

  data() {
    return {
      windowWidth: undefined,
      fullWidthToggledOn: false,
    };
  },

  computed: {
    ...mapState('ui/spacesEditor', {
      isFullWidth: 'isFullWidth',
    }),

    ...mapState('ui/inlineComments', {
      shouldShowCommentsGutter: 'shouldShowCommentsGutter',
      canAddInlineComment: 'canAddInlineComment',
    }),

    ...mapState('ui/activeEditor', {
      activeSelectionFormats: 'activeSelectionFormats',
      activeForeColor: 'activeForeColor',
      canRedo: 'canRedo',
      canUndo: 'canUndo',
    }),

    ...mapGetters('ui/spacesEditor', ['toolbarDisabled']),

    ...mapGetters('ui/activeEditor', [
      'hasActiveSelectionFormat',
    ]),

    ...mapGetters('ui/sidebar', {
      isSidebarCollapsed: 'isCollapsed',
    }),

    ...mapGetters('ui/editorComponents', {
      insertableEditorComponentByName: 'insertableComponentByName',
    }),

    shouldShowUndoRedoButtons() {
      return this.editorType === EDITOR_TYPES.PAGE;
    },

    shouldShowCommentButton() {
      return this.editorType === EDITOR_TYPES.PAGE;
    },

    toolbarClasses() {
      return [
        'spaces-editor-toolbar',
        ...this.toolbarVariants,
      ];
    },

    toolbarVariants() {
      return compact(this.variant.split(' ')).map((variant) => `spaces-editor-toolbar--${variant}`);
    },

    textFormatMenu() {
      return [
        {
          title: 'Paragraph',
          value: 'p',
          type: 'p',
        },
        {
          title: 'Heading 1',
          value: 'h1',
          type: 'h1',
        },
        {
          title: 'Heading 2',
          value: 'h2',
          type: 'h2',
        },
        {
          title: 'Heading 3',
          value: 'h3',
          type: 'h3',
        },
        {
          title: 'Heading 4',
          value: 'h4',
          type: 'h4',
        },
      ];
    },

    formatMenu() {
      const formatMenu = [
        {
          title: 'Strikethrough',
          type: 'strikethrough',
          shortcut: getShortcutString('strikethrough'),
        },
        {
          title: 'Code',
          type: 'code',
          value: 'code',
          shortcut: getShortcutString('code'),
        },
        {
          title: 'Subscript',
          type: 'subscript',
        },
        {
          title: 'Superscript',
          type: 'superscript',
        },
        {
          title: 'Clear formatting',
          type: 'RemoveFormat',
          shortcut: getShortcutString('clear-formatting'),
        },

      ];

      return this.$mq === 'small' ? [
        {
          title: 'Underline',
          type: 'underline',
          shortcut: getShortcutString('underline'),
        },
        {
          title: 'Blockquote',
          type: 'blockquote',
        },
        ...formatMenu,
      ] : formatMenu;
    },

    textAlignment() {
      return [
        {
          type: 'JustifyLeft',
          icon: 'align-left',
          tooltip: 'Left',
        },
        {
          type: 'JustifyCenter',
          icon: 'align-center',
          tooltip: 'Center',
        },
        {
          type: 'JustifyRight',
          icon: 'align-right',
          tooltip: 'Right',
        },
      ];
    },

    selectedTextFormat() {
      return this.textFormatMenu.find(
        (textLevel) => textLevel && this.hasActiveSelectionFormat(textLevel.type),
      ) || this.textFormatMenu[0];
    },
    overflowMenuEditorComponents() {
      if (!this.windowWidth) {
        return [];
      }

      const shouldShowAtBreakpoint = (overflowConfigItem) => (overflowConfigItem.maxBreakpoint
        && this.windowWidth < overflowConfigItem.maxBreakpoint)
        || !overflowConfigItem.maxBreakpoint;

      const configItemToName = (configItem) => (configItem.name);

      const nameToComponent = (name) => {
        const comp = this.insertableEditorComponentByName(name);
        if (!comp) {
          return undefined;
        }
        return comp;
      };

      const isInstalled = (component) => (component !== undefined);

      const isEnabledForCurrentEditorType = (editorComponent) => editorComponent.enabledEditors
        .includes(this.editorType);

      return overflowConfig
        .filter(shouldShowAtBreakpoint)
        .map(configItemToName)
        .map(nameToComponent)
        .filter(isInstalled)
        .filter(isEnabledForCurrentEditorType);
    },
  },
  methods: {
    ...mapActions('ui/inlineComments', ['createInlineComment']),

    ...mapActions('ui/spacesEditor', ['setIsFullWidth']),

    ...mapActions('modals', ['openModal', 'closeModal']),

    ...mapActions('ui/sidebar', {
      setSidebarCollapsedState: 'setCollapsedState',
    }),

    reportToGA({ action }) {
      const activeSpace = this.$store.getters['navigation/activeSpace'];

      if (this.$ga) {
        this.$ga.event({
          eventCategory: 'editor-actions',
          eventAction: action,
          ...(activeSpace ? {
            eventLabel: `${cnameHelper()} | ${activeSpace.title} | ${this.$store.getters['navigation/activePage'].title}`,
          } : {}),
          eventValue: 1,
        });
      }
    },

    getShortcutString(shortcutId) {
      return getShortcutString(shortcutId);
    },

    disabledIf(...formats) {
      return this.toolbarDisabled
        || this.activeSelectionFormats.some((format) => formats.includes(format));
    },

    onColorSelected(executeCommand, close, color) {
      if (this.toolbarDisabled) {
        return;
      }

      executeCommand(color);
      close();
    },

    insertInternalLink(editor, formData) {
      editor.insertContent(editor.dom.createHTML('tw-spaces-internal-link ',
        { 'page-id': formData.selectedPage.id, uuid: uuidv1() }, ''));

      setTimeout(() => {
        const range = editor.selection.getRng();
        const internalLinkNode = editor.selection.getNode();
        const nbsNode = document.createTextNode('\u00A0');
        internalLinkNode.parentNode.insertBefore(nbsNode, internalLinkNode.nextSibling);

        range.setStartAfter(nbsNode);
        range.setEndAfter(nbsNode);
        editor.selection.setRng(range);
      }, 300);
    },

    insertInternalLinkWithText(editor, formData) {
      const space = this.$store.getters['spaces/byCode'](formData.selectedPage.spaceCode);

      const pageCode = space.title === formData.selectedPage.pageTitle ? 'home' : `${formData.selectedPage.id}-${formData.selectedPage.pageSlug}`;

      const linkDetails = this.$router.resolve({
        name: ROUTE_NAMES.SPACE_PAGE,
        params: {
          spaceCode: formData.selectedPage.spaceCode,
          pageCode,
        },
      });

      editor.insertContent(editor.dom.createHTML('a', {
        href: linkDetails.href,
        title: formData.title,
      }, editor.dom.encode(formData.text)));
    },

    insertExternalLink(editor, formData) {
      const linkAttrs = {
        href: formData.href,
        target: formData.selectedTargetOption ? formData.selectedTargetOption.value : null,
        rel: formData.selectedRelOption ? formData.selectedRelOption.value : null,
        class: formData.selectedClassOption ? formData.selectedClassOption.value : null,
        title: formData.title || null,
      };

      if (formData.selectedMultiselectOption
        && formData.selectedMultiselectOption.isHeader) {
        const selectedHeader = formData.selectedMultiselectOption;
        const headers = editor.dom.select('h1,h2,h3,h4,h5,h6');
        headers[selectedHeader.reference].id = selectedHeader.elementId;
      }

      if (formData.text) {
        editor.insertContent(editor.dom.createHTML('a', linkAttrs, editor.dom.encode(formData.text)));
      } else {
        editor.execCommand('mceInsertLink', false, linkAttrs);
      }
    },

    launchCustomLinkModal() {
      if (this.toolbarDisabled) {
        return;
      }

      const editor = window.tinyMCE.activeEditor;
      const vm = this;

      this.openModal({
        activeModal: 'insert-link',
        modalProps: {
          onSubmit(formData) {
            let eventAction = 'insert-link';

            if (formData.selectedPage && !formData.text) {
              eventAction = 'insert-plugin-tw-spaces-internal-link';
              vm.insertInternalLink(editor, formData);
            } else if (formData.selectedPage && formData.text) {
              eventAction = 'insert-plugin-tw-spaces-internal-link-with-text';
              vm.insertInternalLinkWithText(editor, formData);
            } else {
              vm.insertExternalLink(editor, formData);
            }

            vm.reportToGA({ action: eventAction });

            vm.closeModal();
          },
        },
      });
    },

    launchCustomCodeSampleModal(event, { content, language } = '') {
      const preNode = event.target;
      const editor = window.tinyMCE.activeEditor;
      const vm = this;

      this.openModal({
        activeModal: 'code-sample',
        modalProps: {
          activeCodeSample: content,
          activeLanguage: language,
          onSubmit(codeSample, selectedLanguage, editingExistingCodeSample) {
            const code = editor.dom.encode(codeSample);

            if (editingExistingCodeSample) {
              const node = preNode;
              editor.dom.setAttrib(node, 'class', `language-${selectedLanguage}`);
              editor.dom.setAttrib(node, 'contenteditable', 'false');
              node.innerHTML = code;
              editor.selection.select(node);
            } else {
              const paragraphEl = document.createElement('p');
              paragraphEl.appendChild(document.createElement('br'));

              editor.insertContent(`<pre id="__new" contenteditable="false" class="language-${selectedLanguage}">${code}</pre>`);

              const newCodeSample = editor.selection.getNode();
              editor.dom.insertAfter(paragraphEl, newCodeSample);
              editor.selection.setCursorLocation(paragraphEl, 0);
            }

            this.closeModal();

            vm.reportToGA({ action: 'code-sample' });
          },
          onClose() {
            editor.selection.select(preNode);
          },
        },
      });
    },

    onTableInserted(close) {
      // Note: the purpose of the following function to to ensure TinyMCE sets the 'selected node'
      // to be the first <td> when inserting a table. By default TinyMCE will give the illusion of
      // having the cursor position set within the first <td> when actually it's within the first
      // <tr>. This can lead to issues when immediately inserting content after inserting a table
      (function selectFirstTableCell() {
        window.tinyMCE.activeEditor.selection.setCursorLocation(
          window.tinyMCE.activeEditor.selection.getNode().childNodes[0],
        );
      }());

      close();
    },

    onClickInsertInlineCommentButton() {
      this.createInlineComment(window.tinyMCE.editors['page-editor'].plugins.ot.comments);
    },

    // eslint-disable-next-line func-names
    recordWindowWidth: debounce(function () {
      this.windowWidth = window.innerWidth;
    }, 200),

    startWatchingWindowWidth() {
      this.windowWidth = window.innerWidth;
      window.addEventListener('resize', this.recordWindowWidth);
    },

    stopWatchingWindowWidth() {
      window.removeEventListener('resize', this.recordWindowWidth);
    },

    onClickTogglePageWidth() {
      this.setIsFullWidth(!this.isFullWidth);
    },

    applyToolbarListButtonCommand(item) {
      processTinymceCommand(tinymceCommands[item.type]);
      this.reportToGA({ action: item.type });
    },
  },
  mounted() {
    this.$root.$on('openCodeSampleModal', (event) => {
      const dataset = event.target.dataset || event.element.dataset;

      this.launchCustomCodeSampleModal(event, dataset);
    });

    this.$root.$on('openLinkModal', () => {
      this.launchCustomLinkModal();
    });

    this.startWatchingWindowWidth();
  },
  beforeDestroy() {
    if (window.innerWidth < largeDesktop && this.shouldShowCommentsGutter && !this.isSidebarCollapsed) {
      this.setSidebarCollapsedState(true);
    }
    this.$root.$off('openCodeSampleModal');
    this.$root.$off('openLinkModal');
    this.stopWatchingWindowWidth();
  },
};
