// Defines the order in which items appear in the overlfow menu in the Spaces Editor Toolbar
// and at which screen width they should appear

/* eslint-disable-next-line */
export default [
  {
    name: 'ul',
    maxBreakpoint: 580,
  },
  {
    name: 'ol',
    maxBreakpoint: 580,
  },
  {
    name: 'link',
    maxBreakpoint: 720,
  },
  {
    name: 'table',
    maxBreakpoint: 720,
  },
  {
    name: 'tw-placeholder',
  },
  {
    name: 'tw-spaces-image',
  },
  {
    name: 'tw-spaces-core-drawio',
  },
  {
    name: 'hr',
  },
  {
    name: 'media',
  },
  {
    name: 'tw-spaces-info-panel',
  },
  {
    name: 'tw-spaces-status',
  },
  {
    name: 'code-sample',
  },
  {
    name: 'tw-spaces-core-accountability-chart',
  },
  {
    name: 'tw-spaces-core-check-list-item',
  },
  {
    name: 'tw-spaces-core-timeline',
  },
  {
    name: 'tw-spaces-core-swot',
  },
  {
    name: 'table-of-contents',
  },
  {
    name: 'space-pages-list',
  },
  {
    name: 'projects-tasks',
  },
  {
    name: 'tw-spaces-attachments',
  },
  {
    name: 'tw-spaces-image-gallery',
  },
];
