import { createStaticCrudModule } from '@/utils/vuex';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';

export default createStaticCrudModule({
  app: 'projects',
  only: ['FETCH'],
  property: 'person',
  urlRoot: buildApiUrl({
    resource: 'me',
    app: 'projects',
  }),
});
