
import { mapGetters } from 'vuex';
import ErrorContainer from '@pages/Error/ErrorContainer';
import PageNotFound from '@/assets/page_not_found.svg';
import { ROUTE_NAMES } from '@/router/routes';

// @vue/component
export default {
  name: 'NotFound',
  props: {
    errorBody: {
      type: Object,
      default: () => { },
    },
  },
  components: {
    ErrorContainer,
    PageNotFound,
  },
  computed: {
    ...mapGetters('navigation', ['activeSpace']),

    ctaText() {
      if (this.activeSpace) {
        return 'Space Homepage';
      }

      return 'home';
    },
  },
  methods: {
    gotoSpaceHome() {
      if (this.activeSpace) {
        this.$router.push({ name: ROUTE_NAMES.SPACE, params: { spaceCode: this.activeSpace.code } });
        return;
      }

      this.$router.push({ name: ROUTE_NAMES.HOME });
    },
  },
};
