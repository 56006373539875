import { createCrudModule } from '@/utils/vuex';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import RESOURCES from '@/utils/constants/resources';

import store from '..';

const ACCESS_GRANTED = 'granted';

export default createCrudModule({
  only: ['FETCH_LIST', 'CREATE', 'UPDATE'],
  resource: RESOURCES.ACCESS_REQUEST,
  customUrlFn(id, spaceId) {
    return buildApiUrl({
      resource: id ? `spaces/${spaceId}/access/${id}` : `spaces/${spaceId}/access`,
    });
  },
  getters: {
    list(state) {
      return Object.values(state.entities).filter((accessRequest) => accessRequest.state === 'active');
    },
  },
  // Note: the purpose of this code is to ensure that when an access request is approved,
  // the newly created associated permission is added to the 'spacePermissions' store,
  // without having to re-fetch all 'spacePermissions' from the API
  // 'response.meta.permission' will be present due to the inclusion of the 'createPermission'
  // query parameter in the update request. Without this query parameter, no permission would be
  // created when we approve an access request (and therefore this block would not execute)
  onUpdateSuccess(state, response, includes, meta) {
    if (
      response.data.state === ACCESS_GRANTED
      && meta
      && meta[RESOURCES.PERMISSION]
    ) {
      store.dispatch('spacePermissions/addPermission', meta[RESOURCES.PERMISSION]);
    }
  },
});
