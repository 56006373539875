import { createStaticCrudModule } from '@/utils/vuex';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import store from '..';
/*
* This store module is used to fetch the page's shared state and update that
* it populates the pageShareScopes module onFetchSuccess
*/
export default createStaticCrudModule({
  only: ['FETCH', 'UPDATE'],
  property: 'share',
  prepareConfig() {
    // NOTE: We don't need the user include on this endpoint, so this will prevent automatically adding it
    return {};
  },
  customUrlFn(spaceId, pageId) {
    if (!spaceId || !pageId) {
      throw new Error('Unable to construct URL. Missing required Space ID or Page ID parameter');
    }

    return buildApiUrl({
      apiVersion: 'v1',
      resource: `/spaces/${spaceId}/pages/${pageId}/shares`,
    });
  },
  getters: {
    isPageSharingActive: (state, getters) => (
      getters.get
      && getters.get.state
      && getters.get.state === 'enabled'
    ) || false,
  },

  onFetchSuccess(state) {
    // The pageShareScopes module does not have a fetch
    // It relies on this parent module fetching and feeding it data
    const scopesArray = (state.entity && state.entity.scopes) || [];
    const scopesObject = scopesArray.reduce((result, current) => {
      // eslint-disable-next-line no-param-reassign
      result[current.id] = current;
      return result;
    }, {});

    store.dispatch('pageShareScopes/addScopes', scopesObject, { root: true });
  },

  onUpdateSuccess() {
    // Keep various page store modules in sync if an update is made
    const spaceId = store.getters['navigation/activeSpaceId'];
    const pageId = store.getters['navigation/activePageId'];

    store.dispatch('pages/fetchSingle', {
      customUrlFnArgs: [spaceId],
      id: pageId,
    }, { root: true });

    store.dispatch('spacePages/fetch', {
      customUrlFnArgs: [spaceId],
      id: spaceId,
    }, { root: true });

    store.dispatch('activeSpacePages/fetch', {}, { root: true });
  },
});
