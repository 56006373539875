const SPACE_PAGE_ACTIONS = {
  EDIT: 'edit',
  DIFF: 'required-reading-updates',
};

if (Object.freeze) {
  Object.freeze(SPACE_PAGE_ACTIONS);
}

export default SPACE_PAGE_ACTIONS;
