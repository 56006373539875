import Vue from 'vue';
import { createCrudModule } from '@/utils/vuex';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import alphabeticalSort from '@/utils/helpers/alphabeticalSort';

const resource = 'templates/local';

export default createCrudModule({
  only: ['FETCH_LIST', 'FETCH_SINGLE', 'CREATE', 'UPDATE', 'DESTROY'],
  resource,
  listProperty: 'templates',
  singleProperty: 'template',
  customUrlFn(id) {
    return buildApiUrl({
      resource: id ? `${resource}/${id}` : resource,
    });
  },
  state: {
    defaultPageSize: 250,
  },
  getters: {
    getSortedList(state, getters) {
      return (term = '', descendingOrder = true) => {
        if (term === '') {
          return getters.list && getters.list.slice().reverse();
        }

        return getters.list && getters.list
          .filter((template) => template.name.toLowerCase().includes(term.toLowerCase()))
          .sort((templateA, templateB) =>
            alphabeticalSort(templateA.name.toLowerCase(), templateB.name.toLowerCase(), descendingOrder));
      };
    },
  },
  actions: {
    fetchPageTemplates({ dispatch, state },
      {
        customUrlFnArgs,
        pageOffset = state.entities.length,
        pageSize = state.defaultPageSize,
      } = {},
    ) {
      dispatch('fetchList', {
        customUrlFnArgs,
        config: {
          params: {
            pageOffset,
            pageSize,
          },
        },
      });
    },
  },
  onCreateSuccess() {
    if (Vue.$ga) {
      Vue.$ga.event({
        eventCategory: 'templates',
        eventAction: 'local-template-created',
        eventLabel: 'Installation level template created',
        eventValue: 1,
      });
    }
  },
  onDestroySuccess() {
    if (Vue.$ga) {
      Vue.$ga.event({
        eventCategory: 'templates',
        eventAction: 'local-template-deleted',
        eventLabel: 'Installation level template deleted',
        eventValue: 1,
      });
    }
  },
});
