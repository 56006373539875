// @vue/component
export default {
  props: {
    child: {
      type: Object,
      default: () => {},
      required: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return [
        'list-item',
        { 'list-item--is-active': this.active },
      ];
    },
  },
};
