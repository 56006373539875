import { mapGetters } from 'vuex';
import { addTheadToTable, insertRowBelow } from '@/utils/helpers/tinymceTableHelper';
import TinymceCommandMixin from '@sections/SpacesEditor/SpacesEditorToolbar/Mixins/TinymceCommandMixin';
import SpacesEditorToolbarButton from '@sections/SpacesEditor/SpacesEditorToolbar/SpacesEditorToolbarButton';

// @vue/component
export default {
  mixins: [TinymceCommandMixin],
  components: {
    SpacesEditorToolbarButton,
  },
  computed: {
    ...mapGetters('ui/activeEditor', ['hasActiveSelectionFormat']),
    selectionHasTable() {
      return this.hasActiveSelectionFormat('table');
    },
    selectionHasThead() {
      return this.hasActiveSelectionFormat('thead');
    },
    selectionHasTableWithThead() {
      return this.hasActiveSelectionFormat('tableWithThead');
    },
    deleteRowTooltipText() {
      return this.selectionHasThead ? 'Delete Header Row' : 'Delete Row';
    },
  },
  methods: {
    onInsertRowBelowClicked() {
      const editor = window.tinymce.activeEditor;
      const selectionNode = editor.selection.getNode();
      const tableElement = editor.dom.getParent(selectionNode, 'table');

      if (tableElement) {
        insertRowBelow(tableElement, this.selectionHasThead, editor);
      }
    },
    onAddTableHeaderClicked() {
      const editor = window.tinymce.activeEditor;
      const selectionNode = editor.selection.getNode();
      const tableElement = editor.dom.getParent(selectionNode, 'table');

      if (tableElement) {
        addTheadToTable(tableElement, editor);
      }
    },
  },
};
