import store from '@/store';

import processTinymceCommand from '@/utils/helpers/processTinymceCommand';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import uuidv1 from 'uuid/v1';
import tinymceCommands from '@/utils/constants/tinymceCommands';
import { EDITOR_TYPES } from '@/utils/constants/editorConfig';
import cnameHelper from '@/utils/helpers/CNameHelper';
import isInternalImage from '@/utils/helpers/isInternalImage';
import copyInternalImage from '@/utils/helpers/copyInternalImage';

function reportToGA(vueComponentInstance, action) {
  if (vueComponentInstance.$ga) {
    const activeSpace = store.getters['navigation/activeSpace'];

    vueComponentInstance.$ga.event({
      eventCategory: 'editor-actions',
      eventAction: action,
      ...(activeSpace ? {
        eventLabel: `${cnameHelper()} | ${activeSpace.title} | ${store.getters['navigation/activePage'].title}`,
      } : {}),
      eventValue: 1,
    });
  }
}

// make component object referencable (via this) inside insertFn
export const bindComponentToInsertFn = (component) => {
  if (component.insertFn) {
    return {
      ...component,
      insertFn: component.insertFn.bind(component),
    };
  }

  return {
    ...component,
  };
};

export function insertCustomElement(editor, vueComponentInstance) {
  editor.insertContent(`<${this.customElementName} uuid="${uuidv1()}"></${this.customElementName}>`);
  reportToGA(vueComponentInstance, `insert-editor-component-${this.name}`);
}

export function defaultTinymceInsertFn(editor, vueComponentInstance) {
  processTinymceCommand(tinymceCommands[this.name], editor);
  reportToGA(vueComponentInstance, `insert-editor-component-${this.name}`);
}

export function insertImage(editor, vueComponentInstance) {
  if (!vueComponentInstance && !vueComponentInstance.editorType) {
    throw new Error('insertImage function requires an editor instance and a vue component instance with a editorType property');
  }

  store.dispatch('modals/openModal', {
    activeModal: 'insert-image',
    modalProps: {
      buildUploadAttachmentUrl() {
        if (vueComponentInstance.editorType === EDITOR_TYPES.TEMPLATE) {
          return buildApiUrl({
            resource: 'attachments',
            json: true,
          });
        }

        return buildApiUrl({
          resource: `spaces/${store.getters['navigation/activeSpaceId']}/pages/${store.getters['navigation/activePageId']}/attachments/inline`,
          json: true,
        });
      },
      /* eslint-disable-next-line func-names */
      buildViewAttachmentUrl(attachmentId) {
        if (vueComponentInstance.editorType === EDITOR_TYPES.TEMPLATE) {
          return buildApiUrl({
            resource: `attachments/${attachmentId}/view`,
            json: false,
          });
        }

        return buildApiUrl({
          resource: `/spaces/${store.getters['navigation/activeSpaceId']}/pages/${store.getters['navigation/activePageId']}/attachments/inline/${attachmentId}/view`,
          json: false,
        });
      },
      buildDeleteAttachmentUrl(attachmentId) {
        if (vueComponentInstance.editorType === EDITOR_TYPES.TEMPLATE) {
          return buildApiUrl({
            resource: `attachments/${attachmentId}`,
            json: true,
          });
        }

        return buildApiUrl({
          resource: `/spaces/${store.getters['navigation/activeSpaceId']}/pages/${store.getters['navigation/activePageId']}/attachments/inline/${attachmentId}`,
          json: true,
        });
      },
      async onSubmit(imageUrl) {
        if (!imageUrl) {
          return;
        }

        const insertContent = (imageSrc) => {
          editor.insertContent(
            editor.dom.createHTML('tw-spaces-image ', {
              src: imageSrc,
              caption: '',
              uuid: uuidv1(),
            }),
          );

          // Ensure cursor is placed on the next line
          editor.insertContent('<p></p>');

          store.dispatch('modals/closeModal');

          reportToGA(vueComponentInstance, 'insert-editor-component-image');
        };

        const isAbsolutePath = imageUrl.indexOf('://') > 0 || imageUrl.indexOf('//') === 0;

        if (!isAbsolutePath) {
          insertContent(imageUrl);
          return;
        }

        const url = new URL(imageUrl);

        if (url && url.pathname && isInternalImage(url.pathname)) {
          const newImageSrc = await copyInternalImage(url.pathname);

          if (newImageSrc) {
            // eslint-disable-next-line no-param-reassign
            imageUrl = newImageSrc;
          }
        }

        insertContent(imageUrl);
      },
    },
  });
}

export function insertStatus(editor, vueComponentInstance) {
  const selection = editor.selection.getContent();

  // if we have a selection, insert status chip before selection
  if (selection) {
    const selectRng = editor.selection.getRng();
    editor.selection.setCursorLocation(selectRng.startContainer, selectRng.startOffset);
  }

  const uuid = uuidv1();

  // space in the element name is left on purpose, we are seeing some strange behaviour
  // without it where our custom inline elements are not inserted to the editor on empty lines
  editor.insertContent(editor.dom.createHTML('tw-spaces-status ', {
    status: '',
    type: 'purple',
    uuid,
    'data-inserted': true,
  }, ''));

  reportToGA(vueComponentInstance, `insert-editor-component-${this.name}`);
}

export function insertInfoPanel(editor, vueComponentInstance) {
  const content = editor.selection.getContent().replace(/"/g, '&quot;');

  if (content.match(/<tw-span|\/tw-span>/g) || editor.selection.getNode().tagName === 'TW-SPAN') {
    // can only insert plain text
    this.showAlert({ type: 'error', message: 'Info panels cannot contain inline comments' });
    return;
  }

  const uuid = uuidv1();
  editor.insertContent(`<tw-spaces-info-panel content="${content.replace(/<\/?[^>]+(>|$)/g, '')}" type="info" uuid="${uuid}" data-inserted="true" />`);

  reportToGA(vueComponentInstance, `insert-editor-component-${this.name}`);
}

export function insertCodeSample(editor, vueComponentInstance) {
  store.dispatch('modals/openModal', {
    activeModal: 'code-sample',
    modalProps: {
      onSubmit(codeSample, selectedLanguage) {
        const code = editor.dom.encode(codeSample);
        const paragraphEl = document.createElement('p');
        paragraphEl.appendChild(document.createElement('br'));

        editor.insertContent(`<pre id="__new" contenteditable="false" class="language-${selectedLanguage}">${code}</pre>`);

        const newCodeSample = editor.selection.getNode();
        editor.dom.insertAfter(paragraphEl, newCodeSample);
        editor.selection.setCursorLocation(paragraphEl, 0);

        store.dispatch('modals/closeModal');

        reportToGA(vueComponentInstance, `insert-editor-component-${this.name}`);
      },
    },
  });
}

export function insertTable(editor, vueComponentInstance) {
  editor.plugins.table.insertTable(2, 2);
  reportToGA(vueComponentInstance, `insert-editor-component-${this.name}`);
}

export function insertLink(editor, vueComponentInstance) {
  function insertInternalLink(formData) {
    editor.insertContent(editor.dom.createHTML('tw-spaces-internal-link ',
      { 'page-id': formData.selectedPage.id, uuid: uuidv1() }, ''));

    setTimeout(() => {
      const range = editor.selection.getRng();
      const internalLinkNode = editor.selection.getNode();
      const nbsNode = document.createTextNode('\u00A0');
      internalLinkNode.parentNode.insertBefore(nbsNode, internalLinkNode.nextSibling);

      range.setStartAfter(nbsNode);
      range.setEndAfter(nbsNode);
      editor.selection.setRng(range);
    }, 300);
  }

  function insertInternalLinkWithText(formData) {
    const space = this.$store.getters['spaces/byCode'](formData.selectedPage.spaceCode);

    const pageCode = space.title === formData.selectedPage.pageTitle ? 'home' : `${formData.selectedPage.id}-${formData.selectedPage.pageSlug}`;

    const linkDetails = this.$router.resolve({
      name: 'spacePage',
      params: {
        spaceCode: formData.selectedPage.spaceCode,
        pageCode,
      },
    });

    editor.insertContent(editor.dom.createHTML('a', {
      href: linkDetails.href,
      title: formData.title,
    }, editor.dom.encode(formData.text)));
  }

  function insertExternalLink(formData) {
    const linkAttrs = {
      href: formData.href,
      target: formData.selectedTargetOption ? formData.selectedTargetOption.value : null,
      rel: formData.selectedRelOption ? formData.selectedRelOption.value : null,
      class: formData.selectedClassOption ? formData.selectedClassOption.value : null,
      title: formData.title || null,
    };

    if (formData.selectedMultiselectOption
      && formData.selectedMultiselectOption.isHeader) {
      const selectedHeader = formData.selectedMultiselectOption;
      const headers = editor.dom.select('h1,h2,h3,h4,h5,h6');
      headers[selectedHeader.reference].id = selectedHeader.elementId;
    }

    if (formData.text) {
      editor.insertContent(editor.dom.createHTML('a', linkAttrs, editor.dom.encode(formData.text)));
    } else {
      editor.execCommand('mceInsertLink', false, linkAttrs);
    }
  }

  store.dispatch('modals/openModal', {
    activeModal: 'insert-link',
    modalProps: {
      onSubmit(formData) {
        if (formData.selectedPage && !formData.text) {
          insertInternalLink(formData);
          reportToGA(vueComponentInstance, 'insert-editor-component-internal-link');
        } else if (formData.selectedPage && formData.text) {
          insertInternalLinkWithText(formData);
          reportToGA(vueComponentInstance, 'insert-editor-component-internal-link-with-text');
        } else {
          insertExternalLink(formData);
          reportToGA(vueComponentInstance, 'insert-editor-component-external-link');
        }

        store.dispatch('modals/closeModal');
      },
    },
  });
}

export function insertMedia(editor, vueComponentInstance) {
  store.dispatch('modals/openModal', {
    activeModal: 'insert-media',
    modalProps: {
      onSubmit(url) {
        editor.insertContent('<p></p>');
        editor.insertContent(`<iframely-embed url="${url}" uuid="${uuidv1()}" />`);
        // Ensure cursor is placed on the next line
        const paragraphEl = document.createElement('p');
        paragraphEl.appendChild(document.createElement('br'));
        const newCustomMediaNode = editor.selection.getNode();
        editor.dom.insertAfter(paragraphEl, newCustomMediaNode);
        editor.selection.setCursorLocation(paragraphEl, 0);

        reportToGA(vueComponentInstance, 'insert-editor-component-media');

        store.dispatch('modals/closeModal');
      },
    },
  });
}

export function insertPlaceholder(editor, vueComponentInstance) {
  store.dispatch('modals/openModal', {
    activeModal: 'placeholder-text',
    modalProps: {
      onSubmit(placeholderText) {
        editor.insertContent(`<tw-placeholder data-placeholder-text="${placeholderText.trim()}">&nbsp;</tw-placeholder>&nbsp;`);
        reportToGA(vueComponentInstance, 'insert-editor-component-placeholder');

        store.dispatch('modals/closeModal');
      },
    },
  });
}

