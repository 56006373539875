import fullName from '@/utils/filters/fullName';
import PUBBLE_APP_ID from '@/utils/constants/pubbleAppId';

export default {
  namespaced: true,
  getters: {
    pubbleAppId() {
      return PUBBLE_APP_ID;
    },

    pubbleUsername(state, getters, rootState, rootGetters) {
      if (!rootGetters['session/user']) {
        return '';
      }
      return fullName(rootGetters['session/user']);
    },

    pubbleEmail(state, getters, rootState, rootGetters) {
      if (!rootGetters['session/user']) {
        return '';
      }
      return rootGetters['session/user'].email;
    },

    // controls PubbleLiveChat visibility
    shouldHidePubble(state, getters, rootState, rootGetters) {
      if (!rootGetters['session/user']) {
        return true;
      }

      return rootGetters['navigation/isEditingSpacePage']
        || rootGetters['navigation/isPluginFullScreen']
        || rootGetters['ui/embeddedChat/isEmbeddedChatOpen']
        || !rootGetters['session/user'].isOnboarded
        || rootGetters['ui/inlineComments/shouldShowCommentsGutter']
        || rootGetters['panel/hasActivePanel'];
    },

    isPubbleVisible(state, getters, rootState, rootGetters) {
      return !getters.shouldHidePubble && rootGetters['installation/hasActiveTrial'];
    },
  },
};
