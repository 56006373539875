import { isArray } from 'lodash-es';

import { createStaticCrudModule } from '@/utils/vuex';

import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';

export default createStaticCrudModule({
  only: ['FETCH'],
  property: 'collaborators',
  customUrlFn(id, spaceId) {
    return buildApiUrl({
      resource: `spaces/${spaceId}/pages/${id}/collaborators`,
    });
  },
  getters: {
    get(state) {
      // Note: this endpoint returns an array on fetch, so it's unfetched state should be an empty
      // array
      return isArray(state.entity) ? state.entity : [];
    },
  },
  mutations: {
    resetCollaborators(state) {
      state.entity = {};
    },
  },
});
