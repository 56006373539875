export const defaultPageCode = 'home';
export const defaultPageTitle = 'Home';
// Specifically for Home page which is a static link on the sidebar
export const defaultPageId = -1;

export default {
  defaultPageCode,
  defaultPageTitle,
  defaultPageId,
};
