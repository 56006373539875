import Vue from 'vue';
import directives from '../directives';
import filters from '../filters';
import components from '../global-components';

export default function () {
// Global directives
  Object.entries(directives).forEach(([name, directive]) => {
    Vue.directive(name, directive);
  });

  // Global Filters
  Object.entries(filters).forEach(([name, filter]) => {
    Vue.filter(name, filter);
  });

  // Global
  Object.entries(components).forEach(([name, component]) => {
    Vue.component(name, component);
  });
}
