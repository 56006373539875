/* eslint-disable no-shadow */
import { isPlainObject } from 'lodash-es';

import { logError, logInfo } from '@/utils/helpers/logger.utility';
import {
  sendAuthRequest,
  onUserEventReceived,
  onUpdateEventReceived,
} from '@/utils/helpers/socket';
import store from '@/store';

export const socketActionPrefix = 'SOCKET_';
export const socketMutationPrefix = 'SOCKET_';

const defaultSocketEventsConstants = {
  onConnected: `${socketMutationPrefix}CONNECT`,
  onDisconnected: `${socketMutationPrefix}DISCONNECT`,
  onError: `${socketMutationPrefix}ERROR`,
  onTimeout: `${socketMutationPrefix}TIMEOUT`,
};

const customSocketEventsConstants = {
  onAuthRequestResult: `${socketMutationPrefix}AUTHRESULT`,
  onUserEvent: `${socketMutationPrefix}USEREVENT`,
  onUpdateEvent: `${socketMutationPrefix}UPDATEEVENT`,
};

const socketRoomEventToStoreModules = {
  page: [
    'comments',
    'pages',
  ],
};

const constants = {
  ...defaultSocketEventsConstants,
  ...customSocketEventsConstants,
  setConnected: 'setConnected',
  setSocketData: 'setSocketData',
  appendSocketData: 'appendSocketData',
  clearSocketDataItem: 'clearSocketDataItem',
  clearPageRoomSocketData: 'clearPageRoomSocketData',
  releaseSocketDataForModule: 'releaseSocketDataForModule',
};

const handlePluginStateUpdate = (data) => {
  if (data.Operation === 'pluginstate.updated') {
    window.$SPACES.eventBus.$emit(`plugin-state-updated-${data.ObjectID}`, data.Body.pluginstate.data);
    return true;
  }
  return false;
};

const socketIOActions = {
  [constants.onConnected]() {
    logInfo('Socket: connected');
    sendAuthRequest();
  },

  [constants.onDisconnected]({ commit }) {
    logInfo('Socket: disconnected');
    commit(constants.setConnected, false);
  },

  [constants.onError]({ commit }, data) {
    logError('Socket: error', data);
    commit(constants.setConnected, false);
  },

  [constants.onTimeout]({ commit }) {
    logError('Socket: timeout error');
    commit(constants.setConnected, false);
  },
};

const customActions = {
  [constants.sendAuthRequest]() {
    sendAuthRequest();
  },
  [constants.onAuthRequestResult]({ commit }, data) {
    logInfo('Socket: onAuthRequest', data);

    commit(constants.setConnected, data.authenticated);

    store.dispatch('notifications/setUnreadCount', { unreadCount: data.unreadCount });

    if (isPlainObject(data.stats)) {
      store.dispatch('installationStats/updateViaSocket', {
        data: {
          stats: data.stats,
        },
      });
    }
  },

  [constants.onUserEvent](contex, data) {
    onUserEventReceived(data);
  },

  [constants.onUpdateEvent](contex, data) {
    if (handlePluginStateUpdate(data)) {
      return;
    }
    onUpdateEventReceived(data);
  },

  /* eslint-disable max-len */
  [constants.releaseSocketDataForModule]({ commit, getters, dispatch }, { storeModule, operation = null }) {
    const moduleEntities = getters.getSocketDataForModule(storeModule, operation);

    if (moduleEntities && moduleEntities.length > 0) {
      moduleEntities.forEach((e) => {
        dispatch(`${e.module}/${e.action}`, e, { root: true });
        commit(constants.clearSocketDataItem, { id: e.objectId, storeModule: e.module });
      });
    }
  },

  [constants.clearPageRoomSocketData]({ commit, state }) {
    const newSocketData = state.socketData.filter(
      (d) => !socketRoomEventToStoreModules.page.includes(d.module),
    );

    commit(constants.setSocketData, newSocketData);
  },
};

const state = {
  connected: false,
  socketData: [],
};

const mutations = {
  [constants.setConnected](state, data) {
    state.connected = data;
  },

  [constants.appendSocketData](state, data) {
    state.socketData.push(data);
  },

  [constants.clearSocketDataItem](state, { id, storeModule }) {
    const index = state.socketData.findIndex((i) => i.objectId === id && i.module === storeModule);

    if (index !== -1) {
      state.socketData.splice(index, 1);
    }
  },

  [constants.setSocketData](state, newSocketData) {
    state.socketData = newSocketData;
  },
};

const actions = {
  ...socketIOActions,
  ...customActions,
};

const getters = {
  isConnected: ({ connected }) => connected,

  getSocketDataForModule: (state) => (m, o) => state.socketData.filter(
    (d) => (d.module === m && o ? d.action === o : true),
  ),
};

const socketStore = {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};

export default socketStore;
