import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { isString } from 'lodash-es';

import FormCheckbox from '@widgets/Form/FormCheckbox';
import FormContainer from '@widgets/Form/FormContainer';
import FormGroup from '@widgets/Form/FormGroup';
import FormInput from '@widgets/Form/FormInput';
import ListPopover from '@widgets/Popover/ListPopover';
import PageHeaderNavButton from '@widgets/PageHeaderNav/PageHeaderNavButton';
import SpacePagePrivacyPopover from '@widgets/SpacePagePrivacyPopover';
import ButtonGroup from '@widgets/ButtonGroup';

import cnameHelper from '@/utils/helpers/CNameHelper';
import { getShortcutString } from '@/utils/helpers/keyboardShortcuts';
import { logError } from '@/utils/helpers/logger.utility';
import { isDesktop } from '@/utils/helpers/device';

// @vue/component
export default {
  components: {
    FormCheckbox,
    FormContainer,
    FormGroup,
    FormInput,
    ListPopover,
    PageHeaderNavButton,
    SpacePagePrivacyPopover,
    ButtonGroup,
  },

  computed: {
    ...mapGetters('navigation', [
      'activePage',
      'activePageId',
      'activeSpaceId',
    ]),

    ...mapGetters('ui/spacesEditor', ['shouldNotifyFollowers']),

    ...mapGetters('ui/spacePage', [
      'canPublishPage',
    ]),

    ...mapState('ui/spacesEditor', ['isLoadingInitialContent', 'isPublishing']),

    ...mapState('ui/inlineComments', ['shouldShowCommentsGutter']),

    shouldRenderPagePrivacyButton() {
      return this.$store.getters['ui/spacePage/shouldShowPagePrivacyOptions'];
    },

    isNewPage() {
      return !this.activePage.isPublished;
    },

    localNotifyFollowers: {
      get() {
        return this.shouldNotifyFollowers;
      },
      set(value) {
        this.updateShouldNotifyFollowers(value);
      },
    },

    ellipsisOptions() {
      const ellipsisOptions = [
        {
          cypressId: 'editor-add-tag-button',
          title: 'Add tags',
          onClick: () => {
            this.openTagsModal();
          },
        },
      ];

      if (!this.isNewPage) {
        ellipsisOptions.push({
          cypressId: 'editor-revert-button',
          tooltip: 'Revert this draft to the most recently published version of the page',
          title: 'Revert to last published version',
          onClick: () => {
            this.onResetClicked();
          },
        });
      }

      return ellipsisOptions;
    },

    isPublishButtonActive() {
      return !this.isPublishing && this.canPublishPage;
    },

    shouldShowShortcutIcon() {
      return isDesktop();
    },
  },

  methods: {
    ...mapActions('modals', ['openModal']),

    ...mapActions('alerts', ['showAlert']),

    ...mapMutations('ui/spacesEditor', ['setContent']),

    ...mapActions('ui/spacesEditor', ['updateShouldNotifyFollowers']),

    onPublishClicked() {
      this.$emit('save');
    },

    closeEditor() {
      this.$emit('close');
    },

    openPublishWithNoteModal() {
      this.openModal({
        activeModal: 'publish-with-note',
        modalProps: {
          onPublishFn: () => {
            this.$emit('save');
          },
        },
      });
    },

    openTagsModal() {
      this.openModal({
        activeModal: 'editor-tags',
        modalProps: {
          title: 'Add tags',
        },
      });
    },

    getShortcutString(shortcutId) {
      return getShortcutString(shortcutId);
    },

    onResetClicked() {
      this.openModal({
        activeModal: 'confirm',
        modalProps: {
          title: 'Reset this draft?',
          text: 'This will reset the draft content back to that of the currently published version. Unpublished changes from all users will be wiped. Are you sure you want to do this?',
          onConfirm: async () => {
            try {
              // Perform a re-fetch of the current page to ensure we have the latest changes
              await this.$store.dispatch('pages/fetchSingle', {
                customUrlFnArgs: [this.activeSpaceId],
                id: this.activePageId,
              }, { root: true });

              const { content } = this.$store.getters['pages/byId'](this.activePageId);

              if (!isString(content)) {
                return;
              }

              this.setContent(content);

              if (this.$ga) {
                this.$ga.event({
                  eventCategory: 'editor-footer',
                  eventAction: 'reset-last-version',
                  eventLabel: `${cnameHelper()} | ${this.$store.getters['navigation/activeSpace'].title} | ${this.$store.getters['navigation/activePage'].title}`,
                  eventValue: 1,
                });
              }
            } catch (error) {
              logError(error);
              this.showAlert({ type: 'error', message: 'Couldn\'t reset this draft' });
            }
          },
        },
      });
    },

    onShortcutClicked(event) {
      event.preventDefault();

      this.openModal({
        activeModal: 'keyboard-shortcuts',
        modalProps: {
          title: 'Editor Shortcuts',
          text: 'formatting',
        },
      });

      if (this.$ga) {
        this.$ga.event({
          eventCategory: 'editor-actions',
          eventAction: 'keyboard-shortcuts-modal-clicked',
          eventLabel: `${cnameHelper()} | ${this.$store.getters['navigation/activeSpace'].title} | ${this.$store.getters['navigation/activePage'].title}`,
          eventValue: 1,
        });
      }
    },
  },
};
