/* eslint-disable no-param-reassign */

import { forEach, isArray, isObject } from 'lodash-es';
import isAfter from 'date-fns/isAfter';
import isSameSecond from 'date-fns/isSameSecond';
import parseISO from 'date-fns/parseISO';

/**
 * The purpose of this function to perform any modifications to incoming 'meta' fields (created,
 * updated, deleted) before use
 *
 * @param {Object|Array} data
 */
export default (response) => {
  const handleMetaForObject = (o) => {
    const { createdAt, updatedAt } = o;

    if (createdAt && updatedAt) {
      const createdAtDate = parseISO(createdAt);
      const updatedAtDate = parseISO(updatedAt);

      if (isAfter(createdAtDate, updatedAtDate)) {
        o.createdAt = o.updatedAt;
      }

      if (isSameSecond(updatedAtDate, createdAtDate)) {
        o.updatedAt = null;
        o.updatedBy = null;
      }
    } else if (updatedAt) {
      o.createdAt = o.updatedAt;
    }

    return o;
  };

  if (isArray(response.data)) {
    forEach(response.data, (v, i) => {
      response.data[i] = handleMetaForObject(v);
    });
  } else if (isObject(response.data)) {
    response.data = handleMetaForObject(response.data);
  } else {
    throw new Error('Cannot handle meta, response.data is not an Array or Object');
  }
};
