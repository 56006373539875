import Vue from 'vue';
import { cloneDeep, isPlainObject } from 'lodash-es';

import client from '@/utils/vuex/client';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import isValidId from '@/utils/helpers/isValidId';
import { RESOURCE_TYPES } from '@/utils/constants/reactions';
import deepVueSet from '@/utils/vuex/utils/deepVueSet';
import deepVueDelete from '@/utils/vuex/utils/deepVueDelete';
import { logWarning } from '@/utils/helpers/logger.utility';
import { getSupportedReactionTypeIds } from '@/utils/helpers/reactions';

/* eslint-disable no-use-before-define */

export default {
  namespaced: true,
  state: () => {
    const state = {
      isFetching: false,
      fetchError: null,
      isReacting: false,
      reactError: null,
    };

    /*
     * State entries for resources are dynamic and will have the following format (for example):
     * "page": {
     *    "2": {
     *      "reactions": {
     *        "1": [
     *          {
     *            "id": 1,
     *            "type": "users"
     *          }
     *        ],
     *        "3": []
     *      },
     *      "reactionsMeta": {
     *        "userReactionId": 1,
     *          "counts": {
     *            "1": 1,
     *            "3": 0
     *         }
     *      },
     *      "pagedResponse": null,
     *      "pagedResponseMeta": null,
     *    },
     *  }
     *
     * "reactions"          - This is an object where the key is the 'reaction type ID' and the value
     *                        is an array of 'linked user resources'
     * "reactionsMeta"      - This is an object containing the user's own 'reaction type ID' (or null
     *                        if they haven't reacted) and a map of the counts for each
     *                        'reaction type ID'
     * "pagedResponse"      - Populated by the most recent 'reactions by type' request. This will
     *                        be the array of 'linked user resources' for the current 'page'
     * "pagedResponseMeta"  - This is the pagination meta for the most recent 'reactions by type'
     *                        request
     */
    Object.values(RESOURCE_TYPES).forEach((resourceType) => {
      state[resourceType] = {};
    });

    return state;
  },
  actions: {
    fetch(
      { commit, rootGetters },
      {
        config = {},
        args = {},
      },
    ) {
      const { spaceId, pageId, resourceType, resourceId, reactionTypeId = null } = args;

      commit('fetchStart', args);

      if (reactionTypeId && !rootGetters['reactionTypes/byId'](reactionTypeId)) {
        throw new Error(`Invalid 'reactionTypeId' '${reactionTypeId}' specified`);
      }

      const url = customUrlFn(spaceId, pageId, resourceType, resourceId, reactionTypeId);

      return client.get(url, prepareConfig(config))
        .then((response) => {
          const params = { reactionTypeId, resourceType, resourceId, ...parseResponse(response, reactionTypeId) };

          commit('fetchSuccess', params);

          return response;
        })
        .catch((err) => {
          commit('fetchError', err);

          return Promise.reject(err);
        });
    },

    react(
      { commit, getters, rootGetters },
      {
        config = {},
        args = {},
      } = {},
    ) {
      let reactionTypeId = args.reactionTypeId;
      const { spaceId, pageId, resourceType, resourceId } = args;

      if (!rootGetters['reactionTypes/byId'](reactionTypeId)) {
        throw new Error(`Invalid 'reactionTypeId' '${reactionTypeId}' specified`);
      }

      // Note: it's the responsibility of this store module to determine whether or not the user is
      // adding or removing a reaction. 'reactionTypeId' must be specified as an argument for this
      // action, with the action determining if it needs to be sent to the API/committed to state
      // as 'null' (for removing a reaction that already exists)
      const currentReactionTypeId = getters.getUserReactionForResource(resourceType, resourceId);

      if (Number(currentReactionTypeId) && Number(currentReactionTypeId) === Number(reactionTypeId)) {
        // eslint-disable-next-line no-param-reassign
        reactionTypeId = null;
      }

      commit('reactStart');

      commit('removeUserReaction', { userId: rootGetters['session/userId'], resourceType, resourceId });

      if (reactionTypeId) {
        commit('addUserReaction', { userId: rootGetters['session/userId'], reactionTypeId, resourceType, resourceId });
      }

      const url = customUrlFn(spaceId, pageId, resourceType, resourceId);

      return client.put(url, preparePayload(reactionTypeId), prepareConfig(config))
        .then((response) => {
          const params = { resourceType, resourceId, ...parseResponse(response) };

          commit('reactSuccess', { params });

          return response;
        })
        .catch((err) => {
          commit('reactError', err);

          // Revert the optimistic commit of the user's new reaction to the original value
          if (!Number(currentReactionTypeId)) {
            commit('removeUserReaction', { userId: rootGetters['session/userId'], resourceType, resourceId });
          } else {
            commit('addUserReaction', { userId: rootGetters['session/userId'], currentReactionTypeId, resourceType, resourceId });
          }

          return Promise.reject(err);
        });
    },

    updateViaSocket({ commit }, response) {
      const [module, resourceType, operation] = response.operation.split('.');
      const resourceId = response.objectId;

      if (module !== 'reaction' || operation !== 'updated') {
        logWarning(`'reactions' store module does not support the operation '${response.operation}'`);
        return;
      }

      if (!validateResourceType(resourceType)) {
        logWarning(`'reactions' store module does not support the operation '${response.operation}'`);
        return;
      }

      if (!response.data || !Array.isArray(response.data.reactions)) {
        logWarning(`Malformed response for socket operation '${response.operation}'`);
        return;
      }

      const counts = getSupportedReactionTypeIds(resourceType)
        .reduce((result, reactionTypeId) => {
        // eslint-disable-next-line no-param-reassign
          result[Number(reactionTypeId)] = 0;
          return result;
        }, {});

      response.data.reactions
        .forEach((reactionType) => {
        // eslint-disable-next-line no-param-reassign
          counts[Number(reactionType.id)] = Number(reactionType.count);
        });

      commit('setCountsForResource', { resourceType, resourceId, counts });
    },
  },
  mutations: {
    fetchStart(state) {
      state.isFetching = true;
    },

    fetchSuccess(
      state,
      {
        reactionTypeId = null,
        resourceType,
        resourceId,
        reactions = {},
        reactionsMeta = {},
        pagedResponse = [],
        pagedResponseMeta = {},
      },
    ) {
      if (!Object.values(RESOURCE_TYPES).includes(resourceType)) {
        throw new Error(`'${resourceType}' is not a supported reaction resource type`);
      }

      // Note: 'resourceId' is a string as it supports GUIDs
      if (!resourceId) {
        throw new Error('\'resourceId\' is missing or invalid');
      }

      // Note: 'reactionTypeId' indicates this request was for a list of reactions for a specific
      // 'reaction type' and therefore can be paginated. If this value is not present, we should
      // blow away any stored pagination data
      if (reactionTypeId) {
        deepVueSet(state, [resourceType, resourceId, 'pagedResponse'], pagedResponse);
        deepVueSet(state, [resourceType, resourceId, 'pagedResponseMeta'], pagedResponseMeta);
      } else {
        deepVueSet(state, [resourceType, resourceId, 'pagedResponse'], null);
        deepVueSet(state, [resourceType, resourceId, 'pagedResponseMeta'], null);
      }

      // Note: 'reactionTypeId' indicates this request was for a list of reactions for a specific
      // 'reaction type' and therefore should only replace the stored reactions for the specific
      // type
      if (reactionTypeId) {
        deepVueSet(state, [resourceType, resourceId, 'reactions', reactionTypeId], reactions[reactionTypeId]);
      } else {
        deepVueSet(state, [resourceType, resourceId, 'reactions'], reactions);
      }

      deepVueSet(state, [resourceType, resourceId, 'reactionsMeta'], reactionsMeta);

      state.isFetching = false;
      state.fetchError = null;
    },

    fetchError(state, err) {
      state.fetchError = err;
      state.isFetching = false;
    },

    setCountsForResource(state, { resourceType, resourceId, counts }) {
      deepVueSet(state, [resourceType, resourceId, 'reactionsMeta', 'counts'], counts);
    },

    setUserReactionIdForResource(state, { resourceType, resourceId, userReactionId }) {
      deepVueSet(state, [resourceType, resourceId, 'reactionsMeta', 'userReactionId'], userReactionId);
    },

    setMetaForResource(state, { resourceType, resourceId, meta }) {
      deepVueSet(state, [resourceType, resourceId, 'reactionsMeta'], meta);
    },

    removeUserReaction(state, args) {
      const { userId, resourceType, resourceId } = args;

      if (
        !state[resourceType]
        || !state[resourceType][resourceId]
        || !state[resourceType][resourceId].reactions
        || !state[resourceType][resourceId].reactionsMeta
      ) {
        return;
      }

      const currentReactionTypeId = state[resourceType][resourceId].reactionsMeta.userReactionId;
      if (!currentReactionTypeId) {
        return;
      }

      // Remove the user entry in the list of reactions
      if (
        state[resourceType]
          && state[resourceType][resourceId]
          && state[resourceType][resourceId].reactions
          && Array.isArray(state[resourceType][resourceId].reactions[currentReactionTypeId])
      ) {
        const index = state[resourceType][resourceId].reactions[currentReactionTypeId]
          .findIndex((user) => Number(user.id) === Number(userId));
        deepVueDelete(state, [resourceType, resourceId, 'reactions', Number(currentReactionTypeId), index]);
      }

      // Clear the 'userReactionId' in the meta
      deepVueSet(state, [resourceType, resourceId, 'reactionsMeta', 'userReactionId'], null);

      // Decrement the associated count in the meta
      const currentCount = (
        state[resourceType]
          && state[resourceType][resourceId]
          && state[resourceType][resourceId].reactionsMeta
          && state[resourceType][resourceId].reactionsMeta.counts
          && state[resourceType][resourceId].reactionsMeta.counts[Number(currentReactionTypeId)]
      ) || 0;

      // eslint-disable-next-line max-len
      deepVueSet(state, [resourceType, resourceId, 'reactionsMeta', 'counts', Number(currentReactionTypeId)], currentCount <= 0 ? 0 : currentCount - 1);
    },

    addUserReaction(state, args) {
      const { userId, reactionTypeId = null, resourceType, resourceId } = args;

      if (!reactionTypeId) {
        return;
      }

      // Build out any part of the state path that doesn't exist
      if (!state[resourceType]) {
        Vue.set(state, resourceType, {});
      }

      if (
        !state[resourceType][resourceId]
        || !state[resourceType][resourceId].reactions
        || !state[resourceType][resourceId].reactionsMeta
      ) {
        Vue.set(state[resourceType], resourceId, createEmptyReactionsObject(resourceType));
      }

      // Add the user entry in the list of reactions
      const userObj = {
        id: userId,
        type: 'users',
      };

      if (!Array.isArray(state[resourceType][resourceId].reactions[Number(reactionTypeId)])) {
        Vue.set(state[resourceType][resourceId].reactions, Number(reactionTypeId), [userObj]);
      } else {
        state[resourceType][resourceId].reactions[Number(reactionTypeId)].push(userObj);
      }

      // Add the 'userReactionId' in the meta
      deepVueSet(state, [resourceType, resourceId, 'reactionsMeta', 'userReactionId'], Number(reactionTypeId));

      // Increment the associated count in the meta
      const currentCount = (
        state[resourceType][resourceId].reactionsMeta.counts
              && state[resourceType][resourceId].reactionsMeta.counts[Number(reactionTypeId)]
      ) || 0;

      deepVueSet(state, [resourceType, resourceId, 'reactionsMeta', 'counts', Number(reactionTypeId)], currentCount + 1);
    },

    reactStart(state) {
      state.isReacting = true;
      state.reactError = null;
    },

    reactSuccess(state) {
      state.isReacting = false;
      state.reactError = null;
    },

    reactError(state, err) {
      state.isReacting = false;
      state.reactError = err;
    },
  },
  getters: {
    /* eslint-disable arrow-body-style */
    getReactionsForResource: (state) => {
      return (resourceType, resourceId) => {
        if (!Object.values(RESOURCE_TYPES).includes(resourceType)) {
          throw new Error(`'${resourceType}' is not a supported reaction resource type`);
        }

        // Note: 'resourceId' is a string as it supports GUIDs
        if (!resourceId) {
          throw new Error('\'resourceId\' is missing or invalid');
        }

        if (
          !state[resourceType]
          || !state[resourceType][resourceId]
          || !state[resourceType][resourceId].reactions
        ) {
          return {};
        }

        return state[resourceType][resourceId].reactions;
      };
    },

    getReactionsMetaForResource: (state) => {
      return (resourceType, resourceId) => {
        if (!Object.values(RESOURCE_TYPES).includes(resourceType)) {
          throw new Error(`'${resourceType}' is not a supported reaction resource type`);
        }

        // Note: 'resourceId' is a string as it supports GUIDs
        if (!resourceId) {
          throw new Error('\'resourceId\' is missing or invalid');
        }

        if (
          !state[resourceType]
          || !state[resourceType][resourceId]
          || !state[resourceType][resourceId].reactionsMeta
        ) {
          return {};
        }

        return state[resourceType][resourceId].reactionsMeta;
      };
    },

    getDecoratedReactionsForResource: (state, getters, rootState) => {
      return (resourceType, resourceId) => {
        return Object.entries(getters.getReactionsForResource(resourceType, resourceId))
          .reduce((result1, [reactionTypeId, users]) => {
            // eslint-disable-next-line no-param-reassign
            result1[Number(reactionTypeId)] = users.reduce((result2, user) => {
              if (!rootState.users || !rootState.users.entities || !rootState.users.entities[Number(user.id)]) {
                return result2;
              }

              result2.push(rootState.users.entities[Number(user.id)]);

              return result2;
            }, []);

            return result1;
          }, {});
      };
    },

    getReactionsForResourceAndReactionType: (state, getters) => {
      return (resourceType, resourceId, reactionTypeId) => {
        const reactions = getters.getReactionsForResource(resourceType, resourceId);

        if (!Array.isArray(reactions[Number(reactionTypeId)])) {
          return [];
        }

        return reactions[Number(reactionTypeId)];
      };
    },

    getDecoratedReactionsForResourceAndReactionType: (state, getters, rootState) => {
      return (resourceType, resourceId, reactionTypeId) => {
        return getters.getReactionsForResourceAndReactionType(resourceType, resourceId, Number(reactionTypeId))
          .reduce((result, user) => {
            if (!rootState.users || !rootState.users.entities || !rootState.users.entities[Number(user.id)]) {
              return result;
            }

            result.push(rootState.users.entities[Number(user.id)]);

            return result;
          }, []);
      };
    },

    getReactionsForResourceByUser: (state, getters) => {
      return (resourceType, resourceId) => {
        return Object.entries(getters.getReactionsForResource(resourceType, resourceId))
          .reduce((result, [reactionTypeId, users]) => {
            users.forEach((user) => {
            // eslint-disable-next-line no-param-reassign
              result[Number(user.id)] = Number(reactionTypeId);
            });

            return result;
          }, {});
      };
    },

    getReactionsForResourceByType: (state, getters) => {
      return (resourceType, resourceId) => {
        return Object.entries(getters.getReactionsForResource(resourceType, resourceId))
          .reduce((result1, [reactionTypeId, users]) => {
          // eslint-disable-next-line no-param-reassign
            result1[Number(reactionTypeId)] = users.reduce((result2, user) => {
            // eslint-disable-next-line no-param-reassign
              result2[Number(user.id)] = true;
              return result2;
            }, {});

            return result1;
          }, {});
      };
    },

    getUserReactionForResource: (state, getters) => {
      return (resourceType, resourceId) => {
        return getters.getReactionsMetaForResource(resourceType, resourceId).userReactionId;
      };
    },

    getCountForResourceAndReactionType: (state, getters) => {
      return (resourceType, resourceId, reactionTypeId) => {
        const meta = getters.getReactionsMetaForResource(resourceType, resourceId);
        return (meta && meta.counts && meta.counts[reactionTypeId]) || 0;
      };
    },

    checkIfResourceHasReactions: (state, getters) => {
      return (resourceType, resourceId) => {
        const meta = getters.getReactionsMetaForResource(resourceType, resourceId);

        if (!meta || !meta.counts) {
          return false;
        }

        return Object.values(meta.counts)
          .find((count) => count > 0);
      };
    },
  },
};

function validateResourceType(resourceType) {
  return Object.values(RESOURCE_TYPES).includes(resourceType);
}

function createEmptyReactionsObject(resourceType) {
  if (!validateResourceType(resourceType)) {
    throw new Error(`'${resourceType}' is not a supported reaction resource type`);
  }

  return {
    reactions: getSupportedReactionTypeIds(resourceType).reduce((acc, cur) => {
      // eslint-disable-next-line no-param-reassign
      acc[cur] = [];
      return acc;
    }, {}),
    reactionsMeta: {
      userReactionId: null,
      counts: getSupportedReactionTypeIds(resourceType).reduce((acc, cur) => {
        // eslint-disable-next-line no-param-reassign
        acc[Number(cur.id)] = 0;
        return acc;
      }, {}),
    },
  };
}

const preparePayload = (reactionTypeId) => ({
  reaction: {
    reactionType: reactionTypeId,
  },
});

const prepareConfig = (config) => {
  // Always include 'users'
  // eslint-disable-next-line no-param-reassign
  config.params = isPlainObject(config.params) ? config.params : {};
  // eslint-disable-next-line no-param-reassign
  config.params.include = 'users';
};

const parseResponse = (response, reactionTypeId = null) => {
  // eslint-disable-next-line no-param-reassign
  response = cloneDeep(response);

  const { data, status } = response;

  if (status === 204) {
    return response;
  }

  let pagedResponse = null;
  let pagedResponseMeta = null;
  let reactions = {};

  // Note: only the endpoint that supplies a 'reaction type ID' supports pagination
  if (reactionTypeId) {
    reactions[reactionTypeId] = data.reactions;
    pagedResponse = data.reactions;
    pagedResponseMeta = data.meta.page;
  } else {
    // Remove 'meta' from the 'reactions' object as it's bundled seperately
    reactions = cloneDeep(data.reactions);
    delete reactions.meta;
  }

  return {
    reactions,
    reactionsMeta: data.meta.reactions,
    pagedResponse,
    pagedResponseMeta,
  };
};

const customUrlFn = (spaceId, pageId, resourceType, resourceId, reactionTypeId = null) => {
  if (!isValidId(spaceId)) {
    throw new Error('\'spaceId\' is missing or invalid');
  }

  if (!isValidId(pageId)) {
    throw new Error('\'pageId\' is missing or invalid');
  }

  if (!resourceType) {
    throw new Error('\'resourceType\' is missing or invalid');
  }

  // Note: 'resourceId' is a string as it supports GUIDs
  if (!resourceId) {
    throw new Error('\'resourceId\' is missing or invalid');
  }

  let resource = `reactions/spaces/${spaceId}/pages/${pageId}`;
  switch (resourceType) {
    case RESOURCE_TYPES.PAGE: {
      resource = `${resource}`;
      break;
    }
    case RESOURCE_TYPES.COMMENT: {
      resource = `${resource}/comments/${resourceId}`;
      break;
    }
    case RESOURCE_TYPES.INLINE_COMMENT: {
      resource = `${resource}/comments/inline/${resourceId}`;
      break;
    }
    case RESOURCE_TYPES.PLUGIN: {
      resource = `${resource}/plugins/${resourceId}`;
      break;
    }
    default: {
      throw new Error(`'${resourceType}' is not a supported reaction resource type`);
    }
  }

  if (reactionTypeId) {
    resource = `${resource}/reactiontypes/${reactionTypeId}`;
  }

  return buildApiUrl({ resource });
};
