import { isEmpty, isNaN, isObject, isString, get } from 'lodash-es';
import axios from '@/utils/vuex/client';

import { fixBody } from '@/utils/helpers/fixScroll';
import spaceConstants from '@/utils/constants/spaceConstants';
import isPageIdValid from '@/utils/helpers/isValidId';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import { STATES } from '@/utils/constants/spacePage';
import SPACE_PAGE_ACTIONS from '@/utils/constants/spacePageActions';
import getResolvedSpaceMembers from '@/utils/helpers/getResolvedSpaceMembers';
import { ROUTE_NAMES } from '@/router/routes';
import {
  pageTemplateCreateRouteName,
  pageTemplateEditRouteName,
  globalPageTemplateCreateRouteName,
  globalPageTemplateEditRouteName,
} from '@/router/pageTemplates';

const getPageIdFromParam = (param) => {
  if (!isString(param) || isEmpty(param)) {
    return null;
  }

  if (param === spaceConstants.defaultPageCode) {
    return param;
  }

  let pageId = param.split('-')[0];

  pageId = parseInt(pageId, 10);

  if (isNaN(pageId)) {
    return null;
  }

  if (!isPageIdValid(pageId)) {
    return null;
  }

  return pageId;
};

export default {
  namespaced: true,
  state: {
    isDrawerOpen: false,
    isEmbedded: false,
    isPluginFullScreen: false,
    isFlushingEditorOnPageCodeChange: false,
    activeSpacePermission: null,
    previousSpacePageRoute: null,
    settingNavigation: [
      {
        title: 'Space Options',
        uri: '',
      },
    ],
  },
  mutations: {
    handleDrawerOpenMutation(state, isOpen) {
      fixBody(isOpen);
      state.isDrawerOpen = isOpen;
    },

    isPluginFullScreen(state, isPluginFullScreen) {
      state.isPluginFullScreen = isPluginFullScreen;
    },

    modifyDropdownItemOpenState(state, { item, openState }) {
      /* eslint-disable-next-line no-param-reassign */
      item.isOpen = openState;
    },

    updateCurrentNavigationTab(state, navigationTab) {
      state.currentNavigationTab = navigationTab;
    },

    setPreviousSpacePageRoute(state, route) {
      state.previousSpacePageRoute = route;
    },

    setActiveSpacePermission(state, permission) {
      state.activeSpacePermission = permission;
    },

    setEmbeddedState(state, isEmbedded) {
      state.isEmbedded = isEmbedded;
    },

    setIsFlushingEditorOnPageCodeChange(state, flushState) {
      state.isFlushingEditorOnPageCodeChange = flushState;
    },
  },
  actions: {
    openDrawer({ commit }) {
      commit('handleDrawerOpenMutation', true);
    },

    closeDrawer({ commit }) {
      commit('handleDrawerOpenMutation', false);
    },

    closeDropdownItem({ commit }, item) {
      if (Object.prototype.hasOwnProperty.call(item, 'isOpen')) {
        commit('modifyDropdownItemOpenState', { item, openState: false });
      }
    },

    openDropdownItem({ commit }, item) {
      if (Object.prototype.hasOwnProperty.call(item, 'isOpen')) {
        commit('modifyDropdownItemOpenState', { item, openState: true });
      }
    },

    setActiveSpaceHomePageId({ commit }, id) {
      commit('setActiveSpaceHomePageId', { id });
    },

    toggleDropdownItem({ commit }, item) {
      if (Object.prototype.hasOwnProperty.call(item, 'isOpen')) {
        commit('modifyDropdownItemOpenState', { item, openState: !item.isOpen });
      }
    },

    async setActiveSpacePermission({ commit }, identifier) {
      if (!identifier || !identifier.id) {
        return;
      }

      const { data } = await axios.get(
        buildApiUrl({
          resource: `spaces/${identifier.id}/permission`,
        }),
      );

      commit('setActiveSpacePermission', data.permission.level);
    },
  },
  getters: {
    activeSpace(state, getters, rootState, rootGetters) {
      const spaceCode = get(rootState, 'route.params.spaceCode');

      return !spaceCode
        ? null
        : rootGetters['spaces/byCode'](spaceCode);
    },

    activeSpaceId(state, getters) {
      if (getters.activeSpace) {
        return getters.activeSpace.id;
      }
      if (getters.activePage) {
        return getters.activePage.space.id;
      }
      return undefined;
    },

    activeSpaceHasLinkedProject(state, getters, rootState, rootGetters) {
      return !!(rootGetters['launchpad/hasProjects']
        && getters.activeSpace
        && getters.activeSpace.projectId
      );
    },

    activeSpacePermission(state) {
      return state.activeSpacePermission;
    },

    activePage(state, getters, rootState, rootGetters) {
      if (getters['activeSpacePages/isLoading']) {
        return null;
      }

      let pageCode = get(rootState, 'route.params.pageCode');
      const spaceCode = get(rootState, 'route.params.spaceCode');

      pageCode = getPageIdFromParam(pageCode);

      if (pageCode == null) {
        return null;
      }

      if (pageCode === spaceConstants.defaultPageCode) {
        const space = rootGetters['spaces/byCode'](spaceCode);
        if (space) {
          return rootGetters['pages/getActiveHomePage'](space.id);
        }
      }

      return rootGetters['pages/byId'](pageCode);
    },

    activePageId(state, getters) {
      return getters.activePage && getters.activePage.id;
    },

    activePageSlug(state, getters, rootState, rootGetters) {
      const activePage = rootGetters['navigation/activePage'];

      if (!activePage || !activePage.id) {
        return null;
      }

      if (activePage.isHomePage) {
        return spaceConstants.defaultPageCode;
      }

      if (!activePage.slug) {
        return null;
      }

      return `${activePage.id}-${activePage.slug}`;
    },

    activePageTitle(state, getters) {
      if (getters.isActivePageAHomepage) {
        if (getters.activeSpace && getters.activeSpace.title) {
          return getters.activeSpace.title;
        }
      }

      if (getters.activePage && getters.activePage.title) {
        return getters.activePage.title;
      }

      return '';
    },

    isActivePageAHomepage(state, getters, rootState, rootGetters) {
      if (!getters.activePage) {
        return false;
      }

      return isObject(getters.activeSpace)
        && Number(rootGetters['activeSpacePages/homePageId']) === Number(getters.activePageId);
    },

    isActivePageFullWidth(state, getters) {
      if (!getters.activePage) {
        return false;
      }

      return getters.activePage.isFullWidth;
    },

    isActivePageARootLevelPage(state, getters, rootState, rootGetters) {
      if (!getters.activePage) {
        return false;
      }

      if (
        rootGetters['activeSpacePages/isLoading']
        || rootGetters['trashedSpacePages/isLoading']
      ) {
        return false;
      }

      const namespace = rootGetters['trashedSpacePages/getPageById'](getters.activePage.id)
        ? 'trashedSpacePages'
        : 'activeSpacePages';

      return rootGetters[`${namespace}/isPageARootLevelPage`](getters.activePage.id);
    },

    isActivePageInTrash(state, getters) {
      return getters.activePage
        && getters.activePage.state === STATES.REMOVED;
    },

    isActivePagePrivate(state, getters, rootState, rootGetters) {
      return !getters.isActivePageInTrash
        ? rootGetters['activeSpacePages/isPagePrivate'](getters.activePageId)
        : rootGetters['trashedSpacePages/isPagePrivate'](getters.activePageId);
    },

    isActivePageEmpty(state, getters) {
      return getters.activePage
        && (getters.activePage.content === ''
          || getters.activePage.content === '<div><br/></div>'
          || getters.activePage.content === '<p><br/></p>'
        );
    },

    isPluginFullScreen(state) {
      return state.isPluginFullScreen;
    },

    activeSpaceCode(state, getters, rootState, rootGetters) {
      const activeSpace = rootGetters['navigation/activeSpace'];

      if (activeSpace && activeSpace.code) {
        return activeSpace.code;
      }

      return '';
    },

    activeSpaceColor(state, getters, rootState, rootGetters) {
      const activeSpace = rootGetters['navigation/activeSpace'];

      if (activeSpace && activeSpace.spaceColor) {
        return activeSpace.spaceColor;
      }

      return '';
    },

    activeSpaceIcon(state, getters, rootState, rootGetters) {
      const activeSpace = rootGetters['navigation/activeSpace'];

      if (activeSpace && activeSpace.icon) {
        return activeSpace.icon;
      }

      return '';
    },

    activeSpaceMembers(state, getters, rootState, rootGetters) {
      const activeSpace = rootGetters['navigation/activeSpace'];
      if (!activeSpace) {
        return {};
      }

      const companiesMap = rootGetters['companies/list'].reduce((result, company) => {
        result.set(Number(company.id), company);
        return result;
      }, new Map());

      const teamsMap = new Map();

      const usersMap = rootGetters['users/activeUsers'].reduce((result, user) => {
        result.set(Number(user.id), user);
        return result;
      }, new Map());

      return getResolvedSpaceMembers(
        companiesMap,
        teamsMap,
        usersMap,
        rootGetters['spacePermissions/list'],
      );
    },

    defaultPageCode() {
      return spaceConstants.defaultPageCode;
    },

    isEditingSpacePage(state, getters, rootState) {
      return rootState.route.name === ROUTE_NAMES.SPACE_PAGE
        && rootState.route.params.action === SPACE_PAGE_ACTIONS.EDIT;
    },

    isEditingTemplatePage(state, getters, rootState) {
      return [
        pageTemplateCreateRouteName,
        pageTemplateEditRouteName,
        globalPageTemplateCreateRouteName,
        globalPageTemplateEditRouteName,
      ].includes(rootState.route.name);
    },

    // Internal Editor Widgets rely upon this getter
    isEditorActive(state, getters) {
      return getters.isEditingTemplatePage || getters.isEditingSpacePage;
    },

    isRequiredReadingDiffRoute(state, getters, rootState) {
      return rootState.route.params.action === SPACE_PAGE_ACTIONS.DIFF;
    },

    isSpaceOrganizeRoute(state, getters, rootState) {
      return rootState.route.name === ROUTE_NAMES.SPACE_SETTINGS_ORGANIZE;
    },
    isSpaceHomePage(state, getters, rootState) {
      // this is required because specifically due to the unblocking,
      // we use it to determine whether or not breadcrumbs, or SpaceIcon should render
      // it fixes an couple of issues with breadcrumbs flashing when switching space.
      return rootState.route.params.pageCode === spaceConstants.defaultPageCode;
    },

    previousSpacePageRoute(state) {
      return state.previousSpacePageRoute;
    },

    previousSpaceCode(state) {
      return state.previousSpacePageRoute?.params?.spaceCode;
    },

    previousPageCode(state) {
      return state.previousSpacePageRoute?.params?.pageCode;
    },

    hasPreviousPageInMemory(state, getters) {
      return !!(getters.previousPageCode);
    },
  },
};
