import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import { debounce } from 'lodash-es';

import SpacesEditorBody from '@sections/SpacesEditor/SpacesEditorBody';

import InlineCommentMixin from '@/utils/mixins/inlineCommentMixin';

import asyncTryCatchHelper from '@/utils/helpers/asyncTryCatchHelper';

// @vue/component
export default {
  name: 'SpacesEditorCollaborativeBody',
  extends: SpacesEditorBody,
  mixins: [InlineCommentMixin],

  data() {
    return {
      otClientInstance: null,
      hasNeverConnectedToOtSuccessfully: true,
      otConnectionTimeout: null,
    };
  },

  computed: {
    ...mapState('ui/spacesEditor', {
      hasOtConnection: 'hasOtConnection',
      hasOtConnectionError: 'hasOtConnectionError',
      usersPresentList: 'usersPresentList',
      hasPendingSync: 'hasPendingSync',
      isEditorActive: 'isEditorActive',
    }),

    ...mapState('ui/inlineComments', {
      activeCommentId: 'activeCommentId',
      shouldShowCommentsGutter: 'shouldShowCommentsGutter',
    }),

    ...mapGetters('users', {
      users: 'list',
    }),

    ...mapGetters('ui/spacePage', ['shouldResyncOtContent']),

    richTextOtEventListeners() {
      return {
        'ot-operation': this.onOtOperation,
        'ot-presence': this.onOtPresence,
        'ot-nothing-pending': this.onOtNothingPending,
        'ot-when-nothing-pending': this.onOtWhenNothingPending,
        'ot-load': this.onOtLoad,
        'ot-local-operation': this.onLocalOtOperation,
        'ot-connected': this.onOtConnected,
        'ot-connecting': this.onOtConnecting,
        'ot-disconnected': this.onOtDisconnected,
        'ot-connection-error': this.onOtConnectionError,
      };
    },

    richTextOtProps() {
      return {
        'ot-document-id': this.otConfig.documentId,
        'ot-collection-name': this.otConfig.collectionName,
      };
    },
  },

  methods: {
    ...mapMutations('ui/spacesEditor', [
      'setHasPendingSync',
      'setHasOtConnection',
      'setHasOtConnectionError',
      'setLastSyncedContent',
      'setShouldShowEstablishingConnectionScreen',
      'setUsersPresentList',
      'clearUsersPresentList',
    ]),

    ...mapMutations('ui/inlineComments', [
      'setActiveCommentId',
    ]),

    ...mapActions('ui/inlineComments', ['resetStoredScrollPositions']),

    ...mapMutations('inlineComments', ['clearCommentsWithNewReplies']),

    ...mapActions('alerts', ['showAlert']),

    ...mapActions('users', { fetchUsers: 'fetchList' }),

    ...mapActions('apiKeys', [
      'fetchCollaborativeEditingApiKey',
    ]),

    ...mapActions('ui/spacePage', ['setShouldResyncOtContent']),

    async onOtPresence({ src, presence }) {
      if (!presence) {
        // remove user when they leave
        const removedPresence = this.usersPresentList.filter((u) => u.src !== src);
        this.setUsersPresentList(removedPresence);
        return;
      }
      // get the users if not present already
      if (!this.users || this.users.length === 0) {
        const [err] = await asyncTryCatchHelper(this.fetchUsers);

        if (err) {
          this.showAlert({ type: 'error', message: 'Unable to retrieve the user list' });
          return;
        }
      }

      // set the user that is present in this session
      const user = this.users.find((u) => u.id === Number(presence.userId));

      if (user && presence) {
        const presenceColour = presence.backgroundColor;

        /* eslint-disable-next-line no-param-reassign */
        presence.label = this.$options.filters.fullName(user);

        /* eslint-disable-next-line no-param-reassign */
        presence.avatar = user.avatar;

        /* eslint-disable-next-line no-underscore-dangle, no-param-reassign */
        presence.labelNode.style.cssText += 'font-size:14px;font-weight:semi-bold';

        /* eslint-disable-next-line no-underscore-dangle, no-param-reassign */
        presence.barNode.setAttribute('data-user-id', presence.userId);

        this.setUsersPresentList([{ src, presenceColour, user }, ...this.usersPresentList]);
      }
    },

    /* eslint-disable-next-line func-names */
    onOtNothingPending: debounce(function () {
      this.setHasPendingSync(false);
      this.setLastSyncedContent(this.content);
    }, 100),

    onOtWhenNothingPending({ editor }) {
      this.onEditorReady();
      /* eslint-disable-next-line no-param-reassign */
      this.otClientInstance = editor.plugins.ot.client;

      this.clearOtConnectionTimeout();

      this.startImgMutationObserver(editor);

      if (!this.isEditorActive) {
        this.disconnectOt();
      }
    },

    onOtLoad({ editor }) {
      this.content = editor.getContent();
      this.initCommentEventListeners();
    },

    onOtOperation({ source }) {
      // Operation was submitted by a remote user
      if (!source) {
        this.refreshAnchorPopover();
        this.repositionAnchorPopover();
      }
    },

    /* eslint-disable-next-line func-names */
    onLocalOtOperation: debounce(function () {
      this.setHasPendingSync(true);
    }, 100),

    onOtConnecting() {
      this.clearOtConnectionTimeout();
      this.startOtConnectionTimeout();
    },

    onOtConnected() {
      this.setHasOtConnection(true);
      this.hasNeverConnectedToOtSuccessfully = false;
      this.setHasOtConnectionError(false);
      this.setShouldShowEstablishingConnectionScreen(false);
      this.clearOtConnectionTimeout();
    },

    onOtDisconnected() {
      this.setHasOtConnection(false);
      this.setHasOtConnectionError(false);
      this.clearOtConnectionTimeout();

      // dont start timeout if user has just closed the editor
      if (this.isEditorActive) {
        this.startOtConnectionTimeout();
      }
    },

    onOtConnectionError({ editor }) {
      if (!this.userCanEditPage && editor?.plugins?.ot?.client) {
        // eslint-disable-next-line no-param-reassign
        editor.plugins.ot.client.shouldConnect = false;
        return;
      }

      /* eslint-disable func-names */
      debounce(function () {
        this.fetchCollaborativeEditingApiKey();
      }, 120000, { leading: true });
    },

    tryConnectOt() {
      if (this.otClientInstance) {
        this.otClientInstance.shouldConnect = true;
      }
    },

    disconnectOt() {
      if (this.otClientInstance && this.otClientInstance.shouldConnect) {
        this.otClientInstance.shouldConnect = false;
      }
    },

    onOtConnectionTimeout() {
      if (!this.isEditorActive) {
        return;
      }

      if (!this.hasOtConnection) {
        this.setHasOtConnectionError(true);
      }

      if (this.hasNeverConnectedToOtSuccessfully) {
        this.setShouldShowEstablishingConnectionScreen(true);
      }
    },

    startOtConnectionTimeout() {
      this.otConnectionTimeout = setTimeout(this.onOtConnectionTimeout, 3000);
    },

    clearOtConnectionTimeout() {
      this.otConnectionTimeout = clearTimeout(this.otConnectionTimeout);
    },

    onCloseTab(e) {
      if (this.hasPendingSync) {
        /* eslint-disable no-param-reassign */
        e.returnValue = 'Unsynced changes will be lost. Are you sure you want to close this tab?';
      }
    },
  },

  beforeDestroy() {
    this.clearOtConnectionTimeout();
    this.clearUsersPresentList();
    this.clearCommentsWithNewReplies();

    if (this.activeCommentId) {
      this.setActiveCommentId(null);
      this.resetStoredScrollPositions();
    }
  },

  watch: {
    isTinyMCEReady: {
      immediate: true,
      handler(val) {
        if (val) {
          this.scrollToCommentAnchor();
        }
      },
    },
    isEditorActive: {
      immediate: true,
      handler(isEditing) {
        if (isEditing) {
          this.tryConnectOt();

          window.addEventListener('beforeunload', this.onCloseTab);
        } else {
          this.disconnectOt();
          window.removeEventListener('beforeunload', this.onCloseTab);
        }
      },
    },

    hasOtConnection() {
      if (this.hasOtConnection) {
        setTimeout(() => {
          this.removeOrphanedInlineComments();
        }, 0);
      }
    },

    hasOtConnectionError(newVal) {
      // If they can't publish the page they will be disconnected from OT,
      // so don't show them the alert.
      if (newVal) {
        this.showAlert({
          type: 'error',
          message: 'Draft auto save and collaborative editing connection lost',
        });
      } else {
        this.showAlert({
          type: 'success',
          message: 'Draft auto save and collaborative editing connection back online',
        });
      }
    },

    // Used to resync ot content to retrieve latest inline comments markers made in editor
    shouldResyncOtContent: {
      immediate: false,
      handler(shouldResyncOtContent) {
        if (shouldResyncOtContent) {
          this.tryConnectOt();

          setTimeout(() => {
            this.disconnectOt();
            this.setShouldResyncOtContent(false);
          }, 2000);
        }
      },
    },
  },
};
