import { match } from 'path-to-regexp';

export default (path) => {
  const matchFn = match('/:environment/:tag?/:spaceCode/page/:pageCode');
  const { spaceCode, pageCode } = matchFn(path).params;
  const pageId = pageCode.split('-')[0];
  return {
    spaceCode,
    pageId,
  };
};
