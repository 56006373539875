import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import { isDevEnvironment } from '@/utils/helpers/environment.utility';
import modules from './modules';

Vue.use(Vuex);

const store = new Store({
  strict: isDevEnvironment,
  modules,
});

const prepareHotModuleReloading = () => {
  module.hot.accept(['./modules'], () => {
    store.hotUpdate({ modules: modules.default });
  });
};

if (module.hot) { prepareHotModuleReloading(); }

export default store;
