import asyncForEach from '@/utils/helpers/asyncForEach';
import { logError } from '@/utils/helpers/logger.utility';
import { insertCustomElement } from '@/utils/editor/insertFunctions';
import { EDITOR_TYPES } from '@/utils/constants/editorConfig';

// return normalised editorComponent object use to register component
// with Spaces editor
function createEditorComponent(appModule) {
  const customElementName = appModule.isDev
    ? `${appModule.customElementName}-dev`
    : appModule.customElementName;

  const displayName = appModule.isDev
    ? `${appModule.displayName} (dev)`
    : appModule.displayName;

  // we need to define icons and shortcodes here until the sdk is updated
  const iconsMap = {
    'tw-spaces-core-drawio': ['far', 'project-diagram'],
    'tw-spaces-core-accountability-chart': ['far', 'sitemap'],
    'tw-spaces-core-timeline': ['far', 'stream'],
    'tw-spaces-core-check-list-item': ['far', 'ballot-check'],
    'tw-spaces-core-swot': ['far', 'border-all'],
  };

  const shortcodeMap = {
    'tw-spaces-core-drawio': 'draw',
    'tw-spaces-core-accountability-chart': 'acc',
    'tw-spaces-core-timeline': 'time',
    'tw-spaces-core-check-list-item': 'ck',
    'tw-spaces-core-swot': 'swot',
  };

  const isStructureElement = appModule.editor.childNodeNames && appModule.editor.childNodeNames.length > 0;

  return {
    name: customElementName,
    dataCy: customElementName,
    displayName,
    description: appModule.description,
    icon: iconsMap[appModule.customElementName],
    shortcode: shortcodeMap[appModule.customElementName],
    customElementName,
    customElement: appModule.customElement,
    ...appModule.editor,
    attributes: Array.from(new Set([
      'contenteditable',
      'data-diffable',
      'uuid',
      ...appModule.editor.attributes,
    ])),
    isDev: appModule.isDev,
    showInEditorToolbar: true,
    insertFn: insertCustomElement,
    enabledEditors: [EDITOR_TYPES.PAGE, EDITOR_TYPES.TEMPLATE],
    isStructureElement,
  };
}

// returns all editorComponent objects from a list of apps
export const createEditorComponents = (appModules) => {
  const editorComponents = [];
  appModules.forEach((module) => {
    if (module.type === 'custom-element') {
      editorComponents.push(createEditorComponent(module));
    }
  });

  return editorComponents;
};

// async load and define all custom Elements from list of editorComponent Objects
export const defineEditorComponentCustomElements = async (editorComponents) => {
  await asyncForEach(editorComponents, async (component) => {
    const el = await component.customElement();
    window.customElements.define(component.customElementName, el);
  });
};

// extract editor component es modules from apps
export const fetchAppModules = async (apps) => {
  let appModules = [];
  await asyncForEach(apps, async (app) => {
    try {
      const { modules } = await import(/* webpackIgnore: true */ `${app.baseUrl}/spaces.js`);
      // add isDev property to each module
      appModules = appModules.concat(modules.map((module) => ({
        ...module,
        isDev: app.isDev,
      })));
    } catch (e) {
      logError('Failed to dynamically import App es modules. Hint: is your SDK dev server running?', e);
    }
  });
  return appModules;
};

export default {
  fetchAppModules,
  createEditorComponents,
};
