import { render, staticRenderFns } from "./AppLoader.vue?vue&type=template&id=fbdf3c4a&scoped=true&"
import script from "./AppLoader.js?vue&type=script&lang=js&"
export * from "./AppLoader.js?vue&type=script&lang=js&"
import style0 from "./AppLoader.scss?vue&type=style&index=0&id=fbdf3c4a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbdf3c4a",
  null
  
)

export default component.exports