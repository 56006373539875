import Vue from 'vue';
import { isObject, cloneDeep, isBoolean, forEach, isUndefined } from 'lodash-es';

import { createStaticCrudModule } from '@/utils/vuex';
import areArraysDifferent from '@/utils/helpers/areArraysDifferent';
import alphabeticalSort from '@/utils/helpers/alphabeticalSort';
import fullName from '@/utils/filters/fullName';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import { logError } from '@/utils/helpers/logger.utility';

import store from '..';

// Use this to determine what type of RR Asignment has taken place for GA
const requiredReadingAssignmentType = (all, companies = [], individuals = []) => {
  let assignmentType = 'unknown';
  if (all) {
    assignmentType = 'all';
  } else if (companies.length > 0) {
    assignmentType = 'companies';
  } else if ((individuals.length > 0) && (companies.length === 0)) {
    assignmentType = 'individuals';
  }

  return assignmentType;
};

/**
 * This module uses /audience.json to fetch/replace all users required reading
 * assignment status for the page. Users are grouped by Company.
 */
export default createStaticCrudModule({
  only: ['FETCH', 'REPLACE'],
  property: 'requiredReading',
  prepareConfig(config) {
    if (!isObject(config)) {
      /* eslint-disable no-param-reassign */
      config = {};
    }

    if (!isObject(config.params)) {
      config.params = {};
    }

    config.params.include = ['users', 'companies'].join(',');

    return config;
  },
  customUrlFn(spaceId, pageId) {
    if (!spaceId) {
      // If no spaceId is passed through, use activeSpaceId
      spaceId = store.getters['navigation/activeSpaceId'];
    }

    if (!pageId) {
      // If no pageId is passed through, use activePageId
      pageId = store.getters['navigation/activePageId'];
    }

    if (!spaceId || !pageId) {
      throw new Error('Unable to construct URL. Missing required parameter(s)');
    }

    return buildApiUrl({
      resource: `requiredreading/spaces/${spaceId}/pages/${pageId}/audience`,
    });
  },
  state: {
    pendingStateAudienceObject: {},
    isSavingChanges: false,
  },
  getters: {
    /*
    * Getters at the top of the file relate to the original "assignedStateObject"
    * Any getters below relate to the pending state object we use to update when the "Apply"
    * button is pressed
    */
    isRequiredReadingForAllUsers(state) {
      return (state.entity.audience
        && isBoolean(state.entity.audience.all)
        && state.entity.audience.all) || false;
    },

    userCountForCompanyId: (state) => (companyId) => {
      if (!state.entity.audience || !state.entity.audience.companies) {
        return 0;
      }

      const company = state.entity.audience.companies.find((c) => c.company.id === companyId);
      return company.users.length || 0;
    },

    getHydratedData: (state, getters, rootState, rootGetters) => {
      if (
        !state.entity.audience || !state.entity.audience.companies
        || !Array.isArray(state.entity.audience.companies)
      ) {
        return [];
      }

      const companies = cloneDeep(state.entity.audience.companies)
        .filter((c) => {
          if (!Array.isArray(c.users) || !c.users.length) {
            return false;
          }

          if (!rootGetters['companies/byId'](c.company.id)) {
            return false;
          }

          return true;
        });

      companies.forEach((item) => {
        const { meta } = item.company;
        const fetchedCompany = rootGetters['companies/byId'](item.company.id);

        if (!fetchedCompany) {
          return;
        }

        item.company = fetchedCompany;
        item.company.meta = meta;

        const { users } = item;

        users.forEach((user, index) => { // eslint-disable-line
          const originalUser = users.find((x) => x.id === user.id);
          item.users[index] = rootGetters['users/byId'](user.id);
          item.users[index].meta = originalUser.meta;
        });
      });

      return companies;
    },

    assignedStateObject: (state, getters) => {
      if (!getters.getHydratedData) {
        return {};
      }

      const data = cloneDeep(getters.getHydratedData);
      const users = {};
      const companies = {};

      data.forEach((c) => {
        companies[c.company.id] = c.company.meta.assigned;

        c.users.forEach((u) => {
          users[u.id] = u.meta.assigned;
        });
      });

      const newData = {
        all: getters.isRequiredReadingForAllUsers,
        users,
        companies,
      };

      return newData;
    },

    getCompanyIdByUserId: (state) => (userId) => {
      // Find a specific user inside a company, then return the companyId
      let cId = null;
      const items = state.entity.audience.companies;

      for (let x = 0; x < items.length; x++) { // eslint-disable-line
        if (cId) {
          break;
        }

        const companyId = items[x].company.id;
        const { users } = items[x];

        for (let y = 0; y < users.length; y++) { // eslint-disable-line
          if (users[y].id === userId) {
            cId = companyId;
            break;
          }
        }
      }

      return cId;
    },

    getUserIdsByCompany: (state) => (companyId) => {
      const company = state.entity.audience.companies.find((c) => { // eslint-disable-line
        return c.company.id === companyId;
      });

      if (!company || !Array.isArray(company.users)) {
        return [];
      }

      return company.users.map((u) => u.id);
    },

    totalUserCount: (state, getters) => (
      getters.assignedStateObject.users
      && Object.keys(getters.assignedStateObject.users).length
    ) || 0,

    byFullnameOrCompany: (state, getters, rootState, rootGetters) => (query = '') => {
      if (!getters.getHydratedData) {
        return [];
      }

      const data = cloneDeep(getters.getHydratedData);

      return data.map((c) => {
        const queryLC = query.toLowerCase();
        const company = rootGetters['companies/byId'](c.company.id);
        const companyName = company.name;

        const usersMatchingQuery = c.users.filter((u) => {
          const user = rootGetters['users/byId'](u.id);
          const fullname = fullName(user);

          if (fullname.toLowerCase().indexOf(queryLC) !== -1
            || user.email.indexOf(queryLC) !== -1
            || (companyName.toLowerCase().indexOf(queryLC) !== -1)
            || (query === 'me' && u.id === rootGetters['session/userId'])
          ) {
            return true;
          }

          return false;
        });

        c.users = usersMatchingQuery;

        c.users.sort((a, b) => {
          const aName = a.firstname;
          const bName = b.firstname;

          if (aName && bName) {
            return alphabeticalSort(aName.toLowerCase(), bName.toLowerCase(), false);
          }

          return 0;
        });

        return c;
      })
        .sort((a, b) => {
          const cA = a.company.name;
          const cB = b.company.name;

          if (cA && cB) {
            return alphabeticalSort(cA.toLowerCase(), cB.toLowerCase(), false);
          }

          return 0;
        });
    },

    allUserIdsArray(state, getters) {
      const { users } = getters.assignedStateObject;

      return (users && Object.keys(users)
        .map((x) => Number(x))
      ) || [];
    },

    allCompanyIdsArray(state, getters) {
      const { companies } = getters.assignedStateObject;

      return (companies && Object.keys(companies)
        .map((x) => Number(x))
      ) || [];
    },

    assignedUserIds(state, getters) {
      return (
        getters.getHydratedData
        && getters.getHydratedData.reduce((results, company) => {
          if (!company || !company.users.length) {
            return results;
          }

          results = [...results, ...company.users.reduce((userResults, user) => {
            if (user && user.meta && user.meta.assigned) {
              userResults.push(user.id);
            }

            return userResults;
          }, [])];

          return results;
        }, [])
      ) || [];
    },

    assignedCompanyIds(state, getters) {
      return (
        getters.getHydratedData
        && getters.getHydratedData.reduce((results, o) => {
          if (o && o.company && o.company.meta && o.company.meta.assigned) {
            results.push(o.company.id);
          }

          return results;
        }, [])
      ) || [];
    },

    isAnyoneAssigned(state, getters) {
      return !!(
        (
          getters.get
          && getters.get.audience
          && getters.get.audience.all
        )
        || getters.assignedUserIds.length
        || getters.assignedCompanyIds.length
      ) || false;
    },

    pendingAudienceAssignedState: (state) => state.pendingStateAudienceObject,

    pendingAudienceIsRequiredState: (state, getters) => getters.isAnyoneAssigned,

    areAllCompaniesAndUsersSelected(state, getters) {
      const areAllCompaniesSelected = !areArraysDifferent(getters.allCompanyIdsArray,
        getters.selectedPendingCompaniesArray);

      if (areAllCompaniesSelected) {
        return true;
      }

      const areAllUsersSelected = !areArraysDifferent(getters.allUserIdsArray,
        getters.selectedPendingUsersArray);

      if (areAllUsersSelected) {
        return true;
      }

      return false;
    },

    selectedUsersPendingCount(state, getters) {
      return (getters.selectedPendingUsersArray && getters.selectedPendingUsersArray.length) || 0;
    },

    selectedUsersPendingCountByCompanyId: (state, getters) => (companyId) => {
      if (!getters.selectedPendingUsersArray || !getters.getUserIdsByCompany) {
        return 0;
      }

      let countOfUsersSelected = 0;
      const allUsersInCompany = getters.getUserIdsByCompany(companyId);

      getters.selectedPendingUsersArray.forEach((id) => {
        if (allUsersInCompany.includes(id)) {
          countOfUsersSelected += 1;
        }
      });

      return countOfUsersSelected;
    },

    selectedPendingUsersArray(state, getters) {
      const { users } = getters.pendingAudienceAssignedState;

      return (users && Object.keys(users)
        .filter((key) => users[key] === true)
        .map((x) => Number(x))
      ) || [];
    },

    selectedPendingCompaniesArray(state, getters) {
      const { companies } = getters.pendingAudienceAssignedState;

      return (companies && Object.keys(companies)
        .filter((key) => companies[key] === true)
        .map((x) => Number(x))
      ) || [];
    },

    hasPendingChanges: (state, getters) => {
      if (getters.pendingAudienceAssignedState.all !== getters.isRequiredReadingForAllUsers) {
        return true;
      }

      const pendingCompaniesArr = getters.selectedPendingCompaniesArray;
      const originalCompaniesArr = getters.assignedCompanyIds;

      if (areArraysDifferent(originalCompaniesArr, pendingCompaniesArr)) {
        return true;
      }

      const pendingUsersArr = getters.selectedPendingUsersArray;
      const originalUsersArr = getters.assignedUserIds;

      if (areArraysDifferent(originalUsersArr, pendingUsersArr)) {
        return true;
      }

      return false;
    },
  },
  mutations: {
    updatePendingAudienceState(state, data) {
      state.pendingStateAudienceObject = data;
    },

    updateRequiredReadingForEveryone(state, { isAssigned }) {
      if (isUndefined(isAssigned)) {
        throw new Error('Unable to update required reading state for everyone');
      }

      Vue.set(state.pendingStateAudienceObject, 'all', isAssigned);
    },

    updateCompanyState(state, { isAssigned, companyId }) {
      if (!companyId) {
        throw new Error('Unable to update company state');
      }

      Vue.set(state.pendingStateAudienceObject.companies, companyId, isAssigned);
    },

    updateUserState(state, { isAssigned, userId }) {
      if (!userId) {
        throw new Error('Unable to update user state');
      }

      Vue.set(state.pendingStateAudienceObject.users, userId, isAssigned);
    },
    updateSavingState(state, isSaving) {
      state.isSavingChanges = isSaving;
    },
  },
  actions: {
    updateRequiredReadingForEveryone({ commit, getters },
      { isAssigned, shouldUpdateChildren = false } = {}) {
      if (isUndefined(isAssigned)) {
        return;
      }

      commit('updateRequiredReadingForEveryone', { isAssigned });

      if (shouldUpdateChildren) {
        forEach(getters.pendingAudienceAssignedState.users, (val, uKey) => {
          commit('updateUserState', { isAssigned, userId: uKey });
        });

        forEach(getters.pendingAudienceAssignedState.companies, (val, cKey) => {
          commit('updateCompanyState', { isAssigned, companyId: cKey });
        });
      }
    },

    updateCompanyState({ commit, getters, dispatch }, { isAssigned, companyId }) {
      if (isUndefined(companyId) || !getters.pendingAudienceAssignedState.companies
        || !isBoolean(getters.pendingAudienceAssignedState.companies[companyId])
      ) {
        throw new Error('Unable to update company state');
      }

      commit('updateCompanyState', { isAssigned, companyId });

      // If assigning/unassigning a company, update all the users under that company
      const userIds = getters.getUserIdsByCompany(companyId);

      userIds.forEach((userId) => {
        commit('updateUserState', { isAssigned, userId });
      });

      // If unassigning a company and the all state is checked, then uncheck it
      if (!isAssigned && getters.pendingAudienceAssignedState.all) {
        dispatch('updateRequiredReadingForEveryone', {
          isAssigned, shouldUpdateChildren: false,
        });
      }
    },

    updateUserState({ commit, getters, dispatch }, { isAssigned, userId }) {
      if (isUndefined(userId) || !getters.pendingAudienceAssignedState.users
        || !isBoolean(getters.pendingAudienceAssignedState.users[userId])
      ) {
        throw new Error('Unable to update user state');
      }

      commit('updateUserState', { isAssigned, userId });

      // If we have unchecked a user, uncheck the company if it's assigned
      const companyId = getters.getCompanyIdByUserId(userId);

      if (!isAssigned && getters.pendingAudienceAssignedState.companies
        && getters.pendingAudienceAssignedState.companies[companyId]
      ) {
        commit('updateCompanyState', { isAssigned, companyId });
      }

      // If all of the companies userIds are in selectedPendingUsersArray update company to selected
      if (isAssigned) {
        const allUserIdsInCompany = getters.getUserIdsByCompany(companyId);

        if (!areArraysDifferent(allUserIdsInCompany, getters.selectedPendingUsersArray)) {
          commit('updateCompanyState', { isAssigned, companyId });
        }
      }

      // If unassigning a user and the "all" state is checked, then uncheck it
      if (!isAssigned && getters.pendingAudienceAssignedState.all) {
        dispatch('updateRequiredReadingForEveryone', {
          isAssigned, shouldUpdateChildren: false,
        });
      }
    },

    updatePendingAudienceState({ commit, getters }, data) {
      if (!data) {
        data = cloneDeep(getters.assignedStateObject);
      }

      commit('updatePendingAudienceState', data);
    },

    clearPendingChanges({ commit, getters }) {
      const data = cloneDeep(getters.assignedStateObject);
      commit('updatePendingAudienceState', data);
    },

    savePendingChanges({ getters, commit }, { spaceId, pageId }) {
      commit('updateSavingState', true);

      return store.dispatch('requiredReadingAssignment/updateRequiredReadingAudience', {
        all: getters.pendingAudienceAssignedState.all,
        individuals: getters.selectedPendingUsersArray,
        companies: getters.selectedPendingCompaniesArray,
        spaceId,
        pageId,
      });
    },

    /* eslint-disable no-unused-vars */
    async updateRequiredReadingAudience({ getters, commit, rootGetters }, {
      all = getters.isRequiredReadingForAllUsers,
      individuals = getters.assignedUserIds,
      companies = getters.assignedCompanyIds,
      spaceId,
      pageId,
    } = {}) {
      return store.dispatch('requiredReadingAssignment/replace', {
        customUrlFnArgs: [spaceId, pageId],
        data: {
          audience: {
            individuals,
            companies,
            all,
          },
        },
      }).then((response) => {
        commit('updateSavingState', false);

        store.dispatch('requiredReading/fetch');
        // If we are in a page, re-fetch it along with required reading "status" and "next"
        if (rootGetters['navigation/activePageId']) {
          store.dispatch('pages/fetchSingle', {
            customUrlFnArgs: [spaceId],
            id: rootGetters['navigation/activePageId'],
          });

          store.dispatch('requiredReadingStatus/fetch', { customUrlFnArgs: [spaceId, pageId] });
          store.dispatch('nextRequiredReadingItem/fetch', { customUrlFnArgs: [spaceId, pageId] });
        }

        if (Vue.$ga) {
          Vue.$ga.event({
            eventCategory: 'required-reading',
            eventAction: `assigned-${requiredReadingAssignmentType(all, companies, individuals)}`,
            eventLabel: 'assigned',
            eventValue: 1,
          });
        }

        return response;
      }).catch((error) => {
        logError(error);
        commit('updateSavingState', false);
        return Promise.reject(error);
      });
    },
  },
  onFetchSuccess() {
    store.dispatch('requiredReadingAssignment/updatePendingAudienceState');
  },
});
