/* eslint-disable no-underscore-dangle */
import { mapActions, mapGetters, mapState } from 'vuex';
import popperMixin from '@/utils/mixins/popperMixin';
import { escapeKey } from '@/utils/constants/keyboardKeys';
import { isEventKeyEqualTo } from '@/utils/helpers/keyboardEvents';

// @vue/component
export default {
  mixins: [popperMixin],
  props: {
    otInlineCommentsInstance: {
      type: Object,
      required: true,
    },
    cancelFn: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      popperConfig: {
        placement: 'bottom',
      },
    };
  },
  computed: {
    ...mapState('inlineComments', ['newInlineCommentAvailableInReadMode']),

    ...mapGetters('ui/spacePage', ['shouldShowNewPageVersionBanner']),

    ...mapGetters('navigation', ['isEditingSpacePage']),
  },
  methods: {
    ...mapActions('alerts', ['showAlert']),

    ...mapActions('ui/inlineComments', ['createInlineComment']),

    async addComment() {
      if (!this.isEditingSpacePage && this.shouldShowNewPageVersionBanner) {
        this.showAlert({ type: 'info', message: 'Please update page to latest version' });
        return;
      }

      if (!this.isEditingSpacePage && this.newInlineCommentAvailableInReadMode) {
        this.showAlert({ type: 'info', message: 'Please load new inline comment or reload the page' });
        return;
      }

      this.createInlineComment(this.otInlineCommentsInstance);

      this.cancelFn();
    },
    onKeyUp() {
      if (isEventKeyEqualTo(event, escapeKey)) {
        this.cancelFn();
      }
    },
  },
  mounted() {
    document.addEventListener('keyup', this.onKeyUp);
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.onKeyUp);
  },
};
