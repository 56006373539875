import { difference } from 'lodash-es';

export default (arrA, arrB) => {
  if (arrA.length !== arrB.length) {
    return true;
  }

  const somethingAdded = difference(arrA, arrB);

  if (somethingAdded && somethingAdded.length) {
    return true;
  }

  const somethingRemoved = difference(arrB, arrA);

  if (somethingRemoved && somethingRemoved.length) {
    return true;
  }

  return false;
};
