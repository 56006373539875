export default {
  namespaced: true,
  state: {
    panel: '',
    props: {},
    title: '',
    isAmended: false,
  },
  getters: {
    hasActivePanel(state) {
      return !!state.panel;
    },

    isAmended(state) {
      return !!state.isAmended;
    },
  },
  mutations: {
    setPanel(state, { panel, props = {}, title = '' }) {
      state.panel = panel;
      state.props = props;
      state.title = title;
    },

    setAmendedState(state, amended) {
      state.isAmended = amended;
    },
  },
  actions: {
    openPanel({ commit }, { panel, props, title }) {
      commit('setPanel', { panel, props, title });
    },

    closePanel({ commit, state }) {
      if (state.isAmended) {
        /* eslint-disable-next-line no-alert */
        if (!window.confirm('You have unsaved changes. Do you really want to leave?')) {
          return;
        }
      }

      commit('setPanel', { panel: '', props: {}, title: '' });
    },

    setAmendedState({ commit }, isAmended) {
      commit('setAmendedState', isAmended);
    },
  },
};
