const defaultSpinnerHeightWidth = 60;

// @vue/component
export default {
  props: {
    size: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  mounted() {
    this.$nextTick(() => {
      const height = (() => {
        if (this.size) {
          return `${this.size}px`;
        }

        if (this.$parent && this.$parent.$el.clientHeight <= defaultSpinnerHeightWidth) {
          return `${this.$parent.$el.clientHeight / 2}px`;
        }

        return window.getComputedStyle(this.$el).getPropertyValue('height');
      })();

      if (!height) {
        return;
      }

      this.$el.style.width = height;
      this.$el.style.height = height;

      const circles = this.$el.getElementsByClassName('circle');

      for (let i = 0; i < circles.length; i += 1) {
        circles[i].style.borderWidth = `calc(${height} / 10)`;
      }
    });
  },
};
