import { faAddressBook } from '@fortawesome/pro-light-svg-icons/faAddressBook';
import { faAddressCard } from '@fortawesome/pro-light-svg-icons/faAddressCard';
import { faAlarmClock } from '@fortawesome/pro-light-svg-icons/faAlarmClock';
import { faArchive } from '@fortawesome/pro-light-svg-icons/faArchive';
import { faBell } from '@fortawesome/pro-light-svg-icons/faBell';
import { faBook } from '@fortawesome/pro-light-svg-icons/faBook';
import { faBoxFull } from '@fortawesome/pro-light-svg-icons/faBoxFull';
import { faBrowser } from '@fortawesome/pro-light-svg-icons/faBrowser';
import { faBug } from '@fortawesome/pro-light-svg-icons/faBug';
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding';
import { faBullhorn } from '@fortawesome/pro-light-svg-icons/faBullhorn';
import { faCalculator } from '@fortawesome/pro-light-svg-icons/faCalculator';
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons/faCalendarAlt';
import { faCameraRetro } from '@fortawesome/pro-light-svg-icons/faCameraRetro';
import { faChartBar } from '@fortawesome/pro-light-svg-icons/faChartBar';
import { faChartLine } from '@fortawesome/pro-light-svg-icons/faChartLine';
import { faChartPie } from '@fortawesome/pro-light-svg-icons/faChartPie';
import { faClipboardCheck } from '@fortawesome/pro-light-svg-icons/faClipboardCheck';
import { faClipboardList } from '@fortawesome/pro-light-svg-icons/faClipboardList';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { faClone } from '@fortawesome/pro-light-svg-icons/faClone';
import { faCloud } from '@fortawesome/pro-light-svg-icons/faCloud';
import { faCloudDownload } from '@fortawesome/pro-light-svg-icons/faCloudDownload';
import { faCloudUpload } from '@fortawesome/pro-light-svg-icons/faCloudUpload';
import { faCode } from '@fortawesome/pro-light-svg-icons/faCode';
import { faCodeBranch } from '@fortawesome/pro-light-svg-icons/faCodeBranch';
import { faCodeCommit } from '@fortawesome/pro-light-svg-icons/faCodeCommit';
import { faCoffee } from '@fortawesome/pro-light-svg-icons/faCoffee';
import { faCog } from '@fortawesome/pro-light-svg-icons/faCog';
import { faColumns } from '@fortawesome/pro-light-svg-icons/faColumns';
import { faCommentAltDots } from '@fortawesome/pro-light-svg-icons/faCommentAltDots';
import { faCopy } from '@fortawesome/pro-light-svg-icons/faCopy';
import { faCreditCard } from '@fortawesome/pro-light-svg-icons/faCreditCard';
import { faCut } from '@fortawesome/pro-light-svg-icons/faCut';
import { faDesktop } from '@fortawesome/pro-light-svg-icons/faDesktop';
import { faEnvelopeOpen } from '@fortawesome/pro-light-svg-icons/faEnvelopeOpen';
import { faFileAlt } from '@fortawesome/pro-light-svg-icons/faFileAlt';
import { faFileCheck } from '@fortawesome/pro-light-svg-icons/faFileCheck';
import { faFileEdit } from '@fortawesome/pro-light-svg-icons/faFileEdit';
import { faFileExclamation } from '@fortawesome/pro-light-svg-icons/faFileExclamation';
import { faFilePlus } from '@fortawesome/pro-light-svg-icons/faFilePlus';
import { faFolderOpen } from '@fortawesome/pro-light-svg-icons/faFolderOpen';
import { faFrown } from '@fortawesome/pro-light-svg-icons/faFrown';
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe';
import { faHandshake } from '@fortawesome/pro-light-svg-icons/faHandshake';
import { faHome } from '@fortawesome/pro-light-svg-icons/faHome';
import { faImages } from '@fortawesome/pro-light-svg-icons/faImages';
import { faInfoSquare } from '@fortawesome/pro-light-svg-icons/faInfoSquare';
import { faListAlt } from '@fortawesome/pro-light-svg-icons/faListAlt';
import { faLockAlt } from '@fortawesome/pro-light-svg-icons/faLockAlt';
import { faMapMarkerAlt } from '@fortawesome/pro-light-svg-icons/faMapMarkerAlt';
import { faMeh } from '@fortawesome/pro-light-svg-icons/faMeh';
import { faPaste } from '@fortawesome/pro-light-svg-icons/faPaste';
import { faPencilAlt } from '@fortawesome/pro-light-svg-icons/faPencilAlt';
import { faPhone } from '@fortawesome/pro-light-svg-icons/faPhone';
import { faPrint } from '@fortawesome/pro-light-svg-icons/faPrint';
import { faSmile } from '@fortawesome/pro-light-svg-icons/faSmile';

export default [
  faAddressBook,
  faAddressCard,
  faAlarmClock,
  faArchive,
  faBell,
  faBook,
  faBoxFull,
  faBrowser,
  faBug,
  faBuilding,
  faBullhorn,
  faCalculator,
  faCalendarAlt,
  faCameraRetro,
  faChartBar,
  faChartLine,
  faChartPie,
  faClipboardCheck,
  faClipboardList,
  faClock,
  faClone,
  faCloud,
  faCloudDownload,
  faCloudUpload,
  faCode,
  faCodeBranch,
  faCodeCommit,
  faCoffee,
  faCog,
  faColumns,
  faCommentAltDots,
  faCopy,
  faCreditCard,
  faCut,
  faDesktop,
  faEnvelopeOpen,
  faFileAlt,
  faFileCheck,
  faFileEdit,
  faFileExclamation,
  faFilePlus,
  faFolderOpen,
  faFrown,
  faGlobe,
  faHandshake,
  faHome,
  faImages,
  faInfoSquare,
  faListAlt,
  faLockAlt,
  faMapMarkerAlt,
  faMeh,
  faPaste,
  faPencilAlt,
  faPhone,
  faPrint,
  faSmile,
];
