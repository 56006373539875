import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';

import BasePopover from '@widgets/Popover/BasePopover';
import InsertTable from '@widgets/InsertTable';
import SelectColor from '@widgets/SelectColor';
import SpacesEditorToolbarButton from '@sections/SpacesEditor/SpacesEditorToolbar/SpacesEditorToolbarButton';
import SpacesEditorToolbarListButton from '@sections/SpacesEditor/SpacesEditorToolbar/SpacesEditorToolbarListButton';
import TinymceCommandMixin from '@sections/SpacesEditor/SpacesEditorToolbar/Mixins/TinymceCommandMixin';

import { isDesktop } from '@/utils/helpers/device';
import isAppCompatibleBrowser from '@/utils/helpers/isAppCompatibleBrowser';

// @vue/component
export default {

  mixins: [TinymceCommandMixin],
  inject: ['editorType', 'editor'],

  props: {
    buttonVariant: {
      type: String,
      default: '',
      required: false,
    },
    editorComponents: {
      type: Array,
      required: true,
    },
  },

  components: {
    BasePopover,
    InsertTable,
    SelectColor,
    SpacesEditorToolbarButton,
    SpacesEditorToolbarListButton,
  },

  data() {
    return {
      windowWidth: undefined,
    };
  },

  computed: {
    ...mapGetters('launchpad', {
      hasProjects: 'hasProjects',
    }),

    ...mapGetters('navigation', [
      'activePageId',
      'activeSpaceId',
    ]),

    ...mapGetters('session', {
      isInternalInstallation: 'isInternalInstallation',
      currentUserId: 'userId',

    }),

    ...mapState('apps', {
      sdkEditorComponents: 'editorComponents',
    }),

    ...mapGetters('apps', {
      hasCoreExternalPlugins: 'hasCoreEditorComponents',
    }),

    ...mapState('ui/spacesEditor', {
      toolbarDisabled: 'toolbarDisabled',
    }),

    showSdkWidgetBrowser() {
      return this.isInternalInstallation
        && this.sdkEditorComponents
        && this.sdkEditorComponents.length > 0
        && isAppCompatibleBrowser();
    },

    menuItems() {
      const items = [
        ...this.editorComponents,
      ];
      if (this.showSdkWidgetBrowser) {
        items.push({
          displayName: 'SDK Widgets',
          name: 'sdk-widgets-modal',
          icon: ['fas', 'plug'],
          showDivider: true,
        });
      }
      return items;
    },

  },
  methods: {
    ...mapActions('modals', ['openModal', 'closeModal']),

    ...mapActions('alerts', ['showAlert']),

    ...mapActions('ui/editorComponents', {
      insertEditorComponent: 'insertComponent',
    }),

    ...mapMutations('ui/spacesEditor', ['setSelectedWidgetUUID']),

    shouldShowShortcut(type) {
      return !!type.key;
    },

    shouldShowShortcutKey() {
      return isDesktop();
    },

    onItemClick(item) {
      if (item.name === 'sdk-widgets-modal') {
        this.launchWidgetBrowser();
        return;
      }

      this.insertEditorComponent({
        name: item.name,
        editor: this.editor.ref,
        vueComponentInstance: this,
      });
    },

    launchWidgetBrowser() {
      this.openModal({
        activeModal: 'editor-widget-browser',
        modalProps: {
          onSdkEditorComponentSelected: (component) => {
            this.insertEditorComponent({
              name: component.name,
              editor: this.editor.ref,
              vueComponentInstance: this,
            });
            this.closeModal();
          },
        },
      });
    },

  },
  mounted() {
    this.$root.$on('openPlaceholderModal', (event) => {
      const activePlaceholderText = event.target.getAttribute('data-placeholder-text');
      this.onInsertPlaceholder({ placeholderComponent: event.target, activePlaceholderText });
    });

    this.$once('hook:beforeDestroy', () => {
      this.$root.$off('openPlaceholderModal');
    });
  },
};
