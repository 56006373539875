import store from '@/store';

import { createStaticCrudModule } from '@/utils/vuex';
import localStorageDrivers from '@/utils/drivers/localstorage';
import localStorageKeys from '@/utils/constants/localStorage';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import { fetchAppModules, createEditorComponents, defineEditorComponentCustomElements } from '@/utils/editor/appsEditorComponents';
import isAppCompatibleBrowser from '@/utils/helpers/isAppCompatibleBrowser';
import { raiseSentryMessage } from '@/utils/helpers/sentryHelper';

export default createStaticCrudModule({
  only: ['FETCH'],
  property: 'apps',
  customUrlFn() {
    return buildApiUrl({
      resource: 'apps',
    });
  },
  state: {
    // IMPORTANT: editorComponents needs to be undefined until
    // we fetch apps to prevent loosing data
    editorComponents: undefined,
    devAppLocation: 'http://localhost:10001',
    devAppEnabled: false,
    areAppsReady: false,
    devApp: null,
  },
  mutations: {
    setEditorComponents(state, editorComponents) {
      state.editorComponents = editorComponents;
    },
    setDevAppEnabled(state, devAppEnabled) {
      state.devAppEnabled = devAppEnabled;
    },
    setDevAppLocation(state, devAppLocation) {
      state.devAppLocation = devAppLocation;
    },
    setAreAppsReady(state, isReady) {
      state.areAppsReady = isReady;
    },
    setDevApp(state, devApp) {
      state.devApp = devApp;
    },
  },
  getters: {
    allApps(state) {
      const apps = [
        ...state.entity,
      ];
      if (state.devApp) {
        apps.unshift(state.devApp);
      }
      return apps;
    },

    // eslint-disable-next-line no-unused-vars
    hasCoreEditorComponents(state, getters) {
      return state.editorComponents.filter((editorComponent) =>
        editorComponent.customElementName === 'tw-spaces-core-drawio'
        || editorComponent.customElementName === 'tw-spaces-core-check-list-item'
        || editorComponent.customElementName === 'tw-spaces-core-timeline'
        || editorComponent.customElementName === 'tw-spaces-core-accountability-chart'
        || editorComponent.customElementName === 'tw-spaces-core-swot',
      ).length === 4;
    },
  },
  actions: {
    async initApps({ dispatch, getters, commit }) {
      await dispatch('fetchPublishedApps');

      dispatch('addDevAppIfExists');

      const appModules = await fetchAppModules(getters.allApps);

      const editorComponents = createEditorComponents(appModules);

      commit('setEditorComponents', editorComponents);

      defineEditorComponentCustomElements(editorComponents);
    },

    // async procedure to load all editorComponents
    // from all sdk apps (including dev app if enabled)
    async loadApps({ dispatch, commit }) {
      const unwatchEditorComponents = store.watch((state) => state.apps.editorComponents, () => {
        commit('setAreAppsReady', true);
        unwatchEditorComponents();
      });

      if (!isAppCompatibleBrowser()) {
        commit('setEditorComponents', []);
        return;
      }

      commit('setAreAppsReady', false);

      try {
        dispatch('initApps');
      } catch (error) {
        // log to sentry so we know this is definitely happening
        raiseSentryMessage('External Apps failed to load...');
        // try again just the once
        dispatch('initApps');
      }
    },

    async fetchPublishedApps({ dispatch }) {
      const { data: { apps } } = await dispatch(
        'fetch', {
          config: {
            params: {
              page: 1,
            },
          },
        },
      );
      return apps.map((app) => ({
        ...app,
        isDev: false,
      }));
    },
    addDevAppIfExists({ state, commit, dispatch }) {
      dispatch('fetchSDKSettings');
      if (state.devAppEnabled) {
        commit('setDevApp', {
          baseUrl: state.devAppLocation,
          isDev: true,
        });
      }
    },
    // fetch settings from local storage
    fetchSDKSettings({ commit }) {
      const isInLocalStorage = !!localStorageDrivers.get(localStorageKeys.DEV_APP_ENABLED);
      const isTrue = localStorageDrivers.get(localStorageKeys.DEV_APP_ENABLED) === 'true';
      commit('setDevAppEnabled', isInLocalStorage && isTrue);
      // keep default plugin server url if not set in local storage
      const url = localStorageDrivers.get(localStorageKeys.DEV_APP_LOCATION);
      if (url) {
        commit('setDevAppLocation', url);
      }
    },
    updateSDKSettings(_, { devAppEnabled, devAppLocation }) {
      // update local storage
      localStorageDrivers.save(localStorageKeys.DEV_APP_ENABLED, devAppEnabled);
      localStorageDrivers.save(localStorageKeys.DEV_APP_LOCATION, devAppLocation);
      location.reload();
    },
  },
});
