const getEditorSelectionFormats = (editor = window.tinymce.activeEditor) => {
  let formats = [];

  if (!editor.selection) {
    return formats;
  }

  formats = editor.formatter.matchAll([
    'bold',
    'alignleft',
    'aligncenter',
    'alignright',
    'alignjustify',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'forecolor',
    'hilitecolor',
    'fontname',
    'fontsize',
    'blockquote',
    'link',
    'p',
    'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
    'div',
    'address',
    'pre',
    'div',
    'code',
    'dt', 'dd',
    'samp',
    'subscript',
    'superscript',
  ]);

  const selectionNode = editor.selection.getNode();
  const editorRootEl = editor.getBody();

  // work out if there is ul, ol or table in selection
  if (editor.dom.getParent(selectionNode, 'LI', editorRootEl)) {
    if (editor.dom.getParent(selectionNode.parentNode, 'UL', editorRootEl)) {
      formats.push('ul');
    }
    if (editor.dom.getParent(selectionNode.parentNode, 'OL', editorRootEl)) {
      formats.push('ol');
    }
  }

  if (editor.dom.getParent(selectionNode, 'TABLE', editorRootEl)) {
    formats.push('table');

    const tableElement = editor.dom.getParent(selectionNode, 'table');
    const thead = tableElement.querySelector('thead');
    if (thead) {
      formats.push('tableWithThead');
    }
  }

  if (editor.dom.getParent(selectionNode, 'THEAD', editorRootEl)) {
    formats.push('thead');
  }

  return formats;
};

const getSelectionForeColor = (editor = window.tinymce.activeEditor) => {
  if (!editor || !editor.selection) {
    return null;
  }

  const selectionNode = editor.selection.getNode();
  return editor.dom.getStyle(selectionNode, 'color', true);
};

const getSelectionHighlightColor = (editor = window.tinymce.activeEditor) => {
  if (!editor || !editor.selection) {
    return null;
  }

  const selectionNode = editor.selection.getNode();
  return editor.dom.getStyle(selectionNode, 'background-color', true);
};

/* eslint-disable import/prefer-default-export */
export {
  getEditorSelectionFormats,
  getSelectionForeColor,
  getSelectionHighlightColor,
};
