import IllustrationError from './assets/illustrations/illustrationError.svg';

const components = {
  IllustrationError,
};

const props = {
  illustration: {
    type: [Object, String],
    default() {
      return IllustrationError;
    },
  },
  title: String,
  description: String,
};

const computed = {
  errorTitle() {
    return this.title || this.defaultTitle;
  },
  errorDescription() {
    return this.description || this.defaultDescription;
  },
};

/* eslint-disable-next-line func-names */
const data = function () {
  return {
    defaultTitle: 'An error occurred',
    defaultDescription: 'Please try again later',
  };
};

const errorMessageComponent = {
  name: 'w-error-message',
  components,
  props,
  data,
  computed,
};

// @vue/component
export default errorMessageComponent;
