import RESOURCES from '@/utils/constants/resources';

export default [
  RESOURCES.USER_PLURAL,
  RESOURCES.SPACE_PLURAL,
  RESOURCES.PAGE_PLURAL,
  RESOURCES.COMPANY_PLURAL,
  RESOURCES.INSTALLATION_PLURAL,
  RESOURCES.WEBHOOK_EVENT_PLURAL,
  RESOURCES.WEBHOOK_REQUEST_PLURAL,
];
