// the order auto complete items display when user types '/' without a query
// each string maps to and editorComponent name

export default [
  'ul',
  'ol',
  'link',
  'table',
  'tw-placeholder',
  'tw-spaces-image',
  'tw-spaces-core-drawio',
  'hr',
  'media',
  'tw-spaces-info-panel',
  'tw-spaces-status',
  'code-sample',
  'tw-spaces-core-accountability-chart',
  'tw-spaces-core-check-list-item',
  'tw-spaces-core-timeline',
  'tw-spaces-core-swot',
  'table-of-contents',
  'space-pages-list',
  'projects-tasks',
  'tw-spaces-attachments',
  'tw-spaces-image-gallery',
  'h1',
  'h2',
  'h3',
  'h4',
  'p',
  'JustifyLeft',
  'JustifyRight',
  'JustifyCenter',
  'indent',
  'outdent',
];
