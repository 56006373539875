import { mapGetters } from 'vuex';
import {
  buildRoom, socketEventNames, socketRoomNames, emitSocketEvent,
} from '@/utils/helpers/socket';
import { logError } from '@/utils/helpers/logger.utility';

// @vue/component
export default {
  data() {
    return {
      pageRoomKey: 'pageRoom',
      pageEditRoomKey: 'pageEditRoom',
      pageRoom: null,
      pageEditRoom: null,
    };
  },
  computed: {
    ...mapGetters('navigation', ['activePageId']),

    isPageRoomOutdated() {
      return this.isRoomOutdated(this.pageRoomKey);
    },

    isPageEditRoomOutdated() {
      return this.isRoomOutdated(this.pageEditRoomKey);
    },
  },
  methods: {
    isRoomOutdated(roomName) {
      return (!this[roomName] || this[roomName].id !== this.activePageId);
    },

    leaveRoom(roomName) {
      if (roomName && this[roomName] && this[roomName].id) {
        emitSocketEvent(socketEventNames.leaveRoom, this[roomName]);
        this[roomName] = null;
      }
    },

    joinRoom(roomName) {
      if (!this.activePageId) {
        return;
      }

      if (!Object.keys(socketRoomNames).includes(roomName)) {
        logError(`Invalid parameter roomName provided: ${roomName}`);
        return;
      }

      this[roomName] = buildRoom(socketRoomNames[roomName], this.activePageId);
      emitSocketEvent(socketEventNames.joinRoom, this[roomName]);
    },

    handlePageReadSocketRoom() {
      if (this.isPageRoomOutdated) {
        this.joinRoom(this.pageRoomKey);
      }
    },

    handlePageEditSocketRoom() {
      if (this.isPageEditRoomOutdated && this.userCanEditPage) {
        this.joinRoom(this.pageEditRoomKey);
      }
    },
  },
  render() {
    return null;
  },
  watch: {
    activePageId: {
      immediate: true,
      handler(activePageId) {
        this.leaveRoom(this.pageRoomKey);
        this.leaveRoom(this.pageEditRoomKey);

        if (activePageId) {
          this.handlePageReadSocketRoom();
          this.handlePageEditSocketRoom();
        }
      },
    },
  },
};

