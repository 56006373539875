export const VIEW_MODE_KEYS = Object.freeze({
  LIST: 'list',
  GRID: 'grid',
});

export const VIEW_MODES = Object.freeze({
  [VIEW_MODE_KEYS.GRID]: {
    id: VIEW_MODE_KEYS.GRID,
    icon: 'th-large',
    iconTooltipText: 'Grid view',
  },
  [VIEW_MODE_KEYS.LIST]: {
    id: VIEW_MODE_KEYS.LIST,
    icon: 'list-ul',
    iconTooltipText: 'List view',
  },
});

export const DEFAULT_VIEW_MODE = VIEW_MODE_KEYS.GRID;

export default {
  VIEW_MODE_KEYS,
  VIEW_MODES,
  DEFAULT_VIEW_MODE,
};
