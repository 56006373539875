import Vue from 'vue';
import { extend, isPlainObject, isArray, isUndefined } from 'lodash-es';

/**
 * Create default mutations and merge them with mutations defined by a user.
 */
const createMutations = ({
  mutations,
  only,
  idAttribute,
  onFetchListStart,
  onFetchListSuccess,
  onFetchListError,
  onFetchSingleStart,
  onFetchSingleSuccess,
  onFetchSingleError,
  onCreateStart,
  onCreateSuccess,
  onCreateError,
  onUpdateStart,
  onUpdateSuccess,
  onUpdateError,
  onReplaceStart,
  onReplaceSuccess,
  onReplaceError,
  onDestroyStart,
  onDestroySuccess,
  onDestroyError,
  onDestroyAllStart,
  onDestroyAllSuccess,
  onDestroyAllError,
  socketEvents,
  onCreateViaSocketSuccess,
  onUpdateViaSocketSuccess,
  onDestroyViaSocketSuccess,
}) => {
  const crudMutations = {};

  if (only.includes('FETCH_LIST')) {
    Object.assign(crudMutations, {
      fetchListStart(state, payload) {
        state.isFetchingList = true;

        onFetchListStart(state, payload);
      },

      fetchListSuccess(state, payload) {
        const { params } = payload;
        const { response, includes, meta } = params;
        const { data } = response;

        if (!isArray(data)) {
          throw new Error('payload.params.response.data is not an Array');
        }

        if (isArray(data) && data.length) {
          if (isUndefined(data[0][idAttribute])) {
            throw new Error(`Response entities do not contain the specified 'idAttribute' of '${idAttribute}'`);
          }
        }

        data.forEach((m) => {
          Vue.set(state.entities, m[idAttribute].toString(), m);
        });

        state.currentList = data.map((m) => m[idAttribute].toString());

        state.currentMeta = isPlainObject(meta) ? meta : {};

        state.isFetchingList = false;
        state.fetchListError = null;

        onFetchListSuccess(state, response, includes, meta);
      },

      fetchListError(state, err) {
        state.currentList = [];
        state.currentMeta = {};
        state.fetchListError = err;
        state.isFetchingList = false;

        onFetchListError(state, err);
      },
    });
  }

  if (only.includes('FETCH_SINGLE')) {
    Object.assign(crudMutations, {
      fetchSingleStart(state, payload) {
        state.isFetchingSingle = true;
        onFetchSingleStart(state, payload);
      },

      fetchSingleSuccess(state, payload) {
        const { params } = payload;
        const { response, includes, meta } = params;
        const { data } = response;

        if (!isPlainObject(data)) {
          throw new Error('payload.params.response.data is not an Object');
        }

        if (isUndefined(data[idAttribute])) {
          throw new Error(`Response entity does not contain the specified 'idAttribute' of '${idAttribute}'`);
        }

        const id = data[idAttribute].toString();

        Vue.set(state.entities, id, data);
        state.isFetchingSingle = false;
        state.fetchSingleError = null;

        onFetchSingleSuccess(state, response, includes, meta);
      },

      fetchSingleError(state, err) {
        state.fetchSingleError = err;
        state.isFetchingSingle = false;
        onFetchSingleError(state, err);
      },
    });
  }

  if (only.includes('CREATE')) {
    const commitCreate = (state, data) => {
      if (!isArray(data)) {
        /* eslint-disable-next-line no-param-reassign */
        data = [data];
      }

      if (isArray(data) && data.length) {
        if (isUndefined(data[0][idAttribute])) {
          throw new Error(`Response entities do not contain the specified 'idAttribute' of '${idAttribute}'`);
        }
      }

      data.forEach((entity) => {
        const id = entity[idAttribute].toString();
        Vue.set(state.entities, id, entity);
      });
    };

    Object.assign(crudMutations, {
      createStart(state, payload) {
        state.isCreating = true;

        onCreateStart(state, payload);
      },

      createSuccess(state, payload) {
        const { params } = payload;
        const { response, includes, meta } = params;
        const { data } = response;

        commitCreate(state, data);

        state.isCreating = false;
        state.createError = null;

        onCreateSuccess(state, response, includes, meta);
      },

      createError(state, err) {
        state.createError = err;
        state.isCreating = false;

        onCreateError(state, err);
      },
    });
  }

  if (only.includes('UPDATE')) {
    const commitUpdate = (state, data) => {
      if (isUndefined(data[idAttribute])) {
        throw new Error(`Response entity does not contain the specified 'idAttribute' of '${idAttribute}'`);
      }

      const id = data[idAttribute].toString();

      Vue.set(state.entities, id, data);

      const listIndex = state.currentList.indexOf(id);

      if (listIndex >= 0) {
        Vue.set(state.currentList, listIndex, id);
      }
    };

    Object.assign(crudMutations, {
      updateStart(state, payload) {
        const { params, options = {} } = payload;
        const { id } = params;
        let { data } = params;

        state.isUpdating = true;

        if (options.isOptimistic) {
          // Note: we need to extend what's already in state, in case of partial updates
          const entity = state.entities[id];
          if (entity) {
            data = extend({}, state.entities[id] || {}, data);
            commitUpdate(state, data);
          }
        }

        onUpdateStart(state, payload);
      },

      updateSuccess(state, payload) {
        const { params, options = {} } = payload;
        const { response, includes, meta } = params;
        const { data } = response;

        if (!isPlainObject(options) || !options.isOptimistic) {
          commitUpdate(state, data);
        }

        state.isUpdating = false;
        state.updateError = null;

        onUpdateSuccess(state, response, includes, meta);
      },

      updateError(state, err) {
        state.updateError = err;
        state.isUpdating = false;

        onUpdateError(state, err);
      },
    });
  }

  if (only.includes('REPLACE')) {
    const commitReplace = (state, data) => {
      if (isUndefined(data[idAttribute])) {
        throw new Error(`Response entity does not contain the specified 'idAttribute' of '${idAttribute}'`);
      }

      const id = data[idAttribute].toString();

      Vue.set(state.entities, id, data);

      const listIndex = state.currentList.indexOf(id);

      if (listIndex >= 0) {
        Vue.set(state.currentList, listIndex, id);
      }
    };

    Object.assign(crudMutations, {
      replaceStart(state, payload) {
        const { params, options = {} } = payload;
        const { id } = params;
        let { data } = params;

        state.isReplacing = true;

        if (options.isOptimistic) {
          // Note: we need to extend what's already in state, in case of partial updates
          const entity = state.entities[id];
          if (entity) {
            data = extend({}, state.entities[id] || {}, data);
            commitReplace(state, data);
          }
        }

        onReplaceStart(state, payload);
      },

      replaceSuccess(state, payload) {
        const { params, options = {} } = payload;
        const { response, includes, meta } = params;
        const { data } = response;

        if (!isPlainObject(options) || !options.isOptimistic) {
          commitReplace(state, data);
        }

        state.isReplacing = false;
        state.replaceError = null;

        onReplaceSuccess(state, response, includes, meta);
      },

      replaceError(state, err) {
        state.replaceError = err;
        state.isReplacing = false;

        onReplaceError(state, err);
      },
    });
  }

  if (only.includes('DESTROY')) {
    const commitDestroy = (state, id) => {
      const listIndex = state.currentList.indexOf(id.toString());

      if (listIndex >= 0) {
        Vue.delete(state.currentList, listIndex);
      }

      Vue.delete(state.entities, id.toString());
    };

    Object.assign(crudMutations, {
      destroyStart(state, payload) {
        const { params, options = {} } = payload;
        const { id } = params;

        state.isDestroying = true;

        if (options.isOptimistic) {
          commitDestroy(state, id);
        }

        onDestroyStart(state, payload);
      },

      destroySuccess(state, payload) {
        const { params, options = {} } = payload;
        const { id } = params;

        if (!isPlainObject(options) || !options.isOptimistic) {
          commitDestroy(state, id);
        }

        state.isDestroying = false;
        state.destroyError = null;

        onDestroySuccess(state, id);
      },

      destroyError(state, err) {
        state.destroyError = err;
        state.isDestroying = false;

        onDestroyError(state, err);
      },
    });
  }

  if (socketEvents.includes('CREATE')) {
    Object.assign(crudMutations, {
      createViaSocket(state, payload) {
        const { params } = payload;
        const { response } = params;
        let { data } = response;

        if (!isArray(data)) {
          /* eslint-disable-next-line no-param-reassign */
          data = [data];
        }

        if (isArray(data) && data.length) {
          if (isUndefined(data[0][idAttribute])) {
            throw new Error(`Response entities do not contain the specified 'idAttribute' of '${idAttribute}'`);
          }
        }

        data.forEach((entity) => {
          const id = entity[idAttribute].toString();
          Vue.set(state.entities, id, entity);
        });

        onCreateViaSocketSuccess(state, response.data);
      },
    });
  }

  if (socketEvents.includes('UPDATE')) {
    Object.assign(crudMutations, {
      updateViaSocket(state, payload) {
        const { params } = payload;
        const { response } = params;
        let { data } = response;

        if (isUndefined(data[idAttribute])) {
          throw new Error(`Response entity does not contain the specified 'idAttribute' of '${idAttribute}'`);
        }

        const id = data[idAttribute].toString();
        const entity = state.entities[id];

        // Note: we need to extend what's already in state, to make sure we keep the data
        // if we set something custom like replies property in inlineComments
        if (entity) {
          data = extend({}, state.entities[id] || {}, data);
        }

        Vue.set(state.entities, id, data);

        onUpdateViaSocketSuccess(state, data);
      },
    });
  }

  if (socketEvents.includes('DESTROY')) {
    Object.assign(crudMutations, {
      destroyViaSocket(state, payload) {
        const { params } = payload;
        const { response } = params;
        const { id } = response;

        const entity = state.entities[id];

        if (entity) {
          Vue.delete(state.entities, id.toString());
        }

        onDestroyViaSocketSuccess(state, response);
      },
    });
  }

  if (only.includes('DESTROY_ALL')) {
    const commitDestroyAll = (state) => {
      state.entities = {};
    };

    Object.assign(crudMutations, {
      destroyAllStart(state, payload) {
        const { options = {} } = payload;

        state.isDestroyingAll = true;

        if (options.isOptimistic) {
          commitDestroyAll(state);
        }

        onDestroyAllStart(state, payload);
      },

      destroyAllSuccess(state, { options = {} } = {}) {
        if (!isPlainObject(options) || !options.isOptimistic) {
          commitDestroyAll(state);
        }

        state.isDestroyingAll = false;
        state.destroyAllError = null;

        onDestroyAllSuccess(state);
      },

      destroyAllError(state, err) {
        state.destroyAllError = err;
        state.isDestroyingAll = false;

        onDestroyAllError(state, err);
      },
    });
  }

  return Object.assign(crudMutations, mutations);
};

export default createMutations;
