import { createCrudModule } from '@/utils/vuex';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import { PERMISSION_LEVELS } from '@/utils/constants/pagePermissions';
import { REQUESTER_TYPES } from '@/utils/constants/spacePermissions';
import { PAGE_PRIVACY_OPTIONS } from '@/utils/constants/spacePage';

import store from '..';

export default createCrudModule({
  only: ['FETCH_LIST', 'CREATE', 'DESTROY', 'DESTROY_ALL'],
  resource: 'permission',
  customUrlFn(id, activeSpaceId, activePageId) {
    if (!activeSpaceId || !activePageId) {
      throw new Error('Unable to construct URL. Missing required Space ID or Page ID parameter');
    }

    return buildApiUrl({
      resource: id
        ? `spaces/${activeSpaceId}/pages/${activePageId}/permissions/${id}`
        : `spaces/${activeSpaceId}/pages/${activePageId}/permissions`,
    });
  },
  getters: {
    list(state) {
      return Object.values(state.entities)
        .filter((permission) => (
          permission.requester === null
          && permission.role === PERMISSION_LEVELS.EDIT
        )
        || (
          permission.requester
          && (
            permission.requester.type === REQUESTER_TYPES.USER
            || permission.requester.type === REQUESTER_TYPES.TEAM
            || permission.requester.type === REQUESTER_TYPES.COMPANY
          )
          && permission.role === null
        ));
    },

    privacySetting(state, getters) {
      if (getters.isPrivateForOnlyMe) {
        return PAGE_PRIVACY_OPTIONS.ME;
      }

      if (getters.isPrivateForSpecificMembers) {
        return PAGE_PRIVACY_OPTIONS.SPECIFIC_MEMBERS;
      }

      if (getters.isPrivateForEditors) {
        return PAGE_PRIVACY_OPTIONS.EDITORS;
      }

      return PAGE_PRIVACY_OPTIONS.ALL_MEMBERS;
    },

    isPrivate(state, getters) {
      return getters.isPrivateForOnlyMe
        || getters.isPrivateForSpecificMembers
        || getters.isPrivateForEditors;
    },

    // Note: Technically the API allows a mixture of "editors" and "specific members", but we only
    // support one option or another, so we need to stop at the first one we find

    isPrivateForOnlyMe(state, getters) {
      return !!(
        Array.isArray(getters.list)
          && getters.list.length === 1
          && getters.list[0].requester
          && getters.list[0].requester.type === REQUESTER_TYPES.USER
          && Number(getters.list[0].requester.id) === Number(store.getters['session/userId'])
          && !getters.list[0].role
      );
    },

    isPrivateForSpecificMembers(state, getters) {
      return !!(
        Array.isArray(getters.list)
        && getters.list.length > 0
        && !getters.isPrivateForEditors
        && !getters.isPrivateForOnlyMe
      );
    },

    isPrivateForEditors(state, getters) {
      return !!(
        Array.isArray(getters.list)
        && getters.list.length > 0
        && !!getters.list
          .find((permission) => permission.requester === null
            && permission.role === PERMISSION_LEVELS.EDIT)
      );
    },

    usersWithPagePermissions(state, getters, rootState, rootGetters) {
      return getters.list
        .map((permission) => rootGetters['users/byId'](permission?.requester?.id))
        // Remove any null array elements
        .filter((user) => user);
    },
  },
  actions: {

  },
  onFetchListStart(state) {
    // This store module should only contain the entities for the current page, so we need to
    // clear it before fetching
    state.entities = {};
  },
});
