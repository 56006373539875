import store from '@/store';
import isSharedSpacePageURL from '@/utils/helpers/isSharedSpacePageURL';
import { ROUTE_NAMES, fetchDataForRoute } from '@/router/routes';

function isAppStarting(from) {
  return (!from.name || from.path === '/');
}

function isSharedSpacePage() {
  return isSharedSpacePageURL(window.location.href);
}

export default function (router) {
  router.afterEach((to, from) => {
    if (store.getters['ui/isPreloading']) {
      store.commit('ui/setPreloadState', false, { root: true });
    }

    store.dispatch('ui/clearLoadError', 'global');
    store.dispatch('ui/clearLoadError', 'page');
    store.dispatch('ui/clearLoadError', 'section');

    if (!isSharedSpacePage()) {
      const appStarting = isAppStarting(from);
      fetchDataForRoute(to, from, appStarting);
    }
  });

  router.beforeEach((to, from, next) => {
    if (isAppStarting(from)) {
      store.commit('ui/setPreloadState', true, { root: true });
    }

    if (store.state.panel.isAmended) {
      /* eslint-disable-next-line no-alert */
      if (!window.confirm('You have unsaved changes. Do you really want to leave?')) {
        return next(false);
      }

      store.dispatch('panel/setAmendedState', false);
    }

    store.dispatch('panel/closePanel');

    const isOnboardingRoute = to.name.startsWith('onboarding');
    const isOnboarded = store.getters['session/isOnboarded'];
    const isActive = store.getters['session/isActive'];

    // Note: we now do not show spaces onboarding for the add-on
    if (
      isActive
      && !isOnboarded
      && !isOnboardingRoute
      && !isSharedSpacePage()
      && !store.getters['installation/isOnAddOnPlan']
    ) {
      next({ name: ROUTE_NAMES.ONBOARDING });
    } else if (isOnboarded && isOnboardingRoute) {
      next({ name: ROUTE_NAMES.HOME });
    }

    if (!store.getters['ui/spacePage/canPublishPage']) {
      store.commit('ui/spacePage/setCanPublishPage', true, { root: true });
    }

    // ensure isPluginFullScreen === false on route change
    if (store.state.navigation.isPluginFullScreen) {
      store.commit('navigation/isPluginFullScreen', false);
    }

    if (to.matched.some((record) => record.meta.adminRoute) && !store.state.session.entity.isAdmin) {
      next('/');
    }

    // Prompt user with notification if any unsaved progress in the form
    // might be lost on the in app route change and make sure to close modal as well.

    // sliding drawer is active, close it
    if (store.state.slidingDrawers.activeSlidingDrawer) {
      store.dispatch('slidingDrawers/slideDrawerOut', { name: store.state.slidingDrawers.activeSlidingDrawer });
    }

    // no changes made in the form check if modal or persistant alerts are open and close them.
    if (!store.state.ui.formState.isAmended) {
      if (store.getters['modals/hasOpenModal']) {
        store.dispatch('modals/closeAllModals');
      }

      if (store.getters['alerts/hasActivePersistentAlerts']) {
        store.dispatch('alerts/closeAllAlerts');
      }

      return next();
    }

    // form has been amended, prompt confirmation for leaving the page
    if (store.state.ui.formState.isAmended) {
    /* eslint-disable-next-line no-alert */
      const answer = window.confirm('You have unsaved changes. Do you really want to leave?');
      if (!answer) {
        return next(false);
      }

      if (store.getters['modals/hasOpenModal']) {
        store.dispatch('modals/closeAllModals');
      }

      if (store.getters['alerts/hasActivePersistentAlerts']) {
        store.dispatch('alerts/closeAllAlerts');
      }

      store.dispatch('ui/formState/amend', false);
    }

    return next();
  });
}
