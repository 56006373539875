export default Object.freeze({
  checkoutLocalhostPage: 'http://checkout.teamwork.localhost/',
  checkoutStagingPage: 'https://checkout.staging.teamwork.com/',
  checkoutUSPage: 'https://checkout.teamwork.com/',
  checkoutEUPage: 'https://checkout.eu.teamwork.com/',
  checkoutUSBetaPage: 'https://checkout-beta.teamwork.com/',
  checkoutEUBetaPage: 'https://checkout-beta.eu.teamwork.com/',
  helpdocsInfo: 'https://support.teamwork.com/spaces',
  webhooksInfo: 'https://developer.teamwork.com/spaces/intro/webhooks',
  teamworkSpaces: 'https://www.teamwork.com/spaces',
});
