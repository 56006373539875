/**
 * a list of colours to palate the text colour select components
 * on the editor.
 * To get us going I just copied the colors from evernotes color picker.
 */
export default [
  'rgb(156, 159, 165)', // Light grey, new variant for text
  'rgb(131, 89, 241)', // Purple 4.5:1 (60 variant)
  'rgb(9, 117, 227)', // Blue 4.5:1 (60 variant)
  'rgb(58, 132, 100)', // Green 4.5:1 (80 variant)
  'rgb(217, 63, 1)', // Orange 4.5:1 (60 varient)
  'rgb(233, 17, 76)', // Red 4.5:1 (60 variant)
  'rgb(219, 30, 154)', // Pink 4.51:1 (80 variant)
];
