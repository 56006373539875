import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import { InlineComments } from '@teamwork/ot-client';

import { inlineCommentsCollapseWidth, largeDesktop } from '@/utils/constants/mediaBreakpoints';
import { OT_INLINE_COMMENT_ELEMENTS } from '@/utils/constants/otClientConfig';
import popperReferenceHelper from '@/utils/helpers/popperReferenceHelper';
import addStyleSheetRules from '@/utils/helpers/addStyleSheetRules';
import activeInlineCommentsMixin from '@/utils/mixins/activeInlineCommentsMixin';

import InlineCommentSelectionPopover from '@widgets/InlineCommentSelectionPopover';

const defaultToCollapsedWidth = inlineCommentsCollapseWidth;

const INLINE_COMMENT_COLOR = '255, 232, 177, 1';

// @vue/component
export default {
  mixins: [activeInlineCommentsMixin],
  inject: ['commentsStyleSheet', 'otInlineCommentsInstances'],
  components: {
    InlineCommentSelectionPopover,
  },
  data() {
    return {
      isRebindingListeners: false,
      isRefreshingStyleRules: false,
      isMixedFromPageContent: false,
      componentFirstLoad: true,
      metaKeydown: false,
      rangeRef: null,
    };
  },
  computed: {
    ...mapState('ui/spacesEditor', {
      isLoadingInitialContent: 'isLoadingInitialContent',
      usersPresentList: 'usersPresentList',
    }),

    ...mapState('ui/inlineComments', {
      shouldShowCommentsGutter: 'shouldShowCommentsGutter',
      highlightedCommentId: 'highlightedCommentId',
      selectedCommentIds: 'selectedCommentIds',
      newCommentIdentifier: 'newCommentIdentifier',
    }),

    ...mapGetters('ui/sidebar', {
      isSidebarCollapsed: 'isCollapsed',
    }),

    ...mapGetters('ui/spacePage', ['canPublishPage', 'allowInlineCommentsInReadMode']),

    ...mapGetters('navigation', ['isEditingSpacePage', 'isActivePageInTrash']),

    ...mapGetters('inlineComments', ['shouldRemoveOrphanedInlineComments']),

    shouldFlipSelection() {
      return (this.$mq === 'x-small' || this.$mq === 'small' || this.$mq === 'medium' || this.$mq === 'large');
    },

    popperPlacement() {
      return this.shouldFlipSelection ? 'bottom' : 'top';
    },

    contentSelectionPopperConfig() {
      return {
        placement: this.popperPlacement,
        positionFixed: true,
        modifiers: [
          {
            name: 'flip',
            options: {
              altBoundary: true,
              padding: 40,
            },
          },
          {
            name: 'preventOverflow',
            options: {
              altBoundary: true,
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
          {
            name: 'arrow',
            options: {
              element: '[data-popper-arrow]',
            },
          },
        ],
      };
    },

    areCollaboratorsPresent() {
      return this.usersPresentList.length > 0;
    },

    canRemoveOrphanedInlineComments() {
      return this.localOtInlineCommentInstance
        && this.isEditingSpacePage
        && !this.isLoadingInitialContent
        && !this.areCollaboratorsPresent;
    },

    localOtInlineCommentInstance() {
      if (this.isEditingSpacePage && !this.otInlineCommentsInstances.edit) {
        return null;
      }

      return this.isEditingSpacePage ? this.otInlineCommentsInstances.edit : this.otInlineCommentsInstances.read;
    },
  },
  methods: {
    ...mapMutations('ui/inlineComments', [
      'setHighlightedCommentId',
      'setSelectedCommentIds',
      'clearSelectedCommentIds',
      'clearHighlightedCommentId',
      'clearActiveCommentId',
      'setNewCommentIdentifier',
      'setNewCommentTextSelection',
      'setCanAddInlineComment',
    ]),

    ...mapMutations('inlineComments', ['setShouldRemoveOrphanedInlineComments']),

    ...mapActions('alerts', ['showAlert']),

    ...mapActions('ui/inlineComments', [
      'showCommentsGutter',
      'hideCommentsGutter',
      'setTabScrollPosition',
    ]),

    ...mapActions('inlineComments', ['resolveComment']),

    ...mapActions('ui/sidebar', {
      setSidebarCollapsedState: 'setCollapsedState',
    }),

    addInlineCommentRule(styleSheet, identifier, overrides = []) {
      if (!this.isMixedFromPageContent && !this.isEditingSpacePage) {
        return;
      }

      const isClickable = this.inlineCommentIdentifiers.includes(identifier);
      const selector = this.isMixedFromPageContent ? '.s-page-content' : '.s-spaces-editor';

      addStyleSheetRules([
        [
          `${selector} tw-span[data-tw-inline-comments*="${identifier}"]`,
          ['border-bottom', `3px solid rgba(${INLINE_COMMENT_COLOR})`, true],
          isClickable ? ['cursor', 'pointer', true] : undefined,
        ].concat(overrides),
      ], styleSheet.ownerNode);
    },

    showRangePopper() {
      const selection = this.retrieveCurrentSelection();

      if (
        selection
        && this.canPublishPage
        && !this.newCommentIdentifier
        && this.localOtInlineCommentInstance
        && this.localOtInlineCommentInstance.canAdd()
        && window.getSelection().toString().trim() !== ''
      ) {
        this.rangeRef = popperReferenceHelper(selection);
      } else {
        this.rangeRef = null;
      }
    },

    shouldShowInlineCommentPopover() {
      const canAddInlineComment = this.localOtInlineCommentInstance && this.localOtInlineCommentInstance.canAdd();

      this.setCanAddInlineComment(canAddInlineComment);

      this.$nextTick(() => {
        if (!this.retrieveCurrentSelection() || !canAddInlineComment) {
          this.rangeRef = null;
        } else {
          this.showRangePopper();
        }
      });
    },

    scrollToCommentAnchor() {
      if (!this.isMixedFromPageContent && !this.isEditingSpacePage) {
        return;
      }

      const anchor = location.hash.substr(1, location.hash.length - 1);

      if (anchor !== '') {
        this.$nextTick(() => {
          // only highlight if its an active comment
          const comment = this.activeInlineComments
            .find((c) => c.identifier.toLowerCase() === anchor.toLowerCase());

          if (comment) {
            this.setHighlightedCommentId(comment.identifier);
            this.setSelectedCommentIds([comment.identifier]);

            if (!this.shouldShowCommentsGutter) {
              this.showCommentsGutter();
            }

            setTimeout(() => {
              const commentMarkerNodes = document.querySelectorAll(`tw-span[data-tw-inline-comments*="${comment.identifier}"]`);

              if (commentMarkerNodes && commentMarkerNodes.length > 0) {
                const scrollParent = this.isEditingSpacePage ? this.$refs.spaceEditorBody : this.$el.offsetParent;
                scrollParent.scrollTo({ top: commentMarkerNodes[0].offsetTop, behavior: 'smooth' });
              }
            }, 200);
          } else if (this.isEditingSpacePage && !this.isMixedFromPageContent) {
            this.showAlert({ type: 'error', message: 'This inline comment has already been deleted or resolved.' });
          } else {
            if (this.isActivePageInTrash) {
              return;
            }

            setTimeout(() => {
              const isPageCommentAnchor = document.querySelectorAll(`.page-comments__list > [id="${anchor}"]`);
              const isPageContentAnchor = document.querySelectorAll(`.s-page-content > [id="${anchor}"]`);

              if ((isPageCommentAnchor && isPageCommentAnchor.length > 0)
                || (isPageContentAnchor && isPageContentAnchor.length > 0)
              ) {
                return;
              }

              this.showAlert({ type: 'error', message: 'This inline comment has already been deleted or resolved.' });
            }, 2500);
          }
        });
      }
    },

    initCommentEventListeners() {
      this.addClickAndMouseoverListeners();
    },

    removeCommentStyleSheets() {
      const commentsNode = this.commentsStyleSheet.ownerNode;
      if (commentsNode instanceof HTMLElement) {
        commentsNode.parentNode.removeChild(commentsNode);
      }
    },

    removeOrphanedInlineComments() {
      if (!this.canRemoveOrphanedInlineComments && !this.shouldRemoveOrphanedInlineComments) {
        return;
      }

      if (this.shouldRemoveOrphanedInlineComments) {
        this.setShouldRemoveOrphanedInlineComments(false);
      }

      if (!this.isMixedFromPageContent && !this.isEditingSpacePage) {
        return;
      }

      const otComments = this.localOtInlineCommentInstance.getAll();

      otComments.forEach((otComment) => {
        const foundInActive = this.inlineCommentIdentifiers.find(
          (spacesComment) => spacesComment === otComment
          || this.newCommentIdentifier === otComment,
        );

        const foundInClosed = this.closedInlineComments.find(
          (closedComment) => closedComment.identifier === otComment);

        if (!foundInActive && !foundInClosed) {
          this.localOtInlineCommentInstance.remove(otComment);
        }
      });

      // Note: currently disabling the removal of comments that have no reference element in the
      // content until we can come up with a more rebust solution
      // this.activeInlineComments.forEach((inlineComment) => {
      //   const found = otComments.indexOf(inlineComment.identifier) > -1;

      //   if (!found) {
      //     this.resolveComment(inlineComment.id);
      //   }
      // });
    },

    onInlineCommentClick(event) {
      // Don't let event bubble up to document because clicking document will unset selected
      // comment

      // reverted due to bug: https://digitalcrew.teamwork.com/#/tasks/15527795
      // event.stopPropagation();
      if (event.target.tagName !== 'TW-SPAN' || !event.target.dataset.twInlineComments) {
        return;
      }

      if (this.localOtInlineCommentInstance.canAdd()) {
        return;
      }

      const inlineCommentIdentifierList = event.target.dataset.twInlineComments.split(' ');

      if (!inlineCommentIdentifierList.length) {
        return;
      }

      const identifiers = inlineCommentIdentifierList
        .filter((identifier) => this.inlineCommentIdentifiers.includes(identifier));

      if (!identifiers.length) {
        return;
      }

      // Remember scroll position of the active tab so we can return on "Back" clicked
      this.setTabScrollPosition();

      // If there are multiple comments on this selection, we will filter
      // the comments view
      this.setSelectedCommentIds(identifiers);

      if (identifiers.length === 1) {
        // Just show the comment thread
        this.setHighlightedCommentId(identifiers[0]);
      } else {
        this.clearHighlightedCommentId();
        this.clearActiveCommentId();
      }

      if (!this.shouldShowCommentsGutter) {
        this.showCommentsGutter();
      }

      if (window.innerWidth < largeDesktop && this.shouldShowCommentsGutter && !this.isSidebarCollapsed) {
        this.setSidebarCollapsedState(true);
      }
    },

    onInlineCommentHover(event) {
      this.refreshStyleRules();

      if (event.target.tagName !== 'TW-SPAN' || !event.target.dataset.twInlineComments) {
        return;
      }

      const inlineCommentIdentifierList = event.target?.dataset?.twInlineComments?.split(' ');

      if (!inlineCommentIdentifierList || !inlineCommentIdentifierList.length) {
        return;
      }

      const identifiers = inlineCommentIdentifierList
        .filter((identifier) => this.inlineCommentIdentifiers.includes(identifier));

      if (!identifiers.length) {
        return;
      }

      identifiers.forEach((identifier) => {
        this.addInlineCommentRule(
          this.commentsStyleSheet,
          identifier,
          [
            ['background-color', `rgba(${INLINE_COMMENT_COLOR})`, true],
          ],
        );
      });
    },

    removeStyleRules() {
      const rules = Array.from(this.commentsStyleSheet.cssRules);
      for (let i = 0, j = rules.length; i < j; i += 1) {
        // Note: we keep deleting first item as rules will go down with each iteration
        this.commentsStyleSheet.deleteRule(0);
      }
    },

    addStyleRules() {
      if (!this.isMixedFromPageContent && !this.isEditingSpacePage) {
        return;
      }

      this.inlineCommentIdentifiers.forEach((identifier) => {
        this.addInlineCommentRule(this.commentsStyleSheet, identifier);
      });

      if (this.highlightedCommentId) {
        this.addInlineCommentRule(
          this.commentsStyleSheet,
          this.highlightedCommentId,
          [
            ['background-color', `rgba(${INLINE_COMMENT_COLOR})`, true],
          ],
        );
      }

      if (Array.isArray(this.selectedCommentIds) && this.selectedCommentIds.length > 0) {
        this.selectedCommentIds.forEach((identifier) => {
          this.addInlineCommentRule(
            this.commentsStyleSheet,
            identifier,
            [
              ['background-color', `rgba(${INLINE_COMMENT_COLOR})`, true],
            ],
          );
        });
      }
    },

    refreshStyleRules() {
      if (this.isRefreshingStyleRules) {
        return;
      }

      this.isRefreshingStyleRules = true;
      this.removeStyleRules();

      this.$nextTick(() => {
        this.addStyleRules();
        this.isRefreshingStyleRules = false;
      });
    },

    rebindClickAndMouseoverListeners() {
      if (this.isRebindingListeners) {
        return;
      }

      this.isRebindingListeners = true;
      this.removeClickAndMouseoverListeners();

      this.$nextTick(() => {
        this.addClickAndMouseoverListeners();
        this.isRebindingListeners = false;
      });
    },

    checkIfCommentElementHasValidIdentifiers($inlineComment) {
      const attribute = $inlineComment.getAttribute('data-tw-inline-comments');
      const identifiers = attribute.split(' ');

      return this.inlineCommentIdentifiers.some((i) => identifiers.includes(i));
    },

    addClickAndMouseoverListeners() {
      if (!(this.$el instanceof HTMLElement)) {
        return;
      }

      this.$el.addEventListener('mouseover', this.onInlineCommentHover);
      this.$el.addEventListener('click', this.onInlineCommentClick);
    },

    removeClickAndMouseoverListeners() {
      if (!(this.$el instanceof HTMLElement)) {
        return;
      }

      this.$el.removeEventListener('mouseover', this.onInlineCommentHover);
      this.$el.removeEventListener('click', this.onInlineCommentClick);
    },

    repositionCommentPopover() {
      if (!this.isMixedFromPageContent && !this.isEditingSpacePage) {
        return;
      }

      const fn = () => {
        if (this.rangeRef && this.retrieveCurrentSelection()) {
          this.showRangePopper();
        }
      };

      window.requestAnimationFrame(fn);
    },

    recreateCommentPopover() {
      if (!this.isMixedFromPageContent && !this.isEditingSpacePage) {
        return;
      }

      if (this.rangeRef && this.retrieveCurrentSelection()) {
        this.rangeRef = null;
        setTimeout(() => {
          this.showRangePopper();
        }, 400);
      }
    },

    setup() {
      if (!this.isMixedFromPageContent && !this.isEditingSpacePage) {
        return;
      }

      if (!this.isEditingSpacePage && (!this.allowInlineCommentsInReadMode || this.isActivePageInTrash)) {
        return;
      }

      // Note: we should only open the gutter if we are at a large enough viewport
      if (
        this.isEditingSpacePage
        && this.inlineCommentIdentifiers.length > 0
        && this.componentFirstLoad
        && window.innerWidth > defaultToCollapsedWidth
      ) {
        this.showCommentsGutter();
        this.componentFirstLoad = false;
      }
      this.refreshStyleRules();

      this.rebindClickAndMouseoverListeners();
    },

    retrieveCurrentSelection() {
      if (!this.isMixedFromPageContent && !this.isEditingSpacePage) {
        return null;
      }

      if (this.isEditingSpacePage) {
        return this.editor?.ref?.selection;
      }

      const selection = window.getSelection();
      return selection && selection.type === 'Range' ? selection.getRangeAt(0) : null;
    },
  },
  created() {
    this.clearSelectedCommentIds();
    this.setNewCommentIdentifier('');
    this.setNewCommentTextSelection('');
  },
  mounted() {
    this.isMixedFromPageContent = this.$options.name && this.$options.name === 'PageContent';

    window.addEventListener('resize', this.repositionCommentPopover);

    if (this.isMixedFromPageContent) {
      if (this?.$el?.offsetParent instanceof HTMLElement) {
        this.$el.offsetParent.addEventListener('scroll', this.repositionCommentPopover);
      }

      this.otInlineCommentsInstances.read = new InlineComments(this.$el, {
        inlineCommentElements: OT_INLINE_COMMENT_ELEMENTS,
      });
    } else {
      this.$el.addEventListener('scroll', this.repositionCommentPopover);

      this.otInlineCommentsInstances.edit = this.editor?.ref?.plugins?.ot?.comments;
    }
  },
  beforeDestroy() {
    this.rangeRef = null;
    this.removeClickAndMouseoverListeners();
    window.removeEventListener('resize', this.repositionCommentPopover);

    if (!this.isMixedFromPageContent) {
      this.$el.removeEventListener('scroll', this.repositionCommentPopover);

      // we are moving away from the page so hide gutter
      // clear stylesheet of current page inline comment rules
      this.hideCommentsGutter();
      this.removeStyleRules();
    }

    if (this.isMixedFromPageContent && this?.$el?.offsetParent instanceof HTMLElement) {
      this.$el.offsetParent.removeEventListener('scroll', this.repositionCommentPopover);
    }
  },
  watch: {
    shouldShowCommentsGutter() {
      this.recreateCommentPopover();
    },
    isSidebarCollapsed() {
      if (this.isMixedFromPageContent) {
        this.recreateCommentPopover();
      }
    },
    inlineCommentIdentifiers: {
      immediate: true,
      handler() {
        this.setup();
      },
    },
    isEditingSpacePage() {
      this.rangeRef = null;
      this.refreshStyleRules();
    },
    'editor.ref': {
      handler() {
        this.otInlineCommentsInstances.edit = this.editor?.ref?.plugins?.ot?.comments;
      },
    },
    highlightedCommentId: {
      immediate: false,
      handler() {
        this.refreshStyleRules();
      },
    },
    selectedCommentIds: {
      immediate: false,
      handler() {
        this.refreshStyleRules();
      },
    },
    canRemoveOrphanedInlineComments: {
      immediate: false,
      handler(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            this.removeOrphanedInlineComments();
          });
        }
      },
    },
    shouldRemoveOrphanedInlineComments: {
      immediate: false,
      handler(shouldTrigger) {
        if (shouldTrigger) {
          this.$nextTick(() => {
            this.removeOrphanedInlineComments();
          });
        }
      },
    },
    allowInlineCommentsInReadMode: {
      immediate: true,
      handler() {
        if (!this.isEditingSpacePage && !this.allowInlineCommentsInReadMode) {
          this.removeClickAndMouseoverListeners();
        }
      },
    },
    isActivePageInTrash: {
      immediate: true,
      handler() {
        if (this.isActivePageInTrash && !this.isEditingSpacePage) {
          this.removeClickAndMouseoverListeners();
        }
      },
    },
  },
};
