import { RESOURCE_TYPES, SUPPORTED_REACTION_TYPES_BY_RESOURCE, REACTION_TYPES_BY_ID } from '@/utils/constants/reactions';

export function getSupportedResourceTypes() {
  return Object.keys(SUPPORTED_REACTION_TYPES_BY_RESOURCE);
}

export function isResourceTypeSupported(resourceType) {
  return getSupportedResourceTypes().includes(resourceType);
}

export function getSupportedReactionTypeIds(resourceType) {
  if (!Object.values(RESOURCE_TYPES).includes(resourceType)) {
    throw new Error(`'${resourceType}' is not a supported reaction resource type`);
  }

  return Object.entries(SUPPORTED_REACTION_TYPES_BY_RESOURCE[resourceType])
    .reduce((result, [reactionTypeId, isSupported]) => {
      if (isSupported) {
        result.push(Number(reactionTypeId));
      }

      return result;
    }, []);
}

export function isReactionTypeSupported(reactionTypeId) {
  return REACTION_TYPES_BY_ID[Number(reactionTypeId)];
}

export function isReactionTypeSupportedOnResource(resourceType, reactionTypeId) {
  return getSupportedReactionTypeIds(resourceType).includes(Number(reactionTypeId));
}

export default {
  SUPPORTED_REACTION_TYPES_BY_RESOURCE,
  getSupportedResourceTypes,
  isResourceTypeSupported,
  getSupportedReactionTypeIds,
  isReactionTypeSupported,
  isReactionTypeSupportedOnResource,
};
