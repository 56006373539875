export default {
  SUBSCRIPTION_EXPIRED: 101,
  GRACE_PERIOD: 102,
  TRIAL_EXPIRED: 103,
  OVERSUBSCRIBED: 104,
  SPACES_NOT_SETUP: 105,
  USER_NOT_ENABLED: 106,
  PAGE_LIMIT_REACHED: 107,
  USER_LIMIT_REACHED: 108,
  FEATURE_RESTRICTED: 109,
  INADEQUATE: 110,
  CUSTOM_PAGE_TEMPLATE_LIMIT_REACHED: 111,
  PUBLIC_PAGE_SHARING_LIMIT_REACHED: 112,
  SUBSCRIPTION_INVALID: 113,
};
