import { isPlainObject, isString, uniq } from 'lodash-es';

export default (config, forcedIncludes = ['users']) => {
  const tConfig = isPlainObject(config) ? config : {};
  tConfig.params = isPlainObject(tConfig.params) ? tConfig.params : {};

  let includesArr = Array.isArray(forcedIncludes) ? forcedIncludes : [];

  // add other include values defined on the request config argument
  if (tConfig.params.include) {
    if (!isString(tConfig.params.include)) {
      throw new Error('params.include must be comma separted string. eg. include: "comments,spaces"');
    }

    if (tConfig.params.include.split(' ').length > 1) {
      throw new Error('params.include must be comma separted string. eg. include: "comments,spaces"');
    }

    includesArr = includesArr.concat(tConfig.params.include.split(','));
  }

  tConfig.params.include = uniq(includesArr).join(',');

  return tConfig;
};
