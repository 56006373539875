import { isObject, isUndefined } from 'lodash-es';

import { createStaticCrudModule } from '@/utils/vuex';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';

import store from '..';

export default createStaticCrudModule({
  only: ['FETCH'],
  property: 'requiredReading',
  namespaced: true,
  state: {
    nextItemToRead: null,
  },
  prepareConfig(config) {
    if (!isObject(config)) {
      /* eslint-disable no-param-reassign */
      config = {};
    }

    if (!isObject(config.params)) {
      config.params = {};
    }

    config.params.include = ['pages', 'spaces', 'pages.spaces'].join(',');

    return config;
  },
  customUrlFn(spaceId, pageId) {
    if (!spaceId || !pageId) {
      // if spaceId and pageId are not passed through then use store to get current
      spaceId = store.getters['navigation/activeSpaceId'];
      pageId = store.getters['navigation/activePageId'];
    }

    if (!spaceId || !pageId) {
      throw new Error('Unable to construct URL. Missing required parameter(s)');
    }

    return buildApiUrl({
      resource: `requiredreading/spaces/${spaceId}/pages/${pageId}/next.json`,
      apiVersion: 'v1',
    });
  },
  getters: {
    nextItemToRead: (state) => state && state.nextItemToRead,
  },
  mutations: {
    setNextRequiredReadingItem(state,
      {
        spaceCode, pageId, isSpaceHomePage, pageSlug, pageTitle, spaceTitle,
      }) {
      if (
        isUndefined(spaceCode) || isUndefined(pageId) || isUndefined(pageSlug)
        || isUndefined(pageTitle) || isUndefined(spaceTitle)
      ) {
        throw new Error('Missing one or more required properties');
      }

      state.nextItemToRead = {
        spaceCode,
        pageId,
        isSpaceHomePage,
        pageSlug,
        pageTitle,
        spaceTitle,
      };
    },
  },
  actions: {
    setNextRequiredReadingItem({ commit },
      {
        spaceCode, pageId, isSpaceHomePage, pageSlug, pageTitle, spaceTitle,
      }) {
      if (
        isUndefined(spaceCode) || isUndefined(pageId) || isUndefined(pageSlug)
        || isUndefined(pageTitle) || isUndefined(spaceTitle)
      ) {
        throw new Error('Missing one or more required properties');
      }

      commit('setNextRequiredReadingItem', {
        spaceCode, pageId, isSpaceHomePage, pageSlug, pageTitle, spaceTitle,
      });
    },
  },
  onFetchSuccess(state, response) {
    if (response.status === 204) {
      // Nothing to continue on to, Required Reading has been completed
      state.nextItemToRead = null;
      return;
    }

    if (state && state.entity && state.entity.next) {
      const data = state.entity.next;

      if (!data.space || !data.space.id || !data.id) {
        state.nextItemToRead = null;
        return;
      }

      store.dispatch('nextRequiredReadingItem/setNextRequiredReadingItem', {
        spaceCode: data.space.code,
        pageId: data.id,
        isSpaceHomePage: data.isHomePage,
        pageSlug: data.slug,
        pageTitle: data.title,
        spaceTitle: data.space.title,
      });
    }
  },
});
