/* eslint-disable no-param-reassign */

import { cloneDeep, isFunction, isPlainObject, isUndefined } from 'lodash-es';

import handleIncludedFields from '@/utils/vuex/utils/handleIncludedFields';
import defaultPrepareConfig from '@/utils/vuex/utils/defaultPrepareConfig';
import defaultClient from '../../client';
import createActions from './createActions';
import createGetters from './createGetters';
import createMutations from './createMutations';
import createState from './createState';

/**
 * Called after the 'error' mutation i.e. 'fetchError' or 'createError' for the current Vuex CRUD module
 * @callback onError
 * @param {Object} state - The 'state' for the current Vuex CRUD module
 * @param {Error} error - The error that was thrown
 */

/**
 * Called after the 'fetchSuccess' mutation for the current Vuex CRUD module
 * @callback onFetchSuccess
 * @param {Object} state - The 'state' for the current Vuex CRUD module
 * @param {Object} response - The modified HTTP response ('data' property is changed by the
 * associated action in the store module)
 * @param {Object} includes - The raw 'included' property found in the HTTP response
 * @param {Object} meta - The raw 'meta' property found in the HTTP response
 */

/**
 * Called after the 'createSuccess' mutation for the current Vuex CRUD module
 * @callback onCreateSuccess
 * @param {Object} state - The 'state' for the current Vuex CRUD module
 * @param {Object} response - The modified HTTP response ('data' property is changed by the
 * associated action in the store module)
 * @param {Object} includes - The raw 'included' property found in the HTTP response
 * @param {Object} meta - The raw 'meta' property found in the HTTP response
 */

/**
 * Called after the 'updateSuccess' mutation for the current Vuex CRUD module
 * @callback onUpdateSuccess
 * @param {Object} state - The 'state' for the current Vuex CRUD module
 * @param {Object} response - The modified HTTP response ('data' property is changed by the
 * associated action in the store module)
 * @param {Object} includes - The raw 'included' property found in the HTTP response
 * @param {Object} meta - The raw 'meta' property found in the HTTP response
 */

/**
 * Called after the 'replaceSuccess' mutation for the current Vuex CRUD module
 * @callback onReplaceSuccess
 * @param {Object} state - The 'state' for the current Vuex CRUD module
 * @param {Object} response - The modified HTTP response ('data' property is changed by the
 * associated action in the store module)
 * @param {Object} includes - The raw 'included' property found in the HTTP response
 * @param {Object} meta - The raw 'meta' property found in the HTTP response
 */

/**
 * Called after the 'destroySuccess' mutation for the current Vuex CRUD module
 * @callback onDestroySuccess
 * @param {Object} state - The 'state' for the current Vuex CRUD module
 */

/**
 * Creates a new Vuex static CRUD module.
 *
 * @param {Object} configuration
 * @property {String} urlRoot The root url.
 * @property {Function} customUrlFn A custom getter for more complex URL to request data from API.
 * @property {Object} state The default state (will override generated state).
 * @property {Object} actions The default actions (will override generated actions object).
 * @property {Object} mutations The default mutations (will override generated mutations object).
 * @property {Object} getters The default getters (will override generated getters object).
 * @property {Object} client The client that should be used to do API requests.
 * @property {Function} onFetchStart Mutation method called after fetch start.
 * @property {onFetchSuccess} onFetchSuccess Mutation method called after fetch success.
 * @property {onError} onFetchError Mutation method called after fetch error.
 * @property {Function} onCreateStart Mutation method called after create state.
 * @property {onCreateSuccess} onCreateSuccess Mutation method called after create success.
 * @property {onError} onCreateError Mutation method called after create error.
 * @property {Function} onUpdateStart Mutation method called after update state.
 * @property {onUpdateSuccess} onUpdateSuccess Mutation method called after update success.
 * @property {onError} onUpdateError Mutation method called after update error.
 * @property {Function} onReplaceStart Mutation method called after replace state.
 * @property {onReplaceSuccess} onReplaceSuccess Mutation method called after replace success.
 * @property {onError} onReplaceError Mutation method called after replace error.
 * @property {Function} onDestroyStart Mutation method called after destroy state.
 * @property {onDestroySuccess} onDestroySuccess Mutation method called after destroy success.
 * @property {onError} onDestroyError Mutation method called after destroy error.
 * @property {Array} only A list of CRUD actions that should be available.
 * @property {Function} parse A method used to parse the resource reponse.
 * @property {Function} parseError A method used to parse error responses.
 * @property {String} property Specifies the 'nesting' property as part of Teamwork's Unified API.
 * @property {Function} prepareConfig A method used to perform any necessary modifications to
 * the client config ahead of the API call.
 * @property {Function} preparePayload A method used to perform any necessary modifications to
 * passed 'data' ahead of the API call.
 * @property {Array} socketEvents A list of socket operation that should be handled
 * @property {Function} onCreateViaSocketSuccess Mutation method called
 * after createViaSocket success.
 * @property {Function} onUpdateViaSocketSuccess Mutation method called
 * after updateViaSocket success.
 * @property {Function} onDestroyViaSocketSuccess Mutation method called
 * after destroyViaSocket success.
 * @return {Object} A Vuex module.
 */
const createStaticCrud = ({
  urlRoot,
  customUrlFn = null,
  state = {},
  actions = {},
  mutations = {},
  getters = {},
  client = defaultClient,
  onFetchStart = () => { },
  onFetchSuccess = () => { },
  onFetchError = () => { },
  onCreateStart = () => { },
  onCreateSuccess = () => { },
  onCreateError = () => { },
  onUpdateStart = () => { },
  onUpdateSuccess = () => { },
  onUpdateError = () => { },
  onReplaceStart = () => { },
  onReplaceSuccess = () => { },
  onReplaceError = () => { },
  onDestroyStart = () => { },
  onDestroySuccess = () => { },
  onDestroyError = () => { },
  only = ['FETCH', 'CREATE', 'UPDATE', 'REPLACE', 'DESTROY'],
  parse,
  parseError = (res) => res,
  property,
  prepareConfig,
  preparePayload,
  socketEvents = [],
  onSocketDataReceived = () => { },
  onCreateViaSocketSuccess = () => { },
  onUpdateViaSocketSuccess = () => { },
  onDestroyViaSocketSuccess = () => { },
} = {}) => {
  if (!property) {
    throw new Error('\'property\' must be specified');
  }

  let rootUrl;

  /**
   * Create root url for API requests.
   * Use custom url getter if given.
   */
  if (typeof customUrlFn === 'function') {
    rootUrl = customUrlFn;
  } else if (typeof urlRoot === 'string') {
    rootUrl = ((url) => {
      const lastCharacter = url.substr(-1);

      return lastCharacter === '/' ? url.slice(0, -1) : url;
    })(urlRoot);
  } else {
    throw new Error('\'customUrlFn\' or \'urlRoot\' must be specified');
  }

  if (!isFunction(prepareConfig)) {
    prepareConfig = defaultPrepareConfig;
  }

  if (!isFunction(preparePayload)) {
    // eslint-disable-next-line no-unused-vars
    preparePayload = (data, action) => {
      const tData = {};

      tData[property] = data;

      return tData;
    };
  }

  if (!isFunction(parse)) {
    parse = (response) => {
      const { data, status } = response;

      if (status === 204) {
        // No need to continue parsing
        return response;
      }

      if (!isPlainObject(data) || isUndefined(data[property])) {
        throw new Error(`'${property}' property not found in response.data`);
      }

      // Note: we need to clone the response (specifically the 'data' object that will be committed
      // to state) to ensure that our response throttling cache does not serve the same object
      // (by reference). This would mean when processing the cached response we would be
      // inadvertently attempting to modify store data
      const newResponse = cloneDeep(response);

      newResponse.data = newResponse.data[property];

      handleIncludedFields(newResponse, data.included);

      return newResponse;
    };
  }

  return {
    namespaced: true,

    state: createState({ state, only }),

    actions: createActions({
      actions,
      rootUrl,
      only,
      client,
      parse,
      parseError,
      prepareConfig,
      preparePayload,
      socketEvents,
      onSocketDataReceived,
    }),

    mutations: createMutations({
      mutations,
      only,
      onFetchStart,
      onFetchSuccess,
      onFetchError,
      onCreateStart,
      onCreateSuccess,
      onCreateError,
      onUpdateStart,
      onUpdateSuccess,
      onUpdateError,
      onReplaceStart,
      onReplaceSuccess,
      onReplaceError,
      onDestroyStart,
      onDestroySuccess,
      onDestroyError,
      socketEvents,
      onCreateViaSocketSuccess,
      onUpdateViaSocketSuccess,
      onDestroyViaSocketSuccess,
    }),

    getters: createGetters({ getters }),
  };
};

export default createStaticCrud;

/**
 * Export client in case user want's to add interceptors.
 */
export { defaultClient as client };
