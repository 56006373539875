
const fixElementScroll = (shouldBeFixed, element = document.body) => {
  if (shouldBeFixed) {
    element.classList.add('scroll-fixed');
  } else {
    element.classList.remove('scroll-fixed');
  }
};

const fixBody = (shouldBeFixed) => {
  fixElementScroll(shouldBeFixed, document.body);
};

export {
  fixBody,
  fixElementScroll,
};
