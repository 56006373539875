import formatDistance from 'date-fns/formatDistance';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import differenceInDays from 'date-fns/differenceInCalendarDays';
import format from 'date-fns/format';
import isYesterday from 'date-fns/isYesterday';
import isToday from 'date-fns/isToday';

const timeAgo = (dateString, dateFormat = '', strict = false, maxDaysToDisplay = 7) => {
  const now = new Date();
  const to = new Date(dateString);

  if (differenceInDays(now, to) <= maxDaysToDisplay) {
    if (strict) {
      return formatDistanceStrict(to, now, { addSuffix: true });
    }

    return formatDistance(to, now, { addSuffix: true });
  }

  return format(to, dateFormat);
};

const formatDate = (dateString, dateFormat = '') => format(new Date(dateString), dateFormat);

const relativeDate = (dateString, dateFormat = '') => {
  const date = new Date(dateString);

  if (isYesterday(date)) {
    return 'Yesterday';
  }

  if (isToday(date)) {
    return 'Today';
  }

  return format(date, dateFormat);
};

export default {
  formatDate,
  relativeDate,
  timeAgo,
};
