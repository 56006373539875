export default (rootUrl, { customUrl, customUrlFnArgs, id } = {}) => {
  let url;

  const isUsingCustomUrlGetter = typeof rootUrl === 'function';

  if (typeof customUrl === 'string') {
    url = customUrl;
  } else if (isUsingCustomUrlGetter) {
    const argsArray = Array.isArray(customUrlFnArgs) ? customUrlFnArgs : [customUrlFnArgs];
    const args = [id || null].concat(argsArray);

    url = rootUrl(...args);
  } else {
    url = `${rootUrl.replace(/\.json$/, '')}${(id ? `/${id}` : '')}`;
  }

  return `${url.replace(/\.json$/, '')}.json`;
};
