import store from '@/store';
import externalLinks from '@/utils/constants/externalLinks';
import { isBetaEnvironment, isBetaCheckoutEnvironment, isStagingEnvironment } from '@/utils/helpers/environment.utility';

const getLocalDevUrl = (withSubdomain = '') => {
  const { host, origin, protocol } = window.location;
  const isCloudDevEnv = host.indexOf('dev.stg.teamworkops') > -1;

  if (process.env.VUE_APP_PROXY_TARGET && process.env.VUE_APP_PROXY_TARGET.includes('.dev.stg.teamworkops.')) {
    const hostTarget = new URL(process.env.VUE_APP_PROXY_TARGET).host;
    return withSubdomain === '' ? process.env.VUE_APP_PROXY_TARGET : `${protocol}//${withSubdomain}.${hostTarget}`;
  }

  if (isCloudDevEnv) {
    return withSubdomain === '' ? origin : `${protocol}//${withSubdomain}.${host}`;
  }

  return withSubdomain === '' ? 'http://sunbeam.teamwork.localhost' : `http://${withSubdomain}.teamwork.localhost`;
};

const getLocalDevHostname = () => {
  if (process.env.VUE_APP_PROXY_TARGET && process.env.VUE_APP_PROXY_TARGET.includes('.dev.stg.teamworkops.')) {
    const hostnameTarget = new URL(process.env.VUE_APP_PROXY_TARGET).hostname;
    return hostnameTarget;
  }

  const hostname = window.location.hostname;
  const isCloudDevEnv = hostname.indexOf('dev.stg.teamworkops') > -1;

  return isCloudDevEnv ? hostname : 'sunbeam.teamwork.localhost';
};

const getCheckoutUrl = ({ ref, path = '' }) => {
  const documentCookie = document.cookie;
  const { host } = window.location;
  const isLocalhost = host.indexOf('localhost') > -1;
  const isCloudDevEnv = host.indexOf('dev.stg.teamworkops') > -1;

  let baseUrl;
  if (isLocalhost || isCloudDevEnv) {
    if (process.env.VUE_APP_PROXY_TARGET && process.env.VUE_APP_PROXY_TARGET.includes('.staging.')) {
      baseUrl = externalLinks.checkoutStagingPage;
    } else {
      baseUrl = `${getLocalDevUrl('checkout')}/`;
    }
  } else if (host.includes('.staging.') || isStagingEnvironment) {
    baseUrl = externalLinks.checkoutStagingPage;
  } else if (isBetaEnvironment(host, documentCookie) || isBetaCheckoutEnvironment(host, documentCookie)) {
    const installationRegion = store.getters['installation/region'];
    baseUrl = installationRegion === 'US' ? externalLinks.checkoutUSBetaPage : externalLinks.checkoutEUBetaPage;
  } else {
    const installationRegion = store.getters['installation/region'];
    baseUrl = installationRegion === 'US' ? externalLinks.checkoutUSPage : externalLinks.checkoutEUPage;
  }

  let checkoutReferenceParam = '';
  if (ref) {
    checkoutReferenceParam = `#ref=${encodeURI(ref)}`;
  }
  return `${baseUrl}${path}${checkoutReferenceParam}`;
};

const launchpadBaseUrl = `${location.origin}/launchpad`;
const launchpadEndpoints = {
  apiKeys: `${launchpadBaseUrl}/v1/apikeys.json`,
  notSetup: `${launchpadBaseUrl}/notsetup/spaces`,
  spacesCheckoutRef: `${launchpadBaseUrl}/v1/checkoutref/spaces.json`,
  projectsCheckoutRef: `${launchpadBaseUrl}/v1/checkoutref/projects.json`,
};

const checkoutEndpoints = {
  billing: '/billing',
  delete: '/delete',
  invoices: '/invoices',
};

const endpointUrls = {
  ...launchpadEndpoints,
  ...checkoutEndpoints,
};

const hubEmbeddedManagementUrl = `${location.origin}/hub/#/embedded/apps/manage`;

export {
  endpointUrls,
  launchpadBaseUrl,
  getCheckoutUrl,
  hubEmbeddedManagementUrl,
  getLocalDevUrl,
  getLocalDevHostname,
};
