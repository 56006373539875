export default {
  DEV_APP_ENABLED: 'dev-app-enabled',
  DEV_APP_LOCATION: 'dev-app-location',
  FEEDBACK_CTA_DISMISSED_TIME: 'spaces-feedback-cta-dismissed-time',
  GRACE_PERIOD_WARNING_DISMISSED: 'grace-period-warning-dismissed',
  PAGE_VERSION_HISTORY_SHOW_PAGE_DIFF: 'tw-spaces-pvh-show-page-diff',
  REQUIRED_READING_SORT_ORDER: 'spaces-required-reading-sort-order',
  SPACES_HOME_CATEGORY_STATES: 'spaces-home-category-states',
  SPACES_HOME_SIDEBAR_CATEGORIES_EXPAND_STATE: 'spaces-home-sidebar-categories-expand-state',
  SPACES_HOME_SORT_ORDER: 'spaces-home-sort-order',
  SPACES_HOME_VIEW_TYPE: 'spaces-home-view-type',
  SPACES_HOME_LAST_ROUTE_VISITED: 'spaces-home-last-route-visited',
  SPACES_PEOPLE_VIEW_TYPE: 'spaces-people-view-type',
  SPACES_PEOPLE_SIDEBAR_COMPANIES_EXPAND_STATE: 'spaces-people-sidebar-companies-expand-state',
  TRIAL_DIALOG_LAST_DISMISSED: 'trialDialogLastDismissed',
};
