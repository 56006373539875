export default {
  namespaced: true,
  state: {
    activeSlidingDrawer: null,
    slidingDrawerProps: null,
  },
  mutations: {
    changeActiveSlidingDrawer(state, { activeSlidingDrawer, slidingDrawerProps }) {
      state.activeSlidingDrawer = activeSlidingDrawer;
      state.slidingDrawerProps = slidingDrawerProps;
    },
  },
  actions: {
    slideDrawerIn({ commit }, { activeSlidingDrawer, slidingDrawerProps }) {
      commit('changeActiveSlidingDrawer', { activeSlidingDrawer, slidingDrawerProps });
    },

    slideDrawerOut({
      commit, state, dispatch, rootState,
    }, { name }) {
      if (rootState.ui.formState.isAmended) {
        dispatch('ui/formState/amend', false, { root: true });
      }

      if (state.activeSlidingDrawer !== name) {
        return;
      }

      commit('changeActiveSlidingDrawer', {});
    },
  },
};
