const retry = async (fn, retriesLeft = 5, interval = 1000, exponential = false) => {
  try {
    const response = await fn();

    return response;
  } catch (error) {
    if (retriesLeft) {
      await new Promise((r) => setTimeout(r, interval));

      return retry(
        fn,
        retriesLeft - 1,
        exponential ? interval * 2 : interval,
        exponential,
      );
    }

    throw new Error('Max retries reached');
  }
};

export default retry;
