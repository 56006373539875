import { compact } from 'lodash-es';

// @vue/component
export default {
  props: {
    variant: {
      type: String,
      default: '',
    },
    noSpacing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    classes() {
      return [
        ...this.variants,
        { 'w-form__group--no-spacing': this.noSpacing },
      ];
    },
    variants() {
      return compact(this.variant.split(' ')).map((variant) => `w-form__group--${variant}`);
    },
  },
};
