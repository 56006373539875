// @vue/component
export default {
  props: {
    forId: {
      type: String,
      required: false,
      default: null,
    },
    isOptional: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
