import Vue from 'vue';
import defaultClient from '@/utils/vuex/client';
import { endpointUrls } from '@/utils/constants/twEndpoints';
import isPDFExporterAgent from '@/utils/helpers/isPDFExporterAgent';
import { logError } from '@/utils/helpers/logger.utility';

const productParam = 'spaces';

export default {
  namespaced: true,
  state: {
    items: {},
    collaborativeEditingApiKey: undefined,
  },

  getters: {
    apiKeys: (state) =>
      Object.values(state.items).sort((keyA, keyB) => keyA.name.localeCompare(keyB.name)),
    collaborativeEditingApiKey: (state) => state.collaborativeEditingApiKey,
  },

  mutations: {
    updateApiKeys(state, { apiKeys }) {
      const apiKeysObj = apiKeys.reduce((partial, item) => ({ ...partial, [item.id]: item }), {});
      state.items = { ...state.items, ...apiKeysObj };
    },

    removeApiKeys(state, { apiKeyIds }) {
      if (apiKeyIds.length) {
        apiKeyIds.forEach((id) => Vue.delete(state.items, id.toString()));
      }
    },

    regenerateApiKey(state, { newApiKey, oldApiKey }) {
      const newitems = { ...state.items };
      delete newitems[oldApiKey.id];
      newitems[newApiKey.id] = newApiKey;
      state.items = newitems;
    },
    setCollaborativeEditingKey(state, apiKey) {
      state.collaborativeEditingApiKey = apiKey;
    },
  },

  actions: {
    async fetchApiKeys({ dispatch, commit }, params) {
      if (isPDFExporterAgent()) {
        return [];
      }

      const args = params || {};
      return defaultClient.get(endpointUrls.apiKeys, {
        params: {
          product: productParam,
          showHidden: args.showHidden || false,
        },
      })
        .then((response) => {
          const { data: { apiKeys } } = response;
          const apiKeysCount = apiKeys.length;
          if (apiKeysCount) {
            commit('updateApiKeys', { apiKeys, apiKeysCount });
          }
          return response;
        })
        .catch((error) => {
          dispatch('alerts/showAlert', { type: 'error', message: 'Failed to get API keys' }, { root: true });
          return Promise.reject(error);
        });
    },

    create({ dispatch, commit }, { data, shouldRegenerate = false, showAlert = true }) {
      return defaultClient.post(endpointUrls.apiKeys, {
        scope: 'spaces',
        ...data,
      })
        .then((response) => {
          const { data: { apiKey } } = response;

          if (!shouldRegenerate) {
            commit('updateApiKeys', {
              apiKeys: [apiKey],
            });
            if (showAlert) {
              dispatch('alerts/showAlert', { type: 'success', message: 'API key created successfully' }, { root: true });
            }
          }
          return response;
        })
        .catch((error) => {
          if (showAlert) {
            dispatch('alerts/showAlert', { type: 'error', message: 'Error creating API key' }, { root: true });
          }
          Promise.reject(error);
        });
    },

    async createCollaborativeEditingApiKey({ commit, dispatch }) {
      try {
        const { data: { apiKey } } = await defaultClient.post(endpointUrls.apiKeys, {
          scope: 'spaces',
          name: 'Collaborative Editing',
          validFor: 43200, // seconds (12 hours)
          hidden: true,
        });

        commit('setCollaborativeEditingKey', apiKey);
      } catch (error) {
        logError(error);
        dispatch('alerts/showAlert', {
          type: 'error',
          message: 'Error creating Collaborative Editting API key',
        }, { root: true });
      }
    },
  },

  async revokeApiKeys({ commit, dispatch }, { apiKeys, resourceName }) {
    const promises = apiKeys.map(
      (key) => dispatch('destroyApiKey', key),
    );

    try {
      const response = await Promise.all(promises);
      const apiKeyIds = [];
      response.forEach((res, index) => {
        if (res.data.status === 'ok') {
          apiKeyIds.push(apiKeys[index].id);
        } else {
          dispatch('alerts/showAlert', {
            type: 'error',
            message: `Error revoking '${apiKeys[index].name} API key`,
          }, { root: true });
        }
      });

      commit('removeApiKeys', { apiKeyIds });
      const resource = resourceName !== undefined
        ? resourceName
        : 'All API keys';
      dispatch('alerts/showAlert', { type: 'success', message: `${resource} revoked successfully` }, { root: true });
      return response;
    } catch (error) {
      dispatch('alerts/showAlert', { type: 'error', message: 'Error revoking API keys' }, { root: true });
      return Promise.reject(error);
    }
  },

  async regenerateApiKey({ dispatch, commit }, { key, vnode }) {
    const { name, dateValidUntil, scope } = key;

    // The API is changing to return an array but the POST is still a string for now
    // TODO: send array in POST when API is changed.
    const scopeToPass = scope.join(',');

    try {
      await dispatch('destroyApiKey', key);
      const response = await dispatch('create', {
        data: {
          name,
          dateValidUntil,
          scope: scopeToPass,
        },
        shouldRegenerate: true,
      });
      const { data } = response;
      vnode.key = data.apiKey.id; //eslint-disable-line
      commit('regenerateApiKey', { newApiKey: data.apiKey, oldApiKey: key });
      dispatch('alerts/showAlert', {
        type: 'success',
        message: `API key '${name}' regenerated successfully`,
      }, { root: true });
      return data;
    } catch (error) {
      dispatch('alerts/showAlert', { type: 'error', message: `Error regenerating API key '${name}'` }, { root: true });
      return Promise.reject(error);
    }
  },

  /* eslint-disable-next-line no-empty-pattern */
  destroyApiKey({}, apiKey) {
    if (apiKey && apiKey.id && Number.isInteger(apiKey.id)) {
      const url = `/launchpad/v1/apikeys/${apiKey.id}.json`;
      return defaultClient.delete(url);
    }
    return false;
  },
};
