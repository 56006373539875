/* eslint-disable no-underscore-dangle */
import { isFunction } from 'lodash-es';

import { escapeKey } from '@/utils/constants/keyboardKeys';
import { isEventKeyEqualTo } from '@/utils/helpers/keyboardEvents';
import createPopper from '@/utils/popper/createPopper';
import { PLACEMENTS, PLACEMENT_MODIFIERS } from '@/utils/constants/popper';

// @vue/component
export default {
  props: {
    isArrowEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    alignArrowWithReference: {
      type: Boolean,
      required: false,
      default: false,
    },
    positionFixed: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    placement: {
      type: String,
      required: false,
      default: PLACEMENTS.BOTTOM,
      validator(value) {
        const [placement, modifier] = value.split('-');

        if (!placement) {
          return false;
        }

        if (!PLACEMENTS[placement.toUpperCase()]) {
          return false;
        }

        if (modifier) {
          if (!PLACEMENT_MODIFIERS[modifier.toUpperCase()]) {
            return false;
          }
        }

        return true;
      },
    },
    showMobileOverlay: {
      type: Boolean,
      required: false,
      default: true,
    },
    contentClass: {
      type: String,
      required: false,
      default: '',
    },
    shouldContentOverflow: {
      type: Boolean,
      required: false,
      default: true,
    },
    fullWidthOnMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * This is an Array containing two Number values used to configure the Popper offset modifier
     * https://popper.js.org/docs/v2/modifiers/offset/
     * Skidding - https://popper.js.org/docs/v2/modifiers/offset/#skidding-1
     * Distance - https://popper.js.org/docs/v2/modifiers/offset/#distance-1
     */
    offset: {
      type: Array,
      required: false,
      default: () => [0, 0],
    },
    shouldBeOpenOnInitialisation: {
      type: Boolean,
      required: false,
      default: false,
    },
    altBoundary: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideWhenOutOfBoundary: {
      type: Boolean,
      required: false,
      default: false,
    },
    showOnHover: {
      type: Boolean,
      required: false,
      default: false,
    },
    bindToShouldShowProp: {
      type: Boolean,
      required: false,
      default: false,
    },
    shouldShow: {
      type: Boolean,
      required: false,
      default: false,
    },
    /* eslint-disable vue/require-prop-types */
    repositionOnChange: { // reposition if value passed to this prop changes
      required: false,
      default: undefined,
    },
    disableToggle: {
      type: Boolean,
      required: false,
      default: false,
    },
    shouldCloseOnOutsideClick: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      popper: null,
      isOpen: this.shouldBeOpenOnInitialisation,
      isHoveringContent: false,
      isHoveringButton: false,
    };
  },
  computed: {
    isMobileBreakpoint() {
      return (this.$mq === 'x-small' || this.$mq === 'small');
    },

    overflowPadding() {
      return (this.fullWidthOnMobile && this.isMobileBreakpoint) ? 0 : 5;
    },

    offsetSkidding() {
      return this.offset[0];
    },

    offsetDistance() {
      return this.offset[1];
    },

    config() {
      return {
        placement: this.placement,
        ...(this.positionFixed && { strategy: 'fixed' }),
        modifiers: [
          {
            name: 'hide',
            enabled: this.hideWhenOutOfBoundary,
          },
          {
            name: 'preventOverflow',
            options: {
              rootBoundary: 'viewport',
              padding: this.overflowPadding,
            },
          },
          {
            name: 'arrow',
            options: {
              element: '[data-popper-arrow]',
            },
          },
          {
            name: 'offset',
            options: {
              offset: this.offset,
            },
          },
        ],
      };
    },

    classes() {
      return [
        (this.fullWidthOnMobile && this.isMobileBreakpoint) ? 'popper-content-wrapper--mobile-full-width' : '',
        this.contentClass,
      ];
    },

    contentOverflowClass() {
      return [this.shouldContentOverflow ? 'content--overflow' : ''];
    },
  },
  methods: {
    open() {
      if (this.isOpen) {
        return;
      }

      if (this.bindToShouldShowProp && !this.shouldShow) {
        return;
      }

      this.isOpen = true;

      this.$nextTick(() => {
        this.setupPopper();
        this.$emit('open');
      });
    },

    close() {
      if (!this.isOpen) {
        return;
      }

      if (this.bindToShouldShowProp && this.shouldShow) {
        this.$emit('close');
        return;
      }

      this.isOpen = false;

      this.popupItem = null;
      this.$emit('close');
    },

    destroy() {
      if (this.popper && isFunction(this.popper.destroy)) {
        this.popper.destroy();
        this.popupItem = null;
      }
    },

    repositionPopper() {
      if (this.popper) {
        this.popper.update();
      }
    },

    toggle(e) {
      if (this.bindToShouldShowProp) {
        return;
      }

      if (this.disableToggle) {
        return;
      }

      e.preventDefault();

      this.$emit('click');

      if (this.isOpen) {
        this.close();
      } else {
        this.open();
      }
    },

    onMouseEnterButton() {
      this.isHoveringButton = true;
      if (this.showOnHover && !this.isOpen) {
        this.open();
      }
    },

    onMouseLeaveButton() {
      this.isHoveringButton = false;
      if (this.showOnHover && this.isOpen && !this.isHoveringContent) {
        this.close();
      }
    },

    onMouseEnterContent() {
      this.isHoveringContent = true;
      if (this.showOnHover && !this.isOpen) {
        this.open();
      }
    },

    onMouseLeaveContent() {
      this.isHoveringContent = false;
      if (this.showOnHover && this.isOpen && !this.isHoveringButton) {
        this.close();
      }
    },

    onOutsideClick() {
      if (this.shouldCloseOnOutsideClick) {
        this.close();
      }
    },

    setupPopper() {
      if (!this.$refs.button) {
        return;
      }

      this.destroy();

      this.popper = createPopper(
        this.$refs.button,
        this.$refs.content,
        this.config,
      );

      this.popupItem = this.$refs.content;
      // Schedule an update after instantions to avoid an issue where the popper element's width
      // changed after positioning calculations had occurred
      this.popper.update();

      this.setupSpacer();
    },

    onKeyUp() {
      if (isEventKeyEqualTo(event, escapeKey)) {
        this.close();
      }
    },

    setupSpacerPosition() {
      if (!(this.$refs.content instanceof HTMLElement)) {
        return;
      }

      if (!(this.$refs.spacer instanceof HTMLElement)) {
        return;
      }

      // Add a pixel to both the cover any extra gap
      const offset = `${this.offsetDistance + 1}px`;

      const popperPlacement = this.$refs?.content?.dataset?.popperPlacement;
      if (!popperPlacement) {
        return;
      }

      const [placement] = popperPlacement.split('-');

      if (!PLACEMENTS[placement.toUpperCase()]) {
        return;
      }

      if (placement === PLACEMENTS.TOP || placement === PLACEMENTS.BOTTOM) {
        this.$refs.spacer.style.top = placement === PLACEMENTS.BOTTOM ? `-${offset}` : 'auto';
        this.$refs.spacer.style.right = 'auto';
        this.$refs.spacer.style.bottom = placement === PLACEMENTS.TOP ? `-${offset}` : 'auto';
        this.$refs.spacer.style.left = '0px';
      } else {
        this.$refs.spacer.style.top = '0px';
        this.$refs.spacer.style.right = placement === PLACEMENTS.LEFT ? `-${offset}` : 'auto';
        this.$refs.spacer.style.bottom = 'auto';
        this.$refs.spacer.style.left = placement === PLACEMENTS.RIGHT ? `-${offset}` : 'auto';
      }
    },

    setupSpacerSize() {
      if (!(this.$refs.content instanceof HTMLElement)) {
        return;
      }

      if (!(this.$refs.spacer instanceof HTMLElement)) {
        return;
      }

      // Add a pixel to both the cover any extra gap
      const offset = `${this.offsetDistance + 1}px`;
      const height = offset;

      const popperPlacement = this.$refs?.content?.dataset?.popperPlacement;
      if (!popperPlacement) {
        return;
      }

      const [placement] = popperPlacement.split('-');

      if (!PLACEMENTS[placement.toUpperCase()]) {
        return;
      }

      if (placement === PLACEMENTS.TOP || placement === PLACEMENTS.BOTTOM) {
        this.$refs.spacer.style.height = height;
        this.$refs.spacer.style.width = '100%';
      } else {
        this.$refs.spacer.style.height = '100%';
        this.$refs.spacer.style.width = height;
      }
    },

    setupSpacer() {
      this.setupSpacerPosition();
      this.setupSpacerSize();
    },

    startPopperPlacementAttributeObserver() {
      if (!(this.$refs.content instanceof HTMLElement)) {
        return;
      }

      this.stopPopperPlacementAttributeObserver();

      this.mutationObserver = new MutationObserver((mutationsList) => {
        mutationsList.forEach((mutation) => {
          if (mutation.type === 'attributes') {
            if (mutation.attributeName === 'data-popper-placement') {
              this.setupSpacer();
            }
          }
        });
      });

      this.mutationObserver.observe(this.$refs.content, { attributes: true });
    },

    stopPopperPlacementAttributeObserver() {
      if (this.mutationObserver instanceof MutationObserver) {
        this.mutationObserver.disconnect();
      }
    },
  },
  mounted() {
    if (this.showOnHover) {
      this.startPopperPlacementAttributeObserver();
    }

    if (this.isOpen) {
      this.$emit('open');
    }
  },

  beforeDestroy() {
    this.destroy();

    this.stopPopperPlacementAttributeObserver();

    this.$store.dispatch('ui/popover/setPopoverState', { id: this._uid, isOpen: undefined }, { root: true });
  },
  watch: {
    config: {
      immediate: true,
      handler() {
        if (this.popper) {
          this.popper.setOptions(this.config);

          this.setupSpacer();
        }
      },
    },
    isOpen: {
      immediate: true,
      handler() {
        if (this.isOpen) {
          this.$store.dispatch('ui/popover/setPopoverState', { id: this._uid, isOpen: this.isOpen }, { root: true });

          document.addEventListener('keyup', this.onKeyUp);
        } else {
          this.$store.dispatch('ui/popover/setPopoverState', { id: this._uid, isOpen: this.isOpen }, { root: true });

          document.removeEventListener('keyup', this.onKeyUp);
        }
      },
    },
    placement: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          if (this.isOpen) {
            this.setupPopper();
          }
        });
      },
    },
    shouldShow: {
      immediate: true,
      handler() {
        if (this.bindToShouldShowProp) {
          if (this.shouldShow) {
            this.open();
          } else {
            this.close();
          }
        }
      },
    },
    repositionOnChange() {
      this.repositionPopper();
    },
    $route(to, from) {
      if (to.path !== from.path) {
        this.$nextTick(() => {
          this.close();
          this.setupPopper();
        });
      }
    },
  },
};
