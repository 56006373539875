import { isPlainObject } from 'lodash-es';

import { logError } from '@/utils/helpers/logger.utility';

const save = (key, value) => {
  try {
    window.localStorage.setItem(key, value);
  } catch (e) {
    logError(`LocalStorage drivers: save ${key} error: ${e}`);
  }
};

const saveObject = (key, obj) => {
  if (!isPlainObject(obj)) {
    throw new Error(`Cannot save object with key ${key} to localstorage - not a valid Object`);
  }

  save(key, JSON.stringify(obj));
};

const get = (key) => { // eslint-disable-line consistent-return
  try {
    const value = window.localStorage.getItem(key);
    return value === 'undefined' ? undefined : value;
  } catch (e) {
    logError(`LocalStorage drivers: get ${key} error: ${e}`);
  }
};

const getObject = (key) => {
  let obj = get(key);

  if (!obj) {
    return undefined;
  }

  try {
    obj = JSON.parse(obj);
  } catch (e) {
    throw new Error(`Cannot parse object with key ${key} from localstorage - not a valid JSON Object`);
  }

  return obj;
};

const remove = (key) => {
  try {
    window.localStorage.removeItem(key);
  } catch (e) {
    logError(`LocalStorage drivers: remove ${key} error: ${e}`);
  }
};

const clear = (key) => {
  try {
    window.localStorage.clear(key);
  } catch (e) {
    logError(`LocalStorage drivers: clear ${key} error: ${e}`);
  }
};

const localStorageDrivers = {
  save,
  get,
  saveObject,
  getObject,
  remove,
  clear,
};

export default localStorageDrivers;
