export const SORT_ORDER_KEYS = Object.freeze({
  ALPHABETICAL: 'alphabetical',
  REVERSE_ALPHABETICAL: 'reverse-alphabetical',
  NEWEST_FIRST: 'newest-first',
  OLDEST_FIRST: 'oldest-first',
});

export const SORT_ORDERS = Object.freeze({
  [SORT_ORDER_KEYS.ALPHABETICAL]: {
    id: SORT_ORDER_KEYS.ALPHABETICAL,
    label: 'A to Z',
  },
  [SORT_ORDER_KEYS.REVERSE_ALPHABETICAL]: {
    id: SORT_ORDER_KEYS.REVERSE_ALPHABETICAL,
    label: 'Z to A',
  },
  [SORT_ORDER_KEYS.NEWEST_FIRST]: {
    id: SORT_ORDER_KEYS.NEWEST_FIRST,
    label: 'Newest first',
  },
  [SORT_ORDER_KEYS.OLDEST_FIRST]: {
    id: SORT_ORDER_KEYS.OLDEST_FIRST,
    label: 'Oldest first',
  },
});

export const DEFAULT_SORT_ORDER = SORT_ORDER_KEYS.OLDEST_FIRST;

export default {
  SORT_ORDER_KEYS,
  SORT_ORDERS,
  DEFAULT_SORT_ORDER,
};
