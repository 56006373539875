import Vue from 'vue';

import { union, compact, isPlainObject } from 'lodash-es';

import client from '@/utils/vuex/client';
import { PERMISSION_LEVELS } from '@/utils/constants/spacePermissions';
import { createStaticCrudModule } from '@/utils/vuex';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import isInternalInstallation from '@/utils/helpers/isInternalInstallation';
import isTestingInstallation from '@/utils/helpers/isTestingInstallation';

import store from '..';

export default createStaticCrudModule({
  only: ['FETCH', 'UPDATE'],
  urlRoot: buildApiUrl({
    resource: 'me',
  }),
  property: 'me',
  state: {
    spacePermissions: {},
  },
  getters: {
    isOnboardingFetchSuccess: (state) => state.isOnboardingFetchSuccess,

    isAuthenticated: (state, getters) => !!getters.userId,

    isActive: (state, getters) => getters.get && getters.get.state === 'active',

    userId: (state, getters) => (isPlainObject(getters.get) && getters.get.id) || null,

    user: (state, getters) => {
      if (!getters.userId) {
        return null;
      }

      return getters.get;
    },

    spacePermissions: (state) => {
      if (!isPlainObject(state.spacePermissions)) {
        return {};
      }

      return state.spacePermissions;
    },

    isOnboarded: (state, getters) => getters.get && getters.get.isOnboarded,

    companyId: (state, getters) => (
      isPlainObject(getters.get)
      && isPlainObject(getters.get.company)
      && getters.get.company.id
    ) || null,

    company: (state, getters, rootState, rootGetters) => {
      if (!getters.companyId) {
        return null;
      }

      return rootGetters['companies/byId'](getters.companyId);
    },

    companyName: (state, getters) => (
      isPlainObject(getters.company)
      && getters.company.name
    ) || '',

    projectsRoles: (state, getters) => (
      isPlainObject(getters.get)
      && getters.get.projectsRoles
    ) || null,

    isProjectsAdmin: (state, getters) => !!(
      isPlainObject(getters.projectsRoles)
      && getters.projectsRoles.isAdmin
    ),

    isAdmin: (state, getters) => !!(
      isPlainObject(getters.get)
      && getters.get.isAdmin
      && getters.isProjectsAdmin
    ),

    isSiteOwner: (state, getters, rootState, rootGetters) => (
      isPlainObject(getters.get)
      && (getters.userId === rootGetters['installation/appOwnerId'])
    ),

    isGuest: (state, getters) => {
      if (!isPlainObject(getters.get)) {
        return undefined;
      }
      return getters.get.id === 0;
    },

    isGuestFromPublicURL(state, getters) {
      return getters.isGuest && getters.get.email === '';
    },

    isGuestFromEmailURL(state, getters) {
      return getters.isGuest && getters.get.email !== '';
    },

    canDeleteUsers: (state, getters) => getters.canManageUsers,

    canAddUsers: (state, getters) => getters.canManageUsers,

    // Note: currently no-one can edit other user profiles
    canEditUsers: () => false,

    // Note: currently everyone can add Spaces
    canAddSpaces: () => true,

    canManageCategories: (state, getters) => getters.isAdmin,

    preferences: (state, getters) => (
      isPlainObject(getters.get)
      && getters.get.preferences
    ) || null,

    isTestingInstallation: (state, getters, rootState, rootGetters) => isTestingInstallation(rootGetters['installation/id']),

    isInternalInstallation: (state, getters, rootState, rootGetters) => isInternalInstallation(rootGetters['installation/id']),

    authToken: (state, getters) => (
      isPlainObject(getters.get)
      && getters.get.authToken
    ) || null,

    canMovePages: (state, getters) => (spaceId) => getters.canEditSpace(spaceId),

    canManageRequiredReading: (state, getters) => (spaceId) => getters.canEditSpace(spaceId),

    canEditSpace: (state) => (spaceId) => state.spacePermissions[spaceId] === PERMISSION_LEVELS.EDIT
      || state.spacePermissions[spaceId] === PERMISSION_LEVELS.MANAGE,

    canManageSpace: (state) => (spaceId) => state.spacePermissions[spaceId] === PERMISSION_LEVELS.MANAGE,

    canManageUsers: (state, getters) => getters.isAdmin,

    canManageSubscription: (state, getters) => getters.isAdmin,

    canManageTeamworkApps: (state, getters) => getters.isAdmin,

    canManageWebhooks: (state, getters) => getters.isAdmin,

    canManageGlobalTags: (state, getters) => getters.isAdmin,

    canManagePageTemplateGallery: (state, getters) =>
      [
        // peter@teamwork.com
        1,
        // ryan.lynch@teamwork.com
        210817,
        // shay.doherty@teamwork.com
        209495,
        // adam.stewart@teamwork.com
        231021,
        // james.mccullough@teamwork.com
        219445,
      ].includes(getters.userId),

    canManageAnyPageComment: (state, getters) => getters.isAdmin,

    canManageAnyInlineComment: (state, getters) => getters.isAdmin,

    canManageAnyCustomPageTemplate: (state, getters) => getters.isAdmin,
  },
  actions: {
    checkSession({ getters, dispatch }) {
      if (!getters.isAuthenticated) {
        dispatch('fetch', {});
      }
    },

    setSpacePermission({ getters, commit }, { spaceId, permissionLevel }) {
      commit('setSpacePermission', { spaceId, permissionLevel });

      // Remove this space from the Spaces store module (can't perform this mutation in the
      // 'setSpacePermission' mutation) as it does not gain the 'rootState' parameter
      if (getters.isAdmin && !permissionLevel) {
        commit('spaces/removeSpace', spaceId, { root: true });
      }
    },

    updatePreferences({ dispatch }, preferences) {
      return dispatch('update', { data: { preferences } });
    },

    updateUserAvatarUrl({ commit }, avatarUrl) {
      commit('setAvatarUrl', avatarUrl);
    },

    async fetchSpacePermissions({ commit }) {
      try {
        const response = await client.get(buildApiUrl({ resource: 'spaces/permissions' }));
        const data = response.data;

        if (!data || !Array.isArray(data.permissions)) {
          commit('clearSpacePermissions');
          return;
        }

        commit('setSpacePermissions', data.permissions);
      } catch (error) {
        commit('clearSpacePermissions');
      }
    },
  },
  mutations: {
    clearSpacePermissions(state) {
      state.spacePermissions = {};
    },

    setSpacePermissions(state, permissions) {
      state.spacePermissions = {};

      permissions.forEach((permission) => {
        Vue.set(state.spacePermissions, permission.space.id, permission.level);
      });
    },

    setSpacePermission(state, { spaceId, permissionLevel }) {
      if (!isPlainObject(state.spacePermissions)) {
        state.spacePermissions = {};
      }

      if (!permissionLevel) {
        Vue.delete(state.spacePermissions, spaceId);
      }

      Vue.set(state.spacePermissions, spaceId, permissionLevel);
    },

    setOnboardingFetchSuccess(state) {
      state.isOnboardingFetchSuccess = true;
    },

    confirmUserIsOnboarded(state) {
      state.entity.isOnboarded = true;
    },

    setInstallation(state, installation) {
      state.entity.installation = installation;
    },

    setAvatarUrl(state, avatarUrl) {
      state.entity.avatar = avatarUrl;
    },
  },
  prepareConfig: (config) => {
    const tConfig = isPlainObject(config) ? config : {};

    tConfig.params = isPlainObject(tConfig.param) ? tConfig.param : {};

    let include = tConfig.params.include || '';

    include = include.trim().replace(/\s/g, '').split(',');
    include = compact(union(include, ['companies', 'installations', 'installation.users']));
    include = include.join();

    tConfig.params.include = include;

    return tConfig;
  },
  onFetchSuccess(state, response) {
    // Check to ensure 'installation' is present and decorated. No specific reason to check for
    // 'appOwner', we're just checking to ensure the 'installation' object contains something
    // other than 'id' or 'type' properties i.e. it has been included and decorated
    if (!response.data.installation || typeof response.data.installation.appOwner === 'undefined') {
      throw new Error('\'installation\' property on \'session\' is not valid');
    }

    store.dispatch('installation/setInstallation', response.data.installation, { root: true });
  },
});
