/* eslint-disable no-param-reassign */
const mapping = {
  name: 'a',
  keywords: 'b',
  sheet: 'c',
  emoticons: 'd',
  short_names: 'e',
};

const buildSearch = (emoji) => {
  const search = [];

  const addToSearch = (strings, split) => {
    if (!strings) {
      return;
    }

    (Array.isArray(strings) ? strings : [strings]).forEach((string) => {
      (split ? string.split(/[-|_|\s]+/) : [string]).forEach((s) => {
        s = s.toLowerCase();

        if (search.indexOf(s) === -1) {
          search.push(s);
        }
      });
    });
  };

  addToSearch(emoji.short_names, true);
  addToSearch(emoji.name, true);
  addToSearch(emoji.keywords, false);
  addToSearch(emoji.emoticons, false);

  return search.join(',');
};

const compress = (emoji) => {
  emoji.short_names = emoji.short_names.filter((shortname) => shortname !== emoji.short_name);
  delete emoji.short_name;

  emoji.sheet = [emoji.sheet_x, emoji.sheet_y];
  delete emoji.sheet_x;
  delete emoji.sheet_y;

  Object.keys(mapping).forEach((key) => {
    emoji[mapping[key]] = emoji[key];
    delete emoji[key];
  });

  Object.keys(emoji).forEach((key) => {
    const value = emoji[key];

    if (Array.isArray(value) && !value.length) {
      delete emoji[key];
    } else if (typeof value === 'string' && !value.length) {
      delete emoji[key];
    } else if (value === null) {
      delete emoji[key];
    }
  });
};

const uncompress = (data) => {
  data.compressed = false;

  Object.keys(data.emojis).forEach((id) => {
    const emoji = data.emojis[id];

    Object.keys(mapping).forEach((key) => {
      emoji[key] = emoji[mapping[key]];
      delete emoji[mapping[key]];
    });

    if (!emoji.short_names) emoji.short_names = [];
    emoji.short_names.unshift(id);

    emoji.sheet_x = emoji.sheet[0];
    emoji.sheet_y = emoji.sheet[1];
    delete emoji.sheet;

    if (!emoji.text) emoji.text = '';

    emoji.search = buildSearch(emoji);
  });

  return data;
};

export {
  buildSearch,
  compress,
  uncompress,
};
