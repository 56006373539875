import ErrorContainer from '@pages/Error/ErrorContainer';
import NoAccessIllustration from '@/assets/no_access.svg';

// @vue/component
export default {
  components: {
    ErrorContainer,
    NoAccessIllustration,
  },
};
