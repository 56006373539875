import formMixin from '@/utils/mixins/form.mixin';
import FormError from '../FormError/FormError';

// @vue/component
export default {
  mixins: [formMixin],
  props: {
    maxLength: {
      type: Number,
      required: false,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    FormError,
  },
  computed: {
    hasPrefix() {
      return this.$slots.prefix;
    },
    hasPostfix() {
      return this.$slots.postfix;
    },
    inputClasses() {
      return [
        { 'w-form-input--error': this.validationError },
        { 'w-form-input--disabled': this.disabled },
      ];
    },
    inputFieldClasses() {
      return [
        { 'pre-fixed': this.hasPrefix },
        { 'post-fixed': this.hasPostfix },
      ];
    },
  },
  methods: {
    blur() {
      this.$refs.formInput.blur();
    },

    focus() {
      this.$refs.formInput.focus();
    },

    onBlur(value) {
      if (this.vuelidateOptions) {
        this.vuelidateOptions.$touch();
      }

      this.$emit('blur', value);
    },

    onFocus(value) {
      if (this.vuelidateOptions) {
        this.vuelidateOptions.$reset();
      }

      this.$emit('focus', value);
    },

    onPaste(event) {
      if (this.readonly) {
        event.preventDefault();
        return false;
      }

      if (this.vuelidateOptions) {
        this.vuelidateOptions.$touch();
      }

      this.$emit('paste', event);
      return true;
    },

    changeValue(value) {
      if (this.readonly) {
        event.preventDefault();
        return;
      }
      this.$emit('change', value);
    },

    updateValue(value) {
      if (this.readonly) {
        event.preventDefault();
        return;
      }

      if ((this.vuelidateOptions && this.vuelidateOptions.$params)
        && (this.vuelidateOptions.$params.maxLength)
        && (value.length > this.vuelidateOptions.$params.maxLength.max)) {
        this.vuelidateOptions.$touch();
      }

      this.$emit('input', value);
    },
  },
};
