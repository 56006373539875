const mixin = {
  computed: {
    isCypressCI() {
      return !!window.Cypress;
    },
  },
};

export default {
  install(Vue) {
    Vue.mixin(mixin);
  },
};
