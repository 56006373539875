/* eslint-disable no-unused-vars */
import { REQUESTER_TYPES } from '@/utils/constants/spacePermissions';

// Note: teams aren't currently supported
// companies -> teams -> users (order is extremely important)
const REQUESTER_TYPES_IN_ORDER = Object.freeze([
  REQUESTER_TYPES.COMPANY,
  // REQUESTER_TYPES.TEAM,
  REQUESTER_TYPES.USER,
]);

function getPropertyName(entityType) {
  if (entityType === REQUESTER_TYPES.COMPANY) {
    return 'company';
  }

  if (entityType === REQUESTER_TYPES.TEAM) {
    return 'team';
  }

  if (entityType === REQUESTER_TYPES.USER) {
    return 'user';
  }

  return null;
}

function getPermissionsGroupedByRequesterType(permissions, users) {
  const results = {};

  if (!Array.isArray(permissions) || !permissions.length) {
    return results;
  }

  Object.values(REQUESTER_TYPES).forEach((requesterType) => {
    results[requesterType] = new Map();
  });

  permissions.forEach((permission) => {
    const requesterType = permission.requester.type;

    if (!requesterType) {
      return;
    }

    if (!Object.prototype.hasOwnProperty.call(permissions, requesterType)) {
      results[requesterType] = new Map();
    }

    // Special case - admins must be excluded (unless they have a defined permission
    // level i.e. they created the Space)
    if (requesterType === REQUESTER_TYPES.USER) {
      const user = users.get(permission.requester.id);
      if (!user || (user.isAdmin && !permission.requester.meta.level)) {
        return;
      }
    }

    results[requesterType].set(permission.requester.id, permission);
  });

  return results;
}

function createSpaceMembersMap(permissions, requesterType, entities, results) {
  if (!permissions || !permissions[requesterType]) {
    return new Map();
  }

  const explicitPermissionIds = new Set(permissions[requesterType].keys());

  // Check for higher level permissions
  const types = [...REQUESTER_TYPES_IN_ORDER].reverse();
  const index = types.indexOf(requesterType);
  const higherTypes = types.slice(index + 1);

  function hasInteritedPermission(entity) {
    return higherTypes.reduce((result, type) => {
      if (result) {
        return result;
      }

      if (!results[type]) {
        return result;
      }

      const property = getPropertyName(type);

      if (!property || !entity[property] || !entity[property].id) {
        return result;
      }

      // eslint-disable-next-line no-param-reassign
      result = results[type].get(entity[property].id);

      return result;
    }, false);
  }

  return entities.reduce((result, entity) => {
    if (explicitPermissionIds.has(Number(entity.id))) {
      result.set(Number(entity.id), entity);
      return result;
    }

    if (hasInteritedPermission(entity)) {
      result.set(Number(entity.id), entity);
      return result;
    }

    // Special case: admins always have automatic access to all Spaces
    if (requesterType === REQUESTER_TYPES.USER) {
      if (entity.isAdmin) {
        result.set(Number(entity.id), entity);
        return result;
      }
    }

    return result;
  }, new Map());
}

export default function getResolvedSpaceMembers(
  _companies = new Map(),
  _teams = new Map(),
  _users = new Map(),
  _permissions = [],
) {
  const permissions = getPermissionsGroupedByRequesterType(_permissions, _users);

  function getEntities(entityType) {
    if (entityType === REQUESTER_TYPES.COMPANY) {
      return Array.from(_companies.values());
    }

    if (entityType === REQUESTER_TYPES.TEAMS) {
      return Array.from(_teams.values());
    }

    if (entityType === REQUESTER_TYPES.USER) {
      return Array.from(_users.values());
    }

    return [];
  }

  return REQUESTER_TYPES_IN_ORDER.reduce((result, requesterType) => {
    // eslint-disable-next-line no-param-reassign
    result[requesterType] = createSpaceMembersMap(permissions, requesterType, getEntities(requesterType), result);

    return result;
  }, {});
}
