const global = 'global';
const page = 'page';
const section = 'section';
const component = 'component';

export default {
  global,
  page,
  section,
  component,
};
