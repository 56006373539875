export default {
  namespaced: true,
  state: {
    isSearchFullWidth: false,
  },
  getters: {
    isSearchFullWidth: (state) => state.isSearchFullWidth,
  },
  mutations: {
    setSearchFullWidth: (state, isSearchFullWidth) => {
      state.isSearchFullWidth = !!isSearchFullWidth;
    },
  },
  actions: {
    setSearchFullWidth: ({ commit }, isSearchFullWidth) => {
      commit('setSearchFullWidth', !!isSearchFullWidth);
    },
  },
};
