import Vue from 'vue';
import { isPlainObject } from 'lodash-es';

import loadingStates from '@/utils/constants/loadingStates';
import CHECKOUT_CODES from '@/utils/constants/checkoutCodes';
import { ROUTE_NAMES } from '@/router/routes';
import { PERMISSION_LEVELS } from '@/utils/constants/spacePermissions';
import isAppCompatibleBrowser from '@/utils/helpers/isAppCompatibleBrowser';
import isPDFExporterAgent from '@/utils/helpers/isPDFExporterAgent';

import router from '@/router';

import activeEditor from './activeEditor';
import appHeader from './appHeader';
import editorComponents from './editorComponents';
import embeddedChat from './embeddedChat';
import formState from './formState';
import home from './home';
import inlineComments from './inlineComments';
import links from './links';
import people from './people';
import popover from './popover';
import pubbleLiveChat from './pubbleLiveChat';
import sharedSpacePage from './sharedSpacePage';
import sidebar from './sidebar';
import spacePage from './spacePage';
import spacesEditor from './spacesEditor';
import tooltip from './tooltip';

export default {
  modules: {
    activeEditor,
    appHeader,
    editorComponents,
    embeddedChat,
    formState,
    home,
    inlineComments,
    links,
    people,
    popover,
    pubbleLiveChat,
    sharedSpacePage,
    sidebar,
    spacePage,
    spacesEditor,
    tooltip,
  },
  namespaced: true,
  state: {
    isPreloading: true,
    isLoading: {},
    errors: {},
    newVersionAvailable: false,
    isMobileViewport: false,
  },
  mutations: {
    setPreloadState(state, isPreloading) {
      state.isPreloading = isPreloading;
    },

    setLoading(state, { type, isLoading }) {
      const newState = state;

      newState.isLoading = isPlainObject(newState.isLoading) ? newState.isLoading : {};

      Vue.set(newState.isLoading, type, isLoading);
    },

    setLoadError(state, { type, error }) {
      Vue.set(state.errors, type, error || null);
    },

    setNewVersionAvailable(state, newVersionAvailable) {
      state.newVersionAvailable = newVersionAvailable;
    },

    setIsMobileViewport(state, isMobileViewport) {
      state.isMobileViewport = isMobileViewport;
    },
  },
  getters: {
    isPreloading(state) {
      return !!state.isPreloading;
    },

    isLoading(state) {
      return (type) => !!(state && state.isLoading && state.isLoading[type]);
    },

    getError(state) {
      return (type) => (state && state.errors && state.errors[type]) || null;
    },

    hasError(state) {
      return (type) => !!(state && state.errors && state.errors[type]);
    },

    isLoadingGlobal(state, getters) {
      return getters.isLoading(loadingStates.global);
    },

    isLoadingPage(state, getters) {
      return getters.isLoading(loadingStates.page);
    },

    isLoadingSection(state, getters) {
      return getters.isLoading(loadingStates.section);
    },

    hasGlobalError(state, getters) {
      return getters.hasError(loadingStates.global);
    },

    hasPageError(state, getters) {
      return getters.hasError(loadingStates.page);
    },

    hasSectionError(state, getters) {
      return getters.hasError(loadingStates.section);
    },

    shouldRenderEditor(state, getters, rootState, rootGetters) {
      // Don't render editor when page is opened with PDF Exporter
      if (isPDFExporterAgent()) {
        return false;
      }

      if (rootGetters['navigation/activeSpacePermission'] === PERMISSION_LEVELS.READ) {
        return (!rootGetters['loading/spacePageError']
          && rootGetters['navigation/activePage']
          && rootGetters['navigation/activePage'].isPublished
          && rootGetters['ui/spacePage/allowInlineCommentsInReadMode']
          && !rootGetters['navigation/isFlushingEditorOnPageCodeChange']
          && isAppCompatibleBrowser());
      }

      return (
        (
          rootGetters['navigation/activeSpacePermission'] === PERMISSION_LEVELS.MANAGE
          || rootGetters['navigation/activeSpacePermission'] === PERMISSION_LEVELS.EDIT
        )
        && rootGetters['navigation/activePage']
        && !rootGetters['navigation/isFlushingEditorOnPageCodeChange']
        && isAppCompatibleBrowser()
      );
    },

    shouldRenderInlineCommentsGutter(state, getters) {
      return !getters.isPreloading && getters.shouldRenderEditor;
    },

    shouldShowCommentsGutter(state, getters, rootState, rootGetters) {
      return rootGetters['ui/inlineComments/shouldShowCommentsGutter']
        && !rootGetters['navigation/isPluginFullScreen']
        && (
          !rootGetters['navigation/isEditingSpacePage']
          || (rootGetters['navigation/isEditingSpacePage'] && !rootState.ui.spacesEditor.isLoadingInitialContent)
        );
    },

    shouldShowAppHeader(state, getters, rootState, rootGetters) {
      return !!(
        rootGetters['session/isAuthenticated']
        && !rootGetters['ui/isLoading']('global')
        && !(
          rootState
          && rootState.route
          && rootState.route.name
          && rootState.route.name.startsWith('onboarding')
        )
        && !rootGetters['navigation/isPluginFullScreen']
        && !rootGetters['navigation/isEditingSpacePage']
        && !(rootState.navigation && rootState.navigation.isEmbedded)
      );
    },

    shouldShowAppBody(state, getters, rootState, rootGetters) {
      // ensure this gets re-evalutated on every route change
      const currentRoute = rootState.route;
      const isOnPeopleRoute = router.currentRoute.matched.find((route) => route.name === ROUTE_NAMES.PEOPLE);

      return !!(
        currentRoute
        && rootGetters['session/isAuthenticated']
        && !rootGetters['ui/isLoading']('global')
        && (
          !rootGetters['installation/isLocked']
          || (
            isOnPeopleRoute
            && [
              CHECKOUT_CODES.SUBSCRIPTION_EXPIRED,
              CHECKOUT_CODES.TRIAL_EXPIRED,
              CHECKOUT_CODES.OVERSUBSCRIBED,
            ].indexOf(rootGetters['installation/checkoutErrorCode']) > -1
          )
        )
      );
    },

    shouldShowOversubscribedBanner(state, getters, rootState, rootGetters) {
      const isOnPeopleRoute = router.currentRoute.matched.find((route) => route.name === ROUTE_NAMES.PEOPLE);

      return !!(rootGetters['installation/isLocked'] && isOnPeopleRoute);
    },

    shouldShowNewVersionBanner(state) {
      return !!state.newVersionAvailable;
    },

    shouldModalContainerObscureAppHeader(state, getters, rootState, rootGetters) {
      return !(rootGetters['installation/isLocked'] && rootGetters['ui/shouldShowAppHeader']);
    },

    shouldShowSidebar(state, getters, rootState, rootGetters) {
      return !rootGetters['navigation/isPluginFullScreen'];
    },

    shouldShowHelpMenuButton(state, getters, rootState, rootGetters) {
      return !rootGetters['navigation/isPluginFullScreen'] && !rootState.ui.inlineComments.shouldShowCommentsGutter;
    },

    isMobileViewport(state) {
      return state.isMobileViewport;
    },
  },
  actions: {
    startLoading({ commit }, type) {
      commit('clearLoadError', type);
      commit('setLoading', { type, isLoading: true });
    },

    stopLoading({ commit }, type) {
      commit('setLoading', { type, isLoading: false });
    },

    setLoadError({ commit }, { type, error }) {
      commit('setLoadError', { type, error });
    },

    clearLoadError({ commit }, type) {
      commit('setLoadError', { type, error: null });
    },

    startGlobalLoad({ commit }) {
      commit('setLoading', { type: loadingStates.global, isLoading: true });
    },

    stopGlobalLoad({ commit }) {
      commit('setLoading', { type: loadingStates.global, isLoading: false });
    },

    startPageLoad({ commit }) {
      commit('setLoading', { type: loadingStates.page, isLoading: true });
    },

    stopPageLoad({ commit }) {
      commit('setLoading', { type: loadingStates.page, isLoading: false });
    },

    startSectionLoad({ commit }) {
      commit('setLoading', { type: loadingStates.section, isLoading: true });
    },

    stopSectionLoad({ commit }) {
      commit('setLoading', { type: loadingStates.section, isLoading: false });
    },
  },
};
