import { forEach, includes, isObject, isString } from 'lodash-es';

import isValidId from '@/utils/helpers/isValidId';
import supportedIncludedFields from './supportedIncludedFields';

/* eslint-disable no-param-reassign */

/**
 * Used to de-populate detected 'included' fields in preparation of a request to the Teamwork
 * Unified API
 *
 * @param {Object} data
 */
export default (data) => {
  const handleIncludedFieldsForObject = (o) => {
    forEach(o, (v, k) => {
      if (
        isObject(v)
        && isString(v.type)
        && includes(supportedIncludedFields, v.type)
        && isValidId(v.id)
      ) {
        o[k] = {
          type: v.type,
          id: v.id,
          meta: v.meta,
        };
      }
    });

    return o;
  };

  if (Array.isArray(data)) {
    forEach(data, (v, i) => {
      data[i] = handleIncludedFieldsForObject(v);
    });
  } else if (isObject(data)) {
    data = handleIncludedFieldsForObject(data);
  } else {
    throw new Error('Cannot handle included fields, response.data is not an Array or Object');
  }

  return data;
};
