import { isString } from 'lodash-es';

import { createStaticCrudModule } from '@/utils/vuex';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';

export default createStaticCrudModule({
  only: ['FETCH'],
  property: 'diff',
  customUrlFn(spaceId, pageId, oldVersion, newVersion) {
    return buildApiUrl({
      resource: `/spaces/${spaceId}/pages/${pageId}/diff/${oldVersion}/${newVersion}.json`,
      apiVersion: 'v1',
    });
  },
  getters: {
    oldVersionNumber(state, getters) {
      return Array.isArray(getters.get)
        && getters.get[0]
        && getters.get[0].version;
    },

    newVersionNumber(state, getters) {
      return Array.isArray(getters.get)
        && getters.get[1]
        && getters.get[1].version;
    },

    oldContent(state, getters) {
      return Array.isArray(getters.get)
        && getters.get[0]
        && getters.get[0].content;
    },

    newContent(state, getters) {
      return Array.isArray(getters.get)
        && getters.get[1]
        && getters.get[1].content;
    },

    hasNoDiscernableChanges(state, getters) {
      if (!isString(getters.newContent)) {
        return true;
      }

      const changes = getters.newContent.match(/(vdd-added|vdd-removed|vdd-modified)/);
      return !Array.isArray(changes) || !changes.length;
    },
  },
});
