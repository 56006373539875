import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import autosize from 'autosize';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';

import FormError from '@widgets/Form/FormError';

import { isIE } from '@/utils/helpers/device';

// @vue/component
export default {
  props: {
    titleMaxLength: {
      type: Number,
      required: true,
    },
    locked: {
      type: Boolean,
      default: false,
      required: false,
    },
    initialTitle: {
      type: String,
      default: '',
      required: false,
    },
    placeholder: {
      type: String,
      default: 'Give me a title',
      required: false,
    },
    shouldScrollIntoView: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  components: {
    FormError,
  },

  computed: {
    ...mapState('ui/spacesEditor', {
      titleError: 'titleError',
      editorTitle: 'title',
      toolbarDisabled: 'toolbarDisabled',
      isFullWidth: 'isFullWidth',
    }),

    ...mapGetters('navigation', [
      'isEditingSpacePage',
    ]),

    title: {
      get() {
        return this.editorTitle;
      },
      set(title) {
        this.setTitle(title);
      },
    },
  },

  methods: {
    ...mapActions('ui/spacesEditor', ['validateTitle']),

    ...mapMutations('ui/spacesEditor', [
      'setTitle',
      'resetValidation',
      'setToolbarAsDisabled',
    ]),

    onTitleInput() {
      this.resetValidation();
      this.setTitle(this.title);
      this.validateTitle({ maxLength: this.titleMaxLength });
    },

    scrollToPageTitle() {
      scrollIntoViewIfNeeded(document.querySelector('.s-spaces-editor-title'), {
        scrollMode: 'if-needed',
        behavior: 'smooth',
        block: 'end',
      });
    },

    resizeTitle() {
      this.$nextTick(() => {
        autosize.update(this.$refs.title);
      });
    },

    disableToolbar() {
      if (!this.toolbarDisabled) {
        this.setToolbarAsDisabled(true);
      }
    },

    enableToolbar(event) {
      // we can't determine what was clicked in IE
      if (isIE()) {
        this.setToolbarAsDisabled(false);
        return;
      }

      // don't enable toolbar if there is no related target
      if (!event.relatedTarget) {
        this.$refs.title.focus();
        return;
      }

      // if related target is color selection, don't enable toolbar
      if (
        event.relatedTarget
        && event.relatedTarget.hasAttribute('class')
        && event.relatedTarget.getAttribute('class') === 'color-select-icon'
      ) {
        this.$refs.title.focus();
      }
    },
    emitFocusEdtior() {
      this.$emit('focus-editor');
    },
  },

  mounted() {
    this.title = this.initialTitle;
    autosize(this.$refs.title);
  },

  updated() {
    this.$nextTick(() => {
      this.resizeTitle();
    });
  },

  beforeDestroy() {
    this.resetValidation();
  },

  watch: {
    isEditingSpacePage() {
      if (this.isEditingSpacePage) {
        this.$nextTick(() => {
          // need to trigger autosize in edit mode
          // as it doesn't work correctly on hidden elements
          this.resizeTitle();
        });
      }
    },
    initialTitle(val) {
      this.setTitle(val);
    },
    shouldScrollIntoView(val) {
      if (val) {
        this.scrollToPageTitle();
        this.$emit('update:should-scroll-into-view', false);
      }
    },
  },
};
