import replaceAccents from './replaceAccents';

/* eslint-disable no-param-reassign */

export default (s) => {
  s = s.toString()
    .toLowerCase();

  // Replace accents with non-accented ASCII alternatives
  s = replaceAccents(s);

  return s
    .replace(/[·/_,:;]/g, '-')
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/&/g, '-and-') // Replace & with ‘and’
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};
