
// @vue/component
export default {
  props: {
    delaySpinner: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      delayLoader: false,
    };
  },
  created() {
    setTimeout(() => {
      this.delayLoader = true;
    }, this.delaySpinner);
  },
};
