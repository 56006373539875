/* eslint-disable no-console */
import { raiseSentryError } from '@/utils/helpers/sentryHelper';
import { isDevEnvironment, isSunbeam, isBetaEnvironment } from './environment.utility';

const devConsoleFontStyles = 'font-size: 14px; font-weight: bold;';
const devConsoleStyleLookup = {
  error: `${devConsoleFontStyles}`,
  info: `${devConsoleFontStyles} color: blue`,
  success: `${devConsoleFontStyles} color: green`,
  warning: `${devConsoleFontStyles}`,
};

const getFormattedMessages = (originalMessages, formatting) => {
  const addFormattingToText = (formattedMessages, message) => {
    if (typeof message === 'string') {
      return [...formattedMessages, `%c${message}`, formatting];
    }
    return [...formattedMessages, message];
  };
  return originalMessages.reduce(addFormattingToText, []);
};

const logDevConsoleError = (args) => {
  if (!isDevEnvironment && !isSunbeam && !isBetaEnvironment(window.location.host, document.cookie)) { return; }
  console.error(...getFormattedMessages(args, devConsoleStyleLookup.error));
};

const logDevConsoleInfo = (args) => {
  if (!isDevEnvironment && !isSunbeam && !isBetaEnvironment(window.location.host, document.cookie)) { return; }
  console.info(...getFormattedMessages(args, devConsoleStyleLookup.info));
};

const logDevConsoleSuccess = (args) => {
  if (!isDevEnvironment && !isSunbeam && !isBetaEnvironment(window.location.host, document.cookie)) { return; }
  console.info(...getFormattedMessages(args, devConsoleStyleLookup.success));
};

const logDevConsoleWarning = (args) => {
  if (!isDevEnvironment && !isSunbeam && !isBetaEnvironment(window.location.host, document.cookie)) { return; }
  console.warn(...getFormattedMessages(args, devConsoleStyleLookup.warning));
};

/**
 * Logs error to console and sentry.
 * Will not log to sentry if first argument has response property.
 * @param args
 */
const logError = (...args) => {
  logDevConsoleError(args);
  // log any non http errors to sentry
  if (args.length) {
    raiseSentryError(args[0]);
  }
};

const logInfo = (...args) => {
  logDevConsoleInfo(args);
};

const logSuccess = (...args) => {
  logDevConsoleSuccess(args);
};

const logWarning = (...args) => {
  logDevConsoleWarning(args);
};

export {
  logError,
  logInfo,
  logSuccess,
  logWarning,
};
