export default {
  namespaced: true,
  state: {
    isConversationOpen: false,
    isConversationListOpen: false,
  },
  getters: {
    isEmbeddedChatOpen(state) {
      return state.isConversationOpen || state.isConversationListOpen;
    },
  },
  mutations: {
    setIsConversationListOpen(state, isOpen) {
      state.isConversationListOpen = isOpen;
    },
    setIsConversationOpen(state, isOpen) {
      state.isConversationOpen = isOpen;
    },
  },
};
