import socketIO from 'socket.io-client';

import { logError } from '@/utils/helpers/logger.utility';

const socketIOConfig = {
  timeout: 1000,
  reconnection: true,
  reconnectionAttempts: 100,
  reconnectionDelay: 500,
  reconnectionDelayMax: 2000,
  forceNew: true,
  transports: ['websocket'],
};

let socketIOInstance = null;

const getSocketIOInstance = () => socketIOInstance;

const setSocketIOInstance = (newSocketIOInstance) => {
  socketIOInstance = newSocketIOInstance;
};

const createSocketIOInstance = (address) => {
  if (getSocketIOInstance()) {
    return;
  }

  try {
    const newSocketIOInstance = socketIO(address, socketIOConfig);
    setSocketIOInstance(newSocketIOInstance);
  } catch (error) {
    logError(`Socket: SocketIO error ${error}`);
  }
};

export {
  getSocketIOInstance,
  createSocketIOInstance,
};
