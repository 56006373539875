import Vue from 'vue';
import { get, assignIn, clone, isUndefined, omit, pick } from 'lodash-es';

import { createCrudModule } from '@/utils/vuex';
import defaultClient from '@/utils/vuex/client';
import fullName from '@/utils/filters/fullName';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import alphabeticalSort from '@/utils/helpers/alphabeticalSort';
import isValidId from '@/utils/helpers/isValidId';
import RESOURCES from '@/utils/constants/resources';
import store from '@/store';
import USER_STATES from '@/utils/constants/userStates';

export function filterUsers(users, query = '') {
  if (!Array.isArray(users)) {
    return [];
  }

  // eslint-disable-next-line no-param-reassign
  query = query.toLowerCase();

  return users
    .filter((user) => {
      const company = store.getters['companies/byId'](user.company.id);

      if (
        fullName(user).toLowerCase().indexOf(query) !== -1
        || user.email.indexOf(query) !== -1
        || (company && company.name && company.name.toLowerCase().indexOf(query) !== -1)
        || (query === 'me' && Number(user.id) === Number(store.getters['session/userId']))
      ) {
        return true;
      }

      return false;
    });
}

export function sortUsers(users, order, descending = false) {
  if (!Array.isArray(users)) {
    return [];
  }

  return users
    .sort((a, b) => {
      if (!order) {
        return 0;
      }

      if (order === 'company') {
        const cA = store.getters['companies/byId'](a.company.id);
        const cB = store.getters['companies/byId'](b.company.id);

        if (cA && cA.name && cB && cB.name) {
          return alphabeticalSort(cA.name.toLowerCase(), cB.name.toLowerCase(), descending);
        }

        return 0;
      }

      return alphabeticalSort(a[order].toLowerCase(), b[order].toLowerCase(), descending);
    });
}

export function filterAndSortUsers(users, query, sortOrder) {
  if (!Array.isArray(users)) {
    return [];
  }

  return sortUsers(filterUsers(users, query), sortOrder);
}

/* eslint-disable quote-props */
const projectsToSpacesKeyMap = {
  'first-name': 'firstname',
  'last-name': 'lastname',
  'email-address': 'email',
  'id': 'id',
  'company-id': 'company',
};
/* eslint-disable quote-props */

const spacesToProjectsKeyMap = {};
Object.keys(projectsToSpacesKeyMap).forEach((key) => {
  if (Object.prototype.hasOwnProperty.call(projectsToSpacesKeyMap, key)) {
    spacesToProjectsKeyMap[projectsToSpacesKeyMap[key]] = key;
  }
});

const transformData = (data) => {
  const newData = {};

  Object.keys(data).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(spacesToProjectsKeyMap, key)) {
      if (key === 'company' && data[key].id) {
        newData[spacesToProjectsKeyMap[key]] = data[key].id;
        return;
      }

      newData[spacesToProjectsKeyMap[key]] = data[key];
      return;
    }

    newData[key] = data[key];
  });

  return newData;
};

/*
 *  Not all data passed to Teamwork (Projects) API should be persisted in our store
 */
const filterData = (data) => omit(data, ['password', 'currentPassword']);

const createUserRequest = ({ payload, config }) => defaultClient.post(
  buildApiUrl({ resource: 'users/bulk/invite' }),
  payload,
  config,
)
  .catch((error) => {
    const returnedError = get(error, 'response.data.MESSAGE');

    if (returnedError) {
      return Promise.reject(returnedError);
    }

    return Promise.reject(error);
  });

/*
 * Note: CREATE, REPLACE, DESTROY actions are proxied to Teamwork (Projects) API and therefore
 * their actions must be overridden in this module
 */

/* eslint-disable no-param-reassign,arrow-body-style */

export default createCrudModule({
  only: ['FETCH_LIST', 'CREATE', 'REPLACE', 'UPDATE', 'DESTROY'],
  resource: RESOURCES.USER,
  singleProperty: RESOURCES.USER,
  listProperty: RESOURCES.USER_PLURAL,
  getters: {
    admins(state, getters) {
      return getters.list.filter((user) => {
        return user.isAdmin;
      });
    },

    getUsersForCompany(state, getters) {
      return (companyId) => {
        return getters.list.filter((user) => {
          return user.company.id === companyId;
        });
      };
    },

    // Used externally by the timeline widget when searching for users to add to a timeline item
    byFullnameOrEmailOrCompany(state, getters, rootState, rootGetters) {
      return (query, order, descending) => Object.values(state.entities).filter((u) => {
        const fullname = fullName(u);
        const queryLC = query.toLowerCase();
        const company = rootGetters['companies/byId'](u.company.id);

        if (fullname.toLowerCase().indexOf(queryLC) !== -1
          || u.email.indexOf(queryLC) !== -1
          || (company && company.name && company.name.toLowerCase().indexOf(queryLC) !== -1)
          || (query === 'me' && u.id === rootGetters['session/userId'])
        ) {
          return true;
        }

        return false;
      }).sort((a, b) => {
        if (!order) {
          return 0;
        }

        if (order === 'company') {
          const cA = rootGetters['companies/byId'](a.company.id);
          const cB = rootGetters['companies/byId'](b.company.id);

          if (cA && cA.name && cB && cB.name) {
            return alphabeticalSort(cA.name.toLowerCase(), cB.name.toLowerCase(), descending);
          }

          return 0;
        }

        return alphabeticalSort(a[order].toLowerCase(), b[order].toLowerCase(), descending);
      });
    },

    activeUsers(state, getters) {
      return getters.list.filter((user) => {
        return user.state === USER_STATES.ACTIVE;
      });
    },

    activeUsersCount(state, getters) {
      return getters.activeUsers.length;
    },

    inactiveUsers(state, getters) {
      return getters.list.filter((user) => {
        return user.state === USER_STATES.INACTIVE;
      });
    },

    inactiveUsersCount(state, getters) {
      return getters.inactiveUsers.length;
    },

    invitedUsers(state, getters) {
      return getters.list.filter((user) => {
        return user.state === USER_STATES.INVITED;
      });
    },

    invitedUsersCount(state, getters) {
      return getters.invitedUsers.length;
    },
  },
  mutations: {
    bulkReplaceSuccess(state, payload) {
      const { params } = payload;
      const { response } = params;
      const { data } = response;

      data[RESOURCES.USER_PLURAL].forEach((user) => {
        Vue.set(state.entities, user.id, user);
      });
    },

    changeAvatarUrlTemporarily(state, { id, url }) {
      if (state.entities && state.entities[id]) {
        const currentUser = store.getters['session/user'];

        if (currentUser.id === id) {
          currentUser.avatar = url;
          store.dispatch('session/updateUserAvatarUrl', url);
        }

        state.entities[id].avatar = url;
      }
    },

    updateUserState(state, { id, newState }) {
      if (state.entities && state.entities[id]) {
        state.entities[id].state = newState;
      }
    },

    removeUser(state, id) {
      if (!state.entities[id]) {
        return;
      }

      Vue.delete(state.entities, id);
    },
  },
  actions: {
    // Invite users providing email addresses only.
    invite({ commit, dispatch }, {
      data,
      config = {},
    } = {}) {
      commit('createStart', { params: { data } });

      // TODO: Adjust this logic with company when API is ready for it
      const payload = {
        invites: data.emails.map((email) => ({ email })),
      };

      return createUserRequest({ payload, config })
        .then(() => {
          dispatch('onboarding/resetInviteEmails', {}, { root: true });

          setTimeout(() => {
            dispatch('fetchList');
          }, 500);
        })
        .catch((error) => {
          commit('createError', error);
          return Promise.reject(error);
        });
    },

    /**
     * POST /api/v<apiVersionNumber>/<resourceName>
     * Create a new reource
     */
    create({ commit }, {
      data,
      config = {},
    } = {}) {
      commit('createStart', { params: { data } });

      // request is passed to launchpad
      const payload = {
        invites: [{
          firstName: data.firstname,
          lastName: data.lastname,
          email: data.email,
        }],
      };

      return createUserRequest({ payload, config })
        .then(() => {
          if (Vue.$ga) {
            Vue.$ga.event({
              eventCategory: 'user',
              eventAction: 'invited',
              eventLabel: 'User has been invited to join Spaces',
              eventValue: 1,
            });
          }
        })
        .catch((error) => {
          commit('createError', error);
          return Promise.reject(error);
        });
    },

    /**
     * PUT /api/v<apiVersionNumber>/<resourceName>/:id
     * Update a single resource
     */
    replace({ commit }, {
      id,
      data,
      config = {},
      isOptimistic = true,
    } = {}) {
      const vm = this;

      const payload = {
        person: transformData(data),
      };

      const url = buildApiUrl({ resource: `users/${id}` });

      // for removing avatar just call API and update avatar in store without going throught
      // our CRUD module commit logic
      if (Object.prototype.hasOwnProperty.call(data, 'removeAvatar') && data.removeAvatar) {
        return defaultClient.put(
          url,
          payload,
          config,
        ).then(() => {
          commit('changeAvatarUrlTemporarily', { id, url: '' });
        });
      }

      commit('replaceStart', { params: { id, data }, options: { isOptimistic } });

      return defaultClient.put(
        url,
        payload,
        config,
      )
        .then(() => {
          // Note: avoiding a re-fetch by directly modifying the value in the store
          const newUser = assignIn(clone(vm.getters['users/byId'](id) || {}), filterData(data));

          commit('replaceSuccess',
            { params: { response: { data: newUser } }, options: { isOptimistic } });

          return newUser;
        })
        .catch((error) => {
          const returnedError = get(error, 'response.data.MESSAGE');

          if (returnedError) {
            error = returnedError;
          }

          commit('replaceError', error);

          return Promise.reject(error);
        });
    },

    /**
     * DELETE /api/v<apiVersionNumber>/<resourceName>/:id
     * Destroy a single resource
     */
    destroy({ commit }, {
      id,
      config = {},
    } = {}) {
      commit('destroyStart', { params: { id } });

      return defaultClient.delete(
        buildApiUrl({ resource: `users/${id}` }),
        config,
      )
        .then((response) => {
          commit('destroySuccess', { params: { id } });

          return response;
        })
        .catch((error) => {
          const returnedError = get(error, 'response.data.MESSAGE');

          if (returnedError) {
            error = returnedError;
          }

          commit('destroyError', error);

          return Promise.reject(error);
        });
    },

    /*
     * Business logic:
     *  - Bulk action can only be executed by an admin
     *  - Only the 'enabled' state can be changed
     *  - The 'enabled' state cannot be changed for:
     *    - themselves
     *    - 'invited' users
     */
    async bulkUpdate({ commit, rootGetters }, {
      data,
      config = {},
    } = {}) {
      const validProperties = ['enabled'];

      (function validatePayload() {
        if (!Array.isArray(data)) {
          throw new Error('Store action \'bulkUpdate\' expects an Array of user data');
        }

        const currentUserId = rootGetters['session/user'].id;

        // Create a map to check for duplicate user entries
        const idMap = data.reduce((result, user) => {
          if (isUndefined(result[Number(user.id)])) {
            result[Number(user.id)] = 0;
          }

          result[Number(user.id)] += 1;
          return result;
        }, {});

        data.forEach((datum, index) => {
          // Check for duplicate user entries
          if (idMap[Number(datum.id)] > 1) {
            throw new Error(`Store action 'bulkUpdate' was passed invalid data - more than one entry found for user (ID: ${datum.id})`);
          }

          // Check for valid ID
          if (!isValidId(datum.id)) {
            throw new Error(`Store action 'bulkUpdate' was passed invalid data - entry at index ${index} does not have a valid 'id' property`);
          }

          // Business logic - cannot change the state of the current user
          if (Number(datum.id) === Number(currentUserId)) {
            throw new Error(`Store action 'bulkUpdate' was passed invalid data - entry at index ${index} refers to the current user`);
          }

          // Check the user actually exists
          const targetUser = rootGetters['users/byId'](Number(datum.id));
          if (!targetUser) {
            throw new Error(`Store action 'bulkUpdate' was passed invalid data - associated user for entry at index ${index} does not exist in the 'users' store module`);
          }

          // Business logic - cannot change the state of an 'invited' user
          if (targetUser.state === USER_STATES.INVITED) {
            throw new Error(`Store action 'bulkUpdate' was passed invalid data - associated user for entry at index ${index} has an '${USER_STATES.INVITED}' state`);
          }

          // Check for valid update properties (apart from ID)
          if (!Object.keys(datum).some((k) => validProperties.includes(k))) {
            throw new Error(`Store action 'bulkUpdate' was passed invalid data - entry at index ${index} does not contain any valid properties i.e. '${validProperties.join('\', \'')}'`);
          }
        });
      }());

      function getPayload() {
        // Ensure we're only passing the supported properties for each user entry and wrap the
        // payload data with the 'listProperty' for the module
        return {
          [RESOURCES.USER_PLURAL]: data.reduce((result, user, index) => {
            result[index] = pick(user, ['id', ...validProperties]);
            return result;
          }, []),
        };
      }

      try {
        const response = await defaultClient.patch(
          buildApiUrl({ resource: 'users' }),
          getPayload(),
          config,
        );

        commit('bulkReplaceSuccess', { params: { response } });

        return response;
      } catch (error) {
        return Promise.reject(error);
      }
    },

    updateUserState({ commit }, payload) {
      commit('updateUserState', payload);
    },

    /**
     * Change avatar in store only, app reload will set avatar url correctly again
     */
    changeAvatarUrlTemporarily({ commit }, payload) {
      commit('changeAvatarUrlTemporarily', payload);
    },

    /**
     * We need to remove users entities from store when company that
     * that they are part of gets deleted
     */
    removeUserEntitiesByCompany({ commit, state }, companyId) {
      if (!companyId) {
        return;
      }

      const usersToRemove = Object.values(
        state.entities).filter((e) => e.company.id.toString() === companyId.toString(),
      );

      if (usersToRemove.length === 0) {
        return;
      }

      usersToRemove.forEach((user) => commit('removeUser', user.id.toString()));
    },
  },
});
