import { mapActions, mapState } from 'vuex';

import { isEventKeyEqualTo } from '@/utils/helpers/keyboardEvents';
import { enterKey } from '@/utils/constants/keyboardKeys';

// @vue/component
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    shouldPromptBeforeDiscard: {
      type: Boolean,
      default: true,
    },
    variant: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      initFormFields: {},
      customFieldsChanged: [],
    };
  },
  computed: {
    ...mapState('ui/formState', ['isAmended']),
  },
  methods: {
    ...mapActions('ui/formState', {
      setFormAmendmentState: 'amend',
    }),

    handleEnter(event) {
      if (event.metaKey && isEventKeyEqualTo(event, enterKey)) {
        this.handleSubmit(event);
        event.stopPropagation();
      }
    },

    handleSubmit(event) {
      this.$emit('submit', event);

      if (this.shouldPromptBeforeDiscard && this.isAmended) {
        this.setFormAmendmentState(false);
      }
    },

    initializeFormFields() {
      const blackListedClasses = ['u-hide--accessibly', 'multiselect__input'];
      const fields = this.$el.querySelectorAll('input, textarea');
      [...fields].forEach((field, index) => {
        if (!blackListedClasses.includes(field.className)) {
          const initValue = this.getFieldValue(field);
          if (!field.id) {
            const time = new Date().getTime();
            /* eslint-disable-next-line no-param-reassign */
            field.id = `${time}${index}`;
          }

          const initFormField = {
            init: initValue,
            type: field.type,
            name: field.name,
            isChanged: false,
          };

          this.$set(this.initFormFields, field.id, initFormField);
        }
      });
    },

    getFieldValue(element) {
      switch (element.type) {
        case 'checkbox':
        case 'radio':
          return element.checked;
        default:
          return element.value;
      }
    },

    handleChange(event) {
      if (!this.initFormFields[event.target.id]) {
        return;
      }

      const eventValue = this.getFieldValue(event.target);
      if (eventValue !== this.initFormFields[event.target.id].init) {
        if (event.target.type === 'radio') {
          // set all the radio buttons from the same group as changed
          Object.keys(this.initFormFields)
            .filter((key) => event.target.name === this.initFormFields[key].name)
            .forEach((key) => { this.initFormFields[key].isChanged = true; });
        } else {
          this.initFormFields[event.target.id].isChanged = true;
        }

        if (!this.isAmended) {
          this.setFormAmendmentState(true);
        }

        return;
      }

      // back to initial state
      this.initFormFields[event.target.id].isChanged = false;

      // check if radio group has been returned to its init state
      if (event.target.type === 'radio') {
        const initRadioGroup = Object.keys(this.initFormFields)
          .filter((key) => event.target.name === this.initFormFields[key].name
            && this.initFormFields[key].init);

        const currentRadioGroup = Object.keys(this.initFormFields)
          .filter((key) => event.target.name === this.initFormFields[key].name
            && !this.initFormFields[key].isChanged);

        if (initRadioGroup.length === currentRadioGroup.length) {
          Object.keys(this.initFormFields)
            .filter((key) => event.target.name === this.initFormFields[key].name)
            .forEach((key) => { this.initFormFields[key].isChanged = false; });
        }
      }

      this.revertFormAmendmentStateIfPossible();
    },

    revertFormAmendmentStateIfPossible() {
      // check if we can turn off form amendment state
      const formIsChanged = Object.keys(this.initFormFields)
        .filter((key) => this.initFormFields[key].isChanged);

      if (formIsChanged.length === 0 && this.customFieldsChanged.length === 0) {
        this.setFormAmendmentState(false);
      }
    },

    formCommitChange(customFieldName) {
      if (!this.isAmended) {
        this.setFormAmendmentState(true);
      }

      if (!this.customFieldsChanged.some((cf) => cf === customFieldName)) {
        this.customFieldsChanged.push(customFieldName);
      }
    },

    formRevertChange(customFieldName) {
      this.customFieldsChanged = this.customFieldsChanged.filter((cf) => cf !== customFieldName);
      this.revertFormAmendmentStateIfPossible();
    },

    // Check if form amendments have been made
    // before user navigates away from spaces
    handleBeforeUnload(event) {
      if (this.isAmended) {
        // Cancel the event as stated by the standard.
        event.preventDefault();
        // Chrome requires returnValue to be set.
        /* eslint-disable-next-line no-param-reassign */
        event.returnValue = '';
      }
    },

    handleUnload() {
      this.setFormAmendmentState(false);
    },
  },

  mounted() {
    this.$el.addEventListener('keydown', this.handleEnter);

    if (this.shouldPromptBeforeDiscard) {
      this.initializeFormFields();
      this.$el.addEventListener('input', this.handleChange);
      window.addEventListener('beforeunload', this.handleBeforeUnload);
      window.addEventListener('unload', this.handleUnload);
      this.$root.$on('formCommitChange', (name) => this.formCommitChange(name));
      this.$root.$on('formRevertChange', (name) => this.formRevertChange(name));
    }
  },

  beforeDestroy() {
    this.$el.removeEventListener('keydown', this.handleEnter);

    if (this.shouldPromptBeforeDiscard) {
      this.$el.removeEventListener('input', this.handleChange);
      window.removeEventListener('beforeunload', this.handleBeforeUnload);
      window.removeEventListener('unload', this.handleUnload);
      this.$root.$off('formCommitChange');
      this.$root.$off('formRevertChange');
    }
  },
};
