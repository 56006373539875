import { mapState } from 'vuex';
import { debounce } from 'lodash-es';

import UserPresenceList from '@widgets/UserPresenceList';

import { smallDesktop, mediumDesktop } from '@/utils/constants/mediaBreakpoints';

const COLLABORATORS_VISIBLE_SMALL_WIDTH = 1;
const COLLABORATORS_VISIBLE_MEDIUM_WIDTH = 3;
const COLLABORATORS_VISIBLE_DEFAULT = 6;

// @vue/component
export default {
  props: {
    presentCollaborators: {
      type: Array,
      required: true,
    },
  },
  components: {
    UserPresenceList,
  },
  data() {
    return {
      numberOfVisiblePresentCollaborators: COLLABORATORS_VISIBLE_DEFAULT,
    };
  },
  computed: {
    ...mapState('ui/inlineComments', {
      shouldShowCommentsGutter: 'shouldShowCommentsGutter',
    }),

    uniqueCollaboratorsByUserId() {
      return this.presentCollaborators.filter(
        (object, index, self) => self.map((s) => s.user.id).indexOf(object.user.id) === index);
    },
  },
  methods: {
    getNumberOfVisiblePresentCollaborators(width) {
      // check the width of the pageeditorheader
      if (width < smallDesktop) {
        return COLLABORATORS_VISIBLE_SMALL_WIDTH;
      }

      if (this.shouldShowCommentsGutter
        || (width >= smallDesktop && width < mediumDesktop)) {
        return COLLABORATORS_VISIBLE_MEDIUM_WIDTH;
      }

      if (width >= smallDesktop
        && width < mediumDesktop) {
        return COLLABORATORS_VISIBLE_MEDIUM_WIDTH;
      }

      return COLLABORATORS_VISIBLE_DEFAULT;
    },

    // eslint-disable-next-line func-names
    onWindowResize: debounce(function () {
      this.setNumberOfVisibleCollaborators();
    }, 500),

    setNumberOfVisibleCollaborators() {
      const offsetWidth = this.$parent.$el.offsetWidth;
      this.pageEditorToolbarWidth = offsetWidth;
      this.numberOfVisiblePresentCollaborators = this.getNumberOfVisiblePresentCollaborators(offsetWidth);
    },

    /**
     * Scroll to collaborator caret position.
     * Caret should be roughly in middle of screen.
     * @param user
     */
    scrollToUserCaret(user) {
      const userId = user.id || null;

      if (!userId) {
        return;
      }

      const userCaretEl = document.querySelector(`.ot-caret__bar[data-user-id="${userId}"]`);

      if (!userCaretEl) {
        return;
      }

      const scrollContainer = document.querySelector('.s-spaces-editor-body');

      const scrollOffset = scrollContainer.scrollTop
        + (userCaretEl.getBoundingClientRect().top - (window.innerHeight / 2));

      scrollContainer.scrollTo({ top: scrollOffset, behavior: 'smooth' });
    },
  },
  mounted() {
    window.addEventListener('resize', this.onWindowResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize);
  },
  watch: {
    shouldShowCommentsGutter: {
      immediate: true,
      handler() {
        setTimeout(() => {
          this.setNumberOfVisibleCollaborators();
        }, 200);
      },
    },
    presentCollaborators: {
      immediate: false,
      handler() {
        this.setNumberOfVisibleCollaborators();
      },
    },
  },
};
