import { raiseSentryError } from '@/utils/helpers/sentryHelper';
import store from '@/store';
import { logError } from '@/utils/helpers/logger.utility';

/**
 * Handles try/catch logic for async functions and returns an array with
 * error and response that can be destructured
 *
 * If all async actions pass through this middleware
 * it gives us application wide control over how we
 * log, audit, manage and return errors in our application
 *
 * e.g.
 * const [error, data] = await asyncTryCatchHelper(myAddFn, true, 'added', 'failed to add')
 * if (error) doSomething
 *
 * @param {Function}  action
 * @param {Object}    config
 * @param {Boolean}   showAlert
 * @param {String}    successMessage
 * @param {String}    errorMessage
 * @return {Array}    [error,response]
 */
export default async function (action, config = {}, showAlert, successMessage, errorMessage) {
  try {
    const result = await action(config);

    if (showAlert && successMessage) {
      store.dispatch('alerts/showAlert',
        { type: 'success', message: successMessage },
        { root: true });
    }
    return [null, result];
  } catch (error) {
    raiseSentryError(error);

    const isUnprocessableError = (
      error && error.response && error.response.status && error.response.status === 422
    );

    const isPaymentRequiredError = (
      error && error.response && error.response.status && error.response.status === 402
    );

    if (showAlert && !isPaymentRequiredError) {
      if (isUnprocessableError) {
        const warning = ((((error || {}).response || {}).data || {}).errors || [])[0] || 'Invalid data';

        store.dispatch('alerts/showAlert',
          { type: 'warning', message: warning },
          { root: true });
      } else {
        store.dispatch('alerts/showAlert',
          { type: 'error', message: errorMessage },
          { root: true });
      }
    }

    if (!isUnprocessableError) {
      logError(error);
    }

    if (error.response) {
      return [error.response, null];
    }

    // a network error will not have a status or statusText
    store.dispatch('alerts/showAlert',
      {
        type: 'error',
        message: 'A network error has occurred processing your request. Please try again.',
      }, { root: true });

    return [{ status: '', statusText: error.message }, null];
  }
}
