export const REACTION_TYPES = [
  {
    id: 1,
    shortCode: ':+1:',
    unicode: '👍',
  },
  {
    id: 2,
    shortCode: ':-1:',
    unicode: '👎',
  },
  {
    id: 3,
    shortCode: ':heart:',
    unicode: '❤️',
  },
  {
    id: 4,
    shortCode: ':joy:',
    unicode: '😂',
  },
  {
    id: 5,
    shortCode: ':slight_frown:',
    unicode: '🙁',
  },
];

if (Object.freeze) {
  Object.freeze(REACTION_TYPES);
}

// eslint-disable-next-line arrow-body-style
export const REACTION_TYPES_BY_ID = ((reactionTypes) => {
  return reactionTypes.reduce((result, reactionType) => {
    // eslint-disable-next-line no-param-reassign
    result[Number(reactionType.id)] = reactionType;
    return result;
  }, {});
})(REACTION_TYPES);

if (Object.freeze) {
  Object.freeze(REACTION_TYPES_BY_ID);
}

export const RESOURCE_TYPES = {
  PAGE: 'page',
  COMMENT: 'comment',
  INLINE_COMMENT: 'inlinecomment',
  PLUGIN: 'plugin',
};

if (Object.freeze) {
  Object.freeze(RESOURCE_TYPES);
}

export const SUPPORTED_REACTION_TYPES_BY_RESOURCE = {
  [RESOURCE_TYPES.PAGE]: { 1: true, 2: false, 3: true, 4: false, 5: false },
  [RESOURCE_TYPES.COMMENT]: { 1: true, 2: true, 3: true, 4: true, 5: true },
  [RESOURCE_TYPES.INLINE_COMMENT]: { 1: true, 2: true, 3: true, 4: true, 5: true },
  [RESOURCE_TYPES.PLUGIN]: { 1: true, 2: true, 3: true, 4: true, 5: true },
};

if (Object.freeze) {
  Object.freeze(SUPPORTED_REACTION_TYPES_BY_RESOURCE);
}

export default {
  REACTION_TYPES,
  REACTION_TYPES_BY_ID,
  RESOURCE_TYPES,
  SUPPORTED_REACTION_TYPES_BY_RESOURCE,
};
