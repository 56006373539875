import formMixin from '@/utils/mixins/form.mixin';

// Heavily inspired by
// https://www.smashingmagazine.com/2017/08/creating-custom-inputs-vue-js/

// @vue/component
export default {
  mixins: [formMixin],
  model: {
    prop: 'selectedValue',
    event: 'change',
  },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedValue: {
      type: Boolean,
      required: true,
    },
    indeterminate: {
      type: Boolean,
      required: false,
      default: false,
    },
    checkboxLabel: {
      type: String,
      default: '',
    },
    // We set `true-value` and `false-value` to the default true and false so
    // we can always use them instead of checking whether or not they are set.
    // Also can use camelCase here, but hyphen-separating the attribute name
    // when using the component will still work
    trueValue: {
      type: Boolean,
      required: false,
      default: true,
    },
    falseValue: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    shouldBeChecked() {
      if (this.selectedValue instanceof Array) {
        return this.selectedValue.includes(this.value);
      }

      // Note that `true-value` and `false-value` are camelCase in the JS
      return this.selectedValue === this.trueValue;
    },
    checkboxClasses() {
      return {
        'w-form-checkbox--disabled': this.disabled,
        'w-form-checkbox--indeterminate': this.indeterminate,
        'w-form-checkbox--is-checked': this.shouldBeChecked,
      };
    },
  },
  methods: {
    toggle(event) {
      /* eslint-disable-next-line no-param-reassign */
      event.target.checked = !event.target.checked;
      this.updateInput(event);
    },
    updateInput(event) {
      event.stopPropagation();

      const isChecked = event.target.checked;

      if (this.selectedValue instanceof Array) {
        const newValue = [...this.selectedValue];

        if (isChecked) {
          newValue.push(this.value);
        } else {
          newValue.splice(newValue.indexOf(this.value), 1);
        }

        this.$emit('change', newValue);
      } else {
        this.$emit('change', isChecked ? this.trueValue : this.falseValue);
      }
    },
  },
};
