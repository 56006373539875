import { mapActions } from 'vuex';

import ErrorContainer from '@pages/Error/ErrorContainer';
import NoAccessIllustration from '@/assets/no_access.svg';
import PendingAccessIllustration from '@/assets/pending_access.svg';
import asyncTryCatchHelper from '@/utils/helpers/asyncTryCatchHelper';
import axios from '@/utils/vuex/client';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';

// @vue/component
export default {
  name: 'Forbidden',
  props: {
    errorBody: {
      type: Object,
      default: () => { },
    },
  },
  components: {
    ErrorContainer,
    NoAccessIllustration,
    PendingAccessIllustration,
  },
  data() {
    return {
      isLoading: true,
      hasRequestedAccess: true,
    };
  },
  computed: {
    isSpaceRoute() {
      return !!this.$route.params.spaceCode;
    },
  },
  methods: {
    ...mapActions('alerts', ['showAlert']),

    ...mapActions('modals', ['openModal']),

    async checkForPendingAccessRequest() {
      if (!this.$route.params.spaceCode) {
        return;
      }
      const axiosConfig = {
        method: 'head',
        url: buildApiUrl({ resource: `spaces/${this.$route.params.spaceCode}/access.json` }),
      };
      const [err] = await asyncTryCatchHelper(axios, axiosConfig);

      // Request will return a 404 if user has not requested access
      this.hasRequestedAccess = !err;
    },

    requestAccess() {
      this.openModal({
        activeModal: 'access-request',
        modalProps: {
          onCreate: () => {
            this.hasRequestedAccess = true;
          },
        },
      });
    },
  },
  async created() {
    this.isLoading = true;
    await this.checkForPendingAccessRequest();
    this.isLoading = false;
  },
};
