// Based on https://github.com/AlexandreBonaventure/vue-mq

import json2mq from 'json2mq';
import { mqSmall, mqMedium } from '@/utils/constants/mediaBreakpoints';

import component from './component';

export function convertBreakpointsToMediaQueries(breakpoints) {
  const keys = Object.keys(breakpoints);
  const values = keys.map((key) => breakpoints[key]);
  const breakpointValues = [0, ...values.slice(0, -1)];
  const mediaQueries = breakpointValues.reduce((sum, value, index) => {
    const options = {
      minWidth: value,
      ...(index < keys.length - 1 ? { maxWidth: breakpointValues[index + 1] - 1 } : {}),
    };
    const mediaQuery = json2mq(options);
    return Object.assign(
      sum,
      {
        [keys[index]]: mediaQuery,
      },
    );
  }, {});
  return mediaQueries;
}

export function transformValuesFromBreakpoints(breakpoints, values, currentBreakpoint) {
  const findClosestValue = (breakpoint) => {
    if (values[breakpoint] !== undefined) return values[breakpoint];
    const index = breakpoints.findIndex((b) => b === breakpoint);
    const newBreakpoint = index !== -1 || index !== 0 ? breakpoints[index - 1] : null;
    if (!newBreakpoint) return values[index];
    return values[newBreakpoint] !== undefined
      ? values[newBreakpoint]
      : findClosestValue(newBreakpoint);
  };

  return findClosestValue(currentBreakpoint);
}

const DEFAULT_BREAKPOINT = {
  sm: mqSmall,
  md: mqMedium,
  lg: Infinity,
};

/* eslint-disable-next-line func-names */
const install = function (Vue, { breakpoints = DEFAULT_BREAKPOINT } = {}) {
  // Init reactive component
  const reactorComponent = new Vue({
    data: () => ({
      currentBreakpoint: null,
    }),
  });

  function subscribeToMediaQuery(mediaQuery, enter) {
    const mql = window.matchMedia(mediaQuery);
    const cb = ({ matches }) => {
      if (matches) enter();
    };
    mql.addListener(cb); // subscribing
    cb(mql); // initial trigger
  }

  const mediaQueries = convertBreakpointsToMediaQueries(breakpoints);
  Object.keys(mediaQueries).forEach((key) => {
    const mediaQuery = mediaQueries[key];
    const enter = () => { reactorComponent.currentBreakpoint = key; };
    subscribeToMediaQuery(mediaQuery, enter);
  });

  Vue.filter('mq', (currentBreakpoint, values) => transformValuesFromBreakpoints(
    Object.keys(breakpoints), values, currentBreakpoint,
  ));

  Vue.mixin({
    computed: {
      $mq() {
        return reactorComponent.currentBreakpoint;
      },
    },
  });

  /* eslint-disable-next-line no-param-reassign */
  Vue.prototype.$mqAvailableBreakpoints = breakpoints;

  Vue.component('MqLayout', component);
};

export default { install };
