export default {
  namespaced: true,
  state: {
    isAmended: false,
  },
  mutations: {
    setAmendment(state, amended) {
      state.isAmended = amended;
    },
  },
  actions: {
    amend({ commit }, amended) {
      commit('setAmendment', amended);
    },
  },
};
