import Vue from 'vue';
import { isArray } from 'lodash-es';

import { createCrudModule } from '@/utils/vuex';
import cnameHelper from '@/utils/helpers/CNameHelper';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import { findFirst, findPathToFirst } from '@/utils/vuex/utils/objectDeepSearch';
import deepVueSet from '@/utils/vuex/utils/deepVueSet';
import deepVueDelete from '@/utils/vuex/utils/deepVueDelete';
import { RESOURCE_TYPES } from '@/utils/constants/reactions';

import store from '..';

function storeReactionsMetaForComments(comments) {
  comments.forEach((comment) => {
    store.commit('reactions/setMetaForResource', {
      resourceType: RESOURCE_TYPES.COMMENT,
      resourceId: comment.id,
      meta: comment.reactions,
    });

    if (Array.isArray(comment.replies)) {
      storeReactionsMetaForComments(comment.replies);
    }
  });
}

export default createCrudModule({
  only: ['FETCH_LIST', 'CREATE', 'UPDATE', 'DESTROY'],
  socketEvents: ['CREATE'],
  resource: 'comment',
  customUrlFn(id, spaceId, pageId) {
    return buildApiUrl({
      resource: id
        ? `spaces/${spaceId}/pages/${pageId}/comments/${id}`
        : `spaces/${spaceId}/pages/${pageId}/comments`,
    });
  },
  state: {
    newCommentAddedViaSocket: false,
  },
  getters: {
    getCommentsForPage: (state, getters) => (pageId) => getters.list
      .filter((comment) => comment.page.id === pageId)
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),

    getCommentReplies: (state, getters) => (commentId) => getters.list
      .filter((comment) => comment.id === commentId)[0].replies
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
  },
  onCreateSuccess(state, response) {
    if (response.data.parentId) {
      store.dispatch('comments/addCommentReply', response.data);
    }

    if (Vue.$ga) {
      let eventLabel = '';

      if (store.getters['ui/sharedSpacePage/isSharedSpacePage']) {
        eventLabel = `${cnameHelper()} | Shared Space Page | ${store.getters['navigation/activePage'].title}`;
      } else {
        eventLabel = `${cnameHelper()} | ${store.getters['navigation/activeSpace'].title} | ${store.getters['navigation/activePage'].title}`;
      }

      Vue.$ga.event({
        eventCategory: 'comments',
        eventAction: 'created',
        eventLabel,
        eventValue: 1,
      });
    }
  },
  onUpdateSuccess(state, response) {
    if (response.data.parentId) {
      store.dispatch('comments/updateCommentReply', response.data);
    }
  },
  onDestroySuccess(state, id) {
    store.dispatch('comments/removeComment', id);

    // Note: we need to re-fetch notifications here as this deletion will trigger updates to
    // the list of notifications
    store.dispatch('notifications/fetchList');

    if (Vue.$ga) {
      let eventLabel = '';

      if (store.getters['ui/sharedSpacePage/isSharedSpacePage']) {
        eventLabel = `${cnameHelper()} | Shared Space Page | ${store.getters['navigation/activePage'].title}`;
      } else {
        eventLabel = `${cnameHelper()} | ${store.getters['navigation/activeSpace'].title} | ${store.getters['navigation/activePage'].title}`;
      }

      Vue.$ga.event({
        eventCategory: 'comments',
        eventAction: 'deleted',
        eventLabel,
        eventValue: 1,
      });
    }
  },
  onFetchSingleSuccess(state, response) {
    storeReactionsMetaForComments([response.data]);
  },
  onFetchListSuccess(state, response) {
    storeReactionsMetaForComments(response.data);
  },
  onSocketDataReceived() {
    // currently we are only listening for CREATE socket events
    store.commit('comments/setNewCommentAddedViaSocket', true);
  },
  onCreateViaSocketSuccess(state, comment) {
    if (comment.parentId) {
      store.dispatch('comments/addCommentReply', comment);
    }
  },
  /* NOTE: Logic to handle UPDATE, DELETE socket events, we might handle these in future */
  // onUpdateViaSocketSuccess(state, comment) {
  //   if (comment.parentId) {
  //     store.dispatch('comments/updateCommentReply', comment);
  //   }
  // },
  // onDestroyViaSocketSuccess(state, comment) {
  //   store.dispatch('comments/removeComment', comment.id);

  //   // Note: we need to re-fetch notifications here as this deletion will trigger updates to
  //   // the list of notifications
  //   store.dispatch('notifications/fetchList');
  // },
  actions: {
    addCommentReply({ commit }, comment) {
      commit('addCommentReply', comment);
    },

    updateCommentReply({ commit }, comment) {
      commit('updateCommentReply', comment);
    },

    removeComment({ commit }, commentId) {
      commit('removeComment', commentId);
    },
  },
  mutations: {
    setNewCommentAddedViaSocket(state, newCommentAddedViaSocket) {
      state.newCommentAddedViaSocket = newCommentAddedViaSocket;
    },

    addCommentReply(state, comment) {
      const parent = findFirst(state.entities, { id: comment.parentId });

      if (!parent) {
        return;
      }

      if (!isArray(parent.replies)) {
        Vue.set(parent, 'replies', []);
      }

      parent.replies.push(comment);

      Vue.delete(state.entities, comment.id);
    },

    updateCommentReply(state, comment) {
      const pathToComment = findPathToFirst(state.entities, { id: comment.id });
      if (!pathToComment || !pathToComment.length) {
        return;
      }

      deepVueSet(state.entities, pathToComment, comment);

      Vue.delete(state.entities, comment.id); // createMutations adds comment to root object.
    },

    removeComment(state, commentId) {
      const pathToComment = findPathToFirst(state.entities, { id: commentId });
      if (!pathToComment || !pathToComment.length) {
        return;
      }

      deepVueDelete(state.entities, pathToComment);
    },
  },
});
