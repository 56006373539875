import store from '@/store';

import connections from '@/utils/constants/connections';
import isIE from '@/utils/helpers/isIE';

let createSyncOTTeamworkClient = null;
let syncPresenceByCurrentLocationId = null;
const isBrowserIE = isIE();

let syncOT;
let loggedUserId;
let installationId;

const appName = 'spaces';
const globalPresenceEventNames = {
  error: 'error',
  change: 'change',
  presenceChange: 'presenceChange',
};

const globalPresenceLocations = {
  spacePage: (pageId) => `${installationId}:${appName}:page/${pageId}`,
};

const initGlobalPresence = async () => {
  if (isBrowserIE) { return; }

  const region = store.getters['installation/region'];
  const hostname = connections[process.env.APP_ENVIRONMENT][region].syncOT;

  loggedUserId = store.getters['session/userId'];
  installationId = store.getters['installation/id'];

  if (!loggedUserId || !installationId || !region) { return; }

  if (!createSyncOTTeamworkClient) {
    const syncOTClientModule = await import(/* webpackChunkName: "global-presence" */ '@teamwork/syncot-client');

    if (!syncOTClientModule || !syncOTClientModule.createSyncOTTeamworkClient) {
      return;
    }

    createSyncOTTeamworkClient = syncOTClientModule.createSyncOTTeamworkClient;
  }

  // Create a SyncOT-Teamwork client.
  syncOT = createSyncOTTeamworkClient({
    scope: appName,
    serverHostname: hostname,
    serverRef: installationId,
  });

  syncOT.presenceClient.setMaxListeners(Infinity);
};

const submitGlobalPresenceToServer = (path = undefined, data = {}) => {
  if (!syncOT || isBrowserIE) { return; }

  syncOT.presenceClient.locationId = path;
  syncOT.presenceClient.data = data;
};

const onGlobalPresenceChange = (globalPresenceSync, sessionId, onPresenceChangeListener) => {
  const sessionData = globalPresenceSync.presence.get(sessionId);

  const presence = {
    sessionId,
    locationId: undefined,
    data: {},
    ...sessionData,
  };

  if (presence.data
    && presence.data.user
    && presence.data.user.id
    && presence.data.user.id === loggedUserId
  ) {
    return;
  }

  onPresenceChangeListener(presence);
};

const initRemoteGlobalPresenceByLocation = async (path, data = {}, listener = () => {}) => {
  if (!syncOT || !path || isBrowserIE) { return null; }

  submitGlobalPresenceToServer(path, data);

  if (!syncPresenceByCurrentLocationId) {
    const presenceClientModule = await import(/* webpackChunkName: "global-presence" */ '@syncot/presence-client');

    if (!presenceClientModule || !presenceClientModule.syncPresenceByCurrentLocationId) {
      return null;
    }

    syncPresenceByCurrentLocationId = presenceClientModule.syncPresenceByCurrentLocationId;
  }

  const globalPresenceSync = syncPresenceByCurrentLocationId(syncOT.presenceClient);

  globalPresenceSync.on(globalPresenceEventNames.change,
    (sessionId) => onGlobalPresenceChange(globalPresenceSync, sessionId, listener));

  globalPresenceSync.on(globalPresenceEventNames.error, () => {
    onGlobalPresenceChange(globalPresenceSync, undefined, listener);
  });

  return globalPresenceSync;
};

export {
  initGlobalPresence,
  globalPresenceEventNames,
  globalPresenceLocations,
  submitGlobalPresenceToServer,
  initRemoteGlobalPresenceByLocation,
};
