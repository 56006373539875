/* eslint-disable no-param-reassign */

import Vue from 'vue';
import pluralize from 'pluralize';
import { cloneDeep, isPlainObject, isUndefined } from 'lodash-es';

import client from '@/utils/vuex/client';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import defaultPrepareConfig from '@/utils/vuex/utils/defaultPrepareConfig';
import isValidId from '@/utils/helpers/isValidId';
import isValidCategoryId from '@/utils/helpers/isValidCategoryId';
import alphabeticalSort from '@/utils/helpers/alphabeticalSort';
import RESOURCES from '@/utils/constants/resources';
import { DEFAULT_CATEGORY_ID, DEFAULT_CATEGORY_NAME } from '@/utils/constants/spaceCategories';
import { logWarning } from '@/utils/helpers/logger.utility';

const SINGLE_PROPERTY = RESOURCES.CATEGORY;
const LIST_PROPERTY = RESOURCES.CATEGORY_PLURAL;

// Note: it's critically important that 'spaces' are included in requests to this module
// as the 'fetchCategoriesWithSpaces' action is used to drive the 'Home' routes
function prepareConfig(config) {
  return defaultPrepareConfig(config, [
    pluralize(RESOURCES.USER),
    pluralize(RESOURCES.SPACE),
  ]);
}

function syncIncludes(commit, response) {
  // Sync the 'spaces' includes to the 'spaces' store module
  if (isPlainObject(response.data.included) && isPlainObject(response.data.included.spaces)) {
    Object.entries(response.data.included.spaces).forEach(([, space]) => {
      commit('spaces/setSpace', space, { root: true });
    });
  }
}

// Note: the following functions are related to keeping this and the 'spaces' store
// module in sync post-create/update/delete. This is to avoid an unnecessary API calls
// to refresh the data, but this can be easily changed by replacing this function

function updateDefaultCategory(commit, getters, addedSpaceIds = [], removeSpaceIds = []) {
  if (
    (!Array.isArray(addedSpaceIds) || !addedSpaceIds.length)
    && (!Array.isArray(removeSpaceIds) || !removeSpaceIds.length)
  ) {
    return;
  }

  const uncategorised = cloneDeep(getters.getCategoryById(DEFAULT_CATEGORY_ID));

  uncategorised.spaces = Array.isArray(uncategorised.spaces) ? uncategorised.spaces : [];

  uncategorised.spaces = uncategorised.spaces.reduce((result, space) => {
    const spaceId = Number(space.id);

    // This space was added to the target category, so don't add it to the
    // 'uncategorised' list
    if (addedSpaceIds.includes(spaceId)) {
      return result;
    }

    result.push(space);

    return result;
  }, []);

  // These spaces were removed from the target category, so add them to the
  // 'uncategorised' list
  removeSpaceIds.forEach((spaceId) => {
    uncategorised.spaces.push({ id: spaceId, type: RESOURCES.SPACE_PLURAL });
  });

  if (!isPlainObject(uncategorised.meta)) {
    uncategorised.meta = {};
  }

  uncategorised.meta.spaceCount = uncategorised.spaces.length;

  commit('setCategory', uncategorised);
}

function updateSpaces(commit, categoryId = null, addedSpaceIds = [], removedSpaceIds = []) {
  addedSpaceIds.forEach((spaceId) => {
    commit('spaces/setCategoryForSpace', { spaceId, categoryId }, { root: true });
  });

  removedSpaceIds.forEach((spaceId) => {
    commit('spaces/setCategoryForSpace', { spaceId, categoryId: null }, { root: true });
  });
}

function afterCreate(commit, getters, categoryId, addedSpaceIds = []) {
  if (!Array.isArray(addedSpaceIds) || !addedSpaceIds.length) {
    return;
  }

  updateSpaces(commit, categoryId, addedSpaceIds, []);

  updateDefaultCategory(commit, getters, addedSpaceIds, []);
}

function afterUpdate(commit, getters, categoryId, addedSpaceIds = [], removedSpaceIds = []) {
  if (
    (!Array.isArray(addedSpaceIds) || !addedSpaceIds.length)
    && (!Array.isArray(removedSpaceIds) || !removedSpaceIds.length)
  ) {
    return;
  }

  updateSpaces(commit, categoryId, addedSpaceIds, removedSpaceIds);

  updateDefaultCategory(commit, getters, addedSpaceIds, removedSpaceIds);
}

function afterDelete(commit, getters, removedSpaceIds = []) {
  if (!Array.isArray(removedSpaceIds) || !removedSpaceIds.length) {
    return;
  }

  updateSpaces(commit, null, [], removedSpaceIds);

  updateDefaultCategory(commit, getters, [], removedSpaceIds);
}

function changeSpaceCategory(commit, getters, { spaceId, categoryId }) {
  const currentCategoryId = getters.spaceCategoryMap[spaceId];
  const currentCategory = (!isUndefined(currentCategoryId))
    ? getters.getCategoryById(currentCategoryId)
    : null;

  // Supplied category ID is not a valid category ID, therefore we treat this as a
  // removal of the space from all categories (in case of space deletion)
  if (!isValidCategoryId(categoryId)) {
    if (currentCategory) {
      commit('removeSpaceFromCategory', { categoryId: currentCategoryId, spaceId });
    }

    return;
  }

  // Skip this process if the specified space is already in the category that's
  // specified
  if (Number(categoryId) === Number(currentCategoryId)) {
    return;
  }

  // Remove the space from it's existing category
  if (currentCategory) {
    commit('removeSpaceFromCategory', { categoryId: currentCategoryId, spaceId });
  }

  // Add the space to the new category
  commit('addSpaceToCategory', { categoryId, spaceId });
}

export default {
  namespaced: true,
  state: {
    isCreatingCategory: false,
    createCategoryError: null,
    isUpdatingCategory: false,
    updateCategoryError: null,
    isDeletingCategory: false,
    deleteCategoryError: null,
    isFetchingCategory: false,
    fetchCategoryError: null,
    isFetchingCategories: false,
    fetchCategoriesError: null,
    categories: {},
  },
  getters: {
    list: (state) => {
      if (!Object.keys(state.categories).length) {
        return [];
      }

      // Note: ensure the 'uncategorised' category is always present and is last
      const categories = Object.values(state.categories)
        .filter((category) => Number(category.id) !== DEFAULT_CATEGORY_ID)
        .sort((a, b) => alphabeticalSort(a.name, b.name));

      const uncategorised = state.categories['0'];
      uncategorised.title = DEFAULT_CATEGORY_NAME;

      categories.push(uncategorised);

      return categories;
    },

    spaceCategoryMap: (state) => Object.entries(state.categories).reduce((result, [, category]) => {
      if (!Array.isArray(category.spaces)) {
        return result;
      }

      category.spaces.forEach((space) => {
        result[Number(space.id)] = Number(category.id);
      });

      return result;
    }, {}),

    uncategorisedSpaces: (state, getters) => getters.getSpacesInCategory(null),

    uncategorisedSpaceIds: (state, getters) => getters.getSpaceIdsInCategory(null),

    getSpaceIdsInCategory: (state) => (categoryId) => {
      if (!state.categories[Number(categoryId)] || !Array.isArray(state.categories[Number(categoryId)].spaces)) {
        return [];
      }

      return state.categories[Number(categoryId)].spaces.map(({ id }) => Number(id));
    },

    getSpacesInCategory: (state, getters, rootState, rootGetters) =>
      (categoryId) =>
        getters.getSpaceIdsInCategory(categoryId)
          .reduce((result, id) => {
            const space = rootGetters['spaces/byId'](id);
            if (space) {
              result.push(space);
            }
            return result;
          }, []),

    getCategoryById: (state) => (categoryId) => state.categories[categoryId] || null,

    defaultCategory: (state, getters) => getters.getCategoryById(DEFAULT_CATEGORY_ID),
  },
  mutations: {
    setCategory(state, category) {
      if (!category.id) {
        category.name = DEFAULT_CATEGORY_NAME;
      }

      Vue.set(state.categories, Number(category.id), category);
    },

    setCategories(state, categories) {
      // Note: need to ensure the 'uncategorised'/null category has an ID of 0
      state.categories = Object.values(categories).reduce((result, category) => {
        if (!category.id) {
          category.name = DEFAULT_CATEGORY_NAME;
        }

        result[Number(category.id)] = Object.assign(category, { id: Number(category.id) });
        return result;
      }, {});
    },

    removeCategory(state, categoryId) {
      Vue.delete(state.categories, Number(categoryId));
    },

    addSpaceToCategory(state, { categoryId, spaceId }) {
      if (!isValidCategoryId(categoryId)) {
        logWarning(`Attempt to add space to category failed due to an invalid 'categoryId' and/or 'spaceId' (categoryId: ${categoryId} | spaceId: ${spaceId})`);
        return;
      }

      const category = state.categories[categoryId];
      if (!category) {
        logWarning(`Attempt to add space to category failed as the specified category does not exist (categoryId: ${categoryId})`);
        return;
      }

      if (!Array.isArray(category.spaces)) {
        category.spaces = [];
      }

      const exists = !!category.spaces.find((space) => Number(space.id) === Number(spaceId));
      if (exists) {
        return;
      }

      category.spaces.push({ id: Number(spaceId), type: RESOURCES.SPACE_PLURAL });
    },

    removeSpaceFromCategory(state, { categoryId, spaceId }) {
      if (!isValidCategoryId(categoryId)) {
        logWarning(`Attempt to remove space from category failed due to an invalid 'categoryId' and/or 'spaceId' (categoryId: ${categoryId} | spaceId: ${spaceId})`);
        return;
      }

      const category = state.categories[categoryId];
      if (!category) {
        logWarning(`Attempt to remove space from category failed as the specified category does not exist (categoryId: ${categoryId})`);
        return;
      }

      if (!Array.isArray(category.spaces)) {
        return;
      }

      const index = category.spaces.findIndex((space) => Number(space.id) === Number(spaceId));
      if (index === -1) {
        return;
      }

      category.spaces.splice(index, 1);
    },

    setFetchCategoryState(state, isProcessing) {
      state.isFetchingCategory = isProcessing;
    },

    setFetchCategoryError(state, error) {
      state.fetchCategoryError = error;
    },

    setFetchCategoriesState(state, isProcessing) {
      state.isFetchingCategories = isProcessing;
    },

    setFetchCategoriesError(state, error) {
      state.fetchCategoriesError = error;
    },

    setCreateCategoryState(state, isProcessing) {
      state.isCreatingCategory = isProcessing;
    },

    setCreateCategoryError(state, error) {
      state.createCategoryError = error;
    },

    setUpdateCategoryState(state, isProcessing) {
      state.isUpdatingCategory = isProcessing;
    },

    setUpdateCategoryError(state, error) {
      state.updateCategoryError = error;
    },

    setDeleteCategoryState(state, isProcessing) {
      state.isDeletingCategory = isProcessing;
    },

    setDeleteCategoryError(state, error) {
      state.deleteCategoryError = error;
    },
  },
  actions: {
    create({ commit, getters }, { data, config }) {
      if (!isPlainObject(data) || !isPlainObject(data[SINGLE_PROPERTY])) {
        throw new Error(`'data.${SINGLE_PROPERTY}' property is missing or invalid`);
      }

      if (!(Object.keys(data[SINGLE_PROPERTY]).length)) {
        return Promise.resolve();
      }

      const url = buildApiUrl({ resource: 'categories/setup' });

      commit('setCreateCategoryState', true);
      commit('setCreateCategoryError', null);

      const addedSpaceIds = Array.isArray(data[SINGLE_PROPERTY].spaces)
        ? data[SINGLE_PROPERTY].spaces.map((id) => Number(id))
        : [];

      return client.post(url, data, prepareConfig(config))
        .then((response) => {
          if (!response.data || isUndefined(response.data[SINGLE_PROPERTY])) {
            throw new Error(`'${SINGLE_PROPERTY}' property not found in response.data`);
          }

          const clonedData = cloneDeep(response.data);

          commit('setCategory', clonedData[SINGLE_PROPERTY]);

          syncIncludes(commit, response);

          const categoryId = clonedData[SINGLE_PROPERTY].id;

          afterCreate(commit, getters, categoryId, addedSpaceIds);

          return Promise.resolve(clonedData[SINGLE_PROPERTY]);
        })
        .catch((err) => {
          commit('setCreateCategoryError', err);
          return Promise.reject(err);
        })
        .finally(() => {
          commit('setCreateCategoryState', false);
        });
    },

    update({ commit, getters }, { id, data, config = {} }) {
      // Note: you are not able to update the default category (ID: 0)
      if (!isValidId(id)) {
        throw new Error('\'id\' property is missing or invalid');
      }

      if (!isPlainObject(data) || !isPlainObject(data[SINGLE_PROPERTY])) {
        throw new Error(`'data.${SINGLE_PROPERTY}' property is missing or invalid`);
      }

      if (!(Object.keys(data[SINGLE_PROPERTY]).length)) {
        return Promise.resolve();
      }

      const url = buildApiUrl({ resource: `categories/${id}/update` });

      // Store the added/remove spaces so we can update the 'spaces' module
      // after the request has completed
      const addedSpaceIds = Array.isArray(data[SINGLE_PROPERTY].addSpaceIds)
        ? data[SINGLE_PROPERTY].addSpaceIds
        : [];

      const removedSpaceIds = Array.isArray(data[SINGLE_PROPERTY].removeSpaceIds)
        ? data[SINGLE_PROPERTY].removeSpaceIds
        : [];

      commit('setUpdateCategoryState', true);
      commit('setUpdateCategoryError', null);

      return client.post(url, data, prepareConfig(config))
        .then((response) => {
          if (!response.data || isUndefined(response.data[SINGLE_PROPERTY])) {
            throw new Error(`'${SINGLE_PROPERTY}' property not found in response.data`);
          }

          const clonedData = cloneDeep(response.data);

          commit('setCategory', clonedData[SINGLE_PROPERTY]);

          syncIncludes(commit, response);

          afterUpdate(commit, getters, id, addedSpaceIds, removedSpaceIds);

          return Promise.resolve(clonedData[SINGLE_PROPERTY]);
        })
        .catch((err) => {
          commit('setUpdateCategoryError', err);
          return Promise.reject(err);
        })
        .finally(() => {
          commit('setUpdateCategoryState', false);
        });
    },

    delete({ commit, getters }, { id, config = {} }) {
      // Note: you are not able to delete the default category (ID: 0)
      if (!isValidId(id)) {
        throw new Error('\'id\' property is missing or invalid');
      }

      const removedSpaceIds = getters.getSpaceIdsInCategory(id);

      const url = buildApiUrl({ resource: `categories/${id}` });

      commit('setDeleteCategoryState', true);
      commit('setDeleteCategoryError', null);

      return client.delete(url, prepareConfig(config))
        .then(() => {
          commit('removeCategory', id);

          afterDelete(commit, getters, removedSpaceIds);

          return Promise.resolve();
        })
        .catch((err) => {
          commit('setDeleteCategoryError', err);
          return Promise.reject(err);
        })
        .finally(() => {
          commit('setDeleteCategoryState', false);
        });
    },

    fetchCategoryWithSpaces({ commit }, { id, config = {} }) {
      if (!isValidCategoryId(id)) {
        throw new Error('\'categoryId\' property is missing or invalid');
      }

      const url = isValidCategoryId(id)
        ? buildApiUrl({ resource: `categories/${id}/assigned` })
        : buildApiUrl({ resource: 'categories/unassigned' });

      commit('setFetchCategoryState', true);
      commit('setFetchCategoryError', null);

      return client.get(url, prepareConfig(config))
        .then((response) => {
          if (!response.data || isUndefined(response.data[SINGLE_PROPERTY])) {
            throw new Error(`'${SINGLE_PROPERTY}' property not found in response.data`);
          }

          const clonedData = cloneDeep(response.data);

          commit('setCategory', clonedData[SINGLE_PROPERTY]);

          syncIncludes(commit, response);

          return Promise.resolve(clonedData[SINGLE_PROPERTY]);
        })
        .catch((err) => {
          commit('setFetchCategoryError', err);
          return Promise.reject(err);
        })
        .finally(() => {
          commit('setFetchCategoryState', false);
        });
    },

    fetchCategoriesWithSpaces({ commit }, { config = {} } = {}) {
      const url = buildApiUrl({ resource: 'categories/assigned' });

      commit('setFetchCategoriesState', true);
      commit('setFetchCategoriesError', null);

      return client.get(url, prepareConfig(config))
        .then((response) => {
          if (!response.data || isUndefined(response.data[LIST_PROPERTY])) {
            throw new Error(`'${LIST_PROPERTY}' property not found in response.data`);
          }

          if (!Array.isArray(response.data[LIST_PROPERTY])) {
            throw new Error(`'response.data.${LIST_PROPERTY}' is not an Array`);
          }

          const clonedData = cloneDeep(response.data);

          commit('setCategories', clonedData[LIST_PROPERTY]);

          syncIncludes(commit, response);

          return Promise.resolve(clonedData[LIST_PROPERTY]);
        })
        .catch((err) => {
          commit('setFetchCategoriesError', err);
          return Promise.reject(err);
        })
        .finally(() => {
          commit('setFetchCategoriesState', false);
        });
    },

    addSpace({ commit, getters }, { spaceId, categoryId }) {
      changeSpaceCategory(commit, getters, { spaceId, categoryId });
    },

    updateSpace({ commit, getters }, { spaceId, categoryId }) {
      changeSpaceCategory(commit, getters, { spaceId, categoryId });
    },

    deleteSpace({ commit, getters }, { spaceId }) {
      changeSpaceCategory(commit, getters, { spaceId, categoryId: null });
    },
  },
};
