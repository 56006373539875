import { faArrowsH } from '@fortawesome/pro-regular-svg-icons/faArrowsH';
import { faArrowToBottom } from '@fortawesome/pro-regular-svg-icons/faArrowToBottom';
import { faArrowToRight } from '@fortawesome/pro-regular-svg-icons/faArrowToRight';
import { faBallotCheck } from '@fortawesome/pro-regular-svg-icons/faBallotCheck';
import { faBan } from '@fortawesome/pro-regular-svg-icons/faBan';
import { faBolt } from '@fortawesome/pro-regular-svg-icons/faBolt';
import { faBookReader } from '@fortawesome/pro-regular-svg-icons/faBookReader';
import { faBuilding } from '@fortawesome/pro-regular-svg-icons/faBuilding';
import { faChartLine } from '@fortawesome/pro-regular-svg-icons/faChartLine';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp';
import { faCode } from '@fortawesome/pro-regular-svg-icons/faCode';
import { faCog } from '@fortawesome/pro-regular-svg-icons/faCog';
import { faComment } from '@fortawesome/pro-regular-svg-icons/faComment';
import { faCommentAltLines } from '@fortawesome/pro-regular-svg-icons/faCommentAltLines';
import { faCommentAltPlus } from '@fortawesome/pro-regular-svg-icons/faCommentAltPlus';
import { faCommentLines } from '@fortawesome/pro-regular-svg-icons/faCommentLines';
import { faCreditCard } from '@fortawesome/pro-regular-svg-icons/faCreditCard';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons/faEllipsisH';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons/faEllipsisV';
import { faExchange } from '@fortawesome/pro-regular-svg-icons/faExchange';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle';
import { faEye } from '@fortawesome/pro-regular-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-regular-svg-icons/faEyeSlash';
import { faFileAlt } from '@fortawesome/pro-regular-svg-icons/faFileAlt';
import { faFileInvoice } from '@fortawesome/pro-regular-svg-icons/faFileInvoice';
import { faFilePdf } from '@fortawesome/pro-regular-svg-icons/faFilePdf';
import { faFileSpreadsheet } from '@fortawesome/pro-regular-svg-icons/faFileSpreadsheet';
import { faFilter } from '@fortawesome/pro-regular-svg-icons/faFilter';
import { faFlaskPotion } from '@fortawesome/pro-regular-svg-icons/faFlaskPotion';
import { faGift } from '@fortawesome/pro-regular-svg-icons/faGift';
import { faHome } from '@fortawesome/pro-regular-svg-icons/faHome';
import { faImages } from '@fortawesome/pro-regular-svg-icons/faImages';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle';
import { faLifeRing } from '@fortawesome/pro-regular-svg-icons/faLifeRing';
import { faLightbulb } from '@fortawesome/pro-regular-svg-icons/faLightbulb';
import { faLock } from '@fortawesome/pro-regular-svg-icons/faLock';
import { faLockAlt } from '@fortawesome/pro-regular-svg-icons/faLockAlt';
import { faLockOpen } from '@fortawesome/pro-regular-svg-icons/faLockOpen';
import { faLockOpenAlt } from '@fortawesome/pro-regular-svg-icons/faLockOpenAlt';
import { faMegaphone } from '@fortawesome/pro-regular-svg-icons/faMegaphone';
import { faMinus } from '@fortawesome/pro-regular-svg-icons/faMinus';
import { faPaperclip } from '@fortawesome/pro-regular-svg-icons/faPaperclip';
import { faPen } from '@fortawesome/pro-regular-svg-icons/faPen';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons/faPlusCircle';
import { faProjectDiagram } from '@fortawesome/pro-regular-svg-icons/faProjectDiagram';
import { faPuzzlePiece } from '@fortawesome/pro-regular-svg-icons/faPuzzlePiece';
import { faRulerHorizontal } from '@fortawesome/pro-regular-svg-icons/faRulerHorizontal';
import { faSatelliteDish } from '@fortawesome/pro-regular-svg-icons/faSatelliteDish';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { faSignOut } from '@fortawesome/pro-regular-svg-icons/faSignOut';
import { faSitemap } from '@fortawesome/pro-regular-svg-icons/faSitemap';
import { faSmilePlus } from '@fortawesome/pro-regular-svg-icons/faSmilePlus';
import { faSortAlphaDown } from '@fortawesome/pro-regular-svg-icons/faSortAlphaDown';
import { faSortAlphaUp } from '@fortawesome/pro-regular-svg-icons/faSortAlphaUp';
import { faStar } from '@fortawesome/pro-regular-svg-icons/faStar';
import { faStream } from '@fortawesome/pro-regular-svg-icons/faStream';
import { faTable } from '@fortawesome/pro-regular-svg-icons/faTable';
import { faTag } from '@fortawesome/pro-regular-svg-icons/faTag';
import { faTags } from '@fortawesome/pro-regular-svg-icons/faTags';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons/faTimesCircle';
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons/faTrashAlt';
import { faUnlink } from '@fortawesome/pro-solid-svg-icons/faUnlink';
import { faUnlock } from '@fortawesome/pro-regular-svg-icons/faUnlock';
import { faUnlockAlt } from '@fortawesome/pro-regular-svg-icons/faUnlockAlt';
import { faUser } from '@fortawesome/pro-regular-svg-icons/faUser';
import { faUserCircle } from '@fortawesome/pro-regular-svg-icons/faUserCircle';
import { faUserEdit } from '@fortawesome/pro-regular-svg-icons/faUserEdit';
import { faUserFriends } from '@fortawesome/pro-regular-svg-icons/faUserFriends';
import { faUserLock } from '@fortawesome/pro-regular-svg-icons/faUserLock';
import { faUsers } from '@fortawesome/pro-regular-svg-icons/faUsers';
import { faBorderAll } from '@fortawesome/pro-regular-svg-icons/faBorderAll';

export default [
  faArrowsH,
  faArrowToBottom,
  faArrowToRight,
  faBallotCheck,
  faBan,
  faBolt,
  faBookReader,
  faBuilding,
  faChartLine,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCode,
  faCog,
  faComment,
  faCommentAltLines,
  faCommentAltPlus,
  faCommentLines,
  faCreditCard,
  faEllipsisH,
  faEllipsisV,
  faExchange,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFileInvoice,
  faFilePdf,
  faFileSpreadsheet,
  faFilter,
  faFlaskPotion,
  faGift,
  faHome,
  faImages,
  faInfoCircle,
  faLifeRing,
  faLightbulb,
  faLock,
  faLockAlt,
  faLockOpen,
  faLockOpenAlt,
  faMegaphone,
  faMinus,
  faPaperclip,
  faPen,
  faPlus,
  faPlusCircle,
  faProjectDiagram,
  faPuzzlePiece,
  faRulerHorizontal,
  faSatelliteDish,
  faSearch,
  faSignOut,
  faSitemap,
  faSmilePlus,
  faSortAlphaDown,
  faSortAlphaUp,
  faStar,
  faStream,
  faTable,
  faTag,
  faTags,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faUnlink,
  faUnlock,
  faUnlockAlt,
  faUser,
  faUserCircle,
  faUserEdit,
  faUserFriends,
  faUserLock,
  faUsers,
  faBorderAll,
];
