import FormSwitch from '@widgets/Form/FormSwitch/FormSwitch';
import cnameHelper from '@/utils/helpers/CNameHelper';
import { insertTable } from '@/utils/helpers/tinymceTableHelper';

// @vue/component
export default {
  props: {
    initCols: {
      default: 4,
      type: Number,
    },
    initRows: {
      default: 4,
      type: Number,
    },
    maxCols: {
      default: 10,
      type: Number,
    },
    maxRows: {
      default: 10,
      type: Number,
    },
  },
  components: {
    FormSwitch,
  },
  data() {
    return {
      totalCols: 0,
      totalRows: 0,
      highlightedCell: { row: 0, cell: 0 },
      withHeader: false,
    };
  },
  computed: {
    hasHighlightedCells() {
      return this.highlightedCell.row !== 0;
    },
  },
  methods: {
    setHighlightedCell(row, col) {
      this.highlightedCell = {
        row, col,
      };
    },

    onInsertTableClicked(rows, cols) {
      const editor = window.tinyMCE.activeEditor;

      insertTable(cols, rows, this.withHeader, editor);

      this.removeMouseoutListener();

      this.$emit('table-inserted');

      this.resetCells();

      if (this.$ga) {
        const activeSpace = this.$store.getters['navigation/activeSpace'];

        this.$ga.event({
          eventCategory: 'editor-actions',
          eventAction: `insert-table-${cols}-${rows}`,
          ...(activeSpace ? {
            eventLabel: `${cnameHelper()} | ${activeSpace.title} | ${this.$store.getters['navigation/activePage'].title}`,
          } : {}),
          eventValue: 1,
        });
      }
    },

    addRows(rows) {
      if (this.totalRows + rows > this.maxRows) {
        this.totalRows = this.maxRows;
        this.setHighlightedCell(this.totalRows, this.highlightedCell.col);
        return;
      }

      this.totalRows += rows;
    },

    addCols(cols) {
      if (this.totalCols + cols > this.maxCols) {
        this.totalCols = this.maxCols;
        this.setHighlightedCell(this.highlightedCell.row, this.totalCols);
        return;
      }

      this.totalCols += cols;
    },

    addMouseoutListener() {
      this.$el.addEventListener('mouseout', this.onMouseout);
    },

    removeMouseoutListener() {
      this.$el.removeEventListener('mouseout', this.onMouseout);
    },

    resetCells() {
      this.highlightedCell = { row: 0, col: 0 };
    },

    onClickOutside() {
      this.resetCells();
    },
  },
  created() {
    this.totalCols = this.initCols;
    this.totalRows = this.initRows;
  },
  mounted() {
    this.addMouseoutListener();
  },
  watch: {
    highlightedCell: {
      handler(highlightedCell) {
        if (highlightedCell.row < this.maxRows) {
          this.totalRows = this.highlightedCell.row + 1;
        }

        if (highlightedCell.col < this.maxCols) {
          this.totalCols = this.highlightedCell.col + 1;
        }

        if (highlightedCell.row < this.initRows) {
          this.totalRows = this.initRows;
        }

        if (highlightedCell.col < this.initCols) {
          this.totalCols = this.initCols;
        }
      },
    },
  },
};
