import processTinymceCommand from '@/utils/helpers/processTinymceCommand';
import cnameHelper from '@/utils/helpers/CNameHelper';
import tinymceCommands from '@/utils/constants/tinymceCommands';

// @vue/component
export default {
  methods: {
    executeCommand(type, typeValue) {
      return new Promise((resolve, reject) => {
        if (type in tinymceCommands) {
          const value = typeValue || tinymceCommands[type].value;

          const { command } = tinymceCommands[type];

          processTinymceCommand({ command, value });

          let eventAction;

          if (command === value || !value) {
            eventAction = command;
          } else {
            eventAction = `${command}-${value}`;
          }

          if (this.$ga) {
            const activeSpace = this.$store.getters['navigation/activeSpace'];

            this.$ga.event({
              eventCategory: 'editor-actions',
              eventAction,
              ...(activeSpace ? {
                eventLabel: (() => [
                  cnameHelper(),
                  activeSpace.title,
                  this.$store.getters['navigation/activePage'].title,
                ]
                  .filter((a) => a)
                  .join(' | '))(),
              } : {}),
              eventValue: 1,
            });
          }

          resolve();
        } else {
          reject('Type not registered in command map');
        }
      });
    },
  },
};
