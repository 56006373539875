const getKeyOrKeyCode = (event) => event.key || event.keyCode;

// `keyObject` is expected to derive from utils/constants/keyboardKeys.js
const isEventKeyEqualTo = (event, comparisonKey) => {
  if (typeof event !== 'object') {
    throw new Error('\'event\' passed is not an object');
  }

  if (!comparisonKey || !comparisonKey.key || !comparisonKey.keyCode) {
    throw new Error('\'comparisonKey\' does not contain \'key\' or \'keyCode\' properties');
  }

  const key = getKeyOrKeyCode(event);

  return key === comparisonKey.key || key === comparisonKey.keyCode;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  isEventKeyEqualTo,
};
