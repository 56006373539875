import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import LoadingPlaceholder from '@widgets/LoadingPlaceholder';
import getSpaceCodeAndPageIdFromUrl from '@/utils/helpers/getSpaceCodeAndPageIdFromUrl';
import { isIE } from '@/utils/helpers/device';

// @vue/component
export default {
  components: {
    AlertContainer: () => import('@widgets/AlertContainer'),
    IeUnsupportedBanner: () => import('@widgets/IeUnsupportedBanner'),
    LoadingPlaceholder,
    Lightbox: () => import('@sections/Lightbox'),
    ModalContainer: () => import('@sections/Modals/ModalContainer'),
    Tooltip: () => import('@widgets/Tooltip'),
    SharedSpacePage: () => import('@sections/SharedSpacePage/SharedSpacePage'),
  },
  data() {
    return {
      page: undefined,
      isLoading: false,
      httpError: null,
      session: undefined,
    };
  },
  computed: {
    ...mapGetters('modals', ['hasOpenModal']),

    ...mapState('ui/tooltip', {
      tooltipId: 'tooltipId',
      tooltipTitle: 'title',
      tooltipColour: 'colour',
      tooltipPopperConfig: 'popperConfig',
    }),

    ...mapState('lightbox', ['lightboxProps']),

    ...mapGetters('navigation', ['activePage']),

    ...mapGetters('session', [
      'isGuest',
      'isGuestFromPublicURL',
      'isGuestFromEmailURL',
    ]),

    shouldShowIeBanner() {
      return isIE();
    },

  },
  methods: {
    ...mapMutations('ui/sharedSpacePage', ['setSharingToken']),

    ...mapActions('pages', {
      fetchPage: 'fetchSingle',
    }),

    ...mapActions('comments', {
      fetchComments: 'fetchList',
    }),

    ...mapActions('apps', ['loadApps']),

    ...mapActions('session', { fetchSession: 'fetch' }),

    ...mapActions('emojis', ['parseEmojiData']),

    logGaEvent() {
      let eventAction;

      if (!this.isGuest) {
        eventAction = 'auth-user';
      }

      if (this.isGuestFromPublicURL) {
        eventAction = 'public-url';
      }

      if (this.isGuestFromEmailURL) {
        eventAction = 'email-url';
      }

      if (this.$ga && eventAction) {
        this.$ga.event({
          eventCategory: 'shared-page',
          eventAction: `shared-page-viewed-${eventAction}`,
          eventLabel: `Share link opened by ${eventAction}`,
          eventValue: 1,
        });
      }
    },
  },
  created() {
    this.setSharingToken(new URL(window.location.href).searchParams.get('sharingtoken'));

    const { spaceCode, pageId } = getSpaceCodeAndPageIdFromUrl(window.location.pathname);

    Promise.all([
      this.fetchPage({
        customUrlFnArgs: [spaceCode],
        id: pageId,
        config: {
          params: {
            include: 'spaces',
          },
        },
      }),
      this.fetchComments({
        customUrlFnArgs: [spaceCode, pageId],
      }),
      this.fetchSession().then(() => {
        this.logGaEvent();
      }),
    ]).catch((e) => {
      this.httpError = e.response;
    });

    this.loadApps();
    this.parseEmojiData();
  },
};
