export default {
  props: {
    customError: {
      type: String,
      default: null,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fieldLabel: {
      type: String,
      default: '',
    },
    hideErrorMessage: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: [String, Array, Number, Object],
    },
    vuelidateOptions: {
      type: [Object, Function],
      default: () => { },
    },
  },
  computed: {
    validationError() {
      return (
        this.vuelidateOptions
          && this.vuelidateOptions.$dirty
          && this.vuelidateOptions.$invalid
      ) || this.customError;
    },

    validationErrorMessage() {
      if (!this.validationError || this.hideValidationErrorMessage) {
        return '';
      }

      if (this.customError) {
        return this.customError;
      }

      let condition = '';

      if (!this.vuelidateOptions) {
        return '';
      }

      if (this.vuelidateOptions.spaceCodeReserved === false) {
        return 'This slug cannot be used as it is a reserved name';
      }

      if (this.vuelidateOptions.spaceCodeUniqueness === false) {
        return 'This code is in use by another Space';
      }

      if (this.vuelidateOptions.spaceCodePattern === false) {
        condition = 'must contain only numbers, hyphens and at least one lowercase alphabet character i.e. \'my-space\'';
      }

      if (this.vuelidateOptions.pageSlugPattern === false) {
        condition = 'must contain only numbers, hyphens and at least one lowercase alphabet character i.e. \'my-page\'';
      }

      if (this.vuelidateOptions.editorAnchorPattern === false) {
        condition = 'should start with letter, and contain only letters, numbers, dashes, dots, colons or underscores.';
      }

      if (this.vuelidateOptions.phoneNumberValidator === false) {
        condition = 'must contain only numbers, hyphens, plus sign or brackets';
      }

      if (this.vuelidateOptions.alphanumericWithSpaces === false) {
        return this.vuelidateOptions.$params.alphanumericWithSpaces.message;
      }

      if (this.vuelidateOptions.urlValidator === false) {
        return this.vuelidateOptions.$params.urlValidator.message;
      }

      if (this.vuelidateOptions.externalLinkValidator === false) {
        return this.vuelidateOptions.$params.externalLinkValidator.message;
      }

      /* A strict false check is required for vuelidate validation options. This is to
         prevent issues arising when options are not included on the definition object */
      if (this.vuelidateOptions.required === false) {
        if (Array.isArray(this.value)) {
          condition = 'must have at least one option selected'; // This should be rewritten in the future
        } else {
          condition = 'is required';
        }
      }

      if (this.vuelidateOptions.maxLength === false) {
        return `Maximum ${this.vuelidateOptions.$params.maxLength.max} characters`;
      }

      if (this.vuelidateOptions.minLength === false) {
        return `Minimum ${this.vuelidateOptions.$params.minLength.min} characters`;
      }

      if (this.vuelidateOptions.alpha === false) {
        condition = 'must contain alphabet characters';
      }

      if (this.vuelidateOptions.numeric === false) {
        condition = 'must contain numeric characters';
      }

      if (this.vuelidateOptions.alphaNum === false) {
        condition = 'must contain alphabet or numeric characters';
      }

      if (this.vuelidateOptions.email === false) {
        condition = 'must be a valid email format';
      }

      if (this.vuelidateOptions.url === false) {
        condition = 'must be a valid url format';
      }

      if (this.vuelidateOptions.isValidTime === false) {
        condition = 'must be in the format of HH:MM';
      }

      if (this.vuelidateOptions.isValidDate === false) {
        condition = 'must be a valid date';
      }

      if (this.vuelidateOptions.sameAsPassword === false) {
        return 'Passwords do not match';
      }

      if (this.vuelidateOptions.reservedHomepageSlug === false) {
        return '\'home\' cannot be used as it\'s reserved for the homepage of this Space';
      }

      if (condition) {
        const label = this.fieldLabel || 'This field';
        return `${label} ${condition}`;
      }

      return '';
    },
  },
};
