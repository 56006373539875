import Vue from 'vue';
import spacesService from '@/api/spacesService';
import isSharedSpacePageURL from '@/utils/helpers/isSharedSpacePageURL';
import { initGlobalPresence } from '@/utils/helpers/globalPresence';
import App from '@/components/App';
import SharedApp from '@/components/SharedApp';
import AppDownError from '@/components/pages/Error/AppDownError';
import router from '@/router';
import store from '@/store';
import eagerStart from './eagerStart';

// Globals
window.DEBUG_MODE = true;
Vue.config.productionTip = window.DEBUG_MODE;

function mountApp(AppRoot, isImpersonating = false) {
  // Vue Config
  const vueConfig = {
    el: '#app',
    store,
    router,
    data: { isImpersonating },
    created() {
      this.isSharedSpacePageURL = isSharedSpacePageURL(window.location.href);
    },
    render(h) {
      return h(AppRoot);
    },
  };
  /* eslint-disable-next-line no-new */
  new Vue(vueConfig);
}

async function startAuthenticatedSession(path) {
  eagerStart(path);
  try {
    const response = await spacesService.getSession();
    const isImpersonating = !!(response
  && response.headers
  && response.headers['X-Twimpersonating']);
    initGlobalPresence();
    mountApp(App, isImpersonating);
  } catch (error) {
    // Ignore 401 as it's handled by HTTP client
    if (error.response && error.response.status === 401) {
      return;
    }

    // Ignore 402 as these errors are handled by CheckoutHandler
    if (error.response && error.response.status === 402) {
      mountApp(App, false);
      return;
    }

    mountApp(AppDownError);
  }
}

export default function () {
  if (isSharedSpacePageURL(window.location.href)) {
    mountApp(SharedApp);
  } else {
    startAuthenticatedSession(location.pathname);
  }
}
