import { bindComponentToInsertFn } from '@/utils/editor/insertFunctions';
import {
  customElementComponents as internalCustomElementComponentsMeta,
  builtInTinymceComponents as builtInTinymceComponentsMeta,
} from '@/utils/editor/config/internalEditorComponentsMeta';

export default {
  namespaced: true,
  getters: {
    get(state, getters, rootState) {
      return [
        ...internalCustomElementComponentsMeta,
        ...rootState.apps.editorComponents,
        ...builtInTinymceComponentsMeta,
      ];
    },
    customElementComponents(state, getters, rootState) {
      return [
        ...internalCustomElementComponentsMeta,
        ...rootState.apps.editorComponents,
      ];
    },
    devAppComponents(state, getters) {
      return getters.get.filter((component) => component.isDev);
    },
    elementNames(state, getters) {
      return getters.customElementComponents.map((component) => component.customElementName);
    },
    inlineComponents(state, getters) {
      return getters.customElementComponents.filter((component) => component.isInlineElement);
    },
    blockComponents(state, getters) {
      return getters.customElementComponents.filter((component) => !component.isInlineElement);
    },
    insertableComponents(state, getters, rootState, rootGetters) {
      function hasInsertFn(component) {
        return !!component.insertFn;
      }

      function isCompatibleSession(component) {
        return !(component.name === 'tw-spaces-core-accountability-chart'
          && !rootGetters['session/isInternalInstallation']);
      }

      return getters.get
        .filter(hasInsertFn)
        .filter(isCompatibleSession)
        .map(bindComponentToInsertFn);
    },
    insertableComponentByName(state, getters) {
      return (name) => getters.insertableComponents
        .find((c) => c.name === name);
    },
  },
  actions: {
    async insertComponent({ getters }, {
      name,
      editor,
      vueComponentInstance,
    }) {
      const component = getters.insertableComponentByName(name);
      if (!component) {
        throw new Error(`Insertable editor component of name "${name}" does not exist`);
      }
      component.insertFn(editor, vueComponentInstance);
    },
  },
};
