import axios from 'axios';
import { throttleAdapterEnhancer } from 'axios-extensions';
// import Vue from 'vue';

import { raiseSentryError } from '@/utils/helpers/sentryHelper';
// import cnameHelper from '@/utils/helpers/CNameHelper';
import { isDevEnvironment } from '@/utils/helpers/environment.utility';
import CHECKOUT_CODES from '@/utils/constants/checkoutCodes';
import store from '@/store';

import { FEATURE_KEYS } from '@/store/modules/features';
import mockCalls from './mocks';

const client = axios.create({
  adapter: throttleAdapterEnhancer(axios.defaults.adapter, { threshold: 100 }),
});

const commonHeaders = {
  'X-Requested-With': 'XMLHttpRequest',
  'Content-Type': 'application/json',
};

// support shared pages
const shareToken = new URL(window.location.href).searchParams.get('sharingtoken');
if (shareToken) {
  commonHeaders['X-Scope-Token'] = shareToken;
}

Object.assign(client.defaults.headers.common, commonHeaders);

let redirects = 0;

client.interceptors.request.use(
  (config) => config,
  /* eslint-disable-next-line no-param-reassign */
  /*  config.meta = {
        startTime: Math.round(performance.now()),
      }; */
  (error) => Promise.reject(error),
);

client.interceptors.response.use(
  (response) => response,
  /*  const timeTaken = Math.round(performance.now()) - response.config.meta.startTime;

      if (Vue.$ga && timeTaken > 0) {
        Vue.$ga.time({
          timingCategory: cnameHelper(), // use installation name
          timingVar: response.config.url, // endpoint
          timingValue: timeTaken, // time
          timingLabel: response.config.method,
        });
      } */
  (error) => {
    // dont raise sentry error or redirect if 401 on shared page
    const isSharedPage = !!shareToken;
    if (isSharedPage && error.response.status === 401) {
      return Promise.reject(error);
    }

    raiseSentryError(error);

    if (error && error.response) {
      if (
        error.response.status === 401
        && !redirects
        && !error.config.ignore401Redirect
        && !isSharedPage
      ) {
        redirects += 1;

        const routerBasePathName = window.location.pathname;
        const routerBasePath = `${window.location.origin}${routerBasePathName}`;
        const launchpadBasePath = `${window.location.origin}/launchpad/login?continue=`;

        window.location.href = `${launchpadBasePath}${encodeURIComponent(`${routerBasePath}`)}`;
      }

      const code = error
        && error.response
        && error.response.data
        && error.response.data.errors
        && error.response.data.errors[0]
        && error.response.data.errors[0].code;

      /*
       * Note: actions which trigger XHR calls that could result in these errors should be
       * locked down/hidden in the UI and thus realistically shouldn't ever occur. This is just
       * extra defence for the unlikely scenario where the API reports one of these errors
       */

      // Note: error code 106 (user account disabled) is returned with a 403 HTTP status code
      if (code && (error.response.status === 402 || error.response.status === 403)) {
        // Special cases to handling request methods other then GET (avoid last conditional)
        if (code === CHECKOUT_CODES.PAGE_LIMIT_REACHED) {
          store.dispatch('features/triggerUpgradeModal', { feature: FEATURE_KEYS.UNLIMITED_PAGES }, { root: true });
          return Promise.reject(error);
        }

        if (code === CHECKOUT_CODES.USER_LIMIT_REACHED) {
          store.dispatch('features/triggerUpgradeModal', { feature: FEATURE_KEYS.UNLIMITED_USERS }, { root: true });
          return Promise.reject(error);
        }

        if (code === CHECKOUT_CODES.CUSTOM_PAGE_TEMPLATE_LIMIT_REACHED) {
          store.dispatch('features/triggerUpgradeModal', { feature: FEATURE_KEYS.UNLIMITED_CUSTOM_PAGE_TEMPLATES }, { root: true });
          return Promise.reject(error);
        }

        if (code === CHECKOUT_CODES.PUBLIC_PAGE_SHARING_LIMIT_REACHED) {
          store.dispatch('features/triggerUpgradeModal', { feature: FEATURE_KEYS.UNLIMITED_PUBLIC_PAGE_SHARING }, { root: true });
          return Promise.reject(error);
        }

        if (code === CHECKOUT_CODES.FEATURE_RESTRICTED) {
          const featureEndpointMap = Object.freeze({
            [FEATURE_KEYS.REQUIRED_READING_REPORTING]: {
              '/requiredreading/spaces/[^/]+/pages/[^/]+/stats.json': ['GET'],
            },
            [FEATURE_KEYS.PAGE_PRIVACY]: {
              '/spaces/[^/]+/pages/[^/]+/permissions.json': ['GET', 'POST', 'DELETE'],
            },
            [FEATURE_KEYS.SPACE_PDF_EXPORT]: {
              '/spaces/[^/]+/export.json': ['GET', 'HEAD'],
            },
            // Special case - user management
            'user-management': {
              '/users/invite.json': ['POST'],
              '/users/bulk/invite.json': ['POST'],
            },
          });

          // Determine the feature name based on the endpoint and method
          const [feature] = Object.entries(featureEndpointMap)
            .find(([, endpointMap]) => Object.entries(endpointMap)
              .some(([endpointRegex, methods]) => (
                new RegExp(endpointRegex).test(error.config.url)
                      && methods.includes(error.config.method.toUpperCase())
              )),
            );

          // Special case - user management - this isn't an option on either of the add-on plans so
          // we need to handle it slightly differently
          if (feature === 'user-management') {
            store.dispatch('alerts/showAlert', { type: 'error', message: 'You don\'t have permission to perform that action' }, { root: true });
            return Promise.reject(error);
          }

          store.dispatch('features/triggerUpgradeModal', { feature }, { root: true });
          return Promise.reject(error);
        }

        // Only set error code if the requst is a GET
        // This will prevent locking of the app for intentional updates/deletes
        if (error.config && error.config.method === 'get') {
          store.dispatch('installation/setCheckoutErrorCode', code);
        }
      }
    }

    return Promise.reject(error);
  },
);

if (isDevEnvironment) {
  mockCalls(client);
}

export default client;
