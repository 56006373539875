// Based on https://github.com/AlexandreBonaventure/vue-mq

import { isArray } from 'lodash-es';

const component = {
  props: {
    mq: {
      required: true,
      type: [String, Array],
    },
  },
  computed: {
    plusModifier() { return !isArray(this.mq) && this.mq.slice(-1) === '+'; },

    minusModifier() { return !isArray(this.mq) && this.mq.slice(-1) === '-'; },

    activeBreakpoints() {
      const breakpoints = Object.keys(this.$mqAvailableBreakpoints);

      if (this.plusModifier) {
        const index = breakpoints.findIndex((b) => b === this.mq.slice(0, -1));
        return breakpoints.slice(index);
      }

      if (this.minusModifier) {
        const index = breakpoints.findIndex((b) => b === this.mq.slice(0, -1));
        return breakpoints.slice(0, index + 1);
      }

      return (isArray(this.mq) ? this.mq : [this.mq]);
    },
  },
  render(h) {
    if (this.activeBreakpoints.includes(this.$mq)
      && this.$slots
      && this.$slots.default
      && this.$slots.default[0]
    ) {
      return this.$slots.default[0];
    }

    return h();
  },
};

export default component;
