import Vue from 'vue';

import cnameHelper from '@/utils/helpers/CNameHelper';
import { createCrudModule } from '@/utils/vuex';
import RESOURCES from '@/utils/constants/resources';
import { SORT_ORDER_KEYS } from '@/utils/constants/home';
import { PERMISSION_LEVELS } from '@/utils/constants/spacePermissions';
import { ROUTE_NAMES } from '@/router/routes';

import store from '..';

const SINGLE_PROPERTY = RESOURCES.SPACE;
const LIST_PROPERTY = RESOURCES.SPACE_PLURAL;

export function filterSpaces(spaces, filterQuery) {
  if (!Array.isArray(spaces)) {
    return [];
  }

  return spaces
    .filter((x) => x.title.toLowerCase().includes(filterQuery.toLowerCase()));
}

export function sortSpaces(spaces, sortOrder) {
  if (!Array.isArray(spaces)) {
    return [];
  }

  return spaces
    .sort((x, y) => {
      if (sortOrder === SORT_ORDER_KEYS.ALPHABETICAL) {
        return x.title.toLowerCase() > y.title.toLowerCase() ? 1 : -1;
      }

      if (sortOrder === SORT_ORDER_KEYS.REVERSE_ALPHABETICAL) {
        return y.title.toLowerCase() > x.title.toLowerCase() ? 1 : -1;
      }

      if (sortOrder === SORT_ORDER_KEYS.NEWEST_FIRST) {
        return x.id < y.id ? 1 : -1;
      }

      if (sortOrder === SORT_ORDER_KEYS.OLDEST_FIRST) {
        return y.id < x.id ? 1 : -1;
      }

      return y.id < x.id ? 1 : -1;
    });
}

export function filterAndSortSpaces(spaces, filterQuery, sortOrder) {
  if (!Array.isArray(spaces)) {
    return [];
  }

  return sortSpaces(filterSpaces(spaces, filterQuery), sortOrder);
}

export default createCrudModule({
  resource: SINGLE_PROPERTY,
  singleProperty: SINGLE_PROPERTY,
  listProperty: LIST_PROPERTY,
  getters: {
    byStarred: (state, getters, rootState, rootGetters) => (
      rootGetters && rootGetters['starred/spaceIds']
        && getters.list
        && getters.list.filter((space) => rootGetters['starred/spaceIds'].includes(space.id))
    ) || [],

    byStarredAndTitle(state, getters) {
      return (title, order) => filterAndSortSpaces(getters.byStarred, title, order);
    },

    byCode(state) {
      return (code) => Object.values(state.entities)
        .find((x) => x.code.toLowerCase() === code.toLowerCase());
    },

    byTitle(state) {
      return (title, order) => filterAndSortSpaces(Object.values(state.entities), title, order);
    },

    dropdownByTitle(state, getters, rootState, rootGetters) {
      const starredSpacesIds = rootGetters['starred/spaceIds'];
      return (title) => Object.values(state.entities)
        .filter((x) => x.title.toLowerCase().includes(title.toLowerCase()))
        .sort((x, y) => (x.title.toLowerCase() > y.title.toLowerCase() ? 1 : -1))
        .map((space) => ({
          id: space.id,
          title: space.title,
          isStarred: starredSpacesIds.includes(space.id),
          uri: { name: ROUTE_NAMES.SPACE, params: { spaceCode: space.code } },
          icon: () => import('@widgets/SpaceIcon'),
          iconProps: {
            title: space.title,
            icon: space.icon,
            color: space.spaceColor,
          },
        }));
    },

    dropdownByTitleGroupedByStarred(state, getters) {
      return (title = '') => getters.dropdownByTitle(title)
        .sort((x, y) => y.isStarred - x.isStarred || x.title.toLowerCase() > y.title.toLowerCase());
    },

    isSpacesListEmpty(state, getters) {
      return getters.list && getters.list.length === 0;
    },
  },
  mutations: {
    setSpace(state, space) {
      Vue.set(state.entities, space.id, space);
    },

    setCategoryForSpace(state, { spaceId, categoryId }) {
      if (!state.entities[spaceId]) {
        throw new Error(`Cannot set category for space (ID: ${spaceId}) as that space does not exist`);
      }

      const category = categoryId
        ? { id: categoryId, type: RESOURCES.CATEGORY_PLURAL }
        : null;

      Vue.set(state.entities[spaceId], spaceId, category);
    },

    removeSpace(state, id) {
      Vue.delete(state.entities, id);
    },
  },
  actions: {
    // To support Marks Api change we will return
    // the max size of 250 spaces
    // default for spaces.json will now be a page of 50
    // we really always want all the spaces
    // realistically should we be able to pass a much larger number here
    // I believe we should always get all spaces a user has access to in one go
    // subject to change, this is the current server max.
    getPageOfSpaces({ dispatch }) {
      return dispatch(
        'fetchList', {
          config: {
            params: {
              pageSize: 250,
            },
          },
        },
      );
    },

    updateSpaceIcon({ state, dispatch }, { spaceId, icon }) {
      if (!spaceId || !state.entities[spaceId]) {
        throw new Error('\'spaceId\' attribute is missing or invalid');
      }

      dispatch(
        'spaces/update',
        {
          id: spaceId,
          data: {
            icon,
          },
          isOptimistic: true,
        },
        { root: true },
      );
    },

    updateSpaceColor({ state, dispatch }, { spaceId, color }) {
      if (!spaceId || !state.entities[spaceId]) {
        throw new Error('\'spaceId\' attribute is missing or invalid');
      }

      dispatch(
        'spaces/update',
        {
          id: spaceId,
          data: {
            spaceColor: color,
          },
          isOptimistic: true,
        },
        { root: true },
      );
    },
  },

  onCreateSuccess(state, response) {
    const space = response.data;
    const spaceId = Number(space.id);
    const categoryId = (space.category && space.category.id) ? Number(space.category.id) : 0;

    // Refetch the links on Space creation as we have a new home page
    store.dispatch('ui/links/fetch');

    // Ensure the new space is added to the list of session space permissions in the 'session' module
    store.dispatch('session/setSpacePermission', { spaceId, permissionLevel: PERMISSION_LEVELS.MANAGE });

    // Ensure the new space is added to the appropriate category in the 'spaceCategories' module
    store.dispatch('spaceCategories/addSpace', { spaceId, categoryId });

    if (Vue.$ga) {
      Vue.$ga.event({
        eventCategory: 'spaces',
        eventAction: 'created',
        eventLabel: cnameHelper(),
        eventValue: 1,
      });
    }
  },

  onUpdateSuccess(state, response) {
    const space = response.data;
    const spaceId = Number(space.id);
    const categoryId = (space.category && space.category.id) ? Number(space.category.id) : 0;

    // Refetch page links on updating a space as title could be different
    store.dispatch('ui/links/fetch');

    // Keep the 'spaceCategories' module in sync
    store.dispatch('spaceCategories/updateSpace', { spaceId, categoryId });

    if (Vue.$ga) {
      Vue.$ga.event({
        eventCategory: 'spaces',
        eventAction: 'updated',
        eventLabel: cnameHelper(),
        eventValue: 1,
      });
    }
  },

  onDestroySuccess(state, id) {
    const spaceId = Number(id);

    // Note: we need to re-fetch notifications here as this deletion will trigger updates to
    // the list of notifications
    store.dispatch('notifications/fetchList');

    // We need to refetch the page links if the space is deleted.
    store.dispatch('ui/links/fetch');

    // Ensure the new space is removed from the list of session space permissions in the 'session' module
    store.dispatch('session/setSpacePermission', { spaceId, permissionLevel: null });

    // Ensure the new space is removed from the appropriate category in the 'spaceCategories' module
    store.dispatch('spaceCategories/deleteSpace', { spaceId });

    if (Vue.$ga) {
      Vue.$ga.event({
        eventCategory: 'spaces',
        eventAction: 'deleted',
        eventLabel: cnameHelper(),
        eventValue: 1,
      });
    }
  },
});
