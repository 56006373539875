import DefaultErrorIllustration from '@/assets/states/default/error.svg';
import ErrorContainer from '@pages/Error/ErrorContainer';

// @vue/component
export default {
  components: {
    DefaultErrorIllustration,
    ErrorContainer,
  },
  methods: {
    refresh() {
      window.location.reload();
    },
  },
};

