const maxAlerts = 3;

export default {
  namespaced: true,
  state: {
    alerts: [],
  },
  getters: {
    hasActivePersistentAlerts(state) {
      return state.alerts.filter((a) => a.isPersistent).length > 0;
    },
  },
  mutations: {
    addAlert(state, {
      type, message, id, isPersistent, ctaText, ctaHandler, wrapText,
    }) {
      if (state.alerts.length === maxAlerts) {
        state.alerts.shift();
      }
      const alert = {
        type,
        message,
        id,
        isPersistent,
        ctaText,
        ctaHandler,
        wrapText,
      };
      state.alerts.push(alert);
    },

    deleteAlert(state, { index }) {
      state.alerts.splice(index, 1);
    },
  },
  actions: {
    showAlert({ commit }, {
      type, message, isPersistent, ctaText, ctaHandler, wrapText,
    }) {
      const id = Date.now();
      commit('addAlert', {
        type, message, id, isPersistent, ctaText, ctaHandler, wrapText,
      });
      return id;
    },

    closeAlert({ commit }, { index }) {
      commit('deleteAlert', { index });
    },

    closeAllAlerts({ commit, state }) {
      state.alerts.forEach((alert, index) => {
        commit('deleteAlert', { index });
      });
    },
  },
};
