import { isFunction } from 'lodash-es';
import Vue from 'vue';

import EditLinkPopover from '@sections/SpacesEditor/EditLinkPopover';

import createPopper from '@/utils/popper/createPopper';
import store from '@/store';

// @vue/component
export default {
  data() {
    return {
      popper: null,
      instance: null,
      currentNodeEl: null,
    };
  },
  methods: {
    shouldShowEditLinkPopover(editor, e) {
      // OT not enabled.
      if (!editor.plugins.ot) {
        return;
      }

      if (editor.plugins.ot.comments.canAdd()) {
        this.removeAnchorPopover();
        return;
      }

      this.$nextTick(() => {
        // Note: there seeems to be an issue where TinyMCE is firing the 'NodeChange' event
        // twice (hence this check)
        if (e.element === this.currentNodeEl) {
          return;
        }
        this.currentNodeEl = e.element;

        if (this.currentNodeEl.classList.contains('ot-anchor')) {
          return;
        }

        if (this.currentNodeEl.tagName === 'A') {
          this.showEditLinkPopover(editor, e);
        } else if (this.currentNodeEl.parentNode && this.currentNodeEl.parentNode.tagName === 'A') {
          this.showEditLinkPopover(editor, e, true);
        } else {
          this.removeAnchorPopover();
        }
      });
    },

    showEditLinkPopover(editor, e, useParent) {
      const vm = this;
      this.removeAnchorPopover();

      if (useParent) {
        this.currentNodeEl = e.element.parentNode;
      } else {
        this.currentNodeEl = e.element;
      }

      if (!this.instance) {
        const ComponentClass = Vue.extend(EditLinkPopover);
        this.instance = new ComponentClass({
          store,
          propsData: {
            text: this.currentNodeEl.innerText,
            href: this.currentNodeEl.getAttribute('href'),
            target: this.currentNodeEl.hasAttribute('target') ? this.currentNodeEl.getAttribute('target') : '',
          },
          created() {
            this.$on('remove', () => {
              editor.execCommand('unlink');
            });

            this.$on('change', (newText, newLink, newTarget) => {
              vm.currentNodeEl.setAttribute('href', newLink);
              vm.currentNodeEl.innerText = newText;

              if (newTarget) {
                vm.currentNodeEl.setAttribute('target', newTarget);
                vm.currentNodeEl.setAttribute('rel', 'noopener');
              } else {
                vm.currentNodeEl.removeAttribute('target');
                vm.currentNodeEl.removeAttribute('rel');
              }

              vm.removeAnchorPopover();
            });

            this.$on('start-editing', () => {
              vm.repositionAnchorPopover();
            });

            this.$on('stop-editing', () => {
              vm.repositionAnchorPopover();
            });
          },
        });

        this.instance.$mount();

        vm.$el.appendChild(this.instance.$el);
      } else {
        this.instance.text = this.currentNodeEl.innerText;
        this.instance.href = this.currentNodeEl.getAttribute('href');
        this.instance.target = this.currentNodeEl.hasAttribute('target') ? this.currentNodeEl.getAttribute('target') : '';
      }

      if (!this.popper) {
        this.popper = createPopper(
          this.currentNodeEl,
          this.instance.$el,
          {
            placement: 'top',
            modifiers: [
              {
                name: 'preventOverflow',
                options: {
                  altBoundary: true,
                },
              },
              {
                name: 'offset',
                options: {
                  offset: [0, 12],
                },
              },
              {
                name: 'arrow',
                options: {
                  element: '[data-popper-arrow]',
                },
              },
            ],
          },
        );
      } else {
        this.popper.reference = e.element;
        this.popper.update();
      }
    },

    repositionAnchorPopover() {
      if (this.popper) {
        this.popper.update();
      }
    },

    refreshAnchorPopover() {
      if (!this.instance || !this.currentNodeEl) {
        return;
      }

      this.instance.text = this.currentNodeEl.innerText;
      this.instance.href = this.currentNodeEl.getAttribute('href');
      this.instance.target = this.currentNodeEl.hasAttribute('target') ? this.currentNodeEl.getAttribute('target') : '';
    },

    removeAnchorPopover() {
      if (this.instance && this.instance.$el && this.instance.$el.parentNode) {
        this.instance.$el.parentNode.removeChild(this.instance.$el);
      }

      if (this.popper && isFunction(this.popper.destroy)) {
        this.popper.destroy();
      }

      if (this.instance && isFunction(this.instance.$destroy)) {
        this.instance.$destroy();
      }

      this.popper = null;
      this.instance = null;
      this.currentNodeEl = null;
    },

  },
};
