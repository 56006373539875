import { compact } from 'lodash-es';

/**
 * This is the base button component used in the app
 */
// @vue/component
export default {
  name: 'WButton',
  props: {
    type: {
      type: String,
      default: 'button',
    },
    /**
     * 'primary' | 'positive' | 'negative' | 'destructive' | 'tertiary' | 'subscribe' | 'default'
     */
    variant: {
      type: String,
      default: '',
    },
    /**
     * 'small' | 'large'
     */
    size: {
      type: String,
      default: '',
    },
    /**
     * Sets button width to fill available space
     */
    block: {
      type: Boolean,
      default: false,
    },
    rightAligned: {
      type: Boolean,
      default: false,
    },
    /**
     * html title attribute
     */
    title: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * disable state
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * displays spinner in place of content
     */
    loading: {
      type: Boolean,
      default: false,
    },
    route: {
      type: Object,
      default: null,
      required: false,
    },
    href: {
      type: String,
      default: null,
      required: false,
    },
    width: {
      type: String,
      required: false,
      default: null,
    },
    shouldShowSkeleton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    attributes() {
      return {
        class: this.buttonClasses,
        disabled: this.disabled,
        title: this.title,
        type: !(this.href || this.route) ? this.type : null,
        loading: this.loading,
        style: { minWidth: this.width },
      };
    },
    buttonClasses() {
      return [
        'btn',
        { 'btn--skeleton': this.shouldShowSkeleton },
        { [`btn--${this.size}`]: this.size },
        { 'btn--block': this.block },
        { 'btn--disabled': this.disabled || this.loading },
        { 'btn--loading': this.loading },
        { 'btn--icon-only': this.iconOnly },
        ...this.buttonVariants,
      ];
    },
    buttonVariants() {
      return compact(this.variant.split(' ')).map((variant) => `btn--${variant}`);
    },

    hasIcon() {
      return !!this.$slots.icon;
    },

    iconOnly() {
      return !!this.$slots.icon
        && !this.$slots.default;
    },
  },
  methods: {
    onClick(event) {
      if (this.disabled) {
        event.stopPropagation();
        event.preventDefault();
      } else {
        this.$emit('click', event);
      }
    },
  },
};
