import Bowser from 'bowser';

const isDeviceMacOs = () => {
  const parser = Bowser.getParser(window.navigator.userAgent);
  return parser.getOSName() === 'macOS';
};

const isDeviceWindows = () => {
  const parser = Bowser.getParser(window.navigator.userAgent);
  return parser.getOSName() === 'windows';
};

const isPlatform = (platform) => {
  const parser = Bowser.getParser(window.navigator.userAgent);
  return parser.getPlatformType() === platform;
};

const isMobile = () => isPlatform('mobile');

const isTablet = () => isPlatform('tablet');

const isDesktop = () => isPlatform('desktop');

const isIE = () => {
  const parser = Bowser.getParser(window.navigator.userAgent);
  return parser.isBrowser('Internet Explorer');
};

export {
  isDeviceMacOs,
  isDeviceWindows,
  isPlatform,
  isMobile,
  isTablet,
  isDesktop,
  isIE,
};
