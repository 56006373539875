import { uncompress } from '@/utils/emojis';

export default {
  namespaced: true,
  state: {
    categories: {},
    emojis: {},
  },
  actions: {
    parseEmojiData({ commit }) {
      (async () => {
        const emojiData = await import('@sections/EmojiPicker/emoji_data.json');
        const uncompressData = uncompress(emojiData.default);
        commit('setData', uncompressData);
      })();
    },
  },
  mutations: {
    setData(state, data) {
      state.categories = data.categories;
      state.emojis = data.emojis;
    },
    index(state, index) {
      state.index = index;
    },
  },
  getters: {
    categories: (state) => state.categories,
    emojis: (state) => state.emojis,
    emojiFromShortname: (state) => (shortname) => state.emojis[shortname],
  },
};
