import { directive as ClickOutside } from 'vue-on-click-outside';
import clipboard from './clipboard';
import stickToBottom from './stickToBottom';
import lineClamp from './lineClamp';
import visible from './visible';
import tooltip from './tooltip';

/* eslint-disable quote-props */
// Note: Directives must be registered in kebab-case
export default {
  'copy-to-clipboard': clipboard,
  'click-outside': ClickOutside,
  'stick-to-bottom': stickToBottom,
  'line-clamp': lineClamp,
  'visible': visible,
  'tooltip': tooltip,
};
