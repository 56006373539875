import Avatar from '@widgets/Avatar';
import BasePopover from '@widgets/Popover/BasePopover';

// @vue/component
export default {
  props: {
    presenceList: {
      type: Array,
      required: true,
    },
    visibleUsersCount: {
      type: Number,
      required: true,
    },
    contextText: {
      type: String,
      required: false,
      default: null,
    },
  },
  components: {
    Avatar,
    BasePopover,
  },
  computed: {
    visibleUserPresence() {
      return this.presenceList.slice(0, this.visibleUsersCount);
    },

    hiddenUserPresence() {
      return this.presenceList.slice(this.visibleUsersCount);
    },
  },
  methods: {
    tooltipSettings(presence) {
      if (presence.presenceColour) {
        return {
          title: this.$options.filters.fullName(presence.user),
          colour: presence.presenceColour,
        };
      }

      return this.$options.filters.fullName(presence.user);
    },
  },
};
