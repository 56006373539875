import { mapGetters } from 'vuex';

// @vue/component
export default {
  props: {
    text: {
      type: String,
      required: false,
      default: '',
    },
    icon: {
      type: [Object, Array, String],
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    route: {
      type: Object,
      default: null,
      required: false,
    },
    variant: {
      type: String,
      required: false,
      default: 'tertiary',
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters('navigation', [
      'activeSpaceColor',
      'isActivePageAHomepage',
      'activeSpace',
    ]),

    normalizedIcon() {
      if (this.icon === null) {
        return null;
      }

      if (typeof this.icon === 'object' && this.icon.prefix && this.icon.iconName) {
        return this.icon;
      }

      if (Array.isArray(this.icon) && this.icon.length === 2) {
        return { prefix: this.icon[0], iconName: this.icon[1] };
      }

      if (typeof this.icon === 'string') {
        return { prefix: 'fas', iconName: this.icon };
      }

      return null;
    },

    isIconOnly() {
      return (!this.text || this.text === '');
    },
  },
};
