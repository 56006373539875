import { hasActiveSpacePermission, hasActivePagePermission } from '@/utils/access-control';
import { PERMISSION_LEVELS } from '@/utils/constants/spacePermissions';

// @vue/component
const mixin = {
  computed: {
    userCanEditPage() {
      return hasActivePagePermission(PERMISSION_LEVELS.EDIT) || hasActivePagePermission(PERMISSION_LEVELS.MANAGE);
    },

    userCanReadPage() {
      return hasActivePagePermission(PERMISSION_LEVELS.READ);
    },

    userCanEditSpace() {
      return hasActiveSpacePermission(PERMISSION_LEVELS.EDIT) || hasActiveSpacePermission(PERMISSION_LEVELS.MANAGE);
    },

    userCanReadSpace() {
      return hasActiveSpacePermission(PERMISSION_LEVELS.READ);
    },

    userCanManageSpace() {
      return hasActiveSpacePermission(PERMISSION_LEVELS.MANAGE);
    },
  },
};

export default {
  install(Vue) {
    Vue.mixin(mixin);
  },
};
