import tinymceCommands from '@/utils/constants/tinymceCommands';

const getTableColsCount = (tableElement) => {
  const firstRowElement = tableElement.querySelector('tr');
  return firstRowElement.querySelectorAll('td').length;
};

const addTheadToTable = (tableElement, editorRef = window.tinymce.activeEditor) => {
  const theadElement = document.createElement('thead');
  const theadRowElement = document.createElement('tr');
  const theadTdElement = document.createElement('th');

  if (
    !(theadElement instanceof HTMLElement)
    || !(theadRowElement instanceof HTMLElement)
    || !(theadTdElement instanceof HTMLElement)
  ) {
    return;
  }

  /* eslint-disable-next-line no-plusplus */
  for (let i = 0; i < getTableColsCount(tableElement); i++) {
    theadRowElement.appendChild(theadTdElement.cloneNode());
  }

  theadElement.appendChild(theadRowElement);
  tableElement.insertBefore(theadElement, tableElement.firstChild);

  // setcursor position to first td in table
  const firstTdElement = theadElement.querySelector('td');
  editorRef.selection.setCursorLocation(firstTdElement, 0);
};

const insertRowBelow = (tableElement,
  isInsertingBelowThead = false,
  editorRef = window.tinymce.activeEditor) => {
  // if we are in the thead manually add our own row to the table tbody
  if (isInsertingBelowThead) {
    const selectionNode = editorRef.selection.getNode();
    const theadElement = editorRef.dom.getParent(selectionNode, 'thead');
    const tbodyElement = editorRef.dom.getNext(theadElement, 'tbody');

    // create row element to insert
    const rowElement = document.createElement('tr');
    const tdElement = document.createElement('td');
    const numberOfThs = theadElement.querySelectorAll('th').length;

    /* eslint-disable-next-line no-plusplus */
    for (let i = 0; i < numberOfThs; i++) {
      rowElement.appendChild(tdElement.cloneNode());
    }

    // prepend row into tbody
    tbodyElement.insertBefore(rowElement, tbodyElement.firstChild);

    // position cursor in new row at same column index
    const tdsElementsInNewRow = rowElement.querySelectorAll('td');

    editorRef.selection.setCursorLocation(tdsElementsInNewRow[selectionNode.cellIndex], 0);

    return;
  }
  editorRef.execCommand(tinymceCommands.insertRowBelow.command);
};

const insertTable = (
  cols = 2, rows = 3, withHeader = false, editorRef = window.tinymce.activeEditor,
) => {
  // we must give columns fixed width on insert to prevent
  // default fluid behaviour
  const tableElement = editorRef.plugins.table.insertTable(cols, rows);

  const tdElements = tableElement.querySelectorAll('td');

  /* eslint-disable-next-line no-plusplus */
  for (let i = 0; i < tdElements.length; i++) {
    const columnWidth = tdElements[i].getBoundingClientRect().width;
    /* eslint-disable-next-line no-param-reassign */
    tdElements[i].style.width = `${columnWidth}px`;
  }

  // insert thead
  if (withHeader) {
    addTheadToTable(tableElement, editorRef);
  }

  // Insert a line break after a table to ensure the cursor is placed after it
  const p = document.createElement('p');
  p.appendChild(document.createElement('br'));
  editorRef.getBody().appendChild(p);
};

/* eslint-disable import/prefer-default-export */
export {
  addTheadToTable,
  insertRowBelow,
  insertTable,
};
