import Vue from 'vue';

import { createCrudModule } from '@/utils/vuex';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import alphabeticalSort from '@/utils/helpers/alphabeticalSort';

export default createCrudModule({
  only: ['FETCH_LIST', 'CREATE', 'UPDATE', 'DESTROY'],
  resource: 'tags',
  customUrlFn(id) {
    return buildApiUrl({
      resource: id ? `tags/${id}` : 'tags',
    });
  },

  getters: {
    byNameAndAlphabeticalOrder(state, getters) {
      return (term = '') => { // eslint-disable-line
        if (term === '') {
          return getters.byAlphabeticalOrder;
        }

        return getters.list && getters.list
          .filter((tag) => tag.name.toLowerCase().includes(term.toLowerCase()))
          .sort((tagA, tagB) => alphabeticalSort(tagA.name.toLowerCase(), tagB.name.toLowerCase()));
      };
    },

    byAlphabeticalOrder(state, getters) {
      return getters.list && getters.list.sort((a, b) => { // eslint-disable-line
        return alphabeticalSort(a.name.toLowerCase(), b.name.toLowerCase());
      });
    },
  },

  mutations: {
    insertNewTag(state, tag) {
      if (tag && tag.id && tag.name && tag.color) {
        Vue.set(state.entities, tag.id, tag);
      }
    },
  },

  actions: {
    insertNewTag({ commit }, tag) {
      if (tag && tag.id && tag.name && tag.color) {
        commit('insertNewTag', tag);
      }
    },
  },

  onCreateSuccess() {
    if (Vue.$ga) {
      Vue.$ga.event({
        eventCategory: 'tags',
        eventAction: 'created',
        eventLabel: 'Tag has been created',
        eventValue: 1,
      });
    }
  },

  onUpdateSuccess() {
    if (Vue.$ga) {
      Vue.$ga.event({
        eventCategory: 'tags',
        eventAction: 'updated',
        eventLabel: 'Tag has been updated',
        eventValue: 1,
      });
    }
  },

  onDestroySuccess() {
    if (Vue.$ga) {
      Vue.$ga.event({
        eventCategory: 'tags',
        eventAction: 'deleted',
        eventLabel: 'Tag has been deleted',
        eventValue: 1,
      });
    }
  },
});
