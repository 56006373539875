import router from '@/router';
import { ROUTES } from '@/router/routes';

export default function eagerStart(path) {
  const routerBasePathName = process.env.VUE_APP_PUBLIC_PATH;

  // Note: the following check is needed for when you navigate directly to '/spaces'
  // Without this, the subsequent replace on the 'path' would not occur, causing the
  // route to resolve to our space page route (rather than the home page)
  if (path === routerBasePathName.replace(/\/$/, '')) {
    // eslint-disable-next-line no-param-reassign
    path = routerBasePathName;
  }

  const route = router.resolve(path.replace(routerBasePathName, '')).route;

  if (ROUTES[route.name] && typeof ROUTES[route.name].prefetchRouteData === 'function') {
    ROUTES[route.name].prefetchRouteData(route);
  }
}

