import Vue from 'vue';
import pluralize from 'pluralize';

import { createCrudModule } from '@/utils/vuex';
import { findPathToFirst } from '@/utils/vuex/utils/objectDeepSearch';
import deepVueDelete from '@/utils/vuex/utils/deepVueDelete';
import cnameHelper from '@/utils/helpers/CNameHelper';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import searchPageTree from '@/utils/vuex/utils/searchPageTree';
import { logError } from '@/utils/helpers/logger.utility';
import countPages from '@/utils/helpers/countPages';

import store from '..';

// Module for removed pages in Space
export default createCrudModule({
  only: ['CREATE', 'REPLACE', 'DESTROY', 'DESTROY_ALL'],
  resource: 'page',
  customUrlFn(id, spaceId, pageId) {
    if (!spaceId) {
      throw new Error('Unable to construct URL. Missing required Space ID parameter');
    }

    return buildApiUrl({
      resource: pageId ? `spaces/${spaceId}/trash/${pageId}` : `spaces/${spaceId}/trash`,
    });
  },
  mutations: {
    setResyncState(state, value) {
      state.isResyncing = value;
    },

    permanentlyDeletePage(state, pageId) {
      if (state.entities[pageId.toString()]) {
        Vue.delete(state.entities, pageId.toString());
      }

      // in case the page is part of sub level tree
      const pathToPage = findPathToFirst(state.entities, { id: pageId });

      if (!pathToPage || !pathToPage.length) {
        return;
      }

      deepVueDelete(state.entities, pathToPage);
    },
  },
  actions: {
    /**
     * The purpose of this action to to perform any re-fetches of data as a reaction to a CRUD hook
     * or incoming socket event related to this module. The whole 'resyncing' logic/state is to
     * ensure that there is no overlapping or duplicated logic being execute when handling these
     * events. It also serves to reduce duplication between the modules
     */
    async resyncData({ state, commit, dispatch }, { spaceId, pageId }) {
      if (state.isResyncing) {
        return;
      }

      commit('setResyncState', true);

      await Promise.all(
        [
          dispatch('pages/fetchSingle', {
            customUrlFnArgs: spaceId,
            id: pageId,
          }, { root: true }),
          dispatch('activeSpacePages/fetch', {}, { root: true }),
          dispatch('trashedSpacePages/fetch', {
            customUrlFnArgs: [spaceId],
          }, { root: true }),
          dispatch('requiredReading/fetch', {}, { root: true }),
          dispatch('ui/links/fetch', {}, { root: true }),
        ],
      );

      commit('setResyncState', false);
    },

    // Note: this is a helper function used to group common behaviours and rules around restoring
    // a page from trash
    restorePage({
      state,
      dispatch,
    }, {
      space,
      page,
    }) {
      return new Promise(async (resolve, reject) => {
        if (state.isReplacing) {
          resolve();
          return;
        }

        await dispatch('features/check', {}, { root: true });

        if (!dispatch('features/checkIfPassesPageLimitGuard', {}, { root: true })) {
          resolve();
          return;
        }

        const pageCount = countPages(page);

        try {
          await dispatch('spaceTrash/replace', {
            customUrlFnArgs: [space.id, page.id],
            data: {},
          }, { root: true });

          const alertMessage = pageCount > 1
            ? `This page and all it's child ${pluralize('page', pageCount - 1)} restored`
            : 'This page has been restored';

          dispatch('alerts/showAlert', {
            type: 'success',
            message: alertMessage,
            wrapText: false,
            ctaText: 'Undo',
            ctaHandler: async () => {
              await dispatch('spaceTrash/create', {
                customUrlFnArgs: [space.id, page.id],
                data: {},
              }, { root: true });
            },
          }, { root: true });

          resolve();
        } catch (error) {
          logError(error);

          dispatch('alerts/showAlert', { type: 'error', message: 'Couldn\'t restore page' }, { root: true });

          reject();
        }
      });
    },

    // Note: this is a helper function used to group common behaviours and rules around permanently
    // deleting a page from trash
    permanentlyDeletePage({
      commit,
      dispatch,
    }, {
      space,
      page,
    }) {
      const pageCount = countPages(page);

      return new Promise((resolve, reject) => {
        dispatch('modals/openModal', {
          activeModal: 'confirm',
          modalProps: {
            title: `Delete "${page.title}" ?`,
            text: ' Are you sure you want to permanently delete this page? This action is irreversible!',
            confirmButtonText: 'Delete',
            onConfirm: async () => {
              try {
                await dispatch('spaceTrash/destroy', {
                  id: page.id,
                  customUrlFnArgs: [space.id, page.id],
                }, { root: true });

                const alertMessage = pageCount > 1
                  ? 'The page and all of its child pages have been deleted'
                  : 'The page has been deleted';

                dispatch('alerts/showAlert', {
                  type: 'success',
                  message: alertMessage,
                }, { root: true });

                commit('permanentlyDeletePage', page.id);

                resolve();
              } catch (error) {
                logError(error);

                dispatch('alerts/showAlert', { type: 'error', message: 'Couldn\'t delete page' }, { root: true });

                reject();
              }
            },
          },
        }, { root: true });
      });
    },
  },
  getters: {
    getTrashPage: (state, getters) => (pageId) => searchPageTree({ childPages: getters.list },
      (page) => {
        if (page.id && page.id === Number(pageId)) {
          return page;
        }

        return null;
      }),
  },

  // Page moved to trash
  // eslint-disable-next-line no-unused-vars
  onCreateSuccess(state, response) {
    const hasAlreadyResynced = store.getters['trashedSpacePages/getPageById'](response.data.id);
    if (hasAlreadyResynced) {
      return;
    }

    store.dispatch('spaceTrash/resyncData', {
      spaceId: store.getters['navigation/activeSpaceId'],
      pageId: response.data.id,
    });

    if (Vue.$ga) {
      Vue.$ga.event({
        eventCategory: 'spaceTrash',
        eventAction: 'page-removed',
        eventLabel: `${cnameHelper()} | ${store.getters['navigation/activeSpace'].title}`,
        eventValue: 1,
      });
    }
  },

  // Page restored from trash
  async onReplaceSuccess(state, response) {
    store.dispatch('spaceTrash/resyncData', {
      spaceId: store.getters['navigation/activeSpaceId'],
      pageId: response.data.id,
    });

    if (Vue.$ga) {
      Vue.$ga.event({
        eventCategory: 'spaceTrash',
        eventAction: 'page-restored',
        eventLabel: `${cnameHelper()} | ${store.getters['navigation/activeSpace'].title}`,
        eventValue: 1,
      });
    }
  },

  // Page permanently deleted
  onDestroySuccess(state, id) {
    store.commit('pages/remove', Number(id), { root: true });

    // Note: we need to re-fetch our active Space pages
    store.dispatch('activeSpacePages/fetch', {}, { root: true });

    // Note: we need to re-fetch our trashed Space pages
    store.dispatch('trashedSpacePages/fetch', {
      customUrlFnArgs: [store.getters['navigation/activeSpaceId']],
    }, { root: true });

    // Note: we need to re-fetch notifications here as permanent deletion will trigger updates to
    // the list of notifications
    store.dispatch('notifications/fetchList');

    if (Vue.$ga) {
      Vue.$ga.event({
        eventCategory: 'spaceTrash',
        eventAction: 'page-permanently-deleted',
        eventLabel: `${cnameHelper()} | ${store.getters['navigation/activeSpace'].title}`,
        eventValue: 1,
      });
    }
  },

  onDestroyAllSuccess() {
    // Note: we need to re-fetch our active Space pages
    store.dispatch('activeSpacePages/fetch', {}, { root: true });

    // Note: we need to re-fetch our trashed Space pages
    store.dispatch('trashedSpacePages/fetch', {
      customUrlFnArgs: [store.getters['navigation/activeSpaceId']],
    }, { root: true });

    // Note: we need to re-fetch notifications here as permanent deletion will trigger updates to
    // the list of notifications
    store.dispatch('notifications/fetchList');

    if (Vue.$ga) {
      Vue.$ga.event({
        eventCategory: 'spaceTrash',
        eventAction: 'trash-emptied',
        eventLabel: store.getters['navigation/activeSpace'].title,
        eventValue: 1,
      });
    }
  },
});
