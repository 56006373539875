import Vue from 'vue';
import VueCustomElement from 'vue-custom-element';
import VueClipboard from 'vue-clipboard2';
import Vuelidate from 'vuelidate';
import Transitions from 'vue2-transitions';
import VTooltip from 'v-tooltip';
import PortalVue from 'portal-vue';
import VueObserveVisibility from 'vue-observe-visibility';
import Raven from 'raven-js';
import RavenVue from 'raven-js/plugins/vue';

import router from '@/router';

import { isSentryEnabled } from '@/utils/helpers/environment.utility';
import { enableGoogleAnalyticsPlugin } from '../analytics/google';
import VueMq from '../vue-mq';
import permissionMixin from '../mixins/permissionMixin';
import cypressCIMixin from '../mixins/cypressCIMixin';

export default function () {
  enableGoogleAnalyticsPlugin(Vue, router);
  Vue.use(VueClipboard);
  Vue.use(VueCustomElement);
  Vue.use(Vuelidate);
  Vue.use(Transitions);
  Vue.use(VTooltip);
  Vue.use(PortalVue);
  Vue.use(VueObserveVisibility);
  Vue.use(permissionMixin);
  Vue.use(cypressCIMixin);

  Vue.use(VueMq, {
    breakpoints: {
      'x-small': 360,
      small: 640,
      medium: 960,
      large: 1200,
      'x-large': Infinity,
    },
  });

  if (isSentryEnabled) {
    Raven
      .config(process.env.SENTRY_DSN, {
        shouldSendCallback(event) {
          const isSyncOTError = event?.exception?.values[0]?.type?.contains('SyncOT');
          return !isSyncOTError;
        },
        ignoreUrls: [
          /js\/embedded-chat\.[0-9a-z]+\.js/i,
          /(sunbeam\.teamwork\.localhost)/,
          /(localhost)/,
        ],
      })
      .addPlugin(RavenVue, Vue)
      .install();
  }
}
