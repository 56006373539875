import { isObject, isUndefined } from 'lodash-es';

import { createStaticCrudModule } from '@/utils/vuex';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import alphabeticalSort from '@/utils/helpers/alphabeticalSort';

import store from '..';

/**
 * This module uses /stats.json and fetches the required reading report
 * for all users, displaying wether or not they have read the page
 */
export default createStaticCrudModule({
  only: ['FETCH'],
  property: 'requiredReading',
  namespaced: true,
  state: {
    unreadUserList: [],
    readUserList: [],
  },
  prepareConfig(config) {
    if (!isObject(config)) {
      /* eslint-disable no-param-reassign */
      config = {};
    }

    if (!isObject(config.params)) {
      config.params = {};
    }

    config.params.include = ['users'].join(',');

    return config;
  },
  customUrlFn(spaceId, pageId) {
    if (!spaceId || !pageId) {
      spaceId = store.getters['navigation/activeSpaceId'];
      pageId = store.getters['navigation/activePageId'];
    }

    if (!spaceId || !pageId) {
      throw new Error('Unable to construct URL. Missing required parameter(s)');
    }

    return buildApiUrl({
      resource: `requiredreading/spaces/${spaceId}/pages/${pageId}/stats.json`,
      apiVersion: 'v1',
    });
  },
  getters: {
    unreadUserList: (state) => (state && Array.isArray(state.unreadUserList) ? state.unreadUserList
      .map((x) => x)
      .sort((a, b) => {
        const aName = a.user.firstname;
        const bName = b.user.firstname;

        if (aName && bName) {
          return alphabeticalSort(aName.toLowerCase(), bName.toLowerCase(), false);
        }

        return 0;
      }) : []
    ),

    readUserList: (state) => (state && Array.isArray(state.unreadUserList) ? state.readUserList
      .map((x) => x)
      .sort((a, b) => {
        const aName = a.user.firstname;
        const bName = b.user.firstname;

        if (aName && bName) {
          return alphabeticalSort(aName.toLowerCase(), bName.toLowerCase(), false);
        }

        return 0;
      }) : []
    ),
  },
  mutations: {
    setUnreadUserList(state, { users }) {
      if (isUndefined(users)) {
        throw new Error('Missing the required property "users"');
      }

      state.unreadUserList = users;
    },

    setReadUserList(state, { users }) {
      if (isUndefined(users)) {
        throw new Error('Missing the required property "users"');
      }

      state.readUserList = users;
    },
  },
  actions: {
    setUnreadUserList({ commit }, { users }) {
      if (isUndefined(users)) {
        throw new Error('Missing the required property "users"');
      }

      commit('setUnreadUserList', { users });
    },

    setReadUserList({ commit }, { users }) {
      if (isUndefined(users)) {
        throw new Error('Missing the required property "users"');
      }

      commit('setReadUserList', { users });
    },
  },
  onFetchSuccess(state) {
    if (state && state.entity && state.entity.read && state.entity.unread) {
      const unread = state.entity.unread.length > 0 ? state.entity.unread : [];
      const read = state.entity.read.length > 0 ? state.entity.read : [];

      store.dispatch('requiredReadingInfo/setReadUserList', { users: read });
      store.dispatch('requiredReadingInfo/setUnreadUserList', { users: unread });
    }
  },
});
