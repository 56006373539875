import { createCrudModule } from '@/utils/vuex';

import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';

export default createCrudModule({
  app: 'projects',
  only: ['FETCH_LIST', 'FETCH_SINGLE'],
  resource: 'project',
  customUrlFn(id) {
    return buildApiUrl({
      app: 'projects',
      resource: id ? `projects/${id}` : 'projects',
    });
  },
  state: {
    defaultPageSize: 50,
  },
  actions: {
    fetchProjects(
      { dispatch, state },
      {
        customUrlFnArgs,
        searchTerm = '',
        pageSize = state.defaultPageSize,
      } = {}) {
      const params = {
        pageSize,
      };

      if (searchTerm !== '') {
        params.searchTerm = searchTerm;
      }

      return dispatch('fetchList', {
        customUrlFnArgs,
        config: {
          params,
        },
      });
    },
  },
});
