// https://popper.js.org/docs/v2/constructors/#options
export const PLACEMENTS = Object.freeze({
  TOP: 'top',
  RIGHT: 'right',
  BOTTOM: 'bottom',
  LEFT: 'left',
});

export const PLACEMENT_MODIFIERS = Object.freeze({
  START: 'start',
  END: 'end',
});

export default {
  PLACEMENTS,
  PLACEMENT_MODIFIERS,
};
