import Vue from 'vue';

import { createCrudModule } from '@/utils/vuex';
import client from '@/utils/vuex/client';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import { logError } from '@/utils/helpers/logger.utility';
import cnameHelper from '@/utils/helpers/CNameHelper';

import store from '..';

const customUrlFn = (id, spaceId, pageId, isInline = false) => buildApiUrl({
  apiVersion: 'v2',
  resource: `spaces/${spaceId}/pages/${pageId}/attachments${isInline ? '/inline' : ''}${id ? `/${id}` : ''}`,
});

/**
 * The attachments endpoint now returns all attachments and inline images
 * The attachments widget needs to only return attachments (filter by type) and ignore
 * all inline images. For this we need to use a filter on the endpoint.
 * So there is an action wrapped around the fetchList for this store to provide filtering
 */
export const moduleConfig = {
  customUrlFn,
  only: ['FETCH_LIST', 'FETCH_SINGLE', 'CREATE', 'DESTROY'],
  resource: 'attachment',
  getters: {
    // Attachments must have objectType attachment
    getAttachmentsForPage: (state, getters) => (pageId) => getters.list
      .filter((attachment) => (attachment.page.id === pageId) && (attachment.objectType === 'attachment'))
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
    // Images must have "image" in their mimeType
    getGalleryImagesForPage: (state, getters) => (pageId) => getters.list
      .filter((attachment) => (attachment.page.id === pageId) && (attachment.mimeType.includes('image')))
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
  },
  actions: {
    // Note: we have to override the create action to allow for custom file payload
    create({ commit }, {
      data,
      config,
      customUrlFnArgs = [],
    } = {}) {
      commit('createStart', { params: { data } });

      return client.post(
        customUrlFn(...[null].concat(customUrlFnArgs)),
        data,
        config,
      )
        .then((response) => {
          const parsedResponse = { ...response, data: response.data.attachment };

          commit('createSuccess', { params: { response: parsedResponse } });

          return parsedResponse;
        })
        .catch((error) => {
          commit('createError', error);

          logError(error);

          return Promise.reject(error);
        });
    },
  },
  onFetchListStart(state) {
    // This store module should only contain the entities for the current page, so we need to
    // clear it before fetching
    state.entities = {};
  },
  onCreateSuccess() {
    if (Vue.$ga) {
      Vue.$ga.event({
        eventCategory: 'attachment',
        eventAction: 'created',
        eventLabel: (() => [
          cnameHelper(),
          store.getters['navigation/activeSpace'].title,
          (store.getters['navigation/activePage'] && store.getters['navigation/activePage'].title) || '',
        ]
          .filter((a) => a)
          .join(' | '))(),
        eventValue: 1,
      });
    }
  },
  onDestroySuccess(state, id) {
    if (Vue.$ga) {
      Vue.$ga.event({
        eventCategory: 'attachment',
        eventAction: 'deleted',
        eventLabel: (() => [
          cnameHelper(),
          store.getters['navigation/activeSpace'].title,
          (store.getters['navigation/activePage'] && store.getters['navigation/activePage'].title) || '',
        ]
          .filter((a) => a)
          .join(' | '))(),
        eventValue: 1,
      });
    }

    store.dispatch('spaceAttachments/removeAttachmentById', id, { root: true });
  },
};

export default createCrudModule(moduleConfig);
