import { mapState, mapGetters } from 'vuex';

// @vue/component
export default {
  props: {
    obscureHeader: {
      type: Boolean,
      required: false,
      default: true,
    },
    stackSecondary: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    AccessRequest: () => import('../AccessRequestModal'),
    AddEditDuplicatePage: () => import('../AddEditDuplicatePageModal'),
    AddPermission: () => import('../AddPermissionModal'),
    AddProjectLink: () => import('../AddProjectLinkModal'),
    AddSpace: () => import('../AddSpaceModal'),
    Alert: () => import('../AlertModal'),
    CodeSample: () => import('../TinymceCustomModals/CodeSampleModal'),
    Confirm: () => import('../ConfirmModal'),
    ConfluenceImport: () => import('../ConfluenceImportModal'),
    CreateWebhook: () => import('../CreateWebhookModal'),
    EditorTags: () => import('../EditorTagsModal'),
    EditorWidgetBrowser: () => import('../EditorWidgetBrowserModal'),
    EditSourceCode: () => import('../TinymceCustomModals/SourceCodeModal'),
    EditSpace: () => import('../EditSpaceModal'),
    EditUser: () => import('../EditUserModal'),
    EnterprisePlan: () => import('../EnterprisePlanModal'),
    FreeAddonLimitReached: () => import('../FreeAddonLimitReachedModal'),
    GenerateApiKey: () => import('../GenerateApiKeyModal'),
    GracePeriod: () => import('../Checkout/GracePeriodModal'),
    IncompatibleBrowserWidgetInfo: () => import('../IncompatibleBrowserWidgetInfoModal'),
    InsertAnchor: () => import('../TinymceCustomModals/AnchorModal'),
    InsertImage: () => import('../InsertImageModal'),
    InsertLink: () => import('../TinymceCustomModals/LinkModal'),
    InsertMedia: () => import('../TinymceCustomModals/MediaModal'),
    InstallationLocked: () => import('../Checkout/InstallationLockedModal'),
    InvitePeople: () => import('../InvitePeopleModal'),
    KeyboardShortcuts: () => import('../KeyboardShortcutsModal'),
    ManageApp: () => import('../ManageAppModal'),
    ManageCompany: () => import('../ManageCompanyModal'),
    MovePage: () => import('../MovePageModal'),
    Oversubscribed: () => import('../Checkout/OversubscribedModal'),
    PageLimitReached: () => import('../StarterPlan/PageLimitReachedModal'),
    PageTemplates: () => import('../PageTemplatesModal'),
    PageVersionHistory: () => import('../PageVersionHistoryModal'),
    PlaceholderText: () => import('../PlaceholderTextModal'),
    PublishWithNote: () => import('../PublishWithNoteModal'),
    Reaction: () => import('../ReactionModal'),
    RequiredReading: () => import('../RequiredReadingModal'),
    SaveAsPageTemplate: () => import('../SaveAsPageTemplateModal'),
    SaveAsGlobalPageTemplate: () => import('../SaveAsGlobalPageTemplateModal'),
    SharePage: () => import('../SharePageModal'),
    SpaceMembersSelector: () => import('../SpaceMembersSelectorModal'),
    SubscriptionExpired: () => import('../Checkout/SubscriptionExpiredModal'),
    SubscriptionInvalid: () => import('../Checkout/SubscriptionInvalidModal'),
    SharedPageComment: () => import('../SharedPageCommentModal'),
    TrialExpired: () => import('../Checkout/TrialExpiredModal'),
    TrialStart: () => import('../Checkout/TrialStartModal'),
    UserDisabled: () => import('../Checkout/UserDisabledModal'),
    UserFeedback: () => import('../UserFeedbackModal'),
    UserLimitReached: () => import('../StarterPlan/UserLimitReachedModal'),
    UserLimitWarning: () => import('../Checkout/UserLimitWarningModal'),
    UseTemplate: () => import('../UseTemplateModal'),
    ViewTemplate: () => import('../ViewTemplateModal'),
    UpgradeToPaidAddonPlan: () => import('../Checkout/UpgradeToPaidAddonPlanModal'),
  },
  data() {
    return {
      modalKey: 0,
    };
  },
  computed: {
    ...mapGetters('modals', ['getPrimaryModal', 'getSecondaryModal']),

    ...mapState('modals', ['primaryModalProps', 'secondaryModalProps']),

    primaryModal() {
      const primaryModal = this.getPrimaryModal;

      if (!primaryModal) {
        return null;
      }

      return primaryModal;
    },

    secondaryModal() {
      const secondaryModal = this.getSecondaryModal;

      if (!secondaryModal) {
        return null;
      }

      return secondaryModal;
    },

    modalContainerClasses() {
      return {
        'modal-container--below-header': !this.obscureHeader,
        'modal-container--has-secondary': !!this.secondaryModal,
      };
    },
  },
  methods: {
    // force complete reset of component by changing component key attr
    forceModalReRender() {
      this.modalKey += 1;
    },
  },
};
