import Vue from 'vue';
import ErrorMessageIllustration from '@widgets/ErrorMessage/ErrorMessage';
import { ErrorBoundary } from 'vue-error-boundary';
import { logError } from '@/utils/helpers/logger.utility';
// @vue/component
export default {
  props: {
    errorFallBack: {
      type: Object, // Pass in a custom error component
      default: null,
    },
  },
  components: { ErrorMessageIllustration, ErrorBoundary },
  data() {
    return { error: null, errorToggle: true, messages: [] };
  },
  computed: {
    fallback() {
      return this.errorFallBack ? this.errorFallBack : ErrorMessageIllustration;
    },
    errorClasses() {
      if (this.error) {
        return ['w-error-boundary-fall-back'];
      }

      return '';
    },
  },
  methods: {
    onError(err, vm) {
      logError(err);
      this.error = err;
      // Log the error to screen if in debug mode
      if (Vue.config.productionTip) {
        this.$nextTick(() => {
          const h1 = document.createElement('h1');
          h1.style.color = 'red';
          const textnode = document.createTextNode(err.message);
          h1.appendChild(textnode);

          const p = document.createElement('p');
          p.style.color = 'red';
          const pText = document.createTextNode(`div.${vm.$el.className}`);
          p.appendChild(pText);

          this.$el.appendChild(h1);
          this.$el.appendChild(p);
        });
      }
    },
  },
};
