/* eslint-disable no-underscore-dangle */
export default function countPages(page) {
  let _count = 0;

  (function count(_page) {
    _count += 1;

    if (_page.childPages && _page.childPages.length > 0) {
      _page.childPages.forEach((_childPage) => count(_childPage));
    }
  }(page));

  return _count;
}
