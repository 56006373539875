import { createCrudModule } from '@/utils/vuex';

import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';

export default createCrudModule({
  only: ['CREATE', 'UPDATE', 'DESTROY'],
  resource: 'scope',
  customUrlFn(id, spaceId, pageId) {
    if (!spaceId || !pageId) {
      throw new Error('Unable to construct URL. Missing required Space ID or Page ID parameter');
    }

    return buildApiUrl({
      apiVersion: 'v1',
      resource: id
        ? `/spaces/${spaceId}/pages/${pageId}/shares/scopes/${id}`
        : `/spaces/${spaceId}/pages/${pageId}/shares/scopes`,
    });
  },
  getters: {
    allScopes: (state, getters) => (getters.list && getters.list) || [],

    individualScopes: (state, getters) => (
      getters.allScopes && getters.allScopes
        .filter((scope) => scope.scopeType === 'invite')
    ) || [],

    publicScope: (state, getters) => (
      getters.allScopes && getters.allScopes
        .find((scope) => scope.scopeType === 'anyone')
    ) || {},

    publicScopeId: (state, getters) => { // eslint-disable-line
      return (getters.publicScope && getters.publicScope.id) || 0;
    },

    isPublicSharingEnabled: (state, getters) => { // eslint-disable-line
      return !!(getters.publicScope && getters.publicScope.state === 'enabled');
    },

    publicSharingUrl: (state, getters) => { // eslint-disable-line
      return (getters.publicScope.state === 'enabled'
        && getters.publicScope.sharingURL
      ) || '';
    },

    areThereAnyScopes(state, getters) {
      return !!getters.individualScopes.length || getters.isPublicSharingEnabled;
    },
  },
  mutations: {
    addScopes(state, scopesArray) {
      state.entities = scopesArray;
    },
  },
  actions: {
    addScopes({ commit }, scopesArray) {
      commit('addScopes', scopesArray);
    },
  },
});
