/* global pendo */

import store from '@/store';
import INTERNAL_INSTALLATION_IDS from '@/utils/constants/internalInstallationIds';
import { isDevEnvironment } from '@/utils/helpers/environment.utility';
import { logError } from '@/utils/helpers/logger.utility';
import fullName from '@/utils/filters/fullName';
import isPDFExporterAgent from '@/utils/helpers/isPDFExporterAgent';
import { raiseSentryError } from '@/utils/helpers/sentryHelper';

const shouldInitialise = () => {
  if (!process.env.VUE_APP_PENDO_API_KEY) {
    return false;
  }

  if (isPDFExporterAgent()) {
    return false;
  }

  const installation = store.getters['installation/get'];

  // Initialise for twspaces to allow debugging
  if (installation.id === 523679) {
    return true;
  }

  if (INTERNAL_INSTALLATION_IDS.includes(installation.id) || isDevEnvironment) {
    return false;
  }

  return true;
};

const initPendo = () => {
  if (!shouldInitialise()) {
    return;
  }

  const installation = store.getters['installation/get'];
  const session = store.getters['session/get'];
  const launchpad = store.state.launchpad;

  if (!installation || !session || !launchpad) {
    const errorMsg = 'Could not initialise Pendo. Required store data is missing or invalid';
    logError(errorMsg);

    // Raise a sentry error here so we can track failures to init Pendo
    raiseSentryError(new Error(errorMsg), {
      level: 'error',
      session,
      installation,
      launchpad,
    });

    return;
  }

  const visitorId = `${installation.id}-${session.id}`;
  const externalId = `${installation.id}`;
  const siteName = `${installation.name}`;

  if (!window.pendo || !window.pendo.initialize) {
    const errorMsg = 'Could not initialise Pendo. `pendo` global variable is missing or invalid';
    logError(errorMsg);

    // Raise a sentry error here so we can track failures to init Pendo
    raiseSentryError(new Error(errorMsg), {
      level: 'error',
      session,
      installation,
      launchpad,
      pendo,
    });

    return;
  }

  pendo.initialize({
    visitor: {
      id: visitorId,
      new_visitor_id: 'true',
      email: session.email,
      full_name: fullName(session),
      site_owner: !!session.isSiteOwner,
      site_admin: !!session.isAdmin,
      // client_user
      site_baseUrl: launchpad.info.url,
      // in_owner_company
      // collaborator
      // add_projects
      // manage_people_and_companies
      // manage_portfolios
      // timezone
      // manage_task_templates
      spaces_is_trial: store.getters['installation/hasActiveTrial'], // is_trial
      spaces_is_paying: store.getters['installation/isPaid'], // is_paying
      // login_count
    },
    account: {
      id: externalId,
      name: siteName,
      spaces_is_paying: store.getters['installation/isPaid'], // is_paying
      spaces_is_trial: store.getters['installation/hasActiveTrial'], // is_trial
      spaces_plan_id: store.getters['installation/pricePlanId'], // plan_id
      // subscription_plan
      spaces_license_count: store.getters['installation/planUsersCount'], // license_count
      // start_date
      // industry
      site_baseUrl: launchpad.info.url,
      sso_enabled: launchpad.info.sso.enabled,
      server_region: launchpad.info.awsRegion,
      // shard_no
      // first_payment_date
      // strict_branding
      // allow_tw_brand
      // is_installation_id_even
      // trial_plan_id
      // trial_plan_days_remaining
      spaces_payment_method: store.getters['installation/paymentMethod'], // payment_method
      // billing_period
      // site_owner_email
      // is_pendo_onboarding_segment
      // companysizesId
    },
  });
};

const trackEvent = (eventName, eventData) => {
  if (!window.pendo || !window.pendo.track) {
    return;
  }

  pendo.track(eventName, eventData);
};

export {
  initPendo,
  trackEvent,
};
