import projects from '@/store/modules/integrations/projects/index.js';
import desk from '@/store/modules/integrations/desk/index.js';
import crm from '@/store/modules/integrations/crm/index.js';
import chat from '@/store/modules/integrations/chat/index.js';

export default {
  projects: {
    namespaced: true,
    modules: projects,
  },
  desk: {
    namespaced: true,
    modules: desk,
  },
  crm: {
    namespaced: true,
    modules: crm,
  },
  chat: {
    namespaced: true,
    modules: chat,
  },
};
