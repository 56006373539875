import { mapState, mapActions } from 'vuex';

// @vue/component
export default {
  components: {
    WhatsNew: () => import('../WhatsNewSlidingDrawer'),
    ProjectsTaskPreviewDrawer: () => import('../ProjectsTaskPreviewDrawer'),
  },
  computed: {
    ...mapState('slidingDrawers', ['activeSlidingDrawer', 'slidingDrawerProps']),
  },
  methods: {
    ...mapActions('slidingDrawers', ['slideDrawerOut']),

  },
};
