import Vue from 'vue';
import { extend, isUndefined, isNull } from 'lodash-es';

import { createCrudModule } from '@/utils/vuex';
import defaultClient from '@/utils/vuex/client';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import { logError } from '@/utils/helpers/logger.utility';

const transformSocketResponseToAPIFormat = (data) => {
  /* eslint-disable no-param-reassign */
  data.object = {
    id: data.objectId,
    type: data.objectType,
    meta: data.meta,
  };

  delete data.objectId;
  delete data.objectType;
  delete data.meta;

  data.changedBy.id = data.changedBy.userId;
  delete data.changedBy.userId;
  /* eslint-enable no-param-reassign */
};

export default createCrudModule({
  only: ['FETCH_LIST', 'UPDATE'],
  resource: 'notification',
  state: {
    isEnabled: true,
    isReady: false,
    error: null,
    shouldPulseCount: false,
    pulseTimeout: null,
    unreadCount: null,
    defaultPageSize: 50,
  },
  getters: {
    // Note: the functionality to toggle notifications has currently been removed
    isEnabled: (state) => state.isEnabled,

    // Note: while the API and/or socket report on notification count, we can no longer use the list
    // because the response is now paginated, we manually update the unreadCount to prevent
    // it getting out of sync
    unreadCount: (state) => (state && state.unreadCount) || 0,

    /* eslint-disable-next-line arrow-body-style */
    listByMostRecent: (state, getters) => {
      /* eslint-disable-next-line arrow-body-style */
      return getters.list.sort((a, b) => {
        if (!a.eventDate || isNaN(new Date(a.eventDate).getTime())) {
          return 1;
        }

        if (!b.eventDate || isNaN(new Date(b.eventDate).getTime())) {
          return 1;
        }

        if (new Date(a.eventDate).getTime() === new Date(b.eventDate).getTime()) {
          return 0;
        }

        return new Date(a.eventDate).getTime() > new Date(b.eventDate).getTime() ? -1 : 1;
      });
    },
    canFetchMore: (state, getters) => { // eslint-disable-line
      if (
        state && state.currentMeta && state.currentMeta.page
        && state.currentMeta.page.count && state.entities
        && (Object.keys(state.entities).length < state.currentMeta.page.count)
      ) {
        return true;
      }

      return false;
    },
  },
  mutations: {
    setNotificationsEnabled(state, data) {
      state.isEnabled = data;
    },

    markAsRead(state, { notificationId, isRead = true }) {
      const entity = state.entities[notificationId];

      if (!entity) {
        return;
      }

      entity.isRead = isRead;
    },

    markAllAsRead(state) {
      Object.keys(state.entities).forEach((k) => {
        Vue.set(state.entities, k, extend(state.entities[k], { isRead: true }));
      });
    },

    addNotification(state, data) {
      const notification = state.entities[data.id];

      // Notification already exists
      if (notification) {
        return;
      }

      Vue.set(state.entities, data.id, data);
    },

    setCountAsPulsing(state, isPulsing = true) {
      state.shouldPulseCount = isPulsing;
    },

    setPulsingTimeout(state, timeout) {
      state.pulseTimeout = timeout;
    },

    clearPulsingTimeout(state) {
      clearTimeout(state.pulseTimeout);
    },
    setUnreadCount(state, { unreadCount }) {
      if (!isUndefined(unreadCount)) {
        state.unreadCount = unreadCount;
      }
    },
  },
  actions: {
    fetchPageOfNotifications(
      { dispatch, state },
      { pageOffset = 0, pageSize = state.defaultPageSize } = {},
    ) {
      return dispatch('fetchList', {
        config: {
          params: {
            pageOffset,
            pageSize,
          },
        },
      });
    },

    pushNotification({
      commit, getters, dispatch, state,
    }, data) {
      const notification = getters.byId(data.id);

      // Notification already exists
      if (notification) {
        return;
      }

      // Note: the API response is slightly different from the socket response due to lack of
      // support for included on the socket side, so this translation step is crucial
      transformSocketResponseToAPIFormat(data);

      if (data.isRead === false) {
        // increment unreadCount by one
        commit('setUnreadCount', { unreadCount: state.unreadCount + 1 });
      }

      commit('addNotification', data);
      commit('clearPulsingTimeout');
      commit('setCountAsPulsing', true);

      commit('setPulsingTimeout', setTimeout(() => {
        commit('setCountAsPulsing', false);
        commit('clearPulsingTimeout');
      }, 3000));

      // Note: a delete event also deletes all child elements e.g. deleting a space will delete
      // it's pages and comments (and their related notifications). Therefore we need to trigger
      // a re-fetch to clean up out notifications list
      if (data.operation === 'deleted') {
        dispatch('fetchList');
      }
    },

    async markAsRead({ commit, dispatch, getters }, { notificationId, isRead = true } = {}) {
      const notification = getters.byId(notificationId);

      if (!notification) {
        return;
      }

      dispatch('setUnreadCount', { isRead });

      try {
        await dispatch('notifications/update', {
          id: notificationId,
          data: {
            isRead,
          },
          isOptimistic: true,
        }, { root: true });
      } catch (error) {
        // Revert the 'isRead' status manually and adjust the unreadCount
        dispatch('setUnreadCount', { isRead: !isRead });
        commit('markAsRead', { notificationId, isRead: !isRead });

        logError(error);
      }
    },

    async markAllAsRead({ commit, dispatch }) {
      try {
        await defaultClient.post(
          buildApiUrl({
            resource: 'notifications/read',
          }),
        );

        commit('markAllAsRead');
        dispatch('setUnreadCount', { unreadCount: 0 });
      } catch (error) {
        logError(error);
      }
    },

    async setUnreadCount(
      {
        commit, dispatch, rootGetters, state,
      },
      { unreadCount = null, isRead = null },
    ) {
      // if unreadCount is passed through, just update state.
      if (!isNull(unreadCount)) {
        commit('setUnreadCount', { unreadCount });
        return;
      }

      // If no unreadCount && isRead is passed through we increment/decrement state
      if (isNull(unreadCount) && !isNull(isRead)) {
        if (isRead && state.unreadCount > 0) {
          commit('setUnreadCount', { unreadCount: state.unreadCount - 1 });
        } else {
          commit('setUnreadCount', { unreadCount: state.unreadCount + 1 });
        }

        return;
      }

      // If the socket is not active, we must request the unread count ourselves
      if (isNull(unreadCount) || !rootGetters['socket/isConnected']) {
        dispatch('fetchUnreadCount');
      }
    },

    async fetchUnreadCount({ commit }) {
      let data = {};

      try {
        data = await defaultClient.get(
          buildApiUrl({
            resource: 'notifications/unreadcount',
          }),
        );

        const count = data.notification && data.notification.unread;
        commit('setUnreadCount', { unreadCount: count });
      } catch (error) {
        logError(error);
        return null;
      }

      return data;
    },
  },
});
