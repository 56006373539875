import SkeletonMixin from '@/utils/mixins/skeleton.mixin';

const sizes = {
  xsmall: 'xs',
  small: 'sm',
  medium: 'md',
  large: 'lg',
  xlarge: 'xlg',
};

// @vue/component
export default {
  mixins: [SkeletonMixin],
  props: {
    src: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    variant: {
      type: String,
      required: false,
      default: 'user',
    },
    size: {
      type: String,
      required: false,
      default: 'medium',
    },
  },
  data() {
    return {
      error: null,
    };
  },
  computed: {
    classes() {
      return [
        `w-avatar--${sizes[this.size]}`,
        `w-avatar--${this.variant}`,
        { 'w-avatar--skeleton': this.shouldShowSkeleton },
      ];
    },
  },
  methods: {
    onError() {
      this.error = true;
    },
  },
  watch: {
    src: {
      handler() {
        this.error = false;
      },
    },
  },
};
