import { mapGetters, mapState } from 'vuex';

export default {
  inject: ['otInlineCommentsInstances'],
  data() {
    return {
      hasInlineCommentsInUnpublishedContentCache: false,
    };
  },
  computed: {
    ...mapGetters('navigation', [
      'activePageId',
      'isEditingSpacePage',
      'isActivePageInTrash',
    ]),

    ...mapGetters('inlineComments', [
      'activeCommentsForPage',
      'closedCommentsForPage',
      'getActiveCommentIdentifiersForPage',
    ]),

    ...mapState('inlineComments', [
      'newInlineCommentAvailableInReadMode',
    ]),

    ...mapState('ui/spacesEditor', [
      'hasOtConnection',
    ]),

    ...mapGetters('ui/spacePage', [
      'allowInlineCommentsInReadMode',
      'shouldResyncOtContent',
    ]),

    inlineCommentIdentifiers() {
      if (this.isEditingSpacePage) {
        return this.getActiveCommentIdentifiersForPage(this.activePageId, this.userCanEditSpace);
      }

      if (!this.allowInlineCommentsInReadMode || this.isActivePageInTrash) {
        return [];
      }

      // we need to make sure we only get identifiers that are in published content
      return this.getActiveCommentIdentifiersForPage(this.activePageId, this.userCanEditSpace)
        .filter((identifier) => this.commentMarkersInPublishedContent().includes(identifier));
    },

    activeInlineComments() {
      if (this.isEditingSpacePage) {
        return this.activeCommentsForPage(this.activePageId, this.userCanEditSpace);
      }

      if (!this.allowInlineCommentsInReadMode || this.isActivePageInTrash) {
        return [];
      }

      // we need to make sure we only display comments that are in published content
      return this.activeCommentsForPage(this.activePageId, this.userCanEditSpace)
        .filter((comment) => this.commentMarkersInPublishedContent().includes(comment.identifier));
    },

    closedInlineComments() {
      return this.closedCommentsForPage(this.activePageId);
    },

    hasActiveInlineComments() {
      return this.activeInlineComments.length > 0
        || this.newInlineCommentAvailableInReadMode
        || this.hasInlineCommentsInUnpublishedContent;
    },

    hasInlineCommentsInUnpublishedContent() {
      // When ot is syncing comment markers or is connected return the data/cached state
      // this is because we need to have the latest ot content in order to correctly
      // calculate if there are any inline comments in unpublished content
      if (this.shouldResyncOtContent || this.hasOtConnection) {
        return this.hasInlineCommentsInUnpublishedContentCache;
      }

      // Inline comments made in editor are marked as private,
      // we can use that to differentiate if there are any
      // comments made in editor that have not yet been published
      const privateCommentsInRead = this.activeInlineComments.filter((c) => c.isPrivate);
      const privateCommentsInEdit = this.activeCommentsForPage(this.activePageId, this.userCanEditSpace).filter((c) =>
        c.isPrivate && this.commentMarkersInUnpublishedContent().includes(c.identifier));

      this.hasInlineCommentsInUnpublishedContentCache = privateCommentsInEdit.length > privateCommentsInRead.length;
      return privateCommentsInEdit.length > privateCommentsInRead.length;
    },
  },
  methods: {
    commentMarkersInPublishedContent() {
      const commentMarkers = this.otInlineCommentsInstances?.read?.getAll();

      if (!commentMarkers || commentMarkers.length === 0) {
        return [];
      }

      return commentMarkers;
    },

    commentMarkersInUnpublishedContent() {
      const commentMarkers = this.otInlineCommentsInstances?.edit?.getAll();

      if (!commentMarkers || commentMarkers.length === 0) {
        return [];
      }

      return commentMarkers;
    },
  },
};
