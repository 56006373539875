var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.pageList && !_vm.item)?_c('router-link',{staticClass:"w-breadcrumb__item w-bread-crumb__item--home",attrs:{"to":{
    name:'space',
    params: {
      spaceCode: _vm.spaceCode
    },
  },"tag":"li"}},[_c('a',{staticClass:"w-breadcrumb__item-link"},[_c('w-icon',{attrs:{"name":"home"}})],1),_vm._v(" "),_c('w-icon',{staticClass:"chevron",attrs:{"name":"chevron-right"}})],1):(_vm.pageList)?_c('li',{staticClass:"w-breadcrumb__item w-bread-crumb__item--list"},[_c('list-popover',{staticClass:"collapsed-breadcrumbs-dropdown",attrs:{"items":_vm.pageList,"show-separator":true,"align-arrow-with-reference":true,"hide-when-out-of-boundary":true,"placement":"bottom"}},[_c('button',{attrs:{"slot":"button"},slot:"button"},[_c('fa-icon',{attrs:{"icon":['far', 'ellipsis-h']}})],1)]),_vm._v(" "),_c('w-icon',{staticClass:"chevron",attrs:{"name":"chevron-right"}})],1):(_vm.item)?_c('router-link',{staticClass:"w-breadcrumb__item",attrs:{"to":{
    name: _vm.item.uri.name || 'spacePage',
    params: _vm.item.uri.params || {},
  },"title":_vm.item.title,"tag":"li"}},[_c('a',{staticClass:"w-breadcrumb__item-link"},[_vm._v("\n    "+_vm._s(_vm._f("truncatedPageBreadcrumb")(_vm.item.title))+"\n  ")]),_vm._v(" "),_c('w-icon',{staticClass:"chevron",attrs:{"name":"chevron-right"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }