import store from '@/store';
import router from '@/router';
import Vue from 'vue';
import registerInternalEditorComponentsCustomElements
from '@/utils/editor/registerInternalEditorComponentsCustomElements';

export default () => {
  // Expose store, router and event bus to external apps
  window.$SPACES = {
    store,
    router,
    eventBus: new Vue(),
  };

  registerInternalEditorComponentsCustomElements();
};
