import Alert from '@widgets/Alert/Alert';
import { mapActions, mapState, mapGetters } from 'vuex';

// @vue/component
export default {
  components: {
    Alert,
  },
  computed: {
    ...mapState('alerts', ['alerts']),
    ...mapGetters('navigation', ['isEditingSpacePage']),
  },
  methods: {
    ...mapActions('alerts', ['closeAlert']),
    close(index) {
      this.closeAlert({ index });
    },
  },
};
