import { mapGetters, mapState, mapMutations } from 'vuex';

import uuidv1 from 'uuid/v1';

export default {
  props: {
    uuid: {
      type: String,
      required: false,
      default: undefined,
    },
    dataMceSelected: {
      type: String,
      default: '',
    },
    dataInserted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initialisedEventListeners: false,
      placeholderClass: 'custom-widget-placeholder',
      localUuid: this.uuid,
    };
  },
  computed: {
    ...mapGetters('navigation', ['isEditorActive']),

    ...mapState('ui/spacesEditor', ['selectedWidgetUuid']),

    isSelected() {
      return this.dataMceSelected === '1';
    },
  },
  mounted() {
    this.initialise();
    // only fire this for read view
    if (!this.isEditorActive && location.hash) {
      this.$nextTick(() => {
        this.scrollMeTo(location.hash);
      });
    }
  },
  methods: {
    ...mapMutations('ui/spacesEditor', ['setSelectedWidgetUUID']),

    getWidgetNode() {
      if (!(this.$el instanceof HTMLElement) || !(this.$el.parentNode instanceof HTMLElement)) {
        return null;
      }

      return this.$el.parentNode;
    },

    getWidgetParentNode() {
      if (!(this.getWidgetNode() instanceof HTMLElement)) {
        return null;
      }

      return this.getWidgetNode().parentNode;
    },

    destroySelf() {
      if (!this.getWidgetNode() || !this.getWidgetParentNode()) {
        return;
      }

      const vm = this;

      setTimeout(() => {
        vm.getWidgetParentNode().removeChild(vm.getWidgetNode());
      }, 300);
    },

    registerWidgetInstance() {
      if (!this.getWidgetNode()) {
        return;
      }

      if (this.diffable) {
        this.getWidgetNode().setAttribute('data-diffable', true);
      } else {
        this.getWidgetNode().setAttribute('data-diffable', false);
      }

      let uuid = this.localUuid;

      if (!window.tinyMCE || !window.tinyMCE.activeEditor) {
        return;
      }

      // For legacy plugins
      // only create a new uuid if the widget does not have one
      if (!uuid) {
        uuid = uuidv1();
      }

      this.localUuid = uuid;
      this.getWidgetNode().setAttribute('uuid', this.localUuid);
    },

    select() {
      if (this.isEditorActive) {
        window.tinyMCE.activeEditor.selection.select(this.getWidgetNode());
      }
    },

    initialiseEventListeners() {
      if (this.initialisedEventListeners) {
        return;
      }

      const stopPropagation = (e) => {
        e.stopPropagation();
      };

      const stopPropagationAndPreventDefault = (e) => {
        e.stopPropagation();
        e.preventDefault();
      };

      this.$el.addEventListener('keypress', stopPropagation);
      this.$el.addEventListener('focus', stopPropagation);
      this.$el.addEventListener('click', stopPropagation);
      this.$el.addEventListener('drop', stopPropagationAndPreventDefault);

      this.$once('hook:beforeDestroy', () => {
        this.$el.removeEventListener('keypress', stopPropagation);
        this.$el.removeEventListener('focus', stopPropagation);
        this.$el.removeEventListener('click', stopPropagation);
        this.$el.removeEventListener('drop', stopPropagationAndPreventDefault);
      });

      this.initialisedEventListeners = true;
    },

    initialise() {
      if (this.isEditorActive) {
        this.registerWidgetInstance();
        this.initialiseEventListeners();
        this.initialiseInsertedHook();
      }
    },
    initialiseInsertedHook() {
      // if widget element has data-inserted="true" attribute
      // and the widget vue instance has an inserted callback
      // hook on its instance
      if (this.dataInserted && this.$options.inserted) {
        // execute inserted hook on widget;
        this.$options.inserted.call(this);
        // set is inserted to false
        const widgetNode = this.getWidgetNode();
        if (widgetNode) {
          widgetNode.setAttribute('data-inserted', false);
        }
      }
    },

    scrollMeTo(refName) {
      const element = document.querySelector('.s-space-page__body');

      if (refName && element) {
        const anchor = document.getElementById(refName.substr(1));
        const top = anchor ? anchor.offsetTop : 0;
        element.scrollTo({ behavior: 'smooth', top });
      }
    },
    focusEditorAfterWidget() {
      if (!this.getWidgetNode() || !this.getWidgetParentNode()) {
        return;
      }

      this.$nextTick(() => {
        const editor = window.tinymce.activeEditor;

        // if this widget is last child of the parent then add non breaking space to it
        // so that we can correctly set cursor using nextSibling
        if (this.getWidgetParentNode().lastChild.nodeName === this.getWidgetNode().nodeName) {
          const nbsNode = document.createTextNode('\u00A0');
          this.getWidgetParentNode().appendChild(nbsNode);
        }

        editor.selection.select(this.getWidgetNode().nextSibling, true);
        editor.selection.collapse(true);
      });
    },

    focusEditorBeforeWidget() {
      if (!this.getWidgetNode() || !this.getWidgetParentNode()) {
        return;
      }

      this.$nextTick(() => {
        const editor = window.tinymce.activeEditor;

        // if this widget is first child of the parent then add non breaking space to it
        // so that we can correctly set cursor using previousSibling
        if (this.getWidgetParentNode().firstChild.nodeName === this.getWidgetNode().nodeName) {
          const nbsNode = document.createTextNode('\u00A0');
          this.getWidgetParentNode().insertBefore(nbsNode, this.getWidgetNode());
        }

        editor.selection.select(this.getWidgetNode().previousSibling, true);
        editor.selection.collapse(false);
      });
    },
  },
  watch: {
    isEditorActive() {
      this.initialise();
    },
  },
};
