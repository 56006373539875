import { createCrudModule } from '@/utils/vuex';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';

export default createCrudModule({
  only: ['FETCH_LIST'],
  resource: 'page',
  idAttribute: 'pageId',
  listProperty: 'results',
  urlRoot: buildApiUrl({
    resource: 'search',
  }),
  namespaced: true,
  actions: {
    reset({ commit }) {
      commit('reset');
    },
  },
  getters: {
    all(state, getters) {
      return () => getters.list;
    },
  },
  mutations: {
    reset(state) {
      state.entities = {};
    },
  },
});
