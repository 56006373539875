import Vue from 'vue';
import { isUndefined } from 'lodash-es';

import { createCrudModule } from '@/utils/vuex';
import client from '@/utils/vuex/client';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import { logError } from '@/utils/helpers/logger.utility';
import RESOURCES from '@/utils/constants/resources';

export default createCrudModule({
  only: ['FETCH_LIST'],
  resource: RESOURCES.WEBHOOK_REQUEST,
  state: {
    isRedelivering: false,
    redeliverError: null,
    redeliveredList: [],
  },
  customUrlFn(id, webhookId) {
    return buildApiUrl({
      resource: id ? `webhooks/${webhookId}/requests/${id}` : `webhooks/${webhookId}/requests`,
    });
  },
  mutations: {
    redeliverStart(state) {
      state.isRedelivering = true;
      state.redeliverError = null;
    },

    redeliverSuccess(state, request) {
      state.isRedelivering = false;
      state.redeliverError = null;

      Vue.set(state.entities, request.id.toString(), request);

      state.redeliveredList.unshift(request.id.toString());
    },

    redeliverError(state, err) {
      state.isRedelivering = false;
      state.redeliverError = err;
    },

    clearRedeliveredList(state) {
      state.redeliveredList = [];
    },
  },
  actions: {
    redeliver({ commit }, { id }) {
      commit('redeliverStart');

      return client.post(buildApiUrl({
        resource: `webhook-requests/${id}/resend`,
      }), {})
        .then((res) => {
          if (isUndefined(res.data) || isUndefined(res.data[RESOURCES.WEBHOOK_REQUEST])) {
            throw new Error(`'${RESOURCES.WEBHOOK_REQUEST}' property not found in response.data`);
          }

          commit('redeliverSuccess', res.data[RESOURCES.WEBHOOK_REQUEST]);
        })
        .catch((err) => {
          commit('redeliverError', err);

          logError(err);

          return Promise.reject(err);
        });
    },
  },
});
