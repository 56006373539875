import { createCrudModule } from '@/utils/vuex';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';

export default createCrudModule({
  only: ['FETCH_LIST', 'UPDATE'],
  idAttribute: 'version',
  resource: 'version',
  customUrlFn(id, spaceId, pageId) {
    return buildApiUrl({
      resource: id
        ? `spaces/${spaceId}/pages/${pageId}/versions/${id}`
        : `spaces/${spaceId}/pages/${pageId}/versions`,
    });
  },
  parseSingle(response) {
    // response contains the `page` object instead.
    return response;
  },
  getters: {
    listInDescOrder: (state, getters) => getters.list && getters.list.slice().reverse(),
    canFetchMore: (state) => {
      if (
        state
        && state.currentMeta
        && state.currentMeta.page
        && state.currentMeta.page.count
        && state.entities
        && (Object.keys(state.entities).length < state.currentMeta.page.count)
      ) {
        return true;
      }
      return false;
    },
  },
  mutations: {
    resetStore(state) {
      state.entities = {};
    },
  },
  actions: {
    fetchPageVersions(
      { dispatch, state },
      {
        customUrlFnArgs,
        pageOffset = state.entities.length,
        pageSize = state.defaultPageSize,
      } = {},
    ) {
      dispatch('fetchList', {
        customUrlFnArgs,
        config: {
          params: {
            pageOffset,
            pageSize,
          },
        },
      });
    },
    resetStore({ commit }) {
      commit('resetStore');
    },
  },
});
