export const maxSidebarRatio = 0.85;
export const maxWidth = 536;
export const defaultWidth = 304;
export const minWidth = 256;

export default {
  namespaced: true,
  state: {
    isCollapsed: false,
    isCollapsing: false,
    width: 0,
  },
  actions: {
    toggleCollapsedState({ commit, state }) {
      commit('setCollapsedState', !state.isCollapsed);
    },

    setCollapsedState({ commit }, isCollapsed) {
      commit('setCollapsedState', isCollapsed);
    },

    setCollapsingState({ commit }, isCollapsing) {
      commit('setCollapsingState', isCollapsing);
    },

    setWidth({ commit }, width) {
      if (width < minWidth) {
        return;
      }

      if (width > maxWidth) {
        return;
      }

      commit('setWidth', width);
    },
  },
  mutations: {
    setCollapsedState(state, isCollapsed) {
      state.isCollapsed = isCollapsed;
    },

    setCollapsingState(state, isCollapsing) {
      state.isCollapsing = isCollapsing;
    },

    setWidth(state, width) {
      state.width = width;
    },
  },
  getters: {
    width(state) {
      return state.width;
    },

    isCollapsing(state) {
      return state.isCollapsing;
    },

    isCollapsed(state) {
      return state.isCollapsed;
    },
  },
};
