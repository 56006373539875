import { pathToRegexp } from 'path-to-regexp';

export default (href) => {
  const regexp = pathToRegexp('/spaces/:space/page/:pageCode');
  const regexpBetaTesting = pathToRegexp('/spaces-test/:tag/:space/page/:pageCode');
  const url = new URL(href);

  const isSpacePageUrl = !!regexp.exec(url.pathname) || !!regexpBetaTesting.exec(url.pathname);
  const hasSharingToken = !!url.searchParams.get('sharingtoken');

  return isSpacePageUrl && hasSharingToken;
};
