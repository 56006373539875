import { mapState, mapActions } from 'vuex';
import { isNull } from 'lodash-es';

import { escapeKey } from '@/utils/constants/keyboardKeys';
import { isEventKeyEqualTo } from '@/utils/helpers/keyboardEvents';
import fileSizeToString from '@/utils/helpers/fileSizeToString';

// @vue/component
export default {
  props: {
    lightboxImages: {
      type: Array,
      default: () => [],
    },
    activeImage: {
      type: Object,
      default: null,
    },
    closeOnEscKey: {
      type: Boolean,
      default: true,
    },
    isClickOutsideCloseEnabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentImage: null,
      currentIndex: null,
    };
  },

  computed: {
    ...mapState('lightbox', ['isLightboxOpen', 'lightboxProps']),

    controlClasses() {
      return {
        'light-box__control-wrapper--single-image': !this.shouldShowArrows,
      };
    },

    isShown() {
      return (this.lightboxImages && this.lightboxImages.length > 0);
    },

    shouldShowArrows() {
      return (this.lightboxImages && this.lightboxImages.length > 1);
    },

    shouldShowImageCaption() {
      return this.lightboxImages.length === 1
        && this.currentImage
        && this.currentImage.caption;
    },

    totalImageCount() {
      return this.lightboxImages && this.lightboxImages.length;
    },

    activeImageNumber() {
      return this.currentIndex + 1;
    },

    formattedImageSize() {
      if (this.currentImage && this.currentImage.size) {
        return fileSizeToString(this.currentImage.size);
      }
      return '';
    },

    currentImagePath() {
      if (this.currentImage && this.currentImage.url) {
        return this.currentImage.url;
      }

      return '';
    },
  },

  methods: {
    ...mapActions('lightbox', ['closeLightbox']),

    nextImage() {
      if (this.currentIndex < this.lightboxImages.length - 1) {
        this.currentIndex++; // eslint-disable-line
      } else {
        this.currentIndex = 0;
      }

      this.updateCurrentImage(this.currentIndex);
    },

    previousImage() {
      if (this.currentIndex > 0) {
        this.currentIndex--; // eslint-disable-line
      } else {
        this.currentIndex = this.lightboxImages.length - 1;
      }

      this.updateCurrentImage(this.currentIndex);
    },

    close() {
      this.currentImage = null;
      this.currentIndex = null;

      this.$emit('close');
      this.closeLightbox();
    },

    escHandler(event) {
      if (isEventKeyEqualTo(event, escapeKey)) {
        this.close();
      }
    },

    onClickBackdrop() {
      if (!this.isClickOutsideCloseEnabled) {
        return;
      }

      this.closeLightbox();
    },

    updateCurrentImage() {
      if (!isNull(this.currentIndex)) {
        this.currentImage = this.lightboxImages[this.currentIndex];
        return;
      }

      // Find the image if its the first time lightbox is opened
      this.currentImage = this.lightboxImages.find((i, index) => { // eslint-disable-line
        if (i.id === this.activeImage.id) {
          this.currentIndex = index;
          return i;
        }
      });
    },
  },
  mounted() {
    if (this.closeOnEscKey) {
      document.addEventListener('keyup', this.escHandler, { passive: true });
    }
  },

  beforeDestroy() {
    if (this.closeOnEscKey) {
      document.removeEventListener('keyup', this.escHandler, { passive: true });
    }
  },

  watch: {
    lightboxImages: {
      handler() {
        if (!this.lightboxImages || !this.lightboxImages.length > 0) {
          this.currentImage = null;
          this.currentIndex = null;
          return;
        }

        this.updateCurrentImage();
      },
    },
  },
};
