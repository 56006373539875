import { mapActions, mapGetters, mapState } from 'vuex';

import AlertContainer from '@widgets/AlertContainer';
import AppLoader from '@widgets/AppLoader';
import Lightbox from '@sections/Lightbox';
import ModalContainer from '@sections/Modals/ModalContainer';
import Tooltip from '@widgets/Tooltip';
import CheckoutHandler from '@/components/renderless/CheckoutHandler';
import PageEditor from '@sections/PageEditor';
import PageSocketRoomHandler from '@/components/renderless/PageSocketRoomHandler';
import SlidingDrawerContainer from '@sections/SlidingDrawers/SlidingDrawerContainer';

import createStyleSheet from '@/utils/helpers/createStyleSheet';
import { startSocket } from '@/utils/helpers/socket';
import LOCAL_STORAGE from '@/utils/constants/localStorage';

// @vue/component
export default {
  name: 'App',
  provide() {
    return {
      editor: this.editor,
      commentsStyleSheet: createStyleSheet(),
      otInlineCommentsInstances: this.otInlineCommentsInstances,
    };
  },
  components: {
    AppLoader,
    AlertContainer,
    CheckoutHandler,
    Lightbox,
    ModalContainer,
    InlineCommentGutter: () => import(/* webpackChunkName: "inline-comment-gutter" */'@sections/SpacesEditor/InlineCommentGutter'),
    PageEditor,
    PubbleLiveChat: () => import('@sections/PubbleLiveChat'),
    PageSocketRoomHandler,
    SlidingDrawerContainer,
    Tooltip,
  },
  data() {
    return {
      editor: { ref: null },
      otInlineCommentsInstances: { read: null, edit: null },
    };
  },
  computed: {
    ...mapGetters('session', {
      isAuthenticated: 'isAuthenticated',
      currentUser: 'user',
      isUserActive: 'isActive',
      isInternalInstallation: 'isInternalInstallation',
    }),

    ...mapGetters('installation', {
      installation: 'get',
      hasActiveTrial: 'hasActiveTrial',
      isInstallationLocked: 'isLocked',
      checkoutErrorCode: 'checkoutErrorCode',
      inGracePeriod: 'inGracePeriod',
      isTrialExpiringSoon: 'isTrialExpiringSoon',
    }),

    ...mapGetters('launchpad', {
      hasSpaces: 'hasSpaces',
    }),

    ...mapGetters('navigation', ['activePageId', 'isEditingSpacePage']),

    ...mapGetters('ui', [
      'shouldRenderEditor',
      'shouldShowCommentsGutter',
      'shouldRenderInlineCommentsGutter',
      'shouldModalContainerObscureAppHeader',
    ]),

    ...mapGetters('modals', ['hasOpenModal']),

    ...mapState('lightbox', ['lightboxProps']),

    ...mapState('activeSpacePages', ['pageToDrag']),

    ...mapGetters('ui/embeddedChat', ['isEmbeddedChatOpen']),

    ...mapGetters('ui/pubbleLiveChat', [
      'pubbleAppId',
      'pubbleUsername',
      'pubbleEmail',
      'shouldHidePubble',
    ]),

    ...mapGetters('socket', { isSocketConnected: 'isConnected' }),

    ...mapState('ui/tooltip', {
      tooltipId: 'tooltipId',
      tooltipTitle: 'title',
      tooltipColour: 'colour',
      tooltipPopperConfig: 'popperConfig',
    }),

    isPreloading() {
      return this.$store.getters['ui/isPreloading'];
    },

    hasEditorError() {
      return this.$store.getters['loading/spacePageError'] && this.$store.getters['navigation/isEditingSpacePage'];
    },

    shouldShowGrabbingCursor() {
      return this.pageToDrag && this.pageToDrag.id !== 0;
    },
  },
  methods: {
    ...mapActions('alerts', ['showAlert']),

    ...mapActions('modals', ['closeModal', 'openModal']),

    onNetworkOffline() {
      this.showAlert({ type: 'error', message: 'Your network has lost connectivity.' });
    },

    onNetworkOnline() {
      this.showAlert({ type: 'success', message: 'Your network is back online' });
    },

    handleTrial() {
      if (!this.hasActiveTrial || !this.isUserActive || !this.shouldShowTrialStartModal()) {
        return;
      }

      this.openModal({ activeModal: 'trial-start' });

      this.unsubscriber = this.$store.subscribeAction((action) => {
        if (action.type === 'modals/closeModal') {
          window.localStorage.setItem(
            LOCAL_STORAGE.TRIAL_DIALOG_LAST_DISMISSED,
            new Date().toDateString(),
          );
          this.unsubscriber();
        }
      });
    },

    shouldShowTrialStartModal() {
      if (!this.isTrialExpiringSoon) {
        return false;
      }

      const item = window.localStorage.getItem(LOCAL_STORAGE.TRIAL_DIALOG_LAST_DISMISSED);
      if (!item) {
        return true;
      }

      // evaluates to 'Mon 1 Apr 2019'
      const lastDismissed = new Date(item).toDateString();
      const today = new Date().toDateString();

      if (lastDismissed === today) {
        return false;
      }

      return true;
    },

    onBeforeUnload(event) {
      if (this.$store.getters['panel/isAmended']) {
        // Cancel the event as stated by the standard
        event.preventDefault();

        // Older browsers supported custom message
        // eslint-disable-next-line no-param-reassign
        event.returnValue = '';
      }
    },
  },
  async created() {
    startSocket();
  },
  mounted() {
    window.addEventListener('online', this.onNetworkOnline, false);
    window.addEventListener('offline', this.onNetworkOffline, false);
    window.addEventListener('beforeunload', this.onBeforeUnload);
  },
  beforeDestroy() {
    window.removeEventListener('online', this.onNetworkOnline);
    window.removeEventListener('offline', this.onNetworkOffline);
  },
  watch: {
    hasActiveTrial: {
      immediate: false,
      handler() {
        this.handleTrial();
      },
    },
  },
  meta: {
    titleTemplate: (titleChunk) => (titleChunk ? `${titleChunk} - Teamwork Spaces` : 'Teamwork Spaces'),
  },
};
