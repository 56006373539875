import { faAngleLeft } from '@fortawesome/pro-light-svg-icons/faAngleLeft';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons/faArrowLeft';
import { faArrowToBottom } from '@fortawesome/pro-light-svg-icons/faArrowToBottom';
import { faBirthdayCake } from '@fortawesome/pro-light-svg-icons/faBirthdayCake';
import { faBook } from '@fortawesome/pro-light-svg-icons/faBook';
import { faBookmark } from '@fortawesome/pro-light-svg-icons/faBookmark';
import { faBookReader } from '@fortawesome/pro-light-svg-icons/faBookReader';
import { faCar } from '@fortawesome/pro-light-svg-icons/faCar';
import { faChartLine } from '@fortawesome/pro-light-svg-icons/faChartLine';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faChevronDoubleLeft } from '@fortawesome/pro-light-svg-icons/faChevronDoubleLeft';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { faChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp';
import { faClipboard } from '@fortawesome/pro-light-svg-icons/faClipboard';
import { faCloudUpload } from '@fortawesome/pro-light-svg-icons/faCloudUpload';
import { faCog } from '@fortawesome/pro-light-svg-icons/faCog';
import { faComment } from '@fortawesome/pro-light-svg-icons/faComment';
import { faCommentAltLines } from '@fortawesome/pro-light-svg-icons/faCommentAltLines';
import { faCommentAltPlus } from '@fortawesome/pro-light-svg-icons/faCommentAltPlus';
import { faCompressAlt } from '@fortawesome/pro-light-svg-icons/faCompressAlt';
import { faCopy } from '@fortawesome/pro-light-svg-icons/faCopy';
import { faCorn } from '@fortawesome/pro-light-svg-icons/faCorn';
import { faEllipsisH } from '@fortawesome/pro-light-svg-icons/faEllipsisH';
import { faEllipsisHAlt } from '@fortawesome/pro-light-svg-icons/faEllipsisHAlt';
import { faEllipsisV } from '@fortawesome/pro-light-svg-icons/faEllipsisV';
import { faExchangeAlt } from '@fortawesome/pro-light-svg-icons/faExchangeAlt';
import { faExpand } from '@fortawesome/pro-light-svg-icons/faExpand';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons/faEyeSlash';
import { faFileAlt } from '@fortawesome/pro-light-svg-icons/faFileAlt';
import { faFileImport } from '@fortawesome/pro-light-svg-icons/faFileImport';
import { faFileInvoice } from '@fortawesome/pro-light-svg-icons/faFileInvoice';
import { faFilter } from '@fortawesome/pro-light-svg-icons/faFilter';
import { faFlag } from '@fortawesome/pro-light-svg-icons/faFlag';
import { faFrown } from '@fortawesome/pro-light-svg-icons/faFrown';
import { faFutbol } from '@fortawesome/pro-light-svg-icons/faFutbol';
import { faHeart } from '@fortawesome/pro-light-svg-icons/faHeart';
import { faHistory } from '@fortawesome/pro-light-svg-icons/faHistory';
import { faImage } from '@fortawesome/pro-light-svg-icons/faImage';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import { faKeyboard } from '@fortawesome/pro-light-svg-icons/faKeyboard';
import { faLayerGroup } from '@fortawesome/pro-light-svg-icons/faLayerGroup';
import { faLightbulb } from '@fortawesome/pro-light-svg-icons/faLightbulb';
import { faLink } from '@fortawesome/pro-light-svg-icons/faLink';
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons/faPaperPlane';
import { faPaw } from '@fortawesome/pro-light-svg-icons/faPaw';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { faPencilAlt } from '@fortawesome/pro-light-svg-icons/faPencilAlt';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { faPlusSquare } from '@fortawesome/pro-light-svg-icons/faPlusSquare';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons/faQuestionCircle';
import { faRulerHorizontal } from '@fortawesome/pro-light-svg-icons/faRulerHorizontal';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { faSmileBeam } from '@fortawesome/pro-light-svg-icons/faSmileBeam';
import { faSortAlphaDown } from '@fortawesome/pro-light-svg-icons/faSortAlphaDown';
import { faSortAlphaUp } from '@fortawesome/pro-light-svg-icons/faSortAlphaUp';
import { faSortAmountUp } from '@fortawesome/pro-light-svg-icons/faSortAmountUp';
import { faStar } from '@fortawesome/pro-light-svg-icons/faStar';
import { faSync } from '@fortawesome/pro-light-svg-icons/faSync';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons/faTimesCircle';
import { faTrash } from '@fortawesome/pro-light-svg-icons/faTrash';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { faUserCircle } from '@fortawesome/pro-light-svg-icons/faUserCircle';
import { faUserPlus } from '@fortawesome/pro-light-svg-icons/faUserPlus';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { faUsers } from '@fortawesome/pro-light-svg-icons/faUsers';
import { faUserEdit } from '@fortawesome/pro-light-svg-icons/faUserEdit';

export default [
  faClipboard,
  faAngleLeft,
  faArrowLeft,
  faArrowToBottom,
  faBirthdayCake,
  faBook,
  faBookmark,
  faBookReader,
  faCar,
  faChartLine,
  faCheck,
  faChevronDoubleLeft,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCloudUpload,
  faCog,
  faComment,
  faCommentAltLines,
  faCommentAltPlus,
  faCompressAlt,
  faCopy,
  faCorn,
  faEllipsisH,
  faEllipsisHAlt,
  faEllipsisV,
  faExchangeAlt,
  faExpand,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFileImport,
  faFileInvoice,
  faFilter,
  faFlag,
  faFrown,
  faFutbol,
  faHeart,
  faHistory,
  faImage,
  faInfoCircle,
  faKeyboard,
  faLayerGroup,
  faLightbulb,
  faLink,
  faPaperPlane,
  faPaw,
  faPen,
  faPencilAlt,
  faPlus,
  faPlusSquare,
  faQuestionCircle,
  faRulerHorizontal,
  faSearch,
  faSmileBeam,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortAmountUp,
  faStar,
  faSync,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faUserPlus,
  faUser,
  faUsers,
  faUserCircle,
  faUserEdit,
];
