/**
 * Create default getters and merge them with getters defined by a user.
 */
const createGetters = ({ getters } = {}) => ({
  get(state) {
    return state.entity;
  },

  /**
   * Return true if there is a ongoing request.
   */
  isLoading(state) {
    return !!(
      state.isFetching
      || state.isCreating
      || state.isUpdating
      || state.isReplacing
      || state.isDestroying
    );
  },
  ...getters,
});

export default createGetters;
