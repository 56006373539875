import Vue from 'vue';
import { omit, clone } from 'lodash-es';
import axios from '@/utils/vuex/client';
import { createStaticCrudModule } from '@/utils/vuex';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import spaceConstants from '@/utils/constants/spaceConstants';

function insertAtPostion(index, item) {
  this.splice(index, 0, item);
}

export function flattenedPageList(unrestrictedPageList, restrictedPageList, homePage = null) {
  const pages = [];

  function process(parentId, depth, index, isRestricted) {
    // Make a copy of the page and omit the nested child pages to keep this list light
    const newPage = omit(clone(this), ['childPages']);

    // Augment pages with 'depth' and 'parentId' for handiness
    newPage.parentId = parentId;
    newPage.depth = depth;
    newPage.order = index;
    newPage.isRestricted = isRestricted;

    pages.push(newPage);
  }

  function traverse(entities, parentId, depth, isRestricted) {
    entities.forEach((entity, index) => {
      // Note: we need to ignore the home page as it's handled separately
      if (homePage && Number(entity.id) === Number(homePage.id)) {
        return;
      }

      process.call(entity, parentId, depth, index, isRestricted);

      if (Array.isArray(entity.childPages)) {
        traverse(entity.childPages, entity.id, depth + 1, isRestricted);
      }
    });
  }

  if (homePage) {
    process.call(homePage, null, 0, 0, false);
  }

  traverse(unrestrictedPageList, homePage && homePage.id, 0, false);

  traverse(restrictedPageList, homePage && homePage.id, 0, true);

  return pages;
}

function movePageInTree(tree, addNodeExpression, removeNodeExpression) {
  return tree.map((node) => {
    if (addNodeExpression) {
      // eslint-disable-next-line no-param-reassign
      node = addNodeExpression(node);
    }
    const childPages = (removeNodeExpression && removeNodeExpression(node.childPages)) || node.childPages;
    return {
      id: node.id,
      parentId: node.parentId,
      title: node.title,
      slug: node.slug,
      childPages: movePageInTree(childPages, addNodeExpression, removeNodeExpression),
    };
  });
}

/**
 * @typedef {Object} HomePage
 * @property {number} id
 * @property {string} slug
 * @property {string} title
 */

/**
 * @typedef {Object} SpacePage
 * @property {number} id
 * @property {string} slug
 * @property {string} title
 * @property {SpacePage[]} childPages
 */

/**
 * @typedef {Object} CustomSpacePage
 * @property {number} id
 * @property {string} slug
 * @property {string} title
 * @property {number|null} parentId
 * @property {number} depth - A helper property used to represent how deep within the overall page.
 * Note: this ignores the fact that technically all pages are nested under the home page. Top level
 * pages have a depth of 0
 * @property {number} order - Index of the page within its parent
 * @property {boolean} isRestricted - A helper property used to determine if a page is 'private'
 */

export const config = {
  only: ['FETCH', 'UPDATE'],
  property: 'spaceContent',
  customUrlFn(id) {
    if (!id) {
      throw new Error('Unable to construct URL. Missing required Space ID parameter');
    }

    return buildApiUrl({
      apiVersion: 'v2',
      resource: `spaces/${id}/pages`,
    });
  },
  state: {
    activeDragSource: '',
    pageToDrag: { id: 0 },
    movePage: { id: 0, position: '' }, // before, after, as child
  },
  mutations: {
    clearPageToDrag(state) {
      state.pageToDrag = { id: 0 };
    },
    setPageToDrag(state, pageToDrag) {
      state.pageToDrag = pageToDrag;
    },
    setDragSource(state, dragSource) {
      state.activeDragSource = dragSource;
    },
    setMovePage(state, movePage) {
      state.movePage = movePage;
    },
    movePage(state) {
      // get the node we are moving to
      // remove  from old node
      // add the new node
      // walk the tree with filter expression and add expression
      const removePageFilterExpression = (page) => page.id !== state.pageToDrag.id || page.addedNode === true;
      const removePageExpression = (children) => children.filter(removePageFilterExpression);
      const addPageExpression = (parent) => {
        // defaults to last item in list when dropping into a list
        const addedNode = { ...state.pageToDrag, addedNode: true };
        if (parent.id === state.movePage.page.id && state.movePage.position === 'middle') {
          parent.childPages.push(addedNode);
        }

        const index = parent.childPages.findIndex((x) => x.id === state.movePage.page.id);
        if ((index !== -1) && (state.movePage.position === 'top')) {
          insertAtPostion.apply(parent.childPages, [index, addedNode]);
        }
        if ((index !== -1) && (state.movePage.position === 'bottom')) {
          insertAtPostion.apply(parent.childPages, [index + 1, addedNode]);
        }
        return { ...parent, childPages: [...parent.childPages] };
      };

      // Map over the existing tree moving the dragged to node to its new position
      // by adding into the new location and filtering it out of its existing location
      const tree = movePageInTree([state.entity.pages],
        addPageExpression,
        removePageExpression,
      );

      [state.entity.pages] = tree;
    },
  },
  actions: {
    async performPageDuplication({ dispatch }, {
      spaceId, pageId, title, slug, parentId, readerInlineCommentsEnabled,
    }) {
      const data = {
        title,
        slug,
        parentId,
        readerInlineCommentsEnabled,
      };

      const url = buildApiUrl({
        resource: `spaces/${spaceId}/pages/${pageId}/duplicate`,
      });

      const createResponse = await axios.post(url, { page: data });

      if (!createResponse || !createResponse.data || !createResponse.data.page) {
        throw new Error('Invalid response from page duplication request');
      }

      dispatch('activeSpacePages/fetch', {}, { root: true });

      return createResponse.data.page;
    },

    async movePage({ dispatch, getters, commit, state, rootGetters }) {
      if (getters.hasDraggablePage) {
        // optimistically amend page tree
        commit('movePage');
        commit('clearPageToDrag');
        // Dispatch the update to the databse with the new content
        // This will be by pass CRUD, as we have made an optimistic
        // amendment to the page tree
        try {
          const url = buildApiUrl({
            apiVersion: 'v2',
            resource: `spaces/${rootGetters['navigation/activeSpaceId']}/pages`,
          });
          await axios.patch(url, {
            spaceContent: { pages: state.entity.pages },
          });

          if (Vue.$ga) {
            Vue.$ga.event({
              eventCategory: 'space-page-tree-reorder',
              eventAction: 'reorder',
              eventLabel: 'page has been reordered',
              eventValue: 1,
            });
          }
        } catch (error) {
          dispatch('alerts/showAlert', { type: 'error', message: 'There was a problem reordering your pages, please try again.' }, { root: true });
        }
      }
    },
  },
  getters: {
    /**
     * @returns {CustomSpacePage[]}
     */
    flattenedPageList: (state, getters) => flattenedPageList(
      getters.unrestrictedPageList,
      getters.restrictedPageList,
      getters.homePage,
    ),

    /**
     * @returns {CustomSpacePage[]}
     */
    flattenedUnrestrictedPageList: (state, getters) =>
      getters.flattenedPageList.filter((page) => !page.isRestricted),

    /**
     * @returns {CustomSpacePage[]}
     */
    flattenedRestrictedPageList: (state, getters) =>
      getters.flattenedPageList.filter((page) => page.isRestricted),

    /**
     * Returns a map of all Space pages (including the home page) where the key is the page ID
     * and the value is the page (in CustomSpacePage format)
     *
     * @returns {object}
     */
    pagesById: (state, getters) => getters.flattenedPageList
      .reduce((output, page) => {
        // eslint-disable-next-line no-param-reassign
        output[page.id] = page;
        return output;
      }, {}),

    /**
     * Returns a map of all 'non-private' Space pages where the key is the page ID
     * and the value is the page (in CustomSpacePage format)
     *
     * @returns {object}
     */
    unrestrictedPagesById: (state, getters) => getters.unrestrictedPageList
      .reduce((output, page) => {
        if (!page.isRestricted) {
          // eslint-disable-next-line no-param-reassign
          output[page.id] = page;
        }

        return output;
      }, {}),

    /**
     * Returns a map of all 'private' Space pages where the key is the page ID
     * and the value is the page (in CustomSpacePage format)
     *
     * @returns {object}
     */
    restrictedPagesById: (state, getters) => getters.restrictedPageList
      .reduce((output, page) => {
        if (page.isRestricted) {
          // eslint-disable-next-line no-param-reassign
          output[page.id] = page;
        }

        return output;
      }, {}),

    /**
     * @returns {HomePage}
     */
    homePage: (state, getters) => {
      if (
        !Object.hasOwnProperty.call(getters, 'get')
        || !Object.hasOwnProperty.call(getters.get, 'pages')
      ) {
        return null;
      }

      const homePage = clone(getters.get.pages);

      homePage.title = spaceConstants.defaultPageTitle;
      homePage.slug = spaceConstants.defaultPageCode;

      return omit(homePage, ['childPages']);
    },

    /**
     * @returns {(number|null)}
     */
    homePageId: (state, getters) => (getters.homePage && getters.homePage.id) || null,

    /**
     * The purpose of this getter is to provide a list of the 'private' pages within a given
     * Space
     *
     * @returns {SpacePage[]}
     */
    restrictedPageList: (state, getters) => {
      if (
        !Object.hasOwnProperty.call(getters, 'get')
        || !Array.isArray(getters.get.private)
      ) {
        return [];
      }

      return getters.get.private;
    },

    /**
     * The purpose of this getter is to provide a list of the 'non-private' pages within a given
     * Space. Please note that this excludes the home page for the Space
     *
     * @returns {SpacePage[]}
     */
    unrestrictedPageList: (state, getters) => {
      if (
        !Object.hasOwnProperty.call(getters, 'get')
        || !Object.hasOwnProperty.call(getters.get, 'pages')
        || !Array.isArray(getters.get.pages.childPages)
      ) {
        return [];
      }

      return getters.get.pages.childPages;
    },

    isPagePrivate: (state, getters) => (pageId) =>
      !!(getters.pagesById[pageId] && getters.pagesById[pageId].isRestricted),

    getPageById: (state, getters) => (pageId) => getters.pagesById[pageId] || null,

    isSharingEnabledForPageById: (state, getters) => (pageId) => {
      const page = getters.getPageById(pageId);

      return !!(page
        && page.share
        && page.share.state === 'enabled'
      ) || false;
    },

    isPageARootLevelPage: (state, getters) => (pageId) => {
      if (getters.isLoading) {
        return false;
      }

      const rootParent = getters.getRootParentPageForPage(pageId);

      if (!rootParent) {
        return true;
      }

      if (Number(rootParent.id) === Number(pageId)) {
        return true;
      }

      return false;
    },

    /**
     * Returns the top-most ancestor for a given page (or itself if it is a 'root level' page)
     * Note: this ignores the fact that technically all pages are nested under the home page
     *
     * @returns {CustomSpacePage}
     */
    getRootParentPageForPage: (state, getters) => (pageId) => {
      let page = getters.getPageById(pageId);

      if (!page) {
        throw new Error(`Page with ID ${pageId} not found`);
      }

      if (!Number(page.parentId)) {
        return null;
      }

      while (
        page
        && Number(page.parentId)
        && Number(page.parentId) !== Number(getters.homePageId)
      ) {
        page = getters.getPageById(page.parentId);

        if (!page) {
          throw new Error(`Page with ID ${page.parentId} not found`);
        }
      }

      return page;
    },

    /**
     * Returns a list of IDs representing the path to that page in the full page tree.
     * IDs are pushed to the list, meaning they are in order of bottom-most to top-most page
     * Note: this does not include the home page ID. It is the path to the page within
     * it's appropriate 'private' or 'pages' section and within the home page (if applicable)
     *
     * @returns {number[]}
     */
    getPathToPage: (state, getters) => (pageId) => {
      const path = [];

      let page = getters.getPageById(pageId);

      if (!page) {
        throw new Error(`Page with ID ${pageId} not found`);
      }

      path.push(page.id);

      while (
        page
        && Number(page.parentId)
        && getters.homePageId
        && Number(page.parentId) !== Number(getters.homePageId)
      ) {
        page = getters.getPageById(page.parentId);

        if (!page) {
          throw new Error(`Page with ID ${page.parentId} not found`);
        }

        path.push(page.id);
      }

      return path;
    },

    /* eslint-disable no-loop-func */
    findPageInTree: (state, getters) => (pageId) => {
      if (getters.homePageId && pageId === getters.homePageId) {
        return getters.get;
      }

      // First, find the path to the target page from the root
      const path = getters.getPathToPage(pageId);

      if (!path.length) {
        throw new Error('Path is empty. Cannot continue');
      }

      // Next, use the path to find the page within the main tree
      let currentId = path.pop();

      let currentPage;

      if (getters.isPagePrivate(currentId)) {
        currentPage = getters.get.private.find((page) => Number(page.id) === Number(currentId));

        if (!currentPage) {
          throw new Error('Path is invalid. Could not find one or more pages in page tree');
        }

        currentId = path.pop();
      } else {
        currentPage = getters.get.pages;
      }

      while (currentId) {
        currentPage = currentPage.childPages.find(
          (page) => Number(page.id) === Number(currentId),
        );

        if (!currentPage) {
          throw new Error('Path is invalid. Could not find one or more pages in page tree');
        }

        currentId = path.pop();
      }

      return currentPage;
    },

    /**
     * Returns a list of IDs of every descendant page of the specified page
     *
     * @returns {number[]}
     */
    getAllChildPageIdsForPage: (state, getters) => (pageId) => {
      if (!Number(pageId)) {
        throw new Error('Missing required argument: \'pageId\'');
      }

      // Note: in the context of the the web app, a home page doesn't have children so we'll
      // ignore home pages here
      if (
        getters.homePageId
        && Number(pageId) === Number(getters.homePageId)
      ) {
        return [];
      }

      const targetPage = getters.findPageInTree(pageId);

      // Walk the found page's own tree and grab all child page IDs
      const childPageIds = [];

      const grabChildPageIds = (childPages) => {
        childPages.forEach((page) => {
          childPageIds.push(page.id);

          if (Array.isArray(page.childPages)) {
            grabChildPageIds(page.childPages);
          }
        });
      };

      grabChildPageIds(targetPage.childPages);

      return childPageIds;
    },

    isParentOfActivePage: (state, getters) =>
      (pageId, activePageId) => getters.getPathToPage(activePageId).includes(pageId),

    hasDraggablePage: (state) => !!state.pageToDrag.id,
    hasDraggablePageForSource: (state) => (dragSource) => state.activeDragSource === dragSource,
  },
};

export default createStaticCrudModule(config);
