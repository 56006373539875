// @vue/component
export default {
  props: {
    message: {
      type: String,
      required: false,
      default: '',
    },
    isStatic: {
      type: Boolean,
      default: false,
    },
  },
};
