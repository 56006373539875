import defaultClient from '@/utils/vuex/client';
import { endpointUrls } from '@/utils/constants/twEndpoints';

const newCheckOutReferenceTimeInMinutes = 30;

export default {
  namespaced: true,
  state: {
    info: {},
    checkoutReferencePromise: null,
    checkoutReference: null,
    checkoutReferenceTime: null,
    fetchCheckoutReferenceError: null,
    isFetchingInfo: false,
    fetchInfoError: null,
  },
  mutations: {
    storeInfo(state, info) {
      /* eslint-disable-next-line no-param-reassign */
      state.info = { ...state.info, ...info };
    },

    setCheckoutReference(state, data) {
      state.checkoutReference = data;
    },

    setCheckoutReferencePromise(state, promise) {
      state.checkoutReferencePromise = promise;
    },

    setCheckoutReferenceTime(state, data) {
      state.checkoutReferenceTime = data;
    },

    setCheckoutReferenceFetchError(state, error) {
      state.fetchCheckoutReferenceError = error;
    },

    setInfoFetchState(state, data) {
      state.isFetchingInfo = data;
    },

    setInfoFetchError(state, error) {
      state.fetchInfoError = error;
    },
  },
  actions: {
    async fetchInfo({ dispatch, commit, state }) {
      if (state.isFetchingInfo) {
        return;
      }

      commit('setInfoFetchState', true);
      commit('setInfoFetchError', null);

      try {
        const response = await defaultClient.get(`${location.origin}/launchpad/v1/info.json`);
        commit('storeInfo', response.data.info || {});
      } catch (err) {
        commit('setInfoFetchError', err);
        dispatch('alerts/showAlert', { type: 'error', message: 'Failed to get launchpad info' }, { root: true });
      }

      commit('setInfoFetchState', false);
    },

    async fetchCheckoutReference({ commit, dispatch, state }) {
      const minutesSinceLastSubscriptionReference = (new Date() - state.subscriptionReferenceTime) / 1000 / 60;

      const isSubscriptionReferenceExpired = minutesSinceLastSubscriptionReference > newCheckOutReferenceTimeInMinutes;

      const shouldFetchSubscriptionReference = !state.checkoutReference
        || !state.subscriptionReferenceTime
        || isSubscriptionReferenceExpired;

      if (!shouldFetchSubscriptionReference) {
        return state.checkoutReference;
      }

      if (state.checkoutReferencePromise) {
        return state.checkoutReferencePromise;
      }

      const promise = (async () => {
        commit('setCheckoutReferenceFetchError', null);

        try {
          const response = await defaultClient.get(endpointUrls.spacesCheckoutRef);
          commit('setCheckoutReference', response.data.ref);
          commit('setCheckoutReferenceTime', new Date());

          return response.data.ref;
        } catch (error) {
          commit('setCheckoutReferenceFetchError', error);
          dispatch(
            'alerts/showAlert',
            { type: 'error', message: 'Failed to get checkout reference info' },
            { root: true },
          );

          throw error;
        } finally {
          commit('setCheckoutReferencePromise', null);
        }
      })();

      commit('setCheckoutReferencePromise', promise);

      await promise;

      return state.checkoutReference;
    },
  },
  getters: {
    info: (state) => state.info || {},

    hasDesk: (state) => !!(state.info && state.info.apps && state.info.apps.desk) || false,

    hasProjects: (state) => !!(state.info && state.info.apps && state.info.apps.projects) || false,

    hasChat: (state) => !!(state.info && state.info.apps && state.info.apps.chat) || false,

    hasCrm: (state) => !!(state.info && state.info.apps && state.info.apps.crm) || false,

    hasSpaces: (state) => !!(state.info && state.info.apps && state.info.apps.spaces) || false,

    isFetchingCheckoutReference: (state) => Boolean(state.checkoutReferencePromise),

    themeColor: (state) =>
      (state.info
        && state.info.displayOptions
        && state.info.displayOptions.themeColor
        && `#${state.info.displayOptions.themeColor.replace(/^#/, '')}`)
      || '',

    url: (state) => state.info.url,

    checkoutReference: (state) => state.checkoutReference,

    isLoading: (state, getters) => state.isFetchingInfo || getters.isFetchingCheckoutReference,
  },
};
