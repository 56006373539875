import { REACTION_TYPES, REACTION_TYPES_BY_ID } from '@/utils/constants/reactions';

export default {
  namespaced: true,
  getters: {
    list() {
      return REACTION_TYPES;
    },

    byId() {
      return (id) => REACTION_TYPES_BY_ID[id] || null;
    },
  },
};
