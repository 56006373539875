import { getLocalDevUrl, getLocalDevHostname } from '@/utils/constants/twEndpoints';

export default {
  // sunbeam install is US region
  development: {
    US: {
      ot: getLocalDevUrl(),
      spacesSocket: getLocalDevUrl('spacessocket'),
      syncOT: getLocalDevHostname(),
    },
  },
  staging: {
    US: {
      ot: 'https://ot-server.staging.teamwork.com',
      spacesSocket: 'https://spacessockets.staging.teamwork.com',
      syncOT: 'syncot.stg.teamworkops.com',
    },
  },
  production: {
    EU: {
      ot: 'https://ot-server.eu.teamwork.com',
      spacesSocket: 'https://spacessockets.eu.teamwork.com',
      syncOT: 'syncot.eu.teamworkops.com',
    },
    US: {
      ot: 'https://ot-server.teamwork.com',
      spacesSocket: 'https://spacessockets.teamwork.com',
      syncOT: 'syncot.us.teamworkops.com',
    },
  },
};
