/* eslint-disable func-names */
// Plan codes from checkout
export const PLANS = Object.freeze({
  SPACES_BASE_RATE: {
    CODE: 'spaces-flat',
    ID: 27,
    NAME: 'Spaces Base Rate',
  },
  SPACES_BASE_ONE: {
    CODE: 'spaces-band-one',
    ID: 28,
    NAME: 'Spaces Band One',
  },
  SPACES_BASE_TWO: {
    CODE: 'spaces-band-two',
    ID: 29,
    NAME: 'Spaces Band Two',
  },
  SPACES_BASE_THREE: {
    CODE: 'spaces-band-three',
    ID: 30,
    NAME: 'Spaces Band Three',
  },
  ENTERPRISE: {
    CODE: 'spaces-enterprise',
    ID: 31,
    NAME: 'Enterprise',
  },
  STARTER: {
    CODE: 'spaces-starter',
    ID: 38,
    NAME: 'Starter',
  },
  PRO: {
    CODE: 'spaces-pro',
    ID: 39,
    NAME: 'Pro',
  },
  TEAMWORK_ONE: {
    CODE: 'one-2020-08',
    ID: 43,
    NAME: 'Teamwork One',
  },
  SPACES_BUNDLED: {
    CODE: 'spaces-bundled',
    ID: 49,
    NAME: 'Free Spaces with Teamwork',
  },
});

export const PRICE_PLAN_MAP = (function () {
  return Object.values(PLANS).reduce((result, { ID, CODE, NAME }) => {
    result.set(ID, {
      id: ID,
      code: CODE,
      name: NAME,
    });
    return result;
  }, new Map());
}());

// Old plans ID that we grandfather, display regular subscription page for these
export const GRANDFATHER_PRICE_PLAN_IDS = Object.freeze([
  27,
  28,
  29,
  30,
]);

export default {
  PLANS,
  PRICE_PLAN_MAP,
  GRANDFATHER_PRICE_PLAN_IDS,
};
