import { mapState } from 'vuex';
import { compact } from 'lodash-es';

import tinymceCommands from '@/utils/constants/tinymceCommands';
import TinymceCommandMixin from '../Mixins/TinymceCommandMixin';

// @vue/component
export default {
  mixins: [TinymceCommandMixin],

  props: {
    type: {
      type: String,
      default: '',
      required: false,
    },
    icon: {
      type: Array,
      default: undefined,
      required: false,
    },
    isActive: {
      type: Boolean,
      default: undefined,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    variant: {
      type: String,
      default: '',
      required: false,
    },
  },

  computed: {
    ...mapState('ui/activeEditor', ['activeToolbarButtons']),

    isButtonActive() {
      if (this.isActive === undefined) {
        return this.activeToolbarButtons.includes(this.type);
      }

      return this.isActive;
    },

    tinymceCommand() {
      if (this.type in tinymceCommands) {
        return tinymceCommands[this.type];
      }
      return false;
    },

    classes() {
      return [
        'spaces-editor-toolbar-button',
        {
          'spaces-editor-toolbar-button--is-active': this.isButtonActive,
          'spaces-editor-toolbar-button--is-disabled': this.disabled,
        },
        ...this.buttonVariants,
      ];
    },

    buttonVariants() {
      return compact(this.variant.split(' ')).map((variant) => `spaces-editor-toolbar-button--${variant}`);
    },
  },
  methods: {
    onClick() {
      if (this.tinymceCommand) {
        this.executeCommand(this.type);
        return;
      }
      this.$emit('click');
    },
  },
};
