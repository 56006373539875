// add a new styleseed to <head> and re
// ref: https://davidwalsh.name/add-rules-stylesheets
/**
  add a new styleseed to <head>
  ref: https://davidwalsh.name/add-rules-stylesheets
 * @returns {StyleSheet}
 */
export default () => {
  // Create the <style> tag
  const style = document.createElement('style');

  // Add a media (and/or media query) here if you'd like!
  // style.setAttribute("media", "screen")
  // style.setAttribute("media", "only screen and (max-width : 1024px)")

  // WebKit hack :(
  style.appendChild(document.createTextNode(''));

  // Add the <style> element to the page
  document.head.appendChild(style);

  return style.sheet;
};
