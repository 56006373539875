import { ROUTE_NAMES } from '@/router/routes';

export default [
  {
    name: 'welcome',
    path: '/welcome',
    component: () => import(/* webpackChunkName: "onboarding-page" */ '@pages/Onboarding'),
    children: [
      {
        path: '',
        name: ROUTE_NAMES.ONBOARDING,
        components: {
          content: () => import(/* webpackChunkName: "onboarding-page" */ '@sections/Onboarding/WelcomeMessage'),
          image: () => import(/* webpackChunkName: "onboarding-page" */ '@/assets/onboarding/welcome-message.svg'),
        },
      },
      {
        path: '/welcome/invite',
        name: ROUTE_NAMES.ONBOARDING_INVITE,
        components: {
          content: () => import(/* webpackChunkName: "onboarding-page" */ '@sections/Onboarding/InvitePeople'),
          image: () => import(/* webpackChunkName: "onboarding-page" */ '@/assets/onboarding/invite-people.svg'),
        },
        meta: {
          adminRoute: true,
        },
      },
      {
        path: '/welcome/select-space',
        name: ROUTE_NAMES.ONBOARDING_SELECT_SPACE,
        components: {
          content: () => import(/* webpackChunkName: "onboarding-page" */ '@sections/Onboarding/SelectSpace'),
          image: () => import(/* webpackChunkName: "onboarding-page" */ '@/assets/onboarding/first-space.svg'),
        },
        beforeEnter: (to, from, next) => {
          // Cannot hard link to this route, need to be navigated to
          // via the onboarding workflow.
          if (from && !from.name) {
            return next({ name: ROUTE_NAMES.ONBOARDING });
          }
          return next();
        },
      },
      {
        path: '/welcome/first-space',
        name: ROUTE_NAMES.ONBOARDING_FIRST_SPACE,
        components: {
          content: () => import(/* webpackChunkName: "onboarding-page" */ '@sections/Onboarding/FirstSpace'),
          image: () => import(/* webpackChunkName: "onboarding-page" */ '@/assets/onboarding/first-space.svg'),
        },
        beforeEnter: (to, from, next) => {
          // Cannot hard link to this route, need to be navigated to
          // via the onboarding workflow.
          if (from && !from.name) {
            return next({ name: ROUTE_NAMES.ONBOARDING });
          }
          return next();
        },
      },
    ],
  },
];
