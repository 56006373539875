import { nextTick } from 'vue-demi';

import store from '@/store';

import { FEATURE_KEYS } from '@/store/modules/features';
import { ROUTES, ROUTE_NAMES } from './routes';

export const globalPageTemplateCreateRouteName = 'globalPageTemplate.create';
export const globalPageTemplateEditRouteName = 'globalPageTemplate.edit';
export const pageTemplateCreateRouteName = ROUTE_NAMES.TEMPLATES_CREATE;
export const pageTemplateEditRouteName = ROUTE_NAMES.TEMPLATES_EDIT;
export const templateManagementRouteName = ROUTE_NAMES.TEMPLATES;
export const templateGalleryRouteName = ROUTE_NAMES.TEMPLATES_GALLERY;
export const customTemplateRouteName = ROUTE_NAMES.TEMPLATES_CUSTOM_TEMPLATE;
export const globalTemplateRouteName = ROUTE_NAMES.TEMPLATES_GLOBAL_TEMPLATE;

export default [
  {
    path: '/settings/templates',
    redirect: ROUTES[ROUTE_NAMES.TEMPLATES].path,
  },
  {
    path: '/settings/templates/create',
    redirect: ROUTES[ROUTE_NAMES.TEMPLATES_CREATE].path,
  },
  {
    path: '/settings/templates/:templateId(\\d+)/edit',
    redirect: ROUTES[ROUTE_NAMES.TEMPLATES_EDIT].path,
  },
  {
    path: '/settings/templates/global/create',
    redirect: '/templates/global/create',
  },
  {
    path: '/settings/templates/global/:templateId(\\d+)/edit',
    redirect: '/templates/global/:templateId(\\d+)/edit',
  },
  {
    path: ROUTES[ROUTE_NAMES.TEMPLATES].path,
    name: ROUTE_NAMES.TEMPLATES,
    component: () => import(/* webpackChunkName: "app-layout" */ '@pages/AppLayout'),
    children: [
      {
        path: ROUTES[ROUTE_NAMES.TEMPLATES].path,
        name: ROUTE_NAMES.TEMPLATES,
        props: false,
        components: {
          sidebar: () => import(/* webpackChunkName: "home-sidebar" */ '@sections/HomeSidebar'),
          content: () => import(/* webpackChunkName: "pagetemplates" */ '@sections/PageTemplates'),
        },
      },
      {
        path: ROUTES[ROUTE_NAMES.TEMPLATES_GALLERY].path,
        name: ROUTE_NAMES.TEMPLATES_GALLERY,
        props: false,
        components: {
          sidebar: () => import(/* webpackChunkName: "home-sidebar" */ '@sections/HomeSidebar'),
          content: () => import(/* webpackChunkName: "pagetemplates" */ '@sections/PageTemplates'),
        },
      },
      {
        path: ROUTES[ROUTE_NAMES.TEMPLATES_CUSTOM_TEMPLATE].path,
        name: ROUTE_NAMES.TEMPLATES_CUSTOM_TEMPLATE,
        components: {
          sidebar: () => import(/* webpackChunkName: "settings-sidebar" */ '@sections/HomeSidebar'),
          content: () => import(/* webpackChunkName: "settings-pagetemplates" */ '@sections/PageTemplates'),
        },
      },
      {
        path: ROUTES[ROUTE_NAMES.TEMPLATES_GLOBAL_TEMPLATE].path,
        name: ROUTE_NAMES.TEMPLATES_GLOBAL_TEMPLATE,
        components: {
          sidebar: () => import(/* webpackChunkName: "settings-sidebar" */ '@sections/HomeSidebar'),
          content: () => import(/* webpackChunkName: "settings-pagetemplates" */ '@sections/PageTemplates'),
        },
      },
    ],
  },
  {
    path: ROUTES[ROUTE_NAMES.TEMPLATES_CREATE].path,
    name: ROUTE_NAMES.TEMPLATES_CREATE,
    props: false,
    component: () => import(/* webpackChunkName: "page-templates-editor" */ '@sections/PageTemplateEditorRouterView'),
    async beforeEnter(to, from, next) {
      await store.dispatch('features/check');

      if (store.getters['features/hasReachedPageTemplateLimit']) {
        next({ name: ROUTE_NAMES.TEMPLATES });
        nextTick(() => {
          store.dispatch('features/triggerUpgradeModal', { feature: FEATURE_KEYS.UNLIMITED_CUSTOM_PAGE_TEMPLATES });
        });
        return;
      }

      next();
    },
  },
  {
    path: ROUTES[ROUTE_NAMES.TEMPLATES_EDIT].path,
    name: ROUTE_NAMES.TEMPLATES_EDIT,
    props: (route) => {
      const props = { ...route.params };
      props.templateId = Number(props.templateId);
      return props;
    },
    component: () => import(/* webpackChunkName: "page-templates-editor" */ '@sections/PageTemplateEditorRouterView'),
  },
  {
    path: '/templates/global/create',
    name: globalPageTemplateCreateRouteName,
    props: { isGlobalTemplate: true },
    component: () => import(/* webpackChunkName: "page-templates-editor" */ '@sections/PageTemplateEditorRouterView'),
  },
  {
    path: '/templates/global/:templateId(\\d+)/edit',
    name: globalPageTemplateEditRouteName,
    props: (route) => {
      const props = { ...route.params };
      props.isGlobalTemplate = true;
      props.templateId = Number(props.templateId);
      return props;
    },
    component: () => import(/* webpackChunkName: "page-templates-editor" */ '@sections/PageTemplateEditorRouterView'),
  },
];
