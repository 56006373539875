import { createCrudModule } from '@/utils/vuex';
import { assignIn, clone, cloneDeep } from 'lodash-es';

import alphabeticalSort from '@/utils/helpers/alphabeticalSort';
import retry from '@/utils/helpers/retry';
import defaultClient from '@/utils/vuex/client';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import RESOURCES from '@/utils/constants/resources';

/*
 * Note: CREATE, REPLACE, DESTROY actions are proxied to Teamwork (Projects) API and therefore
 * their actions must be overridden in this module
 */
export default createCrudModule({
  only: ['FETCH_LIST', 'FETCH_SINGLE', 'CREATE', 'REPLACE', 'DESTROY'],
  resource: 'company',
  getters: {
    listAlphabetical(state, getters, rootState, rootGetters) {
      const installation = rootGetters['installation/get'];

      if (!installation || !installation.company || !installation.company.id) {
        return getters.list;
      }

      const ownerCompanyId = Number(installation.company.id) || null;

      // Note: ensure the 'owner' company is at the start of the list but the list is
      // otherwise alphabetical
      const companies = cloneDeep(getters.list);

      companies.sort((a, b) => {
        if (ownerCompanyId && Number(ownerCompanyId) === Number(a.id)) {
          return -1;
        }

        if (ownerCompanyId && Number(ownerCompanyId) === Number(b.id)) {
          return 1;
        }

        return alphabeticalSort(a.name.toLowerCase(), b.name.toLowerCase());
      });

      return companies;
    },

    byCompanyName(state) {
      return (query, order, descending) => Object.values(state.entities).filter((c) => {
        const queryLC = query.toLowerCase();

        if (c.name.toLowerCase().indexOf(queryLC) !== -1) {
          return true;
        }

        return false;
      }).sort((a, b) => {
        if (!order) {
          return 0;
        }

        return alphabeticalSort(a[order].toLowerCase(), b[order].toLowerCase(), descending);
      });
    },

    getUserCountForCompany: (state, getters, rootState, rootGetters) => (companyId) => rootGetters['users/list'].reduce((count, user) => {
      if (user && user.company && Number(user.company.id) === Number(companyId)) {
        // eslint-disable-next-line no-param-reassign
        count += 1;
      }

      return count;
    }, 0),

    ownerCompany(state, getters, rootState, rootGetters) {
      const installation = rootGetters['installation/get'];

      if (!installation || !installation.company || !installation.company.id) {
        return undefined;
      }

      return state.entities[Number(installation.company.id)];
    },

    isOwnerCompany: (state, getters) => (companyId) => {
      const ownerCompany = getters.ownerCompany;

      if (!ownerCompany) {
        return false;
      }

      const ownerCompanyId = Number(ownerCompany.id) || null;

      return Number(ownerCompanyId) === Number(companyId);
    },
  },
  actions: {
    async create({ commit }, {
      data,
      config = {},
    } = {}) {
      commit('createStart', { params: { data } });

      // eslint-disable-next-line no-param-reassign
      data = {
        [RESOURCES.COMPANY]: data,
      };

      try {
        const postResponse = await defaultClient.post(
          buildApiUrl({ resource: RESOURCES.COMPANY_PLURAL }),
          data,
          config,
        );

        const fetchCompany = () => defaultClient.get(buildApiUrl({ resource: `${RESOURCES.COMPANY_PLURAL}/${postResponse.data.id }` }));

        const newCompany = await (async () => {
          const fetchResponse = await retry(fetchCompany);
          const companyData = fetchResponse.data[RESOURCES.COMPANY];

          commit('createSuccess', {
            params: {
              response: {
                data: companyData,
              },
            },
          });

          return companyData;
        })();

        return newCompany;
      } catch (error) {
        commit('createError', error);
        return Promise.reject(error);
      }
    },

    async replace({ commit, getters }, {
      id,
      data,
      config = {},
      isOptimistic = false,
    } = {}) {
      commit('replaceStart', { params: { id, data }, options: { isOptimistic } });

      // eslint-disable-next-line no-param-reassign
      data = {
        [RESOURCES.COMPANY]: data,
      };

      try {
        await defaultClient.put(
          buildApiUrl({ resource: `${RESOURCES.COMPANY_PLURAL}/${id}` }),
          data,
          config,
        );

        // Note: avoiding a re-fetch by directly modifying the value in the store
        const companyBeforeUpdate = getters.byId(id) || {};
        const companyAfterUpdate = assignIn(clone(companyBeforeUpdate), data[RESOURCES.COMPANY]);

        commit('replaceSuccess',
          { params: { response: { data: companyAfterUpdate } }, options: { isOptimistic } },
        );

        return companyAfterUpdate;
      } catch (error) {
        commit('replaceError', error);
        return Promise.reject(error);
      }
    },

    destroy({ commit }, {
      id,
      config,
      isOptimistic = false,
    } = {}) {
      commit('destroyStart', { params: { id }, options: { isOptimistic } });

      return defaultClient.delete(
        buildApiUrl({ resource: `${RESOURCES.COMPANY_PLURAL}/${id}` }),
        config,
      )
        .then((response) => {
          commit('destroySuccess', { params: { id }, options: { isOptimistic } });

          return response;
        })
        .catch((error) => {
          commit('destroyError', error);
          return Promise.reject(error);
        });
    },
  },
});
