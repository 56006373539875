import Button from '@widgets/Button';
import Icon from '@widgets/Icon';
import Spinner from '@widgets/Spinner';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import SkeletonText from '@widgets/SkeletonText';

export default {
  FaIcon: FontAwesomeIcon,
  WSpinner: Spinner,
  WButton: Button,
  WIcon: Icon,
  SkeletonText,
};
