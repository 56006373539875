// ** convert a dataURL to a blob reference **
// eslint-disable-next-line no-unused-vars
export default function dataURLtoBlob(dataurl) {
  // eslint-disable-next-line prefer-const
  let arr = dataurl.split(',');
  // eslint-disable-next-line prefer-const
  let mime = arr[0].match(/:(.*?);/)[1];
  // eslint-disable-next-line prefer-const
  let bstr = atob(arr[1]);
  let n = bstr.length;
  // eslint-disable-next-line prefer-const
  let u8arr = new Uint8Array(n);

  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return URL.createObjectURL(new Blob([u8arr], { type: mime }));
}
