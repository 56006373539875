import { hasActiveSpacePermission } from '@/utils/access-control';
import { PERMISSION_LEVELS } from '@/utils/constants/spacePermissions';

const INSUFFICIENT_DRAFT_VERSION_NUMBER = 1;

export default {
  namespaced: true,
  state: {
    isMobileBreakpoint: false,
    isTabletBreakpoint: false,
    canPublishPage: true,
    isDeletingPage: false,
    newVersionAlertDismissed: false,
    canRefetchPage: false,
    shouldRerenderPageContent: false,
    shouldResyncOtContent: false,
  },
  actions: {
    setPageToDeleting({ commit }, isDeleting) {
      commit('setPageToDeleting', isDeleting);
    },

    dismissNewVersionAlert({ commit }) {
      commit('setNewVersionAlertDismissed', true);
    },

    setCanRefetchPage({ commit }, canRefetchPage) {
      commit('setCanRefetchPage', canRefetchPage);
    },

    setShouldRerenderPageContent({ commit }, shouldRerenderPageContent) {
      commit('setShouldRerenderPageContent', shouldRerenderPageContent);
    },

    setShouldResyncOtContent({ commit }, shouldResyncOtContent) {
      commit('setShouldResyncOtContent', shouldResyncOtContent);
    },

    async refetchPage({ commit, state, dispatch, rootGetters }) {
      if (!state.canRefetchPage) {
        return;
      }

      const spaceId = rootGetters['navigation/activeSpaceId'];
      const pageId = rootGetters['navigation/activePageId'];

      if (!spaceId || !pageId) {
        return;
      }

      await dispatch('pages/fetchSingle', {
        customUrlFnArgs: spaceId,
        id: pageId,
        config: {
          params: {
            include: '',
          },
        },
      }, { root: true });

      commit('setCanRefetchPage', false);
    },
  },
  mutations: {
    setIsMobileBreakpoint(state, value) {
      state.isMobileBreakpoint = value;
    },

    setIsTabletBreakpoint(state, value) {
      state.isTabletBreakpoint = value;
    },

    setPageToDeleting(state, isDeleting) {
      state.isDeletingPage = isDeleting;
    },

    setCanPublishPage(state, canPublishPage) {
      state.canPublishPage = canPublishPage;
    },

    setNewVersionAlertDismissed(state, value) {
      state.newVersionAlertDismissed = value;
    },

    setCanRefetchPage(state, canRefetchPage) {
      state.canRefetchPage = canRefetchPage;
    },

    setShouldRerenderPageContent(state, shouldRerenderPageContent) {
      state.shouldRerenderPageContent = shouldRerenderPageContent;
    },

    setShouldResyncOtContent(state, shouldResyncOtContent) {
      state.shouldResyncOtContent = shouldResyncOtContent;
    },
  },
  getters: {
    isDeletingPage(state) {
      return state.isDeletingPage;
    },

    hasBannerImage(state, getters, rootState, rootGetters) {
      const activePageGetter = rootGetters['navigation/activePage'];

      return !!(activePageGetter && activePageGetter.banner && activePageGetter.banner.url);
    },

    canPublishPage: (state) => state.canPublishPage,

    shouldShowPagePrivacyOptions(state, getters, rootState, rootGetters) {
      return !!(
        rootGetters['navigation/activePage']
        && !rootGetters['navigation/isActivePageAHomepage']
        && (
          hasActiveSpacePermission(PERMISSION_LEVELS.EDIT)
          || hasActiveSpacePermission(PERMISSION_LEVELS.MANAGE)
        )
      );
    },

    canConsumeInlineCommentSocketEvents: (state) => state.canPublishPage,

    isAnyLoading: (state, getters, rootState, rootGetters) => { // eslint-disable-line
      return rootGetters['ui/isLoadingGlobal'] || rootGetters['ui/isLoadingPage'] || rootGetters['ui/isLoadingSection']
        || (rootGetters['navigation/isEditingSpacePage'] && rootGetters['ui/spacesEditor/isLoadingInitialContent']);
    },

    shouldShowHelpers(state, getters, rootState, rootGetters) {
      return rootGetters['session/preferences'] && rootGetters['session/preferences'].showOnboardingHelp
        && !rootGetters['installation/installationIsLocked'] && !getters.isAnyLoading
        && !rootGetters['ui/embeddedChat/isEmbeddedChatOpen'];
    },

    shouldShowOnboardingHelp(state, getters, rootState, rootGetters) {
      if (rootGetters['navigation/isEditingSpacePage']) {
        return getters.shouldShowHelpers && !rootGetters['ui/inlineComments/shouldShowCommentsGutter'];
      }

      return getters.shouldShowHelpers;
    },

    shouldShowNewPageVersionBanner(state, getters, rootState) {
      return rootState.pages.updatedPageAvailableFromSocket
        && !state.newVersionAlertDismissed;
    },

    canRefetchPage: (state) => state.canRefetchPage,

    shouldRerenderPageContent: (state) => state.shouldRerenderPageContent,

    shouldResyncOtContent: (state) => state.shouldResyncOtContent,

    hasAcceptableDraftVersion(state, getters, rootState, rootGetters) {
      return rootGetters['navigation/activePage'] && rootGetters['navigation/activePage'].draftVersion > INSUFFICIENT_DRAFT_VERSION_NUMBER;
    },

    allowInlineCommentsInReadMode(state, getters, rootState, rootGetters) {
      const activePageGetter = rootGetters['navigation/activePage'];

      // Don't allow inline comments if we don't have proper draftVersion,
      // the page needs to be published or republished to save correct draftVersion
      if (!activePageGetter || !getters.hasAcceptableDraftVersion) {
        return false;
      }

      return activePageGetter.readerInlineCommentsEnabled;
    },
  },
};
