export const formattingShortcuts = [
  {
    id: 'bold',
    label: 'Bold',
    hasMeta: true,
    hasShift: false,
    hasModifier: false,
    key: 'b',
  },
  {
    id: 'italic',
    label: 'Italic',
    hasMeta: true,
    hasShift: false,
    hasModifier: false,
    key: 'i',
  },
  {
    id: 'underline',
    label: 'Underline',
    hasMeta: true,
    hasShift: false,
    hasModifier: false,
    key: 'u',
  },
  {
    id: 'strikethrough',
    label: 'Strikethrough',
    hasMeta: true,
    hasShift: true,
    hasModifier: false,
    key: 's',
  },
  {
    id: 'code',
    label: 'Code',
    hasMeta: true,
    hasShift: true,
    hasModifier: false,
    key: 'm',
  },
  {
    id: 'cut',
    label: 'Cut',
    hasMeta: true,
    hasShift: false,
    hasModifier: false,
    key: 'x',
  },
  {
    id: 'copy',
    label: 'Copy',
    hasMeta: true,
    hasShift: false,
    hasModifier: false,
    key: 'c',
  },
  {
    id: 'paste',
    label: 'Paste',
    hasMeta: true,
    modifiers: [],
    hasModifier: false,
    key: 'p',
  },
  {
    id: 'undo',
    label: 'Undo',
    hasMeta: true,
    hasShift: false,
    hasModifier: false,
    key: 'z',
  },
  {
    id: 'redo',
    label: 'Redo',
    hasMeta: true,
    hasShift: false,
    hasModifier: false,
    key: 'y',
  },
  {
    id: 'insert-link',
    label: 'Insert link',
    hasMeta: true,
    hasShift: false,
    hasModifier: false,
    key: 'k',
  },
  {
    id: 'indent-item',
    label: 'Indent',
    hasMeta: false,
    hasShift: false,
    hasModifier: false,
    key: 'tab',
  },
  {
    id: 'outdent-item',
    label: 'Outdent',
    hasMeta: false,
    hasShift: true,
    hasModifier: false,
    key: 'tab',
  },
  {
    id: 'clear-formatting',
    label: 'Clear formatting',
    hasMeta: true,
    hasShift: false,
    hasModifier: false,
    key: '\\',
  },
];

export const markdownShortcuts = [
  {
    id: 'numbered-list',
    label: '1. Numbered list',
    hasMeta: true,
    hasShift: true,
    hasModifier: false,
    key: '7',
  },
  {
    id: 'bulleted-list',
    label: '• Bullet list',
    hasMeta: true,
    hasShift: true,
    hasModifier: false,
    key: '8',
  },
  {
    id: 'paragraph',
    label: 'Paragraph',
    hasMeta: true,
    hasShift: false,
    hasModifier: true,
    key: '0',
  },
  {
    id: 'heading-1',
    label: 'Heading 1',
    hasMeta: true,
    hasShift: false,
    hasModifier: true,
    key: '1',
  },
  {
    id: 'heading-2',
    label: 'Heading 2',
    hasMeta: true,
    hasShift: false,
    hasModifier: true,
    key: '2',
  },
  {
    id: 'heading-3',
    label: 'Heading 3',
    hasMeta: true,
    hasShift: false,
    hasModifier: true,
    key: '3',
  },
  {
    id: 'heading-4',
    label: 'Heading 4',
    hasMeta: true,
    hasShift: false,
    hasModifier: true,
    key: '4',
  },
  // TBD - decide on shortcut key for placeholder text
  {
    id: 'placeholder-text',
    label: 'Placeholder text',
    hasMeta: true,
    hasShift: false,
    hasModifier: true,
    key: '6',
  },
  {
    id: 'divider',
    label: 'Divider',
    hasMeta: true,
    hasShift: true,
    hasModifier: false,
    key: '-',
  },
  {
    id: 'publish',
    label: 'Publish',
    hasMeta: true,
    hasShift: false,
    hasModifier: false,
    key: 'enter',
  },
];

export default {
  formattingShortcuts,
  markdownShortcuts,
};
