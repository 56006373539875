import TESTING_INSTALLATION_IDS from '@/utils/constants/testingInstallationIds';

export default [
  1,
  371533,
  336629,
  214793,
  435174,
  520970,
  514240,
  561560,
  ...TESTING_INSTALLATION_IDS,
];
