const USER_ACCESS_ERRORS = {
  PERMISSION: 'Insufficient permission level to allow access',
  PAGE_IN_TRASH: 'Page has been trashed, access denied',
};

if (Object.freeze) {
  Object.freeze(USER_ACCESS_ERRORS);
}

export default USER_ACCESS_ERRORS;
