import PageNotFound from './404';
import PageNotAuthorized from './403';
import AppDownError from './AppDownError';
import UnhandledError from './Unhandled';
import EmptyErrorContainer from './ErrorContainer';

// @vue/component
export default {
  props: {
    errorCode: {
      type: String,
      default: '404',
    },
    errorMessage: {
      type: String,
      default: '',
    },
    errorBody: {
      type: Object,
      default: () => { },
    },
  },
  components: {
    PageNotFound,
    PageNotAuthorized,
  },
  computed: {
    errorIllustrationToRender() {
      if (this.errorCode === '401') {
        // Render nothing as we are heading back to Launchpad
        return EmptyErrorContainer;
      }

      if (this.errorCode === '403') {
        return PageNotAuthorized;
      }

      if (this.errorCode === '404') {
        return PageNotFound;
      }

      if (this.errorCode === '502') {
        return AppDownError;
      }

      return UnhandledError;
    },
  },
  render(h) {
    return h(this.errorIllustrationToRender,
      {
        props: {
          errorCode: this.errorCode,
          errorBody: this.errorBody,
        },
      });
  },
  meta() {
    return {
      title: this.errorCode,
    };
  },
};
