import { getEditorSelectionFormats, getSelectionForeColor } from '@/utils/helpers/tinymceSelectionHelpers';
import tinymceCommands from '@/utils/constants/tinymceCommands';

export default {
  namespaced: true,
  state: {
    activeSelectionFormats: [],
    activeForeColor: 'rgb(0, 45, 0)',
    canUndo: false,
    canRedo: false,
    activeToolbarButtons: [],
  },
  getters: {
    activeSelectionFormats: (state) => (
      Array.isArray(state.activeSelectionFormats)
        ? state.activeSelectionFormats
        : []
    ),

    hasActiveSelectionFormat: (state, getters) => (format) => !!getters.activeSelectionFormats
      .find((selectionFormat) => selectionFormat === format),
  },
  mutations: {
    setCanUndo(state, canUndo) {
      state.canUndo = canUndo;
    },

    setCanRedo(state, canRedo) {
      state.canRedo = canRedo;
    },

    setActiveSelectionFormats(state, activeFormats) {
      state.activeSelectionFormats = activeFormats;
    },

    setActiveForeColor(state, color) {
      state.activeForeColor = color;
    },

    setActiveToolbarButtons(state, activeButtons) {
      state.activeToolbarButtons = activeButtons;
    },
  },
  actions: {
    refreshActiveSelectionFormats({ commit, dispatch }, editor) {
      if (!editor || !editor.selection) {
        return;
      }

      const activeFormats = getEditorSelectionFormats(editor);
      const activeForeColor = getSelectionForeColor(editor);
      commit('setActiveSelectionFormats', activeFormats);
      commit('setActiveForeColor', activeForeColor);
      dispatch('updateActiveToolbarButtons');
    },

    updateActiveToolbarButtons({ state, commit }) {
      const activeButtons = state.activeSelectionFormats.map(
        (format) => Object.keys(tinymceCommands).find(
          (command) => tinymceCommands[command].format === format,
        ),
      );
      commit('setActiveToolbarButtons', activeButtons);
    },
  },
};
