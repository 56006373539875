import vCreateCrudModule from './crud/default';
import vCreateStaticCrudModule from './crud/static';

/* eslint-disable no-param-reassign */
export const createCrudModule = (...args) => {
  const module = vCreateCrudModule.apply(this, args);

  module.namespaced = true;

  return module;
};
/* eslint-enable */

/* eslint-disable no-param-reassign */
export const createStaticCrudModule = (...args) => {
  const module = vCreateStaticCrudModule.apply(this, args);

  module.namespaced = true;

  return module;
};
/* eslint-enable */

export default {};
