import { isObject, isArray } from 'lodash-es';

const matchObject = (object, needle) => {
  if (typeof needle === 'function') {
    return needle(object);
  }

  if (Array.isArray(needle) && Array.isArray(object)) {
    return needle.every((item) => object.find(
      (objectItem) => matchObject(objectItem, item),
    ) !== undefined);
  }

  if (isObject(needle) && isObject(object) && !Array.isArray(object)) {
    return Object.keys(needle).every((property) => (
      Object.prototype.hasOwnProperty.call(object, property)
      && matchObject(object[property], needle[property])));
  }

  return object === needle;
};

export const find = (object, filter, options) => {
  const result = [];

  if (matchObject(object, filter)) {
    result.push(object);
    return result;
  }

  if (Array.isArray(object)) {
    object.forEach((item) => {
      if (matchObject(item, filter)) {
        result.push(item);
      } else {
        result.push(...find(item, filter, options));
      }
    });
  } else if (isObject(object)) {
    Object.keys(object).forEach((key) => {
      const item = object[key];
      if (matchObject(item, filter)) {
        result.push(item);
      } else {
        result.push(...find(item, filter, options));
      }
    });
  }

  return result;
};

export const findFirst = (object, filter, path) => {
  /* eslint-disable-next-line no-param-reassign */
  path = path || [];

  let result;
  if (matchObject(object, filter)) {
    return object;
  }

  if (isArray(object)) {
    object.every((item, index) => {
      /* eslint-disable-next-line no-param-reassign */
      path[(path.length || 1) - 1] = index;

      if (matchObject(item, filter)) {
        result = item;
        return false;
      }

      path.push('');
      result = findFirst(item, filter, path);
      if (result === undefined) {
        path.splice(-1, 1);
        return true;
      }

      return false;
    });
  } else if (isObject(object)) {
    Object.keys(object).every((key) => {
      /* eslint-disable-next-line no-param-reassign */
      path[(path.length || 1) - 1] = key;

      const item = object[key];
      if (matchObject(item, filter)) {
        result = item;
        return false;
      }

      path.push('');
      result = findFirst(item, filter, path);
      if (result === undefined) {
        path.splice(-1, 1);
        return true;
      }

      return false;
    });
  }
  return result;
};

export const findPathToFirst = (object, filter) => {
  const path = [];
  if (findFirst(object, filter, path) !== undefined) {
    return path;
  }
  return undefined;
};
