import store from '@/store';
import axios from '@/utils/vuex/client';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import isInternalImage from '@/utils/helpers/isInternalImage';

export default async (imageSrc) => {
  if (!imageSrc || !isInternalImage(imageSrc)) {
    return null;
  }

  const activeSpaceId = store.getters['navigation/activeSpaceId'];
  const activePageId = store.getters['navigation/activePageId'];
  const isEditingTemplatePage = store.getters['navigation/isEditingTemplatePage'];

  const pageIdIndex = 7;
  const imagePageId = parseInt(imageSrc.split('/')[pageIdIndex], 10);

  if (activePageId === imagePageId) {
    return imageSrc;
  }

  const isInline = imageSrc.indexOf('/attachments/inline') !== -1;
  const attachmentIdIndex = isInline ? 10 : 9;
  const attachmentId = parseInt(imageSrc.split('/')[attachmentIdIndex], 10);

  if (!attachmentId) {
    return null;
  }

  const requestBody = {
    attachment: {},
  };

  if (!isEditingTemplatePage) {
    requestBody.attachment.spaceId = activeSpaceId;
    requestBody.attachment.pageId = activePageId;
  }

  const copyImageEndpoint = buildApiUrl({
    resource: `attachments/${attachmentId}/copy`,
  });

  try {
    const response = await axios.post(copyImageEndpoint, requestBody);
    const newAttachmentId = response.data.attachment.id;
    let newImageSrc = buildApiUrl({
      resource: `/spaces/${activeSpaceId}/pages/${activePageId}/attachments/inline/${newAttachmentId}/view`,
      json: false,
    });

    if (isEditingTemplatePage) {
      newImageSrc = buildApiUrl({
        resource: `/attachments/${newAttachmentId}/view`,
        json: false,
      });
    }

    return newImageSrc;
  } catch (error) {
    return error;
  }
};
