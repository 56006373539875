import trimExtraWhitespace from '@/utils/helpers/trimExtraWhitespace';

export default {
  namespaced: true,
  state: {
    changeMessage: '',
    content: '',
    hasOtConnection: false,
    hasOtConnectionError: false,
    hasPendingSync: false,
    isLoadingInitialContent: true,
    isPublishing: false,
    lastSyncedContent: null,
    selectedWidgetUuid: null,
    shouldNotifyFollowers: false,
    shouldShowEstablishingConnectionScreen: false,
    title: '',
    titleError: null,
    toolbarDisabled: false,
    usersPresentList: [],
    isEditorActive: false,
    isEmpty: false,
    templateUsedId: null,
    isFullWidth: false,
  },
  getters: {
    isPageValid: (state) => !state.titleError,

    changeMessage: (state) => state.changeMessage,

    shouldNotifyFollowers: (state) => state.shouldNotifyFollowers,

    hasUnsyncedChanges: (state) => state.hasPendingSync
      && state.lastSyncedContent !== state.content,

    shouldPromptForUnsavedChangesOnPageLeave(state, getters) {
      return getters.hasUnsyncedChanges
        && !state.isPublishing;
    },

    isLoadingInitialContent(state) {
      return state.isLoadingInitialContent;
    },

    isFullWidth: (state) => state.isFullWidth,

    toolbarDisabled: (state) => state.toolbarDisabled || state.isLoadingInitialContent,
  },
  mutations: {
    setTitle(state, title) {
      state.title = trimExtraWhitespace(title);
    },

    setContent(state, content) {
      state.content = content;
    },

    setHasPendingSync(state, value) {
      state.hasPendingSync = value;
    },

    setIsPublishing(state, isPublishing) {
      state.isPublishing = isPublishing;
    },

    setIsLoadingInitialContent(state, isFetching) {
      state.isLoadingInitialContent = isFetching;
    },

    setHasOtConnection(state, hasConnection) {
      state.hasOtConnection = hasConnection;
    },

    setHasOtConnectionError(state, hasError) {
      state.hasOtConnectionError = hasError;
    },

    setLastSyncedContent(state, content) {
      state.lastSyncedContent = content;
    },

    setShouldShowEstablishingConnectionScreen(state, shouldShow) {
      state.shouldShowEstablishingConnectionScreen = shouldShow;
    },

    setUsersPresentList(state, usersPresentList) {
      state.usersPresentList = usersPresentList;
    },

    clearUsersPresentList(state) {
      state.usersPresentList = [];
    },

    setToolbarAsDisabled(state, toolbarDisabled) {
      state.toolbarDisabled = toolbarDisabled;
    },

    setSelectedWidgetUUID(state, uuid) {
      state.selectedWidgetUuid = uuid;
    },

    setTitleError(state, errorString) {
      state.titleError = errorString;
    },

    resetValidation(state) {
      state.titleError = null;
    },

    updateChangeMessage(state, message) {
      state.changeMessage = message;
    },

    updateShouldNotifyFollowers(state, shouldNotifyFollowers) {
      state.shouldNotifyFollowers = shouldNotifyFollowers;
    },

    setActiveToolbarButtons(state, activeButtons) {
      state.activeToolbarButtons = activeButtons;
    },

    setIsEditorActive(state, isActive) {
      state.isEditorActive = isActive;
    },

    setTemplateUsedId(state, templateUsedId) {
      state.templateUsedId = templateUsedId;
    },

    setEmptyState(state, isEmpty) {
      state.isEmpty = isEmpty;
    },

    setIsFullWidth(state, isFullWidth) {
      state.isFullWidth = isFullWidth;
    },
  },
  actions: {
    setContent({ commit }, content) {
      commit('setContent', content);
    },

    validateTitle({ commit, state }, { maxLength }) {
      if (!state.title) {
        commit('setTitleError', 'Please enter a value for the title');
        return;
      }

      if (state.title.length > maxLength) {
        commit('setTitleError', `Title must not exceed ${maxLength} characters`);
        return;
      }

      commit('resetValidation');
    },

    updateChangeMessage({ commit }, message) {
      commit('updateChangeMessage', message);
    },

    updateShouldNotifyFollowers({ commit }, shouldNotifyFollowers) {
      commit('updateShouldNotifyFollowers', shouldNotifyFollowers);
    },

    clearEditingMetaData({ commit }) {
      commit('updateChangeMessage', '');
      commit('updateShouldNotifyFollowers', false);
    },

    setIsEditorActive({ commit }, isActive) {
      commit('setIsEditorActive', isActive);
    },

    setTemplateUsedId({ commit }, templateUsedId) {
      commit('setTemplateUsedId', templateUsedId);
    },

    setIsFullWidth({ commit }, isFullWidth) {
      commit('setIsFullWidth', isFullWidth);
    },
  },
};
