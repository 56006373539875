const isDevEnvironment = process.env.NODE_ENV !== 'production';

const isStagingEnvironment = process.env.APP_ENVIRONMENT === 'staging';

const chatDevEnvContainersEnabled = process.env.VUE_APP_CHAT_DEV_ENV_CONTAINERS_ENABLED === 'true';

const isSentryEnabled = process.env.IS_SENTRY_ENABLED === 'true';

const isSunbeam = window.location.host.includes('localhost');

const isBetaCheckoutEnvironment = (host, documentCookie) => {
  const isCheckoutBeta = documentCookie.match(/CHECKOUTLB=beta/) !== null;
  return isCheckoutBeta;
};

const isBetaEnvironment = (host, documentCookie) => {
  const isBetaCookieSet = documentCookie.match(/SPACES=beta/) !== null;
  const isBetaSubDomain = host.indexOf('twtest') > -1;
  return isBetaCookieSet || isBetaSubDomain;
};

export {
  isDevEnvironment,
  isBetaCheckoutEnvironment,
  isBetaEnvironment,
  isStagingEnvironment,
  isSunbeam,
  chatDevEnvContainersEnabled,
  isSentryEnabled,
};
