import Vue from 'vue';
import pluralize from 'pluralize';

import fullName from '@/utils/filters/fullName';
import { createCrudModule } from '@/utils/vuex';
import cnameHelper from '@/utils/helpers/CNameHelper';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import prepareIncludedFields from '@/utils/vuex/utils/prepareIncludedFields';

import { REQUESTER_TYPES, PERMISSION_LEVELS, PERMISSION_SEQUENCE_TOP_TO_BOTTOM } from '@/utils/constants/spacePermissions';

import store from '..';

const resource = 'permission';

export default createCrudModule({
  only: ['FETCH_LIST', 'CREATE', 'UPDATE', 'DESTROY'],
  resource,
  customUrlFn(id, spaceId) {
    return buildApiUrl({
      resource: id ? `spaces/${spaceId}/permissions/${id}` : `spaces/${spaceId}/permissions`,
    });
  },
  preparePayload(data, action) {
    const tData = {};

    if (Array.isArray(data)) {
      tData[pluralize(resource)] = prepareIncludedFields(data);
    } else {
      tData[resource] = prepareIncludedFields(data);

      // Note: this is a bit hacky, but unfortunately the API expects a different format for the
      // 'update' action than what is returned from the PATCH response. Therefore we'll expect
      // the data format when calling the 'update' action to match the response/state format and
      // use this hook to transform the payload before sending it to the API. It's worth noting
      // that this is only an issue when using the 'isOptimistic' flag
      if (action === 'update') {
        tData[resource] = { level: tData[resource].requester.meta.level };
      }
    }

    return tData;
  },
  getters: {
    forRequesterLevel(state, getters) {
      return (requesterLevel) => {
        const userPermissions = Object.values(getters.groupedByRequesterType.users || {});
        const companyPermissions = Object.values(getters.groupedByRequesterType.companines || {});
        const permissions = [...userPermissions, ...companyPermissions];

        return permissions.filter(
          (permission) => permission.requester.meta.level === requesterLevel,
        );
      };
    },

    forRequesterType(state, getters) {
      return (requesterType) => Object.values(getters.groupedByRequesterType[requesterType] || {});
    },

    forRequesterTypeAndLevel(state, getters) {
      /* eslint-disable-next-line */
      return (requesterType, level) => {
        return getters.forRequesterType(requesterType)
          /* eslint-disable-next-line */
          .filter((permission) => {
            return permission.requester.meta.level === level;
          });
      };
    },

    forRequester(state, getters) {
      /* eslint-disable-next-line */
      return (requesterType, requesterId) => {
        return getters.forRequesterType(requesterType)
          /* eslint-disable-next-line */
          .find((permission) => {
            return permission.requester.id === requesterId;
          });
      };
    },

    /**
     * Provides the map of permissions grouped by 'requesterType'
     */
    groupedByRequesterType(state, getters, rootState, rootGetters) {
      const permissions = {};

      if (!Array.isArray(getters.list) || !getters.list.length) {
        return permissions;
      }

      PERMISSION_SEQUENCE_TOP_TO_BOTTOM.forEach((requesterType) => {
        permissions[requesterType] = {};
      });

      getters.list.forEach((permission) => {
        if (!Object.prototype.hasOwnProperty.call(permissions, permission.requester.type)) {
          permissions[permission.requester.type] = {};
        }

        // Special case - admins must be excluded
        if (permission.requester.type === REQUESTER_TYPES.USER) {
          const user = rootGetters['users/byId'](permission.requester.id);
          // Unless the admin has a defined permission level i.e. they created the Space
          if (!user || (user.isAdmin && !permission.requester.meta.level)) {
            return;
          }
        }

        permissions[permission.requester.type][permission.requester.id] = permission;
      });

      return permissions;
    },

    filterablePermissionList(state, getters, rootState, rootGetters) {
      return getters.list
        .filter((permission) => {
          // first filter out permissions with deleted requester object
          let requestorObject = null;
          if (permission.requester.type === REQUESTER_TYPES.USER) {
            requestorObject = rootGetters['users/byId'](permission.requester.id);
          }

          if (permission.requester.type === REQUESTER_TYPES.COMPANY) {
            requestorObject = rootGetters['companies/byId'](permission.requester.id);
          }

          return !!requestorObject;
        })
        .map((permission) => {
          // now transform filtered permissions into 'normalised' filterable list
          let filterablePermission = {
            ...permission,
          };

          let requesterObject = null;

          if (permission.requester.type === REQUESTER_TYPES.USER) {
            requesterObject = rootGetters['users/byId'](permission.requester.id);
            filterablePermission = {
              ...filterablePermission,
              name: fullName(requesterObject),
              user: requesterObject,
            };
          }

          if (permission.requester.type === REQUESTER_TYPES.COMPANY) {
            requesterObject = rootGetters['companies/byId'](permission.requester.id);
            filterablePermission = {
              ...filterablePermission,
              name: requesterObject.name,
              company: requesterObject,
            };
          }

          return {
            ...filterablePermission,
          };
        });
    },

    getResolvedUserPermissions(state, getters, rootState, rootGetters) {
      return (companyPermissions, userPermissions) => {
        const resolvedPermissions = {};

        // Note: we need to exclude admins from the user list as they always have access to all
        // Spaces
        const users = rootGetters['users/list'].filter((user) => !user.isAdmin);

        const userPermissionsByRequesterId = (() => {
          const permissions = {};

          Object.values(userPermissions)
            .forEach((userPermission) => {
              permissions[userPermission.requester.id] = userPermission;
            });

          return permissions;
        })();

        users.forEach((user) => {
          resolvedPermissions[user.id] = null;
        });

        Object.values(companyPermissions)
          .forEach((companyPermission) => {
            const companyUsers = rootGetters['users/getUsersForCompany'](companyPermission.requester.id);

            companyUsers.forEach((companyUser) => {
              resolvedPermissions[companyUser.id] = companyPermission.requester.meta.level;
            });
          });

        Object.values(userPermissionsByRequesterId)
          .forEach((userPermission) => {
            resolvedPermissions[userPermission.requester.id] = userPermission.requester.meta.level;
          });

        return resolvedPermissions;
      };
    },

    usersWithAnyPermissionLevel(state, getters, rootState, rootGetters) {
      const users = {};

      const companyPermissions = getters.forRequesterType(REQUESTER_TYPES.COMPANY);
      const userPermissions = getters.forRequesterType(REQUESTER_TYPES.USER);

      const adminUsers = rootGetters['users/list'].filter((user) => user.isAdmin && user.state === 'active');

      Object.values(adminUsers)
        .forEach((adminUser) => {
          users[adminUser.id] = adminUser;
        });

      Object.values(companyPermissions)
        .forEach((companyPermission) => {
          const companyUsers = rootGetters['users/getUsersForCompany'](companyPermission.requester.id);

          companyUsers.forEach((companyUser) => {
            users[companyUser.id] = companyUser;
          });
        });

      Object.values(userPermissions)
        .forEach((userPermission) => {
          users[userPermission.requester.id] = rootGetters['users/byId'](userPermission.requester.id);
        });

      return Object.values(users);
    },

    resolvedUserPermissions(state, getters) {
      return getters.getResolvedUserPermissions(
        getters.forRequesterType(REQUESTER_TYPES.COMPANY),
        getters.forRequesterType(REQUESTER_TYPES.USER),
      );
    },

    usersWithEditOrManage(state, getters, rootState, rootGetters) {
      return Object.entries(getters.resolvedUserPermissions)
        .filter(([, level]) => level === PERMISSION_LEVELS.EDIT || level === PERMISSION_LEVELS.MANAGE)
        .map(([userId]) => rootGetters['users/byId'](userId));
    },
  },

  actions: {
    async addPermission({ commit }, permission) {
      commit('addPermission', permission);
    },
  },

  mutations: {
    addPermission(state, permission) {
      Vue.set(state.entities, permission.id, permission);
    },
  },

  onFetchListStart(state) {
    // This store should only contain and manage the permissions for the current space
    state.entities = {};
  },

  onDestroySuccess() {
    if (Vue.$ga) {
      Vue.$ga.event({
        eventCategory: 'spacePermissions',
        eventAction: 'deleted',
        eventLabel: `${cnameHelper()} | ${store.getters['navigation/activeSpace'].title}`,
        eventValue: 1,
      });
    }
  },
});
