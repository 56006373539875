
import { mapGetters } from 'vuex';
import { isEqualWith, isNil, omitBy } from 'lodash-es';

import ErrorContainer from '@pages/Error/ErrorContainer';
import DefaultErrorIllustration from '@/assets/states/default/error.svg';
import { ROUTE_NAMES } from '@/router/routes';
import SPACE_CONSTANTS from '@/utils/constants/spaceConstants';
import { DEFAULT_HOME_ROUTE_NAME } from '@/router';

// @vue/component
export default {
  props: {
    errorCode: {
      type: String,
      default: '',
    },
  },
  components: {
    DefaultErrorIllustration,
    ErrorContainer,
  },
  computed: {
    ...mapGetters('navigation', ['activeSpace']),

    errorTitle() {
      return this.errorCode || 'Oops, we got an unexpected error!';
    },

    buttonText() {
      if (this.activeSpace) {
        if (this.activeSpace.title) {
          return `Back to ${this.activeSpace.title} home page`;
        }

        return 'Back to space home page';
      }

      return 'Back to home';
    },

    shouldRenderButton() {
      if (!this.$route || !this.$route.name) {
        return false;
      }

      return !(
        this.$route.name === this.to.name
        && isEqualWith(
          omitBy(this.$route.params, isNil),
          omitBy(this.to.params, isNil),
        )
      );
    },

    to() {
      if (this.activeSpace) {
        return {
          name: ROUTE_NAMES.SPACE,
          params: {
            spaceCode: this.activeSpace.code,
            pageCode: SPACE_CONSTANTS.defaultPageCode,
          },
        };
      }

      return { name: DEFAULT_HOME_ROUTE_NAME };
    },
  },
  methods: {
    onButtonClicked() {
      this.$router.push(this.to);
    },
  },
};
