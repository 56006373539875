export default (a, b, descendingOrder = false) => {
  if (descendingOrder) {
    if (b < a) {
      return -1;
    }

    return (b > a) ? 1 : 0;
  }

  if (a < b) {
    return -1;
  }

  return (a > b) ? 1 : 0;
};
