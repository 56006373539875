import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { debounce } from 'lodash-es';

import EditLinkMixin from '@sections/SpacesEditor/SpacesEditorBody/Mixins/EditLinkMixin';
import SpacesEditorAutocomplete from '@sections/SpacesEditor/SpacesEditorAutocomplete';

import { DATA_PROMISE_KEYS } from '@/utils/constants/dataPromise';

// @vue/component
export default {

  mixins: [EditLinkMixin],
  inject: ['editor', 'isCollaborative'],

  props: {
    editorId: {
      type: String,
      default: '',
      required: false,
    },
    initialContent: {
      type: String,
      default: '',
      required: false,
    },
    mentionsEnabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    mentionUsers: {
      type: Array,
      default: () => [],
      required: false,
    },
    otConfig: {
      type: Object,
      default: () => {},
      required: false,
      validator: (value) => value.documentId && value.collectionName,
    },
  },

  components: {
    EmojiPicker: () => import(/* webpackChunkName: "emoji-picker" */ '@sections/EmojiPicker'),
    RichTextEditor: () => import(/* webpackChunkName: "rich-text-editor" */ '@widgets/RichTextEditor'),
    SpacesEditorAutocomplete,
  },

  data() {
    return {
      emojiPickerIsOpen: false,
      isAutocompleteActive: false,
      otNothingPending: false,
      scrollTop: 0,
      isTinyMCEReady: false,
    };
  },

  computed: {
    ...mapState('ui/spacesEditor', {
      editorContent: 'content',
      isEmpty: 'isEmpty',
      toolbarDisabled: 'toolbarDisabled',
      isFullWidth: 'isFullWidth',
      shouldShowCommentsGutter: 'shouldShowCommentsGutter',
    }),

    ...mapGetters('navigation', ['isEditorActive']),

    editorDraftReady() {
      return ((this.isCollaborative && this.otNothingPending) || (!this.isCollaborative || this.isCypressCI));
    },
    isEmojisLoaded() {
      return this.$store.getters['loading/hasDataLoaded'](DATA_PROMISE_KEYS.EMOJIS);
    },

    content: {
      get() {
        return this.editorContent;
      },
      set(content) {
        this.setContent(content);
      },
    },
  },

  methods: {
    ...mapMutations('ui/spacesEditor', [
      'setContent',
      'setEmptyState',
      'setToolbarAsDisabled',
    ]),

    ...mapActions('ui/activeEditor', [
      'refreshActiveSelectionFormats',
    ]),

    enableToolbar() {
      if (this.toolbarDisabled) {
        this.setToolbarAsDisabled(false);
      }
    },

    onTinymceReady(editor) {
      // local ref of tinymce instance
      this.tinymceInstance = editor;

      this.editor.ref = editor;

      if ((!this.isCollaborative) || (this.isCypressCI)) {
        this.onEditorReady();
        this.startImgMutationObserver(editor);
      }

      // This is specifically for InlineComment Selection on touch devices such as iPad
      // as the Node Change event does not fire, this also fixes the Sentry error with
      // plugins null, which happens whe tinymce is not initialized
      // so we will only add the listener when the editor is collaborative and
      // is ready
      // https://sentry.io/organizations/teamwork/issues/1591602581/?project=1249004&query=is%3Aunresolved+plugins&statsPeriod=14d
      if (this.isCollaborative) {
        document.addEventListener('selectionchange', this.onDocumentSelectionChanged);
      }

      this.isTinyMCEReady = true;
    },

    /* eslint-disable-next-line func-names */
    onDocumentSelectionChanged: debounce(function () {
      // If the InlineCommentMixin is part of the object and its collaborative
      if (this.isCollaborative && this.shouldShowInlineCommentPopover) {
        this.shouldShowInlineCommentPopover();
      }
    }, 100, { leading: false }),

    /* eslint-disable-next-line func-names */
    onSelectionChange: debounce(function (editor, e) {
      if (this.isEditorActive) {
        // ensures active editor is focused editor
        if (window.tinymce
          && window.tinymce.activeEditor !== window.tinymce.focusedEditor
          && window.tinymce.setActive
        ) {
          window.tinymce.setActive(editor);
        }

        this.refreshActiveSelectionFormats(editor);

        this.shouldShowEditLinkPopover(editor, e);
      }
    }, 200),

    startImgMutationObserver(editor) {
      const vm = this;

      const targetNode = vm.$refs.richTextEditor.$el;
      const observerConfig = {
        attributes: true,
        childList: true,
        characterData: true,
        subtree: true,
      };

      vm.mutationObserver = new MutationObserver((mutationRecords) => {
        const imgInsertionRecords = mutationRecords.filter((mutation) => {
          const x = Array.apply(0, mutation.addedNodes)
            .find((node) =>
              node.nodeName === 'IMG' && (node.parentNode === null || !node.parentNode.className.includes('mce-pastebin')));
          return x !== undefined;
        });

        imgInsertionRecords.forEach((imgMutation) => {
          const imgEl = imgMutation.addedNodes[0];

          if (editor.dom.getParent(imgEl, '.ot-caret', editor.getBody())) {
            return;
          }

          if (!imgEl.src) {
            editor.dom.remove(imgEl);
            return;
          }

          const image = editor.dom.createHTML('tw-spaces-image ', {
            src: imgEl.src,
            caption: '',
          });

          /**
           Note: depending on whether the image has been inserted in a paragraph,
           we must change the order by which we insert the new custom
           tw-spaces-image element and delete the old img element.
           I am not sure why this is necessary.
           Perhaps it has something to do with how tinymce handles new lines.
           For example, when you delete the last character of a paragraph,
           tinymce will insert a child <br> to that empty <p> tag.
             */

          if (!vm.$store.getters['ui/activeEditor/hasActiveSelectionFormat']('p')) {
            editor.insertContent(image);
            editor.dom.remove(imgEl);
          } else {
            editor.dom.remove(imgEl);
            editor.insertContent(image);
          }
        });
      });

      this.mutationObserver.observe(targetNode, observerConfig);
    },

    onEditorReady() {
      this.$emit('ready');
    },

    onSave() {
      this.$emit('save');
    },

    onEmptyStateChanged(isEmpty) {
      this.setEmptyState(isEmpty);
    },
  },

  beforeDestroy() {
    this.editor.ref = null;

    if (this.imgMutationObserver instanceof MutationObserver) {
      this.imgMutationObserver.disconnect();
    }

    if (this.isCollaborative) {
      document.removeEventListener('selectionchange', this.onDocumentSelectionChanged);
    }
  },
};
