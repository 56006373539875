
import { Vue2 } from 'vue-demi';
import { isUndefined } from 'lodash-es';

import { createStaticCrudModule } from '@/utils/vuex';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';

export default createStaticCrudModule({
  only: ['FETCH'],
  socketEvents: ['UPDATE'],
  property: 'stats',
  urlRoot: buildApiUrl({
    resource: 'stats',
  }),
  getters: {
    pageCount: (state, getters) => (
      getters.get
      && getters.get.pageCount
    ),

    templateCount: (state, getters) => (
      getters.get
      && getters.get.templateCount
    ),

    pageShareCount: (state, getters) => (
      getters.get
      && getters.get.pageShareCount
    ),

    maxPages: (state, getters) => (
      getters.get
      && getters.get.maxPages
    ),

    maxTemplates: (state, getters) => (
      getters.get
      && getters.get.maxTemplates
    ),

    maxPageShares: (state, getters) => (
      getters.get
      && getters.get.maxPageShares
    ),
  },
  mutations: {
    updateViaSocket(state, payload) {
      const { params } = payload;
      const { response } = params;
      const { data } = response;

      const properties = [
        'pageCount',
        'pageShareCount',
        'templateCount',
      ];

      properties.forEach((property) => {
        if (isUndefined(data[property])) {
          return;
        }

        Vue2.set(state.entity, property, data[property]);
      });
    },
  },
});
