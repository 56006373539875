import { isPlainObject, isUndefined } from 'lodash-es';
import store from '@/store';
import Error from '@pages/Error';
import LoadingContent from '@/components/widgets/LoadingPlaceholder/LoadingContent';
import LoadingSpinner from '@/components/widgets/LoadingPlaceholder/LoadingSpinner';
import PageTemplateEditAccessError from '@pages/Error/PageTemplateEditAccessError';
import SpacePagePrivacyError from '@pages/Error/SpacePagePrivacyError';
import SharedPageInvalidTokenError from '@pages/Error/SharedPageInvalidTokenError';

// Note: these 'contexts' exist to allow us to have some more control over exactly
// what error screen is rendered
export const CONTEXTS = Object.freeze({
  SPACE_PAGE: 'space-page',
  TEMPLATE: 'template',
  SHARED_PAGE: 'shared-page',
});

// @vue/component
export default {
  functional: true,
  props: {
    context: {
      type: String,
      required: false,
      default: '',
    },
    httpError: {
      type: [Object, Response],
      default: null,
    },
    isLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
    errorFallBack: {
      type: Object, // Pass in a custom error component
      default: null,
    },
    delaySpinner: {
      type: Number,
      default: 300,
    },
    loader: {
      type: Object,
      required: false,
      default: () => LoadingSpinner,
    },
  },

  render(h, context) {
    if (context.props.httpError) {
      // 503 errors comeback as string of html, need to handle that condition
      let errorBody = {};
      if (context.props.httpError.data && isPlainObject(context.props.httpError.data)) {
        errorBody = context.props.httpError.data;
      }

      // Note: we need to ensure `status` is always defined. `status` would only not be set
      // in the event of a connection error
      const status = !isUndefined(context.props.httpError.status) ? context.props.httpError.status.toString() : '';
      const statusText = context.props.httpError.statusText || '';

      const errorCode = Number(status);

      // 403 handling needs cleaned up a bit. This is for a user attempting to edit a page
      // template that they aren't privileged to edit.
      if (context.props.context === CONTEXTS.TEMPLATE && errorCode === 403) {
        return h(PageTemplateEditAccessError, {});
      }

      // Special case: 403 on a space page endpoint is assumed to be due to page privacy and
      // as such we'll show a bespoke error
      if (context.props.context === CONTEXTS.SPACE_PAGE
        && errorCode === 403
        && !store.getters['navigation/isEditingSpacePage']) {
        return h(SpacePagePrivacyError, {});
      }

      // 401 or 402 on shared page
      if (context.props.context === CONTEXTS.SHARED_PAGE && (errorCode === 401 || errorCode === 402)) {
        return h(SharedPageInvalidTokenError, {});
      }

      return h(Error, {
        props: {
          errorCode: status,
          errorMessage: statusText,
          errorBody,
        },
      });
    }

    if (context.props.isLoading) {
      return h(context.props.loader, {
        props: {
          delaySpinner: context.props.delaySpinner,
        },
      });
    }

    return h(
      LoadingContent,
      {
        props: {
          errorFallBack: context.props.errorFallBack,
        },
      },
      context.children,
    );
  },
};
