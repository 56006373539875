import ErrorContainer from '@pages/Error/ErrorContainer';
import NoAccessIllustration from '@/assets/no_access.svg';
import { ROUTE_NAMES } from '@/router/routes';

// @vue/component
export default {
  components: {
    ErrorContainer,
    NoAccessIllustration,
  },
  methods: {
    onButtonClicked() {
      this.$router.push({
        name: ROUTE_NAMES.SPACE,
        params: {
          spaceCode: this.$store.getters['navigation/activeSpace'].code,
        },
      });
    },
  },
};
