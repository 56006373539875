export default {
  namespaced: true,
  getters: {
    userList(state, getters, rootState, rootGetters) {
      if (rootGetters['activePagePermissions/isPrivateForOnlyMe']) {
        return [];
      }
      if (rootGetters['activePagePermissions/isPrivateForSpecificMembers']) {
        return rootGetters['activePagePermissions/usersWithPagePermissions'];
      }
      if (rootGetters['activePagePermissions/isPrivateForEditors']) {
        return rootGetters['spacePermissions/usersWithEditOrManage'];
      }
      return rootGetters['spacePermissions/usersWithAnyPermissionLevel'];
    },
  },
};
