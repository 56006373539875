/* eslint-disable no-param-reassign */

import { cloneDeep, isFunction, isUndefined } from 'lodash-es';
import pluralize from 'pluralize';

import handleIncludedFields from '@/utils/vuex/utils/handleIncludedFields';
import prepareIncludedFields from '@/utils/vuex/utils/prepareIncludedFields';
import handleMeta from '@/utils/vuex/utils/handleMeta';
import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';

import defaultPrepareConfig from '@/utils/vuex/utils/defaultPrepareConfig';
import defaultClient from '../../client';
import createActions from './createActions';
import createGetters from './createGetters';
import createMutations from './createMutations';
import createState from './createState';

/**
 * Called after the 'error' mutation i.e. 'fetchListError' or 'createError' for the current Vuex CRUD module
 * @callback onError
 * @param {Object} state - The 'state' for the current Vuex CRUD module
 * @param {Error} error - The error that was thrown
 */

/**
 * Called after the 'fetchListSuccess' mutation for the current Vuex CRUD module
 * @callback onFetchListSuccess
 * @param {Object} state - The 'state' for the current Vuex CRUD module
 * @param {Object} response - The modified HTTP response ('data' property is changed by the
 * associated action in the store module)
 * @param {Object} includes - The raw 'included' property found in the HTTP response
 * @param {Object} meta - The raw 'meta' property found in the HTTP response
 */

/**
 * Called after the 'fetchSingleSuccess' mutation for the current Vuex CRUD module
 * @callback onFetchSingleSuccess
 * @param {Object} state - The 'state' for the current Vuex CRUD module
 * @param {Object} response - The modified HTTP response ('data' property is changed by the
 * associated action in the store module)
 * @param {Object} includes - The raw 'included' property found in the HTTP response
 * @param {Object} meta - The raw 'meta' property found in the HTTP response
 */

/**
 * Called after the 'createSuccess' mutation for the current Vuex CRUD module
 * @callback onCreateSuccess
 * @param {Object} state - The 'state' for the current Vuex CRUD module
 * @param {Object} response - The modified HTTP response ('data' property is changed by the
 * associated action in the store module)
 * @param {Object} includes - The raw 'included' property found in the HTTP response
 * @param {Object} meta - The raw 'meta' property found in the HTTP response
 */

/**
 * Called after the 'updateSuccess' mutation for the current Vuex CRUD module
 * @callback onUpdateSuccess
 * @param {Object} state - The 'state' for the current Vuex CRUD module
 * @param {Object} response - The modified HTTP response ('data' property is changed by the
 * associated action in the store module)
 * @param {Object} includes - The raw 'included' property found in the HTTP response
 * @param {Object} meta - The raw 'meta' property found in the HTTP response
 */

/**
 * Called after the 'replaceSuccess' mutation for the current Vuex CRUD module
 * @callback onReplaceSuccess
 * @param {Object} state - The 'state' for the current Vuex CRUD module
 * @param {Object} response - The modified HTTP response ('data' property is changed by the
 * associated action in the store module)
 * @param {Object} includes - The raw 'included' property found in the HTTP response
 * @param {Object} meta - The raw 'meta' property found in the HTTP response
 */

/**
 * Called after the 'destroySuccess' mutation for the current Vuex CRUD module
 * @callback onDestroySuccess
 * @param {Object} state - The 'state' for the current Vuex CRUD module
 * @param {Number} id - The ID of the delete entity
 */

/**
 * Creates new Vuex CRUD module.
 *
 * @param {Object} configuration
 * @property {String} idAttribute The name of ID attribute.
 * @property {String} resource The name of the resource.
 * @property {String} urlRoot The root url.
 * @property {Function} customUrlFn A custom getter for more complex URL to request data from API.
 * @property {Object} state The default state (will override generated state).
 * @property {Object} actions The default actions (will override generated actions object).
 * @property {Object} mutations The default mutations (will override generated mutations object).
 * @property {Object} getters The default getters (will override generated getters object).
 * @property {Object} client The client that should be used to do API requests.
 * @property {Function} onFetchListStart Mutation method called after collection fetch start.
 * @property {onFetchListSuccess} onFetchListSuccess Mutation method called after collection fetch success.
 * @property {onError} onFetchListError Mutation method called after collection fetch error.
 * @property {Function} onFetchSingleStart Mutation method called after single fetch start.
 * @property {onFetchSingleSuccess} onFetchSingleSuccess Mutation method called after single fetch success.
 * @property {onError} onFetchSingleError Mutation method called after single fetch error.
 * @property {Function} onCreateStart Mutation method called after create state.
 * @property {onCreateSuccess} onCreateSuccess Mutation method called after create success.
 * @property {onError} onCreateError Mutation method called after create error.
 * @property {Function} onUpdateStart Mutation method called after update state.
 * @property {onUpdateSuccess} onUpdateSuccess Mutation method called after update success.
 * @property {onError} onUpdateError Mutation method called after update error.
 * @property {Function} onReplaceStart Mutation method called after replace state.
 * @property {onReplaceSuccess} onReplaceSuccess Mutation method called after replace success.
 * @property {onError} onReplaceError Mutation method called after replace error.
 * @property {Function} onDestroyStart Mutation method called after destroy state.
 * @property {onDestroySuccess} onDestroySuccess Mutation method called after destroy success.
 * @property {onError} onDestroyError Mutation method called after destroy error.
 * @property {Array} only A list of CRUD actions that should be available.
 * @property {Function} parseList A method used to parse list of resources.
 * @property {Function} parseSingle A method used to parse singe resource.
 * @property {Function} parseError A method used to parse error responses.
 * @property {Function} prepareConfig A method used to perform any necessary modifications to
 * the client config ahead of the API call.
 * @property {Function} preparePayload A method used to perform any necessary modifications to
 * passed 'data' ahead of the API call.
 * @property {String} singleProperty Specifies the single 'nesting' property as part of Teamwork's
 * Unified API.
 * @property {String} listProperty Specifies the list 'nesting' property as part of Teamwork's
 * Unified API.
 * @property {String} app Specifies the Teamwork app this module is for
 * @property {Array} socketEvents A list of socket operation that should be handled
 * @property {Function} onCreateViaSocketSuccess Mutation method called
 * after createViaSocket success.
 * @property {Function} onUpdateViaSocketSuccess Mutation method called
 * after updateViaSocket success.
 * @property {Function} onDestroyViaSocketSuccess Mutation method called
 * after destroyViaSocket success.
 * @return {Object} A Vuex module.
 */
const createCrudModule = ({
  idAttribute = 'id',
  resource,
  urlRoot,
  customUrlFn = null,
  state = {},
  actions = {},
  mutations = {},
  getters = {},
  client = defaultClient,
  onFetchListStart = () => { },
  onFetchListSuccess = () => { },
  onFetchListError = () => { },
  onFetchSingleStart = () => { },
  onFetchSingleSuccess = () => { },
  onFetchSingleError = () => { },
  onCreateStart = () => { },
  onCreateSuccess = () => { },
  onCreateError = () => { },
  onUpdateStart = () => { },
  onUpdateSuccess = () => { },
  onUpdateError = () => { },
  onReplaceStart = () => { },
  onReplaceSuccess = () => { },
  onReplaceError = () => { },
  onDestroyStart = () => { },
  onDestroySuccess = () => { },
  onDestroyError = () => { },
  onDestroyAllStart = () => { },
  onDestroyAllSuccess = () => { },
  onDestroyAllError = () => { },
  only = ['FETCH_LIST', 'FETCH_SINGLE', 'CREATE', 'UPDATE', 'REPLACE', 'DESTROY', 'DESTROY_ALL'],
  parseList,
  parseSingle,
  parseError = (res) => res,
  prepareConfig,
  preparePayload,
  singleProperty,
  listProperty,
  app = 'spaces',
  socketEvents = [],
  onSocketDataReceived = () => { },
  onCreateViaSocketSuccess = () => { },
  onUpdateViaSocketSuccess = () => { },
  onDestroyViaSocketSuccess = () => { },
} = {}) => {
  if (!resource) {
    throw new Error('Resource name must be specified');
  }

  singleProperty = singleProperty || resource;
  listProperty = listProperty || pluralize(resource);

  let rootUrl;

  /**
   * Create root url for API requests. By default it is: /api/v<apiVersionNumber>/<resource>.
   * Use custom url getter if given.
   */
  if (typeof customUrlFn === 'function') {
    rootUrl = customUrlFn;
  } else if (typeof urlRoot === 'string') {
    rootUrl = ((url) => {
      const lastCharacter = url.substr(-1);

      return lastCharacter === '/' ? url.slice(0, -1) : url;
    })(urlRoot);
  } else {
    rootUrl = buildApiUrl({
      resource: pluralize(resource),
      app,
    });
  }

  if (!isFunction(prepareConfig)) {
    prepareConfig = defaultPrepareConfig;
  }

  if (!isFunction(preparePayload)) {
    // eslint-disable-next-line no-unused-vars
    preparePayload = (data, action) => {
      const tData = {};

      if (Array.isArray(data)) {
        tData[listProperty] = prepareIncludedFields(data);
      } else {
        tData[singleProperty] = prepareIncludedFields(data);
      }

      return tData;
    };
  }

  if (!isFunction(parseSingle)) {
    parseSingle = (response) => {
      const { data } = response;

      if (isUndefined(data[singleProperty])) {
        throw new Error(`'${singleProperty}' property not found in response.data`);
      }

      // Note: we need to clone the response (specifically the 'data' object that will be committed
      // to state) to ensure that our response throttling cache does not serve the same object
      // (by reference). This would mean when processing the cached response we would be
      // inadvertently attempting to modify store data
      const newResponse = cloneDeep(response);

      newResponse.data = newResponse.data[singleProperty];

      if (data.included) {
        handleIncludedFields(newResponse, data.included);
      }

      handleMeta(newResponse);

      return newResponse;
    };
  }

  if (!isFunction(parseList)) {
    parseList = (response) => {
      const { data } = response;

      if (isUndefined(data[listProperty])) {
        throw new Error(`'${listProperty}' property not found in response.data`);
      }

      // Note: we need to clone the response (specifically the 'data' object that will be committed
      // to state) to ensure that our response throttling cache does not serve the same object
      // (by reference). This would mean when processing the cached response we would be
      // inadvertently attempting to modify store data
      const newResponse = cloneDeep(response);

      newResponse.data = newResponse.data[listProperty];

      handleIncludedFields(newResponse, data.included);

      handleMeta(newResponse);

      return newResponse;
    };
  }

  return {
    namespaced: true,

    state: createState({ state, only }),

    actions: createActions({
      actions,
      rootUrl,
      only,
      client,
      parseList,
      parseSingle,
      parseError,
      prepareConfig,
      preparePayload,
      socketEvents,
      onSocketDataReceived,
    }),

    mutations: createMutations({
      mutations,
      idAttribute,
      only,
      onFetchListStart,
      onFetchListSuccess,
      onFetchListError,
      onFetchSingleStart,
      onFetchSingleSuccess,
      onFetchSingleError,
      onCreateStart,
      onCreateSuccess,
      onCreateError,
      onUpdateStart,
      onUpdateSuccess,
      onUpdateError,
      onReplaceStart,
      onReplaceSuccess,
      onReplaceError,
      onDestroyStart,
      onDestroySuccess,
      onDestroyError,
      onDestroyAllStart,
      onDestroyAllSuccess,
      onDestroyAllError,
      socketEvents,
      onCreateViaSocketSuccess,
      onUpdateViaSocketSuccess,
      onDestroyViaSocketSuccess,
    }),

    getters: createGetters({ getters }),
  };
};

export default createCrudModule;

/**
 * Export client in case user want's to add interceptors.
 */
export { defaultClient as client };
