/* eslint-disable no-param-reassign */

import Vue from 'vue';
import { isObject, isArray } from 'lodash-es';

/**
 * Create default mutations and merge them with mutations defined by a user.
 */
const createMutations = ({
  mutations,
  only,
  onFetchStart,
  onFetchSuccess,
  onFetchError,
  onCreateStart,
  onCreateSuccess,
  onCreateError,
  onUpdateStart,
  onUpdateSuccess,
  onUpdateError,
  onReplaceStart,
  onReplaceSuccess,
  onReplaceError,
  onDestroyStart,
  onDestroySuccess,
  onDestroyError,
  socketEvents,
  onCreateViaSocketSuccess,
  onUpdateViaSocketSuccess,
  onDestroyViaSocketSuccess,
}) => {
  const crudMutations = {};

  if (only.includes('FETCH')) {
    Object.assign(crudMutations, {
      fetchStart(state, payload) {
        state.isFetching = true;

        onFetchStart(state, payload);
      },

      fetchSuccess(state, payload) {
        const { params } = payload;
        const { response, includes, meta } = params;
        const { data } = response;

        Vue.set(state, 'entity', data);

        if (isArray(data)) {
          state.currentMeta = isObject(params.meta) ? params.meta : {};
        }

        state.isFetching = false;
        state.fetchError = null;

        onFetchSuccess(state, response, includes, meta);
      },

      fetchError(state, err) {
        state.fetchError = err;
        state.isFetching = false;

        onFetchError(state, err);
      },
    });
  }

  if (only.includes('CREATE')) {
    Object.assign(crudMutations, {
      createStart(state, payload) {
        state.isCreating = true;

        onCreateStart(state, payload);
      },

      createSuccess(state, payload) {
        const { params } = payload;
        const { response, includes, meta } = params;
        const { data } = response;

        Vue.set(state, 'entity', data);
        state.isCreating = false;
        state.createError = null;

        onCreateSuccess(state, response, includes, meta);
      },

      createError(state, err) {
        state.createError = err;
        state.isCreating = false;

        onCreateError(state, err);
      },
    });
  }

  if (only.includes('UPDATE')) {
    Object.assign(crudMutations, {
      updateStart(state, payload) {
        state.isUpdating = true;

        onUpdateStart(state, payload);
      },

      updateSuccess(state, payload) {
        const { params } = payload;
        const { response, includes, meta } = params;
        const { data } = response;

        Vue.set(state, 'entity', data);

        state.isUpdating = false;
        state.updateError = null;

        onUpdateSuccess(state, response, includes, meta);
      },

      updateError(state, err) {
        state.updateError = err;
        state.isUpdating = false;

        onUpdateError(state, err);
      },
    });
  }

  if (only.includes('REPLACE')) {
    Object.assign(crudMutations, {
      replaceStart(state, payload) {
        state.isReplacing = true;

        onReplaceStart(state, payload);
      },

      replaceSuccess(state, payload) {
        const { params } = payload;
        const { response, includes, meta } = params;
        const { data } = response;

        Vue.set(state, 'entity', data);

        state.isReplacing = false;
        state.replaceError = null;

        onReplaceSuccess(state, response, includes, meta);
      },

      replaceError(state, err) {
        state.replaceError = err;
        state.isReplacing = false;

        onReplaceError(state, err);
      },
    });
  }

  if (only.includes('DESTROY')) {
    Object.assign(crudMutations, {
      destroyStart(state, payload) {
        state.isDestroying = true;

        onDestroyStart(state, payload);
      },

      destroySuccess(state) {
        Vue.delete(state, 'entity');

        state.isDestroying = false;
        state.destroyError = null;

        onDestroySuccess(state);
      },

      destroyError(state, err) {
        state.destroyError = err;
        state.isDestroying = false;

        onDestroyError(state, err);
      },
    });
  }

  if (socketEvents.includes('CREATE')) {
    Object.assign(crudMutations, {
      createViaSocket(state, payload) {
        const { params } = payload;
        const { response } = params;
        const { data } = response;

        Vue.set(state, 'entity', data);

        onCreateViaSocketSuccess(state, data);
      },
    });
  }

  if (socketEvents.includes('UPDATE')) {
    Object.assign(crudMutations, {
      updateViaSocket(state, payload) {
        const { params } = payload;
        const { response } = params;
        const { data } = response;

        Vue.set(state, 'entity', data);

        onUpdateViaSocketSuccess(state, data);
      },
    });
  }

  if (socketEvents.includes('DESTROY')) {
    Object.assign(crudMutations, {
      destroyViaSocket(state, payload) {
        const { params } = payload;
        const { response } = params;

        Vue.delete(state, 'entity');

        onDestroyViaSocketSuccess(state, response);
      },
    });
  }

  return Object.assign(crudMutations, mutations);
};

export default createMutations;
