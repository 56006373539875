export default {
  namespaced: true,
  state: {
    emails: [],
  },
  mutations: {
    storeInviteEmails(state, emails) {
      state.emails = emails;
    },

    resetInviteEmails(state) {
      state.emails = [];
    },
  },
  getters: {
    inviteEmails: (state) => (Array.isArray(state.emails) ? state.emails : []),
  },
  actions: {
    storeInviteEmails({ commit }, emails) {
      commit('storeInviteEmails', emails);
    },

    resetInviteEmails({ commit }) {
      commit('resetInviteEmails');
    },
  },
};
