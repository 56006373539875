import buildApiUrl from '@/utils/vuex/utils/buildApiUrl';
import { createStaticCrudModule } from '@/utils/vuex';

export default createStaticCrudModule({
  only: ['FETCH'],
  property: 'subscription',
  urlRoot: buildApiUrl({ resource: 'subscription' }),
  getters: {
    hasPaid: (state, getters) => (
      getters.get
        && getters.get.paidUntil
        && !isNaN(new Date(getters.get.paidUntil).getTime())
    ),

    paidUntil: (state, getters) => (
      getters.get
        && getters.get.paidUntil
        && !isNaN(new Date(getters.get.paidUntil).getTime())
        ? new Date(getters.get.paidUntil)
        : null
    ),

    hasPaymentExpired: (state, getters) => !getters.hasPaid,

    billingAddress: (state, getters) => getters.get.billingAddress,

    billingInfo: (state, getters) => getters.get.billingInfo,

    paymentCurrency: (state, getters) => (getters.billingInfo && getters.billingInfo.billingCurrency) || 'USD',
  },
});
