// @vue/component
export default {
  name: 'SkeletonText',
  props: {
    width: {
      type: String,
      default: '100%',
    },
    isHeading: {
      type: Boolean,
      default: false,
    },
    lineCount: {
      type: Number,
      default: 1,
    },
    isParagraph: {
      type: Boolean,
      default: false,
    },
    isDark: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    widthObj() {
      const widthObj = { num: parseInt(this.width, 10) };
      if (this.width.includes('px')) {
        widthObj.unit = 'px';
      }
      if (this.width.includes('%')) {
        widthObj.unit = '%';
      }
      return widthObj;
    },
    lines() {
      return Array.from(
        {
          length: this.lineCount > 1 ? this.lineCount : 1,
        },
        () => ({
          width: this.calcWidth(),
        }),
      );
    },
  },
  methods: {
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    calcWidth() {
      let width = this.width;
      if (this.lineCount > 1) {
        const { num, unit } = this.widthObj;
        if (unit === '%') {
          const randomWidth = `${this.getRandomInt(0, 75) }px`;
          width = `calc(${width} - ${randomWidth})`;
        } else if (unit === 'px') {
          width = `${this.getRandomInt(num - 75, num) }px`;
        }
      }
      return width;
    },
  },
};
