/* eslint-disable max-len */
/* eslint-disable no-case-declarations */
import Vue from 'vue';
import VueSocketIO from 'vue-socket.io-extended';
import { toUpper, isPlainObject } from 'lodash-es';

import store from '@/store';
import { socketActionPrefix, socketMutationPrefix } from '@/store/modules/socket';

import connections from '@/utils/constants/connections';
import { logInfo } from '@/utils/helpers/logger.utility';
import { getSocketIOInstance, createSocketIOInstance } from '@/utils/helpers/socketIOInstance';

const socketUpdateEventCache = {};

// Cache socket update events so that we don't process same update twice
// event identifier should be generated based on Operation and ObjectID
const cacheSocketUpdateEvent = (eventIdentifier) => {
  if (!socketUpdateEventCache[eventIdentifier]) {
    socketUpdateEventCache[eventIdentifier] = eventIdentifier;

    setTimeout(() => {
      delete socketUpdateEventCache[eventIdentifier];
    }, 1000);
  }
};

const socketEventNames = {
  sendAuthRequest: 'AUTH',
  joinRoom: 'join',
  leaveRoom: 'leave',
  markNotificationAsRead: 'readNotification',
  markNotificationAsUnread: 'unreadNotification',
};

const socketRoomNames = {
  spaceRoom: 'space',
  pageRoom: 'page',
  pageEditRoom: 'pageedit',
};

/*
 * Module modes for incoming socket data
 *  automatic - data is released automatically and updates the operation module entities straight away
 *  manual - data is stored in sockets module and can be released when necessary
 */
const socketOperationToStoreModuleMap = {
  inlinecomment: { module: 'inlineComments', mode: 'automatic' },
  comment: { module: 'comments', mode: 'manual' },
  page: { module: 'pages', mode: 'manual' },
  reaction: { module: 'reactions', mode: 'automatic' },
  stats: { module: 'installationStats', mode: 'automatic' },
};

const socketOperationToStoreActionMap = {
  created: 'createViaSocket',
  updated: 'updateViaSocket',
  deleted: 'destroyViaSocket',
  'updated.reverted': 'updateViaSocket',
  'updated.trashed': 'handlePageTrashedEvent',
};

/* eslint-disable-next-line arrow-body-style */
const isSocketConnected = () => {
  return store.getters['socket/isConnected'];
};

const emitSocketEvent = (eventName, data) => {
  const socketIOInstance = getSocketIOInstance();
  if (socketIOInstance && isSocketConnected()) {
    logInfo(`Socket: emit ${eventName} event`, data);
    socketIOInstance.emit(eventName, data);

    if (data.name
      && data.name === 'page'
      && eventName === 'leave'
      && store.state.socket.socketData
      && store.state.socket.socketData.length > 0
    ) {
      store.dispatch('socket/clearPageRoomSocketData');
    }
  }
};

const startSocket = () => {
  if (window.Cypress) {
    return;
  }

  if (isSocketConnected()) {
    return;
  }

  const region = store.getters['installation/region'];

  // Note: The socket url has now changed to include region only in eu
  const socketUrl = (() => {
    if (process.env.VUE_APP_PROXY_TARGET && process.env.VUE_APP_PROXY_TARGET.includes('.staging.')) {
      return connections.staging.US.spacesSocket;
    }

    return connections[process.env.APP_ENVIRONMENT][region].spacesSocket;
  })();

  createSocketIOInstance(socketUrl);

  Vue.use(
    VueSocketIO,
    getSocketIOInstance(),
    {
      store,
      actionPrefix: socketActionPrefix,
      mutationPrefix: socketMutationPrefix,
      eventToMutationTransformer: toUpper,
      eventToActionTransformer: toUpper,
    },
  );
};

const sendAuthRequest = () => {
  if (isSocketConnected()) {
    return;
  }

  const authToken = store.getters['session/authToken'];
  const socketIOInstance = getSocketIOInstance();
  if (!authToken || !socketIOInstance || !socketIOInstance.connected) {
    return;
  }

  logInfo(`Socket: emit ${socketEventNames.sendAuthRequest} event`, authToken);
  socketIOInstance.emit(socketEventNames.sendAuthRequest, authToken);
};

const buildRoom = (roomName, objectID) => ({ name: roomName, id: objectID });

const sendNotificationStatusRequest = (notificationId, isRead = true) => {
  const socketIOInstance = getSocketIOInstance();
  if (!socketIOInstance || !isSocketConnected()) {
    return;
  }

  const data = { id: notificationId };
  const event = isRead
    ? socketEventNames.markNotificationAsRead
    : socketEventNames.markNotificationAsUnread;

  logInfo(`Socket: emit ${event} event`, data);
  socketIOInstance.emit(event, data);
  store.commit('notifications/markAsRead', { notificationId, isRead });
  store.dispatch('notifications/setUnreadCount', { isRead });
};

const onUserEventReceived = async (data) => {
  store.dispatch('notifications/pushNotification', data);
};

/*
 * Fire appropriate module action or store incoming data based on the module mode. Module needs to
 * have socketEvents set to listen for either UPDATE, CREATE, DESTROY to start using sockets
 */
const onUpdateEventReceived = async (data) => {
  if (!data.Operation || !data.ObjectID) {
    logInfo('Socket: unable to process socket data, Operation or ObjectID not present', data);
    return;
  }

  const cacheIdentifier = `${data.Operation}.${data.ObjectID}`;

  if (socketUpdateEventCache[cacheIdentifier]) {
    return;
  }

  cacheSocketUpdateEvent(cacheIdentifier);

  const [dispatchModule, operation, subOperation] = data.Operation.split('.');

  let dispatchAction = subOperation ? `${operation}.${subOperation}` : operation;

  // Special case for 'reaction' events
  if (dispatchModule === 'reaction') {
    dispatchAction = 'updated';
  }

  // check if the operation is mapped in frontend to handle it
  if (
    !socketOperationToStoreModuleMap[dispatchModule]
    || !socketOperationToStoreModuleMap[dispatchModule].module
    || !socketOperationToStoreActionMap[dispatchAction]
  ) {
    logInfo('Socket: unknown module or action operation', data.Operation);
    return;
  }

  const operationAction = `${socketOperationToStoreModuleMap[dispatchModule].module}/${socketOperationToStoreActionMap[dispatchAction]}`;

  // check if spcific action exists in the store to handle the operation, in case we specified
  // the module to only handle create but socket is notifying us about other operations
  // eslint-disable-next-line no-underscore-dangle
  if (typeof store._actions[operationAction] !== 'object') {
    logInfo(`Socket: \`${socketOperationToStoreActionMap[dispatchAction]}\` action does not exist in \`${socketOperationToStoreModuleMap[dispatchModule].module}\` store module`, data.Operation);
    return;
  }

  // our parse functions relies on data property name, so adjust socket response
  const newResponse = {
    data: data.Body,
    operation: data.Operation,
    objectId: data.ObjectID,
  };

  // Socket responses contain 'included' fields without explicting asking for them. Therefore
  // we need to fake that we asked for them by presenting `response` in the format of an
  // Axios API request i.e. a `response.config.params.include` field to specify the includes.
  // This will ensure the includes are decorated as expected
  // eslint-disable-next-line no-param-reassign
  if (isPlainObject(newResponse.data.included)) {
    newResponse.config = {
      params: {
        include: Object.keys(newResponse.data.included).join(','),
      },
    };
  }

  // if operation module is set in automatic mode, just fire appriopriate action and do not store
  // socket incoming data as it will update module entities automatically
  if (socketOperationToStoreModuleMap[dispatchModule].mode === 'automatic') {
    // Note: this timeout is important to ensure a socket event is fired after a corresponding
    // Vuex CRUD event hook that was the cause of the event
    setTimeout(() => {
      store.dispatch(operationAction, newResponse);
    }, 100);
    return;
  }

  const storeIncomingSocketDataAction = `${socketOperationToStoreModuleMap[dispatchModule].module}/storeIncomingSocketData`;

  // check if the action to store socket data for the module exist
  // eslint-disable-next-line no-underscore-dangle
  if (typeof store._actions[storeIncomingSocketDataAction] !== 'object') {
    logInfo('Socket: action to notify module of incoming socket data does not exists', storeIncomingSocketDataAction);
    return;
  }

  // and add properties to help us filter socket data and release data per module basis
  newResponse.operation = data.Operation;
  newResponse.objectId = data.ObjectID;
  newResponse.module = socketOperationToStoreModuleMap[dispatchModule].module;
  newResponse.action = socketOperationToStoreActionMap[dispatchAction];

  setTimeout(() => {
    // Note: this timeout is important to ensure a socket event is fired after a corresponding
    // Vuex CRUD event hook that was the cause of the event
    store.dispatch(storeIncomingSocketDataAction, newResponse);
  }, 100);
};

export {
  startSocket,
  sendAuthRequest,
  isSocketConnected,
  onUserEventReceived,
  onUpdateEventReceived,
  sendNotificationStatusRequest,
  buildRoom,
  socketEventNames,
  socketRoomNames,
  emitSocketEvent,
};
