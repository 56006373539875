import SpacesIconPrimary from '@/assets/apps/spaces/icon_primary.svg';

// @vue/component
export default {
  components: {
    SpacesIconPrimary,
  },
  computed: {
    installationName() {
      return this.$store.getters['installation/name'];
    },
  },
};
